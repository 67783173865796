import React, { useMemo } from 'react';

import Table, { StatBox } from '../Table';
import SectionContainer from './SectionContainer';
import { SectionTitle } from './shared';

const COLS = [
  {
    label: 'CREATORS',
    key: 'creators',
    render: (data) => <StatBox stat={data.creators}></StatBox>,
    stretch: true,
  },
  {
    label: 'VIDEOS',
    key: 'streams',
    render: (data) => <StatBox stat={data.streams} />,
    stretch: true,
  },
  {
    label: 'Avg. CCV',
    key: 'avgCCV',
    render: (data) => <StatBox stat={data.avgCCV} />,
    stretch: true,
  },
  {
    label: 'Peak CCV',
    key: 'peakCCV',
    render: (data) => <StatBox stat={data.peakCCV} />,
    stretch: true,
  },
  {
    label: 'Duration hrs.',
    key: 'duration',
    render: (data) => <StatBox stat={data.duration} />,
    stretch: true,
  },
  {
    label: 'Hrs. Watched',
    key: 'watched',
    render: (data) => <StatBox stat={data.watched} />,
    stretch: true,
  },
  {
    label: 'Views',
    key: 'views',
    render: (data) => <StatBox stat={data.views} />,
    stretch: true,
  },
  {
    label: 'Media Value',
    key: 'totalEMV',
    render: (data) => (
      <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
    ),
    stretch: true,
  },
];

const getRows = (data) => [
  {
    streams: data.count,
    creators: data.creators,
    avgCCV: data.avg_ccv,
    peakCCV: data.peak_ccv,
    duration: data.duration / 3600,
    watched: data.hrs_watched,
    views: data.views,
    totalEMV: data.total_emv,
    key: 0,
  },
];

export const StreamPerformance = ({
  data,
  campaignInfluencers,
  onViewMore,
}) => {
  const rows = useMemo(() => getRows(data), [data]);
  const idTable = 'stream-performance';
  return (
    <SectionContainer>
      <SectionTitle onViewMore={() => onViewMore()}>Videos</SectionTitle>
      <Table noBorders={true} cols={COLS} rows={rows} idTable={idTable} />
    </SectionContainer>
  );
};

export default StreamPerformance;
