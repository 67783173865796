import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const Chip = ({
  label,
  component: Component = 'div',
  deleteIcon: deleteIconProp,
  onDelete,
}) => {
  let deleteIcon;

  if (deleteIconProp) {
    deleteIcon = React.cloneElement(deleteIconProp, {
      className: clsx(deleteIconProp.props.className, 'ti-close'),
    });
  } else {
    deleteIcon = <i className="ti-close" />;
  }

  return (
    <Component className="option-selector">
      {label}
      <button onClick={onDelete} className="remove-option button-link">
        {deleteIcon}
      </button>
    </Component>
  );
};

Chip.propTypes = {
  /*
   * The component used for the root node
   */
  label: PropTypes.string.isRequired,

  /*
   * The icon used as the close-icon
   */
  deleteIcon: PropTypes.element,

  /*
   * The component used for the root node
   */
  component: PropTypes.elementType,

  /*
   * Callback fired when an interaction that would require a deletion is detected
   */
  onDelete: PropTypes.func,
};

export default Chip;
