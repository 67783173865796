import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/styles';
import numeral from 'numeral';
import React from 'react';

import FacebookFlag from '../../../components/FacebookFlag';
import InstagramFlag from '../../../components/InstagramFlag';
import SectionTitle from '../../../components/SectionTitle';
import TwitchFlag from '../../../components/TwitchFlag';
import TwitterFlag from '../../../components/TwitterFlag';
import YoutubeFlag from '../../../components/YoutubeFlag';
import {
  calculateAMA,
  calculateYoutubeEngagement,
} from '../../../helpers/enhance-influencers';

const useStyles = makeStyles({
  statsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '0 10px',
    marginTop: 18,
  },
});

const BoxInfo = ({ label, number, info, format, formatSuffix }) => {
  return (
    <div>
      <Box textAlign="left">
        <Typography variant="caption" color="textSecondary">
          {label}
        </Typography>
        <Tooltip
          placement="top"
          style={{ padding: '3px', marginTop: '-3px' }}
          title={info}
        >
          <IconButton>
            <InfoOutlinedIcon className="fsz-def" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box textAlign="left">
        <Typography style={{ fontWeight: 'bold', fontSize: 30 }}>
          {numeral(number).format(format || '0.[0]a')}
          {formatSuffix}
        </Typography>
      </Box>
    </div>
  );
};

const InfluencerTabOverview = (props) => {
  const classes = useStyles();

  const {
    influencerYoutubeStreamer,
    influencerTwitchStreamer,
    influencer,
    instagramUser,
    twitterProfile,
    facebookPage,
  } = props;

  const { no_followers: instagramFollowers, no_posts: instagramPosts } =
    instagramUser;

  const {
    avg_viewers_days_90,
    peak_viewers_days_90,
    stream_time_days_90,
    total_watch_time_days_90,
  } = influencerTwitchStreamer || {};

  const {
    followers_count: twitterFollowersCount,
    following_count: twitterFollowingCount,
    // likes: twitterLikes,
    likes_90: twitterLikes90,
    posts_no_90: postsNo90,
    // replies_90: twitterReplies90,
    retweets_90: twitterRetweets90,
    quotes_90: twitterQuotes90,
  } = twitterProfile || {};

  const { total_followers, view_count: viewCountTwitch } =
    influencerTwitchStreamer;

  const { twitch_rate, youtube_rate } = influencer || {};

  const {
    avg_view_count_per_video,
    avg_comments_per_video,
    subscriber_count,
    likes_90,
    view_count: viewCountYoutube,
  } = influencerYoutubeStreamer;

  const { likes: facebookLikes } = facebookPage || {};

  return (
    <Grid container direction="column" wrap="nowrap" spacing={4}>
      <Grid item>
        <SectionTitle>Channels Performance</SectionTitle>
      </Grid>

      {influencerTwitchStreamer && influencerTwitchStreamer.login && (
        <Grid item>
          <Card>
            <CardContent>
              <TwitchFlag />
              <div className={classes.statsContainer}>
                <BoxInfo
                  label="FOLLOWERS"
                  info="Total followers."
                  number={total_followers}
                />
                <BoxInfo
                  label="TOTAL VIEWS"
                  info="Total lifetime views."
                  number={viewCountTwitch}
                />
                <BoxInfo
                  label="AVG. CCV"
                  info="Average number of viewers who watched the stream live over the last 90 days."
                  number={avg_viewers_days_90}
                />
                <BoxInfo
                  label="PEAK CCV"
                  info="Maximum number of viewers who watched the stream live over the last 90 days."
                  number={peak_viewers_days_90}
                />
                <BoxInfo
                  label="Duration hrs."
                  info="Total number of streamed hours over the last 90 days."
                  number={stream_time_days_90}
                />
                <BoxInfo
                  label="HRS. WATCHED"
                  info="Total number of hours watched by the audience over the last 90 days."
                  number={total_watch_time_days_90}
                />
                <BoxInfo
                  label="AMA"
                  info="Average minute audience (AMA) in the last 90 days is calculated by dividing the total minutes watched by the total minutes broadcasted."
                  format="0.[0]a"
                  number={calculateAMA(
                    total_watch_time_days_90,
                    stream_time_days_90
                  )}
                />
                <BoxInfo
                  label="EMV/VIDEO"
                  info="This is an estimation for the average media value per video on this channel. The value relies on the videos streamed in the last three months and it's calculated by valuing the price of impressions against a standardized CPM"
                  number={twitch_rate}
                  format="0,0"
                  formatSuffix="$"
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      )}

      {influencerYoutubeStreamer && influencerYoutubeStreamer.channel_id && (
        <Grid item>
          <Card>
            <CardContent>
              <YoutubeFlag />
              <div className={classes.statsContainer}>
                <BoxInfo
                  label="SUBSCRIBERS"
                  info="Total lifetime subscribers."
                  number={subscriber_count}
                />
                <BoxInfo
                  label="TOTAL VIEWS"
                  info="Total lifetime views."
                  number={viewCountYoutube}
                />
                <BoxInfo
                  label="AVG. VIEWS"
                  info="Average number of views per video in the last 90 days."
                  number={avg_view_count_per_video}
                />
                <BoxInfo
                  label="AVG. COMMENTS"
                  info="Average number of comments per video in the last 90 days."
                  number={avg_comments_per_video}
                />
                <BoxInfo
                  label="TOTAL LIKES"
                  info="The number of likes received on the videos posted in the last 90 days."
                  number={likes_90}
                />

                <BoxInfo
                  label="ENGAGEMENT RATE"
                  info="The engagement rate is based on the interactions (views, likes, and dislikes) to subscribers ratio and it is calculated for videos from the last 90 days."
                  format="0[.][0]%"
                  number={calculateYoutubeEngagement(influencerYoutubeStreamer)}
                />
                <BoxInfo
                  label="EMV/VIDEO"
                  info="This is an estimation for the average media value per video on this channel. The value relies on the videos streamed in the last three months and it's calculated by valuing the price of impressions against a standardized CPM"
                  number={youtube_rate}
                  format="0,0"
                  formatSuffix="$"
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      )}
      {twitterFollowersCount && (
        <>
          <Grid item>
            <Card>
              <CardContent>
                <TwitterFlag />
                <div className={classes.statsContainer}>
                  <BoxInfo
                    label="TOTAL FOLLOWERS"
                    info="Total followers."
                    number={twitterFollowersCount}
                  />
                  {twitterFollowingCount > 0 && (
                    <BoxInfo
                      label="TOTAL FOLLOWING"
                      info="Total number of followed accounts."
                      number={twitterFollowingCount}
                    />
                  )}
                  {/*
                    <BoxInfo
                      label='TOTAL LIKES'
                      info='Total likes.'
                      number={twitterLikes}
                    />
                  */}
                  {!!postsNo90 && (
                    <BoxInfo
                      label="# POSTS"
                      info="Number of twitter posts in the last 90 days."
                      number={postsNo90}
                    />
                  )}
                  {!!twitterLikes90 && (
                    <BoxInfo
                      label="LIKES"
                      info="Total likes in the last 90 days."
                      number={twitterLikes90}
                    />
                  )}
                  {!!twitterRetweets90 && (
                    <BoxInfo
                      label="RETWEETS"
                      info="Total retweets in the last 90 days."
                      number={twitterRetweets90 + twitterQuotes90}
                    />
                  )}
                  {/*
                    { twitterReplies90 && (
                      <BoxInfo
                        label='REPLIES'
                        info='Total replies in the last 90 days.'
                        number={twitterReplies90}
                      />
                    )}
                   */}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
      {instagramFollowers > 0 && (
        <>
          <Grid item>
            <Card>
              <CardContent>
                <InstagramFlag />
                <div className={classes.statsContainer}>
                  <BoxInfo
                    label="TOTAL FOLLOWERS"
                    info="Total followers."
                    number={instagramFollowers}
                  />
                  <BoxInfo
                    label="TOTAL POSTS"
                    info="Total number of posts"
                    number={instagramPosts}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
      {!isNaN(facebookLikes) && (
        <>
          <Grid item>
            <Card>
              <CardContent>
                <FacebookFlag />

                <Grid
                  container
                  justify="space-between"
                  spacing={4}
                  style={{ marginTop: 18 }}
                >
                  <Grid item>
                    <BoxInfo
                      label="TOTAL LIKES"
                      info="Total likes."
                      number={facebookLikes}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default InfluencerTabOverview;
