import { connect } from 'react-redux';
import { compose } from 'recompose';

import mapSelectorsToProps from '../../helpers/map-selectors-to-props';
import { selectCommunication } from '../../store/models/communication/communication.selectors';
import { selectMyBrands } from '../../store/models/my-brands/my-brands.selectors';

export default compose(
  connect(
    mapSelectorsToProps({
      brands: selectMyBrands,
      brandsCommunication: selectCommunication('brands'),
    })
  )
);
