import _orderBy from 'lodash/orderBy';
import memoize from 'proxy-memoize';

import { State } from '../../../../../store/types';
import { LoadingState, shouldFetch, shouldShowLoading } from '../../../types';
import { BrandFilter } from '../../types';
import { filterBrandDeliverables } from '../../utils';
import { ChatMentionDeliverableStructure, SLICE_NAME } from './types';

const memoizedFilteredChatMentionDeliverablesStructures = memoize(
  ({
    campaignId,
    onlyBrandDeliverables,
    brandIdFilter,
    state,
    sortBy,
  }: {
    campaignId: number;
    onlyBrandDeliverables: boolean;
    brandIdFilter: BrandFilter;
    state: State;
    sortBy?: {
      key: keyof ChatMentionDeliverableStructure;
      order: 'asc' | 'desc';
    };
  }) => {
    const allChatMentionDeliverables =
      state.campaigns.byId[campaignId]?.keyword_mention_deliverables ?? [];

    return _orderBy(
      filterBrandDeliverables(
        allChatMentionDeliverables,
        onlyBrandDeliverables,
        brandIdFilter
      ),
      [sortBy.key || 'keyword'],
      [sortBy.order]
    );
  }
);

export const selectFilteredChatMentionDeliverablesStructures =
  ({
    campaignId,
    onlyBrandDeliverables,
    brandIdFilter,
    sortBy,
  }: {
    campaignId: number;
    onlyBrandDeliverables: boolean;
    brandIdFilter: BrandFilter;
    sortBy?: {
      key: keyof ChatMentionDeliverableStructure;
      order: 'asc' | 'desc';
    };
  }) =>
  (state: State) =>
    memoizedFilteredChatMentionDeliverablesStructures({
      campaignId,
      onlyBrandDeliverables,
      brandIdFilter,
      state,
      sortBy,
    });

export const selectChatMentionDeliverableLoadingState =
  (chatMentionDeliverableId: number) => (state: State) =>
    state[SLICE_NAME].loadingMap[chatMentionDeliverableId] ?? LoadingState.IDLE;

export const selectChatMentionDeliverableShowLoading =
  (chatMentionDeliverableId: number) => (state: State) =>
    shouldShowLoading(
      selectChatMentionDeliverableLoadingState(chatMentionDeliverableId)(state)
    );

export const selectShouldFetchChatMentionDeliverable =
  (chatMentionDeliverableId: number) => (state: State) =>
    shouldFetch(
      selectChatMentionDeliverableLoadingState(chatMentionDeliverableId)(state)
    );

export const selectChatMentionDeliverableById =
  (chatMentionDeliverableId: number) => (state: State) =>
    state[SLICE_NAME].entities[chatMentionDeliverableId];

export const selectChatMentionDeliverablesError = () => (state: State) =>
  state[SLICE_NAME].error;

const memoizedAllChatMentionDeliverableIdsByCampaignId = memoize(
  ({ campaignId, state }: { campaignId: number; state: State }) =>
    state.campaigns.byId[campaignId]?.keyword_mention_deliverables.map(
      (deliverable) => deliverable.id
    ) ?? []
);

export const selectAllChatMentionDeliverableIdsByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedAllChatMentionDeliverableIdsByCampaignId({ campaignId, state });

// used by PDF report
const memoizedAllChatMentionDeliverablesByCampaignId = memoize(
  ({ campaignId, state }: { campaignId: number; state: State }) => {
    const allChatMentionDeliverableIds =
      selectAllChatMentionDeliverableIdsByCampaignId(campaignId)(state);

    return allChatMentionDeliverableIds.map((id) =>
      selectChatMentionDeliverableById(id)(state)
    );
  }
);

export const selectAllChatMentionDeliverablesByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedAllChatMentionDeliverablesByCampaignId({ campaignId, state });

export const selectAllChatMentionDeliverablesByCampaignIdShowLoading =
  (campaignId: number) => (state: State) => {
    const ids =
      selectAllChatMentionDeliverableIdsByCampaignId(campaignId)(state);

    return ids
      .map((id) => selectChatMentionDeliverableShowLoading(id)(state))
      .some((showLoading) => showLoading);
  };

const memoizedChatMentionDeliverablesToFetchByCampaignId = ({
  campaignId,
  state,
}: {
  campaignId: number;
  state: State;
}) => {
  const ids = selectAllChatMentionDeliverableIdsByCampaignId(campaignId)(state);

  return ids.filter((id) => selectShouldFetchChatMentionDeliverable(id)(state));
};

export const selectChatMentionDeliverablesToFetchByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedChatMentionDeliverablesToFetchByCampaignId({ campaignId, state });
