import {
  createEntityAdapter,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';

import { LoadingState } from '../../../types';
import { LoadingStateMap } from '../../Shoutouts/store/types';
import {
  deleteBannerVideo,
  deleteMultipleBannerVideos,
  fetchBannerDeliverable,
} from './BannerDeliverables.thunks';
import { BannerDeliverable, SLICE_NAME } from './types';

const bannerDeliverablesAdapter = createEntityAdapter<BannerDeliverable>();

export const initialState = bannerDeliverablesAdapter.getInitialState<{
  loadingMap: LoadingStateMap;
  error: SerializedError | null;
}>({ loadingMap: {}, error: null });

const bannerDeliverablesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannerDeliverable.pending, (state, action) => {
        state.loadingMap[action.meta.arg.bannerDeliverableId] =
          LoadingState.PENDING;
        state.error = null;
      })
      .addCase(fetchBannerDeliverable.fulfilled, (state, action) => {
        state.loadingMap[action.meta.arg.bannerDeliverableId] =
          LoadingState.FULLFILLED;
        state.error = null;

        bannerDeliverablesAdapter.addOne(state, action.payload);
      })
      .addCase(fetchBannerDeliverable.rejected, (state, action) => {
        state.loadingMap[action.meta.arg.bannerDeliverableId] =
          LoadingState.REJECTED;
        state.error = action.error;
      })
      .addCase(deleteBannerVideo.pending, (state, action) => {
        const { bannerDeliverableId, videoId } = action.meta.arg;

        const deliverable = state.entities[bannerDeliverableId];

        // filter out deleted video data
        deliverable.data_array = deliverable.data_array.filter(
          (bannerData) => bannerData.video_id !== videoId
        );

        delete deliverable.data[videoId];

        state.entities[bannerDeliverableId] = deliverable;
      })
      .addCase(deleteMultipleBannerVideos.fulfilled, (state, action) => {
        const { bannerDeliverableId, videoIds } = action.meta.arg;

        const deliverable = bannerDeliverablesAdapter
          .getSelectors()
          .selectById(state, bannerDeliverableId);

        const newBannersDataArray = deliverable.data_array.filter(
          (bannerData) => !videoIds.includes(bannerData.video_id)
        );

        bannerDeliverablesAdapter.updateOne(state, {
          id: bannerDeliverableId,
          changes: {
            data_array: newBannersDataArray,
          },
        });
      });
  },
});

export const { reducer } = bannerDeliverablesSlice;
