import { Box, Button, makeStyles } from '@material-ui/core';
import React, { Dispatch, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTwitterToken } from '../../../../ajax';
import facebook from '../../../../assets-2.0/images-influencer/facebook.svg';
import facebookOutline from '../../../../assets-2.0/images-influencer/facebookOutline.svg';
import instagram from '../../../../assets-2.0/images-influencer/Instagram.svg';
import instagramOutline from '../../../../assets-2.0/images-influencer/InstagramOutline.svg';
import tiktokOutline from '../../../../assets-2.0/images-influencer/tiktokOutline.svg';
import twitch from '../../../../assets-2.0/images-influencer/twitch.svg';
import twitchOutline from '../../../../assets-2.0/images-influencer/twitchOutline.svg';
import twitter from '../../../../assets-2.0/images-influencer/twitter.svg';
import twitterOutline from '../../../../assets-2.0/images-influencer/twitterOutline.svg';
import youtube from '../../../../assets-2.0/images-influencer/YouTube.svg';
import youtubeOutline from '../../../../assets-2.0/images-influencer/YouTubeOutline.svg';
import tiktokIcon from '../../../../assets-2.0/tiktok.svg';
import {
  INFLUENCERS_SIGNUP,
  PATH_SIGNIN_TWITTER_CALLBACK,
  PROFILE,
} from '../../../../common/constants/paths';
import {
  FACEBOOK_SCOPE,
  redirectTiktok,
  redirectTwitch,
  redirectTwitter,
  redirectYoutube,
} from '../../../../helpers/influencer-login';
import useEnableFacebook from '../../../../hooks/enable-facebook';
import actions from '../../../../store/actions';
import { LoadingState } from '../../../Campaign/types';
import { setSocialPostsPlatformFilter } from '../../Stats/SocialMedia/store/SocialMediaPosts.slice';
import { setStreamsPlatformFilter } from '../../Stats/Streaming/store/Streams.slice';
import {
  selectInfluencerDisconnectPlatformLoadingMap,
  selectInfluencerInfo,
} from '../../store/InfluencerInfo.selectors';
import { disconnectInfluencerSocialPlatform } from '../../store/InfluencerInfo.thunks';
import { InfluencerInfo, PlatformName } from '../../store/types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
    padding: theme.spacing(2),
    border: '1px solid #0000003B',
    borderRadius: 4,
    marginBottom: 30,
    height: 63,
  },
  socialsImg: {
    width: 24,
    marginRight: 20,
  },
  socialsImgMini: {
    width: 10,
    margin: 0,
  },
  connectImage: {
    backgroundRepeat: 'no-repeat',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginRight: 8,
    borderRadius: '100%',
  },
  contentBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  secondIconsBlock: {
    fontSize: 0,
    padding: 4,
    borderRadius: 5,
  },
  nameBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    '& p': {
      fontSize: 16,
      margin: 0,
      lineHeight: 1,
    },
  },
}));

const connectTwitter = (redirectTarget: string) => () =>
  getTwitterToken(
    `${PATH_SIGNIN_TWITTER_CALLBACK}?t=${encodeURI(redirectTarget)}`
  ).then((response) => {
    const { oauth_token } = response;

    redirectTwitter(oauth_token);
  });

const getPlatforms = (
  profilePage: boolean,
  {
    onboarding_twitch_pages,
    onboarding_youtube_pages,
    onboarding_twitter_pages,
    onboarding_facebook_pages,
    onboarding_instagram_pages,
    onboarding_tiktok_pages,
  }: Partial<InfluencerInfo>,
  onDisconnect: (id: number, platform: PlatformName) => void,
  dispatch: Dispatch<any>
) => {
  const redirectTarget = profilePage ? PROFILE : INFLUENCERS_SIGNUP;

  const twitchAccount = onboarding_twitch_pages?.[0] ?? null;
  const youtubeAccount = onboarding_youtube_pages?.[0] ?? null;
  const twitterAccount = onboarding_twitter_pages?.[0] ?? null;
  const facebookAccount = onboarding_facebook_pages?.[0] ?? null;
  const instagramAccount = onboarding_instagram_pages?.[0] ?? null;
  const tiktokAccount = onboarding_tiktok_pages?.[0] ?? null;

  const connectFacebook = () => {
    // @ts-ignore
    window.FB.login(
      (response) => {
        if (response.status !== 'connected') return;

        // @ts-ignore actions are imported using require(glob); this is very dumb and breaks ts
        dispatch(actions.signinFacebook(response.authResponse));
      },
      {
        scope: FACEBOOK_SCOPE,
      }
    );
  };

  return {
    twitch: {
      id: twitchAccount?.id,
      name: 'Twitch',
      icon: twitch,
      outlineIcon: twitchOutline,
      colorBackground: '#6E5593',
      connect: redirectTwitch(redirectTarget),
      connected: !!twitchAccount,
      accountImage: twitchAccount?.profile_image_url,
      disconnect: () => onDisconnect(twitchAccount?.id, 'twitch'),
      accountName: twitchAccount?.page_title,
    },
    youtube: {
      id: youtubeAccount?.id,
      name: 'YouTube',
      icon: youtube,
      outlineIcon: youtubeOutline,
      colorBackground: '#FF0000',
      connect: redirectYoutube(redirectTarget),
      connected: !!youtubeAccount,
      accountImage: youtubeAccount?.profile_image_url,
      disconnect: () => onDisconnect(youtubeAccount?.id, 'youtube'),
      accountName: youtubeAccount?.page_title,
    },
    twitter: {
      id: twitterAccount?.id,
      name: 'Twitter',
      icon: twitter,
      outlineIcon: twitterOutline,
      colorBackground: '#55ACEE',
      connect: connectTwitter(redirectTarget),
      connected: !!twitterAccount,
      accountImage: twitterAccount?.profile_image_url,
      disconnect: () => onDisconnect(twitterAccount?.id, 'twitter'),
      accountName: twitterAccount?.page_title,
    },
    facebook: {
      id: facebookAccount?.id,
      name: 'Facebook',
      icon: facebook,
      outlineIcon: facebookOutline,
      colorBackground: '#1977f2',
      connect: connectFacebook,
      connected: !!facebookAccount,
      accountImage: facebookAccount?.profile_image_url,
      disconnect: () => onDisconnect(facebookAccount?.id, 'facebook'),
      accountName: facebookAccount?.page_title,
    },
    instagram: {
      id: instagramAccount?.id,
      name: 'Instagram',
      icon: instagram,
      outlineIcon: instagramOutline,
      colorBackground: '#B0368B',
      connect: connectFacebook,
      connected: !!instagramAccount,
      accountImage: instagramAccount?.profile_image_url,
      disconnect: () => onDisconnect(instagramAccount?.id, 'instagram'),
      accountName: instagramAccount?.page_title,
    },
    tiktok: {
      colorBackground: '#000',
      id: tiktokAccount?.id,
      name: 'TikTok',
      icon: tiktokIcon,
      outlineIcon: tiktokOutline,
      connect: redirectTiktok(redirectTarget),
      connected: !!tiktokAccount,
      accountImage: tiktokAccount?.profile_image_url,
      disconnect: () => onDisconnect(tiktokAccount?.id, 'tiktok'),
      accountName: tiktokAccount?.page_title,
    },
  };
};

interface SocialAccountsFormProps {
  profilePage?: boolean;
}

const SocialAccountsForm = ({ profilePage }: SocialAccountsFormProps) => {
  useEnableFacebook();

  const classes = useStyles();
  const dispatch = useDispatch();

  const influencerInfo = useSelector(selectInfluencerInfo());

  const disconnectPlatformLoadingMap = useSelector(
    selectInfluencerDisconnectPlatformLoadingMap()
  );

  const onDisconnect = useCallback(
    (id: number, platform: PlatformName) => {
      dispatch(disconnectInfluencerSocialPlatform({ id, platform }));
      dispatch(setSocialPostsPlatformFilter('all'));
      dispatch(setStreamsPlatformFilter('all'));
    },
    [dispatch]
  );

  const platforms = useMemo(
    () =>
      getPlatforms(profilePage, influencerInfo ?? {}, onDisconnect, dispatch),
    [profilePage, influencerInfo, onDisconnect, dispatch]
  );

  return (
    <>
      {Object.keys(platforms).map((platformName) => {
        const platform = platforms[platformName];

        const disconnectLoading =
          disconnectPlatformLoadingMap[
            `${platformName.toLowerCase()}/${platform.id}`
          ] === LoadingState.PENDING;

        let buttonText = 'LOADING';
        let buttonColor = '#aaa';

        if (!disconnectLoading) {
          buttonText = platform.connected ? 'DISCONNECT' : 'CONNECT';
          buttonColor = platform.connected ? '#E94B4B' : '#0048F2';
        }

        return (
          <Box
            className={classes.container}
            style={{ paddingLeft: platform.connected && 12 }}
            key={platformName}
          >
            <div className={classes.contentBlock}>
              {!platform.connected ? (
                <img
                  className={classes.socialsImg}
                  src={platform.icon}
                  alt={platform.icon}
                />
              ) : (
                <div
                  className={classes.connectImage}
                  style={{
                    background: `url(${platform.accountImage})`,
                    backgroundSize: 'contain',
                  }}
                >
                  <div
                    className={classes.secondIconsBlock}
                    style={{ background: platform.colorBackground }}
                  >
                    <img
                      className={(classes.socialsImg, classes.socialsImgMini)}
                      src={
                        platform.outlineIcon
                          ? platform.outlineIcon
                          : platform.icon
                      }
                      alt={platform.icon}
                      style={
                        !platform.outlineIcon
                          ? { width: '16px', marginRight: '-4px' }
                          : {}
                      }
                    />
                  </div>
                </div>
              )}
              <div className={classes.nameBlock}>
                <span>{platform.name}</span>
                {platform.connected && platform.accountName && (
                  <p>{platform.accountName}</p>
                )}
              </div>
            </div>
            <Button
              type="button"
              color="primary"
              style={{
                color: buttonColor,
              }}
              variant="text"
              onClick={
                platform.connected ? platform.disconnect : platform.connect
              }
              disabled={disconnectLoading}
            >
              {buttonText}
            </Button>
          </Box>
        );
      })}
    </>
  );
};

export default SocialAccountsForm;
