import { Link, makeStyles } from '@material-ui/core';

import facebook from '../../../../../assets-2.0/images-influencer/facebook.svg';
import instagram from '../../../../../assets-2.0/images-influencer/Instagram.svg';
import twitch from '../../../../../assets-2.0/images-influencer/twitch.svg';
import twitter from '../../../../../assets-2.0/images-influencer/twitter.svg';
import youtube from '../../../../../assets-2.0/images-influencer/YouTube.svg';
import { ModalShell } from './ModalShell';

const useStyles = makeStyles((theme) => ({
  modal: {},
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    height: 56,
    width: 320,
  },
  socialsImg: {
    width: 24,
    marginRight: 20,
  },
}));

interface SocialAccountsModalProps {
  onClose: () => void;
  open: boolean;
}

const MEDIA_ACCOUNTS = [
  {
    id: 1,
    name: 'Twitch',
    icon: twitch,
  },
  {
    id: 2,
    name: 'YouTube',
    icon: youtube,
  },
  {
    id: 3,
    name: 'Twitter',
    icon: twitter,
  },
  {
    id: 4,
    name: 'Facebook',
    icon: facebook,
  },
  {
    id: 5,
    name: 'Instagram',
    icon: instagram,
  },
];

export const SocialAccountsModal = ({
  onClose,
  open,
}: SocialAccountsModalProps) => {
  const classes = useStyles();

  return (
    <ModalShell
      open={open}
      onClose={onClose}
      noActionButtons
      title="Add social account"
    >
      {MEDIA_ACCOUNTS.map((item) => (
        <div className={classes.container} key={item.id}>
          <img className={classes.socialsImg} alt={item.icon} src={item.icon} />
          <Link style={{ color: '#000000' }} href="#">
            <span>{item.name}</span>
          </Link>
        </div>
      ))}
    </ModalShell>
  );
};
