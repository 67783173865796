import { SerializedError } from '@reduxjs/toolkit';

import { LoadingState } from '../../../../../types';

export const SLICE_NAME = 'influencerAudience';

export type TopCountries = {
  [key: string]: number;
  other: number;
};

export type TopFollowed = {
  user: string;
  name: string;
  avatar_url: string;
  percentage: number;
};

export interface AudienceBrand {
  avatar_url: string;
  brand: string;
  main_category_label: string;
  mutual_followers: number;
  name: string;
  percentage: number;
}

export interface AudienceBrandCategory {
  category: string;
  count: number;
  percentage: number;
}

interface StreamPlatformData {
  ages: { [key: string]: number };
  audience_location: { [key: string]: number };
  genders: { 1: number; 0: number };
  languages: { [key: string]: number };
}

export interface AudienceData {
  twitter_data: {
    brand_categories: AudienceBrandCategory[];
    top_brands: AudienceBrand[];
    top_followed: TopFollowed[];
  };

  youtube_data: StreamPlatformData;

  twitch_data: StreamPlatformData;
}

export interface AudienceState {
  data: AudienceData | null;
  loadingState: LoadingState;
  error: SerializedError | null;
}
