import { actionChannel, delay, put, take } from 'redux-saga/effects';
import uuid from 'uuid/v4';

import overwriteDeep from '../../utils/overwrite-deep';
import { toastExpired, toastSpawned } from '../models/toast/toast.actions';

function* spawnToast(action) {
  const { meta } = action;

  const toastId = uuid();

  yield put(toastSpawned(overwriteDeep(meta.toast, { id: toastId })));

  yield delay(meta.toast.lifespan);

  yield put(toastExpired({ id: toastId }));
}

export default function* root() {
  const channel = yield actionChannel(({ meta }) => meta && meta.toast);

  while (true) {
    const action = yield take(channel);

    yield spawnToast(action);
  }
}
