import { createSlice } from '@reduxjs/toolkit';

import { CAMPAIGN_SOCIAL_POST_DELETE_SUCCEEDED } from '../../../../../store/action-types';
import { campaignSocialPostDeleteSucceeded } from '../../../../../store/models/campaigns/campaigns.actions';
import { LoadingState } from '../../../../../types';
import { SLICE_NAME } from '../../../types';
import {
  deleteSocialPostsAction,
  fetchSocialMediaPerformancePosts,
  fetchSocialMediaPerformanceSummary,
} from './SocialMediaPerformance.thunks';
import { getCacheKey, getPostId, SocialMediaPerformanceState } from './types';

const initialState: SocialMediaPerformanceState = {
  summary: null,
  summaryCacheKey: null,
  summaryLoadingState: LoadingState.IDLE,
  summaryError: null,

  posts: null,
  postsCacheKey: null,
  postsLoadingState: LoadingState.IDLE,
  postsError: null,
};

export const { reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // summary
    builder.addCase(
      fetchSocialMediaPerformanceSummary.pending,
      (state, action) => {
        state.summaryLoadingState = LoadingState.PENDING;
        state.summaryError = null;
        state.summaryCacheKey = getCacheKey(action.meta.arg);
      }
    );

    builder.addCase(
      fetchSocialMediaPerformanceSummary.fulfilled,
      (state, action) => {
        state.summaryLoadingState = LoadingState.FULFILLED;

        state.summary = action.payload;
      }
    );

    builder.addCase(
      fetchSocialMediaPerformanceSummary.rejected,
      (state, action) => {
        state.summaryLoadingState = LoadingState.REJECTED;
        state.summaryError = action.error;
      }
    );

    // posts
    builder.addCase(
      fetchSocialMediaPerformancePosts.pending,
      (state, action) => {
        state.postsLoadingState = LoadingState.PENDING;
        state.postsError = null;
        state.postsCacheKey = getCacheKey(action.meta.arg);
      }
    );

    builder.addCase(
      fetchSocialMediaPerformancePosts.fulfilled,
      (state, action) => {
        state.postsLoadingState = LoadingState.FULFILLED;

        state.posts = action.payload;
      }
    );

    builder.addCase(
      fetchSocialMediaPerformancePosts.rejected,
      (state, action) => {
        state.postsLoadingState = LoadingState.REJECTED;
        state.postsError = action.error;
      }
    );

    // Handle the delete post action
    builder.addCase(CAMPAIGN_SOCIAL_POST_DELETE_SUCCEEDED, (state, action) => {
      const typedAction = action as ReturnType<
        typeof campaignSocialPostDeleteSucceeded
      >;

      // Remove post from the posts array
      if (state.posts) {
        state.posts = state.posts.filter((post) => {
          const postId = getPostId(post);

          return postId !== typedAction.payload.postId;
        });
      }

      // Reset the cache key so that the next fetch will fetch the new data
      state.summaryLoadingState = LoadingState.IDLE;
      state.summaryError = null;
      state.summaryCacheKey = null;
    });

    builder.addCase(deleteSocialPostsAction.fulfilled, (state, action) => {
      const { postIds } = action.meta.arg;

      if (state.posts) {
        state.posts = state.posts.filter((post) => {
          const postId = getPostId(post);

          return !postIds.includes(postId);
        });
      }

      // Reset the cache key so that the next fetch will fetch the new data
      state.summaryLoadingState = LoadingState.IDLE;
      state.summaryError = null;
      state.summaryCacheKey = null;
    });
  },
});
