import {
  Button,
  Chip,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';
import { FieldArray, useFormikContext } from 'formik';
import React, { useState } from 'react';

import StructuredShoutoutEditor from './StructuredShoutoutEditor';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    paddingTop: 8,
    paddingLeft: -theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: 340,
  },
  structuredChip: {
    backgroundColor: '#dae5ff',
  },
}));

export const ShoutoutsV2 = ({
  disabled,
  brandForm,
  index,
  item,
  value,
  handleClick,
  handleDelete,
  brandId,
  isViewOnly,
  id,
}) => {
  const classes = useStyles();
  const [newShoutoutContent, setNewShoutoutContent] = useState('');
  const [newShoutoutModal, setNewShoutoutModal] = useState(false);
  const [duplicate, isDuplicate] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const onAddNotStructured = (arrayHelpers) => {
    const content = newShoutoutContent.split(',');
    content.forEach((el, index) => {
      content[index] = el.trim().toLowerCase();
    });
    new Set(content).forEach((newContent) => {
      newContent = newContent.trim();
      if (!newContent) return;
      if (brandForm) {
        if (!values.brands[index].shoutoutChecked)
          setFieldValue(`brands[${index}].shoutoutChecked`, true);
        if (
          values?.brands[index]?.shoutouts.filter(
            (shoutout) => shoutout.name === newContent
          ).length
        ) {
          isDuplicate(newContent);
        } else {
          arrayHelpers.push({
            name: newContent,
            inclusionTerms: [newContent],
            exclusionTerms: [],
            structured: false,
            v2: true,
            campaign_brand_id: brandId,
          });
          setNewShoutoutContent('');
          isDuplicate(false);
        }
      } else {
        if (!values.shoutoutChecked) setFieldValue('shoutoutChecked', true);
        if (
          values?.shoutouts
            .filter((el) => el.campaign_brand_id === brandId)
            .filter((shoutout) => shoutout.name === newContent).length
        ) {
          isDuplicate(newContent);
        } else {
          setFieldValue('shoutoutChecked', true);
          arrayHelpers.push({
            name: newContent,
            inclusionTerms: [newContent],
            exclusionTerms: [],
            structured: false,
            v2: true,
            campaign_brand_id: brandId,
          });
          setNewShoutoutContent('');
          isDuplicate(false);
        }
      }
    });
  };

  return (
    <FieldArray
      name={brandForm ? `brands[${index}].shoutouts` : 'shoutouts'}
      render={(arrayHelpers) => (
        <>
          {brandForm ? (
            <StructuredShoutoutEditor
              open={newShoutoutModal !== false}
              brandId={brandId}
              onClose={() => setNewShoutoutModal(false)}
              isViewOnly={isViewOnly}
              initialShoutout={
                newShoutoutModal !== true
                  ? newShoutoutModal !== false &&
                    values.brands[index].shoutouts[newShoutoutModal]
                  : null
              }
              onSave={(shoutout) => {
                if (newShoutoutModal === true) {
                  arrayHelpers.push({ ...shoutout, v2: true });
                  setFieldValue(`brands[${index}].shoutoutChecked`, true);
                  !brandId && brandForm && handleClick(item, value);
                } else {
                  setFieldValue(`brands[${index}].shoutoutChecked`, true);
                  handleClick(item, value);
                  arrayHelpers.replace(newShoutoutModal, {
                    ...shoutout,
                    v2: true,
                    id: values.brands[index].shoutouts[newShoutoutModal].id,
                  });
                }

                setNewShoutoutModal(false);
              }}
            />
          ) : (
            <StructuredShoutoutEditor
              isViewOnly={isViewOnly}
              open={newShoutoutModal !== false}
              onClose={() => setNewShoutoutModal(false)}
              brandId={brandId}
              initialShoutout={
                newShoutoutModal !== true
                  ? newShoutoutModal !== false &&
                    values.shoutouts[newShoutoutModal]
                  : null
              }
              onSave={(shoutout) => {
                if (newShoutoutModal === true) {
                  setFieldValue('shoutoutChecked', true);
                  arrayHelpers.push({ ...shoutout, v2: true });
                } else {
                  setFieldValue('shoutoutChecked', true);
                  arrayHelpers.replace(newShoutoutModal, {
                    ...shoutout,
                    v2: true,
                    id: values.shoutouts[newShoutoutModal].id,
                  });
                }

                setNewShoutoutModal(false);
              }}
            />
          )}

          <div>
            {!disabled && !isViewOnly && (
              <div className={classes.inputContainer}>
                <TextField
                  fullWidth
                  placeholder="Add keyword"
                  variant="outlined"
                  value={newShoutoutContent}
                  className={classes.input}
                  onChange={({ target: { value } }) => {
                    setNewShoutoutContent(value);
                    isDuplicate(false);
                  }}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        onAddNotStructured(arrayHelpers);
                        !brandId && brandForm && handleClick(item, value);
                      }
                    },
                    'data-id': `input-vm-${id}`,
                  }}
                />
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  style={{ padding: 9.5 }}
                  disabled={!newShoutoutContent.trim()}
                  onClick={() => {
                    onAddNotStructured(arrayHelpers);
                    !brandId && brandForm && handleClick(item, value);
                  }}
                  data-id={`button-vm-${id}`}
                >
                  Add
                </Button>
              </div>
            )}
            {disabled && (
              <Typography className="fs-i" color="textSecondary">
                This feature is disabled for your account. Please contact Aggero
                support to upgrade your plan.
              </Typography>
            )}
          </div>
          {duplicate && (
            <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
              "{duplicate}" is already added
            </p>
          )}
          {!disabled && !isViewOnly && (
            <Typography>
              <Link href="#" onClick={() => setNewShoutoutModal(true)}>
                Create structured query
              </Link>
            </Typography>
          )}

          <div component="ul" className={classes.chipsContainer}>
            {brandId ? (
              <>
                {values.shoutouts.map(
                  ({ name, structured, campaign_brand_id }, ind) => (
                    <>
                      {campaign_brand_id === brandId && (
                        <li key={ind} data-id={`chip-vm-${id}-${ind}`}>
                          <Chip
                            label={
                              structured ? `${name} (click to edit)` : name
                            }
                            variant="outlined"
                            className={cx(classes.chip, {
                              [classes.structuredChip]: structured,
                            })}
                            onClick={
                              structured ? () => setNewShoutoutModal(ind) : null
                            }
                            onDelete={
                              isViewOnly
                                ? undefined
                                : () => {
                                    if (values.shoutouts.length === 1)
                                      setFieldValue(
                                        brandForm
                                          ? `brands[${index}].shoutoutChecked`
                                          : 'shoutoutChecked',
                                        false
                                      );
                                    arrayHelpers.remove(ind);
                                    name === newShoutoutContent &&
                                      isDuplicate(false);
                                  }
                            }
                          />
                        </li>
                      )}
                    </>
                  )
                )}
              </>
            ) : (
              <>
                {brandForm ? (
                  <>
                    {values.brands[index].shoutouts.map(
                      ({ name, structured }, i) => (
                        <li key={i} data-id={`chip-vm-${id}-${i}`}>
                          <Chip
                            label={
                              structured ? `${name} (click to edit)` : name
                            }
                            variant="outlined"
                            className={cx(classes.chip, {
                              [classes.structuredChip]: structured,
                            })}
                            onClick={
                              structured ? () => setNewShoutoutModal(i) : null
                            }
                            onDelete={
                              isViewOnly
                                ? undefined
                                : () => {
                                    arrayHelpers.remove(i);
                                    if (
                                      values.brands[index].shoutouts.length ===
                                      1
                                    ) {
                                      setFieldValue(
                                        `brands[${index}].shoutoutChecked`,
                                        false
                                      );
                                      handleDelete(
                                        item,
                                        `shoutoutChecked`,
                                        index
                                      );
                                    }
                                    name === newShoutoutContent &&
                                      isDuplicate(false);
                                  }
                            }
                          />
                        </li>
                      )
                    )}
                  </>
                ) : (
                  <>
                    {values.shoutouts.map(
                      ({ name, structured, campaign_brand_id }, ind) => (
                        <>
                          {!campaign_brand_id && (
                            <li key={ind} data-id={`chip-vm-${id}-${ind}`}>
                              <Chip
                                label={
                                  structured ? `${name} (click to edit)` : name
                                }
                                variant="outlined"
                                className={cx(classes.chip, {
                                  [classes.structuredChip]: structured,
                                })}
                                onClick={
                                  structured
                                    ? () => setNewShoutoutModal(ind)
                                    : null
                                }
                                onDelete={
                                  isViewOnly
                                    ? undefined
                                    : () => {
                                        if (values.shoutouts.length === 1)
                                          setFieldValue(
                                            brandForm
                                              ? `brands[${index}].shoutoutChecked`
                                              : 'shoutoutChecked',
                                            false
                                          );
                                        arrayHelpers.remove(ind);
                                        name === newShoutoutContent &&
                                          isDuplicate(false);
                                      }
                                }
                              />
                            </li>
                          )}
                        </>
                      )
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    />
  );
};

export default ShoutoutsV2;
