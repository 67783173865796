import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  ShowChart as LineChartIcon,
} from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import moment from 'moment';
import numeral from 'numeral';
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';

import CardTitle from '../../../components/CardTitle';

const useStyles = makeStyles((theme) => ({
  red: {
    color: '#f44336',
    margin: 0,
  },
  green: {
    color: '#4caf50',
    margin: 0,
  },
}));

const StyledToggleButton = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    border: 'none',
    background: '#ddd',
    '&:hover': {
      background: '#e4e4e4',
    },
    '&.Mui-selected': {
      background: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
  },
}))(ToggleButton);

const FollowersGrowthChart = ({ platform, stats }) => {
  const label = platform === 'youtube' ? 'subscribers' : 'followers';
  const classes = useStyles();

  const [chartType, setChartType] = useState('line');

  const mappedStats = stats
    .slice(1, 8)
    .map((s) => ({ count: s.value, atTime: moment(s.date).format('Do MMMM') }));

  const deltaStats = stats.slice(1, 8).map((v, idx) => {
    return {
      count: v.value - stats[idx].value,
      atTime: moment(v.date).format('Do MMMM'),
    };
  });

  const firstStat = stats[0];
  const lastStat = stats[stats.length - 1];
  const countDiff = lastStat.value - firstStat.value;
  const countPer = (countDiff / firstStat.value) * 100;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} wrap="nowrap" direction="column">
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            align="start"
          >
            <Grid item>
              <CardTitle>{label} growth</CardTitle>
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                exclusive
                size="small"
                value={chartType}
                onChange={(_, v) => v && setChartType(v)}
              >
                <StyledToggleButton value="line">
                  <LineChartIcon />
                </StyledToggleButton>
                <StyledToggleButton value="bar">
                  <BarChartIcon />
                </StyledToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={deltaStats.length > 1 ? 3 : 12}>
              <Box
                fontWeight="bold"
                fontSize="1em"
                height={26}
                textAlign="center"
              >
                {countDiff >= 1 && (
                  <p className={classes.green}>
                    <span className="triangle triangle-green" />+
                    {numeral(countPer).format('0,0.[000]')}%
                  </p>
                )}
                {countDiff < 1 && (
                  <p className={classes.red}>
                    <span className="triangle triangle-red" />
                    {numeral(countPer).format('0,0.[000]')}%
                  </p>
                )}
              </Box>

              <Box fontSize="3em" textAlign="center" fontWeight="bold">
                {`${numeral(countDiff).format('0,0.[000]')}`}
              </Box>

              <Box clone fontSize="1em">
                <Typography
                  align="center"
                  variant="caption"
                  display="block"
                  color="textSecondary"
                >
                  {label}
                  &nbsp;
                  {countDiff >= 1 && 'gained'}
                  {countDiff < 1 && 'lost'}
                </Typography>
              </Box>
            </Grid>
            {deltaStats.length > 1 && (
              <Grid item xs={9}>
                <Bar
                  data={{
                    datasets: [
                      {
                        data: mappedStats.map(({ count }) => count),
                        borderColor: '#2096f3',
                        backgroundColor: 'rgba(76, 175, 80, .05)',
                        hidden: chartType !== 'line',
                        label,
                        type: 'line',
                      },
                      {
                        data: deltaStats.map(({ count }) => count),
                        backgroundColor: '#2096f3',
                        hidden: chartType !== 'bar',
                        label: `${label} gained/lost`,
                      },
                    ],
                    labels: deltaStats.map(({ atTime }) => atTime),
                  }}
                  options={{
                    legend: {
                      display: false,
                    },
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            callback: (label) =>
                              numeral(label).format('0.[0]a'),
                          },
                        },
                      ],
                    },
                  }}
                  height={200}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FollowersGrowthChart;
