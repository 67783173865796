import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { array, object, string } from 'yup';

import * as regex from '../../../../common/constants/regex';
import { getUserOrganizationRestrictions } from '../../../../common/containers/Account/selectors';
import { brandUpdateFormSubmitted } from '../../../../store/events';
import { getTrialAccount } from '../../../../store/models/user/user.selectors';
import getImageSize from '../../../../utils/getImageSize';

const mapPropsToValues = ({ brand = {} }) => {
  const { name } = brand;
  return {
    brandName: name,

    shoutouts: brand
      ? (brand.organization_brand_shoutouts || [])
          .filter((d) => !d.v2)
          .map(
            ({
              v2,
              name,
              content,
              inclusion_terms,
              exclusion_terms,
              structured,
              id,
            }) => {
              return {
                structured,
                name,
                inclusionTerms: inclusion_terms,
                exclusionTerms: exclusion_terms,
                id,
                v2,
              };
            }
          )
      : [],
    newLink: '',
    links:
      brand && brand.organization_brand_link_trackings
        ? brand.organization_brand_link_trackings.map((l) => l.link)
        : [],
    newSocialMediaKeyword: '',
    socialMediaKeywords: brand
      ? Array.from(
          new Set(
            (brand.organization_brand_social_media_mentions || []).map(
              (d) => d.keyword
            )
          )
        )
      : [],
    newChatMentionKeyword: '',
    chatMentionKeywords:
      brand && brand.organization_brand_keyword_mentions
        ? brand.organization_brand_keyword_mentions.map((d) => d.keyword)
        : [],

    newBannerUrl: '',
    banners:
      brand && brand.organization_brand_banners
        ? brand.organization_brand_banners.map((d) => ({
            bannerUrl: d.image || d.raw_banner_url,
            rawBanner: d.raw_banner_url,
            id: d.id,
          }))
        : [],
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    trialAccount: getTrialAccount(state),
    organizationRestrictions: getUserOrganizationRestrictions(state),
  };
};

export default compose(
  connect(mapStateToProps, null),
  withFormik({
    mapPropsToValues,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: object().shape({
      brandName: string().required('Choose a name for your campaign'),
      newLink: string().matches(regex.URL, 'Not a valid url'),
      banners: array().of(
        object().test(
          'minimum-size',
          'Image has to be at least 60x60 pixels.',
          async (banner) => {
            if ((!banner.bannerUrl && !banner.bannerFile) || banner.id)
              return true;

            const { width, height } = await getImageSize(
              banner.bannerUrl && banner.bannerUrl.length > 0
                ? banner.bannerUrl
                : banner.bannerFile
            );

            if (width < 60 || height < 60) return false;

            return true;
          }
        )
      ),
    }),
    handleSubmit: (values, bag) => {
      const {
        props: {
          brand: {
            id,
            name,
            organization_brand_shoutouts,
            organization_brand_keyword_mentions,
            organization_brand_banners,
            organization_brand_link_trackings,
            organization_brand_social_media_mentions,
          },
        },
      } = bag;
      const deliverables = {};
      const allShoutoutDeliverables = organization_brand_shoutouts;
      const shoutoutDeliverablesV2 = allShoutoutDeliverables;
      const newShoutoutDeliverablesV2 = values.shoutouts;

      if (name) {
        deliverables.name = values.brandName;
      }
      if (organization_brand_link_trackings) {
        const links = new Set(values.links);
        const newLinks = new Set(values.links);

        deliverables.organization_brand_link_trackings_attributes =
          organization_brand_link_trackings
            .map((d) => {
              const bareDeliverable = { id: d.id, keyword: d.keyword };

              if (links.has(d.link)) {
                newLinks.delete(d.link);
                return bareDeliverable;
              }

              return { ...bareDeliverable, _destroy: true };
            })
            .concat(Array.from(newLinks).map((link) => ({ link })));
      } else {
        deliverables.organization_brand_link_trackings_attributes =
          values.links.map((link) => ({
            link,
          }));
      }

      if (shoutoutDeliverablesV2 && shoutoutDeliverablesV2.length) {
        const deliverablesToRemove = new Set(
          shoutoutDeliverablesV2.filter((d) => d.id).map((d) => d.id)
        );

        deliverables.organization_brand_shoutouts_attributes =
          newShoutoutDeliverablesV2
            .map((d) => {
              deliverablesToRemove.delete(d.id);

              return {
                name: d.name,
                inclusion_terms: d.inclusionTerms,
                exclusion_terms: d.exclusionTerms,
                structured: d.structured,
                id: d.id,
              };
            })
            .concat(
              Array.from(deliverablesToRemove).map((id) => ({
                id,
                _destroy: true,
              }))
            );
      } else {
        deliverables.organization_brand_shoutouts_attributes =
          newShoutoutDeliverablesV2.map((d) => ({
            name: d.name,
            inclusion_terms: d.inclusionTerms,
            exclusion_terms: d.exclusionTerms,
            structured: d.structured,
          }));
      }
      if (organization_brand_social_media_mentions) {
        const keywords = new Set(values.socialMediaKeywords);
        const newKeywords = new Set(values.socialMediaKeywords);

        deliverables.organization_brand_social_media_mentions_attributes =
          organization_brand_social_media_mentions
            .map((d) => {
              const bareDeliverable = { id: d.id, keyword: d.keyword };

              if (keywords.has(d.keyword)) {
                newKeywords.delete(d.keyword);
                return bareDeliverable;
              }

              return { ...bareDeliverable, _destroy: true };
            })
            .concat(Array.from(newKeywords).map((keyword) => ({ keyword })));
      } else {
        deliverables.twitter_keyword_mention_deliverables_attributes =
          values.socialMediaKeywords.map((keyword) => ({ keyword }));
      }

      if (organization_brand_keyword_mentions) {
        const keywords = new Set(values.chatMentionKeywords);
        const newKeywords = new Set(values.chatMentionKeywords);

        deliverables.organization_brand_keyword_mentions_attributes =
          organization_brand_keyword_mentions
            .map((d) => {
              const bareDeliverable = { id: d.id, keyword: d.keyword };

              if (keywords.has(d.keyword)) {
                newKeywords.delete(d.keyword);
                return bareDeliverable;
              }

              return { ...bareDeliverable, _destroy: true };
            })
            .concat(Array.from(newKeywords).map((keyword) => ({ keyword })));
      } else {
        deliverables.organization_brand_keyword_mentions_attributes =
          values.chatMentionKeywords.map((keyword) => ({ keyword }));
      }

      const bannersToDestroy = new Set(
        (organization_brand_banners || []).map((d) => d.id)
      );

      deliverables.organization_brand_banners_attributes = values.banners
        .map((banner) => {
          const { id, bannerUrl, raw_banner_url, bannerFile } = banner;

          if (id) {
            bannersToDestroy.delete(id);

            return {
              id,
              raw_banner_url: raw_banner_url,
              image: !!raw_banner_url ? undefined : bannerUrl,
            };
          }

          if (bannerUrl) return { image: bannerUrl };

          return { image: bannerFile };
        })
        .concat(
          Array.from(bannersToDestroy).map((id) => ({ id, _destroy: true }))
        );

      bag.props.dispatch(
        brandUpdateFormSubmitted({
          id,
          deliverables,
        })
      );
    },
    enableReinitialize: true,
  })
);
