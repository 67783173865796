import { Mercator } from '@visx/geo';
import { ScaleLinear } from 'd3-scale';
import React from 'react';
import * as topojson from 'topojson-client';

import type { AudienceChoroplethProps } from './AudienceChoropleth';
import topology from './world-topo.json';

interface FeatureShape {
  type: 'Feature';
  id: string;
  geometry: { coordinates: [number, number][][]; type: 'Polygon' };
  properties: { name: string };
}

// @ts-ignore
const world = topojson.feature(topology, topology.objects.units) as {
  type: 'FeatureCollection';
  features: FeatureShape[];
};

export interface MapProps {
  width: number;
  height: number;
  colorScale: ScaleLinear<string, string>;
  data: AudienceChoroplethProps['topCountries'];
  onHoverCountry: (
    event: React.MouseEvent | React.TouchEvent,
    countryId: string
  ) => void;
  onHideTooltip: () => void;
}

export const Map = React.memo(
  React.forwardRef<SVGSVGElement, MapProps>(
    (
      { width, height, colorScale, data, onHideTooltip, onHoverCountry },
      ref
    ) => {
      const centerX = width / 2;
      const centerY = height / 2;
      const scale = (width / 630) * 100;

      return (
        <svg width={width} height={height} ref={ref}>
          <rect x={0} y={0} width={width} height={height} fill="#fff" />
          <Mercator<FeatureShape>
            data={world.features}
            scale={scale}
            translate={[centerX, centerY + 50]}
          >
            {(mercator) => (
              <g>
                {mercator.features.map(({ feature, path }, i) => {
                  let color = 'rgb(221, 221, 221)';

                  if (feature.id in data) {
                    color = colorScale(data[feature.id]);
                  }

                  const handleMouseMove = (event) =>
                    onHoverCountry(event, feature.id);

                  return (
                    <path
                      key={`map-feature-${i}`}
                      d={path || ''}
                      fill={color}
                      stroke="#fff"
                      strokeWidth={0.5}
                      onMouseLeave={onHideTooltip}
                      onMouseMove={handleMouseMove}
                      onTouchMove={handleMouseMove}
                      onTouchEnd={onHideTooltip}
                    />
                  );
                })}
              </g>
            )}
          </Mercator>
        </svg>
      );
    }
  )
);
