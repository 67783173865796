import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { influencerOnboardingApi } from '../views/CampaignInfluencerOnboarding/store/api';
import analyticsMiddleware from './middlewares/analytics';
import httpErrorMiddleware from './middlewares/http-error';
import missingDataMiddleware from './middlewares/missing-data';
import { isAggeroUser } from './models/user/user.selectors';
import makeRootReducer from './reducers';
import rootSaga from './sagas';

export const history = createBrowserHistory();

let prevPath = null;

// listen and notify Segment of client-side page updates
history.listen((location) => {
  if (
    location.pathname !== prevPath &&
    window.analytics != null &&
    !isAggeroUser(store.getState())
  ) {
    prevPath = location.pathname;
    window.analytics.page();
  }
});

// ======================================================
// Middleware Configuration
// ======================================================

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  httpErrorMiddleware,
  analyticsMiddleware,
  missingDataMiddleware,
  sagaMiddleware,
  routerMiddleware(history),
  influencerOnboardingApi.middleware,
];

// ======================================================
// Store Initialization
// ======================================================

export const store = configureStore({
  reducer: makeRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // redux persist dispatches non-serializable actions
      serializableCheck: false,
    }).concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
