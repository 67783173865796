export const DEFAULT_PARAMS = {
  network: 'twitch',
  influencer_followers_range: [500, 17e6],
  influencer_game_title_or_sector: '',
  streamer_language: '',
  country: '',
  streamer_talking_about: [],
  streamer_interest_categories: [],

  game_categories: [],
  main_games: [],

  min_ccv: '',
  max_ccv: '',
  min_avg_view_count_per_video: '',
  max_avg_view_count_per_video: '',

  audience_age_groups: [],
  audience_gender: '',
  audience_country: '',
  audience_talking_about: [],
  audience_interest_keywords: [],
  audience_language: [],
  audience_interest_categories: [],

  campaign_goal: 'best_fit',
  search_watchlist: false,
  main_game_only: false,
  // campaign_budget: '',
};

export default DEFAULT_PARAMS;
