import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

export const TranscriptAccordion = ({ transcript }) => {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Show Transcript</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{transcript}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
