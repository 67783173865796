import React from 'react';

import TwitchFlag from '../../../components/TwitchFlag';
import TwitterFlag from '../../../components/TwitterFlag';
import YoutubeFlag from '../../../components/YoutubeFlag';

const InfluencerPlatformSelect = (props) => {
  const { onSelect, activePlatform, activePlatforms = [] } = props;

  return (
    <p className="influencer-platform-select">
      Show statistics for:
      {activePlatforms.indexOf('twitch') !== -1 && (
        <button
          className={activePlatform === 'twitch' ? 'active-platform' : null}
          onClick={() => onSelect('twitch')}
        >
          <TwitchFlag />
        </button>
      )}
      {activePlatforms.indexOf('youtube') !== -1 && (
        <button
          className={activePlatform === 'youtube' ? 'active-platform' : null}
          onClick={() => onSelect('youtube')}
        >
          <YoutubeFlag />
        </button>
      )}
      {activePlatforms.indexOf('twitter') !== -1 && (
        <button
          className={activePlatform === 'twitter' ? 'active-platform' : null}
          onClick={() => onSelect('twitter')}
        >
          <TwitterFlag />
        </button>
      )}
    </p>
  );
};

export default InfluencerPlatformSelect;
