import { STORAGE_PREFIX as prefix } from './redux';

export const wasLoggedOut = (state) =>
  !state[prefix].authInProgress && !state[prefix].authUser;
export const getAuthInProgress = (state) => state[prefix].authInProgress;
export const getUser = (state) => state[prefix].authUser;
export const getUserName = (state) =>
  state[prefix].authUser ? state[prefix].authUser.displayName || '' : '';

export const getUserEmail = (state) =>
  state[prefix].authUser ? state[prefix].authUser.email || '' : '';
export const getUserImage = (state) =>
  state[prefix].authUser ? state[prefix].authUser.photoURL || '' : '';

export const onboardingCompleted = (state) =>
  !!state[prefix].data.onboarding_email &&
  !!state[prefix].data.first_name &&
  !!state[prefix].data.last_name &&
  !!state[prefix].data.location &&
  !!state[prefix].data.date_of_birth &&
  !!state[prefix].data.gender;

export const getUserOrganizationRestrictions = ({ account }) => {
  let restrictions = {};

  if (account && account.organization) {
    restrictions.ui_disable_shoutouts =
      !!account.organization.ui_disable_shoutouts;
    restrictions.ui_disable_banners = !!account.organization.ui_disable_banners;
    restrictions.ui_banner_limit_per_campaign =
      account.organization.ui_banner_limit_per_campaign;
  }

  return restrictions;
};

export const onboardingForm = (state) => state[prefix].data;

export const onboardingLoaded = (state) =>
  Object.keys(state[prefix].data).length > 1;
