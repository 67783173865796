import { call, put } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import {
  watchlistsRequestFailed,
  watchlistsRequestSucceeded,
} from '../models/watchlists/watchlists.actions';

export default function* requestWatchlists() {
  try {
    const { watchlists } = yield call(ajax.getWatchlists);

    yield put(
      watchlistsRequestSucceeded({
        watchlists: watchlists.map((watchlist) => ({
          ...watchlist,
          influencers: watchlist.records_count,
        })),
        loaded: true,
      })
    );
  } catch (error) {
    yield put(watchlistsRequestFailed({ error }));
  }
}
