import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { INFLUENCERS_STATS } from '../../../common/constants/paths';
import { InfluencerCard } from '../../../components/InfluencerCard/InfluencerCard';
import { InfluencerOverviewSkeleton } from '../../../components/InfluencerOverviewSkeleton/InfluencerOverviewSkeleton';
import { selectInfluencerInfo } from '../store/InfluencerInfo.selectors';
import InfluencerDatePicker from '../utils/InfluencerDatePicker';
import { Audience } from './Audience/Audience';
import { Overview } from './Overview/Overview';
import { SocialMediaTab } from './SocialMedia/SocialMediaTab';
import { StatsPlatformDropdown } from './StatsPlatformDropdown';
import { Streaming } from './Streaming/Streaming';
import { VideoTypeToggle } from './Streaming/VideoTypeToggle';

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100%',
    overflow: 'auto',
    padding: '32px 16px',
  },
  topBarContainer: {
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    position: 'absolute',
  },
  tabs: {
    color: '#333',
    borderTop: '1px solid #e4e4e4',
    borderBottom: '1px solid #e4e4e4',
    display: 'flex',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabsWrapper: {
    display: 'flex',
    flex: 1,
  },
  paper: {
    padding: 16,
    background: theme.palette.background.default,
  },
  select: {
    minWidth: 120,
  },
  button: {
    padding: theme.spacing(2),
    background: 'none',
    fontSize: 16,
    color: 'black',
    textTransform: 'uppercase',
    border: 'none',
    borderBottom: `2px solid transparent`,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    minWidth: 175,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      padding: 6,
      minWidth: 'unset',
      display: 'inline-block',
      whiteSpace: 'nowrap',
    },
  },
  active: {
    borderColor: theme.palette.primary.main,
  },
  menuDiv: {
    display: 'flex',
  },
  mainGrids: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0px 16px 0px !important',
    },
  },
  platformFilterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  platformFilterLabel: {
    marginRight: 8,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },

  videoFilterContainer: {
    display: 'flex',
    marginRight: 40,
    alignSelf: 'center',

    [theme.breakpoints.down('sm')]: {
      marginRight: 5,
    },
  },
}));

export type StatsTab = 'overview' | 'audience' | 'streaming' | 'social-media';
type Tab = {
  key: StatsTab;
  title: string;
  display: boolean;
};

export const Stats = () => {
  const classes = useStyles();
  const influencerInfo = useSelector(selectInfluencerInfo());

  const { tab } = useParams<{ tab: StatsTab }>();

  const activeTab = tab ?? 'overview';
  const history = useHistory();

  const setActiveTab = useCallback(
    (tab: StatsTab) =>
      history.push(`${INFLUENCERS_STATS}/${tab === 'overview' ? '' : tab}`),
    [history]
  );

  const tabHasPlatformFilter = tab === 'streaming' || tab === 'social-media';

  const tabs: Tab[] = [
    {
      key: 'overview',
      title: 'Overview',
      display: true,
    },
    {
      key: 'audience',
      title: 'Audience',
      display: true,
    },
    {
      key: 'streaming',
      title: 'Streaming',
      display: true,
    },
    {
      key: 'social-media',
      title: 'Social Media',
      display: true,
    },
  ];

  function changeTab(key: StatsTab) {
    setActiveTab(key);

    const elem = document.getElementsByClassName('main-container')[0];
    if (elem) {
      elem.scrollTo(0, 0);
    }
  }

  function getInfluencerTabsMenu() {
    return (
      <div className={classes.tabs}>
        <div className={classes.tabsWrapper}>
          {tabs
            .filter(({ display }) => display)
            .map(({ key, title }, index) => {
              return (
                <button
                  key={index}
                  onClick={() => changeTab(key)}
                  className={clsx(classes.button, {
                    [classes.active]: activeTab === key,
                  })}
                >
                  {title}
                </button>
              );
            })}
        </div>
        {activeTab === 'streaming' && (
          <div className={classes.videoFilterContainer}>
            <VideoTypeToggle />
          </div>
        )}
        {tabHasPlatformFilter && (
          <div className={classes.platformFilterContainer}>
            <Typography className={classes.platformFilterLabel}>
              PLATFORM:
            </Typography>
            <StatsPlatformDropdown activeTab={activeTab} />
          </div>
        )}
      </div>
    );
  }

  function getActiveTab() {
    switch (activeTab) {
      case 'overview': {
        return <Overview {...influencerInfo} />;
      }

      case 'audience': {
        return <Audience />;
      }

      case 'streaming': {
        return <Streaming />;
      }

      case 'social-media': {
        return <SocialMediaTab />;
      }

      default: {
        return <Redirect to={INFLUENCERS_STATS} />;
      }
    }
  }

  return (
    <>
      <main
        className={clsx('influencer-page', classes.main)}
        style={{ paddingTop: tabHasPlatformFilter ? 30 : 89 }}
      >
        <Grid container spacing={4} direction="column" wrap="nowrap">
          <Grid item className={classes.mainGrids}>
            {tabHasPlatformFilter && <InfluencerDatePicker />}
            {influencerInfo ? (
              <InfluencerCard {...influencerInfo} />
            ) : (
              <InfluencerOverviewSkeleton />
            )}
            {getInfluencerTabsMenu()}
          </Grid>

          <Grid item className={classes.mainGrids}>
            {getActiveTab()}
          </Grid>
        </Grid>
      </main>
    </>
  );
};
