import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core';
import { React, useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getQuotaUsage } from '../../../ajax';
import Icon from '../../../assets-2.0/Icon.svg';
import { LoadingState } from '../../Campaign/types';

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  popup: {
    '& .MuiPaper-rounded': {
      borderRadius: '10px !important',
    },
    '& .MuiPaper-root': {
      backgroundColor: '#bcdce8',
    },
  },
}));

const Usage = (props) => {
  document.title = 'My Aggero - Usage';

  const classes = useStyles();
  const [loadingState, setLoadingState] = useState(LoadingState.PENDING);
  const [usageData, setUsageData] = useState({
    total_hours: 0,
    consumed_hours: 0,
    remaining_hours: 0,
    consumed_hours_percentage: 0,
  });

  useEffect(() => {
    const fetchUsageHours = async () => {
      const usage_data = await getQuotaUsage();
      setUsageData(usage_data);
      setLoadingState(LoadingState.FULFILLED);
    };

    fetchUsageHours();
  }, []);

  const upgradeClicked = () => {
    setOpen(true);

    window.analytics &&
      window.analytics.track('Upgrade Plan Clicked', {
        user_id: props.authUser.id,
        organization_id: props.organization.id,
        organization_name: props.organization.name,
      });
  };

  let formatStartDate, formatEndDate = null;

  if (usageData.contract_start_date && usageData.contract_end_date) {
    formatStartDate = new Date(usageData.contract_start_date);
    formatEndDate = new Date(usageData.contract_end_date);
  }

  const [open, setOpen] = useState(false);

  return (
    <Container style={{ marginTop: '12px' }}>
      <Grid container direction="row" justifyContent="space-between">
        <div>
          <Typography
            variant="h2"
            style={{
              margin: '32px 0 8px 0',
              fontWeight: 'bold',
              fontSize: '1.3rem',
              textTransform: 'uppercase',
            }}
          >
            Usage Summary
          </Typography>
          { formatStartDate && formatEndDate && (
              <Typography
                variant="h5"
                style={{
                  fontSize: '0.8rem',
                  color: '#666666',
                }}
              >
                {
                  moment(formatStartDate).year() !== moment(formatEndDate).year() ?
                    moment(formatStartDate).format('Do MMMM YYYY') :
                    moment(formatStartDate).format('Do MMMM')
                } -
                {moment(formatEndDate).format(' Do MMMM YYYY')}
              </Typography>
            )
          }
        </div>
        {loadingState === LoadingState.FULFILLED && (
          <Button
            id="popup"
            variant="outlined"
            color="primary"
            style={{
              margin: '16px 0',
              maxHeight: '2.75rem',
              maxWidth: '11rem',
              color: '#0049f2',
              borderRadius: '0.25rem',
              backgroundColor: 'white',
            }}
            onClick={() => upgradeClicked()}
          >
            <Typography
              style={{
                fontWeight: 'Regular',
                color: '#347cff',
                fontSize: '0.8rem',
                textTransform: 'uppercase',
              }}
            >
              UPGRADE YOUR PLAN
            </Typography>
          </Button>
        )}
      </Grid>

      <Popover
        className={classes.popup}
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={document.getElementById('popup')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Grid
          container
          style={{
            fontWeight: 'Regular',
            padding: '1rem',
            fontSize: '1rem',
            height: '130px',
            flexDirection: 'column',
          }}
        >
          <Grid container justifyContent="space-between">
            <Typography
              style={{
                textTransform: 'uppercase',
                color: '#000',
              }}
            >
              Upgrade your plan
            </Typography>
            <button
              onClick={() => setOpen(false)}
              style={{ border: 'none', background: 'transparent' }}
            >
              <img
                src={Icon}
                alt={'close'}
                style={{
                  height: '24px',
                  width: '24px',
                }}
              />
            </button>
          </Grid>
          <Typography style={{ marginTop: '1rem' }}>
            Thanks for getting in touch.
          </Typography>
          <Typography>
            Someone from our team will contact you right away!
          </Typography>
        </Grid>
      </Popover>
      {loadingState === LoadingState.PENDING && (
        <Paper
          style={{
            marginTop: '16px',
            padding: '24px',
            textAlign: 'center',
          }}
        >
          <CircularProgress />
        </Paper>
      )}
      {loadingState === LoadingState.FULFILLED && (
        <>
          <Paper
            style={{
              marginTop: '12px',
              padding: '24px 16px',
            }}
          >
            <Grid container>
              <Grid
                container
                item
                xs
                direction="row"
                justifyContent="space-between"
              >
                <Grid>
                  <Typography
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    Image Tracking - Usage to date
                  </Typography>
                </Grid>
                <Grid>
                  <Typography style={{ fontSize: '1.2rem' }}>
                    {usageData.banner_consumed_hours}
                    <span style={{ color: '#666666' }}>
                      /{usageData.banner_hours} Hours
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        ({usageData.banner_consumed_percentage}
                        %)
                      </span>{' '}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: '#000',
                      fontStyle: 'italic',
                      fontSize: '12px',
                      textAlign: 'end',
                    }}
                  >
                    Based on your service plan
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs>
                  <ProgressBar
                    style={{
                      margin: '16px 0px 16px 0px',
                      height: '1.5rem',
                      borderRadius: '1.25rem',
                    }}
                    now={(usageData.banner_consumed_hours * 100) / usageData.banner_hours}
                    visuallyHidden
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs
              direction="row"
              justifyContent="space-between"
            >
              <Grid>
                <Typography
                  variant="h2"
                  style={{
                    fontWeight: 'Bold',
                    fontSize: '1.6rem',
                    textDecoration: 'none solid',
                  }}
                >
                  {usageData.banner_consumed_hours} Hrs
                </Typography>
                <Typography variant="subtitle1" style={{ color: '#666666' }}>
                  Consumed
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  variant="h2"
                  style={{
                    fontWeight: 'Light',
                    fontSize: '1.6rem',
                    textDecoration: 'none solid',
                  }}
                >
                  {usageData.banner_remaining_hours} Hrs
                </Typography>
                <Typography variant="subtitle1" style={{ color: '#666666' }}>
                  Remaining
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            style={{
              marginTop: '12px',
              padding: '24px 16px',
            }}
          >
            <Grid container>
              <Grid
                container
                item
                xs
                direction="row"
                justifyContent="space-between"
              >
                <Grid>
                  <Typography
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    Voice Tracking - Usage to date
                  </Typography>
                </Grid>
                <Grid>
                  <Typography style={{ fontSize: '1.2rem' }}>
                    {usageData.shoutout_consumed_hours}
                    <span style={{ color: '#666666' }}>
                      /{usageData.shoutout_hours} Hours
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        ({usageData.shoutout_consumed_percentage}
                        %)
                      </span>{' '}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: '#000',
                      fontStyle: 'italic',
                      fontSize: '12px',
                      textAlign: 'end',
                    }}
                  >
                    Based on your service plan
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs>
                  <ProgressBar
                    style={{
                      margin: '16px 0px 16px 0px',
                      height: '1.5rem',
                      borderRadius: '1.25rem',
                    }}
                    now={(usageData.shoutout_consumed_hours * 100) / usageData.shoutout_hours}
                    visuallyHidden
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs
              direction="row"
              justifyContent="space-between"
            >
              <Grid>
                <Typography
                  variant="h2"
                  style={{
                    fontWeight: 'Bold',
                    fontSize: '1.6rem',
                    textDecoration: 'none solid',
                  }}
                >
                  {usageData.shoutout_consumed_hours} Hrs
                </Typography>
                <Typography variant="subtitle1" style={{ color: '#666666' }}>
                  Consumed
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  variant="h2"
                  style={{
                    fontWeight: 'Light',
                    fontSize: '1.6rem',
                    textDecoration: 'none solid',
                  }}
                >
                  {usageData.shoutout_remaining_hours} Hrs
                </Typography>
                <Typography variant="subtitle1" style={{ color: '#666666' }}>
                  Remaining
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    account: { authUser, organization },
  } = state;

  return {
    authUser,
    organization,
  };
};

export default compose(connect(mapStateToProps, null))(Usage);
