import { ComponentMeta, ComponentStory } from '@storybook/react';
import React from 'react';

import { AudienceBrandAffinities } from './AudienceBrandAffinities';

// More on default export: https://storybook.js.org/docs/react/writing-stories/introduction#default-export
export default {
  title: 'AudienceBrandAffinities',
  component: AudienceBrandAffinities,
} as ComponentMeta<typeof AudienceBrandAffinities>;

export const TOP_BRANDS = [
  {
    brand: 'gfuelenergy',
    name: 'G Fuel',
    main_category_label: 'Energy drinks',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1399790641849176064/wLPsldXm_normal.jpg',
    mutual_followers: 239798,
    percentage: 17.407356593182037,
  },
  {
    brand: 'seaofthieves',
    name: 'Sea of thieves',
    main_category_label: 'VR',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1404126835013734401/ZKtSnX07_normal.jpg',
    mutual_followers: 26352,
    percentage: 1.912937809921405,
  },
  {
    brand: 'espn',
    name: 'ESPN',
    main_category_label: 'TV',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1170690523201527808/FriNRiir_normal.png',
    mutual_followers: 80945,
    percentage: 5.875939246512147,
  },
  {
    brand: 'fortnitegame',
    name: 'Fortnite',
    main_category_label: 'Video Games',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1402151305976729603/c6WBIx4V_normal.jpg',
    mutual_followers: 669025,
    percentage: 48.56569589718685,
  },
  {
    brand: 'nfl',
    name: 'NFL',
    main_category_label: 'Football',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1399696850798710786/09t5pXh8_normal.jpg',
    mutual_followers: 72774,
    percentage: 5.282792052945519,
  },
  {
    brand: 'rocketleague',
    name: 'Rocket League',
    main_category_label: 'Action',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1375608776330371072/BJuqlcVy_normal.jpg',
    mutual_followers: 51857,
    percentage: 3.764390407145351,
  },
  {
    brand: 'turtlebeach',
    name: 'Turtle Beach',
    main_category_label: 'Gaming peripherals',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1408123893139841025/kjDl86CT_normal.png',
    mutual_followers: 60692,
    percentage: 4.4057385230627615,
  },
  {
    brand: 'disney',
    name: 'Disney',
    main_category_label: 'Entertainment',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1289584734494892032/Z1TjPU-z_normal.jpg',
    mutual_followers: 27044,
    percentage: 1.9631713012869791,
  },
  {
    brand: 'disneyplus',
    name: 'Disney Plus',
    main_category_label: 'Video streaming',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1299466541021327362/0h66D1Zg_normal.jpg',
    mutual_followers: 27152,
    percentage: 1.971011210344034,
  },
  {
    brand: 'fallguysgame',
    name: 'Fall Guys',
    main_category_label: 'VR',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1408387558812405762/pOxkz5r5_normal.png',
    mutual_followers: 46036,
    percentage: 3.341833827320196,
  },
  {
    brand: 'playstation',
    name: 'PlayStation',
    main_category_label: 'Game consoles',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1278183948279922690/ybnDHXn7_normal.jpg',
    mutual_followers: 263145,
    percentage: 19.102156192765943,
  },
  {
    brand: 'pokemon',
    name: 'Pokemon Sword/Shield',
    main_category_label: 'Video Games',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1409529990459260935/rHjmbMV5_normal.jpg',
    mutual_followers: 90983,
    percentage: 6.604615238315087,
  },
  {
    brand: 'twitch',
    name: 'Twitch.tv',
    main_category_label: 'Video streaming',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1399764861022728193/WNobJuMG_normal.png',
    mutual_followers: 411848,
    percentage: 29.896767271573726,
  },
  {
    brand: 'twitter',
    name: 'Twitter',
    main_category_label: 'Social Media',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1354479643882004483/Btnfm47p_normal.jpg',
    mutual_followers: 141405,
    percentage: 10.264836483452347,
  },
  {
    brand: 'xbox',
    name: 'Xbox',
    main_category_label: 'Game consoles',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1400217045027876876/sxiunvsr_normal.jpg',
    mutual_followers: 223075,
    percentage: 16.193404749097503,
  },
  {
    brand: 'youtube',
    name: 'YouTube',
    main_category_label: 'Video streaming',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1398259947847233538/JXNWY7iv_normal.jpg',
    mutual_followers: 190836,
    percentage: 13.853119303816076,
  },
  {
    brand: 'asus_rog',
    name: 'Asus',
    main_category_label: 'Gaming components',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1346369825019371525/awnlfQQs_normal.jpg',
    mutual_followers: 95328,
    percentage: 6.920026394360492,
  },
  {
    brand: 'paladinsgame',
    name: 'Paladins',
    main_category_label: 'FPS',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1397565117877870594/jgnVCENl_normal.jpg',
    mutual_followers: 21131,
    percentage: 1.5339362804132213,
  },
  {
    brand: 'steelseriesfr',
    name: 'SteelSeries',
    main_category_label: 'Gaming peripherals',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1257321523053858816/6LVGB9zI_normal.jpg',
    mutual_followers: 95498,
    percentage: 6.932366991950301,
  },
  {
    brand: 'logitechgfrance',
    name: 'Logitech',
    main_category_label: 'Gaming peripherals',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1400356677745004544/oGzr8yMl_normal.jpg',
    mutual_followers: 148207,
    percentage: 10.758605570545752,
  },
  {
    brand: 'amazonmusic',
    name: 'Amazon MP3',
    main_category_label: 'Music',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1400442319824883713/su1ZXUS0_normal.jpg',
    mutual_followers: 6120,
    percentage: 0.4442615132331132,
  },
  {
    brand: 'rockstargames',
    name: 'Grand Theft Auto V',
    main_category_label: 'Action',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1336687116214996993/2njl7BCT_normal.jpg',
    mutual_followers: 197510,
    percentage: 14.33759664684186,
  },
  {
    brand: 'activision',
    name: 'activision',
    main_category_label: 'Game developer',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1402290887271403523/PZMWMH80_normal.jpg',
    mutual_followers: 86441,
    percentage: 6.274903507415611,
  },
  {
    brand: 'assassinscreed',
    name: "Assassin's Creed Origins",
    main_category_label: 'Action',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1377702585839325187/OuofeWc5_normal.jpg',
    mutual_followers: 55597,
    percentage: 4.035883554121143,
  },
  {
    brand: 'csgo',
    name: 'Counter-Strike Online',
    main_category_label: 'FPS',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1389342981107318785/AL7Ha5E4_normal.jpg',
    mutual_followers: 67276,
    percentage: 4.883682608541,
  },
  {
    brand: 'deadbybhvr',
    name: 'Dead by Daylight',
    main_category_label: 'Action',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1400162045174165505/rfo_-iNu_normal.jpg',
    mutual_followers: 23734,
    percentage: 1.722892607038351,
  },
  {
    brand: 'doritos',
    name: 'Doritos',
    main_category_label: 'Baked goods',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1170018877423738881/EMSvVLxz_normal.jpg',
    mutual_followers: 19479,
    percentage: 1.414014708540492,
  },
  {
    brand: 'halo',
    name: 'Halo: The Master Chief Collection',
    main_category_label: 'FPS',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1392938142957637632/5ucWD7Mk_normal.jpg',
    mutual_followers: 54624,
    percentage: 3.96525178085712,
  },
  {
    brand: 'minecraft',
    name: 'Minecraft',
    main_category_label: 'Video Games',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1405183053241094144/ygBkpGHW_normal.jpg',
    mutual_followers: 61922,
    percentage: 4.495026376212555,
  },
  {
    brand: 'nasa',
    name: 'nasa',
    main_category_label: 'Aerospace',
    avatar_url:
      'http://pbs.twimg.com/profile_images/1321163587679784960/0ZxKlEKB_normal.jpg',
    mutual_followers: 104270,
    percentage: 7.56914182758443,
  },
];

export const TOP_CATEGORIES = [
  {
    category: 'Video Games',
    count: 864778,
    percentage: 62.77574883835051,
  },
  {
    category: 'Shooter',
    count: 812267,
    percentage: 58.963883426359665,
  },
  {
    category: 'Video streaming',
    count: 487423,
    percentage: 35.38288881774898,
  },
  {
    category: 'FPS',
    count: 410954,
    percentage: 29.83187024660144,
  },
  {
    category: 'Gaming peripherals',
    count: 357830,
    percentage: 25.975506091536744,
  },
  {
    category: 'Action',
    count: 354586,
    percentage: 25.740018452822984,
  },
  {
    category: 'Game consoles',
    count: 334767,
    percentage: 24.301322549102874,
  },
  {
    category: 'Game developer',
    count: 323450,
    percentage: 23.479801708374257,
  },
  {
    category: 'Adventure Game',
    count: 296464,
    percentage: 21.52084072861792,
  },
  {
    category: 'Open World',
    count: 268542,
    percentage: 19.4939338703671,
  },
];

const Template: ComponentStory<typeof AudienceBrandAffinities> = (arg) => {
  return <AudienceBrandAffinities {...arg} />;
};

export const Primary = Template.bind({});
Primary.args = {
  topBrands: TOP_BRANDS,
  topCategories: TOP_CATEGORIES,
};
