import { State } from '../../../../../store/types';
import { LoadingState } from '../../../../../types';
import { SLICE_NAME } from './types';

export const selectStreamsOverview = () => (state: State) => {
  const { facebook, twitch, youtube } = state[SLICE_NAME]?.overview || {};

  const avgCCV = twitch?.avg_ccv || null;
  const comments =
    (twitch?.comments || 0) +
    (facebook?.comments || 0) +
    (youtube?.comments || 0);
  const hrsStreamed =
    (twitch?.hrs_streamed || 0) +
    (youtube?.hrs_streamed || 0) +
    (facebook?.hrs_streamed || 0);
  const hrsWatched =
    (twitch?.hrs_watched || 0) +
    (youtube?.hrs_watched || 0) +
    (facebook?.hrs_watched || 0);
  const streamsCount =
    (twitch?.streams_count || 0) +
    (youtube?.streams_count || 0) +
    (facebook?.streams_count || 0);
  const totalViews =
    (twitch?.total_views || 0) +
    (youtube?.total_views || 0) +
    (facebook?.total_views || 0);

  const streamsCountByPlatform = {
    twitch: twitch?.streams_count || 0,
    youtube: youtube?.streams_count || 0,
    facebook: facebook?.streams_count || 0,
  };

  const emv = null; // missing from the API
  const engagement = null; // missing from the API
  const followersGained = null; // missing from the API
  const peakCCV = null; // missing from the API

  return {
    avgCCV,
    comments,
    emv,
    engagement,
    followersGained,
    hrsStreamed,
    hrsWatched,
    streamsCount,
    totalViews,
    streamsCountByPlatform,
    peakCCV,
  };
};

export const selectStreamsAreLoading = () => (state: State) => {
  return (
    state[SLICE_NAME].loading === LoadingState.PENDING ||
    state[SLICE_NAME].loading === LoadingState.IDLE
  );
};

export const selectStreamsHasError = () => (state: State) =>
  state[SLICE_NAME].loading === LoadingState.REJECTED;

export const selectShouldFetchStreams = () => (state: State) =>
  state[SLICE_NAME].loading === LoadingState.IDLE;

export const selectStreamsPlatformFilter = () => (state: State) =>
  state[SLICE_NAME].platformFilter;

export const selectStreamsVideoTypeFilter = () => (state: State) =>
  state[SLICE_NAME].videoTypeFilter;
