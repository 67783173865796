import moment from 'moment';

import { getSentiment } from '../../../helpers/get-sentiment';
import { computeSummary as computeStreamOverview } from '../PerformanceTab/Streams/SummaryTable';
import { videoIsFromStreamingPlatform } from '../utils';
import computeBannerStats from '../utils/computeBannerStats';
import { computeInfluencerSocialPostDeliverableData } from '../utils/computeInfluencerSocialPostDeliverableData';
import { computeOverviewChatMentionData } from '../utils/computeOverviewChatMentionData';
import { computeOverviewShoutoutData } from '../utils/computeOverviewShoutoutData';
import { getCampaignStartEndDates } from '../utils/timeFrameUtils';

const computeOverview = (campaign) => {
  const {
    videos,
    banner_deliverables,
    shoutout_deliverables,
    social_media_mention_deliverables,
    keyword_mention_deliverables,
  } = campaign;

  const rows = [];

  const timeFrame = getCampaignStartEndDates(campaign);

  // Only use the videos from the streaming platforms
  const streamVideos = videos.filter((video) =>
    videoIsFromStreamingPlatform(video)
  );

  // videos
  rows.push(['Streams/Videos']);
  rows.push([
    'Total number of Streams/Videos',
    'Average Engagement Rate',
    'Average CCV',
    'Peak CCV',
    'Total Views',
    'Total Hours Watched',
    'Total No. of Comments',
    'Total Media Value',
  ]);

  const {
    streams,
    hoursWatched,
    comments,
    engagementRate,
    totalViews,
    avgCCV,
    peakCCV,
    totalEMV,
  } = computeStreamOverview({ usedVideos: streamVideos });

  rows.push(
    [
      streams,
      engagementRate,
      avgCCV,
      peakCCV,
      totalViews,
      hoursWatched,
      comments,
      totalEMV,
    ],
    [],
    []
  );

  shoutout_deliverables.forEach((shoutoutDeliverable) => {
    const { content, totalMentions, videos, avgCCV, peakCCV, totalEMV } =
      computeOverviewShoutoutData({
        shoutoutDeliverable,
        timeFrame,
        campaign,
      });
    rows.push(
      [`"${content}" Voice Mentions`],
      [
        'Total Number of Voice Mentions',
        'Total Number of Streams/Videos',
        'Average CCV',
        'Peak CCV',
        'Media Value',
      ],
      [totalMentions, videos, avgCCV || 0, peakCCV, totalEMV],
      [],
      []
    );
  });

  banner_deliverables.forEach((bannerDeliverable) => {
    const usedVideosId = videos.map(({ id }) => id);

    const {
      image,
      raw_banner_image,
      timeOnScreen,
      avgCCViewers,
      usedVideosForBannerDeliverable,
      peakCCViewers,
      totalEmv,
    } = computeBannerStats({
      usedVideos: videos,
      bannerDeliverable,
      usedVideosId,
    });

    rows.push(
      ['Banner presence'],
      [image || raw_banner_image],
      [
        'Total Number of Streams/Videos',
        'Time On-Screen',
        'Average CCV',
        'Peak CCV',
        'Media Value',
      ],
      [
        usedVideosForBannerDeliverable.length,
        moment
          .duration(timeOnScreen, 'seconds')
          .format('HH[h] : mm[m] : ss[s]'),
        avgCCViewers,
        peakCCViewers,
        totalEmv,
      ],
      [],
      []
    );
  });

  Array.from(new Set(social_media_mention_deliverables || []))
    .map(({ keyword }) => keyword)
    .forEach((keyword) => {
      const {
        totalMentions,
        twitterMentions,
        instagramMentions,
        tiktokMentions,
        facebookMentions,
        totalEngagements,
        totalImpressions,
        totalEMV,
      } = computeInfluencerSocialPostDeliverableData({
        campaign,
        keyword,
        timeFrame,
      });

      rows.push(
        [`"${keyword}" Social Posts Deliverables`],
        [
          'Total number of mentions',
          'Twitter Mentions',
          'Instagram Mentions',
          'TikTok Mentions',
          'Facebook Mentions',
          'Engagements',
          'Impressions',
          'Media Value',
        ],
        [
          totalMentions,
          twitterMentions,
          instagramMentions,
          tiktokMentions,
          facebookMentions,
          totalEngagements,
          totalImpressions,
          totalEMV,
        ],
        [],
        []
      );
    });

  keyword_mention_deliverables.forEach((keywordMentionDeliverable) => {
    const { numberOfMentions, liveStreams, keyword, videoComments } =
      computeOverviewChatMentionData({
        timeFrame,
        keywordMentionDeliverable,
        campaign,
      });

    rows.push(
      [`"${keyword}" Chat Mentions`],
      [
        'Total number of mentions',
        'Number of Streams/Videos',
        'No. of Positive Mentions',
        'No. of Negative Mentions',
        'No. of Neutral Mentions',
      ],
      [
        numberOfMentions,
        liveStreams,
        videoComments.filter((c) => getSentiment(c.sentiment) === 1).length,
        videoComments.filter((c) => getSentiment(c.sentiment) === 2).length,
        videoComments.filter((c) => getSentiment(c.sentiment) === 3).length,
      ],
      [],
      []
    );
  });

  return rows;
};

export default computeOverview;
