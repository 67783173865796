import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import TwitterIcon from '../assets-2.0/twitter.svg';

const useStyles = makeStyles({
  text: {
    color: '#00acee',
    fontSize: 16,
    display: 'inline-block',
  },
  iconImage: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
});

function TwitterFlag(props) {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.text}>
      <img src={TwitterIcon} className={classes.iconImage} alt="Twitter icon" />
      Twitter
    </Typography>
  );
}

export default TwitterFlag;
