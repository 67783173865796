import qs from 'query-string';
import { compose, lifecycle, withHandlers } from 'recompose';

import { UserIsNotAuthenticated } from '../../../../common/auth';
import { PATH_SIGNIN } from '../../../../common/constants/paths';
import { Dialog } from '../../../../common/containers';
import { history } from '../../../../store';
import { actions } from '../../../../store/actions';

const withDidMount = lifecycle({
  componentDidMount() {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);

    if (!query['token']) {
      Dialog.show({
        caption: 'Invalid Reset Password Link',
        message: 'Please click the link in your email to reset your password!',
        buttons: ['Got it'],
      }).then(() => history.push(PATH_SIGNIN));
    }
  },
});

export default compose(
  UserIsNotAuthenticated,
  withDidMount,
  withHandlers({
    onSubmitFail: () => () => {
      Dialog.show({
        caption: 'Failed',
        message:
          'Please fill all required fields. Password must match password confirmation!',
        buttons: ['Got it'],
      });
    },
    resetPasswordSubmit:
      ({ dispatch }) =>
      async ({ password }) => {
        const query = qs.parse(window.location.search);
        const token = query['token'];

        dispatch(actions.resetPassword(password, token));
      },
  })
);
