import { useEffect } from 'react';

export const useScrollToActivePoint = (activePoint, setActivePoint) => {
  useEffect(() => {
    if (!activePoint) return;

    const element = document.getElementById(activePoint);

    if (!element) return;

    element.scrollIntoView(true);

    setActivePoint(null);
  });
};

export default useScrollToActivePoint;
