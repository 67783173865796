import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import * as paths from '../../common/constants/paths';
import { Dialog } from '../../common/containers';
import { enhanceInfluencerData } from '../../helpers/enhance-influencers';
import { history } from '../../store';
import {
  MY_WATCHLIST_MOUNTED_TWITCH,
  MY_WATCHLIST_MOUNTED_YOUTUBE,
} from '../../store/action-types';
import { actions } from '../../store/actions';
import {
  myWatchlistMountedTwitch,
  myWatchlistMountedYoutube,
} from '../../store/events';
import { actionTypes } from './redux';

function* getMyWatchlistYoutubeData({ payload: watchlistId }) {
  try {
    yield put(actions.myWatchlistDataYoutubeRequest(watchlistId));

    const influencers = yield call(ajax.getMyWatchlistData, {
      network: 'youtube',
      watchlistId,
    });

    enhanceInfluencerData(influencers);

    yield call(ajax.recordUserSession, { action: 'check-watchlist-youtube' });

    yield put(actions.myWatchlistDataYoutubeSuccess(influencers));
  } catch (e) {
    console.error(e);

    Dialog.show({
      caption: 'Failed to get the watchlist',
      message: (e.bodyJson && e.bodyJson.errors) || e.message,
      buttons: ['Got it'],
    });
  }
}

function* getMyWatchlistTwitchData({ payload: watchlistId }) {
  try {
    yield put(actions.myWatchlistDataTwitchRequest(watchlistId));

    const influencers = yield call(ajax.getMyWatchlistData, {
      network: 'twitch',
      watchlistId,
    });

    enhanceInfluencerData(influencers);

    yield call(ajax.recordUserSession, { action: 'check-watchlist-twitch' });

    yield put(actions.myWatchlistDataTwitchSuccess(influencers));
  } catch (e) {
    console.error(e);

    Dialog.show({
      caption: 'Failed to get the watchlist',
      message: (e.bodyJson && e.bodyJson.errors) || e.message,
      buttons: ['Got it'],
    });
  }
}

function* myWatchlistAddSubmit({
  urls,
  withDialog = true,
  watchlistId,
  network,
}) {
  try {
    const response = yield call(ajax.myWatchlistAddCreator, urls, watchlistId);
    const { fetching } = response;

    yield put(actions.myWatchlistAddSuccess(network, watchlistId));

    if (network === 'twitch') yield put(myWatchlistMountedTwitch(watchlistId));
    else yield put(myWatchlistMountedYoutube(watchlistId));

    // Reload the watchlist ids for user
    const { watched_influencers } = yield call(ajax.getUserData);
    yield put(actions.userGet({ watched_influencers }));

    if (fetching) {
      Dialog.show({
        caption: 'Success!',
        message:
          'We are collecting and analyzing the data for the creator you added. This might take a while.',
        buttons: ['Got it'],
      }).then(() =>
        history.push(`${paths.MY_WATCHLIST}/${watchlistId}/${network}`)
      );
    }

    if (!fetching && withDialog) {
      Dialog.show({
        caption: 'Success!',
        message: 'The creator was added to your watchlist.',
        buttons: ['Got it'],
      }).then(() =>
        history.push(`${paths.MY_WATCHLIST}/${watchlistId}/${network}`)
      );
    }
  } catch (e) {
    console.error(e);

    let message = 'Something went wrong. Please try again later!';

    if (e.bodyJson && e.bodyJson.errors && e.bodyJson.errors.length) {
      message = e.bodyJson.errors[0];
    }

    Dialog.show({
      caption: 'Failed',
      message,
      buttons: ['Got it'],
    });

    yield put(actions.myWatchlistAddError());
  }
}

function* myWatchlistRemoveInfluencer({ watchlistId, influencerId }) {
  try {
    yield put(
      actions.myWatchlistRemoveInfluencerSucceeded(influencerId, watchlistId)
    );

    yield call(ajax.removeInfluencerFromWatchlist, influencerId, watchlistId);
  } catch (err) {
    yield put(
      actions.myWatchlistRemoveInfluencerFailed(influencerId, watchlistId, err)
    );
    Dialog.show({
      caption: 'Failed',
      message: 'Failed to remove influencer from the watchlist',
      buttons: ['Got it'],
    });
  }
}

export default function* root() {
  yield all([
    takeEvery(
      actionTypes.MY_WATCHLIST_REMOVE_INFLUENCER,
      myWatchlistRemoveInfluencer
    ),
    takeLatest(MY_WATCHLIST_MOUNTED_YOUTUBE, getMyWatchlistYoutubeData),
    takeLatest(MY_WATCHLIST_MOUNTED_TWITCH, getMyWatchlistTwitchData),
    takeLatest(actionTypes.MY_WATCHLIST_ADD_REQUEST, myWatchlistAddSubmit),
  ]);
}
