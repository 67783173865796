import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { isInfluencerView } from '../common/containers/Config/selectors';
import { getAuthUser, isAggeroUser } from '../store/models/user/user.selectors';

interface UserInfo {
  id: string;
  displayName: string;
  email: string;
  organization_id: number;
}

let isLogRocketEnabled = false;

export const useLogRocket = (enableLoggedOut: boolean) => {
  const isAggeroEmail: boolean = useSelector(isAggeroUser);
  const userInfo: UserInfo = useSelector(getAuthUser);
  const influencerView = useSelector(isInfluencerView);

  useEffect(() => {
    // only enable on SaaS
    if (influencerView) return;

    if (!enableLoggedOut && !!userInfo?.email) return;

    // we only want to enable LogRocket on the production deployment, for non aggero users
    if (!window.location.hostname.includes('app.aggero.io') || isAggeroEmail)
      return;

    if (!isLogRocketEnabled) {
      LogRocket.init('uuabko/aggero');

      isLogRocketEnabled = true;
    }

    if (userInfo != null) {
      LogRocket.identify(userInfo.id, {
        name: userInfo.displayName,
        email: userInfo.email,
        organization_id: userInfo.organization_id,
      });
    }
  }, [userInfo, isAggeroEmail, enableLoggedOut, influencerView]);
};
