import Grid from '@material-ui/core/Grid';
import React from 'react';

import SectionTitle from '../../../components/SectionTitle';
import InfluencerActivity from './InfluencerActivity';
import InfluencerPlayedGames from './InfluencerPlayedGames';

const InfluencerTabActivity = (props) => {
  const { playedGames, influencerTwitchStreamer } = props;

  return (
    <Grid container direction="column" wrap="nowrap" spacing={4}>
      <Grid item>
        <SectionTitle>Content Creator Activity</SectionTitle>
      </Grid>

      {playedGames.length > 0 && (
        <Grid item>
          <InfluencerPlayedGames
            playedGames={playedGames}
            lastGame={influencerTwitchStreamer.last_game}
          />
        </Grid>
      )}

      <Grid item>
        <InfluencerActivity />
      </Grid>
    </Grid>
  );
};

export default InfluencerTabActivity;
