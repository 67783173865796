import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getCreatorStreams,
  myStatsUpdateStreamSegments,
} from '../../../../../ajax';
import { State } from '../../../../../store/types';
import { selectDateFilter } from '../../store/Stats.selectors';
import {
  selectStreamsPlatformFilter,
  selectStreamsVideoTypeFilter,
} from './Streams.selectors';
import { SLICE_NAME, StreamsAPIResponse } from './types';

export const fetchStreams = createAsyncThunk<StreamsAPIResponse>(
  `${SLICE_NAME}/fetchStreams`,
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as State;
    const platformFilter = selectStreamsPlatformFilter()(state);
    const dates = selectDateFilter()(state);
    const videoTypeFilter = selectStreamsVideoTypeFilter()(state);

    return getCreatorStreams({ platformFilter, dates, videoTypeFilter });
  }
);

interface SubmitStreamSegmentPayload {
  videoId: number;
  startAt: number;
  endAt: number;
}

export const submitStreamSegment = createAsyncThunk<
  void,
  SubmitStreamSegmentPayload
>(`${SLICE_NAME}/submitStreamSegment`, ({ startAt, endAt, videoId }) =>
  myStatsUpdateStreamSegments(videoId, { start_at: startAt, end_at: endAt })
);
