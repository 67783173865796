import { createActions, createReducer } from 'reduxsauce';

export const STORAGE_PREFIX = 'influencerOnboarding';

const INITIAL_STATE = {
  saving: false,
};

const { Types, Creators } = createActions(
  {
    influencerOnboardingSaveRequest: ['payload'],
    influencerOnboardingSaveSuccess: ['payload'],
    influencerOnboardingSaveError: ['payload'],
  },
  {
    prefix: `${STORAGE_PREFIX}/`,
  }
);

export const handleInfluencerOnboardingSaveRequest = (state) => {
  return {
    ...state,
    saving: true,
  };
};

export const handleInfluencerOnboardingSaveSuccess = (state, { payload }) => {
  return {
    ...state,
    ...payload,
    saving: false,
  };
};

export const handleInfluencerOnboardingSaveError = (state) => {
  return {
    ...state,
    saving: false,
  };
};

const HANDLERS = {
  [Types.INFLUENCER_ONBOARDING_SAVE_REQUEST]: handleInfluencerOnboardingSaveRequest,
  [Types.INFLUENCER_ONBOARDING_SAVE_SUCCESS]: handleInfluencerOnboardingSaveSuccess,
  [Types.INFLUENCER_ONBOARDING_SAVE_ERROR]: handleInfluencerOnboardingSaveError,
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);

export const actionTypes = Types;

export default Creators;
