import {
  Button,
  Card,
  CardContent,
  Chip,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import _remove from 'lodash/remove';
import _uniqBy from 'lodash/uniqBy';
import React, { useState } from 'react';

import FormikMuiTextField from '../../../components/FormikMuiTextField';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: 340,
  },
}));

export const SocialMediaMentions = ({ brandId }) => {
  const classes = useStyles();
  const [duplicate, isDuplicate] = useState(false);
  const [newSocialMediaKeyword, setNewSocialMediaKeyword] = useState('');
  const { values, setFieldValue } = useFormikContext();
  const onAdd = (arrayHelpers) => {
    const content = newSocialMediaKeyword.split(',');
    content.forEach((el, index) => {
      content[index] = el.trim().toLowerCase();
    });
    new Set(content).forEach((newContent) => {
      if (!newContent) return;
      if (
        !values.socialMediaKeywords.some((el) => el.keyword === newContent) ||
        !values.socialMediaKeywords
          .filter((el) => el.campaign_brand_id === brandId)
          .some((el) => el.keyword === newContent)
      ) {
        isDuplicate(false);
        arrayHelpers.push({
          keyword: newContent,
          campaign_brand_id: brandId,
        });
        setNewSocialMediaKeyword('');
      } else {
        setNewSocialMediaKeyword(newContent);
        isDuplicate(true);
      }
    });
  };

  const onDelete = (item) => {
    const keywords = [...values.socialMediaKeywords];

    // When deleting a keyword - we remove all occurrences of that keyword for a specific brand
    _remove(keywords, (keywordDeliverable) => {
      return (
        keywordDeliverable.keyword === item.keyword &&
        keywordDeliverable.campaign_brand_id === item.campaign_brand_id
      );
    });

    setFieldValue('socialMediaKeywords', keywords);
  };

  const getUniqueSocialMediaKeywords = (socialMediaKeywords, brandId) => {
    let keywords = [];

    // Return all the keywords belonging to that brand
    if (brandId) {
      keywords = socialMediaKeywords.filter(
        (deliverable) => deliverable.campaign_brand_id === brandId
      );
    } else {
      //  Return all the keywords that don't belong to any brand
      keywords = socialMediaKeywords.filter(
        (deliverable) => !deliverable.campaign_brand_id
      );
    }

    return _uniqBy(keywords, 'keyword');
  };

  const uniqueKeywords = getUniqueSocialMediaKeywords(
    values.socialMediaKeywords,
    brandId
  );

  return (
    <Card className={classes.container}>
      <CardContent>
        <Typography variant="h2">Social posts mentions</Typography>
      </CardContent>
      <FieldArray
        name="socialMediaKeywords"
        render={(arrayHelpers) => (
          <>
            <CardContent>
              <div className={classes.inputContainer}>
                <FormikMuiTextField
                  fullWidth
                  placeholder="Add keyword"
                  variant="outlined"
                  onChange={(e) => {
                    setNewSocialMediaKeyword(e.target.value);
                    isDuplicate(false);
                  }}
                  value={newSocialMediaKeyword}
                  name="newSocialMediaKeyword"
                  className={classes.input}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        onAdd(arrayHelpers);
                      }
                    },
                  }}
                />
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  style={{ padding: 9.5 }}
                  disabled={!newSocialMediaKeyword.trim()}
                  onClick={() => onAdd(arrayHelpers)}
                >
                  Add
                </Button>
              </div>
              {duplicate && (
                <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                  "{newSocialMediaKeyword}" is already added
                </p>
              )}
            </CardContent>
            <CardContent component="ul" className={classes.chipsContainer}>
              {uniqueKeywords.map((d) => (
                <li key={d.id}>
                  <Chip
                    label={d.keyword}
                    variant="outlined"
                    className={classes.chip}
                    onDelete={() => {
                      onDelete(d);
                    }}
                  />
                </li>
              ))}
            </CardContent>
          </>
        )}
      />
    </Card>
  );
};

export default SocialMediaMentions;
