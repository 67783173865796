import { getInfluencer } from './utils';

const computeLinkSharing = ({
  link_tracking_deliverables,
  videos,
  campaign_influencers,
}) => {
  const rows = [];

  rows.push([
    'Link',
    'Creator',
    'Platform',
    'Stream/Video Title',
    'Number of Shares',
  ]);

  link_tracking_deliverables.forEach((deliverable) => {
    const data = deliverable.link_tracking_occurrences.reduce((data, occ) => {
      if (data[occ.video_id]) data[occ.video_id] += 1;
      else data[occ.video_id] = 1;

      return data;
    }, {});

    videos.forEach((video) => {
      if (!data[video.id]) return;

      const influencer = getInfluencer(
        campaign_influencers,
        video.campaign_influencer_ids
      );

      rows.push([
        deliverable.link,
        influencer.name,
        video.resource,
        video.video_title,
        data[video.id],
      ]);
    });
  });

  return rows;
};

export default computeLinkSharing;
