import { STORAGE_PREFIX as prefix } from './redux';

export const getMyWatchlistYoutubeInfluencers = (state) =>
  state[prefix].youtubeInfluencers;
export const getMyWatchlistYoutubeLoaded = (state) =>
  state[prefix].youtubeLoaded;

export const getMyWatchlistTwitchInfluencers = (state) =>
  state[prefix].twitchInfluencers;
export const getMyWatchlistTwitchLoaded = (state) => state[prefix].twitchLoaded;

export const getWatchlistAddSubmitted = (state) =>
  state[prefix].addFormSubmitted;
