import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import twitch from '../../../../assets-2.0/images-influencer/twitch.svg';
import youtube from '../../../../assets-2.0/images-influencer/YouTube.svg';
import {
  INFLUENCERS_SIGNIN,
  INFLUENCERS_SIGNUP,
} from '../../../../common/constants/paths';
import {
  redirectTwitch,
  redirectYoutube,
} from '../../../../helpers/influencer-login';

const BUTTON_WIDTH = 268;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    width: BUTTON_WIDTH,
    height: 50,
    fontSize: 14,
    marginBottom: 14,
    border: ' 1px solid #dadfe3',
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  orWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 1,
    width: BUTTON_WIDTH,
    background: '#dbdbdb',
    marginTop: 17,
    marginBottom: 31,
  },
  or: {
    background: '#ffffff',
    width: 32,
    textAlign: 'center',
    marginTop: -11,
  },
  link: {
    color: '#347cff',
  },
}));

interface WelcomeStepProps {
  onNextStep: () => void;
}

export default function WelcomeStep({ onNextStep }: WelcomeStepProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={redirectTwitch(INFLUENCERS_SIGNUP)}
      >
        <img src={twitch} className={classes.icon} alt="Twitch logo" />
        {t('marketplace.signup.welcomeStep.registerTwitch')}
      </Button>

      <Button
        variant="outlined"
        className={classes.button}
        onClick={redirectYoutube(INFLUENCERS_SIGNUP)}
      >
        <img src={youtube} className={classes.icon} alt="Youtube logo" />
        {t('marketplace.signup.welcomeStep.registerYoutube')}
      </Button>
      <div className={classes.orWrapper}>
        <span className={classes.or}>{t('generic.or')}</span>
      </div>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={onNextStep}
      >
        {t('marketplace.signup.welcomeStep.registerEmail')}
      </Button>
      <p>
        {t('marketplace.signup.welcomeStep.confirmPolicy')}{' '}
        <a
          href="https://aggero.io/aggero-privacy-notice-influencers/"
          className={classes.link}
        >
          {t('generic.privacyPolicy')}
        </a>
      </p>
      <p>
        {t('marketplace.signup.welcomeStep.existentAccount')}{' '}
        <Link to={INFLUENCERS_SIGNIN} className={classes.link}>
          {t('generic.login')}
        </Link>
      </p>
    </div>
  );
}
