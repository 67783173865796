import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import _debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';

import * as ajax from '../ajax';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  search: {
    position: 'relative',
    borderRadius: 4,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: '100%',
  },
  resultsContainer: {
    marginTop: theme.spacing(1),
  },
  noResults: {
    padding: theme.spacing(2),
    paddingTop: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  listItem: {
    color: '#333',
    cursor: 'pointer',
    '&:hover': {
      background: '#efefef',
      color: '#0f9aee',
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  noResultsButton: {
    boxShadow: 'none !important',
    marginTop: theme.spacing(1),
  },
}));

const MIN_QUERY_LENGTH = 3;
const DEBOUNCE_INTERVAL = 500;

const AddInfluencerBySearch = ({
  onInfluencerClick,
  onAddEmptyInfluencer,
  addedInfluencersSet,
  listName,
}) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);

  const loading = results === null;

  const onChange = (query) => {
    setQuery(query);

    setResults(null);

    if (query.trim().length < MIN_QUERY_LENGTH) return;

    onSearch(query);
  };

  const onResultClick = (influencer) => () => {
    onChange('');
    onInfluencerClick({
      ...influencer,
      influencerYoutubeUrl: influencer.youtube_url || '',
      influencerInstagramUrl: influencer.instagram_url || '',
      influencerTwitchUrl: influencer.twitch_url || '',
      influencerTwitterUrl: influencer.twitter_url || '',
      influencerFacebookUrl: influencer.facebook_url || '',
    });
  };

  const addEmptyInfluencer = () => {
    onAddEmptyInfluencer(query);
    onChange('');
  };

  const onSearch = useCallback(
    _debounce((query) => {
      (async () => {
        if (!loading) setResults(null);

        const data = await ajax.searchInfluencers(query.trim());

        setResults(
          data.filter((i) => !addedInfluencersSet.has(i.display_name))
        );
      })();
    }, DEBOUNCE_INTERVAL),
    [addedInfluencersSet]
  );

  function renderContent() {
    if (loading)
      return (
        <Box
          height={96}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );

    if (results.length)
      return (
        <>
          <List dense>
            {results.map((influencer) => (
              <ListItem
                onClick={onResultClick(influencer)}
                classes={{ root: classes.listItem }}
                key={Math.random()}
              >
                <ListItemAvatar>
                  <Avatar src={influencer.profile_image_url} />
                </ListItemAvatar>
                <ListItemText primary={influencer.display_name} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <Box display="flex" flexDirection="column" padding={3}>
            <Typography style={{ textAlign: 'center' }}>
              Didn't find the creator you were looking for?
              <br />
              You can easily add the creator to your {listName} by inputting the
              channel URLs
            </Typography>
            <Box alignSelf="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.noResultsButton}
                onClick={addEmptyInfluencer}
              >
                Add new creator
              </Button>
            </Box>
          </Box>
        </>
      );

    return (
      <div className={classes.noResults}>
        <Typography>
          Sorry, we don't have this creator on our platform.
        </Typography>
        <Typography>
          You can easily add the creator to your {listName} by inputting the
          channel URLs
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.noResultsButton}
          onClick={addEmptyInfluencer}
        >
          Add new creator
        </Button>
      </div>
    );
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            data-id="influencers-search-bar"
            placeholder="Search creators by Twitch or YouTube channel name"
            onChange={(e) => onChange(e.target.value)}
            value={query}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
      </div>
      {query.trim().length >= MIN_QUERY_LENGTH && (
        <div className={classes.resultsContainer}>{renderContent()}</div>
      )}
    </>
  );
};

AddInfluencerBySearch.defaultProps = {
  addedInfluencersSet: new Set(),
  listName: 'campaign',
};

export default AddInfluencerBySearch;
