import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getChatMentionDeliverableById,
  postCampaignBlacklistVideoComment,
} from '../../../../../ajax';
import { ChatMentionDeliverable, SLICE_NAME } from './types';

interface FetchChatMentionDeliverablePayload {
  campaignId: number;
  chatMentionDeliverableId: number;
}

export const fetchChatMentionDeliverable = createAsyncThunk<
  ChatMentionDeliverable,
  FetchChatMentionDeliverablePayload
>(
  `${SLICE_NAME}/fetchChatMentionDeliverable`,
  ({ campaignId, chatMentionDeliverableId }) =>
    getChatMentionDeliverableById(campaignId, chatMentionDeliverableId)
);

interface DeleteChatMentionPayload {
  campaignId: number;
  commentId: number;
  chatMentionDeliverableId: number;
  deleteAll: boolean;
  commenterExternalId: number;
}

export const deleteChatMention = createAsyncThunk(
  `${SLICE_NAME}/deleteChatMention`,
  ({
    campaignId,
    commentId,
    commenterExternalId,
    deleteAll,
  }: DeleteChatMentionPayload) => {
    if (deleteAll) {
      return postCampaignBlacklistVideoComment(campaignId, null, [
        commenterExternalId,
      ]);
    }

    return postCampaignBlacklistVideoComment(campaignId, [commentId], null);
  }
);

interface DeleteMultipleChatMentionsPayload {
  campaignId: number;
  commentIds: number[];
  chatMentionDeliverableId: number;
  deleteAll: false; // For now, we only support deleting 1 comment from a user
  commenterExternalIds: number[];
}

export const deleteMultipleChatMentions = createAsyncThunk(
  `${SLICE_NAME}/deleteMultipleChatMentions`,
  ({
    campaignId,
    commentIds,
    commenterExternalIds,
    deleteAll,
  }: DeleteMultipleChatMentionsPayload) => {
    if (deleteAll) {
      return postCampaignBlacklistVideoComment(
        campaignId,
        null,
        commenterExternalIds
      );
    }

    return postCampaignBlacklistVideoComment(campaignId, commentIds, null);
  }
);
