import { Icon, IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    display: 'inline-block',
    position: 'relative',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  clearIconButton: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    background: 'rgba(0, 0, 0, 0.2)',
  },
  clearIcon: {
    color: 'white',
  },
});

function ImagePreview(props) {
  const { onDelete, className: classNameProp, ...rest } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <img
        className={clsx(classes.image, classNameProp)}
        alt={rest.alt}
        {...rest}
      />
      {onDelete && (
        <IconButton
          size="small"
          className={classes.clearIconButton}
          onClick={onDelete}
        >
          <Icon fontSize="large" className={classes.clearIcon}>
            highlight_off
          </Icon>
        </IconButton>
      )}
    </Paper>
  );
}

export default ImagePreview;
