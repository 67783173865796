import {
  ButtonBase,
  FormLabel,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useFormikContext } from 'formik';
import React from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';

import CustomPluginForDatePicker from './CustomPluginForDatePicker';

const useStyles = makeStyles((theme) => ({
  inputIcon: {
    display: 'flex',
    '& svg': {
      fill: '#757575',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  iconHover: {
    order: 1,
    marginRight: 15,
    borderRadius: '50%',
    padding: 11,
    '&:hover': {
      background: '#a2a2a236',
    },
  },
  datapiker: {
    '& .rmdp-day': {
      margin: '0 2px',
      width: 36,
      height: 36,
    },
    '& .rmdp-week-day': {
      fontSize: '0.9rem',
      color: '#00000061',
    },
    '& .rmdp-header-values': {
      fontSize: '1rem',
    },
    '& .rmdp-panel': {
      fontSize: '1rem',
      margin: '0 36px 0 16px',
      width: 160,
    },
    '& .rmdp-panel-body': {
      padding: 0,
      '& li': {
        width: '100%',
        maxWidth: 140,
        borderRadius: 16,
        color: '#000000de',
        background: '#F0F0F0',
        border: '1px solid #BBBBBB',
        boxShadow: 'none',
        marginBottom: 10,
        '& span': {
          fontSize: 14,
          padding: 3,
        },
        '& .b-deselect': {
          fontSize: 20,
          width: 18,
          height: 18,
          background: '#919090',
          color: '#F0F0F0',
          fontWeight: 'bold',
        },
      },
    },
  },
  arrowHover: {
    borderRadius: '50%',
    padding: 12,
    '&:hover': {
      background: '#a2a2a236',
    },
  },
  arrowDisable: {
    color: '#33333385',
  },
  datePickerHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    color: 'black',
    padding: 14,
  },
}));
const WEEK_DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const DatePickerMultiple = ({ handlePickerChange, campaignIsOver }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const DatePickerHeader = () => {
    return (
      <div className={classes.datePickerHeader}>
        {values.campaignDays && values.campaignDays.length
          ? `${values.campaignDays.length} selected`
          : `0 selected`}
      </div>
    );
  };

  function DatePickeкButtons({ direction, handleClick, disabled }) {
    return (
      <ButtonBase
        disabled={disabled}
        className={`${classes.arrowHover} ${disabled && classes.arrowDisable}`}
        onClick={handleClick}
      >
        {direction === 'right' ? (
          <KeyboardArrowRightIcon />
        ) : (
          <KeyboardArrowLeftIcon />
        )}
      </ButtonBase>
    );
  }

  return (
    <Grid item xs={6} sm={3}>
      <FormLabel>Day</FormLabel>
      <DatePicker
        onClose={() => true}
        calendarPosition={`top-start`}
        fixMainPosition={'true'}
        multiple
        className={classes.datapiker}
        weekDays={WEEK_DAYS}
        mapDays={({ date, today, selectedDate, isSameDate }) => {
          let props = {};

          props.style = {
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            fontSize: '0.875rem',
          };

          if (isSameDate(date, today)) {
            props.style = {
              ...props.style,
              background: 'none',
              color: '#1A44ED',
            };
          }

          selectedDate.forEach((el) => {
            if (isSameDate(date, el)) {
              props.style = {
                ...props.style,
                color: 'white',
                background: '#1A44ED',
              };
            }
          });

          return props;
        }}
        renderButton={<DatePickeкButtons />}
        render={(value, openCalendar) => {
          return (
            <TextField
              onClick={openCalendar}
              className={classes.inputIcon}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <ButtonBase className={classes.iconHover}>
                    <EventIcon />
                  </ButtonBase>
                ),
              }}
              variant="outlined"
              placeholder={`${values.campaignDays.length} days`}
              id="outlined-read-only-input"
              inputProps={{
                'data-id': 'outlined-read-only-input',
              }}
            />
          );
        }}
        onlyShowInRangeDates={true}
        minDate={new Date()}
        maxDate={campaignIsOver && new DateObject().subtract(1, 'days')}
        value={values.campaignDays}
        onChange={handlePickerChange('campaignDays')}
        plugins={[
          <CustomPluginForDatePicker />,
          <DatePickerHeader position="top" />,
        ]}
      />
    </Grid>
  );
};

export default DatePickerMultiple;
