import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import React from 'react';

import clamp from '../../utils/clamp';
import percent from '../../utils/percent';

function CampaignProgress({ campaign }) {
  let campaignProgression = 0;

  const totalCampaignTime =
    moment(campaign.end_date).unix() - moment(campaign.start_date).unix();
  const elapsedCampaignTime =
    moment().unix() - moment(campaign.start_date).unix();

  campaignProgression = clamp(
    0,
    100
  )(percent(totalCampaignTime, elapsedCampaignTime));

  return <LinearProgress variant="determinate" value={campaignProgression} />;
}

export default CampaignProgress;
