import {
  createEntityAdapter,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';

import { LoadingState } from '../../../types';
import { fetchLinkTrackingDeliverable } from './LinkTrackingDeliverables.thunks';
import { LinkTrackingDeliverable, LoadingStateMap, SLICE_NAME } from './types';

const linkTrackingDeliverablesAdapter = createEntityAdapter<
  LinkTrackingDeliverable
>();

export const initialState = linkTrackingDeliverablesAdapter.getInitialState<{
  loadingMap: LoadingStateMap;
  error: SerializedError | null;
}>({ loadingMap: {}, error: null });

const linkTrackingDeliverablesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLinkTrackingDeliverable.pending, (state, action) => {
        state.loadingMap[action.meta.arg.linkTrackingDeliverableId] =
          LoadingState.PENDING;
        state.error = null;
      })
      .addCase(fetchLinkTrackingDeliverable.fulfilled, (state, action) => {
        state.loadingMap[action.meta.arg.linkTrackingDeliverableId] =
          LoadingState.FULLFILLED;
        state.error = null;

        linkTrackingDeliverablesAdapter.addOne(state, action.payload);
      })
      .addCase(fetchLinkTrackingDeliverable.rejected, (state, action) => {
        state.loadingMap[action.meta.arg.linkTrackingDeliverableId] =
          LoadingState.REJECTED;
        state.error = action.error;
      });
  },
});

export const { reducer } = linkTrackingDeliverablesSlice;
