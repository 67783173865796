import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    padding: '12px 16px',
    background: '#F7F9FB',
  },
});

function TopBarContent(props) {
  const classes = useStyles();

  return <div className={classes.root} {...props} />;
}

export default TopBarContent;
