import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonIcon from '@material-ui/icons/Person';
import VideocamIcon from '@material-ui/icons/Videocam';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _sumBy from 'lodash/sumBy';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CampaignCreatorAndDayFilter from '../../CampaignCreatorAndDayFilter';
import DetailedViewToggle, {
  useDetailedViewToggle,
} from '../../components/DetailedViewToggle';
import EyeIcon from '../../components/EyeIcon';
import OverviewCard, {
  PlaceholderOverviewRow,
} from '../../components/OverviewCard';
import SectionTitle from '../../components/SectionTitle';
import { findInfluencerByVideo, findVideoById } from '../../utils';
import DetailedTable from './DetailedTable';
import {
  selectBannerDeliverableById,
  selectBannerDeliverableShowLoading,
  selectShouldFetchBannerDeliverable,
} from './store/BannerDeliverables.selectors';
import {
  deleteMultipleBannerVideos,
  fetchBannerDeliverable,
} from './store/BannerDeliverables.thunks';
import { BannerData, BannerDeliverableStructure } from './store/types';
import SummaryTable, { computeSummary } from './SummaryTable';

const computeOverview = ({ usedInfluencers, usedBannerData, usedVideos }) => {
  const creators = new Set(
    usedBannerData.map(
      (data) =>
        findInfluencerByVideo(
          findVideoById(data.video_id, usedVideos),
          usedInfluencers
        ).id
    )
  ).size;

  const { timeOnScreen, streams, avgCCV, peakCCV, impressions, totalEMV } =
    computeSummary(usedBannerData);

  const totalDuration = _sumBy(usedVideos, 'duration') || 0;

  const ofTotal = totalDuration
    ? Math.round((timeOnScreen / totalDuration) * 10000) / 100
    : 0;

  return {
    creators,
    timeOnScreen,
    ofTotal,
    streams,
    avgCCV,
    peakCCV,
    impressions,
    totalEMV,
  };
};

interface OverviewProps {
  bannerDeliverableStructure: BannerDeliverableStructure;
  usedBannerData: BannerData[];
  usedInfluencers: any[];
  usedVideos: any[];
  showLoading: boolean;
}

const Overview = React.memo<OverviewProps>(
  ({
    bannerDeliverableStructure,
    usedBannerData,
    usedInfluencers,
    usedVideos,
    showLoading,
  }) => {
    const {
      creators,
      timeOnScreen,
      ofTotal,
      streams,
      avgCCV,
      peakCCV,
      impressions,
      totalEMV,
    } = computeOverview({
      usedBannerData,
      usedVideos,
      usedInfluencers,
    });

    const image =
      bannerDeliverableStructure.image ||
      bannerDeliverableStructure.raw_banner_image;

    const header = (
      <>
        <SectionTitle style={{ display: 'flex', alignItems: 'flex-end' }}>
          <img
            alt="banner"
            src={image}
            style={{
              maxHeight: 125,
              maxWidth: 200,
              boxShadow: '4px 4px 0px 0px rgba(0,73,242,.25)',
              marginRight: 16,
            }}
          />
          <span>image tracking</span>
        </SectionTitle>
      </>
    );

    if (showLoading)
      return (
        <div>
          {header}
          <PlaceholderOverviewRow nCards={usedInfluencers.length > 1 ? 6 : 5} />
        </div>
      );

    return (
      <div>
        {header}
        <OverviewCard.Container>
          <OverviewCard
            label="Creators"
            color="teal"
            main={{ stat: creators }}
            icon={<PersonIcon />}
            outOf={usedInfluencers.length}
            id={`sponsorshipVal-it-creators-${bannerDeliverableStructure.id}`}
          />
          <OverviewCard
            label="Videos"
            color="orange"
            main={{ stat: streams }}
            icon={<VideocamIcon />}
            id={`sponsorshipVal-it-streams-${bannerDeliverableStructure.id}`}
          />
          <OverviewCard
            label="Time on-screen"
            color="blue"
            main={{ stat: timeOnScreen / 3600, suffix: ' h' }}
            secondary={{
              stat: ofTotal,
              suffix: '%',
              label: 'of total',
            }}
            icon={<AccessTimeIcon />}
            id={`sponsorshipVal-it-timeonscrean-${bannerDeliverableStructure.id}`}
          />
          <OverviewCard
            label="Average CCV"
            color="purple"
            main={{ stat: avgCCV }}
            secondary={{ label: 'peak', stat: peakCCV }}
            icon={<EyeIcon />}
            id={`sponsorshipVal-it-averageccv-${bannerDeliverableStructure.id}`}
          />
          <OverviewCard
            label="Impressions"
            color="purple"
            main={{ stat: impressions }}
            icon={<VisibilityIcon />}
            id={`sponsorshipVal-it-impressions-${bannerDeliverableStructure.id}`}
          />
          <OverviewCard
            label="Media value"
            color="green"
            main={{ stat: totalEMV, prefix: '$', format: '0,[0].[0]a' }}
            icon={<LocalAtmIcon />}
            id={`sponsorshipVal-it-mediavalue-${bannerDeliverableStructure.id}`}
          />
        </OverviewCard.Container>
      </div>
    );
  }
);

interface BannerDeliverableProps {
  bannerDeliverableStructure: BannerDeliverableStructure;
  usedInfluencers: any[];
  usedVideos: any[];
  usedVideosIds: Set<number>;
  timeZone: string;
  setSelectedCampaignInfluencer: (inf: any) => void;
  isSponsorship: boolean;
  isFilteredByCreator: boolean;
  setByCreator: (inf: any) => void;
  campaignId: number;
  deleteBanner: (bannerData: BannerData) => void;
}

export const BannerDeliverable = React.memo<BannerDeliverableProps>(
  ({
    bannerDeliverableStructure,
    usedInfluencers,
    usedVideos,
    usedVideosIds,
    timeZone,
    setSelectedCampaignInfluencer,
    isSponsorship,
    isFilteredByCreator,
    setByCreator,
    campaignId,
    deleteBanner,
  }) => {
    const bannerDeliverableId = bannerDeliverableStructure.id;
    const image =
      bannerDeliverableStructure.image ||
      bannerDeliverableStructure.raw_banner_image;

    const shouldFetch = useSelector(
      selectShouldFetchBannerDeliverable(bannerDeliverableId)
    );

    const dispatch = useDispatch();

    useEffect(() => {
      if (shouldFetch) {
        dispatch(fetchBannerDeliverable({ bannerDeliverableId, campaignId }));
      }
    }, [shouldFetch, campaignId, bannerDeliverableId, dispatch]);

    const showLoading = useSelector(
      selectBannerDeliverableShowLoading(bannerDeliverableId)
    );

    const bannerDeliverable = useSelector(
      selectBannerDeliverableById(bannerDeliverableId)
    );

    const usedBannerData = useMemo(
      () =>
        showLoading || bannerDeliverable == null
          ? []
          : bannerDeliverable.data_array.filter((videoData) =>
              usedVideosIds.has(videoData.video_id)
            ),
      [bannerDeliverable, usedVideosIds, showLoading]
    );

    const { detailed, setDetailed } = useDetailedViewToggle(usedInfluencers);

    const onDeleteMultipleBannerVideos = (videoIds: number[]) => {
      dispatch(
        deleteMultipleBannerVideos({
          bannerDeliverableId,
          videoIds,
          campaignId,
        })
      );
    };

    return (
      <div
        style={{ paddingBottom: 100 }}
        id={`banners/${bannerDeliverableStructure.id}`}
      >
        <Overview
          bannerDeliverableStructure={bannerDeliverableStructure}
          usedBannerData={usedBannerData}
          usedInfluencers={usedInfluencers}
          usedVideos={usedVideos}
          showLoading={showLoading}
        />
        {usedBannerData.length > 0 && (
          <>
            <DetailedViewToggle
              {...{ detailed, setDetailed, usedInfluencers }}
            />
            {isSponsorship && !detailed && (
              <CampaignCreatorAndDayFilter
                setByCreator={setByCreator}
                isFilteredByCreator={isFilteredByCreator}
              />
            )}
            {!detailed && (
              <SummaryTable
                usedInfluencers={usedInfluencers}
                usedVideos={usedVideos}
                usedBannerData={usedBannerData}
                setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
                isFilteredByCreator={isFilteredByCreator}
                timeZone={timeZone}
                isSponsorship={isSponsorship}
                image={image}
              />
            )}
            {detailed && (
              <DetailedTable
                timeZone={timeZone}
                usedInfluencers={usedInfluencers}
                usedVideos={usedVideos}
                usedBannerData={usedBannerData}
                bannerDeliverableId={bannerDeliverable.id}
                campaignId={campaignId}
                thumbnails={bannerDeliverableStructure.thumbnail || {}}
                setCampaignImageDelete={deleteBanner}
                image={image}
                onDeleteMultipleBannerVideos={onDeleteMultipleBannerVideos}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default BannerDeliverable;
