import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import { Dialog } from '../../common/containers';
import firebase from '../../common/resources/firebase';
import { INFLUENCER_MOUNTED } from '../../store/action-types';
import { getWatchlistById } from '../../store/models/watchlists/watchlists.selectors';
import objectFilter from '../../utils/object-filter';
import actions, {
  influencerDataRequested,
  influencerDataRequestSucceded,
  influencerDataRequestSuccededInterim,
  influencerDataReset,
  Types,
} from './redux';
import { getInfluencerInternalId, getInfluencerName } from './selectors';

const InfluencerNotFound = {
  status: 404,
  message: '',
  object: 'InfluencerNotFound',
};

function* clearInfluencer(action) {
  const { influencerId } = action.payload;
  const influencerDetailName = yield select(getInfluencerName);

  if (influencerDetailName && influencerDetailName !== influencerId) {
    yield put(influencerDataReset());
  }
}

function* requestInfluencerData(action) {
  yield put(influencerDataRequested(action.payload));

  const {
    payload: { influencerId, influencerNetwork },
  } = action;

  try {
    let influencer;

    influencer = yield call(
      ajax.getInfluencerV2,
      influencerId,
      influencerNetwork
    );

    if (!influencer) {
      ajax.postInfluencerMissingData(['influencer'], {
        influencer_network: influencerNetwork,
        influencer_name: influencerId,
      });
      throw InfluencerNotFound;
    }

    const {
      twitch_streamer,
      youtube_streamer,
      watchlists,
      twitter: {
        top_followed: topFollowed,
        top_brands: topBrands,
        brand_categories: topCategories,
        twitter_profile: twitterProfile,
      },
    } = influencer;

    let results = {};
    let reduxResults = {
      influencerId,
      influencerNetwork,
      influencer,
      watchlists,
      twitterProfile,
      topBrands,
      topCategories,
      topFollowed,
    };

    yield put(influencerDataRequestSuccededInterim(reduxResults));

    // Data Round 2
    const promisesTwo = objectFilter((item) => Boolean(item))({
      influencerWordcloud:
        influencer &&
        influencer.id &&
        ajax
          .getInfluencerWordcloud(influencer.id)
          .catch((e) => console.error(e)),

      youtubeAudienceWordcloud:
        youtube_streamer &&
        youtube_streamer.display_name &&
        firebase.getAudienceWordcloud(youtube_streamer.display_name),

      twitchAudienceWordcloud:
        twitch_streamer &&
        twitch_streamer.login &&
        firebase.getAudienceWordcloud(twitch_streamer.login),
    });

    Object.keys(promisesTwo).forEach((key) => {
      promisesTwo[key].then((resolution) => (results[key] = resolution));
    });

    yield call(Promise.all.bind(Promise), Object.values(promisesTwo));

    reduxResults = Object.assign(reduxResults, {
      influencerWordcloud: results.influencerWordcloud,
      youtubeAudienceWordcloud: results.youtubeAudienceWordcloud,
      twitchAudienceWordcloud: results.twitchAudienceWordcloud,
    });

    yield put(influencerDataRequestSucceded(reduxResults));
  } catch (e) {
    let caption = 'Failed to get influencer details data';
    let message = e.bodyJson.errors[0] || '';

    if (e.status === 404) {
      ajax.postInfluencerMissingData(['influencer'], {
        influencer_network: influencerNetwork,
        influencer_name: influencerId,
      });

      caption = `The data for account '${influencerId}' is currently being processed.`;
      message = null;
    }

    console.error(e);

    Dialog.show({
      caption,
      message,
      buttons: ['Got it'],
    });
  }
}

function* watchRequestInfluencerData() {
  yield takeLatest(INFLUENCER_MOUNTED, clearInfluencer);
  yield takeLatest(INFLUENCER_MOUNTED, requestInfluencerData);
}

function* addToWatchlist({ watchlistId }) {
  try {
    const influencerId = yield select(getInfluencerInternalId);

    const influencerName = yield select(getInfluencerName);
    const watchlist = yield select(getWatchlistById(watchlistId));

    yield put(
      actions.addToWatchlistSuccess(
        watchlistId,
        influencerName,
        watchlist ? watchlist.name : ''
      )
    );

    yield call(ajax.addInfluencerToWatchlist, influencerId, watchlistId);
  } catch (error) {
    yield put(actions.removeFromWatchlistSuccess(watchlistId));
    Dialog.show({
      caption: 'Failed',
      message: error.message,
      buttons: ['Got it'],
    });
  }
}

function* removeFromWatchlist({ watchlistId }) {
  try {
    const influencerId = yield select(getInfluencerInternalId);

    const influencerName = yield select(getInfluencerName);
    const watchlist = yield select(getWatchlistById(watchlistId));

    yield call(ajax.removeInfluencerFromWatchlist, influencerId, watchlistId);

    yield put(
      actions.removeFromWatchlistSuccess(
        watchlistId,
        influencerName,
        watchlist ? watchlist.name : ''
      )
    );
  } catch (error) {
    yield put(actions.addToWatchlistSuccess(watchlistId));
    Dialog.show({
      caption: 'Failed',
      message: error.message,
      buttons: ['Got it'],
    });
  }
}

function* watchWatchlistChanges() {
  yield takeLatest(Types.ADD_TO_WATCHLIST, addToWatchlist);
  yield takeLatest(Types.REMOVE_FROM_WATCHLIST, removeFromWatchlist);
}

export default function* root() {
  yield all([fork(watchRequestInfluencerData), fork(watchWatchlistChanges)]);
}
