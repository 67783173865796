import { createActions, createReducer } from 'reduxsauce';

export const STORAGE_PREFIX = 'myStats';

const INITIAL_STATE = {
  loaded: false,
  dateRangeFilter: null,
  data: {},
};

export const myStatsServerDataRequest = (state) => {
  return {
    ...state,
    loaded: false,
  };
};

export const myStatsServerDataSuccess = (state, { payload }) => {
  return {
    ...state,
    data: payload,
    loaded: true,
  };
};

const { Types, Creators } = createActions(
  {
    myStatsServerDataRequest: [],
    myStatsServerDataSuccess: ['payload'],
  },
  {
    prefix: `${STORAGE_PREFIX}/`,
  }
);

export const HANDLERS = {
  [Types.MY_STATS_SERVER_DATA_REQUEST]: myStatsServerDataRequest,
  [Types.MY_STATS_SERVER_DATA_SUCCESS]: myStatsServerDataSuccess,
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);

export const actionTypes = Types;

export default Creators;
