import {
  Button,
  Card,
  CardContent,
  Container,
  FormLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { Form, withFormik } from 'formik';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { compose } from 'recompose';

import FormikMuiTextField from '../../../components/FormikMuiTextField';
import {
  updateUser,
  updateUserEmail,
  updateUserPassword,
} from '../../../store/actions/user.js';
import { showToast } from '../../../store/commonActions';
import { ChangeEmailUserModal } from './ChangeEmailUserModal';
import { ChangePasswordUserModal } from './ChangePasswordUserModal';

const Profile = (props) => {
  document.title = 'My Aggero - Profile';

  const { values, handleSubmit } = props;

  const [openedModal, setOpenedModal] = useState(null);
  const dispatch = useDispatch();

  const onSubmitEmail = async (values) => {
    const result = await updateUserEmail({
      id: props.id,
      email: values.email,
      dispatch,
    });

    return result;
  };

  const onSubmitPassword = async (values) => {
    const result = await updateUserPassword({
      current_password: values.currentPassword,
      password: values.newPassword,
      dispatch,
    });

    return result;
  };

  return (
    <Container style={{ marginTop: '12px' }}>
      <Typography
        variant="h2"
        style={{
          margin: '32px 0 16px 0',
          fontWeight: 'bold',
          fontSize: '1.3rem',
          textTransform: 'uppercase',
        }}
      >
        Basic Information
      </Typography>
      <Grid>
        <Form>
          <Card>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormLabel>Full Name</FormLabel>
                  <FormikMuiTextField
                    fullWidth
                    placeholder={values.fullName || ''}
                    variant="outlined"
                    name="fullName"
                    value={values.fullName || ''}
                    onBlur={handleSubmit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>Email</FormLabel>
                  <p>{values.userEmail}</p>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: '1rem' }}
                    onClick={() => setOpenedModal('passwordModal')}
                  >
                    CHANGE PASSWORD
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpenedModal('emailModal')}
                  >
                    CHANGE EMAIL
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Form>
        {openedModal === 'emailModal' && (
          <ChangeEmailUserModal
            onClose={() => setOpenedModal(null)}
            onSubmit={onSubmitEmail}
          />
        )}
        {openedModal === 'passwordModal' && (
          <ChangePasswordUserModal
            onClose={() => setOpenedModal(null)}
            onSubmit={onSubmitPassword}
          />
        )}
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    account: { authUser },
  } = state;

  return {
    id: authUser?.id,
    fullName: authUser?.displayName,
    userEmail: authUser?.email,
  };
};

export default compose(
  connect(mapStateToProps, null),
  withFormik({
    validateOnChange: true,
    validateOnMount: true,

    handleSubmit: (values, bag) => {
      const { dispatch } = bag.props;

      updateUser({
        id: bag.props.id,
        name: values.fullName.trim(),
        dispatch,
      }).then(({ success, errorKey }) => {
        if (success) {
          dispatch(
            showToast({
              message: 'Name saved successfully',
              type: 'success',
            })
          );
        } else {
          dispatch(
            showToast({
              message: errorKey || 'User update failed',
              type: 'error',
            })
          );
        }
      });
    },
    enableReinitialize: true,
  })
)(Profile);
