import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';

import Behaviours from '../../../components/Behaviours';
import SectionTitle from '../../../components/SectionTitle';
import behaviourCategoryByBehaviour from '../../../helpers/behaviour-category-by-behaviour';
import InfluencerAudienceBrandAffinities from './InfluencerAudienceBrandAffinities';
import InfluencerAudienceDemographics from './InfluencerAudienceDemographics';
import InfluencerAudienceTopFollowed from './InfluencerAudienceTopFollowed';
import InfluencerPlatformSelect from './InfluencerPlatformSelect';

const InfluencerTabAudience = (props) => {
  const {
    twitchAudienceBehaviour,
    youtubeAudienceBehaviour,
    networkFilter,
    influencerId,
    twitterProfile,
    topFollowed,
    topBrands,
    topCategories,
  } = props;

  let audiencePreferences;
  let audienceBehaviours;

  const activePlatforms = [
    twitchAudienceBehaviour && twitchAudienceBehaviour.length && 'twitch',
    youtubeAudienceBehaviour && youtubeAudienceBehaviour.length && 'youtube',
  ].filter((e) => !!e);

  const [activePlatform, setActivePlatform] = useState('twitch');

  useEffect(() => {
    if (activePlatforms.length === 2 && networkFilter) {
      setActivePlatform(networkFilter);
    } else {
      setActivePlatform(activePlatforms[0]);
    }
  }, [activePlatforms.length]);

  const audienceBehaviour =
    activePlatform === 'twitch'
      ? twitchAudienceBehaviour
      : youtubeAudienceBehaviour;

  if (audienceBehaviour) {
    const behaviours = audienceBehaviour
      .map(({ consumption_preferences }) => consumption_preferences)
      .flat(1)
      .map(({ data, name }) =>
        data.map((behaviour) => ({ ...behaviour, likeliness: name }))
      )
      .flat(1);

    audiencePreferences = behaviours.filter(
      ({ name }) => behaviourCategoryByBehaviour[name] === 'preference'
    );

    audienceBehaviours = behaviours.filter(
      ({ name }) => behaviourCategoryByBehaviour[name] === 'behaviour'
    );
  }

  return (
    <Grid container direction="column" wrap="nowrap" spacing={4}>
      <InfluencerAudienceDemographics />

      {twitterProfile && topFollowed && topFollowed.length > 0 && (
        <InfluencerAudienceTopFollowed
          influencerId={influencerId}
          influencerNetwork={networkFilter}
          twitterProfile={twitterProfile}
          topFollowed={topFollowed}
        />
      )}

      {topBrands &&
        topBrands.length > 0 &&
        topCategories &&
        topCategories.length > 0 &&
        twitterProfile && (
          <Grid item>
            <InfluencerAudienceBrandAffinities
              topBrands={topBrands}
              topCategories={topCategories}
              twitterProfile={twitterProfile}
            />
          </Grid>
        )}

      {audiencePreferences.length > 0 && (
        <>
          <Grid item>
            <SectionTitle>Audience Preferences</SectionTitle>
          </Grid>

          <Grid item>
            <Grid container spacing={4} alignItems="stretch">
              <Grid item xs={6} lg={6}>
                <Behaviours
                  category="lifestyle"
                  label="Lifestyle"
                  behaviours={audiencePreferences}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <Behaviours
                  category="purchasing"
                  label="Purchasing"
                  behaviours={audiencePreferences}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {audienceBehaviours.length > 0 && (
        <>
          <Grid item>
            <SectionTitle>Audience Behaviours</SectionTitle>
            {activePlatforms.length > 1 && (
              <InfluencerPlatformSelect
                activePlatform={activePlatform}
                activePlatforms={activePlatforms}
                onSelect={(platform) => setActivePlatform(platform)}
              />
            )}
          </Grid>

          <Grid item>
            <Grid container spacing={4} alignItems="stretch">
              <Grid item xs={6}>
                <Behaviours
                  category="lifestyle"
                  label="Lifestyle"
                  behaviours={audienceBehaviours}
                />
              </Grid>
              <Grid item xs={6}>
                <Behaviours
                  category="purchasing"
                  label="Purchasing"
                  behaviours={audienceBehaviours}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default InfluencerTabAudience;
