import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  deleteImageOccurrence,
  deleteMultipleImageOccurrence,
  getBannerDeliverableById,
} from '../../../../../ajax';
import { BannerDeliverable, SLICE_NAME } from './types';

interface FetchBannerDeliverablePayload {
  campaignId: number;
  bannerDeliverableId: number;
}

export const fetchBannerDeliverable = createAsyncThunk<
  BannerDeliverable,
  FetchBannerDeliverablePayload
>(
  `${SLICE_NAME}/fetchBannerDeliverable`,
  ({ campaignId, bannerDeliverableId }) =>
    getBannerDeliverableById(campaignId, bannerDeliverableId).then(
      (deliverable) => ({
        ...deliverable,
        data_array: Object.keys(deliverable.data).map((video_id) => ({
          ...deliverable.data[video_id],
          created_at: deliverable.created_at,
          video_id: parseInt(video_id),
        })),
      })
    )
);

interface DeleteBannerPayload {
  campaignId: number;
  videoId: number;
  bannerDeliverableId: number;
}

export const deleteBannerVideo = createAsyncThunk(
  `${SLICE_NAME}/deleteBanner`,
  ({ campaignId, bannerDeliverableId, videoId }: DeleteBannerPayload) =>
    deleteImageOccurrence(campaignId, bannerDeliverableId, videoId)
);

interface DeleteMultipleBannerVideosPayload {
  campaignId: number;
  videoIds: number[];
  bannerDeliverableId: number;
}

export const deleteMultipleBannerVideos = createAsyncThunk(
  `${SLICE_NAME}/deleteMultipleShoutouts`,
  ({
    campaignId,
    videoIds,
    bannerDeliverableId,
  }: DeleteMultipleBannerVideosPayload) =>
    deleteMultipleImageOccurrence({ campaignId, videoIds, bannerDeliverableId })
);
