import {
  Button,
  Avatar,
  Tooltip,
  Container,
  Grid,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { AddBox as AddBoxIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'recompose';

import { showToast } from '../../../store/commonActions';
import { isUserAdmin } from '../../../helpers/user-roles';
import {
  getOrganizationUsers,
  createNewUser,
  updateUser,
  removeUser,
} from '../../../ajax';
import { LoadingState } from '../../Campaign/types';
import {
  getErrorMessagesFromResponse,
  translateErrorMessage,
} from '../../../common/ErrorMessageUtil';
import { InviteUserModal } from './InviteUserModal';
import { EditUserModal } from './EditUserModal';
import { RemoveUserModal } from './RemoveUserModal';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  tableCell: {
    border: 'none',
  },
  buttonInvite: {
    margin: '12px 0',
    maxHeight: '2.75rem',
    maxWidth: '11rem',
    color: '#0049f2',
    borderRadius: '0.25rem',
    backgroundColor: 'white',
  },
  buttonSettings: {
    padding: '4px 16px',
    color: '#0049f2',
    borderRadius: '0.25rem',
    backgroundColor: 'white',
  },
  typoInviteButton: {
    fontWeight: 'Regular',
    color: '#347cff',
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
  typoInviteButtonDisabled: {
    color: 'grey'
  },
  typoInviteButtonIconDisabled: {
    color: 'grey'
  }
}))

const Usage = (props) => {
  document.title = 'My Aggero - Users';

  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedUser, setSelectedUser] = useState(null);
  const [getUsersCount, setGetUsersCount] = useState(0);
  const [openedModal, setOpenedModal] = useState(null);

  const [loadingState, setLoadingState] = useState(LoadingState.PENDING);
  const [usersData, setUsers] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getOrganizationUsers();
      setUsers(users);
      setLoadingState(LoadingState.FULFILLED);
    };

    fetchUsers();
  }, [getUsersCount]);

  const onSubmitRemoveUser = async (user) => {
    await removeUser(user.id).then(() => {
      setGetUsersCount(getUsersCount + 1)

      dispatch(
        showToast({
          message: 'User successfully removed!',
          type: 'success',
        })
      );
    }).catch((ex) => {
      const [error] = getErrorMessagesFromResponse(ex);
      const message = translateErrorMessage(error);

      dispatch(
        showToast({
          message,
          type: 'error',
        })
      );
    })
  };

  const onSubmitEditUser = async (id, values) => {
    const result = await updateUser(id, {
      name: values.name,
      password: values.password,
      email: values.email,
    }).then(() => {
      setGetUsersCount(getUsersCount + 1)

      dispatch(
        showToast({
          message: 'User updated successfully!',
          type: 'success',
        })
      );

      return { success: true };
    }).catch((ex) => {
      const [error] = getErrorMessagesFromResponse(ex);
      const message = translateErrorMessage(error);

      return { success: false, errorMessage: message };
    })

    return result
  };

  const onSubmitUser = async (values) => {
    const result = await createNewUser({
      name: values.name,
      password: values.password,
      email: values.email,
    }).then(() => {
      setGetUsersCount(getUsersCount + 1)

      dispatch(
        showToast({
          message: 'User created successfully!',
          type: 'success',
        })
      );

      return { success: true };
    }).catch((ex) => {
      const [error] = getErrorMessagesFromResponse(ex);
      const message = translateErrorMessage(error);

      return { success: false, errorMessage: message };
    })

    return result
  };

  const { authUser } = props;

  const isCurrentUserAdmin = isUserAdmin(authUser);

  return (
    <Container style={{ marginTop: '12px' }}>
      <Grid container direction="row" justifyContent="space-between">
        <div>
          <Typography
            variant="h2"
            style={{
              margin: '32px 0 8px 0',
              fontWeight: 'bold',
              fontSize: '1.3rem',
              textTransform: 'uppercase',
            }}
          >
            Manage People
          </Typography>
        </div>
        {loadingState === LoadingState.FULFILLED && isCurrentUserAdmin && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonInvite}
            onClick={() => setOpenedModal('inviteUser')}
          >
            <Typography className={classes.typoInviteButton}>
              <AddBoxIcon style={{ paddingRight: '12px' }} />
              Add new User
            </Typography>
          </Button>
        )}
        {loadingState === LoadingState.FULFILLED && !isCurrentUserAdmin && (
          <Tooltip title="You have to be an administrator to access this option." placement="top">
            <span>
              <Button
                variant="outlined"
                color="grey"
                className={classes.buttonInvite}
                disabled
              >
                <Typography className={`${classes.typoInviteButton} ${classes.typoInviteButtonDisabled}`}>
                  <AddBoxIcon className={classes.typoInviteButtonDisabled} style={{ paddingRight: '12px' }} />
                  Add new User
                </Typography>
              </Button>
            </span>
          </Tooltip>
        )}
      </Grid>
      <Grid container>
        {loadingState === LoadingState.PENDING && (
          <Paper
            style={{
              marginTop: '16px',
              padding: '24px',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Paper>
        )}
        { (loadingState === LoadingState.FULFILLED) && (
          <Table style={{
            backgroundColor: 'white',
            marginTop: '16px',
            border: '1px solid #ccc',
          }}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.tableHead}
                  orderByValue="email"
                >
                  Name
                </TableCell>
                <TableCell
                  className={classes.tableHead}
                  orderByValue="email"
                >
                  Email
                </TableCell>
                <TableCell
                  className={classes.tableHead}
                  orderByValue="email"
                >
                  Role
                </TableCell>
                <TableCell
                  className={classes.tableHead}
                  orderByValue="settings"
                >
                  Settings
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { usersData.sort((a,b) => isUserAdmin(b) - isUserAdmin(a)).map((user) => {
                  return (
                    <TableRow
                      key={user.id}
                      className={classes.tableRow}
                    >
                      <TableCell
                        className={classes.tableCell}
                        alignLeft
                      >
                        { !!(user.name && user.name.trim()) && (
                            <Avatar alt={user.name} style={{
                              display: 'inline-block',
                              textAlign: 'center',
                              padding: '10px',
                              float: 'left',
                            }}>
                              {user.name &&
                                user.name.charAt(0) &&
                                user.name.charAt(0).toUpperCase()}
                            </Avatar>
                          )
                        }
                        <div style={{
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '8px',
                          float: 'left',
                        }}> { user.name.trim() || "-" } </div>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        alignLeft
                      >
                        { user.email }
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        alignLeft
                      >
                        { isUserAdmin(user) ? 'Administrator' : 'Member' }
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        alignLeft
                      >
                        {!isCurrentUserAdmin && user.id !== authUser.id && (
                          <>
                            <Tooltip title="You have to be an administrator to access this option." placement="top">
                              <span>
                                <Button
                                  variant="outlined"
                                  color="grey"
                                  className={classes.buttonSettings}
                                  style={{ marginRight: '12px' }}
                                  disabled
                                >
                                  <Typography className={`${classes.typoInviteButton} ${classes.typoInviteButtonDisabled}`}>
                                    Edit
                                  </Typography>
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip title="You have to be an administrator to access this option." placement="top">
                              <span>
                                <Button
                                  variant="outlined"
                                  color="grey"
                                  className={classes.buttonSettings}
                                  disabled
                                >
                                  <Typography className={`${classes.typoInviteButton} ${classes.typoInviteButtonDisabled}`}>
                                    Remove
                                  </Typography>
                                </Button>
                              </span>
                            </Tooltip>
                          </>
                        )}
                        {isCurrentUserAdmin && user.id !== authUser.id && (
                          <>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonSettings}
                              style={{ marginRight: '12px' }}
                              onClick={() => {
                                setSelectedUser(user);
                                setOpenedModal('editUser');
                              }}
                            >
                              <Typography className={classes.typoInviteButton}>
                                Edit
                              </Typography>
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonSettings}
                              onClick={() => {
                                setSelectedUser(user);
                                setOpenedModal('removeUser');
                              }}
                            >
                              <Typography className={classes.typoInviteButton}>
                                Remove
                              </Typography>
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )
              })}
            </TableBody>
          </Table>
        )}
        { openedModal === 'inviteUser' && (
          <InviteUserModal
            onClose={() => setOpenedModal(null)}
            onSubmit={onSubmitUser}
          />
        )}
        { openedModal === 'editUser' && selectedUser && (
          <EditUserModal
            user={selectedUser}
            onClose={() => { setOpenedModal(null); setSelectedUser(null) }}
            onSubmit={onSubmitEditUser}
          />
        )}
        { openedModal === 'removeUser' && selectedUser && (
          <RemoveUserModal
            user={selectedUser}
            onClose={() => { setOpenedModal(null); setSelectedUser(null) }}
            onSubmit={onSubmitRemoveUser}
          />
        )}
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    account: { authUser, organization },
  } = state;

  return {
    authUser,
    organization,
  };
};

export default compose(connect(mapStateToProps, null))(Usage);
