import { call, put, takeLatest } from 'redux-saga/effects';

import { putBrand } from '../../ajax';
import queryClient from '../../queryClient';
import { BRAND_UPDATE_SUBMITTED } from '../action-types';
import {
  brandUpdateFailed,
  brandUpdateRequested,
  brandUpdateSucceeded,
} from '../models/my-brands/my-brands.actions';

function* updateBrand(action) {
  const {
    payload: { id, deliverables },
  } = action;

  yield put(brandUpdateRequested());

  const data = {
    ...deliverables,
  };

  try {
    yield call(putBrand, id, data);

    queryClient.clear();

    yield put(brandUpdateSucceeded());
  } catch (e) {
    console.error('Brand Update Error - ', e);

    let message = null;

    if (e.status === 422 || e.status === 408) {
      message = e.bodyJson && e.bodyJson.errors;
    } else if (e.status === 500) {
      message = e.bodyJson && `${e.bodyJson.error} - ${e.bodyJson.exception}`;
    }

    yield put(brandUpdateFailed({ error: message || 'Brand Update Error' }));
  }
}

export default function* root() {
  yield takeLatest(BRAND_UPDATE_SUBMITTED, updateBrand);
}
