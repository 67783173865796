import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddInfluencersBySearch from '../../components/AddInfluencerBySearch';
import CampaignWizardInfluencerExpando from './CampaignWizardInfluencerExpando';
import { CampaignWizardFormValues, createEmptyInfluencer } from './types';

export const addToggles = (urls) => {
  const toggles = Object.keys(urls).reduce((prev, curr) => {
    if (urls[curr] !== '') prev[`${curr}Checked`] = true;

    return prev;
  }, {});

  return { ...urls, ...toggles };
};

const RemoveDialog = ({ open, influencerName, onRemove, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to remove {influencerName} from this campaign?
        Please note that all the data associated with this creator will be
        deleted.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" variant="outlined">
        Cancel
      </Button>
      <Button onClick={onRemove} color="primary" variant="contained">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

interface CampaignWizardInfluencersProps {
  dontExpand: boolean;
  shouldConfirmRemoval: boolean;
}

export default function CampaignWizardInfluencers(
  props: CampaignWizardInfluencersProps
) {
  const { values, setFieldTouched } =
    useFormikContext<CampaignWizardFormValues>();

  const { dontExpand, shouldConfirmRemoval } = props;

  const [expanded, setExpanded] = useState(
    values.campaignName.includes('Copy of') || dontExpand ? null : 0
  );

  const [removeConfirmDialog, setRemoveConfirmDialog] = useState(null);

  const history = useHistory();

  useEffect(() => {
    history.replace({ search: '' });
  }, [history]);

  return (
    <>
      <FieldArray
        name="influencersSocials"
        render={(arrayHelpers) => (
          <Card>
            <RemoveDialog
              open={removeConfirmDialog !== null}
              onClose={() => setRemoveConfirmDialog(null)}
              influencerName={
                removeConfirmDialog !== null
                  ? values.influencersSocials[removeConfirmDialog].display_name
                  : ''
              }
              onRemove={() => {
                setRemoveConfirmDialog(null);
                arrayHelpers.remove(removeConfirmDialog);
              }}
            />
            {values.influencersSocials.map((influencer, index) => (
              <CampaignWizardInfluencerExpando
                shouldFetchPlatfromLinksFromDiscovery
                key={index}
                expanded={expanded === index}
                index={index}
                onExpandToggle={() => {
                  if (expanded === index) {
                    return setExpanded(null);
                  }

                  setExpanded(index);
                }}
                onRemove={() => {
                  if (shouldConfirmRemoval) setRemoveConfirmDialog(index);
                  else arrayHelpers.remove(index);
                }}
                onCheckPlatform={(platform) => {
                  const key = `influencer${platform}UrlChecked`;

                  setFieldTouched(`influencersSocials[${index}].${key}`, true);

                  arrayHelpers.replace(index, {
                    ...influencer,
                    [key]: !influencer[key],
                  });
                }}
              />
            ))}
            {values.influencersSocials.length > 0 && (
              <Box display="flex" alignItems="center">
                <Box flex={1} height="1px" bgcolor="#C2C2C2" marginRight={4} />
                <Typography>ADD ANOTHER CREATOR</Typography>
                <Box flex={1} height="1px" bgcolor="#C2C2C2" marginLeft={4} />
              </Box>
            )}
            <AddInfluencersBySearch
              onInfluencerClick={(influencer) => {
                arrayHelpers.push({
                  ...createEmptyInfluencer(),
                  ...addToggles({ ...influencer }),
                });
                setExpanded(values.influencersSocials.length);
              }}
              onAddEmptyInfluencer={(display_name) => {
                arrayHelpers.push({ ...createEmptyInfluencer(), display_name });
                setExpanded(values.influencersSocials.length);
              }}
              addedInfluencersSet={
                new Set(values.influencersSocials.map((i) => i.display_name))
              }
            />
          </Card>
        )}
      />
    </>
  );
}
