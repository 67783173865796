import { Button, makeStyles, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#edf7ff',
    padding: '34px 34px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      padding: '16px 30px',
    },
  },

  icon: {
    color: theme.palette.primary.main,
    width: 50,
    height: 50,
    marginBottom: 14,

    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
      marginBottom: 18,
    },
  },

  button: {
    width: 170,
    height: 40,
    marginTop: 34,
    fontWeight: 'bold',

    [theme.breakpoints.down('sm')]: {
      width: 121,
      height: 30,
      marginTop: 20,
      fontWeight: 'normal',
    },
  },

  typography: {
    color: 'black',
    opacity: '0.5',
  },

  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      marginBottom: 10,
    },
  },

  body: {
    fontSize: '1rem',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
}));

interface UnlockBannerProps {
  title: string;
  bodyText: string;
  onCTA: (any) => void;
}

export const UnlockBanner = ({ title, bodyText, onCTA }: UnlockBannerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LockIcon className={classes.icon} />
      <Typography className={clsx(classes.typography, classes.title)}>
        {title}
      </Typography>
      <Typography className={clsx(classes.typography, classes.body)}>
        {bodyText}
      </Typography>
      <Button
        type="button"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onCTA}
      >
        Unlock
      </Button>
    </div>
  );
};
