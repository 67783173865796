import {
  USER_REQUEST_FAILED,
  USER_REQUEST_SUCCEEDED,
} from '../../action-types';
import createAction from '../../create-action';

export const userRequestedSucceeded = createAction(USER_REQUEST_SUCCEEDED, {
  request: {
    name: 'user',
    type: 'succeeded',
  },
});

export const userRequestedFailed = createAction(USER_REQUEST_FAILED, {
  request: {
    name: 'user',
    type: 'failed',
  },
});
