import SubMenu, { useInsightsSubmenu } from '../components/SubMenu';
import { campaignHasStreamlitStats } from '../utils';
import { Stats } from './Stats';
import { TopCreatorsTab } from './TopCreatorsTab';
import { TopMomentsTab } from './TopMomentsTab';
import { TopTopicsTab } from './TopTopics/TopTopicsTab';

export const Insights = (props) => {
  const hasStats = campaignHasStreamlitStats(props.campaign);
  const {
    tabs,
    activeTab,
    onTabClick,
    setStatsProps,
    setActiveTab,
    statsProps,
  } = useInsightsSubmenu(null, hasStats);

  const tabProps = {
    ...props,
    setStatsProps,
    setActiveTab,
    statsProps,
  };

  return (
    <>
      <SubMenu tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
      <div style={{ marginBottom: 40 }}></div>
      {activeTab === 'top_moments' && <TopMomentsTab {...tabProps} />}
      {activeTab === 'top_creators' && <TopCreatorsTab {...tabProps} />}
      {activeTab === 'top_topics' && <TopTopicsTab {...tabProps} />}
      {activeTab === 'stats' && <Stats {...tabProps} />}
    </>
  );
};
