import _sortBy from 'lodash/sortBy';
import _sumBy from 'lodash/sumBy';
import memoize from 'proxy-memoize';

import { State } from '../../../../../store/types';
import { LoadingState } from '../../../../../types';
import { SLICE_NAME, TopCountries } from './types';

export const selectAudienceData = () => (state: State) =>
  state[SLICE_NAME].data;

export const selectAudienceIsLoading = () => (state: State) => {
  return (
    state[SLICE_NAME].loadingState === LoadingState.PENDING ||
    state[SLICE_NAME].loadingState === LoadingState.IDLE
  );
};

export const selectAudienceHasErrors = () => (state: State) =>
  state[SLICE_NAME].loadingState === LoadingState.REJECTED;

export const selectShouldFetchAudience = () => (state: State) =>
  state[SLICE_NAME].loadingState === LoadingState.IDLE;

export const selectAudienceBrandData = () => (state: State) =>
  state[SLICE_NAME].data.twitter_data;

export const memoizedAudienceGenderData = memoize((state: State) => {
  const { twitch_data, youtube_data } = state[SLICE_NAME].data ?? {};

  let genderData;

  // prefer twitch over youtube
  if (twitch_data?.genders) {
    genderData = twitch_data?.genders;
  } else {
    genderData = youtube_data?.genders;
  }

  if (!genderData) return null;

  const totalAudience = _sumBy(Object.keys(genderData), (g) => genderData[g]);

  // transform to percentages
  const genderDataPercentages = Object.keys(genderData).map((label) => ({
    label: label.toString() === '1' ? 'Female' : 'Male',
    value: Math.round((genderData[label] / totalAudience) * 100),
  }));

  return genderDataPercentages;
});

export const selectAudienceGenderData = () => (state: State) =>
  memoizedAudienceGenderData(state);

export const memoizedAudienceAgeData = memoize((state: State) => {
  const { twitch_data, youtube_data } = state[SLICE_NAME].data ?? {};

  let ageData;

  // prefer twitch over youtube
  if (twitch_data?.ages) {
    ageData = twitch_data?.ages;
  } else {
    ageData = youtube_data?.ages;
  }

  if (!ageData) return null;

  const totalAudience = _sumBy(Object.keys(ageData), (key) => ageData[key]);

  // transform to percentages
  const ageDataPercentages = Object.keys(ageData).map((label) => ({
    label,
    value: Math.round((ageData[label] / totalAudience) * 100),
  }));

  return ageDataPercentages;
});

export const selectAudienceAgeData = () => (state: State) =>
  memoizedAudienceAgeData(state);

const memoizedAudienceLanguageData = memoize((state: State) => {
  const { twitch_data, youtube_data } = state[SLICE_NAME].data ?? {};

  let languageData;

  // prefer youtube over twitch
  if (youtube_data?.languages) {
    languageData = youtube_data?.languages;
  } else {
    languageData = twitch_data?.languages;
  }

  if (!languageData) return null;

  const totalAudience = _sumBy(
    Object.keys(languageData),
    (key) => languageData[key]
  );

  // transform to percentages
  const languageDataPercentages = Object.keys(languageData).map((label) => ({
    label,
    value: Math.round((languageData[label] / totalAudience) * 100),
  }));

  return languageDataPercentages;
});

export const selectAudienceLanguageData = () => (state: State) =>
  memoizedAudienceLanguageData(state);

const LOCATION_LIMIT = 5;

const memoizedAudienceLocationData = memoize((state: State) => {
  const { twitch_data, youtube_data } = state[SLICE_NAME].data ?? {};

  let locationData;

  // prefer twitch over youtube
  if (twitch_data?.audience_location) {
    locationData = twitch_data?.audience_location;
  } else {
    locationData = youtube_data?.audience_location;
  }

  if (!locationData) return null;

  const sortedLocations = _sortBy(
    Object.keys(locationData),
    // sort in descending order
    (country) => -locationData[country]
  );

  const topLocations = new Set(sortedLocations.slice(0, LOCATION_LIMIT));

  const reducedLocationData = sortedLocations.reduce((acc, country) => {
    if (topLocations.has(country)) {
      acc[country] = locationData[country];
    } else if ('other' in acc) {
      acc.other += locationData[country];
    } else {
      acc.other = locationData[country];
    }

    return acc;
  }, {} as TopCountries);

  // total audience

  const totalAudience = _sumBy(
    Object.keys(reducedLocationData),
    (country) => reducedLocationData[country]
  );

  // transform to percentage
  const percentageLocationData = Object.keys(reducedLocationData).reduce(
    (acc, country) => {
      acc[country] = Math.round(
        (reducedLocationData[country] / totalAudience) * 100
      );

      return acc;
    },
    {} as TopCountries
  );

  return percentageLocationData;
});

export const selectAudienceLocationData = () => (state: State) =>
  memoizedAudienceLocationData(state);

export const selectHasAudienceInterestsData = () => (state: State) =>
  !!(
    state[SLICE_NAME].data?.twitter_data?.brand_categories?.length ||
    state[SLICE_NAME].data?.twitter_data?.top_brands?.length ||
    state[SLICE_NAME].data?.twitter_data?.top_followed?.length
  );

export const selectAudienceTopBrands = () => (state: State) =>
  state[SLICE_NAME].data?.twitter_data?.top_brands;

export const selectAudienceBrandCategories = () => (state: State) =>
  state[SLICE_NAME].data?.twitter_data?.brand_categories;

export const selectAudienceTopFollowed = () => (state: State) =>
  state[SLICE_NAME].data?.twitter_data?.top_followed;
