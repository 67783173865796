import Box from '@material-ui/core/Box';
import React from 'react';

const colors = {
  'MEGA INFLUENCER': '#0048F2',
  'MACRO INFLUENCER': '#2CA092',
  'MID-TIER INFLUENCER': '#ff77ff',
  'MICRO INFLUENCER': '#6262DB',
  'NANO INFLUENCER': '#F9A948',
};

function InfluencerSize(props) {
  const { children, sizeOnly } = props;

  const bgColor = colors[children];
  const text =
    sizeOnly && children ? children.replace(' INFLUENCER', '') : children;

  if (!text) {
    return null;
  }

  return (
    <Box
      px={1}
      py={0.5}
      bgcolor={bgColor}
      color="white"
      fontSize="0.7rem"
      fontWeight="bold"
      display="inline-block"
      className="influencer-size"
    >
      {text}
    </Box>
  );
}

export default InfluencerSize;
