import { ComponentMeta, ComponentStory } from '@storybook/react';
import React from 'react';

import { AudienceOverlap } from './AudienceOverlap';

// More on default export: https://storybook.js.org/docs/react/writing-stories/introduction#default-export
export default {
  title: 'AudienceOverlap',
  component: AudienceOverlap,
  argTypes: {
    width: { type: 'number' },
    height: { type: 'number' },
  },
} as ComponentMeta<typeof AudienceOverlap>;

export const TOP_FOLLOWED = [
  {
    user: 'aguerosergiokun',
    name: 'Sergio Kun Aguero',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1061753629722492929/dGtVACSP_400x400.jpg',
    percentage: 100,
  },
  {
    user: 'cristiano',
    name: 'Cristiano Ronaldo',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1157313327867092993/a09TxL_1_400x400.jpg',
    percentage: 70.45008220679576,
  },
  {
    user: 'neymarjr',
    name: 'Neymar Jr',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1318976541661986823/8BGLLjLB_400x400.jpg',
    percentage: 59.99725977347461,
  },
  {
    user: 'andresiniesta8',
    name: 'Andrés Iniesta',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1360222014431244290/bqZ1FttY_400x400.jpg',
    percentage: 58.83266350018268,
  },
  {
    user: '3gerardpique',
    name: 'Gerard Piqué',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1303854832894312450/YbdVhwef_400x400.jpg',
    percentage: 53.99844720496895,
  },
  {
    user: 'championsleague',
    name: 'UEFA Champions League',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1073607078109949957/74oimLX4_400x400.jpg',
    percentage: 53.93793386919985,
  },
  {
    user: 'premierleague',
    name: 'Premier League',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1402916628766015490/ZYeq0kdE_400x400.jpg',
    percentage: 50.93281877968578,
  },
  {
    user: 'garethbale11',
    name: 'Gareth Bale',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1405290638728335362/nWNAv9gu_400x400.jpg',
    percentage: 50.67706430398247,
  },
  {
    user: 'realmadrid',
    name: 'Real Madrid C.F.',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1284197749030887429/7n6w-Urk_400x400.jpg',
    percentage: 50.380206430398246,
  },
  {
    user: 'sergioramos',
    name: 'Sergio Ramos',
    avatar_url:
      'https://pbs.twimg.com/profile_images/1009034630891175936/msrj3hUn_400x400.jpg',
    percentage: 47.165007307270734,
  },
];

const Template: ComponentStory<typeof AudienceOverlap> = (arg) => {
  return <AudienceOverlap {...arg} />;
};

export const Primary = Template.bind({});
Primary.args = {
  topFollowed: TOP_FOLLOWED,
};
