import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import CardTitle from '../../../components/CardTitle';

const N_DAYS = 7;

const EngagementChart = ({ data: unslicedData }) => {
  const data = (canvas) => {
    const ctx = canvas.getContext('2d');

    const hoursGradient = ctx.createLinearGradient(50, 0, 50, 250);
    hoursGradient.addColorStop(0, 'rgba(0, 72, 242, .75)');
    hoursGradient.addColorStop(1, 'rgba(0, 72, 242, .4)');

    const data = unslicedData.slice(-N_DAYS);

    return {
      datasets: [
        {
          label: 'Average CCV',
          data: data.map(({ avg_ccv }) => avg_ccv),
          borderColor: 'rgba(240, 123, 90, 1)',
          type: 'line',
          yAxisID: 'ccv-axis',
          borderWidth: 4,
          backgroundColor: 'transparent',
        },
        {
          label: 'Peak CCV',
          data: data.map(({ peak_ccv }) => peak_ccv),
          borderColor: 'rgba(76, 175, 80, 1)',
          backgroundColor: 'transparent',
          type: 'line',
          yAxisID: 'ccv-axis',
          borderWidth: 4,
        },
        {
          label: 'Hours watched by the audience',
          data: data.map(({ hrs_watched }) => hrs_watched),
          backgroundColor: hoursGradient,
          borderColor: 'transparent',
          yAxisID: 'hours-axis',
        },
      ],
      labels: data.map(({ date }) => moment(date).format('Do MMMM')),
    };
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item container justify="space-between" alignItems="center">
            <Grid item>
              <CardTitle>Channel performance</CardTitle>
            </Grid>
            <Grid item>
              <Typography
                className="fs-i"
                style={{ display: 'inline', fontSize: 13 }}
                color="textSecondary"
              >
                Last {N_DAYS} days
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Bar
              data={data}
              options={{
                maintainAspectRatio: false,
                layout: {
                  padding: {
                    left: 50,
                    right: 0,
                    top: 0,
                    bottom: 0,
                  },
                },
                tooltips: {
                  mode: 'index',
                  axis: 'x',
                  intersect: false,
                },
                legend: {
                  position: 'bottom',
                },
                scales: {
                  yAxes: [
                    {
                      id: 'ccv-axis',
                      type: 'linear',
                      position: 'left',
                      gridLines: {
                        drawTicks: false,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Viewers',
                      },
                    },
                    {
                      id: 'hours-axis',
                      type: 'linear',
                      position: 'right',
                      gridLines: {
                        drawOnChartArea: false,
                        drawTicks: false,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Hours watched',
                      },
                    },
                  ],
                },
              }}
              height={250}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EngagementChart;
