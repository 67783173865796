import { FormLabel, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { getErrorMessageByField } from '../../../../common/ErrorMessageUtil';
import FormikMuiTextField from '../../../../components/FormikMuiTextField';
import { selectSignupInfluencerWithPasswordError } from '../store/InfluencerSignupWizard.selectors';

const useStyles = makeStyles({
  inputContainer: {
    width: 456,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto',
  },
  input: {
    height: 44,
    flex: 1,
    fontSize: 16,
  },
  formLabelStyle: {
    marginRight: 'auto',
  },
});
const SignUpForm = () => {
  const classes = useStyles();
  const signUpErrors = useSelector(selectSignupInfluencerWithPasswordError());

  return (
    <>
      <Grid>
        <div className={classes.inputContainer}>
          <FormLabel className={classes.formLabelStyle}>Email</FormLabel>
          <FormikMuiTextField
            className={classes.input}
            fullWidth
            placeholder="Enter your email address"
            variant="outlined"
            name="email"
            style={{ marginBottom: 16 }}
            errorMessage={getErrorMessageByField('email', signUpErrors)}
          />

          <FormLabel className={classes.formLabelStyle}>Password</FormLabel>
          <FormikMuiTextField
            name="password"
            fullWidth
            type="password"
            placeholder="Enter your password"
            variant="outlined"
            className={classes.input}
            style={{ marginBottom: 16 }}
            errorMessage={getErrorMessageByField('password', signUpErrors)}
          />
          <FormLabel className={classes.formLabelStyle}>
            Confirm Password
          </FormLabel>
          <FormikMuiTextField
            name="repeat_password"
            fullWidth
            type="password"
            placeholder="Repeat your password"
            variant="outlined"
            className={classes.input}
          />
        </div>
      </Grid>
    </>
  );
};

export default SignUpForm;
