import { Button, FormLabel } from '@material-ui/core';
import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import {
  ErrorMessage,
  getErrorMessageByField,
} from '../../../../../common/ErrorMessageUtil';
import FormikMuiTextField from '../../../../../components/FormikMuiTextField';
import { LoadingState } from '../../../../../types';
import { changeInfluencerEmail } from '../../../store/InfluencerInfo.thunks';
import { ModalShell } from './ModalShell';

const INITIAL_VALUES = {
  email: '',
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Not a valid email')
    .required('Enter email')
    .max(75, 'Must be shorter than 75 character'),
});

interface ChangePasswordModalProps {
  onClose: () => void;
  open: boolean;
}

export const ChangeEmailModal = ({
  onClose,
  open,
}: ChangePasswordModalProps) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<ErrorMessage>(null);
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.IDLE
  );

  const onSubmit = useCallback(
    async (values: typeof INITIAL_VALUES) => {
      setLoadingState(LoadingState.PENDING);

      const result = await dispatch(changeInfluencerEmail(values));

      if (isFulfilled(result)) {
        setLoadingState(LoadingState.FULFILLED);
      } else if (isRejectedWithValue(result)) {
        setLoadingState(LoadingState.REJECTED);

        setError(
          getErrorMessageByField('email', result.payload as ErrorMessage[])
        );
      }
    },

    [setLoadingState, dispatch]
  );

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}
    >
      {(formik) => {
        const { submitForm, resetForm, isValid } = formik;

        let content = (
          <>
            <FormLabel style={{ marginBottom: 15 }}>New email</FormLabel>
            <FormikMuiTextField
              name="email"
              fullWidth
              placeholder="Enter your email address"
              variant="outlined"
              style={{ marginBottom: 16 }}
              errorMessage={error}
              disabled={loadingState === LoadingState.PENDING}
            />
          </>
        );

        if (loadingState === LoadingState.FULFILLED) {
          content = (
            <>
              <h4>Email confirmation required</h4>
              <p>
                A confirmation email has been sent to{' '}
                <b>{formik.values.email}</b>. You'll have to visit the link
                enclosed in the email to confirm the email address change.
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={() => {
                    onClose();
                    resetForm();
                    setLoadingState(LoadingState.IDLE);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Got it
                </Button>
              </div>
            </>
          );
        }

        return (
          <ModalShell
            title="CHANGE EMAIL"
            open={open}
            onClose={() => {
              onClose();
              resetForm();
              setError(null);
            }}
            onConfirm={submitForm}
            disableConfirm={!isValid}
            loading={loadingState === LoadingState.PENDING}
            noActionButtons={loadingState === LoadingState.FULFILLED}
          >
            {content}
          </ModalShell>
        );
      }}
    </Formik>
  );
};
