export const Stats = (props) => {
  const statsProps = props.statsProps || {};
  const campaignId = props.campaign.id;
  const topic = statsProps.topic || '';
  const feedback = statsProps.feedback || '';

  let url = `https://aggero-audience-insights.streamlit.app/Influencer_Profile?embedded=true&iframe=true&campaign_id=${campaignId}`;

  // Special case for client
  if (parseInt(campaignId) === 5258) {
    url = `https://aggero-audience-insights-test.streamlit.app/Influencer_Profile?embedded=true&iframe=true&campaign_id=${campaignId}`;
  }

  if (topic !== '') {
    url += `&topic=${topic}`;
  }
  if (feedback !== '') {
    url += `&feedback=${feedback}`;
  }
  return (
    <div>
      <iframe
        src={url}
        height="auto"
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="streamlit"
      ></iframe>
    </div>
  );
};
