import Typography from '@material-ui/core/Typography';
import React from 'react';

interface VideoFlagProps {
  color: string;
  style?: React.CSSProperties;
  title: string;
}

export function VideoFlag({ color, style, title }: VideoFlagProps) {
  return (
    <Typography
      variant="h4"
      style={{
        fontSize: '0.6rem',
        color: color,
        border: `1px solid ${color}`,
        display: 'inline-block',
        margin: '0 0 10px 10px',
        padding: '3px 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(style ?? {}),
      }}
    >
      {title}
    </Typography>
  );
}
