import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import xss from 'xss';

import { useMultiItemsSelect } from '../../../../hooks/useMultiItemsSelect';
import { getIsViewOnly } from '../../../../store/models/user/user.selectors';
import { CampaignMultipleItemsDeleteDialog } from '../../CampaignMultipleItemsDeleteDialog';
import { CampaignDeliverableChartTrigger } from '../../components/DeliverablesChartTrigger';
import InfluencerAvatar from '../../components/InfluencerAvatar';
import VideoCell from '../../components/VideoCell';
import Table, {
  // StatBox,
  TableText,
} from '../../Table';
import {
  findInfluencerByVideo,
  findVideoById,
  getMomentURL,
} from '../../utils';

const ROW_HEIGHT = 130;

const useMentionTextStyles = makeStyles((theme) => ({
  box: {
    height: ROW_HEIGHT,
    position: 'relative',
    '&:hover a': {
      opacity: 1,
    },
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    fontSize: '1rem',
    '--lines': 3,
    margin: 0,
    lineHeight: '1.25rem',
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
    position: 'absolute',
    left: 48,
    bottom: 4,
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
  },
  name: {
    fontWeight: 'bold',
    margin: 0,
    lineHeight: '1em',
  },
}));
const CreatorCol = ({
  influencer,
  platform,
  comment,
  setCampaignCommentDelete,
  isViewOnly,
  handleRowSelect,
}) => (
  <div style={{ position: 'relative' }} className="padding-on-hover">
    <div
      style={{
        position: 'absolute',
        left: -32,
        top: '50%',
        transform: 'translateY(-50%)',
        height: ROW_HEIGHT,
        width: 48,
        background: 'rgba(0, 0, 0, .1)',
        zIndex: 5,
      }}
      className="slide-on-hover"
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Checkbox
          color="primary"
          checked={comment.isSelected}
          onChange={() => {
            handleRowSelect(comment.id);
          }}
          inputProps={{ 'aria-label': 'Select comment' }}
        />
        <CampaignDeliverableChartTrigger videoId={comment.video.id} />
        {!isViewOnly && (
          <Tooltip placement="top" title="Delete Comment">
            <IconButton
              onClick={() => {
                setCampaignCommentDelete(comment);
              }}
            >
              <DeleteIcon style={{ color: 'red' }} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
    <InfluencerAvatar influencer={influencer} platform={platform} />
  </div>
);

const MentionText = ({
  text,
  momentUrl,
  avatar,
  name,
  isNotYTStreamComment,
}) => {
  const classes = useMentionTextStyles();
  const fallbackImage = '../../fallback/img.png';

  return (
    <div className={classes.box}>
      <Avatar src={avatar ? avatar : fallbackImage} alt={name?.toUpperCase()} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pl={1}
        flex={1}
      >
        <p className={classes.name}>{name}</p>
        <p
          dangerouslySetInnerHTML={{ __html: text }}
          className={`text-ellipsis ${classes.text}`}
        />
      </Box>
      {momentUrl && !isNotYTStreamComment && (
        <a
          href={momentUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <OpenInNewIcon fontSize="inherit" style={{ marginRight: 4 }} />
          <span>VIEW MOMENT</span>
        </a>
      )}
    </div>
  );
};

const getCols = ({ setCampaignCommentDelete, isViewOnly, handleRowSelect }) => [
  {
    label: 'Creator',
    key: 'creator',
    render: (row) => {
      return (
        <CreatorCol
          influencer={row.influencer}
          platform={row.platform}
          comment={row}
          setCampaignCommentDelete={setCampaignCommentDelete}
          isViewOnly={isViewOnly}
          handleRowSelect={handleRowSelect}
        />
      );
    },
    stretch: true,
    flex: 1.5,
  },
  {
    label: 'Video',
    key: 'videoTimeStamp',
    render: (row) => <VideoCell video={row.video} videoTime={row.videoTime} />,
    stretch: true,
    flex: 1,
  },
  {
    label: 'Mention',
    key: 'text',
    render: (row) => (
      <MentionText
        text={row.text}
        momentUrl={row.momentUrl}
        avatar={row.avatar}
        name={row.name}
        isNotYTStreamComment={row.isNotYTStreamComment}
      />
    ),
    stretch: true,
    flex: 2,
  },
  {
    label: 'Time',
    key: 'timeStamp',
    render: (row) => (
      <TableText fontSize="0.8rem">
        {row.date},
        <br />
        {row.time}
      </TableText>
    ),
    stretch: true,
    flex: 0.75,
  },
  // {
  //   label: 'Viewers',
  //   key: 'views',
  //   render: (data) => <StatBox stat={data.views} />,
  // },
  // {
  //   label: 'Media Value',
  //   key: 'emv',
  //   render: (data) => (
  //     <StatBox stat={data.emv} format="0,[0].[0]a" prefix="$" />
  //   ),
  // },
];

const getRows = ({
  usedComments,
  usedVideos,
  usedInfluencers,
  timeZone,
  setCampaignCommentDelete,
  content,
  selectedRows,
}) =>
  usedComments.map((comment) => {
    const video = findVideoById(comment.video_id, usedVideos);

    const videoTimeObject = momentTz.tz(video.published_at, timeZone);

    const endVideo = momentTz
      .tz(video.published_at, timeZone)
      .add(video.duration, 'seconds');

    const videoTimeStamp = videoTimeObject.unix();

    const videoTime = videoTimeObject.format('Do MMM YYYY, hh:mm A');
    const timeObject = momentTz.tz(comment.published_at, timeZone);

    const isNotYTStreamComment =
      !moment(timeObject).isBetween(videoTimeObject, endVideo) &&
      video.resource === 'youtube';

    const date = timeObject.format('Do MMM YYYY');
    const time = timeObject.format('hh:mm A');
    const timeStamp = timeObject.unix();

    const influencer = findInfluencerByVideo(video, usedInfluencers);

    const segment_start = timeObject.utc() - videoTimeObject.utc();
    const momentUrl = getMomentURL(video, segment_start);

    const text =
      content === '?'
        ? `<b>?</b>`
        : xss(comment.comment).replace(new RegExp(content, 'gi'), `<b>$&</b>`);

    return {
      time,
      date,
      videoTimeStamp,
      timeStamp,
      influencer,
      creator: influencer.name,
      key: comment.id,
      id: comment.id,
      platform: video.resource,
      views: comment.views,
      emv: comment.emv,
      text,
      videoTitle: video.video_title,
      video,
      videoTime,
      momentUrl,
      avatar: comment.commenter_avatar_url,
      name: comment.commenter_name,
      isNotYTStreamComment,
      commenter_external_id: comment.commenter_external_id,
      isSelected: selectedRows.includes(comment.id),
    };
  });

export const DetailedTable = ({
  usedComments,
  usedVideos,
  usedInfluencers,
  timeZone,
  content,
  setCampaignCommentDelete,
  onMultipleCommentsDelete,
}) => {
  const isViewOnly = useSelector(getIsViewOnly);

  const usedCommentsIds = useMemo(
    () => usedComments.map((data) => data.id),
    [usedComments]
  );

  const {
    selectedItems: selectedRows,
    handleAllItemsSelect: handleAllRowsSelect,
    handleItemSelect: handleRowSelect,
  } = useMultiItemsSelect(usedCommentsIds);

  const rows = useMemo(
    () =>
      getRows({
        usedComments,
        usedVideos,
        usedInfluencers,
        timeZone,
        content,
        selectedRows,
      }),
    [usedComments, usedVideos, usedInfluencers, timeZone, content, selectedRows]
  );
  const cols = useMemo(
    () =>
      getCols({
        setCampaignCommentDelete,
        isViewOnly,
        handleRowSelect,
      }),
    [setCampaignCommentDelete, isViewOnly]
  );
  const idTable = `SV-chatMentions-detailed-${content.replace(/\s+/g, '')}`;

  return (
    <>
      {selectedRows.length > 0 && (
        <div style={{ padding: 4, display: 'flex' }}>
          <Checkbox
            color="primary"
            onChange={(_, checked) => {
              handleAllRowsSelect(checked);
            }}
          />
          <CampaignMultipleItemsDeleteDialog
            items={selectedRows}
            onDelete={onMultipleCommentsDelete}
            title={'Delete Comments'}
          />
        </div>
      )}
      <Table
        cols={cols}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        minWidth={1300}
        defaultSortCol="timeStamp"
        idTable={idTable}
      />
    </>
  );
};

export default DetailedTable;
