import _cloneDeep from 'lodash/cloneDeep';
import qs from 'query-string';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { recordUserSession } from '../../ajax';
import { Dialog } from '../../common/containers';
import { enhanceInfluencerData } from '../../helpers/enhance-influencers';
import { REGIONS_COUNTRY_LIST } from '../../utils/country-list';
import DEFAULT_PARAMS from '../../views/InfluencerSearch/components/defaultParams';
import {
  influencersSearchRequested,
  influencersSearchRequestFailed,
  influencersSearchRequestSucceeded,
} from '../action-creators';
import {
  INFLUENCERS_FETCH_NEXT_PAGE,
  INFLUENCERS_MOUNTED,
} from '../action-types';
import { getUserSearchLimit } from '../models/user/user.selectors';
import {
  selectSearchInfluencers,
  selectSearchPageIndex,
  selectSearchQuery,
} from '../selectors-folder/search-influencers.selector';

const getOrganizationId = (store) => store.account.authUser.organization_id;

export const RESULTS_PER_PAGE = 50;
const MAX_LIMIT = 2500;

const toArray = (params, param) => {
  if (params[param]) params[param] = params[param].split(',');

  return params;
};

const toNumber = (params, param) => {
  if (params[param]) params[param] = parseInt(params[param]);

  return params;
};

const toBoolean = (params, param) => {
  if (params[param]) params[param] = params[param] === 'true';

  return params;
};

const getSearchQueryString = (
  givenParams,
  organizationId,
  { order, orderBy },
  limit,
  page = 0
) => {
  const params = _cloneDeep(givenParams);

  if (params.search_watchlist) {
    const { search_watchlist: watchlistParam } = params;
    delete params.search_watchlist;

    params.organization_id = organizationId;

    if (watchlistParam !== 'all') {
      params.watchlist_id = parseInt(watchlistParam);
    }
  }

  Object.keys(params)
    .filter((param) => Array.isArray(DEFAULT_PARAMS[param]))
    .forEach((param) => toArray(params, param));

  if (params.main_games) {
    params.main_games = params.main_games.map((id) => parseInt(id));
  }

  if (!params.main_game_only || params.main_game_only === 'false') {
    params.games = params.main_games;

    delete params.main_games;
  } else {
    delete params.main_game_only;
  }

  toArray(params, 'audience_country');

  toNumber(params, 'min_ccv');
  toNumber(params, 'max_ccv');

  toBoolean(params, 'with_email');

  const [min, max] = params.influencer_followers_range;

  params.followers_intervals = [[Number(min), Number(max)]];

  delete params.influencer_followers_range;

  delete params.influencer_sizes;

  if (params.country) {
    if (REGIONS_COUNTRY_LIST[params.country])
      params.country = REGIONS_COUNTRY_LIST[params.country];
    else params.country = [params.country];
  }

  const { campaign_goal } = params;

  delete params.campaign_goal;

  const hashedParams = qs.stringify({
    ...params,
    limit: Math.min(MAX_LIMIT, limit),
    results_per_page: RESULTS_PER_PAGE,
    sort: orderBy,
    direction: order,
    ordering: {
      name: campaign_goal,
    },
  });

  const queryString = qs.stringify({
    key: 't2Ka36F4M6Mq5FvgfG7CQVQkWKYHPasMZ5TqdfLwXcCAMSqzNRT4bhTmjnbSkqUDXJAPDgLe',
    q: JSON.stringify({
      ...params,
      limit: Math.min(MAX_LIMIT, limit),
      results_per_page: RESULTS_PER_PAGE,
      page,
      sort: orderBy,
      direction: order,
      ordering: {
        name: campaign_goal,
      },
    }),
  });

  return { queryString, hashedParams };
};

const getSearchUrl = (queryString) => {
  const base = process.env.REACT_APP_AGGERO_SEARCH_API_URL;

  return `${base}/search?${queryString}`;
};

function* requestInfluencerSearch({ payload }) {
  const organizationId = yield select(getOrganizationId);

  const limit = yield select(getUserSearchLimit);

  const { queryString, hashedParams } = getSearchQueryString(
    payload.params,
    organizationId,
    payload.ordering,
    limit
  );

  const oldQuery = yield select(selectSearchQuery);

  if (hashedParams === oldQuery) return;

  yield put(influencersSearchRequested({ ...payload, query: hashedParams }));

  try {
    const url = getSearchUrl(queryString);

    const response = yield call(window.fetch, url);

    const {
      results: influencers,
      has_next_page: hasNextPage,
      len_of_results: totalResults,
    } = yield call(response.json.bind(response));

    enhanceInfluencerData(influencers);

    yield call(recordUserSession, {
      action: 'search-influencers-list',
      misc: {
        params: queryString,
        results: influencers.map(({ display_name }) => display_name),
      },
    });

    const currentQuery = yield select(selectSearchQuery);

    if (hashedParams === currentQuery)
      yield put(
        influencersSearchRequestSucceeded({
          influencers,
          game_id_list: [],
          params: queryString,
          pageIndex: 0,
          hasNextPage,
          query: hashedParams,
          totalResults,
        })
      );
  } catch (e) {
    Dialog.show({
      caption: 'Influencer search failed',
      message:
        (e.bodyJson && e.bodyJson.errors) ||
        'Ooops, there was a problem with this search. Please try to reload the page.',
      buttons: ['Got it'],
    });

    console.error('Influencer Search Error - ', e);
    yield put(
      influencersSearchRequestFailed({
        error: (e.bodyJson && e.bodyJson.errors) || 'Influencer Search Error',
      })
    );
  }
}

function* fetchNextPage({ payload }) {
  const previousInfluencers = yield select(selectSearchInfluencers);
  const previousPageIndex = yield select(selectSearchPageIndex);
  const organizationId = yield select(getOrganizationId);
  const limit = yield select(getUserSearchLimit);

  const { queryString, hashedParams } = getSearchQueryString(
    payload.params,
    organizationId,
    payload.ordering,
    limit,
    previousPageIndex + 1
  );

  try {
    const url = getSearchUrl(queryString);

    const response = yield call(window.fetch, url);

    const {
      results: influencers,
      has_next_page: hasNextPage,
      len_of_results: totalResults,
    } = yield call(response.json.bind(response));

    enhanceInfluencerData(influencers);

    yield put(
      influencersSearchRequestSucceeded({
        influencers: previousInfluencers.concat(influencers),
        game_id_list: [],
        params: queryString,
        pageIndex: previousPageIndex + 1,
        hasNextPage,
        query: hashedParams,
        totalResults,
      })
    );
  } catch (e) {
    Dialog.show({
      caption: 'Influencer search failed',
      message: (e.bodyJson && e.bodyJson.errors) || e.message,
      buttons: ['Got it'],
    });

    console.error('Influencer Search Error - ', e);
    yield put(
      influencersSearchRequestFailed({
        error: (e.bodyJson && e.bodyJson.errors) || 'Influencer Search Error',
      })
    );
  }
}

export default function* root() {
  yield takeLatest(INFLUENCERS_MOUNTED, requestInfluencerSearch);
  yield takeLatest(INFLUENCERS_FETCH_NEXT_PAGE, fetchNextPage);
}
