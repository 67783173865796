import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar, BarRounded } from '@visx/shape';
import { Text } from '@visx/text';
import { max } from 'd3-array';
import React, { useMemo } from 'react';

import {
  groupBarData,
  mentionsGetLabel,
  mentionsGetMentionsKeys,
  mentionsGetMentionsSum,
} from './utils';

const MENTIONS_HEIGHT = 228;
const MENTIONS_PADDING = { top: 48, bottom: 32, left: 32, right: 32 };

const mentionsYMax =
  MENTIONS_HEIGHT - MENTIONS_PADDING.bottom - MENTIONS_PADDING.top;

interface MentionsBarsProps {
  mentionsBars: ReturnType<typeof groupBarData>;
  width: number;
  onBarLeave: () => void;
  onBarMove: (b: {
    barIndex: number;
    label: string;
  }) => (e: React.MouseEvent) => void;
  getOpacity: (label: string) => number;
  mentionsColorScale: (label: string) => string;
  title: string;
  BarIcon?: React.FC<{
    width: number;
    height: number;
    style: React.CSSProperties;
  }>;
}

export const MentionsBars = ({
  mentionsBars,
  width,
  onBarLeave,
  onBarMove,
  getOpacity,
  mentionsColorScale,
  title,
  BarIcon,
}: MentionsBarsProps) => {
  const mentionsYScale = useMemo(
    () =>
      scaleLinear({
        range: [mentionsYMax, 0],
        domain: [0, Math.max(max(mentionsBars, mentionsGetMentionsSum), 5)],
      }),
    [mentionsBars]
  );

  const mentionsXMax = width - MENTIONS_PADDING.left - MENTIONS_PADDING.right;

  const mentionsBarsScale = useMemo(
    () =>
      scaleBand({
        domain: mentionsBars.map(mentionsGetLabel),
        padding: 0.2,
        range: [0, mentionsXMax],
      }),
    [mentionsXMax, mentionsBars]
  );

  return (
    <svg height={MENTIONS_HEIGHT} width={width} style={{ marginTop: -4 }}>
      <Group top={0} left={0}>
        <Group top={MENTIONS_PADDING.top} left={MENTIONS_PADDING.left}>
          <Text fontSize={16} y={-12}>
            {title}
          </Text>
          <GridColumns scale={mentionsBarsScale} height={MENTIONS_HEIGHT} />
          <GridRows scale={mentionsYScale} width={mentionsXMax} numTicks={4} />
          {mentionsBars.map((mentionBar, barIndex) => {
            const x = mentionsBarsScale(mentionBar.label);
            const width = mentionsBarsScale.bandwidth();

            const mentionsKeys = mentionsGetMentionsKeys(mentionBar).filter(
              (k) => mentionBar[k] > 0
            );

            return (
              <React.Fragment key={mentionBar.label}>
                {
                  mentionsKeys
                    .sort(
                      (a, b) =>
                        (mentionBar[b] as number) - (mentionBar[a] as number)
                    )
                    .reduce(
                      ({ bars, total }, k, index) => {
                        const height =
                          mentionsYMax -
                          mentionsYScale(mentionBar[k] as number);

                        const newTotal = total + height;

                        const y = mentionsYMax - newTotal;
                        const color = mentionsColorScale(k);

                        const isLastBar = index === mentionsKeys.length - 1;

                        bars.push(
                          <React.Fragment key={k}>
                            <BarRounded
                              key={k}
                              y={y}
                              x={x}
                              radius={2}
                              top={isLastBar}
                              height={height}
                              width={width}
                              fill={color}
                              opacity={getOpacity(mentionBar.label)}
                            />
                            {isLastBar && BarIcon && (
                              <Group
                                left={x + mentionsBarsScale.bandwidth() / 2 - 8}
                                top={y - 20}
                              >
                                <Group transform={`rotate(-45, 8, 8)`}>
                                  <BarIcon
                                    width={16}
                                    height={16}
                                    style={{
                                      fill: '#888',
                                      opacity: getOpacity(mentionBar.label),
                                    }}
                                  />
                                </Group>
                              </Group>
                            )}
                          </React.Fragment>
                        );

                        return { bars, total: newTotal };
                      },
                      {
                        bars: [],
                        total: 0,
                      }
                    ).bars
                }
                <Bar
                  height={mentionsYMax}
                  width={width}
                  y={0}
                  x={x}
                  fill="transparent"
                  onMouseLeave={onBarLeave}
                  onMouseMove={onBarMove({ label: mentionBar.label, barIndex })}
                />
              </React.Fragment>
            );
          })}
        </Group>
        <AxisBottom
          scale={mentionsBarsScale}
          top={MENTIONS_PADDING.top + mentionsYMax}
          left={MENTIONS_PADDING.left}
          numTicks={12}
          tickStroke="#0048f2"
          tickLength={4}
          stroke="#0048f2"
          strokeWidth={2}
        />
        <AxisLeft
          scale={mentionsYScale}
          top={MENTIONS_PADDING.top}
          left={MENTIONS_PADDING.left}
          numTicks={4}
          tickFormat={(d) => `${Math.floor(d as number)}`}
          hideAxisLine
          hideTicks
        />
      </Group>
    </svg>
  );
};
