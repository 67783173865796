import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

export const computeOverviewShoutoutData = ({
  shoutoutDeliverable,
  timeFrame,
  campaign,
}) => {
  const { content, shoutouts: unfilteredShoutouts, name } = shoutoutDeliverable;

  const video_ids = new Set(
    campaign.videos
      .filter(({ published_at }) =>
        moment(published_at).isBetween(timeFrame.start, timeFrame.end)
      )
      .map(({ id }) => id)
  );

  const shoutouts = unfilteredShoutouts.filter(({ video_id }) =>
    video_ids.has(video_id)
  );

  const totalMentions = shoutouts.length;

  const videos = shoutouts.reduce(
    (set, shoutout) => set.add(shoutout.video_id),
    new Set()
  ).size;

  const avgCCV =
    shoutouts.reduce((avg, shoutout) => avg + shoutout.views, 0) /
    totalMentions;
  const peakCCV = shoutouts.reduce(
    (peak, shoutout) => Math.max(peak, shoutout.views),
    0
  );
  const totalEMV = shoutouts.reduce((avg, shoutout) => avg + shoutout.emv, 0);

  return {
    content: content || name,
    totalMentions,
    videos,
    avgCCV,
    peakCCV,
    shoutouts,
    totalEMV,
  };
};

export const computeShoutoutData = ({
  shoutoutDeliverable,
  timeFrame,
  campaign,
}) => {
  const {
    content,
    totalMentions,
    videos,
    avgCCV,
    peakCCV,
    shoutouts,
    totalEMV,
  } = computeOverviewShoutoutData({ shoutoutDeliverable, timeFrame, campaign });

  const byInfluencerId = shoutouts.reduce((prev, shoutout) => {
    const { campaign_influencer_ids } = campaign.videos.find(
      ({ id }) => id === shoutout.video_id
    );

    const influencer = campaign.campaign_influencers.find(
      ({ id }) => campaign_influencer_ids.indexOf(id) !== -1
    ).id;

    if (!prev[influencer]) {
      prev[influencer] = {
        peakCCV: shoutout.views,
        averageCCV: shoutout.views,
        emv: shoutout.emv,
        shoutouts: 1,
      };
    } else {
      prev[influencer].shoutouts += 1;
      prev[influencer].emv += shoutout.emv;
      prev[influencer].averageCCV += shoutout.views;

      if (shoutout.views > prev[influencer].peakCCV)
        prev[influencer].peakCCV = shoutout.views;
    }

    return prev;
  }, {});

  let maxShoutouts = 0;

  Object.keys(byInfluencerId).forEach((id) => {
    byInfluencerId[id].averageCCV /= byInfluencerId[id].shoutouts;

    if (byInfluencerId[id].shoutouts > maxShoutouts) {
      maxShoutouts = byInfluencerId[id].shoutouts;
    }
  });

  const sortedInfluencers = _cloneDeep(campaign.campaign_influencers)
    .filter(
      (i) =>
        i.twitch_url ||
        i.youtube_url ||
        i.facebook_url ||
        campaign.videos.some(
          (v) => v.campaign_influencer_ids.indexOf(i.id) !== -1
        ) // influencer platforms removed but may still have videos
    )
    .sort(({ id: idA }, { id: idB }) => {
      const { peakCCV: peakCCVA } = byInfluencerId[idA] || { peakCCV: 0 };

      const { peakCCV: peakCCVB } = byInfluencerId[idB] || { peakCCV: 0 };

      return peakCCVB - peakCCVA;
    });

  const influencers = sortedInfluencers.map((influencer) => {
    const influencerData = byInfluencerId[influencer.id] || {
      peakCCV: 0,
      shoutouts: 0,
      averageCCV: 0,
    };

    const { peakCCV: maxCCV } = byInfluencerId[sortedInfluencers[0].id] || {
      peakCCV: 1,
    };

    influencer.shoutoutData = {
      ...influencerData,
      peakCCVBar: influencerData.peakCCV / maxCCV,
      averageCCVBar: influencerData.averageCCV / maxCCV,
      shoutoutsBar: influencerData.shoutouts / maxShoutouts,
    };

    return influencer;
  });

  return {
    content,
    totalMentions,
    videos,
    avgCCV,
    peakCCV,
    totalEMV,
    influencers,
  };
};
