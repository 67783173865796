import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';

import Behaviours from '../../../components/Behaviours';
import CardTitle from '../../../components/CardTitle';
import SectionTitle from '../../../components/SectionTitle';
import Traits from '../../../components/Traits';
import behaviourCategoryByBehaviour from '../../../helpers/behaviour-category-by-behaviour';
import InfluencerPlatformSelect from './InfluencerPlatformSelect';

const InfluencerTabPsychographics = (props) => {
  const {
    influencerTwitchStreamer,
    influencerYoutubeStreamer,
    twitterProfile,
    networkFilter,
  } = props;

  let personalityData;
  let influencerPreferences;
  let influencerBehaviour;
  let influencerBehaviours;

  const activePlatforms = [
    influencerTwitchStreamer &&
      (influencerTwitchStreamer.personality ||
        influencerTwitchStreamer.behaviour) &&
      'twitch',
    influencerYoutubeStreamer &&
      (influencerYoutubeStreamer.personality ||
        influencerYoutubeStreamer.behaviour) &&
      'youtube',
    twitterProfile &&
      (twitterProfile.personality || twitterProfile.behaviour) &&
      'twitter',
  ].filter((e) => !!e);

  const [activePlatform, setActivePlatform] = useState('twitch');

  useEffect(() => {
    if (activePlatforms.length === 2 && networkFilter) {
      setActivePlatform(networkFilter);
    } else {
      setActivePlatform(activePlatforms[0]);
    }
  }, [activePlatforms.length]);

  if (activePlatform === 'twitch') {
    personalityData =
      (influencerTwitchStreamer && influencerTwitchStreamer.personality) || {};
    influencerBehaviour =
      (influencerTwitchStreamer && influencerTwitchStreamer.behaviour) || [];
  } else if (activePlatform === 'youtube') {
    personalityData =
      (influencerYoutubeStreamer && influencerYoutubeStreamer.personality) ||
      {};
    influencerBehaviour =
      (influencerYoutubeStreamer && influencerYoutubeStreamer.behaviour) || [];
  } else {
    personalityData = (twitterProfile && twitterProfile.personality) || {};
    influencerBehaviour = (twitterProfile && twitterProfile.behaviour) || [];
  }

  const { personality: rawPersonality = [], values = [] } = personalityData;

  const personality = [
    ...rawPersonality,
    ...rawPersonality.map(({ children }) => children).flat(1),
  ];

  if (influencerBehaviour) {
    const behaviours = influencerBehaviour
      .map(({ consumption_preferences }) => consumption_preferences)
      .flat(1)
      .map(({ data, name }) =>
        data.map((behaviour) => ({ ...behaviour, likeliness: name }))
      )
      .flat(1);

    influencerPreferences = behaviours.filter(
      ({ name }) => behaviourCategoryByBehaviour[name] === 'preference'
    );

    influencerBehaviours = behaviours.filter(
      ({ name }) => behaviourCategoryByBehaviour[name] === 'behaviour'
    );
  }

  return (
    <Grid container direction="column" wrap="nowrap" spacing={4}>
      <Grid item>
        <SectionTitle>Personality Insights</SectionTitle>
        {activePlatforms.length > 1 && (
          <InfluencerPlatformSelect
            activePlatform={activePlatform}
            activePlatforms={activePlatforms}
            onSelect={(platform) => setActivePlatform(platform)}
          />
        )}
      </Grid>

      <Grid item>
        <Grid container spacing={3}>
          {personality && (
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <CardTitle>Personality</CardTitle>
                </CardContent>
                <CardContent>
                  <Traits
                    traits={personality}
                    available={personality && personality.length}
                    communication="influencer"
                    name="influencer-values"
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {values && (
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <CardTitle>Values</CardTitle>
                </CardContent>
                <CardContent>
                  <Traits
                    traits={values}
                    available={values && values.length}
                    communication="influencer"
                    name="influencer-values"
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <SectionTitle>Content Creator Preferences</SectionTitle>
      </Grid>

      <Grid item>
        <Grid container spacing={4} alignItems="stretch">
          {influencerPreferences && (
            <>
              <Grid item xs={6} lg={6}>
                <Behaviours
                  category="lifestyle"
                  label="Lifestyle"
                  behaviours={influencerPreferences}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <Behaviours
                  category="purchasing"
                  label="Purchasing"
                  behaviours={influencerPreferences}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <SectionTitle>Content Creator Behaviours</SectionTitle>
      </Grid>

      <Grid item>
        <Grid container spacing={4} alignItems="stretch">
          {influencerBehaviours && (
            <>
              <Grid item xs={6}>
                <Behaviours
                  category="lifestyle"
                  label="Lifestyle"
                  behaviours={influencerBehaviours}
                />
              </Grid>
              <Grid item xs={6}>
                <Behaviours
                  category="purchasing"
                  label="Purchasing"
                  behaviours={influencerBehaviours}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InfluencerTabPsychographics;
