import { useReducer } from 'react';

const INITIATED = 'INITIATED';
const FAILED = 'FAILED';
const SUCCEEDED = 'SUCCEEDED';

const initial = {
  initiated: false,
  fetching: false,
  response: undefined,
  data: undefined,
  error: undefined,
};

const reducer = (state = initial, action) => {
  switch (action.type) {
    case INITIATED: {
      return {
        ...initial,
        initiated: true,
        fetching: true,
        succeeded: false,
      };
    }

    case FAILED: {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    }

    case SUCCEEDED: {
      return {
        ...state,
        fetching: false,
        response: action.response,
        data: action.data,
        succeeded: true,
      };
    }

    default:
      return state;
  }
};

function useRequest(request) {
  const [state, dispatch] = useReducer(reducer);

  function init() {
    dispatch({ type: INITIATED });

    function handleResponse(response) {
      if (response && response.json) {
        response.json().then((data) => {
          dispatch({ type: SUCCEEDED, response, data });
        });
      } else if (response) {
        dispatch({ type: SUCCEEDED, response, data: response });
      } else {
        console.warn('Response is missing for', request);
      }
    }

    function handleError(error) {
      console.error(error);
      dispatch({ type: FAILED, error });
    }

    request(...arguments)
      .then(handleResponse)
      .catch(handleError);
  }

  return { init, ...state };
}

export default useRequest;
