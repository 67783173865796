import React, { useCallback, useMemo } from 'react';

import InfluencerAvatar from '../../components/InfluencerAvatar';
// import _meanBy from 'lodash/meanBy';
// import _maxBy from 'lodash/maxBy';
// import _sumBy from 'lodash/sumBy';
import Table, { StatBox } from '../../Table';
import { findInfluencerByVideo, findVideoById } from '../../utils';

export const computeSummary = (usedLinkOccurrences) => {
  const occurrences = usedLinkOccurrences.length;

  const streams = new Set(
    usedLinkOccurrences.map((shoutout) => shoutout.video_id)
  ).size;

  // const avgCCV = _meanBy(usedLinkOccurrences, 'views') || 0;
  // const peakCCV = (_maxBy(usedLinkOccurrences, 'views') || { views: 0 }).views;
  // const totalViewers = _sumBy(usedLinkOccurrences, 'views') || 0;
  // const totalEMV = _sumBy(usedLinkOccurrences, 'emv') || 0;

  return {
    occurrences,
    streams,
    // avgCCV, peakCCV, totalViewers, totalEMV
  };
};

const getRows = ({ usedInfluencers, usedVideos, usedLinkOccurrences }) =>
  usedInfluencers.map((influencer) => {
    const linkOccurrences = usedLinkOccurrences.filter(
      (occurrence) =>
        findInfluencerByVideo(
          findVideoById(occurrence.video_id, usedVideos),
          usedInfluencers
        ).id === influencer.id
    );

    const {
      occurrences,
      streams,
      // avgCCV,
      // peakCCV,
      // totalViewers,
      // totalEMV,
    } = computeSummary(linkOccurrences);

    return {
      influencer,
      creator: influencer.name,
      occurrences,
      streams,
      // avgCCV,
      // peakCCV,
      // totalViewers,
      // totalEMV,
      key: influencer.id,
    };
  });

const COLS = [
  {
    label: 'Creator',
    key: 'creator',
    render: (row) => <InfluencerAvatar influencer={row.influencer} />,
    stretch: true,
  },
  {
    label: 'Videos',
    key: 'streams',
    render: (data) => <StatBox stat={data.streams} />,
  },
  {
    label: 'Shares',
    key: 'occurrences',
    render: (row) => <StatBox stat={row.occurrences} />,
  },
  // {
  //   label: 'Avg. CCV',
  //   key: 'avgCCV',
  //   render: (data) => <StatBox stat={data.avgCCV} />,
  // },
  // {
  //   label: 'Peak CCV',
  //   key: 'peakCCV',
  //   render: (data) => <StatBox stat={data.peakCCV} />,
  // },
  // {
  //   label: 'Total Viewers',
  //   key: 'totalViewers',
  //   render: (data) => <StatBox stat={data.totalViewers} />,
  // },
  // {
  //   label: 'Media Value',
  //   key: 'totalEMV',
  //   render: (data) => (
  //     <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
  //   ),
  // },
];

export const SummaryTable = ({
  usedInfluencers,
  usedLinkOccurrences,
  usedVideos,
  setSelectedCampaignInfluencer,
  link,
}) => {
  const rows = useMemo(
    () => getRows({ usedInfluencers, usedVideos, usedLinkOccurrences }),
    [usedVideos, usedLinkOccurrences, usedInfluencers]
  );

  const onRowClick = useCallback(
    ({ key }) => setSelectedCampaignInfluencer(key),
    [setSelectedCampaignInfluencer]
  );
  const idTable = `SV-linksharing-summary-${link.replace(/\s+/g, '')}`;

  return (
    <Table
      cols={COLS}
      rows={rows}
      defaultSortCol="occurrences"
      onRowClick={onRowClick}
      idTable={idTable}
    />
  );
};

export default SummaryTable;
