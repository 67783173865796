import overwriteDeep from '../../utils/overwrite-deep';

const httpErrorMiddleware = (store) => (next) => (action) => {
  const { meta } = action;

  if (!meta || !meta.request || meta.request.type !== 'failed')
    return next(action);

  /*
   * if it already has a toast meta set (probably because this already came through
   * this middleware or maybe because somebody actually configured a custom toast meta
   * in the end this middleware serves as fallback defaults for http-codes)
   */
  if (meta.toast) return next(action);

  /*
   * let every http-error trigger a toast with the http error message
   */
  const {
    payload: { error },
    meta: {
      request: { name },
    },
  } = action;

  store.dispatch(
    overwriteDeep(action, {
      meta: {
        toast: {
          type: 'error',
          lifespan: 4000,
          message: `[ ${name} ] ${error}`,
        },
      },
    })
  );
};

export default httpErrorMiddleware;
