import _orderBy from 'lodash/orderBy';
import memoize from 'proxy-memoize';

import { State } from '../../../../../store/types';
import { LoadingState, shouldFetch, shouldShowLoading } from '../../../types';
import { BrandFilter } from '../../types';
import { filterBrandDeliverables } from '../../utils';
import { LinkTrackingDeliverableStructure, SLICE_NAME } from './types';

const memoizedFilteredLinkTrackingDeliverablesStructures = memoize(
  ({
    campaignId,
    onlyBrandDeliverables,
    brandIdFilter,
    state,
    sortBy,
  }: {
    campaignId: number;
    onlyBrandDeliverables: boolean;
    brandIdFilter: BrandFilter;
    state: State;
    sortBy?: {
      key: keyof LinkTrackingDeliverableStructure;
      order: 'asc' | 'desc';
    };
  }) => {
    const allLinkTrackingDeliverables =
      state.campaigns.byId[campaignId]?.link_tracking_deliverables ?? [];

    return _orderBy(
      filterBrandDeliverables(
        allLinkTrackingDeliverables,
        onlyBrandDeliverables,
        brandIdFilter
      ),
      [sortBy.key || 'link'],
      [sortBy.order]
    );
  }
);

export const selectFilteredLinkTrackingDeliverablesStructures =
  ({
    campaignId,
    onlyBrandDeliverables,
    brandIdFilter,
    sortBy,
  }: {
    campaignId: number;
    onlyBrandDeliverables: boolean;
    brandIdFilter: BrandFilter;
    sortBy?: {
      key: keyof LinkTrackingDeliverableStructure;
      order: 'asc' | 'desc';
    };
  }) =>
  (state: State) =>
    memoizedFilteredLinkTrackingDeliverablesStructures({
      campaignId,
      onlyBrandDeliverables,
      brandIdFilter,
      state,
      sortBy,
    });

export const selectLinkTrackingDeliverableLoadingState =
  (linkTrackingDeliverableId: number) => (state: State) =>
    state[SLICE_NAME].loadingMap[linkTrackingDeliverableId] ??
    LoadingState.IDLE;

export const selectLinkTrackingDeliverableShowLoading =
  (linkTrackingDeliverableId: number) => (state: State) =>
    shouldShowLoading(
      selectLinkTrackingDeliverableLoadingState(linkTrackingDeliverableId)(
        state
      )
    );

export const selectShouldFetchLinkTrackingDeliverable =
  (linkTrackingDeliverableId: number) => (state: State) =>
    shouldFetch(
      selectLinkTrackingDeliverableLoadingState(linkTrackingDeliverableId)(
        state
      )
    );

export const selectLinkTrackingDeliverableById =
  (linkTrackingDeliverableId: number) => (state: State) =>
    state[SLICE_NAME].entities[linkTrackingDeliverableId];

export const selectLinkTrackingDeliverablesError = () => (state: State) =>
  state[SLICE_NAME].error;

const memoizedAllLinkTrackingDeliverableIdsByCampaignId = memoize(
  ({ campaignId, state }: { campaignId: number; state: State }) =>
    state.campaigns.byId[campaignId]?.link_tracking_deliverables.map(
      (deliverable) => deliverable.id
    ) ?? []
);

export const selectAllLinkTrackingDeliverableIdsByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedAllLinkTrackingDeliverableIdsByCampaignId({ campaignId, state });

// used by PDF report
const memoizedAllLinkTrackingDeliverablesByCampaignId = memoize(
  ({ campaignId, state }: { campaignId: number; state: State }) => {
    const allLinkTrackingDeliverableIds =
      selectAllLinkTrackingDeliverableIdsByCampaignId(campaignId)(state);

    return allLinkTrackingDeliverableIds.map((id) =>
      selectLinkTrackingDeliverableById(id)(state)
    );
  }
);

export const selectAllLinkTrackingDeliverablesByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedAllLinkTrackingDeliverablesByCampaignId({ campaignId, state });

export const selectAllLinkTrackingDeliverablesByCampaignIdShowLoading =
  (campaignId: number) => (state: State) => {
    const ids =
      selectAllLinkTrackingDeliverableIdsByCampaignId(campaignId)(state);

    return ids
      .map((id) => selectLinkTrackingDeliverableShowLoading(id)(state))
      .some((showLoading) => showLoading);
  };

const memoizedLinkTrackingDeliverablesToFetchByCampaignId = ({
  campaignId,
  state,
}: {
  campaignId: number;
  state: State;
}) => {
  const ids =
    selectAllLinkTrackingDeliverableIdsByCampaignId(campaignId)(state);

  return ids.filter((id) =>
    selectShouldFetchLinkTrackingDeliverable(id)(state)
  );
};

export const selectLinkTrackingDeliverablesToFetchByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedLinkTrackingDeliverablesToFetchByCampaignId({ campaignId, state });
