/*
 * takes two numbers
 * returns the relative percentage of the second number when compared to the first
 */
function percent(full, partial) {
  if (full === 0) full = 1;

  return Math.round((partial / full) * 100);
}

export default percent;
