import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import { CAMPAIGN_DELETE_CLICKED } from '../action-types';
import {
  campaignDeleteFailed,
  campaignDeleteRequested,
  campaignDeleteSucceeded,
} from '../models/campaigns/campaigns.actions';

function* deleteCampaign(action) {
  const {
    payload: { id },
  } = action;

  yield put(campaignDeleteRequested({ id }));

  try {
    yield call(ajax.deleteCampaign, id);

    yield put(push(`/campaigns`));

    yield put(campaignDeleteSucceeded({ id }));
  } catch (e) {
    console.error('Campaign Delete Error - ', e);
    yield put(
      campaignDeleteFailed({
        error: (e.bodyJson && e.bodyJson.errors) || 'Campaign Delete Error',
        id,
      })
    );
  }
}

export default function* root() {
  yield takeLatest(CAMPAIGN_DELETE_CLICKED, deleteCampaign);
}
