import { TOAST_EXPIRED, TOAST_SPAWNED } from '../../action-types';

const toast = (state = null, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOAST_SPAWNED: {
      return payload;
    }

    case TOAST_EXPIRED: {
      return null;
    }

    default:
      return state;
  }
};

export default toast;
