import { all, call, delay, put, takeLatest } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import { Dialog } from '../../common/containers';
import { MY_STATS_MOUNTED } from '../../store/action-types';
import { actions } from '../../store/actions';

function* getMyStats() {
  try {
    yield put(actions.myStatsServerDataRequest());

    let data;
    let count = 0;
    let ready = false;

    do {
      try {
        data = yield call(ajax.getMyStats);
        ready = true;
      } catch (e) {
        if (e.status !== 404) {
          ready = true;
        } // error, but don't retry
      }

      count = count + 1;

      if (!ready) {
        yield delay(3000);
      }
    } while (!ready && count <= 30); // 90 seconds

    yield put(actions.myStatsServerDataSuccess(data));
  } catch (e) {
    console.error(e);

    Dialog.show({
      caption: 'Failed to get my stats',
      message: (e.bodyJson && e.bodyJson.errors) || e.message,
      buttons: ['Got it'],
    });
  }
}

export default function* root() {
  yield all([takeLatest(MY_STATS_MOUNTED, getMyStats)]);
}
