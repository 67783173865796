import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';

function CampaignDateFilter(props) {
  const {
    campaignFilterStart,
    campaignFilterEnd,
    setCampaignFilterStart,
    setCampaignFilterEnd,
    campaignStartTime,
    campaignEndTime,
    style = {},
  } = props;

  return (
    <div style={style.div} className="campaign-date-filter">
      <KeyboardDatePicker
        autoOk
        name="campaignFilterStart"
        value={campaignFilterStart}
        label="From"
        inputVariant="outlined"
        format="DD/MM/YYYY"
        minDate={campaignStartTime}
        maxDate={campaignFilterEnd}
        onChange={(e) => setCampaignFilterStart(e)}
      />
      <KeyboardDatePicker
        style={{ marginLeft: 4 }}
        autoOk
        name="campaignFilterEnd"
        value={campaignFilterEnd}
        label="To"
        inputVariant="outlined"
        format="DD/MM/YYYY"
        minDate={campaignFilterStart}
        maxDate={campaignEndTime}
        onChange={(e) => setCampaignFilterEnd(e)}
      />
    </div>
  );
}

export default CampaignDateFilter;
