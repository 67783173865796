import qs from 'query-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { authenticateNFTUser, getUserData } from '../ajax';
import {
  INFLUENCERS_STATS,
  OOPS,
  PATH_SIGNIN,
} from '../common/constants/paths';
import actions from '../store/actions';

export const useDidTokenAutoLogin = ({
  isLoggedOut,
}: {
  isLoggedOut: boolean;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const did_token = qs.parse(history.location.search).did_token;
  const path = history.location.pathname;

  const isInfluencerPath = path === INFLUENCERS_STATS;
  const isOopsPath = path === OOPS;

  return useEffect(() => {
    if (!did_token && isLoggedOut && !isOopsPath) {
      history.push(PATH_SIGNIN);
    }

    if (did_token && isLoggedOut) {
      const loginAggeroUserUsingDidToken = async () => {
        try {
          const { token, errors: tokenErrors } = await authenticateNFTUser(
            did_token
          ).catch((e) => {
            throw new Error(e);
          });

          if (!token) {
            throw new Error(tokenErrors || 'No token');
          }

          const user = await getUserData(token).catch((e) => {
            throw new Error(e);
          });

          if (!user) {
            throw new Error('No user data');
          }

          if (isInfluencerPath) {
            dispatch(
              // @ts-ignore actions are imported using require(glob); this is very dumb and breaks ts
              actions.setLoggedInViewInfluencer()
            );
          }

          if (user) {
            dispatch(
              // @ts-ignore actions are imported using require(glob); this is very dumb and breaks ts
              actions.userSignedIn({
                ...user,
                displayName: user.name,
              })
            );
          }
        } catch (e) {
          history.push(OOPS);
          console.error(e);
        }
      };

      loginAggeroUserUsingDidToken();
    }
  }, [did_token, isLoggedOut, isInfluencerPath, history, dispatch, isOopsPath]);
};
