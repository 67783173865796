import { createAction } from '@reduxjs/toolkit';

export const showToast = createAction(
  'SHOW_TOAST',
  ({
    message,
    type,
    lifespan = 4000,
  }: {
    message: string;
    type: 'success' | 'warning' | 'error';
    lifespan?: number;
  }) => {
    return {
      payload: {},
      meta: {
        toast: {
          message,
          type,
          lifespan,
        },
      },
    };
  }
);
