import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as yup from 'yup';

import { getUserData } from '../../../ajax';
import { INFLUENCERS_SIGNUP, PATH_ROOT } from '../../../common/constants/paths';
import Container from '../../../components/Container';
import LoadingButton from '../../../components/LoadingButton';
import { useFetchOnMount } from '../../../hooks/useFetchOnMount';
import { store } from '../../../store';
import { history } from '../../../store';
import actions from '../../../store/actions';
import { getUserEmail } from '../../../store/models/user/user.selectors';
import {
  selectInfluencerInfo,
  selectInfluencerInfoPending,
  selectPutInfluencerInfoPending,
  selectShouldFetchInfluencerInfo,
} from '../store/InfluencerInfo.selectors';
import {
  fetchInfluencerInfo,
  putInfluencerInfo,
} from '../store/InfluencerInfo.thunks';
import {
  EMPTY_FORM_VALUES,
  formValuesToInfluencerInfo,
  InfluencerInfoFormValues,
  influencerInfoToFormValues,
} from '../utils/influencerInfoFormUtils';
import DescribesForm from './DescribesForm/DescribesForm';
import InfoForm from './InfoForm/InfoForm';
import logo from './logo.svg';
import SignUpForm from './SignUpForm/SignUpForm';
import SocialAccountsForm from './SocialAccountsForm/SocialAccountsForm';
import { selectSignupInfluencerWithPasswordShowLoading } from './store/InfluencerSignupWizard.selectors';
import { signupInfluencerWithPassword } from './store/InfluencerSignupWizard.thunks';
import SubInfoForm from './SubInfoForm/SubInfoForm';
import WelcomeStep from './WelcomeStep/WelcomeStep';

type WizardFormValues = InfluencerInfoFormValues & {
  email: string;
  password: string;
  repeat_password: string;
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('Enter email')
    .max(75, 'Must be shorter than 75 character'),
  password: yup.string().required('Enter password'),
  repeat_password: yup
    .string()
    .required('Repeat password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

enum FormStep {
  Welcome = 'welcome',
  Register = 'register',
  Describes = 'describes',
  Info = 'info',
  SubInfo = 'subinfo',
  SocialAccounts = 'social',
}

const ORDERED_STEPS = [
  FormStep.Welcome,
  FormStep.Register,
  FormStep.Describes,
  FormStep.Info,
  FormStep.SubInfo,
  FormStep.SocialAccounts,
];

const getStepIndex = (step: FormStep) => ORDERED_STEPS.indexOf(step);

const STEPS = [
  {
    component: WelcomeStep,
    title: 'marketplace.signup.welcomeStep.title',
  },
  {
    component: SignUpForm,
    title: 'marketplace.signup.signupForm.title',
    subTitle: 'marketplace.signup.signupForm.subTitle',
  },
  {
    component: DescribesForm,
    title: 'Nice to meet you',
    subTitle: 'Select which of the following describes you best',
  },
  {
    component: InfoForm,
    title: 'Tell us more about you',
    subTitle:
      'Yeah, we know it looks overwhelming, but keep in mind that this can help you get more sponsorship deals. You can also skip ;)',
  },
  {
    component: SubInfoForm,
    title: 'Where should we ping you?',
    subTitle:
      "We understand you're busy, playing :) ...but this should be fast. Please tell us where would you like to receive sponsorship opportunities",
  },
  {
    component: SocialAccountsForm,
    title: 'Connect your social accounts',
    subTitle:
      'Get powerful performance insights and understand your audience across all your channels',
  },
];

const useStyles = makeStyles({
  container: {
    padding: '30px 50px',
    maxWidth: 640,
    minWidth: 640,
    overflow: 'unset',
    background: 'white',
    minHeight: '370px',
    border: '2px solid #dbdbdb',
    borderRadius: '4px',
    strokeWidth: 1,
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    padding: '80px 0',
    minHeight: '100vh',
    justifyContent: 'center',
  },
  buttonsStyles: {
    width: 95,
    fontSize: '0.875rem',
    marginTop: '24px',
    fontWeight: 'bold',
    padding: 10,
  },
  buttonsStylesCentered: {
    fontSize: '0.875rem',
    marginTop: '24px',
    fontWeight: 'bold',
    padding: '12px 44px',
  },
  error: {
    color: 'red',
  },
});

const redirectIfLoggedIn = async (
  dispatch: Dispatch<any>,
  formStep: FormStep
) => {
  const { token } = store.getState().account.authUser || {};

  if (token == null) return;

  const user = await getUserData(token);
  dispatch(
    // @ts-ignore actions are imported using require(glob); this is very dumb and breaks ts
    actions.userSignedIn({
      ...user,
      displayName: user.name,
    })
  );

  // don't redirect if the socialCallback param is set
  if (formStep !== FormStep.SocialAccounts) {
    // if the user completed the wizard, redirect away from the wizard
    if (user.wizard_completed) {
      return history.replace(PATH_ROOT);
    }
    history.replace(`${INFLUENCERS_SIGNUP}/${FormStep.Describes}`);
  }
};

export const InfluencerSignupWizard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    params: { step },
  } = useRouteMatch<{ step?: FormStep }>();

  const history = useHistory();

  const { t } = useTranslation();

  const [loadingLoggedInUser, setLoadingLoggedInUser] = useState(true);

  let formStep = step ?? FormStep.Welcome;

  const setFormStep = useCallback(
    (step) => {
      if (step === FormStep.Describes) {
        // no going back from here
        return history.replace(`${INFLUENCERS_SIGNUP}/${step}`);
      }
      history.push(`${INFLUENCERS_SIGNUP}/${step}`);
    },
    [history]
  );

  const influencerInfo = useSelector(selectInfluencerInfo());

  const influencerInfoPending = useSelector(selectInfluencerInfoPending());

  const signupInfluencerWithPasswordShowLoading = useSelector(
    selectSignupInfluencerWithPasswordShowLoading()
  );

  const putInfluencerInfoPending = useSelector(
    selectPutInfluencerInfoPending()
  );

  useEffect(() => {
    redirectIfLoggedIn(dispatch, formStep).then(() => {
      setLoadingLoggedInUser(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // redirect if

  useFetchOnMount(
    (state) =>
      selectShouldFetchInfluencerInfo()(state) && getUserEmail(state) != null,
    fetchInfluencerInfo
  );

  const goToPreviousStep = useCallback(() => {
    const stepIndex = getStepIndex(formStep);

    if (stepIndex > 0) {
      setFormStep(ORDERED_STEPS[stepIndex - 1]);
    }
  }, [formStep, setFormStep]);

  const goToNextStep = useCallback(() => {
    if (formStep === FormStep.SocialAccounts) return history.replace(PATH_ROOT);

    setFormStep(ORDERED_STEPS[getStepIndex(formStep) + 1]);
  }, [formStep, setFormStep, history]);

  const {
    title,
    subTitle,
    component: StepComponent,
  } = STEPS[getStepIndex(formStep)];

  const initialValues: WizardFormValues = useMemo(() => {
    let values = EMPTY_FORM_VALUES;

    if (influencerInfo) values = influencerInfoToFormValues(influencerInfo);

    // this form also has email and password fields
    return { ...values, email: '', password: '', repeat_password: '' };
  }, [influencerInfo]);

  const onSubmit = useCallback(
    (values: WizardFormValues) => {
      const onSuccess = () => {
        goToNextStep();
      };

      // if submitting second step, submit email and password
      if (formStep === FormStep.Register) {
        dispatch(
          signupInfluencerWithPassword({
            email: values.email,
            password: values.password,
            onSuccess,
          })
        );
        localStorage.setItem('welcome_message', 'true');
      }

      // if submitting forth step, submit influencer details
      if (formStep === FormStep.SubInfo) {
        dispatch(
          putInfluencerInfo({
            onSuccess,
            influencerInfo: formValuesToInfluencerInfo(values),
          })
        );
      }
    },
    [formStep, dispatch, goToNextStep]
  );
  if (loadingLoggedInUser || influencerInfoPending)
    return (
      <Container className={classes.scrollContainer}>
        <img
          style={{ width: '100px', marginBottom: 55 }}
          src={logo}
          alt={logo}
        ></img>
        <Container className={classes.container}>
          <Box
            height={400}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        </Container>
      </Container>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      isInitialValid={false}
    >
      {(formik) => {
        const { values, isValid } = formik;

        const submitButtonLoading =
          formStep === FormStep.Register
            ? signupInfluencerWithPasswordShowLoading
            : putInfluencerInfoPending;

        return (
          <Container className={classes.scrollContainer}>
            <img
              style={{ width: '100px', marginBottom: 51 }}
              src={logo}
              alt={logo}
            ></img>
            <Container className={classes.container}>
              <Grid container wrap="nowrap" direction="column">
                <Grid item>
                  <Box mb={3}>
                    {title && (
                      <Box textAlign="center" fontSize="1.7rem">
                        {t(title as any)}
                      </Box>
                    )}
                    {subTitle && (
                      <Box textAlign="center" fontSize="1.3rem">
                        {t(subTitle as any)}
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Form>
                  <StepComponent onNextStep={goToNextStep} />
                  {formStep !== FormStep.Welcome && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent:
                          formStep === FormStep.Register
                            ? 'center'
                            : 'space-between',
                        marginTop: 'auto',
                      }}
                    >
                      {![FormStep.Register, FormStep.Describes].includes(
                        formStep
                      ) && (
                        <Button
                          className={classes.buttonsStyles}
                          onClick={goToPreviousStep}
                          variant="outlined"
                          color="primary"
                          type="button"
                        >
                          PREV
                        </Button>
                      )}
                      {![FormStep.Register, FormStep.SubInfo].includes(
                        formStep
                      ) && (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          className={classes.buttonsStyles}
                          style={{
                            marginLeft:
                              formStep === FormStep.Describes
                                ? 'auto'
                                : undefined,
                          }}
                          onClick={goToNextStep}
                        >
                          NEXT
                        </Button>
                      )}

                      {[FormStep.Register, FormStep.SubInfo].includes(
                        formStep
                      ) && (
                        <LoadingButton
                          key="submit"
                          type="submit"
                          variant="contained"
                          className={classes.buttonsStyles}
                          color="primary"
                          disabled={
                            submitButtonLoading ||
                            (formStep === FormStep.Register && !isValid)
                          }
                          loading={submitButtonLoading}
                          onClick={() => onSubmit(values)}
                        >
                          {formStep === FormStep.Register ? 'Continue' : 'Next'}
                        </LoadingButton>
                      )}
                    </div>
                  )}
                </Form>
              </Grid>
            </Container>
          </Container>
        );
      }}
    </Formik>
  );
};
