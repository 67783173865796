import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import usePrevious from '../hooks/use-previous';
import { selectToast } from '../store/models/toast/toast.selectors';
// import delay from '../utils/delay';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function SlideLeft(props) {
  return <Slide {...props} direction="left" />;
}

function GlobalToast() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  function close() {
    setOpen(false);
  }

  const toast = useSelector(selectToast);

  const previousToast = usePrevious(toast); // for transition purposes

  useEffect(() => {
    if (toast && !previousToast) {
      setOpen(true);
    }
  }, [toast, previousToast]);

  const { type, message, lifespan } = toast || previousToast || {};

  const Icon = variantIcon[type];

  return (
    <Snackbar
      open={open}
      onClose={close}
      autoHideDuration={lifespan - 400}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={SlideLeft}
    >
      <SnackbarContent
        className={classes[type]}
        message={
          toast && (
            <span className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          )
        }
      />
    </Snackbar>
  );
}

export default GlobalToast;
