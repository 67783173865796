import _cloneDeep from 'lodash/cloneDeep';
import _sortBy from 'lodash/sortBy';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { getCampaign, recordUserSession } from '../../ajax';
import { normalizeLanguageKey } from '../../views/Campaign/utils';
import { CAMPAIGN_MOUNTED } from '../action-types';
import {
  campaignRequested,
  campaignRequestFailed,
  campaignRequestSucceeded,
} from '../models/campaigns/campaigns.actions';
import { getUserId } from '../models/user/user.selectors';

let isNotRequest = true;
export const stopMountedCampaign = () => {
  setTimeout(() => {
    isNotRequest = true;
  }, 3500);
  isNotRequest = false;
};

function* fetchCampaign(campaignId) {
  let fetchAttemptsCount = 0;
  let campaign = null;

  do {
    campaign = yield call(getCampaign, campaignId);

    campaign.structure_loaded = true;

    fetchAttemptsCount = fetchAttemptsCount + 1;

    if (!campaign.processed) {
      yield delay(3000);
    }
  } while (!campaign.processed && fetchAttemptsCount <= 40 && isNotRequest); // 2 minutes

  return campaign;
}

const normalizeCampaignVideosLanguage = (campaign) => {
  campaign.videos = campaign.videos.map((video) => {
    let language = video.language;

    language = normalizeLanguageKey(language);

    return {
      ...video,
      language,
    };
  });

  return campaign;
};

function* getCampaignLocal(action) {
  const {
    payload: { campaignId },
  } = action;

  let campaign;

  try {
    const oldUserId = yield select(getUserId);
    const currentCampaign = yield select(
      (state) => state.campaigns.byId[campaignId]
    );

    // If the campaign structure has not been fetched before - fetch it
    if (!currentCampaign || !currentCampaign?.structure_loaded) {
      yield put(campaignRequested({ campaignId }));
      campaign = yield call(fetchCampaign, campaignId);
    } else {
      campaign = _cloneDeep(currentCampaign);
    }

    const newUserId = yield select(getUserId);

    yield call(recordUserSession, { action: 'check-campaign' });

    if (newUserId !== oldUserId) return;

    if (campaign.processed) {
      campaign.all_shoutout_deliverables = _sortBy(
        (campaign.shoutout_deliverables ?? []).concat(
          (campaign.shoutout_deliverables_v2 ?? []).map((deliverable) => ({
            ...deliverable,
            v2: true,
          }))
        ),
        'id'
      );

      normalizeCampaignVideosLanguage(campaign);
    }

    yield put(campaignRequestSucceeded({ campaign, campaignId }));
  } catch (e) {
    console.error('Campaign Request Error - ', e);
    yield put(
      campaignRequestFailed({
        error: (e.bodyJson && e.bodyJson.errors) || 'Campaign Request Error',
        campaignId,
      })
    );

    return;
  }
}

export default function* root() {
  yield takeLatest(CAMPAIGN_MOUNTED, getCampaignLocal);
}
