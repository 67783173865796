import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCreatorSocialPosts } from '../../../../../ajax';
import { State } from '../../../../../store/types';
import { selectDateFilter } from '../../store/Stats.selectors';
import { selectSocialPostsPlatformFilter } from './SocialMediaPosts.selectors';
import { SLICE_NAME, SocialPostsAPIResponse } from './types';

export const fetchSocialMediaPosts = createAsyncThunk<SocialPostsAPIResponse>(
  `${SLICE_NAME}/fetchSocialMediaPosts`,
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as State;
    const platformFilter = selectSocialPostsPlatformFilter()(state);
    const dates = selectDateFilter()(state);

    return getCreatorSocialPosts({ platformFilter, dates });
  }
);
