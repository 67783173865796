import PersonIcon from '@material-ui/icons/Person';
import VideocamIcon from '@material-ui/icons/Videocam';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CampaignCreatorAndDayFilter from '../../CampaignCreatorAndDayFilter';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ChatsIcon from '../../components/ChatsIcon';
import DetailedViewToggle, {
  useDetailedViewToggle,
} from '../../components/DetailedViewToggle';
import OverviewCard, {
  PlaceholderOverviewRow,
} from '../../components/OverviewCard';
import SectionTitle from '../../components/SectionTitle';
import { findInfluencerByVideo, findVideoById } from '../../utils';
import PercentIcon from '../PercentIcon';
import DetailedTable from './DetailedTable';
import {
  selectChatMentionDeliverableById,
  selectChatMentionDeliverableShowLoading,
  selectShouldFetchChatMentionDeliverable,
} from './store/ChatMentionDeliverables.selectors';
import {
  deleteMultipleChatMentions,
  fetchChatMentionDeliverable,
} from './store/ChatMentionDeliverables.thunks';
import { ChatMention, ChatMentionDeliverableStructure } from './store/types';
import SummaryTable, { computeSummary } from './SummaryTable';

const computeOverview = ({ usedComments, usedInfluencers, usedVideos }) => {
  const creators = new Set(
    usedComments.map(
      (comment) =>
        findInfluencerByVideo(
          findVideoById(comment.video_id, usedVideos),
          usedInfluencers
        ).id
    )
  ).size;

  const {
    mentions,
    streams,
    perTotalComments,
    totalImpressions,
    totalEMV,
    mentions_YT,
  } = computeSummary({
    usedComments,
    usedVideos,
  });

  return {
    mentions,
    creators,
    streams,
    perTotalComments,
    totalImpressions,
    totalEMV,
    mentions_YT,
  };
};

interface OverviewProps {
  keyword: string;
  usedComments: ChatMention[];
  usedInfluencers: any[];
  usedVideos: any[];
  showLoading: boolean;
}

const Overview = React.memo<OverviewProps>(
  ({ keyword, usedComments, usedInfluencers, usedVideos, showLoading }) => {
    const header = (
      <SectionTitle>
        <b>{keyword}</b> chat mentions
      </SectionTitle>
    );

    if (showLoading) {
      return (
        <div>
          {header}

          <PlaceholderOverviewRow nCards={usedInfluencers.length > 0 ? 4 : 3} />
        </div>
      );
    }

    const {
      mentions,
      creators,
      streams,
      perTotalComments,
      mentions_YT,
      // totalImpressions,
      // totalEMV,
    } = computeOverview({
      usedComments,
      usedVideos,
      usedInfluencers,
    });

    return (
      <div>
        {header}
        <OverviewCard.Container>
          <OverviewCard
            label="Creators"
            color="teal"
            main={{ stat: creators }}
            icon={<PersonIcon />}
            outOf={usedInfluencers.length}
            id={`sponsorshipVal-chatmentions-creators-${keyword.replace(
              /\s+/g,
              ''
            )}`}
          />
          <OverviewCard
            label="Videos"
            color="orange"
            main={{ stat: streams }}
            icon={<VideocamIcon />}
            id={`sponsorshipVal-chatmentions-streams-${keyword.replace(
              /\s+/g,
              ''
            )}`}
          />
          <OverviewCard
            label="Mentions"
            info="The total number of mentions found in the live chat and video comments section."
            color="blue"
            main={{ stat: mentions }}
            secondary={{
              label: 'video comm.',
              stat: mentions_YT,
              info: 'The number of mentions found in YouTube video comments.',
            }}
            icon={<ChatsIcon />}
            id={`sponsorshipVal-chatmentions-mentions-${keyword.replace(
              /\s+/g,
              ''
            )}`}
          />
          <OverviewCard
            label="% of total cmnts."
            color="purple"
            main={{ stat: perTotalComments, suffix: '%' }}
            icon={<PercentIcon />}
            id={`sponsorshipVal-chatmentions-totalcmnts-${keyword.replace(
              /\s+/g,
              ''
            )}`}
          />
          {/*<OverviewCard
            label="Total viewers"
            color="purple"
            main={{ stat: totalImpressions }}
            icon={<VisibilityIcon />}
          />
          <OverviewCard
            label="Media value"
            color="green"
            main={{ stat: totalEMV, prefix: '$', format: '0,[0].[0]a' }}
            icon={<LocalAtmIcon />}
          />*/}
        </OverviewCard.Container>
      </div>
    );
  }
);

interface ChatMentionDeliverableProps {
  chatMentionDeliverableStructure: ChatMentionDeliverableStructure;
  usedInfluencers: any[];
  usedVideos: any[];
  usedVideosIds: Set<number>;
  timeZone: string;
  setSelectedCampaignInfluencer: (inf: any) => void;
  setByCreator: (byCreator: boolean) => void;
  isFilteredByCreator: boolean;
  isSponsorship: boolean;
  deleteComment: (
    comment: ChatMention & { chatMentionDeliverableId: number }
  ) => void;
}

export const ChatMentionDeliverable = React.memo<ChatMentionDeliverableProps>(
  ({
    chatMentionDeliverableStructure,
    usedInfluencers,
    usedVideos,
    usedVideosIds,
    timeZone,
    setSelectedCampaignInfluencer,
    deleteComment,
    isFilteredByCreator,
    setByCreator,
    isSponsorship,
  }) => {
    const {
      keyword,
      campaign_id: campaignId,
      id: chatMentionDeliverableId,
    } = chatMentionDeliverableStructure;

    const dispatch = useDispatch();

    const showLoading = useSelector(
      selectChatMentionDeliverableShowLoading(chatMentionDeliverableId)
    );

    const shouldFetch = useSelector(
      selectShouldFetchChatMentionDeliverable(chatMentionDeliverableId)
    );

    useEffect(() => {
      if (shouldFetch) {
        dispatch(
          fetchChatMentionDeliverable({ chatMentionDeliverableId, campaignId })
        );
      }
    }, [campaignId, chatMentionDeliverableId, dispatch, shouldFetch]);

    const chatMentionDeliverable = useSelector(
      selectChatMentionDeliverableById(chatMentionDeliverableId)
    );

    const usedComments = useMemo(
      () =>
        showLoading || chatMentionDeliverable == null
          ? []
          : chatMentionDeliverable.video_comments.filter((shoutout) =>
              usedVideosIds.has(shoutout.video_id)
            ),
      [showLoading, chatMentionDeliverable, usedVideosIds]
    );

    const setCampaignCommentDelete = useCallback(
      (comment: ChatMention) =>
        deleteComment({ ...comment, chatMentionDeliverableId }),
      [chatMentionDeliverableId, deleteComment]
    );

    const { detailed, setDetailed } = useDetailedViewToggle(usedInfluencers);

    const onMultipleCommentsDelete = (ids: number[]) => {
      const comments = usedComments.filter((comment) =>
        ids.includes(comment.id)
      );

      dispatch(
        deleteMultipleChatMentions({
          campaignId,
          chatMentionDeliverableId,
          deleteAll: false,
          commentIds: ids,
          commenterExternalIds: comments.map(
            (comment) => comment.commenter_external_id
          ),
        })
      );
    };

    return (
      <div style={{ paddingBottom: 100 }} id={`chat-mentions/${keyword}`}>
        <Overview
          keyword={keyword}
          usedComments={usedComments}
          usedInfluencers={usedInfluencers}
          usedVideos={usedVideos}
          showLoading={showLoading}
        />
        {usedComments.length > 0 && (
          <>
            <DetailedViewToggle
              {...{ detailed, setDetailed, usedInfluencers }}
            />
            {isSponsorship && !detailed && (
              <CampaignCreatorAndDayFilter
                setByCreator={setByCreator}
                isFilteredByCreator={isFilteredByCreator}
              />
            )}

            {!detailed && (
              <SummaryTable
                usedInfluencers={usedInfluencers}
                usedVideos={usedVideos}
                usedComments={usedComments}
                setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
                isFilteredByCreator={isFilteredByCreator}
                timeZone={timeZone}
                isSponsorship={isSponsorship}
                content={keyword}
              />
            )}
            {detailed && (
              <DetailedTable
                setCampaignCommentDelete={setCampaignCommentDelete}
                usedInfluencers={usedInfluencers}
                timeZone={timeZone}
                usedVideos={usedVideos}
                usedComments={usedComments}
                content={keyword}
                onMultipleCommentsDelete={onMultipleCommentsDelete}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default ChatMentionDeliverable;
