export default [
  { name: 'Afrikaans' },
  { name: 'Albanian' },
  { name: 'Arabic' },
  // { name: "Bengali" },
  { name: 'Bulgarian' },
  { name: 'Chinese' },
  { name: 'Croatian' },
  { name: 'Czech' },
  { name: 'Danish' },
  { name: 'Dutch; Flemish' },
  { name: 'English' },
  { name: 'Estonian' },
  { name: 'Finnish' },
  { name: 'French' },
  { name: 'German' },
  { name: 'Hebrew' },
  { name: 'Hindi' },
  { name: 'Hungarian' },
  { name: 'Indonesian' },
  { name: 'Italian' },
  { name: 'Japanese' },
  { name: 'Korean' },
  { name: 'Latvian' },
  { name: 'Lithuanian' },
  { name: 'Macedonian' },
  { name: 'Norwegian' },
  { name: 'Persian' },
  { name: 'Polish' },
  { name: 'Portuguese' },
  { name: 'Romanian' },
  { name: 'Russian' },
  { name: 'Slovak' },
  { name: 'Slovenian' },
  { name: 'Somali' },
  { name: 'Spanish' },
  { name: 'Swahili' },
  { name: 'Swedish' },
  { name: 'Tagalog' },
  { name: 'Taiwanese Mandarin' },
  { name: 'Thai' },
  { name: 'Turkish' },
  { name: 'Ukrainian' },
  // { name: "Urdu" },
  { name: 'Vietnamese' },
  { name: 'Welsh' },
];
