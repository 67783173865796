import React from 'react';

import { TwitterSocialPage } from '../../../store/types';
import { BoxInfo } from '../InfluencerOverviewPerformance';

export const OverviewPerformanceTwitter = ({
  followers_count,
  avg_likes_per_post,
  avg_posts_per_day,
  avg_shares_per_post,
}: TwitterSocialPage) => {
  return (
    <>
      <BoxInfo
        label="FOLLOWERS"
        info="Total followers."
        stat={followers_count}
      />
      <BoxInfo
        label="AVG. POSTS / DAY"
        info="Number of twitter posts per day in the last 90 days."
        stat={avg_posts_per_day}
      />
      <BoxInfo
        label="AVG. RTs / POST"
        info="Average retweets per post in the last 90 days."
        stat={avg_shares_per_post}
      />
      <BoxInfo
        label="AVG. LIKES / POST"
        info="Average likes per post in the last 90 days."
        stat={avg_likes_per_post}
      />
    </>
  );
};
