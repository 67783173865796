import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import queryClient from '../queryClient';
import { reducer as bannerDeliverables } from '../views/Campaign/DeliverablesTab/Banners/store/BannerDeliverables.slice';
import { reducer as chatMentionDeliverables } from '../views/Campaign/DeliverablesTab/ChatMentions/store/ChatMentionDeliverables.slice';
import { reducer as linkTrackingDeliverables } from '../views/Campaign/DeliverablesTab/LinkSharing/store/LinkTrackingDeliverables.slice';
import { reducer as shoutoutDeliverables } from '../views/Campaign/DeliverablesTab/Shoutouts/store/ShoutoutDeliverables.slice';
import { reducer as socialMediaPerformance } from '../views/Campaign/PerformanceTab/SocialMedia/store/SocialMediaPerformance.slice';
import { reducer as campaignFilters } from '../views/Campaign/utils/CampaignFiltersStore';
import { influencerOnboardingApi } from '../views/CampaignInfluencerOnboarding/store/api';
import { reducer as influencerSignin } from '../views/influencerViews/InfluencerSignin/store/InfluencerSignin.slice';
import { reducer as influencerSignupWizard } from '../views/influencerViews/InfluencerSignupWizard/store/InfluencerSignupWizard.slice';
import { reducer as influencerAudience } from '../views/influencerViews/Stats/Audience/store/Audience.slice';
import { reducer as influencerSocialPosts } from '../views/influencerViews/Stats/SocialMedia/store/SocialMediaPosts.slice';
import { reducer as influencerStats } from '../views/influencerViews/Stats/store/Stats.slice';
import { reducer as influencerStreams } from '../views/influencerViews/Stats/Streaming/store/Streams.slice';
import { reducer as influencerInfo } from '../views/influencerViews/store/InfluencerInfo.slice';
import campaignTab from './models/campaign-tab/campaign-tab.reducer';
import campaigns from './models/campaigns/campaigns.reducer';
import communication from './models/communication/communication.reducer';
import influencers from './models/influencers/influencers.reducer';
import brands from './models/my-brands/my-brands.reducer';
import toast from './models/toast/toast.reducer';
import watchlists from './models/watchlists/watchlists.reducer';
import location from './reducers-folder/location.reducer';
import searchInfluencers from './reducers-folder/search-influencers.reducer';

export default (history) => {
  const context = require.context(
    '..',
    true,
    /\.\/(common|views).*\/redux\.js$/
  );

  const projectReducers = context.keys().reduce(
    (reduction, key) => ({
      ...reduction,
      [context(key).STORAGE_PREFIX]: context(key).reducer,
    }),
    {}
  );

  const appReducer = combineReducers({
    ...projectReducers,
    form,
    socialMediaPerformance,
    influencerAudience,
    influencerSignin,
    influencerSignupWizard,
    shoutoutDeliverables,
    bannerDeliverables,
    linkTrackingDeliverables,
    chatMentionDeliverables,
    influencerInfo,
    influencerSocialPosts,
    influencerStreams,
    campaignFilters,
    campaigns,
    communication,
    brands,
    toast,
    searchInfluencers,
    influencers,
    location,
    router: connectRouter(history),
    watchlists,
    campaignTab,
    influencerStats,
    [influencerOnboardingApi.reducerPath]: influencerOnboardingApi.reducer,
  });

  return (state, action) => {
    if (action.type === 'RESET_APP') {
      state = undefined;

      queryClient.clear();
    }
    return appReducer(state, action);
  };
};
