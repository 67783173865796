import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import momentTz from 'moment-timezone';
import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import {
  deleteBannerOccurence,
  getCampaignBannerOccurrences,
} from '../../../../ajax';
import { getShouldShowRemoveBannerOccurrenceButton } from '../../../../store/models/user/user.selectors';
import VideoPreview from '../../components/VideoPreview';
import Table, { StatBox, TableText } from '../../Table';
import { getMomentURL } from '../../utils';

const getCols = ({ onRemove, shouldShowRemoveBannerOccurrenceButton }) =>
  [
    {
      label: 'Time on Screen',
      key: 'timeOnScreenTimestamp',
      render: (data) => (
        <Box alignItems="flex-start" flexDirection="column" display="flex">
          <StatBox stat={data.timeOnScreen} format={null} />
          <a
            href={data.videoUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#0048f2',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <OpenInNewIcon fontSize="inherit" style={{ marginRight: 4 }} />
            <span>VIEW MOMENT</span>
          </a>
        </Box>
      ),
      stretch: true,
    },
    {
      label: 'From',
      key: 'fromTimeStamp',
      render: (data) => (
        <TableText fontSize="0.8rem">
          {data.fromDate},
          <br />
          {data.fromTime}
        </TableText>
      ),
      stretch: true,
    },
    {
      label: 'To',
      key: 'toTimeStamp',
      render: (data) => (
        <TableText fontSize="0.8rem">
          {data.toDate},
          <br />
          {data.toTime}
        </TableText>
      ),
      stretch: true,
    },
    {
      label: 'Avg. CCV',
      key: 'avgCCV',
      render: (data) => <StatBox stat={data.avgCCV} />,
    },
    shouldShowRemoveBannerOccurrenceButton
      ? {
          label: '',
          key: 'remove',
          render: (data) => (
            <IconButton onClick={() => onRemove(data)}>
              <DeleteIcon style={{ color: 'red' }} />
            </IconButton>
          ),
        }
      : null,
  ].filter((c) => c !== null);

const getRows = ({ rows, video, timeZone }) =>
  rows.map((row) => {
    const videoTime = momentTz.tz(video.published_at, timeZone);
    const fromTimeStamp = row.start_at;
    const from = videoTime.clone().add(fromTimeStamp, 'seconds');
    const fromDate = from.format('Do MMM YYYY');
    const fromTime = from.format('hh:mm:ss A');

    const toTimeStamp = row.end_at;

    const to = videoTime.clone().add(toTimeStamp, 'seconds');
    const toDate = to.format('Do MMM YYYY');
    const toTime = to.format('hh:mm:ss A');

    const timeOnScreenTimestamp = toTimeStamp - fromTimeStamp + 1;
    const timeOnScreen = momentTz
      .duration(timeOnScreenTimestamp, 'seconds')
      .format('mm[m] ss[s]');

    // need to convert to milliseconds
    const videoUrl = getMomentURL(video, fromTimeStamp * 1000);

    return {
      key: row.id,
      videoUrl,
      timeOnScreen,
      timeOnScreenTimestamp,
      fromTimeStamp,
      fromDate,
      fromTime,
      toTimeStamp,
      toDate,
      toTime,
      avgCCV: row.avg_ccv,
    };
  });

export const TimestampsDialog = ({
  open,
  onClose,
  video,
  influencer,
  bannerDeliverableId,
  campaignId,
  timeZone,
  thumbnail,
}) => {
  const shouldShowRemoveBannerOccurrenceButton = useSelector(
    getShouldShowRemoveBannerOccurrenceButton
  );

  const queryKey = `banner-occurrences-${bannerDeliverableId}-${video.id}`;

  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery(
    queryKey,
    () =>
      getCampaignBannerOccurrences(campaignId, bannerDeliverableId, video.id),

    { enabled: open }
  );

  const removeMutation = useMutation(
    (row) =>
      deleteBannerOccurence({
        campaignId,
        deliverableId: bannerDeliverableId,
        occurrenceId: row.key,
      }),
    {
      onMutate: async (row) => {
        await queryClient.cancelQueries();

        const previousValue = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, (data) =>
          data.filter((d) => d.id !== row.key)
        );

        return previousValue;
      },
      onError: (_, __, previousValue) =>
        queryClient.setQueryData(queryKey, previousValue),
    }
  );

  const rows = useMemo(() => {
    if (!data) return null;

    return getRows({ rows: data, video, timeZone });
  }, [data]);

  const cols = useMemo(() => {
    return getCols({
      onRemove: removeMutation.mutate,
      shouldShowRemoveBannerOccurrenceButton,
    });
  }, [removeMutation.mutate, shouldShowRemoveBannerOccurrenceButton]);

  const renderContent = () => {
    if (isLoading)
      return (
        <Box
          height={250}
          minWidth={800}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );

    if (error) return <Typography>Error {error.message}</Typography>;

    return (
      <Table
        cols={cols}
        rows={rows}
        defaultSortCol="fromTimeStamp"
        noBorders
        defaultIncreasing
      />
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle style={{ position: 'relative' }} disableTypography>
        <Typography variant="h6">Banner time stamps</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <VideoPreview
          video={video}
          influencer={influencer}
          timeZone={timeZone}
          bannerThumbnail={thumbnail}
        />
      </DialogContent>
      {renderContent()}
    </Dialog>
  );
};

export default TimestampsDialog;
