import { SET_CAMPAIGN_TAB } from '../../action-types';

const campaignTab = (state = 'all', action) => {
  if (action.type === SET_CAMPAIGN_TAB) {
    return action.payload;
  }

  return state;
};

export default campaignTab;
