import React, { useState } from 'react';

import { ChatMentionDeliverable } from '../DeliverablesTab/ChatMentions/store/types';
import { ShoutoutDeliverableV2 } from '../DeliverablesTab/Shoutouts/store/types';
import BannerTrack, { ExtendedBannerDeliverable } from './BannerTrack';
import VideoBrush from './Brush';
import CCVMentions from './CCVMentions';
import { BrushBounds, CCVData, STACK_COLORS } from './utils';

interface CampaignDeliverablesChartProps {
  ccvData: CCVData[];
  width: number;
  startTime?: string;
  chatMentions: ChatMentionDeliverable[];
  duration: number;
  svgOnly?: boolean;
  shoutouts: ShoutoutDeliverableV2[];
  bannerDeliverables: ExtendedBannerDeliverable[];
  videoId: number;
}

const CampaignDeliverablesChart = ({
  ccvData,
  width,
  startTime = '2021-01-19T14:07:20.000Z',
  chatMentions,
  duration,
  svgOnly = false,
  shoutouts,
  bannerDeliverables,
  videoId,
}: CampaignDeliverablesChartProps) => {
  const [brushBounds, setBrushBounds] = useState<BrushBounds | null>(null);

  const onBrushChange = (domain) => {
    if (!domain) return;

    const { x0, x1 } = domain;

    const start = Math.max(Math.floor(x0), 0);
    const end = Math.ceil(x1);

    setBrushBounds({ start, end });
  };

  const onBrushClick = () => {
    setBrushBounds(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'sticky', top: -8, background: 'white' }}>
        <VideoBrush
          ccvData={ccvData}
          width={width}
          onBrushClick={onBrushClick}
          onBrushChange={onBrushChange}
          duration={duration}
        />
      </div>
      <CCVMentions
        ccvData={ccvData}
        videoId={videoId}
        chatMentionDeliverables={chatMentions}
        shoutoutDeliverables={shoutouts}
        startTime={startTime}
        duration={duration}
        width={width}
        svgOnly={svgOnly}
        brushBounds={brushBounds}
      />
      {bannerDeliverables &&
        bannerDeliverables.map((deliverable, idx) => (
          <BannerTrack
            width={width}
            duration={duration}
            key={deliverable.id}
            deliverable={deliverable}
            brushBounds={brushBounds}
            svgOnly={svgOnly}
            color={STACK_COLORS[idx % STACK_COLORS.length]}
          />
        ))}
    </div>
  );
};

export default CampaignDeliverablesChart;
