import {
  INFLUENCERS_SEARCH_REQUEST_FAILED,
  INFLUENCERS_SEARCH_REQUEST_SUCCEEDED,
  INFLUENCERS_SEARCH_REQUESTED,
  INFLUENCERS_SEARCH_RESET,
} from './action-types';
import createAction from './create-action';

export const influencersSearchReset = createAction(INFLUENCERS_SEARCH_RESET);

export const influencersSearchRequested = createAction(
  INFLUENCERS_SEARCH_REQUESTED,
  {
    request: {
      name: 'influencers',
      type: 'requested',
    },
  }
);

export const influencersSearchRequestFailed = createAction(
  INFLUENCERS_SEARCH_REQUEST_FAILED,
  {
    request: {
      name: 'influencers',
      type: 'failed',
    },
  }
);

export const influencersSearchRequestSucceeded = createAction(
  INFLUENCERS_SEARCH_REQUEST_SUCCEEDED,
  {
    request: {
      name: 'influencers',
      type: 'succeeded',
    },
  }
);
