import { Typography } from '@material-ui/core';
import * as d3 from 'd3';
import Datamap from 'datamaps/dist/datamaps.world.min.js';
import React, { useEffect, useRef } from 'react';

import { TWO_LETTER_TO_ISO } from '../../../utils/country-list-audience';

const ASPECT_RATIO = 11 / 16;

const AudienceChoropleth = ({ topCountries }) => {
  const mapRef = useRef(null);

  const colorScale = d3
    .scaleLinear()
    .domain([
      Math.min(...Object.values(topCountries)),
      Math.max(...Object.values(topCountries)),
    ])
    .range(['rgb(178, 192, 225)', '#0048F2']);

  useEffect(() => {
    if (mapRef.current === null) return;

    const data = Object.keys(topCountries).reduce((prev, country) => {
      prev[TWO_LETTER_TO_ISO[country]] = {
        fillColor: colorScale(topCountries[country]),
        percentage: topCountries[country],
      };

      return prev;
    }, {});

    const map = new Datamap({
      element: mapRef.current,
      responsive: true,
      aspectRatio: ASPECT_RATIO,
      geographyConfig: {
        popupOnHover: true,
        highlightOnHover: true,
        popupTemplate: function (geo, data) {
          if (!data) {
            return;
          }
          // tooltip content
          return [
            '<div class="hoverinfo">',
            '<strong>',
            geo.properties.name,
            ': ',
            data.percentage,
            '%',
            '</strong>',
            '</div>',
          ].join('');
        },
      },
      projectionConfig: {
        rotation: [97, 0],
      },
      fontSize: '.8rem',
      projection: 'mercator',
      fills: {
        defaultFill: '#ddd',
      },
      data,
    });

    const onResize = () => {
      map.resize();
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      <div
        style={{
          width: '75%',
          margin: '0 12.5%',
          paddingTop: `calc(${ASPECT_RATIO * 100}% - 12.5%)`,
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div ref={mapRef} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {Object.keys(topCountries).map((country) => (
          <div key={country}>
            <Typography
              variant="caption"
              style={{
                color: colorScale(topCountries[country]),
                fontSize: '.8rem',
              }}
            >
              {country}
            </Typography>
            <Typography
              style={{
                color: colorScale(topCountries[country]),
                fontWeight: 'bold',
                fontSize: '1.25rem',
                marginTop: -8,
              }}
            >
              {topCountries[country]}%
            </Typography>
          </div>
        ))}
      </div>
    </>
  );
};

export default AudienceChoropleth;
