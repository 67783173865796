export const getWatchlists = () => ({ watchlists }) => watchlists;

export const getWatchlistsList = () => ({ watchlists }) =>
  watchlists.watchlists || [];

export const getWatchlistById = (id) => (state) =>
  state.watchlists.watchlists
    ? state.watchlists.watchlists.find((w) => w.id === id)
    : null;

export const getWatchlistIndexById = (id) => (state) =>
  state.watchlists.watchlists.findIndex((w) => w.id === id).name;

export const getFirstWatchlist = () => (state) => {
  const watchlists = getWatchlistsList()(state);

  if (watchlists.length === 0) return {};

  return watchlists[0];
};
