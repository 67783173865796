import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { InputField } from '../../../../common/components';

export const ResetPasswordForm = ({
  pristine,
  authInProgress,
  handleSubmit,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        placeholder="required"
        name="password"
        type="password"
        component={InputField}
        label="Password"
      />
      <Field
        placeholder="required"
        name="password_confirmation"
        type="password"
        component={InputField}
        label="Password Confirmation"
      />
      <div className="form-group">
        <div className="peers ai-c jc-sb fxw-nw">
          <div className="peer">
            <button
              className="btn btn-primary"
              disabled={pristine || authInProgress}
            >
              {authInProgress ? 'Submitting' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  authInProgress: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
