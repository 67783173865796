import { Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useCallback, useEffect, useState } from 'react';

const MAX_DELIVERABLES_PER_PAGE = 5;

interface DeliverablePaginatorProps<T> {
  deliverables: T[];
  renderDeliverable: (deliverable: T) => React.ReactNode;
  forcePageIndex?: number;
}

export function DeliverablePaginator<T>({
  deliverables,
  renderDeliverable,
  forcePageIndex,
}: DeliverablePaginatorProps<T>) {
  const [activePageIndex, setActivePageIndex] = useState(forcePageIndex || 0);

  useEffect(() => {
    setActivePageIndex(forcePageIndex || 0);
  }, [setActivePageIndex, forcePageIndex, deliverables]);

  const onPageChange = useCallback(
    (_, page) => {
      // the MUI paginator numbers pages starting from 1
      const zeroIndexedPage = page - 1;

      if (zeroIndexedPage !== activePageIndex) {
        setActivePageIndex(page - 1);

        // scroll to top
        document
          .getElementsByClassName('main-container')?.[0]
          ?.scrollTo({ top: 0 });
      }
    },
    [activePageIndex]
  );

  const nPages = Math.ceil(deliverables.length / MAX_DELIVERABLES_PER_PAGE);

  const activePageDeliverables = deliverables.slice(
    activePageIndex * MAX_DELIVERABLES_PER_PAGE,
    (activePageIndex + 1) * MAX_DELIVERABLES_PER_PAGE
  );

  const paginator = nPages > 1 && (
    <div style={{ marginTop: -64, marginBottom: 48 }}>
      <Box display="flex" alignContent="center" justifyContent="center">
        <Pagination
          count={nPages}
          onChange={onPageChange}
          page={activePageIndex + 1}
          variant="outlined"
          color="primary"
        />
      </Box>
    </div>
  );

  return (
    <div>
      {activePageDeliverables.map(renderDeliverable)}
      {paginator}
    </div>
  );
}
