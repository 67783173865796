import React, { useMemo } from 'react';

import Table, { StatBox } from '../Table';
import SectionContainer from './SectionContainer';
import { SectionTitle } from './shared';

const getCols = ({ impressionsAreEst }) =>
  [
    {
      label: 'Creators',
      key: 'creators',
      render: (data) => <StatBox stat={data.creators}></StatBox>,
      stretch: true,
    },
    {
      label: 'Posts',
      key: 'posts',
      render: (data) => <StatBox stat={data.posts} />,
      stretch: true,
    },
    {
      label: 'Engagements',
      key: 'engagements',
      render: (data) => <StatBox stat={data.engagements} />,
      stretch: true,
    },
    {
      label: impressionsAreEst ? 'Est. Impressions' : 'Impressions',
      key: 'impressions',
      render: (data) => <StatBox stat={data.impressions} />,
      stretch: true,
    },
    {
      label: 'Media Value',
      key: 'media_value',
      render: (data) => (
        <StatBox stat={data.media_value} format="0,[0].[0]a" prefix="$" />
      ),
      stretch: true,
    },
  ].filter((c) => c !== null);

const getRows = (performance) => [
  {
    key: 1,
    ...performance,
  },
];

export const SocialMediaPerformance = ({ performance, onViewMore }) => {
  const rows = useMemo(() => getRows(performance), [performance]);

  const cols = useMemo(
    () => getCols({ impressionsAreEst: performance.impressions_are_est }),
    []
  );
  const idTable = 'social-media-performance';

  return (
    <SectionContainer>
      <SectionTitle onViewMore={onViewMore}>Social media</SectionTitle>
      <Table noBorders={true} cols={cols} rows={rows} idTable={idTable} />
    </SectionContainer>
  );
};

export default SocialMediaPerformance;
