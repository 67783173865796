const context = require.context(
  '..',
  true,
  /\.\/(common|views).*\/selectors\.js$/
);
const appProjectSelectors = {};
context.keys().forEach((path) => {
  const component = context(path);
  Object.keys(component).forEach((methodName) => {
    appProjectSelectors[methodName] = component[methodName];
  });
});

export const selectors = appProjectSelectors;

export default appProjectSelectors;
