import momentTz from 'moment-timezone';
import React, { useMemo } from 'react';

import { CampaignDeliverableChartTrigger } from '../../components/DeliverablesChartTrigger';
import InfluencerAvatar from '../../components/InfluencerAvatar';
import VideoCell from '../../components/VideoCell';
import Table, { StatBox } from '../../Table';
import { findInfluencerByVideo } from '../../utils';

const ROW_HEIGHT = 100;

const COLS = [
  {
    label: 'Creator',
    key: 'creator',
    render: (row) => (
      <div style={{ position: 'relative' }} className="padding-on-hover">
        <div
          style={{
            position: 'absolute',
            left: -32,
            top: '50%',
            transform: 'translateY(-50%)',
            height: ROW_HEIGHT,
            width: 48,
            background: 'rgba(0, 0, 0, .1)',
            zIndex: 5,
          }}
          className="slide-on-hover"
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CampaignDeliverableChartTrigger videoId={row.video.id} />
          </div>
        </div>
        <InfluencerAvatar influencer={row.influencer} platform={row.platform} />
      </div>
    ),
    stretch: true,
  },
  {
    label: 'Video',
    key: 'videoTimeStamp',
    render: (row) => <VideoCell video={row.video} videoTime={row.videoTime} />,
    stretch: true,
    flex: 1,
  },
  {
    label: 'Shares',
    key: 'occurrences',
    render: (data) => <StatBox stat={data.occurrences} />,
  },
  // {
  //   label: 'Viewers',
  //   key: 'views',
  //   render: (data) => <StatBox stat={data.views} />,
  // },
  // {
  //   label: 'Media Value',
  //   key: 'emv',
  //   render: (data) => (
  //     <StatBox stat={data.emv} format="0,[0].[0]a" prefix="$" />
  //   ),
  // },
];

const getRows = ({
  usedLinkOccurrences,
  usedVideos,
  usedInfluencers,
  timeZone,
}) =>
  usedVideos
    .map((video) => {
      const occurrencesList = usedLinkOccurrences.filter(
        (o) => o.video_id === video.id
      );

      const occurrences = occurrencesList.length;

      if (occurrences === 0) return null;

      const videoTimeObject = momentTz.tz(video.published_at, timeZone);

      const videoTime = videoTimeObject.format('Do MMM YYYY, hh:mm A');

      const videoTimeStamp = videoTimeObject.unix();

      const influencer = findInfluencerByVideo(video, usedInfluencers);

      return {
        influencer,
        occurrences,
        creator: influencer.name,
        key: video.id,
        platform: video.resource,
        videoTitle: video.video_title,
        video,
        videoTime,
        videoTimeStamp,
      };
    })
    .filter((r) => r !== null);

export const DetailedTable = ({
  usedLinkOccurrences,
  usedVideos,
  usedInfluencers,
  timeZone,
  link,
}) => {
  const rows = useMemo(
    () =>
      getRows({
        usedLinkOccurrences,
        usedVideos,
        usedInfluencers,
        timeZone,
      }),
    [usedLinkOccurrences, usedVideos, usedInfluencers, timeZone]
  );
  const idTable = `SV-linksharing-detailed-${link.replace(/\s+/g, '')}`;

  return (
    <Table
      cols={COLS}
      rows={rows}
      minWidth={1300}
      defaultSortCol="videoTimeStamp"
      rowHeight={ROW_HEIGHT}
      idTable={idTable}
    />
  );
};

export default DetailedTable;
