import { TableCell, TableSortLabel } from '@material-ui/core';
import React, { useContext } from 'react';

export default function OrderableTableCell(props) {
  const { TableCellProps, orderByValue, children, context } = props;

  const { order, orderBy, handleOrderBySelection } = useContext(context);

  return (
    <TableCell {...TableCellProps}>
      <TableSortLabel
        active={orderBy === orderByValue}
        direction={order}
        onClick={handleOrderBySelection(orderByValue)}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
}
