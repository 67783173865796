import React from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import BarchartDefaultPercantageTooltipContent from '../components/BarchartDefaultPercantageTooltipContent';

const Languages = (props) => {
  const { data } = props;

  return (
    <ResponsiveContainer minHeight="250px">
      <BarChart data={data}>
        <XAxis dataKey="language" axisLine={false} tickLine={false} />
        <YAxis dataKey="percentage" axisLine={false} tickLine={false} />
        <Tooltip content={BarchartDefaultPercantageTooltipContent} />
        <Bar barSize={20} dataKey="percentage" fill="#009fff" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Languages;
