import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import bgImage from '../../../../assets/images/big-blue-bgr-logo.png';
import FacebookIcon from '../../../../assets-2.0/images-influencer/facebook.svg';
import TiktokIcon from '../../../../assets-2.0/tiktok.svg';
import TwitchIcon from '../../../../assets-2.0/twitch.svg';
import TwitterIcon from '../../../../assets-2.0/twitter.svg';
import YoutubeIcon from '../../../../assets-2.0/youtube.svg';
import Button from '../../../../components/Button';
import useEnableFacebook from '../../../../hooks/enable-facebook';
import { useLogRocket } from '../../../../utils/useLogRocket';

const useStyles = makeStyles((theme) => ({
  twitch: {
    width: '220px',
  },
  youtube: {
    width: '220px',
  },
  facebook: {
    width: '220px',
    padding: '5px',
  },
  twitter: {
    width: '220px',
    padding: '5px',
  },
  tiktok: {
    width: '220px',
    padding: '5px',
  },
  iconImage: {
    width: 24,
    height: 24,
  },
}));

export const InfluencerLoginPage = ({
  redirectTwitch,
  loginFacebook,
  loginTwitter,
  redirectYoutube,
  redirectTiktok,
}) => {
  const classes = useStyles();

  useLogRocket(true);

  useEnableFacebook();

  return (
    <div className="peers ai-s fxw-nw h-100vh">
      <div
        className="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <div
        className="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r"
        style={{ minWidth: '320px' }}
      >
        <h4 className="fw-300 c-grey-900 mB-40">Content Creator Login</h4>
        <Button
          type="button"
          variant="outlined"
          onClick={redirectTwitch}
          className={clsx('mT-10 pL-0 mR-10', classes.twitch)}
        >
          {' '}
          <img
            src={TwitchIcon}
            className={classes.iconImage}
            alt="twitch icon"
          />
          <span style={{ marginLeft: 10 }}>Login with Twitch</span>
        </Button>
        <Button
          type="button"
          variant="outlined"
          className={clsx('mT-10 pL-10 mR-10', classes.youtube)}
          onClick={redirectYoutube}
        >
          {' '}
          <img
            src={YoutubeIcon}
            className={classes.iconImage}
            alt="youtube icon"
          />
          <span style={{ marginLeft: 10 }}>Login with Youtube</span>
        </Button>
        <Button
          type="button"
          variant="outlined"
          onClick={loginFacebook}
          className={clsx('mT-10 pL-0 mR-10', classes.facebook)}
        >
          {' '}
          <img
            src={FacebookIcon}
            className={classes.iconImage}
            alt="facebook icon"
            style={{ marginLeft: 4 }}
          />
          <span style={{ marginLeft: 6 }}>Login with Facebook</span>
        </Button>
        <Button
          type="button"
          variant="outlined"
          onClick={loginTwitter}
          className={clsx('mT-10 pL-0 mR-10', classes.twitter)}
        >
          {' '}
          <img
            src={TwitterIcon}
            className={classes.iconImage}
            alt="twitter icon"
            style={{ marginLeft: 4 }}
          />
          <span style={{ marginLeft: 6 }}>Login with Twitter</span>
        </Button>
        <Button
          type="button"
          variant="outlined"
          onClick={redirectTiktok}
          className={clsx('mT-10 pL-0 mR-10', classes.tiktok)}
        >
          {' '}
          <img
            src={TiktokIcon}
            className={classes.iconImage}
            alt="twitter icon"
            style={{ marginLeft: 4 }}
          />
          <span style={{ marginLeft: 6 }}>Login with TikTok</span>
        </Button>
        <br />
        <br />
        <Typography className={'fs-i fsz-sm'} color="textSecondary">
          Aggero will not post or modify any data from your social media
          accounts. We will analyse your social data and activity so we can
          better match your personality and content with brands, sponsors and
          teams. Aggero will track social posts and page metrics, games played
          and live streams.
        </Typography>
      </div>
    </div>
  );
};

InfluencerLoginPage.propTypes = {
  loginFacebook: PropTypes.func.isRequired,
  loginTwitter: PropTypes.func.isRequired,
  redirectTwitch: PropTypes.func.isRequired,
  redirectYoutube: PropTypes.func.isRequired,
};
