import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  deleteMultipleShoutoutsMentions,
  deleteShoutoutDeliverables,
  getShoutoutDeliverableById,
  getShoutoutDeliverableV2ById,
} from '../../../../../ajax';
import { ShoutoutDeliverable, SLICE_NAME } from './types';

interface FetchShoutoutDeliverablePayload {
  campaignId: number;
  shoutoutDeliverableId: number;
  v2: boolean;
}

export const fetchShoutoutDeliverable = createAsyncThunk<
  ShoutoutDeliverable,
  FetchShoutoutDeliverablePayload
>(
  `${SLICE_NAME}/fetchShoutoutDeliverablesPage`,
  async ({ campaignId, shoutoutDeliverableId, v2 }) => {
    if (v2) {
      const deliverable = await getShoutoutDeliverableV2ById(
        campaignId,
        shoutoutDeliverableId
      );
      return { ...deliverable, v2: true };
    }

    return getShoutoutDeliverableById(campaignId, shoutoutDeliverableId);
  }
);

interface DeleteShoutoutPayload {
  campaignId: number;
  shoutoutId: number;
  shoutoutDeliverableId: number;
}

export const deleteShoutout = createAsyncThunk(
  `${SLICE_NAME}/deleteShoutout`,
  ({ campaignId, shoutoutId, shoutoutDeliverableId }: DeleteShoutoutPayload) =>
    deleteShoutoutDeliverables(shoutoutDeliverableId, campaignId, shoutoutId)
);

interface DeleteMultipleShoutoutsPayload {
  campaignId: number;
  shoutoutIds: number[];
  shoutoutDeliverableId: number;
  v2: boolean;
}

export const deleteMultipleShoutouts = createAsyncThunk(
  `${SLICE_NAME}/deleteMultipleShoutouts`,
  ({
    campaignId,
    shoutoutIds,
    shoutoutDeliverableId,
    v2,
  }: DeleteMultipleShoutoutsPayload) =>
    deleteMultipleShoutoutsMentions({
      campaign_id: campaignId,
      shoutout_deliverables_v2_id: shoutoutDeliverableId,
      shoutout_deliverables_v1_id: !v2 ? shoutoutDeliverableId : undefined,
      shoutout_ids: shoutoutIds,
    })
);
