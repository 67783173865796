import qs from 'query-string';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  postFacebookLogin,
  postForgotPassword,
  postResetPassword,
  postSessionLogin,
  postTiktokLogin,
  postTwitchConnect,
  postTwitchLogin,
  postTwitterLogin,
  postYoutubeConnect,
  postYoutubeLogin,
  recordUserSession,
} from '../../../ajax';
import {
  INFLUENCERS_SIGNIN,
  INFLUENCERS_SIGNUP,
  PATH_ROOT,
  PATH_SIGNIN,
} from '../../../common/constants/paths';
import { TIKTOK_STATE_STORAGE_KEY } from '../../../helpers/influencer-login';
import { persistor } from '../../../store';
import { history } from '../../../store';
import { actions, types } from '../../../store/actions';
import { getUserEmail } from '../../../store/models/user/user.selectors';
import { selectors } from '../../../store/selectors';
import { forceSocialPostsReload } from '../../../views/influencerViews/Stats/SocialMedia/store/SocialMediaPosts.slice';
import { forceInfluencerInfoReload } from '../../../views/influencerViews/store/InfluencerInfo.slice';
import {
  getErrorMessagesFromResponse,
  translateErrorMessage,
} from '../../ErrorMessageUtil';
import Firebase from '../../resources/firebase';
import Dialog from '../Dialog';

// function* signupWithPassword({ name, email, password }) {
//   try {
//     // add user to Firebase
//     const result = yield call(Firebase.createUserWithCredentials, email, password)
//     yield put(actions.setLoggedInViewCompany())
//     // add user to our own Firebase db with displayName & signin locally
//     yield all([
//       call(Firebase.addUser, result.user.uid, name, result.user.email, ''),
//       put(actions.userSignedIn({
//         uid: result.user.uid,
//         displayName: name,
//         email: result.user.email,
//       })),
//     ])
//   } catch (ex) {
//     Dialog.show({
//       caption: 'Failed',
//       message: ex.message,
//       buttons: ['Got it'],
//     })
//   } finally {
//     yield put(actions.authInProgress())
//   }
// }

function* signinWithPassword({ email, password }) {
  try {
    // try to sign in with credentials provided
    const result = yield call(postSessionLogin, email, password);

    yield call(recordUserSession, { action: 'user-signin' });

    yield put(actions.setLoggedInViewCompany());
    yield put(
      actions.userSignedIn({
        ...result.user,
        displayName: result.user.name,
      })
    );
  } catch (ex) {
    handleError(ex);
  } finally {
    yield put(actions.authInProgress());
  }
}

// function* signinGoogle() {
//   try {
//     // try to sign in with Google
//     yield put(actions.authInProgress(true))
//     const result = yield call(Firebase.signInWithGoogle)
//     yield put(actions.setLoggedInViewCompany())
//     yield put(actions.userSignedIn({ accessToken: result.credential.accessToken }))
//   } catch (ex) {
//     Dialog.show({
//       caption: 'Failed',
//       message: ex.message,
//       buttons: ['Got it'],
//     })
//   } finally {
//     yield put(actions.authInProgress())
//   }
// }

function* signinFacebook({ authResponse }) {
  try {
    yield put(actions.authInProgress(true));

    const result = yield call(postFacebookLogin, authResponse);

    yield put(actions.setLoggedInViewInfluencer());
    yield put(
      actions.userSignedIn({
        ...result.user,
        displayName: result.user.name,
      })
    );

    yield put(forceInfluencerInfoReload());
    yield put(forceSocialPostsReload());
  } catch (ex) {
    const [error] = getErrorMessagesFromResponse(ex);

    const message = translateErrorMessage(error);

    Dialog.show({
      caption: 'Failed',
      message,
      buttons: ['Got it'],
    });
  } finally {
    yield put(actions.authInProgress());
  }
}

function* signinTwitch({ accessToken }) {
  try {
    const alreadyLoggedIn = !!(yield select(getUserEmail));

    yield put(actions.authInProgress(true));

    let result;

    if (alreadyLoggedIn) {
      result = yield call(postTwitchConnect, accessToken);
    } else {
      result = yield call(postTwitchLogin, accessToken);
    }

    yield put(actions.setLoggedInViewInfluencer());
    yield put(
      actions.userSignedIn({
        ...result.user,
        displayName: result.user.name,
      })
    );
  } catch (ex) {
    const [error] = getErrorMessagesFromResponse(ex);

    const message = translateErrorMessage(error);

    Dialog.show({
      caption: 'Failed',
      message,
      buttons: ['Got it'],
    }).then(() =>
      history.push(qs.parse(window.location.search)['t'] ?? PATH_ROOT)
    );
  } finally {
    yield put(actions.authInProgress());
  }
}

function* signinTwitter({ oauthToken, oauthVerifier }) {
  try {
    yield put(actions.authInProgress(true));
    const result = yield call(postTwitterLogin, oauthToken, oauthVerifier);

    yield put(actions.setLoggedInViewInfluencer());
    yield put(
      actions.userSignedIn({
        ...result.user,
        displayName: result.user.name,
      })
    );
  } catch (ex) {
    const [error] = getErrorMessagesFromResponse(ex);

    const message = translateErrorMessage(error);

    Dialog.show({
      caption: 'Failed',
      message,
      buttons: ['Got it'],
    });
  } finally {
    yield put(actions.authInProgress());
  }
}

function* signinYoutube({ accessCode, target }) {
  try {
    const alreadyLoggedIn = !!(yield select(getUserEmail));

    yield put(actions.authInProgress(true));

    let result;

    if (alreadyLoggedIn) {
      result = yield call(postYoutubeConnect, accessCode, target);
    } else {
      result = yield call(postYoutubeLogin, accessCode, target);
    }

    yield put(actions.setLoggedInViewInfluencer());
    yield put(
      actions.userSignedIn({
        ...result.user,
        displayName: result.user.name,
      })
    );
  } catch (ex) {
    const [error] = getErrorMessagesFromResponse(ex);

    const message = translateErrorMessage(error);

    Dialog.show({
      caption: 'Failed',
      message,
      buttons: ['Got it'],
    }).then(() =>
      history.push(qs.parse(window.location.search)['t'] ?? PATH_ROOT)
    );
  } finally {
    yield put(actions.authInProgress());
  }
}

function* signinTiktok({ code, scopes, state }) {
  try {
    yield put(actions.authInProgress(true));

    if (window.localStorage.getItem(TIKTOK_STATE_STORAGE_KEY) !== state) {
      throw new Error();
    }

    const result = yield call(
      postTiktokLogin,
      code,
      scopes,
      window.location.href.split('?')[0]
    );

    yield put(actions.setLoggedInViewInfluencer());

    yield put(
      actions.userSignedIn({
        ...result.user,
        displayName: result.user.name,
      })
    );
  } catch (ex) {
    const [error] = getErrorMessagesFromResponse(ex);

    const message = translateErrorMessage(error);

    Dialog.show({
      caption: 'Failed',
      message,
      buttons: ['Got it'],
    }).then(() => history.push(INFLUENCERS_SIGNUP));
  } finally {
    yield put(actions.authInProgress());

    window.localStorage.removeItem(TIKTOK_STATE_STORAGE_KEY);
  }
}

function* resetPassword({ password, token }) {
  try {
    yield call(postResetPassword, password, token);
    Dialog.show({
      caption: 'Success!',
      message: 'Your password has been reset successfully!',
      buttons: ['Got it'],
    }).then(() => history.push(PATH_SIGNIN));
  } catch (ex) {
    handleError(ex);
  } finally {
    yield put(actions.authInProgress());
  }
}

function* forgotPassword({ email }) {
  try {
    yield call(postForgotPassword, email);
    Dialog.show({
      caption: 'Success!',
      message:
        'If your email is in our database you will receive a reset password link. Please check your email!',
      buttons: ['Got it'],
    });
  } catch (ex) {
    handleError(ex);
  } finally {
    yield put(actions.authInProgress());
  }
}

function* signout() {
  const isInfluencerView = yield select(selectors.isInfluencerView);

  yield call(Firebase.signout);
  // compete clearence of redux state
  yield put(actions.resetApp());

  // Cleanup redux persist
  persistor.pause();
  persistor
    .flush()
    .then(() => persistor.purge())
    .then(() => persistor.persist());

  if (isInfluencerView) {
    history.push({
      pathname: INFLUENCERS_SIGNIN,
    });
  } else {
    history.push({
      pathname: PATH_SIGNIN,
    });
  }
}

async function handleError(ex) {
  try {
    const body = ex.bodyJson;

    let { message } = body;

    if (!message && body.messages) {
      message = translateErrorMessage(body.messages[0]);
    }

    Dialog.show({
      caption: 'Failed',
      message: message,
      buttons: ['Got it'],
    });
  } catch {
    console.error(ex);

    Dialog.show({
      caption: 'Failed',
      message: 'Something went wrong. Please try again!',
      buttons: ['Got it'],
    });
  }
}

// function getBrandsAndCompetitorsForUser() {
//   var currentUserId = yield call(Firebase.getCurrentUserId)
// }

export default function* root() {
  yield all([
    takeLatest(types.SIGNIN_WITH_PASSWORD, signinWithPassword),
    takeLatest(types.SIGNIN_FACEBOOK, signinFacebook),
    takeLatest(types.SIGNIN_TWITTER, signinTwitter),
    takeLatest(types.SIGNIN_TWITCH, signinTwitch),
    takeLatest(types.SIGNIN_YOUTUBE, signinYoutube),
    takeLatest(types.SIGNIN_TIKTOK, signinTiktok),
    takeLatest(types.RESET_PASSWORD, resetPassword),
    takeLatest(types.FORGOT_PASSWORD, forgotPassword),
    takeEvery(types.SIGNOUT, signout),
  ]);
}
