import {
  IconButton,
  makeStyles,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import momentTz from 'moment-timezone';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PROFILE } from '../../../../common/constants/paths';
import { InfluencerAvatar } from '../../../../components/InfluencerAvatar/InfluencerAvatar';
import { LabelButton } from '../../../../components/LabelButton/LabelButton';
import { StreamSegment } from '../../../../components/StreamSegment/StreamSegment';
import {
  ROW_HEIGHT,
  VideoCell,
} from '../../../../components/VideoCell/VideoCell';
import { StreamingPlatform } from '../../../../types';
import { ComponentSize } from '../../../../utils/ComponentSize';
import { MarketplaceStreamChartTrigger } from '../../../Campaign/components/DeliverablesChartTrigger';
import Table, { StatBox } from '../../../Campaign/Table';
import { Video } from '../../../Campaign/types';
import {
  selectInfluencerHasStreamingAccounts,
  selectInfluencerInfo,
} from '../../store/InfluencerInfo.selectors';
import { SocialPage } from '../../store/types';
import { fakeStreamInfluencer, fakeStreams } from './store/fakeData';
import { selectAllStreams } from './store/Streams.slice';

export type Row = {
  influencer: any;
  date: string;
  date_unix: number;
  platform: Video['resource'];
  hoursStreamed: number;
  hoursWatched: number;
  key: number;
  isLocked?: boolean;
  lockedContent?: JSX.Element;
  video: Video;
  comments: number;
  emv: number;
  engagementRate: number;
  hasSegments: boolean;
  avgCCV: number;
  peakCCV: number;
  totalViews: number;
};

const useStyles = makeStyles((theme) => ({
  creatorCol: {
    position: 'relative',
    maxWidth: '150px',
  },
  onHover: {
    position: 'absolute',
    left: -32,
    top: '50%',
    transform: 'translateY(-50%)',
    height: ROW_HEIGHT,
    width: 48,
    background: 'rgba(0, 0, 0, .1)',
    zIndex: 5,
  },
  iconsOnHover: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CreatorCol = ({
  row,
  size,
  setModalStreamSegment,
  modalStreamSegment,
}: {
  row: Row;
  size: Extract<ComponentSize, 'sm'>;
  modalStreamSegment: number | null;
  setModalStreamSegment: (videoId: number | null) => void;
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.creatorCol} padding-on-hover`}>
      <StreamSegment
        video={row.video}
        influencer={row.influencer}
        modalStreamSegment={modalStreamSegment}
        setModalStreamSegment={setModalStreamSegment}
      />
      <div className={`${classes.onHover} slide-on-hover`}>
        <div className={classes.iconsOnHover}>
          <MarketplaceStreamChartTrigger
            video={row.video}
            influencer={row.influencer}
          />
          {row.video.resource === 'twitch' && (
            <Tooltip
              placement="top"
              title="Select stream segment to be tracked"
            >
              <IconButton
                style={{ height: 45 }}
                onClick={() => setModalStreamSegment(row.video.id)}
              >
                <AvTimerIcon style={{ color: '#A300D8' }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <InfluencerAvatar influencer={row.influencer} size={size} />
    </div>
  );
};

const getCols = (
  { size }: { size?: Extract<ComponentSize, 'sm'> },
  setModalStreamSegment: (videoId: number | null) => void,
  modalStreamSegment: number | null
) => {
  return [
    {
      label: 'Username',
      key: 'creator',
      render: (row: Row) => (
        <CreatorCol
          row={row}
          size={size}
          setModalStreamSegment={setModalStreamSegment}
          modalStreamSegment={modalStreamSegment}
        />
      ),
      flex: 1.5,
      stretch: true,
    },
    {
      label: 'Video',
      key: 'date',
      sortKey: 'date_unix',
      render: (row: Row) => (
        <VideoCell video={row.video} videoTime={row.date} showType />
      ),
      stretch: true,
      flex: 2,
    },
    {
      label: 'Duration hrs.',
      key: 'hoursStreamed',
      render: (row: Row) => <StatBox stat={row.hoursStreamed} />,
    },
    {
      label: 'Hrs. Watched',
      key: 'hoursWatched',
      render: (row: Row) => <StatBox stat={row.hoursWatched} />,
    },
    {
      label: 'Cmnts.',
      key: 'comments',
      render: (row: Row) => <StatBox stat={row.comments} />,
    },
    {
      label: 'Eng. Rate',
      key: 'engagementRate',
      render: (row: Row) => (
        <StatBox
          stat={row.engagementRate}
          info={
            row.hasSegments
              ? 'Engagement rate is calculated based on the number of interactions and potential estimated views for the selected stream segment.'
              : undefined
          }
          suffix={'%'}
        />
      ),
    },
    {
      label: 'Avg CCV',
      key: 'avgCCV',
      render: (row: Row) => <StatBox stat={row.avgCCV} />,
    },
    {
      label: 'Peak CCV',
      key: 'peakCCV',
      render: (row: Row) => <StatBox stat={row.peakCCV} />,
    },
    {
      label: 'Views',
      key: 'totalViews',
      render: (row: Row) => (
        <StatBox
          stat={row.totalViews}
          info={
            row.hasSegments
              ? 'The number of estimated potential views based on the distribution of concurrent viewers at each minute across the selected stream segment'
              : undefined
          }
        />
      ),
    },
    {
      label: 'Media Value',
      key: 'emv',
      render: (row: Row) => (
        <StatBox stat={row.emv} format="0,[0].[0]a" prefix="$" />
      ),
    },
  ].filter((col) => col !== null);
};

const getRows = ({
  size,
  streams,
  isLocked,
  twitchAccount,
  youtubeAccount,
  facebookAccount,
  viewLockedButtons,
}: {
  size?: Extract<ComponentSize, 'sm'>;
  streams: Video[];
  isLocked: boolean;
  twitchAccount: SocialPage;
  youtubeAccount: SocialPage;
  facebookAccount: SocialPage;
  viewLockedButtons: boolean;
}): Row[] => {
  return streams.map((video) => {
    const publishedAt = momentTz(video.published_at);

    let account = twitchAccount;

    if (video.resource === 'youtube') {
      account = youtubeAccount;
    }
    if (video.resource === 'facebook') {
      account = facebookAccount;
    }

    const influencer = isLocked
      ? fakeStreamInfluencer
      : {
          name: account?.page_title,
          avatar: account?.profile_image_url,
        };

    return {
      influencer,
      video,
      date: publishedAt.format('DD MMM YYYY, h:mm A'),
      date_unix: publishedAt.unix(),
      platform: video.resource,
      emv: video.emv,
      hoursStreamed: video.duration / 60 / 60,
      hoursWatched: (video.duration * video.avg_cc_viewers) / 60 / 60,
      comments: video.comments_count,
      hasSegments: !!video.video_segments?.length,
      avgCCV: video.avg_cc_viewers,
      peakCCV: video.max_cc_viewers,
      engagementRate: video.engagement_rate,
      totalViews: video.total_views,
      key: video.id,
      isLocked,
      lockedContent: viewLockedButtons && (
        <LockedRowContent platform={video.resource} size={size} />
      ),
    };
  });
};

export const StreamingTable = ({
  modalStreamSegment,
  setModalStreamSegment,
  bluredData,
}) => {
  const theme = useTheme();
  const size = useMediaQuery(theme.breakpoints.down('sm')) ? 'sm' : null;

  const realStreams = useSelector(selectAllStreams);

  const hasStreamingAccounts = useSelector(
    selectInfluencerHasStreamingAccounts()
  );

  const influencerInfo = useSelector(selectInfluencerInfo());

  const youtubeAccount = influencerInfo?.onboarding_youtube_pages?.[0];
  const twitchAccount = influencerInfo?.onboarding_twitch_pages?.[0];
  const facebookAccount = influencerInfo?.onboarding_facebook_pages?.[0];

  const rows = useMemo(() => {
    const streams =
      hasStreamingAccounts && !bluredData ? realStreams : fakeStreams;
    return getRows({
      size,
      streams,
      isLocked: !hasStreamingAccounts || bluredData,
      twitchAccount,
      youtubeAccount,
      facebookAccount,
      viewLockedButtons: !bluredData,
    });
  }, [
    hasStreamingAccounts,
    bluredData,
    realStreams,
    size,
    twitchAccount,
    youtubeAccount,
    facebookAccount,
  ]);

  const cols = useMemo(
    () => getCols({ size }, setModalStreamSegment, modalStreamSegment),
    [size, modalStreamSegment, setModalStreamSegment]
  );

  return (
    <Table
      cols={cols}
      rows={rows}
      rowHeight={ROW_HEIGHT}
      minWidth={1400}
      defaultSortCol="date"
      idTable="mystats-streaming-table"
      // Added the blow props otherwise TS would complain
      sortKey={undefined}
      noBorders={undefined}
      defaultIncreasing={undefined}
      onRowClick={undefined}
      onCreatorColClick={undefined}
    />
  );
};

const LockedRowContent = ({
  size,
  platform,
}: {
  size: Extract<ComponentSize, 'sm'>;
  platform: StreamingPlatform;
}) => {
  const history = useHistory();

  return (
    <LabelButton
      text={`Connect to your ${platform} account to unlock`}
      size={size}
      onClick={() => {
        history.push(`${PROFILE}?socialCallback=true`);
      }}
    />
  );
};
