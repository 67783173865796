import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect, useFormikContext } from 'formik';
import _forEach from 'lodash/forEach';
import React, { useEffect, useState } from 'react';

import LoadingButton from '../../components/LoadingButton';
import MyBrandsMain from '../MyBrands/MyBrandsMain';
import CampaignWizardDeliverables from './CampaignWizardDeliverables';

const useStyles = makeStyles((theme) => ({
  accordion: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    p: 4,
    overflowY: 'auto',
    height: 800,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  heightAccordionSummary: {
    height: '72px',
  },
  defaultContainerButton: {
    width: '100%',
    display: 'contents',
  },
  margin: {
    margin: '0',
  },
}));

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 0,
    width: '100%',
    '&:first-child': {
      borderTop: 0,
    },
    margin: '0 !important',
  },
})(MuiAccordion);

const enhance = connect;

const CampaignWizardSponsorshipValuation = (props) => {
  const { brands, trialAccount, organizationRestrictions } = props;
  const classes = useStyles();
  // const [expanded, setExpanded] = useState('0');
  const [brandsId, setBrandsId] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [checkedBrands, setCheckedBrands] = useState({});
  const [emptyCheckedBrands, setEmptyCheckedBrands] = useState(false);
  const [newWizardBrand, setNewWizardBrand] = useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
    // setExpanded(newExpanded ? panel : false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    if (values.brandsClone) {
      if (Object.keys(checkedBrands).length === 0) {
        values.brands
          .filter((el) => !el.unique)
          .forEach((el) => {
            el.checked = true;
            setCheckedBrands((prev) => {
              return {
                ...prev,
                [el.brandName]: [
                  el.shoutouts.length > 0 && 'shoutoutChecked',
                  el.kpiKeywordMentionList.length > 0 &&
                    'kpiKeywordMentionChecked',
                  el.bannerImages.length > 1 && 'bannerChecked',
                  el.socialMediaMentionContent.length > 0 &&
                    'socialMediaMentionChecked',
                  el.links.length > 0 && 'linkSharingChecked',
                ].filter((el) => el !== false),
              };
            });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(checkedBrands).length === 0) {
      values.brands.forEach((brand) => {
        if (brand.checked) {
          const brandsArray = [];
          brand.shoutoutChecked && brandsArray.push('shoutoutChecked');
          brand.kpiKeywordMentionChecked &&
            brandsArray.push('kpiKeywordMentionChecked');
          brand.bannerChecked && brandsArray.push('bannerChecked');
          brand.socialMediaMentionChecked &&
            brandsArray.push('socialMediaMentionChecked');
          brand.linkSharingChecked && brandsArray.push('linkSharingChecked');
          setCheckedBrands(() => {
            return {
              [brand.brandName]: brandsArray,
            };
          });
        }
      });
    }
  }, [values.brandId]);

  useEffect(() => {
    if (Object.keys(checkedBrands).length !== 0) {
      let arr = values?.brands?.map((itemBrand) => {
        if (Object.keys(checkedBrands)?.includes(itemBrand.brandName)) {
          if (checkedBrands[itemBrand.brandName].length > 0) {
            _forEach(checkedBrands, (checkedBrand, key) => {
              if (itemBrand.brandName === key)
                checkedBrand.forEach((item) => (itemBrand[item] = true));
            });
            return {
              ...itemBrand,
              checked: true,
              unique: false,
            };
          } else {
            return {
              ...itemBrand,
              checked: false,
              unique: true,
            };
          }
        } else return itemBrand;
      });
      setFieldValue('brands', arr);
      setBrandsId(arr.filter((item) => item.checked).map((el) => el.id));
      setFieldValue(
        'brandId',
        arr.filter((item) => item.checked).map((el) => el.id)
      );
    }
  }, [checkedBrands, emptyCheckedBrands]);

  const onAdd = (brand) => {
    let arr = values?.brands.map((itemBrand) => {
      if (itemBrand.id === brand.id) {
        setCheckedBrands((prev) => {
          if (
            Object.keys(prev).includes(brand.brandName) &&
            prev[brand.brandName].length !== 0
          ) {
            return {
              ...prev,
              [brand.brandName]: [],
            };
          }
          return {
            ...prev,
            [brand.brandName]: [
              'shoutoutChecked',
              'socialMediaMentionChecked',
              'kpiKeywordMentionChecked',
              'bannerChecked',
              'linkSharingChecked',
            ],
          };
        });
        return {
          ...itemBrand,
          checked: !itemBrand.checked,
          shoutoutChecked: !itemBrand.checked,
          socialMediaMentionChecked: !itemBrand.checked,
          kpiKeywordMentionChecked: !itemBrand.checked,
          bannerChecked: !itemBrand.checked,
          linkSharingChecked: !itemBrand.checked,
        };
      } else return itemBrand;
    });
    setFieldValue('brands', arr);
    let arrOfBrandsId = [];
    if (!brandsId.includes(brand.id)) {
      arrOfBrandsId.push(brand.id);
      setBrandsId(brandsId.concat(arrOfBrandsId));
    } else {
      setBrandsId(brandsId.filter((item) => item !== brand.id));
    }
  };
  useEffect(() => {
    if (brandsId.length > 0) setFieldValue('brandId', brandsId);
  }, [brandsId]);
  useEffect(() => {
    if (brands && values.brands.length !== brands.length) {
      setFieldValue(
        'brandId',
        values.brands.filter((item) => item.checked).map((el) => el.id)
      );
      let arr = [];
      brands &&
        brands.forEach((brand) => {
          arr.push({
            checked: false,
            id: brand.id,
            brandName: brand.name,
            unique: true,
            shoutoutContent: '',
            shoutouts: brand
              ? (brand.organization_brand_shoutouts || [])
                  .filter((d) => !d.v2)
                  .map(
                    ({
                      v2,
                      name,
                      inclusion_terms,
                      exclusion_terms,
                      structured,
                      id,
                    }) => {
                      return {
                        structured,
                        name,
                        inclusionTerms: inclusion_terms,
                        exclusionTerms: exclusion_terms,
                        id,
                        v2,
                      };
                    }
                  )
              : [],
            shoutoutChecked: false,
            createSocialMediaMentionContent: '',
            socialMediaMentionContent: brand
              ? Array.from(
                  new Set(
                    (brand.organization_brand_social_media_mentions || []).map(
                      (d) => d.keyword
                    )
                  )
                )
              : [],
            socialMediaMentionChecked: false,
            createKpiKeywordMentionList: '',
            kpiKeywordMentionChecked: false,
            kpiKeywordMentionList:
              brand && brand.organization_brand_keyword_mentions
                ? brand.organization_brand_keyword_mentions.map(
                    (d) => d.keyword
                  )
                : [],
            linkSharingChecked: false,
            links: brand.organization_brand_link_trackings.map((d) => d.link),
            newLink: '',
            imageUrl: '',
            bannerChecked: false,
            bannerImages:
              brand && brand.organization_brand_banners
                ? [
                    ...brand.organization_brand_banners.map((d) => ({
                      bannerUrl: !d.image.includes('data:') ? d.image : '',
                      bannerFile: d.image.includes('data:') && d.image,
                      allowSmallSize: true,
                    })),
                    { bannerFile: undefined, bannerUrl: '', bannerUrlTemp: '' },
                  ]
                : [],
          });
        });

      setFieldValue(
        'brands',
        values.brandsClone
          ? values.brands
              .filter((item) => arr.map((el) => el.id).includes(item.id))
              .concat(
                arr.filter(
                  (item) => !values.brands.map((el) => el.id).includes(item.id)
                )
              )
          : arr
      );
    }
  }, [brands]);

  useEffect(() => {
    values?.brands?.forEach((item) => {
      if (item.brandName === newWizardBrand && item.checked !== true) {
        onAdd(item);
      }
    });
  }, [values.brands]);

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item width="100%" xs={12}>
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {values?.brands?.map((item, index) => (
            <Accordion
              square
              onChange={handleChange(`${index}`)}
              key={index}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                expandIcon={<ExpandMoreIcon />}
                data-id={`panel1a-header-${index}`}
                className={classes.heightAccordionSummary}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flex={1}
                >
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      className={classes.margin}
                      control={
                        <Checkbox
                          checked={item.checked}
                          onChange={(e) => onAdd(item)}
                          color="primary"
                          value="true"
                          onClick={(event) => {
                            event.stopPropagation();
                            setNewWizardBrand(null);
                          }}
                        />
                      }
                    />
                    <Avatar className={classes.avatar}>
                      {item.brandName?.trim()[0].toUpperCase()}
                    </Avatar>
                    <Typography
                      style={{ fontWeight: 600, fontSize: 18 }}
                      className={classes.heading}
                    >
                      {item.brandName}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <CampaignWizardDeliverables
                  item={item}
                  setEmptyCheckedBrands={setEmptyCheckedBrands}
                  organizationRestrictions={organizationRestrictions}
                  trialAccount={trialAccount}
                  index={index}
                  brandForm={true}
                  checkedBrands={checkedBrands}
                  setCheckedBrands={setCheckedBrands}
                />
              </AccordionDetails>
            </Accordion>
          ))}

          <div className={classes.defaultContainerButton}>
            <LoadingButton
              color="primary"
              variant="contained"
              loading={!brands}
              disabled={!brands}
              onClick={() => setOpenModal(true)}
              style={{ margin: '25px 0px' }}
            >
              add new brand
            </LoadingButton>
          </div>
        </Card>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <MyBrandsMain
              isWizardModal={true}
              onClose={handleCloseModal}
              setNewWizardBrand={setNewWizardBrand}
            />
          </div>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default enhance(CampaignWizardSponsorshipValuation);
