import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import FormikMuiTextField from '../../../components/FormikMuiTextField';

const NEW_SHOUTOUT = {
  name: '',
  inclusion: '',
  exclusion: '',
};

const shoutoutToFormikValues = (
  { name, inclusionTerms, exclusionTerms },
  brandId
) => ({
  name,
  inclusion: inclusionTerms.join(', ').trim(),
  exclusion: exclusionTerms.join(', ').trim(),
  campaign_brand_id: brandId,
});

const formikValuesToShoutout = ({ name, inclusion, exclusion }, brandId) => ({
  name,
  inclusionTerms: Array.from(
    new Set(
      inclusion
        .split(',')
        .map((i) => i.trim().toLowerCase())
        .filter((i) => i.length > 0)
    )
  ),
  exclusionTerms: Array.from(
    new Set(
      exclusion
        .split(',')
        .map((e) => e.trim().toLowerCase())
        .filter((e) => e.length > 0)
    )
  ),
  campaign_brand_id: brandId,
  structured: true,
});

const formikValuesToOutputText = (values) => {
  const { inclusionTerms, exclusionTerms } = formikValuesToShoutout(values);

  return (
    <p>
      The query will search for voice mentions of{' '}
      {inclusionTerms.map((term, index) => {
        if (index === inclusionTerms.length - 2 && inclusionTerms.length > 1)
          return (
            <React.Fragment key={index}>
              <b>{term}</b> or{' '}
            </React.Fragment>
          );

        if (
          (inclusionTerms.length > 1 && index !== inclusionTerms.length - 1) ||
          exclusionTerms.length > 0
        )
          return (
            <React.Fragment key={index}>
              <b>{term}</b>,{' '}
            </React.Fragment>
          );

        return (
          <React.Fragment key={index}>
            <b>{term}</b>.
          </React.Fragment>
        );
      })}
      {exclusionTerms.length > 0 && (
        <>
          excluding those that are in the same phrase with
          {exclusionTerms.length > 1 ? ' any of the words ' : ' the word '}
          {exclusionTerms.map((term, index) => {
            if (
              index === exclusionTerms.length - 2 &&
              exclusionTerms.length > 1
            )
              return (
                <React.Fragment key={index}>
                  <b>{term}</b> or{' '}
                </React.Fragment>
              );

            if (
              exclusionTerms.length > 1 &&
              index !== exclusionTerms.length - 1
            )
              return (
                <React.Fragment key={index}>
                  <b>{term}</b>,{' '}
                </React.Fragment>
              );

            return (
              <React.Fragment key={index}>
                <b>{term}</b>.
              </React.Fragment>
            );
          })}
        </>
      )}
    </p>
  );
};

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },

  outputContainer: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
  },
  grayBg: {
    flex: 1,
    background: '#eee',
    padding: theme.spacing(1),
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 24px',
  },
}));

export const StructuredShoutoutEditor = ({
  onClose,
  onSave,
  open,
  initialShoutout,
  brandId,
  isViewOnly,
}) => {
  const classes = useStyles();
  const renderContent = () => {
    return (
      <Formik
        initialValues={
          initialShoutout
            ? shoutoutToFormikValues(initialShoutout, brandId)
            : NEW_SHOUTOUT
        }
        validationSchema={yup.object().shape({
          name: yup.string().required('Name is a requierd field'),
          inclusion: yup
            .string()
            .required('At least one inclusion term is required'),
          exclusion: yup.string(),
        })}
        onSubmit={(values) => onSave(formikValuesToShoutout(values, brandId))}
      >
        {(formik) => {
          const {
            values: { name, inclusion, exclusion },
          } = formik;

          return (
            <Form>
              <DialogContent className={classes.formContainer}>
                <div>
                  <div className={classes.inputContainer}>
                    <FormLabel>Name</FormLabel>
                    <FormikMuiTextField
                      name="name"
                      placeholder="e.g. Cars"
                      variant="outlined"
                      value={name}
                      style={{ width: 300 }}
                      disabled={isViewOnly}
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <FormLabel>Inclusion terms (comma separated)</FormLabel>
                    <FormikMuiTextField
                      name="inclusion"
                      placeholder="e.g. Mercedes, Toyota, Ford, Honda"
                      variant="outlined"
                      value={inclusion}
                      disabled={isViewOnly}
                      fullWidth
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <FormLabel>Exclusion terms (comma separated)</FormLabel>
                    <FormikMuiTextField
                      name="exclusion"
                      placeholder="e.g. diesel"
                      variant="outlined"
                      value={exclusion}
                      disabled={isViewOnly}
                      fullWidth
                    />
                  </div>
                  <Typography color="textSecondary">
                    You can use the wildcard operator * to track voice mentions.
                    The asterisk works as a suffix at the end of a root word.
                    Example: gam* - will search for keyword mentions with the
                    root word gam, e.g. gamer, game, gaming, etc.
                  </Typography>
                </div>
                <div className={classes.outputContainer}>
                  <div className={classes.grayBg}>
                    <p>
                      <b>OUTPUT</b>
                    </p>
                    {inclusion.trim().length > 0 &&
                      formikValuesToOutputText(formik.values)}
                  </div>
                </div>
              </DialogContent>
              <DialogActions className={classes.containerButton}>
                <Button color="primary" onClick={onClose}>
                  {isViewOnly ? 'Close' : 'Cancel'}
                </Button>
                {!isViewOnly && (
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                )}
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ position: 'relative' }} disableTypography>
        <Typography variant="h6">
          {initialShoutout ? 'Edit' : 'Create new'} query
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {renderContent()}
    </Dialog>
  );
};

export default StructuredShoutoutEditor;
