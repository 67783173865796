import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { useSelector } from 'react-redux';

import facebookIcon from '../../../assets-2.0/facebook.svg';
import facebookGamingIcon from '../../../assets-2.0/facebookGaming.svg';
import instagramIcon from '../../../assets-2.0/instagram.svg';
import tiktokIcon from '../../../assets-2.0/tiktok.svg';
import twitchIcon from '../../../assets-2.0/twitch.svg';
import youtubeIcon from '../../../assets-2.0/youtube.svg';
import { isAggeroUser } from '../../../store/models/user/user.selectors';
import CampaignVideoFlag from '../CampaignVideoFlag';
import { videoHasSegments } from '../utils';

const ICONS = {
  tiktok: tiktokIcon,
  tiktok_video: tiktokIcon,
  twitch: twitchIcon,
  facebook: facebookIcon,
  youtube: youtubeIcon,
  facebook_gaming: facebookGamingIcon,
  instagram: instagramIcon,
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: `${theme.palette.text.primary} !important`,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
    },
    '& .icon': {
      opacity: 0,
    },
    '&:hover .icon': {
      opacity: 1,
    },
  },
}));

export const VideoCell = ({ video, videoTime, showType }) => {
  const classes = useStyles();

  const { video_type } = video;
  const aggeroUser = useSelector(isAggeroUser);
  const aggero = process.env.REACT_APP_AGGERO_API_URL;

  return (
    <div style={{ width: '100%' }}>
      {showType && (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: -10 }}>
          {(video_type === 'live-vod' || video_type === 'live') && (
            <CampaignVideoFlag title="LIVE STREAM" color="#0036D9" />
          )}
          {(video_type === 'live-vod' || video_type === 'vod') && (
            <CampaignVideoFlag title="VOD" color="#A300D8" />
          )}
          {video_type === 'video' && (
            <CampaignVideoFlag title="VIDEO" color="#A300D8" />
          )}
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={'Video'}>
          <img
            src={ICONS[video.resource]}
            alt="platform logo"
            style={{ height: '1.5em', marginRight: 8 }}
          />
        </Tooltip>

        <Typography color="textSecondary" style={{ fontSize: '0.8rem' }}>
          {videoTime}
        </Typography>
        {videoHasSegments(video) && (
          <Tooltip
            placement="top"
            title="Showing stats only for the selected time frame."
          >
            <AvTimerIcon
              style={{ padding: 0, color: '#A300D8', marginLeft: 8 }}
            />
          </Tooltip>
        )}
      </div>
      <a href={video.link} target="_blank" rel="noopener noreferrer">
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          className={classes.link}
        >
          <Tooltip placement="top" title={video.video_title}>
            <Typography
              className="text-ellipsis title"
              style={{ '--lines': 2, marginRight: 4, flex: 1 }}
            >
              {video.video_title}
            </Typography>
          </Tooltip>

          <OpenInNewIcon className="icon" />
        </div>
      </a>
      {aggeroUser && (
        <a
          href={`${aggero}/admin/videos/${video.id}/download_video_transcript`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Transcript
        </a>
      )}
    </div>
  );
};

export default VideoCell;
