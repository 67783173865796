import { createAsyncThunk } from '@reduxjs/toolkit';

import { postSessionLogin } from '../../../../ajax';
import { getErrorMessagesFromResponse } from '../../../../common/ErrorMessageUtil';
import actions from '../../../../store/actions';
import { SLICE_NAME } from './types';

interface SigninInfluencerPayload {
  email: string;
  password: string;
}

export const signinInfluencer = createAsyncThunk<void, SigninInfluencerPayload>(
  `${SLICE_NAME}/signin`,
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await postSessionLogin(email, password, false);

      dispatch(
        // @ts-ignore actions are imported using require(glob); this is very dumb and breaks ts
        actions.userSignedIn({
          ...res.user,
          displayName: res.user.name,
        })
      );

      dispatch(
        // @ts-ignore actions are imported using require(glob); this is very dumb and breaks ts
        actions.setLoggedInViewInfluencer()
      );
    } catch (err) {
      return rejectWithValue(getErrorMessagesFromResponse(err));
    }
  }
);
