import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { PropsWithChildren } from 'react';

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiDialog-paperWidthSm': {
      width: 440,
      '& h2, & label': {
        color: '#000000',
      },
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  actions: {
    paddding: 0,
  },
  cancelButton: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  confirmButton: {
    padding: '8px 29px',
    marginLeft: 12,
  },
}));

interface ModalShellProps {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title: string;
  noActionButtons?: boolean;
  disableConfirm?: boolean;
  loading?: boolean;
}

export const ModalShell = ({
  open,
  onClose,
  onConfirm,
  title,
  noActionButtons,
  disableConfirm,
  children,
  loading,
}: PropsWithChildren<ModalShellProps>) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.modal}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {noActionButtons && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeIcon}
        >
          <Close />
        </IconButton>
      )}
      <DialogContent>
        {children}
        {!noActionButtons && (
          <DialogActions className={classes.actions}>
            <Button
              onClick={onClose}
              color="primary"
              className={classes.cancelButton}
              disabled={loading}
            >
              CANCEL
            </Button>
            <Button
              onClick={onConfirm}
              color="primary"
              variant="contained"
              className={classes.confirmButton}
              disabled={disableConfirm || loading}
            >
              {loading ? 'Loading...' : 'SAVE'}
            </Button>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};
