import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(30),
  },
}));

export default (props) => {
  const classes = useStyles();

  return <IconButton {...props} classes={{ ...classes, ...props.classes }} />;
};
