import { Moment } from 'moment';
import momentTz from 'moment-timezone';

import { Campaign, TimeFrame } from '../types';

export const isValidTimeFrame = (
  timeFrame: Partial<TimeFrame>
): timeFrame is TimeFrame => {
  const { start, end } = timeFrame;

  if (end == null || start == null) return false;

  if (!start.isValid() || !end.isValid()) return false;

  return true;
};

export const fixTimeFrameIfEmpty = (
  timeFrame: Partial<TimeFrame>,
  campaignStartTime: Moment,
  campaignEndTime: Moment
): TimeFrame => {
  const { start, end } = timeFrame;

  return {
    start: start ?? campaignStartTime,
    end: end ?? campaignEndTime,
  };
};

export const getCampaignStartEndDates = (campaign: Campaign): TimeFrame => {
  const { start_date, end_date, campaign_days, time_zone } = campaign;
  const timeZone = time_zone || 'UTC';
  const timeFrame: TimeFrame = {
    start: momentTz.tz(start_date, timeZone).startOf('day'),
    end: momentTz.tz(end_date, timeZone).endOf('day'),
  };

  if (isValidTimeFrame(timeFrame)) {
    return timeFrame;
  }

  const newDays = campaign_days && campaign_days.length && campaign_days.sort();
  const campaignStartDay =
    newDays &&
    newDays.length &&
    momentTz.tz(newDays[0], timeZone).startOf('day');
  const campaignEndDay =
    newDays &&
    newDays.length &&
    momentTz.tz(newDays[newDays.length - 1], timeZone).endOf('day');

  return {
    start: campaignStartDay,
    end: campaignEndDay,
  };
};
