import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import numeral from 'numeral';
import qs from 'query-string';
import React, { useState } from 'react';

import { postMissingSearchResults } from '../../ajax';
import useRouter from '../../hooks/use-router';
import { RESULTS_PER_PAGE } from '../../store/sagas-folder/influencers-mounted';
import InfluencersTable from './InfluencersTable';

const useStyles = makeStyles({
  buttonIcon: {
    marginRight: 8,
  },
  fs: {
    fontSize: '15px',
  },
});

function InfluencersMain(props) {
  const {
    influencers,
    loading,
    onFetchMore,
    hasNextPage,
    totalResults,
    pageIndex,
    params: {
      streamer_talking_about,
      audience_interest_categories,
      streamer_interest_categories,
    },
  } = props;

  const router = useRouter();

  const { network, search_watchlist, campaign_goal } = qs.parse(
    router.location.search
  );
  const [notifyMissingResults, setNotifyMissingResults] = useState(
    search_watchlist || !!streamer_talking_about ? 'hidden' : 'default'
  );
  const classes = useStyles();

  function notifyOnNoResults() {
    const filters = qs.parse(router.location.search);
    const results_count = props.influencers.length;

    postMissingSearchResults({ filters, results_count }).then(() => {
      setNotifyMissingResults('done');
    });
  }

  function renderNoResultsButton() {
    if (notifyMissingResults === 'hidden' || hasNextPage || loading) {
      return null;
    }

    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} md={6}>
          {notifyMissingResults === 'done' && (
            <Card>
              <CardContent>
                <p className={classes.fs}>
                  You will receive a notification when new creators are added on
                  the platform!
                </p>
              </CardContent>
            </Card>
          )}
          {notifyMissingResults === 'default' &&
            !audience_interest_categories &&
            !streamer_interest_categories && (
              <Card>
                <CardContent>
                  <p className={classes.fs}>
                    We are adding new talents to our platform every day. If you
                    need fresh content creators matching your selected filters
                    just let us know.
                  </p>

                  <Grid container justify="flex-start">
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: '14px' }}
                      onClick={() => notifyOnNoResults()}
                    >
                      {' '}
                      I want more content creators
                    </button>
                    {/* <button
                  className="button-link"
                  onClick={() => setNotifyMissingResults('hidden')}
                >
                  No, thank you
                </button> */}
                  </Grid>
                </CardContent>
              </Card>
            )}
        </Grid>
      </Grid>
    );
  }

  function renderNoResults() {
    return (
      <div id="mainContent">
        <div className="row gap-20 masonry pos-r">
          <div className="masonry-sizer col-md-6"></div>
          <div className="masonry-item  w-100">
            <p className="text-center">
              We are sorry! There are no results for the selected filter(s).
              Please try different criteria.
            </p>
          </div>
        </div>
      </div>
    );
  }

  function renderInfluencersTable() {
    return (
      <InfluencersTable
        searchWatchlist={search_watchlist}
        hasCampaignGoal={campaign_goal !== 'best_fit'}
        influencers={influencers}
        loading={loading}
        network={network}
        onFetchMore={onFetchMore}
        hasNextPage={hasNextPage}
      />
    );
  }
  return (
    <Grid container spacing={4} direction="column" wrap="nowrap">
      <Grid item>
        <Grid container spacing={4} wrap="nowrap" justify="space-between">
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h5" component="h2">
              {loading && influencers.length === 0 ? (
                'C'
              ) : (
                <>
                  <b>{numeral(totalResults).format('0,0')}</b> c
                </>
              )}
              reators matching your filters
            </Typography>
            <Typography>
              {influencers.length === 0 ? (
                ''
              ) : influencers.length < 50 ? (
                <>
                  {`Showing  ${numeral(influencers.length).format(
                    '0,0'
                  )} of ${numeral(totalResults).format('0,0')} `}
                </>
              ) : (
                <>
                  {`Showing  ${numeral(
                    (pageIndex + 1) * RESULTS_PER_PAGE > totalResults
                      ? totalResults
                      : (pageIndex + 1) * RESULTS_PER_PAGE
                  ).format('0,0')} of ${numeral(totalResults).format('0,0')} `}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        {renderInfluencersTable()}
        {!loading && influencers.length === 0 && renderNoResults()}
      </Grid>

      <Grid item>{renderNoResultsButton()}</Grid>
    </Grid>
  );
}

export default InfluencersMain;
