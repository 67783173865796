import {
  alpha,
  Box,
  withStyles,
  // makeStyles,
} from '@material-ui/core';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';
import React, { useState } from 'react';
// import { useLocation } from 'react-router';
// const useStyles = makeStyles((theme) => ({
//   title: {
//     position: 'absolute',
//     top: '60px',
//     right: '15%',
//     fontSize: '1.2em',
//   },
// }));
const ToggleButton = withStyles((theme) => ({
  root: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    width: 140,
  },
  selected: {
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
    background: `${alpha(theme.palette.primary.main, 0.2)} !important`,
  },
}))(MuiToggleButton);

export const useDetailedViewToggle = (usedInfluencers) => {
  const multipleInfluencers = usedInfluencers?.length > 1;

  const [detailed, setDetailed] = useState(false);

  return { detailed: detailed || !multipleInfluencers, setDetailed };
};

export const DetailedViewToggle = ({
  setDetailed,
  detailed,
  usedInfluencers,
}) => {
  // const classes = useStyles();
  // const location = useLocation();
  const multipleInfluencers = usedInfluencers?.length > 1;

  if (!multipleInfluencers) return <div style={{ height: 32 }} />;

  const onChange = (_, value) => setDetailed(value === 'detailed');

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      pt={3}
      pb={6}
    >
      <ToggleButtonGroup
        exclusive
        onChange={onChange}
        value={detailed ? 'detailed' : 'summary'}
      >
        <ToggleButton value="summary">Summary</ToggleButton>
        <ToggleButton value="detailed">Detailed</ToggleButton>
      </ToggleButtonGroup>

      {/* {location.pathname.includes('sponsorship') && (
        <Typography variant="h5" className={classes.title}>
          View by
        </Typography>
      )} */}
    </Box>
  );
};

export default DetailedViewToggle;
