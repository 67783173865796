import React, { useCallback, useMemo } from 'react';

import { InfluencerAvatar } from '../../../../components/InfluencerAvatar/InfluencerAvatar';
import Table, { StatBox } from '../../Table';
import { CampaignInfluencer } from '../../types';
import { SocialMediaPerformanceSummary } from './store/types';

const getRows = ({
  usedInfluencers,
  socialMediaPerformanceSummary,
}: {
  usedInfluencers: CampaignInfluencer[];
  socialMediaPerformanceSummary: SocialMediaPerformanceSummary[];
}) =>
  socialMediaPerformanceSummary.map((influencerSummary) => {
    const influencer = usedInfluencers.find(
      (i) => i.id === influencerSummary.campaign_influencer_id
    );

    return {
      influencer,
      creator: influencer.name,
      followers:
        influencerSummary.followers_before + influencerSummary.followers_gained,
      posts: influencerSummary.posts_count,
      engagements: influencerSummary.engagements, // missing from API
      impressions: influencerSummary.impressions_count,
      estImpressions: influencerSummary.est_impressions_count,
      totalEMV: influencerSummary.emv,
      retweets: influencerSummary.shares_count,
      likes: influencerSummary.likes_count,
      replies: influencerSummary.comments_count,
      followersGained: influencerSummary.followers_gained,
      key: influencer.id,
      impressionsAreEst: !!influencerSummary.est_impressions_count,
    };
  });

const getCols = (hasSharesOrRetweets, allImpressionsAreEst) =>
  [
    {
      label: 'Creator',
      key: 'creator',
      render: (row) => <InfluencerAvatar influencer={row.influencer} />,
      stretch: true,
    },
    {
      label: 'Posts',
      key: 'posts',
      render: (data) => <StatBox stat={data.posts} />,
    },
    {
      label: 'Followers',
      key: 'followers',
      render: (row) => (
        <StatBox
          stat={row.followers}
          delta={row.followersGained}
          deltaInfo="Followers gained during the campaign."
        />
      ),
    },
    hasSharesOrRetweets
      ? {
          label: 'Retweets/Shares',
          key: 'retweets',
          render: (row) => <StatBox stat={row.retweets} />,
        }
      : null,
    {
      label: 'Likes',
      key: 'likes',
      render: (row) => <StatBox stat={row.likes} />,
    },
    {
      label: 'Replies/Cmts',
      key: 'replies',
      render: (row) => <StatBox stat={row.replies} />,
    },
    {
      label: 'Engagements',
      key: 'engagements',
      render: (row) => <StatBox stat={row.engagements} />,
    },
    {
      label: allImpressionsAreEst ? 'Est. Impressions' : 'Impressions',
      key: 'impressions',
      render: (row) => (
        <StatBox
          stat={row.impressions ? row.impressions : row.estImpressions}
        />
      ),
    },
    {
      label: 'Value',
      key: 'totalEMV',
      render: (data) => (
        <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
      ),
    },
  ].filter((c) => c !== null);

interface SummaryTableProps {
  usedInfluencers: CampaignInfluencer[];
  socialMediaPerformanceSummary: SocialMediaPerformanceSummary[];
  hasSharesOrRetweets: boolean;
  setSelectedCampaignInfluencer: (id: number) => void;
}

export const SummaryTable = ({
  usedInfluencers,
  socialMediaPerformanceSummary,
  hasSharesOrRetweets,
  setSelectedCampaignInfluencer,
}: SummaryTableProps) => {
  const rows = useMemo(
    () => getRows({ usedInfluencers, socialMediaPerformanceSummary }),
    [socialMediaPerformanceSummary, usedInfluencers]
  );

  const allImpressionsAreEst = rows.some(
    ({ impressionsAreEst }) => impressionsAreEst
  );

  const cols = useMemo(
    () => getCols(hasSharesOrRetweets, allImpressionsAreEst),
    [allImpressionsAreEst, hasSharesOrRetweets]
  );

  const onRowClick = useCallback(
    ({ key }) => setSelectedCampaignInfluencer(key),
    [setSelectedCampaignInfluencer]
  );
  const idTable = 'performanceTab-socialMedia-summary';

  return (
    // @ts-expect-error the Table component isn't typed
    <Table
      cols={cols}
      rows={rows}
      defaultSortCol="posts"
      minWidth={1390}
      onRowClick={onRowClick}
      idTable={idTable}
    />
  );
};

export default SummaryTable;
