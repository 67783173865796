import moment from 'moment';
import { useMemo } from 'react';

import { videoIsFromStreamingPlatform } from '../utils';

export const useUsedVideos = ({
  campaign,
  selectedCampaignInfluencer,
  platformFilter,
  timeFrame,
  onlyStreams = false,
  languageFilter = [],
}) => {
  const usedVideos = useMemo(
    () =>
      campaign.videos.filter(
        (video) =>
          moment(video.published_at).isBetween(
            timeFrame.start,
            timeFrame.end
          ) &&
          (selectedCampaignInfluencer === 'all' ||
            video.campaign_influencer_ids.indexOf(
              selectedCampaignInfluencer.id
            ) !== -1) &&
          (platformFilter === 'all' || video.resource === platformFilter) &&
          (onlyStreams ? videoIsFromStreamingPlatform(video) : true) &&
          (languageFilter.length === 0 ||
            languageFilter.includes(video.language))
      ),
    [timeFrame, campaign.id, platformFilter, languageFilter]
  );

  const usedVideosIds = useMemo(
    () => new Set(usedVideos.map((video) => video.id)),
    [usedVideos]
  );

  return { usedVideos, usedVideosIds };
};
