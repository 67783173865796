import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  root: {
    textTransform: 'uppercase',
  },
});

function CardTitle(props) {
  const classes = useStyles();

  return (
    <Typography
      variant="h4"
      {...props}
      className={clsx(props.className, classes.root)}
    />
  );
}

export default CardTitle;
