import between from '../utils/between';

export function getSentiment(sentiment) {
  if (between(0.1, 1.001)(sentiment)) {
    // positive
    return 1;
  } else if (between(-1.001, -0.1)(sentiment)) {
    // negative
    return 2;
  } else {
    // neutral
    return 3;
  }
}

export function oldGetSentiment(sentiment) {
  if (between(0.5, 1)(sentiment)) {
    return 1; // positive
  } else if (between(0.1, 0.50001)(sentiment)) {
    return 2; // slightly positive
  } else if (between(-0.50001, -0.1)(sentiment)) {
    return 4; // slightly negative
  } else if (between(-1, -0.5)(sentiment)) {
    return 5; // slightly negative
  } else {
    return 3; // neutral
  }
}
