import TableCell from '@material-ui/core/TableCell';
import React from 'react';

export default function TableCellWithLink({
  noLink,
  children,
  alignRight,
  link,
  className,
  id,
}) {
  return (
    <TableCell
      align={alignRight ? 'right' : 'left'}
      className={className}
      data-id={id}
    >
      {!noLink && (
        <a
          href={link}
          onClick={(event) => event.preventDefault()}
          style={{ color: '#333' }}
        >
          {children}
        </a>
      )}
      {noLink && <div> {children} </div>}
    </TableCell>
  );
}
