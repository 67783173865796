import { useEffect } from 'react';

function useEnableFacebook() {
  useEffect(() => {
    /* eslint-disable */
    window.fbAsyncInit = () => {
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        status: true,
        xfbml: true,
        version: 'v19.0',
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    /* eslint-enable */
  }, []);
}

export default useEnableFacebook;
