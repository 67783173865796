import { makeStyles, Tooltip } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import Icon from '@material-ui/core/Icon';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 18,
    position: 'absolute',
    marginLeft: theme.spacing(1),
  },
}));

function ExplainedFormLabel(props) {
  const {
    children,
    explanation,
    explanationIcon = 'help_outline',
    IconProps = {},
    TooltipProps = {},
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <FormLabel {...rest}>
      {children}

      {explanation && (
        <Tooltip title={explanation} placement="top" {...TooltipProps}>
          <Icon className={classes.icon} {...IconProps}>
            {explanationIcon}
          </Icon>
        </Tooltip>
      )}
    </FormLabel>
  );
}

export default ExplainedFormLabel;
