import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import LoadingButton from '../../../../components/LoadingButton';
import Banners from './Banners';
import ChatMentions from './ChatMentions';
import Links from './Links';
import Shoutouts from './Shoutouts';
import SocialMediaMentions from './SocialMediaMentions';
// import ShoutoutsV2 from './ShoutoutsV2/ShoutoutsV2';

const useStyles = makeStyles((theme) => ({
  containerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: theme.spacing(4),
  },
  containerFlex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '16px',
  },
  leftColumn: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
  },
  rightColumn: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  trialDisabled: {
    pointerEvents: 'none',
    backgroundColor: '#ccc',
  },
  label: {
    fontSize: '24px',
    fontWeight: 'bold',
    paddingLeft: 0,
  },
  brandNameContainer: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
  },
  h2Margin: {
    margin: '40px 0 15px 0',
  },
  h2MarginBottom: {
    marginBottom: '15px',
  },
  containerButton: {
    display: 'flex',
    padding: '0 16px',
    justifyContent: 'flex-end',
  },
  input: {
    fontSize: '18px',
  },
}));
export const MyBrandsEdit = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();
  const trialClassName = classes.trialDisabled;

  const { isViewOnly } = props;

  const {
    ui_disable_shoutouts,
    ui_disable_banners,
    ui_banner_limit_per_campaign,
  } = props.organizationRestrictions;

  useEffect(() => {
    props.brand.name = values.brandName;
  }, [values.brandName]);

  return (
    <Form style={{ width: '100%' }}>
      <div className={classes.containerFlex}>
        <div className={classes.brandNameContainer}>
          <Typography variant="h2" className={classes.h2MarginBottom}>
            Brand name
          </Typography>
          <TextField
            disabled={isViewOnly}
            fullWidth
            variant="outlined"
            inputProps={{
              style: { fontSize: 18 },
              'data-id': `brandName-${props.brand.id}`,
            }}
            placeholder={values.brandName}
            value={values.brandName}
            onChange={(e) => setFieldValue('brandName', e.target.value)}
          />
          <Typography variant="h2" className={classes.h2Margin}>
            Media
          </Typography>
        </div>
        <div className={classes.containerGrid}>
          <div className={classes.leftColumn}>
            <Shoutouts
              trialClassName={trialClassName}
              disabled={ui_disable_shoutouts}
              isViewOnly={isViewOnly}
              id={props.brand.id}
            />
            <SocialMediaMentions isViewOnly={isViewOnly} id={props.brand.id} />
            <Links isViewOnly={isViewOnly} id={props.brand.id} />
          </div>
          <div className={classes.rightColumn}>
            <Banners
              isViewOnly={isViewOnly}
              trialClassName={trialClassName}
              disabled={ui_disable_banners}
              limit={
                !!ui_banner_limit_per_campaign
                  ? ui_banner_limit_per_campaign
                  : Infinity
              }
              id={props.brand.id}
            />
            <ChatMentions isViewOnly={isViewOnly} id={props.brand.id} />
          </div>
        </div>
      </div>
      {!isViewOnly && (
        <div className={classes.containerButton}>
          <LoadingButton
            data-id={`saveChanges-${props.brand.id}`}
            key="submit"
            type="submit"
            variant="contained"
            color="primary"
            style={{ margin: '25px 0px' }}
            onClick={() => {
              props.handleSetBrandName('');
            }}
            disabled={!values.brandName}
          >
            Save changes
          </LoadingButton>
        </div>
      )}
    </Form>
  );
};

export default MyBrandsEdit;
