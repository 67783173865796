import { ComponentMeta, ComponentStory } from '@storybook/react';
import React from 'react';

import {
  AGE_COLOR_SCALE,
  AudienceDonutChart,
  GENDER_COLOR_SCALE,
} from './AudienceDonutChart';

// More on default export: https://storybook.js.org/docs/react/writing-stories/introduction#default-export
export default {
  title: 'AudienceDonutChart',
  component: AudienceDonutChart,
  argTypes: {
    width: { type: 'number' },
    height: { type: 'number' },
  },
} as ComponentMeta<typeof AudienceDonutChart>;

const Template: ComponentStory<typeof AudienceDonutChart> = (arg) => {
  return <AudienceDonutChart {...arg} />;
};

export const GENDER_DATA = [
  { label: 'Male', value: 50 },
  { label: 'Female', value: 50 },
];

export const AGE_DATA = [
  {
    label: '12-17',
    value: 36,
  },
  {
    label: '18-24',
    value: 327,
  },
  {
    label: '25-34',
    value: 2456,
  },
  {
    label: '35-44',
    value: 665,
  },
  {
    label: '45-54',
    value: 96,
  },
  {
    label: '55-64',
    value: 17,
  },
  {
    label: '65-200',
    value: 6,
  },
];

export const Primary = Template.bind({});
Primary.args = {
  data: GENDER_DATA,
  width: 1000,
  height: 600,
  colorScale: GENDER_COLOR_SCALE,
};

export const Age = Template.bind({});
Age.args = {
  data: AGE_DATA,
  width: 1000,
  height: 600,
  colorScale: AGE_COLOR_SCALE,
};
