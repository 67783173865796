import { makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';

import { selectStreamsVideoTypeFilter } from './store/Streams.selectors';
import { setStreamsVideoTypeFilter } from './store/Streams.slice';

const useStyles = makeStyles(() => ({
  toggleButton: {
    textTransform: 'capitalize',
    '&.Mui-selected': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
}));

export const VideoTypeToggle = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const streamsVideoTypeFilter = useSelector(selectStreamsVideoTypeFilter());

  return (
    <ToggleButtonGroup
      value={streamsVideoTypeFilter}
      size="small"
      exclusive
      onChange={(_, selectedValue) => {
        const value = selectedValue === null ? 'all' : selectedValue;
        dispatch(setStreamsVideoTypeFilter(value));
      }}
    >
      <ToggleButton className={classes.toggleButton} value="all">
        All
      </ToggleButton>
      <ToggleButton className={classes.toggleButton} value="live_stream">
        Live Streams
      </ToggleButton>
      <ToggleButton className={classes.toggleButton} value="vod">
        Videos
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
