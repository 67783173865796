import {
  CAMPAIGN_CREATION_FAILED,
  CAMPAIGN_CREATION_REQUESTED,
  CAMPAIGN_CREATION_SUCCEEDED,
  CAMPAIGN_DELETE_FAILED,
  CAMPAIGN_DELETE_REQUESTED,
  CAMPAIGN_DELETE_SUCCEEDED,
  CAMPAIGN_MULTIPLE_VIDEOS_DELETE_FAILED,
  CAMPAIGN_MULTIPLE_VIDEOS_DELETE_REQUESTED,
  CAMPAIGN_MULTIPLE_VIDEOS_DELETE_SUCCEEDED,
  CAMPAIGN_REQUEST_FAILED,
  CAMPAIGN_REQUEST_RESET,
  CAMPAIGN_REQUEST_SUCCEEDED,
  CAMPAIGN_REQUESTED,
  CAMPAIGN_SOCIAL_POST_DELETE_FAILED,
  CAMPAIGN_SOCIAL_POST_DELETE_REQUESTED,
  CAMPAIGN_SOCIAL_POST_DELETE_SUCCEEDED,
  CAMPAIGN_UPDATE_FAILED,
  CAMPAIGN_UPDATE_REQUESTED,
  CAMPAIGN_UPDATE_SUCCEEDED,
  CAMPAIGN_VIDEO_ADD_FILTER_SUCCEEDED,
  CAMPAIGN_VIDEO_DELETE_FAILED,
  CAMPAIGN_VIDEO_DELETE_REQUESTED,
  CAMPAIGN_VIDEO_DELETE_SUCCEEDED,
  CAMPAIGNS_REQUEST_FAILED,
  CAMPAIGNS_REQUEST_SUCCEEDED,
  CAMPAIGNS_REQUESTED,
} from '../../action-types';
import createAction from '../../create-action';

export const campaignsRequested = createAction(CAMPAIGNS_REQUESTED, {
  request: {
    name: 'campaigns',
    type: 'requested',
  },
});

export const campaignsRequestSucceeded = createAction(
  CAMPAIGNS_REQUEST_SUCCEEDED,
  {
    request: {
      type: 'succeeded',
      name: `campaigns`,
    },
  }
);

export const campaignsRequestFailed = createAction(CAMPAIGNS_REQUEST_FAILED, {
  request: {
    name: 'campaigns',
    type: 'failed',
  },
});

export const campaignRequested = ({ campaignId }) => ({
  type: CAMPAIGN_REQUESTED,
  meta: {
    request: {
      name: `campaign-${campaignId}`,
      type: 'requested',
    },
  },
});

export const campaignRequestSucceeded = (payload) => ({
  type: CAMPAIGN_REQUEST_SUCCEEDED,
  payload,
  meta: {
    request: {
      name: `campaign-${payload.campaignId}`,
      type: 'succeeded',
    },
  },
});

export const campaignRequestFailed = (payload) => ({
  type: CAMPAIGN_REQUEST_FAILED,
  payload,
  meta: {
    request: {
      name: `campaign-${payload.campaignId}`,
      type: 'failed',
    },
  },
});

// Payload is { key: `campaign-${campaignId}` }
export const campaignRequestReset = (payload) => ({
  type: CAMPAIGN_REQUEST_RESET,
  payload,
  meta: {
    request: {
      name: payload.key,
      type: 'reset',
    },
  },
});

export const campaignCreationRequested = createAction(
  CAMPAIGN_CREATION_REQUESTED,
  {
    request: {
      name: 'campaign-creation',
      type: 'requested',
    },
  }
);

export const campaignCreationSucceeded = createAction(
  CAMPAIGN_CREATION_SUCCEEDED,
  {
    request: {
      name: 'campaign-creation',
      type: 'succeeded',
    },
    toast: {
      message: 'Campaign created successfully',
      type: 'success',
      lifespan: 4000,
    },
  }
);

export const campaignCreationFailed = (payload) => ({
  type: CAMPAIGN_CREATION_FAILED,
  payload,
  meta: {
    request: {
      name: 'campaign-creation',
      type: 'failed',
    },
    toast: {
      message: payload.error,
      type: 'error',
      lifespan: 4000,
    },
  },
});

export const campaignUpdateRequested = createAction(CAMPAIGN_UPDATE_REQUESTED, {
  request: {
    name: 'campaign-update',
    type: 'requested',
  },
});

export const campaignUpdateSucceeded = createAction(CAMPAIGN_UPDATE_SUCCEEDED, {
  request: {
    name: 'campaign-update',
    type: 'succeeded',
  },
  toast: {
    message: 'Campaign updated successfully',
    type: 'success',
    lifespan: 4000,
  },
});

export const campaignUpdateFailed = (payload) => ({
  type: CAMPAIGN_UPDATE_FAILED,
  payload,
  meta: {
    request: {
      name: 'campaign-update',
      type: 'failed',
    },
    toast: {
      message: payload.error,
      type: 'error',
      lifespan: 4000,
    },
  },
});

export const campaignDeleteRequested = (payload) => ({
  type: CAMPAIGN_DELETE_REQUESTED,
  payload,
  meta: {
    request: {
      type: 'requested',
      name: `campaign-delete-${payload.id}`,
    },
  },
});

export const campaignDeleteSucceeded = (payload) => ({
  type: CAMPAIGN_DELETE_SUCCEEDED,
  payload,
  meta: {
    request: {
      type: 'succeeded',
      name: `campaign-delete-${payload.id}`,
    },
    toast: {
      message: 'Campaign deleted successfully',
      type: 'success',
      lifespan: 4000,
    },
  },
});

export const campaignDeleteFailed = (payload) => ({
  type: CAMPAIGN_DELETE_FAILED,
  payload,
  meta: {
    request: {
      type: 'failed',
      name: `campaign-delete-${payload.id}`,
    },
  },
});

export const campaignSocialPostDeleteRequested = (payload) => ({
  type: CAMPAIGN_SOCIAL_POST_DELETE_REQUESTED,
  payload,
  meta: {
    request: {
      type: 'requested',
      name: `campaign-social-post-delete-${payload.id}`,
    },
  },
});

export const campaignSocialPostDeleteSucceeded = (payload) => ({
  type: CAMPAIGN_SOCIAL_POST_DELETE_SUCCEEDED,
  payload,
  meta: {
    request: {
      type: 'succeeded',
      name: `campaign-social-post-delete-${payload.id}`,
    },
    toast: {
      message: 'Campaign social post deleted successfully!',
      type: 'success',
      lifespan: 4000,
    },
  },
});

export const campaignSocialPostDeleteFailed = (payload) => ({
  type: CAMPAIGN_SOCIAL_POST_DELETE_FAILED,
  payload,
  meta: {
    request: {
      type: 'failed',
      name: `campaign-social-post-delete-${payload.id}`,
    },
  },
});

export const campaignVideoDeleteRequested = (payload) => ({
  type: CAMPAIGN_VIDEO_DELETE_REQUESTED,
  payload,
  meta: {
    request: {
      type: 'requested',
      name: `campaign-video-delete-${payload.id}`,
    },
  },
});

export const campaignVideoDeleteSucceeded = (payload) => ({
  type: CAMPAIGN_VIDEO_DELETE_SUCCEEDED,
  payload,
  meta: {
    request: {
      type: 'succeeded',
      name: `campaign-video-delete-${payload.id}`,
    },
    toast: {
      message: 'Campaign video deleted successfully!',
      type: 'success',
      lifespan: 4000,
    },
  },
});

export const campaignVideoDeleteFailed = (payload) => ({
  type: CAMPAIGN_VIDEO_DELETE_FAILED,
  payload,
  meta: {
    request: {
      type: 'failed',
      name: `campaign-video-delete-${payload.id}`,
    },
  },
});

export const campaignMultipleVideosDeleteRequested = (payload) => ({
  type: CAMPAIGN_MULTIPLE_VIDEOS_DELETE_REQUESTED,
  payload,
  meta: {
    request: {
      type: 'requested',
      name: `campaign-multiple-videos-delete-${payload.videoIds.join('-')}`,
    },
  },
});

export const campaignMultipleVideosDeleteSucceeded = (payload) => ({
  type: CAMPAIGN_MULTIPLE_VIDEOS_DELETE_SUCCEEDED,
  payload,
  meta: {
    request: {
      type: 'succeeded',
      name: `campaign-multiple-video-delete-${payload.videoIds.join('-')}`,
    },
    toast: {
      message: 'Campaign videos deleted successfully!',
      type: 'success',
      lifespan: 4000,
    },
  },
});

export const campaignMultipleVideosDeleteFailed = (payload) => ({
  type: CAMPAIGN_MULTIPLE_VIDEOS_DELETE_FAILED,
  payload,
  meta: {
    request: {
      type: 'failed',
      name: `campaign-multiple-video-delete-${payload.videoIds.join('-')}`,
    },
  },
});

export const campaignVideoAddFilterSucceeded = (payload) => ({
  type: CAMPAIGN_VIDEO_ADD_FILTER_SUCCEEDED,
  payload,
});
