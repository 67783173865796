import {
  Chip,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { DatePicker } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import _intersection from 'lodash/intersection';
import _uniq from 'lodash/uniq';
import _without from 'lodash/without';
import moment from 'moment';
import React, { useState } from 'react';

import OutlinedInput from '../../../../components/OutlinedInput';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  input: {
    flex: 1,
    fontSize: 16,
  },
  formLabelStyle: {
    fontSize: 18,
    marginRight: 'auto',
    marginBottom: 15,
  },
  select: {
    width: '100%',
  },
  chipContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  chip: {
    cursor: 'pointer',
    padding: '10px 10px',
    height: '37px',
    marginRight: '9px',
    marginBottom: '9px',
    fontSize: '1rem',
    '& span': {
      padding: 0,
    },
  },
  chipActive: {
    border: '1px solid #0048F2',
    color: '#0048F2',
    background: 'rgba(204, 219, 255, 0.16)',
  },
}));
const gender = ['Male', 'Female', 'Custom'];
const labels = [
  'Games',
  'Sports',
  'ASMR',
  'Entertainment',
  'Travel & Outdoors',
  'Science & Tech',
  'Fashion',
  'Beauty',
  'Politics',
  'Special Events',
  'eSports',
];

const InfoForm = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const [isOther, setIsOther] = useState(
    _without(values.interests, ...labels).length ? true : false
  );
  const [otherInterests, setOtherInterests] = useState(
    _without(values.interests, ...labels)
  );
  const [dateBirth, setDateBirth] = useState(
    values.date_of_birth ? values.date_of_birth : null
  );

  const handleSelectedLabels = (labeles) => {
    if (Array.isArray(labeles)) {
      labeles.forEach((label, index) => (labeles[index] = label.trim()));
      setFieldValue(
        'interests',
        _uniq([
          ..._intersection(values.interests, labels),
          ...labeles.filter((label) => label !== ''),
        ])
      );
    } else {
      if (values.interests.includes(labeles)) {
        setFieldValue(
          'interests',
          values.interests.filter((el) => el !== labeles)
        );
      } else {
        setFieldValue('interests', [...values.interests, labeles]);
      }
    }
  };
  const handleChange = (e) => {
    setFieldValue('gender.maleOrFemale', e.target.value);
  };
  return (
    <>
      <Grid>
        <div className={classes.inputContainer}>
          <FormLabel className={classes.formLabelStyle}>Name</FormLabel>
          <TextField
            fullWidth
            placeholder="The One"
            variant="outlined"
            className={classes.input}
            value={values.name}
            style={{ marginBottom: 16 }}
            onChange={(e) => setFieldValue('name', e.target.value)}
          />

          <FormLabel className={classes.formLabelStyle}>
            Date of Birth
          </FormLabel>
          <DatePicker
            fullWidth
            maxDate={moment().subtract('years', 18).format('YYYY-MM-DD')}
            inputVariant="outlined"
            className={classes.input}
            format="DD/MM/YYYY"
            value={dateBirth}
            style={{ marginBottom: 16 }}
            onChange={(date) => {
              setDateBirth(moment(date).format('YYYY-MM-DD'));
              setFieldValue('date_of_birth', moment(date).format('YYYY-MM-DD'));
            }}
            labelFunc={() =>
              dateBirth ? moment(dateBirth).format('DD/MM/YYYY') : 'DD/MM/YYYY'
            }
          />

          <FormLabel className={classes.formLabelStyle}>Location</FormLabel>
          <TextField
            fullWidth
            placeholder="Country"
            variant="outlined"
            className={classes.input}
            value={values.location}
            style={{ marginBottom: 16 }}
            onChange={(e) => setFieldValue('location', e.target.value)}
          />

          <FormLabel className={classes.formLabelStyle}>Interests</FormLabel>
          <div className={classes.chipContainer}>
            <Grid item>
              {labels.map((item, index) => (
                <Chip
                  onClick={() => handleSelectedLabels(item)}
                  className={`${classes.chip} ${
                    values.interests.includes(item) && classes.chipActive
                  }`}
                  label={item}
                  variant="outlined"
                  key={index}
                />
              ))}
              <Chip
                onClick={() => {
                  if (otherInterests.length !== 0) {
                    const deleteInterests = otherInterests
                      .split(',')
                      .map((e) => e && e.trim());
                    setFieldValue(
                      'interests',
                      _without(values.interests, ...deleteInterests)
                    );
                    setOtherInterests('');
                  }
                  setIsOther(!isOther);
                }}
                className={`${classes.chip} ${
                  (otherInterests.length || isOther) && classes.chipActive
                }`}
                label="Other"
                variant="outlined"
              />
            </Grid>

            {(otherInterests.length || isOther) && (
              <TextField
                fullWidth
                placeholder="e.g. Anime, NFTs, Cars"
                variant="outlined"
                className={classes.input}
                value={otherInterests}
                style={{ marginBottom: 16 }}
                onChange={(e) => {
                  setOtherInterests(e.target.value);
                }}
                onBlur={(e) => handleSelectedLabels(e.target.value.split(','))}
              />
            )}
          </div>

          <FormLabel className={classes.formLabelStyle}>Gender</FormLabel>
          <Select
            IconComponent={KeyboardArrowDownIcon}
            className={classes.select}
            variant="outlined"
            input={<OutlinedInput id="sentiment" />}
            value={values.gender.maleOrFemale}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span style={{ color: '#A3A3A3' }}>Select</span>;
              }
              return selected.slice(0, 1).toUpperCase() + selected.slice(1);
            }}
            onChange={handleChange}
          >
            {gender.map((gen, index) => (
              <MenuItem key={index} value={gen.toLowerCase()}>
                {gen}
              </MenuItem>
            ))}
          </Select>
          {
            // disable custom gender for now
            false && values.gender.maleOrFemale.toLowerCase() === 'custom' && (
              <TextField
                fullWidth
                placeholder="Your gender"
                variant="outlined"
                className={classes.input}
                style={{ marginTop: 16 }}
                value={values.gender.customGender}
                onChange={(e) =>
                  setFieldValue('gender.customGender', e.target.value)
                }
              />
            )
          }
        </div>
      </Grid>
    </>
  );
};

export default InfoForm;
