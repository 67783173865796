import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import behaviourTypeByBehaviour from '../helpers/behaviour-type-by-behaviour';
import through from '../utils/through';
import CardTitle from './CardTitle';
import Label from './Label';
import MissingData from './MissingData';
import OutlinedInput from './OutlinedInput';

function Behaviours(props) {
  const { behaviours = [], label, category } = props;

  const [pagination, setPagination] = useState(0);

  const [likeliness, setLikeliness] = useState('Most likely to');

  function handleLikelinessSelected({ target: { value } }) {
    setLikeliness(value);
  }

  useEffect(() => {
    setPagination(0);
  }, [likeliness]);

  const filteredBehaviours = behaviours
    .filter(({ name }) => behaviourTypeByBehaviour[name] === category)
    .filter(({ likeliness: l }) => l === likeliness);

  const sortedBehaviours = filteredBehaviours.sort(
    (behaviourA, behaviourB) => behaviourB.score - behaviourA.score
  );

  const visibleBehaviours = sortedBehaviours.slice(
    pagination * 4,
    (pagination + 1) * 4
  );

  const buttons = through(0, filteredBehaviours.length / 4).map((i) => {
    const onClick = () => {
      setPagination(i);
    };

    const className = clsx('btn', 'btn-light', {
      active: pagination === i,
    });

    return (
      <ToggleButton
        style={{ zIndex: 0 }}
        key={behaviours[i * 4].name}
        className={className}
        onClick={onClick}
        value={i + 1}
      >
        {i + 1}
      </ToggleButton>
    );
  });

  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <Grid container spacing={0} alignItems="center">
          <Grid item style={{ flex: 1 }}>
            <CardTitle>{label}</CardTitle>
          </Grid>
          <Grid item>
            <Grid container spacing={4}>
              <Grid item>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Label htmlFor="sentiment">Attitude</Label>
                  </Grid>
                  <Grid item>
                    <Select
                      variant="outlined"
                      value={likeliness}
                      onChange={handleLikelinessSelected}
                      input={<OutlinedInput id="sentiment" />}
                      style={{ minWidth: 120 }}
                    >
                      <MenuItem value="Most likely to">More Likely</MenuItem>
                      <MenuItem value="Less likely to">Less likely</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {behaviours && behaviours.length ? (
        <>
          <CardContent>
            <List disablePadding style={{ minHeight: 211 }}>
              {visibleBehaviours.map((behaviour, i, { length }) => {
                const { name } = behaviour;

                const rank = sortedBehaviours.findIndex((b) => b === behaviour);

                return (
                  <ListItem key={i} divider={i + 1 < length}>
                    <ListItemText
                      primary={`${rank + 1}. ${likeliness} ${name}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
          <CardContent>
            <Box textAlign="center">
              <ToggleButtonGroup size="small" exclusive value={pagination}>
                {buttons}
              </ToggleButtonGroup>
            </Box>
          </CardContent>
        </>
      ) : (
        <CardContent>
          <MissingData name={label} communication="influencer" />
        </CardContent>
      )}
    </Card>
  );
}

export default Behaviours;
