import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CampaignShoutoutDeleteConfirmation, {
  useCampaignShoutoutDeleteConfirmation,
} from '../../CampaignShoutoutDeleteConfirmation';
import { DeliverablePaginator } from '../../components/DeliverablePaginator';
import {
  selectShoutoutsFilterMediaTracking,
  selectShoutoutsFilterSponsorship,
} from '../../utils/CampaignFiltersStore';
import ShoutoutDeliverable from './ShoutoutDeliverable';
import {
  selectFilteredShoutoutDeliverableStructures,
  selectShoutoutDeliverablesError,
} from './store/ShoutoutDeliverables.selectors';
import { deleteShoutout } from './store/ShoutoutDeliverables.thunks';
import { ShoutoutDeliverableStructure } from './store/types';

export const Shoutouts = ({
  campaign,
  usedVideos,
  usedVideosIds,
  usedInfluencers,
  setSelectedCampaignInfluencer,
  isSponsorship,
  brandFilter,
  isFilteredByCreator,
  setByCreator,
  numberPage,
  setNumberPage,
}) => {
  const dispatch = useDispatch();

  const campaignId = campaign.id;

  const { setShoutoutToDelete, shoutoutToDelete } =
    useCampaignShoutoutDeleteConfirmation();

  const shoutoutsFilterMediaTracking = useSelector(
    selectShoutoutsFilterMediaTracking
  );
  const shoutoutsFilterSponsorship = useSelector(
    selectShoutoutsFilterSponsorship
  );

  useEffect(() => {
    if (!!numberPage) {
      return () => {
        setNumberPage(0);
      };
    }
  }, [numberPage, setNumberPage]);

  const handleConfirmDeleteShoutout = useCallback(() => {
    if (!shoutoutToDelete) {
      return;
    }
    const {
      shoutout_deliverable_id = undefined,
      shoutout_deliverables_v2_id = undefined,
      shoutoutId,
    } = shoutoutToDelete;

    dispatch(
      deleteShoutout({
        campaignId,
        shoutoutDeliverableId:
          shoutout_deliverable_id || shoutout_deliverables_v2_id,
        shoutoutId,
      })
    );
  }, [dispatch, campaignId, shoutoutToDelete]);

  const shoutoutDeliverableStructures = useSelector(
    selectFilteredShoutoutDeliverableStructures({
      campaignId,
      brandIdFilter: brandFilter,
      onlyBrandDeliverables: !!isSponsorship,
      sortBy: { key: 'results_count', order: 'desc' },
      shoutoutFilter: isSponsorship
        ? shoutoutsFilterSponsorship
        : shoutoutsFilterMediaTracking,
    })
  );

  const error = useSelector(selectShoutoutDeliverablesError());

  const renderDeliverable = useCallback(
    (shoutoutDeliverableStructure: ShoutoutDeliverableStructure) => (
      <ShoutoutDeliverable
        key={shoutoutDeliverableStructure.id}
        deleteShoutout={setShoutoutToDelete}
        shoutoutDeliverableStructure={shoutoutDeliverableStructure}
        usedVideos={usedVideos}
        usedVideosIds={usedVideosIds}
        usedInfluencers={usedInfluencers}
        timeZone={campaign.time_zone}
        setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
        isSponsorship={isSponsorship}
        isFilteredByCreator={isFilteredByCreator}
        setByCreator={setByCreator}
      />
    ),
    [
      setShoutoutToDelete,
      usedVideos,
      usedVideosIds,
      usedInfluencers,
      campaign,
      setSelectedCampaignInfluencer,
      isSponsorship,
      isFilteredByCreator,
      setByCreator,
    ]
  );

  if (error) return <p>error</p>;

  return (
    <>
      <CampaignShoutoutDeleteConfirmation
        shoutoutToDelete={shoutoutToDelete}
        setShoutoutToDelete={setShoutoutToDelete}
        handleConfirmDeleteShoutout={handleConfirmDeleteShoutout}
      />
      <div style={{ paddingTop: 24 }}>
        <DeliverablePaginator
          deliverables={shoutoutDeliverableStructures}
          renderDeliverable={renderDeliverable}
          forcePageIndex={numberPage}
        />
      </div>
    </>
  );
};

export default Shoutouts;
