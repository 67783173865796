import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

import ShoutoutsV2 from '../../CampaignWizard/ShoutoutsV2/ShoutoutsV2';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: 340,
  },
}));

export const Shoutouts = ({ disabled, trialClassName, index, brandId }) => {
  const classes = useStyles();

  return (
    <Card
      className={cx(classes.container, {
        [trialClassName]: disabled,
      })}
    >
      <CardContent>
        <Typography variant="h2">Voice mentions</Typography>
      </CardContent>
      <CardContent>
        <ShoutoutsV2 disabled={disabled} index={index} brandId={brandId} />
      </CardContent>
    </Card>
  );
};

export default Shoutouts;
