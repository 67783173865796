import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';

import {
  getCampaignOnboardingForPageName,
  postFacebookCampaignOnboarding,
} from '../../../../ajax';
import * as paths from '../../../../common/constants/paths';
import {
  FACEBOOK_SCOPE,
  redirectTwitch,
  redirectYoutube,
} from '../../../../helpers/influencer-login';

const mapStateToProps = (state) => {
  const {
    router: {
      location: { pathname },
    },
  } = state;

  return {
    onboardingNetwork:
      pathname.indexOf(paths.ONBOARDING_INSTAGRAM) === 0
        ? 'instagram'
        : 'facebook',
  };
};

export default compose(
  connect(mapStateToProps),
  withState('errors', 'setErrors', []),
  withState('onboardingCompleted', 'setOnboardingCompleted', false),
  withState('onboardingCompanies', 'setOnboardingCompanies', []),
  withHandlers({
    redirectTwitch,
    redirectYoutube,
    getOnboardingCampaignData:
      ({ match, onboardingNetwork, setOnboardingCompanies }) =>
      async () => {
        const {
          params: { pageName },
        } = match;

        getCampaignOnboardingForPageName(pageName, onboardingNetwork).then(
          (response) => {
            setOnboardingCompanies(response);
          }
        );
      },
    loginFacebook:
      ({ setErrors, setOnboardingCompleted, match, onboardingNetwork }) =>
      async () => {
        window.FB.login(
          (response) => {
            if (response.status !== 'connected') return;

            const {
              params: { pageName },
            } = match;

            postFacebookCampaignOnboarding({
              ...response.authResponse,
              pageName,
              onboardingNetwork,
            })
              .then((response) => {
                setErrors([]);
                setOnboardingCompleted(true);
              })
              .catch((response) => {
                if (response.bodyJson && response.bodyJson.errors) {
                  setErrors(response.bodyJson.errors);
                } else {
                  setErrors(['Unexpected Error. Please try again later!']);
                }
              });
          },
          {
            scope: FACEBOOK_SCOPE,
          }
        );
      },
  })
);
