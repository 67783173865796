import { call, put, takeLatest } from 'redux-saga/effects';

import { getMyBrands, recordUserSession } from '../../ajax';
import { BRANDS_MOUNTED } from '../action-types';
import {
  myBrandsRequested,
  myBrandsRequestFailed,
  myBrandsRequestSucceeded,
} from '../models/my-brands/my-brands.actions';

function* requestMyBrands() {
  let myBrands;
  try {
    yield put(myBrandsRequested());

    myBrands = yield call(getMyBrands);

    yield call(recordUserSession, { action: 'get-my-brands' });

    yield put(myBrandsRequestSucceeded(myBrands));
  } catch (e) {
    console.error('My Brands Request Error - ', e);
    yield put(
      myBrandsRequestFailed({
        error: (e.bodyJson && e.bodyJson.errors) || 'My brands Request Error',
      })
    );
  }
}
export default function* () {
  yield takeLatest(BRANDS_MOUNTED, requestMyBrands);
}
