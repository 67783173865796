import { FormLabel } from '@material-ui/core';
import { Formik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import FormikMuiTextField from '../../../components/FormikMuiTextField';
import { showToast } from '../../../store/commonActions';
import { LoadingState } from '../../Campaign/types';
import { ModalShell } from '../../influencerViews/Profile/components/modals/ModalShell';

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Enter current password'),
  newPassword: yup.string().required('Enter new password'),
  newPasswordConfirm: yup.string().required('Enter password confirmation'),
});

const validatePasswords = async (values, props) => {
  const { newPassword, newPasswordConfirm } = values;

  let error = {};

  if (newPassword && newPasswordConfirm && newPassword !== newPasswordConfirm) {
    error.newPasswordConfirm =
      'New password should match password confirmation!';
  }

  return error;
};

export const ChangePasswordUserModal = ({ onClose, onSubmit }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [currentPasswordError, setCurrentPasswordError] = useState(null);
  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);

  const onSubmitLocal = async (values) => {
    setLoadingState(LoadingState.PENDING);

    await onSubmit(values).then(({ success, errorKey }) => {
      if (success) {
        onClose();
        dispatch(
          showToast({
            message: 'Password changed successfully',
            type: 'success',
          })
        );
      } else {
        if (errorKey.indexOf('current_password') !== -1) {
          setCurrentPasswordError({ key: errorKey });
        } else {
          setError({ key: errorKey });
        }

        setLoadingState(LoadingState.IDLE);
      }
    });
  };

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      }}
      onSubmit={onSubmitLocal}
      validationSchema={validationSchema}
      validate={validatePasswords}
    >
      {(formik) => {
        const { submitForm, isValid } = formik;

        let content = (
          <>
            <FormLabel style={{ marginBottom: 15 }}>Current Password</FormLabel>
            <FormikMuiTextField
              name="currentPassword"
              type="password"
              fullWidth
              placeholder="Current Password"
              variant="outlined"
              style={{ marginBottom: 16 }}
              errorMessage={currentPasswordError}
              disabled={loadingState === LoadingState.PENDING}
            />
            <FormLabel style={{ marginBottom: 15 }}>New Password</FormLabel>
            <FormikMuiTextField
              name="newPassword"
              type="password"
              fullWidth
              placeholder="New Password"
              variant="outlined"
              style={{ marginBottom: 16 }}
              errorMessage={error}
              disabled={loadingState === LoadingState.PENDING}
            />
            <FormLabel style={{ marginBottom: 15 }}>
              Repeat New Password
            </FormLabel>
            <FormikMuiTextField
              name="newPasswordConfirm"
              type="password"
              fullWidth
              placeholder="Confirm Password"
              variant="outlined"
              style={{ marginBottom: 16 }}
              errorMessage={error}
              disabled={loadingState === LoadingState.PENDING}
            />
          </>
        );

        return (
          <ModalShell
            title="CHANGE PASSWORD"
            open={true}
            onClose={() => {
              onClose();
              setError(null);
            }}
            onConfirm={submitForm}
            disableConfirm={!isValid}
            loading={loadingState === LoadingState.PENDING}
            noActionButtons={loadingState === LoadingState.FULFILLED}
          >
            {content}
          </ModalShell>
        );
      }}
    </Formik>
  );
};
