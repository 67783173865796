import {
  Box,
  Card,
  CardContent,
  makeStyles,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';
import cx from 'classnames';
import moment from 'moment';
import React, { forwardRef, useMemo, useState } from 'react';
import { FixedSizeList as List } from 'react-window';

import StandardStatBox from '../../components/StatBox';

export const StatBox = (props) => <StandardStatBox size="table" {...props} />;

const compare = (a, b) => {
  if (a > b) return 1;
  if (a < b) return -1;

  return 0;
};

const DEBUG = false;

export const makeSortMap = (externalMap) => (sortState) => (a, b) => {
  const map = {
    video: (video) => moment(video.published_at).unix(),
    ...externalMap,
  };

  if (!(sortState.key in map)) return 0;

  return (
    sortState.multiplier * compare(map[sortState.key](a), map[sortState.key](b))
  );
};

export const useSorting = (defaultKey, defaultDecreasing) => {
  const [key, setKey] = useState(defaultKey);

  const [increasing, setIncreasing] = useState(!defaultDecreasing);

  const onHeadCellClick = (clickedKey) => () => {
    if (clickedKey === key) setIncreasing(!increasing);
    else setKey(clickedKey);
  };

  const getSortBy = (currentKey) => currentKey === key;

  const getHeadCellProps = (currentKey) => ({
    onClick: onHeadCellClick(currentKey),
    sortBy: getSortBy(currentKey),
    increasing,
  });

  return {
    increasing,
    setIncreasing,
    key,
    setKey,
    getHeadCellProps,
    sortState: {
      key,
      multiplier: increasing ? 1 : -1,
    },
  };
};

const HEAD_HEIGHT = 40.7167;

const HeadContent = styled(CardContent)({
  background: '#F4F4F4',
  border: 'rgb(221, 221, 221, .33) 0px solid',
  borderTopWidth: 1,
  borderBottomWidth: 1,
  paddingTop: 10,
  paddingBottom: 10,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 24,
  paddingRight: 24,
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  height: HEAD_HEIGHT,
});

export const TableText = (props) => (
  <Typography
    {...props}
    className={/\s/g.test(props.children) ? 'text-ellipsis--2' : ''}
    style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      ...props.style,
    }}
  />
);

export const HeadCellText = styled(Typography)({
  textTransform: 'uppercase',
  color: '#111',
  fontSize: '.78rem',
});

const HeadCellContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '--icon-opacity': 0,
  '&:hover': {
    '--icon-opacity': 1,
  },
  cursor: 'pointer',
  userSelect: 'none',
});

export const HeadCell = ({
  children,
  flex,
  stretch,
  onClick,
  increasing,
  sortBy,
}) => (
  <Box
    flex={flex}
    display="flex"
    alignItems="center"
    justifyContent={stretch ? 'flex-start' : 'flex-end'}
    borderLeft={DEBUG ? '1px solid red' : 'none'}
    pl={1}
    pr={1}
  >
    <HeadCellContainer
      onClick={onClick}
      display="flex"
      alignItems="center"
      position="relative"
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      {onClick && (
        <ArrowDownwardIcon
          style={{
            color: '#565656',
            opacity: sortBy ? 1 : 'var(--icon-opacity)',
            transition: 'all .2s',
            transform: `translateY(-50%) rotate(${increasing ? 180 : 0}deg)`,
            fontSize: '1rem',
            position: 'absolute',
            top: '50%',
            left: '-22px',
          }}
        />
      )}
      <HeadCellText style={{ textAlign: stretch ? 'left' : 'right' }}>
        {children}
      </HeadCellText>
    </HeadCellContainer>
  </Box>
);

HeadCell.defaultProps = {
  flex: 1,
};

export const Row = ({
  children,
  height,
  style,
  noStroke,
  colorOnHover,
  ...rest
}) => (
  <div
    style={{
      height,
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderTop: noStroke ? 'none' : 'rgb(221, 221, 221, 1) 1px solid',
      boxSizing: 'border-box',
      padding: '0 24px',
      ...style,
    }}
    className={cx('show-on-hover-root', { 'row-hover-color': colorOnHover })}
    {...rest}
  >
    {children}
  </div>
);

export const Cell = ({ children, flex, stretch, style, ...rest }) => (
  <div
    style={{
      flex: flex || 1,
      display: 'flex',
      alignItems: stretch ? 'flex-start' : 'flex-end',
      flexDirection: 'column',
      justifyContent: 'center',
      borderLeft: DEBUG ? '1px solid red' : 'none',
      minWidth: 0,
      padding: '0 8px',
      height: '100%',
      ...style,
    }}
    {...rest}
  >
    {children}
  </div>
);

const renderHeadCol = (getHeadCellProps) => (options, index) => {
  return (
    <HeadCell
      {...options}
      key={options.key}
      {...getHeadCellProps(options.key)}
      index={index}
    >
      {options.label}
    </HeadCell>
  );
};

const LockedRowOverlay = ({ style, content }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        ...style,
        top: 0,
        left: 0,
        display: 'flex',
        flex: 1,
        height: '100%',
        paddingLeft: isSmallScreen ? 100 : 200,
        alignItems: 'center',
      }}
    >
      {!!content && content}
    </div>
  );
};

const RenderRow = React.memo(
  ({
    style,
    index,
    data: { rows, rowHeight, cols, onRowClick, idTable, onCreatorColClick },
  }) => (
    <Row
      style={{
        ...style,
        top: style.top + HEAD_HEIGHT,
        cursor: onRowClick ? 'pointer' : 'unset',
        backgroundColor: rows[index].isSelected ? '#DDE7FD' : 'inherit',
      }}
      key={rows[index].key}
      height={rowHeight}
      noStroke={index === 0}
      onClick={() => onRowClick && onRowClick(rows[index], index)}
      colorOnHover={!!onRowClick}
    >
      {cols.map(({ render, flex, stretch, key }, i) => (
        <Cell
          data-id={idTable && `${idTable}-${key}-${i}`}
          flex={flex}
          stretch={stretch}
          key={key}
          index={i}
          style={{
            filter: rows[index].isLocked ? 'blur(4px)' : null,
            cursor: key === 'creator' && onCreatorColClick && 'pointer',
          }}
          onClick={() =>
            key === 'creator' &&
            onCreatorColClick &&
            onCreatorColClick(rows[index], index)
          }
        >
          {render(rows[index])}
        </Cell>
      ))}
      {rows[index].isLocked && (
        <LockedRowOverlay style={style} content={rows[index].lockedContent} />
      )}
    </Row>
  )
);

const innerElementType = ({ cols, getHeadCellProps, rows }) =>
  forwardRef(({ children, ...rest }, ref) => (
    <div ref={ref} {...rest}>
      <HeadContent>
        {cols.map(
          // no sort buttons when there is a single row
          renderHeadCol(rows.length > 1 ? getHeadCellProps : () => ({}))
        )}
      </HeadContent>
      {children}
    </div>
  ));

const useStyles = makeStyles(() => ({
  innerTable: {
    minWidth: 'var(--min-table-width)',
  },
  noBorders: {
    border: 'none',
    borderRadius: 0,
  },
}));

export const Table = ({
  rows,
  cols,
  sortKey,
  rowHeight,
  defaultSortCol,
  minWidth,
  noBorders,
  defaultIncreasing,
  onRowClick,
  onCreatorColClick,
  idTable,
}) => {
  const classes = useStyles();

  const { getHeadCellProps, sortState } = useSorting(
    defaultSortCol,
    !defaultIncreasing
  );

  const sortMap = useMemo(
    () =>
      makeSortMap(
        cols.reduce(
          (map, col) => ({
            ...map,
            [col.key]: col.getter
              ? col.getter
              : (d) => d[col.sortKey || col.key],
          }),
          {}
        )
      ),
    [cols]
  );

  const sortedRows = useMemo(
    () => rows.sort(sortMap(sortState)),
    [sortState, rows]
  );

  return (
    <Card
      style={{
        overflowX: 'auto',
        '--min-table-width': `${minWidth}px`,
      }}
      className={noBorders ? classes.noBorders : undefined}
    >
      <List
        overscanCount={4}
        itemCount={sortedRows.length}
        height={Math.min(sortedRows.length, 6.5) * rowHeight + HEAD_HEIGHT}
        itemSize={rowHeight}
        itemData={{
          rows,
          rowHeight,
          cols,
          onRowClick,
          idTable,
          onCreatorColClick,
        }}
        innerElementType={innerElementType({ cols, rows, getHeadCellProps })}
        itemKey={(idx, data) => data.rows[idx].key}
        className={classes.innerTable}
      >
        {RenderRow}
      </List>
    </Card>
  );
};

Table.defaultProps = {
  rowHeight: 72,
  minWidth: 800,
};

export default Table;
