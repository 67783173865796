import { combineReducers } from 'redux';

import { INFLUENCER_REQUEST_SUCCEEDED } from '../../action-types';

const byId = (state = {}, action) => {
  const { type } = action;

  switch (type) {
    case INFLUENCER_REQUEST_SUCCEEDED: {
      const { payload } = action;

      return {
        ...state,
        [payload.influencer.id]: payload.influencer,
      };
    }

    default:
      return state;
  }
};

export default combineReducers({ byId });
