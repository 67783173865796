const youtubeRegex =
  /(?:https?:\/\/)?(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/|be\.com\/shorts\/)([\w\W]+?)(?=[?&]|\/|$)/;

const tiktokRegex =
  /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@[\w\W]+\/video\/([\w\W]+?)(?=[?&]|\/|$)/;

const twitchRegex =
  /(?:https?:\/\/)?(?:www\.)?twitch\.tv\/videos\/([\w\W]+?)(?=[?&]|\/|$)/;

const facebookRegex =
  /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:[\w\W]+\/videos|reel)\/([\w\W]+?)(?=[?&]|\/|$)/;

// Matches Facebook watch links like https://www.facebook.com/watch/?v=123456789
const facebookRegex2 =
  /(?:https?:\/\/)?(?:www\.)?facebook\.com\/watch\/?\?v=([\w\W]+?)(?=[?&]|\/|$)/;

const instagramRegex =
  /(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p|reel|reels)\/([\w\W]+?)(?=[?&]|\/|$)/;

export const getVideoIdAndPlatformFromUrl = (url: string) => {
  const youtubeMatch = url.match(youtubeRegex);
  const tiktokMatch = url.match(tiktokRegex);
  const twitchMatch = url.match(twitchRegex);
  const facebookMatch = url.match(facebookRegex);
  const facebookMatch2 = url.match(facebookRegex2);
  const instagramMatch = url.match(instagramRegex);

  if (youtubeMatch) return { id: youtubeMatch[1], platform: 'youtube' };
  else if (tiktokMatch) return { id: tiktokMatch[1], platform: 'tiktok' };
  else if (twitchMatch) return { id: twitchMatch[1], platform: 'twitch' };
  else if (facebookMatch) return { id: facebookMatch[1], platform: 'facebook' };
  else if (facebookMatch2)
    return { id: facebookMatch2[1], platform: 'facebook' };
  else if (instagramMatch)
    return { id: instagramMatch[1], platform: 'instagram' };

  return null;
};
