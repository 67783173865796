const countries = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Brazil', code: 'BR' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Canada', code: 'CA' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'Germany', code: 'DE' },
  { name: 'Greece', code: 'GR' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Italy', code: 'IT' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Malta', code: 'MT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Norway', code: 'NO' },
  { name: 'Panama', code: 'PA' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'UK', code: 'GB' },
  { name: 'US', code: 'US' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Aland Islands', code: 'AX' },
  { name: 'Peru', code: 'PE' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Egypt', code: 'EG' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'Niger', code: 'NE' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Uganda', code: 'UG' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const TWO_LETTER_TO_ISO = {
  BD: 'BGD',
  BE: 'BEL',
  BF: 'BFA',
  BG: 'BGR',
  BA: 'BIH',
  BB: 'BRB',
  WF: 'WLF',
  BL: 'BLM',
  BM: 'BMU',
  BN: 'BRN',
  BO: 'BOL',
  BH: 'BHR',
  BI: 'BDI',
  BJ: 'BEN',
  BT: 'BTN',
  JM: 'JAM',
  BV: 'BVT',
  BW: 'BWA',
  WS: 'WSM',
  BQ: 'BES',
  BR: 'BRA',
  BS: 'BHS',
  JE: 'JEY',
  BY: 'BLR',
  BZ: 'BLZ',
  RU: 'RUS',
  RW: 'RWA',
  RS: 'SRB',
  TL: 'TLS',
  RE: 'REU',
  TM: 'TKM',
  TJ: 'TJK',
  RO: 'ROU',
  TK: 'TKL',
  GW: 'GNB',
  GU: 'GUM',
  GT: 'GTM',
  GS: 'SGS',
  GR: 'GRC',
  GQ: 'GNQ',
  GP: 'GLP',
  JP: 'JPN',
  GY: 'GUY',
  GG: 'GGY',
  GF: 'GUF',
  GE: 'GEO',
  GD: 'GRD',
  GB: 'GBR',
  GA: 'GAB',
  SV: 'SLV',
  GN: 'GIN',
  GM: 'GMB',
  GL: 'GRL',
  GI: 'GIB',
  GH: 'GHA',
  OM: 'OMN',
  TN: 'TUN',
  JO: 'JOR',
  HR: 'HRV',
  HT: 'HTI',
  HU: 'HUN',
  HK: 'HKG',
  HN: 'HND',
  HM: 'HMD',
  VE: 'VEN',
  PR: 'PRI',
  PS: 'PSE',
  PW: 'PLW',
  PT: 'PRT',
  SJ: 'SJM',
  PY: 'PRY',
  IQ: 'IRQ',
  PA: 'PAN',
  PF: 'PYF',
  PG: 'PNG',
  PE: 'PER',
  PK: 'PAK',
  PH: 'PHL',
  PN: 'PCN',
  PL: 'POL',
  PM: 'SPM',
  ZM: 'ZMB',
  EH: 'ESH',
  EE: 'EST',
  EG: 'EGY',
  ZA: 'ZAF',
  EC: 'ECU',
  IT: 'ITA',
  VN: 'VNM',
  SB: 'SLB',
  ET: 'ETH',
  SO: 'SOM',
  ZW: 'ZWE',
  SA: 'SAU',
  ES: 'ESP',
  ER: 'ERI',
  ME: 'MNE',
  MD: 'MDA',
  MG: 'MDG',
  MF: 'MAF',
  MA: 'MAR',
  MC: 'MCO',
  UZ: 'UZB',
  MM: 'MMR',
  ML: 'MLI',
  MO: 'MAC',
  MN: 'MNG',
  MH: 'MHL',
  MK: 'MKD',
  MU: 'MUS',
  MT: 'MLT',
  MW: 'MWI',
  MV: 'MDV',
  MQ: 'MTQ',
  MP: 'MNP',
  MS: 'MSR',
  MR: 'MRT',
  IM: 'IMN',
  UG: 'UGA',
  TZ: 'TZA',
  MY: 'MYS',
  MX: 'MEX',
  IL: 'ISR',
  FR: 'FRA',
  IO: 'IOT',
  SH: 'SHN',
  FI: 'FIN',
  FJ: 'FJI',
  FK: 'FLK',
  FM: 'FSM',
  FO: 'FRO',
  NI: 'NIC',
  NL: 'NLD',
  NO: 'NOR',
  NA: 'NAM',
  VU: 'VUT',
  NC: 'NCL',
  NE: 'NER',
  NF: 'NFK',
  NG: 'NGA',
  NZ: 'NZL',
  NP: 'NPL',
  NR: 'NRU',
  NU: 'NIU',
  CK: 'COK',
  XK: 'XKX',
  CI: 'CIV',
  CH: 'CHE',
  CO: 'COL',
  CN: 'CHN',
  CM: 'CMR',
  CL: 'CHL',
  CC: 'CCK',
  CA: 'CAN',
  CG: 'COG',
  CF: 'CAF',
  CD: 'COD',
  CZ: 'CZE',
  CY: 'CYP',
  CX: 'CXR',
  CR: 'CRI',
  CW: 'CUW',
  CV: 'CPV',
  CU: 'CUB',
  SZ: 'SWZ',
  SY: 'SYR',
  SX: 'SXM',
  KG: 'KGZ',
  KE: 'KEN',
  SS: 'SSD',
  SR: 'SUR',
  KI: 'KIR',
  KH: 'KHM',
  KN: 'KNA',
  KM: 'COM',
  ST: 'STP',
  SK: 'SVK',
  KR: 'KOR',
  SI: 'SVN',
  KP: 'PRK',
  KW: 'KWT',
  SN: 'SEN',
  SM: 'SMR',
  SL: 'SLE',
  SC: 'SYC',
  KZ: 'KAZ',
  KY: 'CYM',
  SG: 'SGP',
  SE: 'SWE',
  SD: 'SDN',
  DO: 'DOM',
  DM: 'DMA',
  DJ: 'DJI',
  DK: 'DNK',
  VG: 'VGB',
  DE: 'DEU',
  YE: 'YEM',
  DZ: 'DZA',
  US: 'USA',
  UY: 'URY',
  YT: 'MYT',
  UM: 'UMI',
  LB: 'LBN',
  LC: 'LCA',
  LA: 'LAO',
  TV: 'TUV',
  TW: 'TWN',
  TT: 'TTO',
  TR: 'TUR',
  LK: 'LKA',
  LI: 'LIE',
  LV: 'LVA',
  TO: 'TON',
  LT: 'LTU',
  LU: 'LUX',
  LR: 'LBR',
  LS: 'LSO',
  TH: 'THA',
  TF: 'ATF',
  TG: 'TGO',
  TD: 'TCD',
  TC: 'TCA',
  LY: 'LBY',
  VA: 'VAT',
  VC: 'VCT',
  AE: 'ARE',
  AD: 'AND',
  AG: 'ATG',
  AF: 'AFG',
  AI: 'AIA',
  VI: 'VIR',
  IS: 'ISL',
  IR: 'IRN',
  AM: 'ARM',
  AL: 'ALB',
  AO: 'AGO',
  AQ: 'ATA',
  AS: 'ASM',
  AR: 'ARG',
  AU: 'AUS',
  AT: 'AUT',
  AW: 'ABW',
  IN: 'IND',
  AX: 'ALA',
  AZ: 'AZE',
  IE: 'IRL',
  ID: 'IDN',
  UA: 'UKR',
  QA: 'QAT',
  MZ: 'MOZ',
};

export const getCountryName = (code) => {
  if (!code) {
    return null;
  }

  const country = countries.find(
    (country) => country.code.toLowerCase() === code.toLowerCase()
  );
  return country ? country.name : code;
};

export default countries;
