import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#edf7ff',
    padding: '34px 34px',
    margin: '0px 100px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      padding: '16px 30px',
      margin: '0px 40px',
    },
  },

  typography: {
    color: 'black',
    opacity: '0.5',
  },
  icon: {
    width: 60,
    height: 60,
    marginBottom: 15,
  },
  title: {
    fontSize: '30px',
    fontWeight: 'bold',
    textTransform: 'capitalize',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      marginBottom: 10,
    },
  },

  body: {
    fontSize: '20px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },

  lds_ripple: {
    display: 'inline-block',
    position: 'relative',
    width: 80,
    height: 80,
    '& div': {
      position: 'absolute',
      border: `4px solid ${theme.palette.primary.main}`,
      opacity: 1,
      borderRadius: '50%',
      animation: `$lds_ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite`,
    },
    '& div:nth-child(2)': {
      animationDelay: '-0.5s',
    },
  },
  '@keyframes lds_ripple': {
    '0%': {
      top: '36px',
      left: '36px',
      width: 0,
      height: 0,
      opacity: 1,
    },
    '100%': {
      top: '0px',
      left: '0px',
      width: 72,
      height: 72,
      opacity: 0,
    },
  },
}));

interface UnlockBannerProps {
  loader?: boolean;
  icon?: string;
  title?: string;
  bodyText?: string;
}

export const WaitingBanner = ({
  loader,
  icon,
  title,
  bodyText,
}: UnlockBannerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {loader && (
        <div className={classes.lds_ripple}>
          <div></div>
          <div></div>
        </div>
      )}
      {icon && (
        <div className={classes.icon}>
          <img alt="icon" src={icon} />
        </div>
      )}
      {title && (
        <Typography className={clsx(classes.typography, classes.title)}>
          {title}
        </Typography>
      )}
      {bodyText && (
        <Typography className={clsx(classes.typography, classes.body)}>
          {bodyText}
        </Typography>
      )}
    </div>
  );
};
