import { call, put, takeLatest } from 'redux-saga/effects';

import { postBrand } from '../../ajax';
import { BRAND_CREATION_SUBMITTED } from '../action-types';
import { brandsMounted } from '../events';
import {
  brandCreationFailed,
  brandCreationRequested,
  brandCreationSucceeded,
} from '../models/my-brands/my-brands.actions';

function* createBrand(action) {
  const {
    payload: { values },
  } = action;

  yield put(brandCreationRequested());

  const {
    brandName,
    bannerImages,
    shoutouts,
    links,
    socialMediaMentionContent,
    kpiKeywordMentionList,
  } = values;

  const deliverables = {};
  if (brandName) {
    deliverables.name = brandName;
  }
  if (socialMediaMentionContent) {
    deliverables.organization_brand_social_media_mentions_attributes = [];

    socialMediaMentionContent.forEach((kw) => {
      if (kw && kw.trim().length) {
        deliverables.organization_brand_social_media_mentions_attributes.push({
          keyword: kw.trim(),
        });
      }
    });
  }

  if (shoutouts.length > 0) {
    deliverables.organization_brand_shoutouts_attributes = shoutouts.map(
      (s) => ({
        name: s.name,
        inclusion_terms: s.inclusionTerms,
        exclusion_terms: s.exclusionTerms,
        structured: s.structured,
      })
    );
  }
  if (links) {
    deliverables.organization_brand_link_trackings_attributes = [];

    links.forEach((kw) => {
      if (kw && kw.trim().length) {
        deliverables.organization_brand_link_trackings_attributes.push({
          link: kw.trim(),
        });
      }
    });
  }
  if (kpiKeywordMentionList) {
    deliverables.organization_brand_keyword_mentions_attributes = [];

    kpiKeywordMentionList.forEach((kw) => {
      if (kw && kw.trim().length) {
        deliverables.organization_brand_keyword_mentions_attributes.push({
          keyword: kw.trim(),
        });
      }
    });
  }

  if (bannerImages) {
    deliverables.organization_brand_banners_attributes = [];

    let bannerDeliverable = null;

    bannerImages.forEach(({ bannerUrl, bannerFile }) => {
      bannerDeliverable = {};

      if (bannerUrl) {
        bannerDeliverable.image = bannerUrl;
      } else if (bannerFile) {
        bannerDeliverable.image = bannerFile;
      }

      deliverables.organization_brand_banners_attributes.push(
        bannerDeliverable
      );
    });
  }

  const data = {
    ...deliverables,
  };

  try {
    const brand = yield call(postBrand, data);

    yield put(brandCreationSucceeded({ brand }));
    yield put(brandsMounted());
  } catch (e) {
    console.error('Brand Create Error - ', e);

    let message = null;

    if (e.status === 422 || e.status === 408) {
      message = e.bodyJson && e.bodyJson.errors;
    } else if (e.status === 500) {
      message = e.bodyJson && `${e.bodyJson.error} - ${e.bodyJson.exception}`;
    }

    yield put(brandCreationFailed({ error: message || 'Brand Create Error' }));
  }
}

export default function* root() {
  yield takeLatest(BRAND_CREATION_SUBMITTED, createBrand);
}
