import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';
import clsx from 'clsx';
import numeral from 'numeral';
import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  getRecommendedInfluencers,
  postRecommendedStreamerIrrelevant,
} from '../ajax';
import * as paths from '../common/constants/paths';
import TwitchFlag from '../components/TwitchFlag';
import YoutubeFlag from '../components/YoutubeFlag';
import influencerTypeBySubscriberCount from '../helpers/influencer-type-by-subscriber-count';
import clamp from '../utils/clamp';
import isNumber from '../utils/is-number';
import InfluencerSize from './InfluencerSize';

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
    position: 'relative',
  },
  fullWidth: {
    width: '100%',
  },
  bigAvatar: {
    width: 100,
    height: 100,
  },
  value: {
    fontSize: '2.125rem',
    fontWeight: 'bold',
  },
  irrelevant: {
    background: 'lightgray',
    pointerEvents: 'none',
  },
  blockButton: {
    float: 'right',
    right: '8px',
    top: '8px',
    zIndex: '100',
    fontSize: '12px',
    padding: '3px',
    position: 'absolute',
  },
  profileCard: {
    marginTop: '20px',
  },
  networkFlag: {
    float: 'left',
    padding: 8,
  },
});

const enhance = memo;

const InfluencerProfileOverviewCard = (props) => {
  const [irrelevant, setIrrelevent] = useState(false);

  const {
    profile_image_url,
    display_name,
    login,
    network,
    subscriber_count,
    size,
    follower_virality,
    total_followers,
    avg_view_count_per_video,
    id,
    influencers,
    setInfluencers,
    organization_id,
    setIsRequestRunning,
    isRequestRunning,
  } = props;

  const type = size || influencerTypeBySubscriberCount(subscriber_count);

  // const matchingScore = clamp(0, 100)(Math.round(match_score))

  const classes = useStyles();

  const markAsIrrelevant = async (e) => {
    e.preventDefault();
    setIrrelevent(true);
    const prevInfluencers = influencers.filter(
      (influencer) => influencer?.id !== id
    );
    prevInfluencers.push(null);
    setInfluencers(prevInfluencers);
    setIsRequestRunning(true);
    await postRecommendedStreamerIrrelevant({ influencerName: login });
    await getRecommendedInfluencers(organization_id, {
      limit: 6,
      organization_id,
    }).then((res) => {
      setInfluencers(res.results.results);
      setIsRequestRunning(false);
    });
  };
  const fallbackImage = '../../fallback/img.png';
  return (
    <Card
      className={clsx('influencer-profile-card', classes.fullHeight, {
        [classes.irrelevant]: irrelevant,
      })}
    >
      <Link
        to={`${paths.INFLUENCER}/${encodeURI(login)}/${network}`}
        style={{ color: 'black' }}
      >
        <Tooltip
          placement="top"
          title="If this content creator is not relevant for you, mark him as 'Not interested' and we will not recommend similar channels in the future."
        >
          <Button
            disabled={isRequestRunning}
            type="button"
            size="small"
            variant="outlined"
            color="primary"
            className={classes.blockButton}
            onClick={(e) => markAsIrrelevant(e)}
          >
            <BlockIcon
              style={{ width: '14px', height: '14px', marginRight: '2px' }}
            />
            Not interested
          </Button>
        </Tooltip>
        <CardActionArea>
          <div className={classes.networkFlag}>
            {network === 'twitch' && <TwitchFlag />}
            {network === 'youtube' && <YoutubeFlag />}
          </div>
          <CardContent>
            <Grid
              container
              direction="column"
              alignItems="center"
              className={clsx(classes.fullHeight, classes.profileCard)}
              spacing={1}
            >
              <Grid item>
                <Avatar
                  src={profile_image_url ? profile_image_url : fallbackImage}
                  className={classes.bigAvatar}
                  alt={display_name?.toUpperCase()}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  <Box fontWeight="bold">{display_name}</Box>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  <InfluencerSize>{type}</InfluencerSize>
                </Typography>
              </Grid>
              <Grid item className={classes.fullWidth}>
                <Box my={2}>
                  <Grid container justify="space-evenly" spacing={1}>
                    {isNumber(total_followers) && (
                      <Grid item xs={6}>
                        <Typography className={classes.value} align="center">
                          {numeral(total_followers).format('0.[0]a')}
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          color="textSecondary"
                        >
                          <Box fontWeight="bold">FOLLOWERS</Box>
                        </Typography>
                      </Grid>
                    )}

                    {isNumber(subscriber_count) && (
                      <Grid item xs={6}>
                        <Typography className={classes.value} align="center">
                          {numeral(subscriber_count).format('0.[0]a')}
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          color="textSecondary"
                        >
                          <Box fontWeight="bold">SUBSCRIBERS</Box>
                        </Typography>
                      </Grid>
                    )}

                    {isNumber(follower_virality) && (
                      <Grid item xs={6}>
                        <Typography className={classes.value} align="center">
                          {clamp(0, 100)(Math.round(follower_virality))}%
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          color="textSecondary"
                        >
                          <Box fontWeight="bold">GROWTH SCORE</Box>
                        </Typography>
                      </Grid>
                    )}

                    {isNumber(avg_view_count_per_video) && (
                      <Grid item xs={6}>
                        <Typography className={classes.value} align="center">
                          {numeral(avg_view_count_per_video).format('0.[0]a')}
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          color="textSecondary"
                        >
                          <Box fontWeight="bold">AVERAGE VIEWS/VIDEO</Box>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};

export default enhance(InfluencerProfileOverviewCard);
