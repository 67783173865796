import { AsyncThunk } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from '../store/types';

export function useFetchOnMount<FP>(
  shouldFetchSelector: (state: State) => boolean,
  fetchAction: AsyncThunk<any, FP, any>,
  fetchActionPayload?: FP
) {
  const dispatch = useDispatch();

  const shouldFetch = useSelector(shouldFetchSelector);

  useEffect(() => {
    if (shouldFetch) {
      dispatch(fetchAction(fetchActionPayload));
    }
  }, [dispatch, shouldFetch, fetchAction, fetchActionPayload]);
}
