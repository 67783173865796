import { IconButton, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import momentTz from 'moment-timezone';
import { platform } from 'os';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import xss from 'xss';

import { InfluencerAvatar } from '../../../../components/InfluencerAvatar/InfluencerAvatar';
import { useMultiItemsSelect } from '../../../../hooks/useMultiItemsSelect';
import {
  getIsViewOnly,
  getUserFeatures,
} from '../../../../store/models/user/user.selectors';
import { CampaignMultipleItemsDeleteDialog } from '../../CampaignMultipleItemsDeleteDialog';
import PostCell, { ROW_HEIGHT } from '../../components/PostCell';
import { Sections } from '../../constants';
import Table, { StatBox } from '../../Table';
import { CampaignInfluencer } from '../../types';
import { findInfluencerById } from '../../utils';
import {
  DeleteMultipleSocialPostsPayload,
  deleteSocialPostsAction,
} from './store/SocialMediaPerformance.thunks';
import {
  getCreator,
  getImage,
  getImpressions,
  getLikes,
  getPermalink,
  getPlatform,
  getPlatformKey,
  getPostId,
  getReplies,
  getRetweets,
  getText,
  SocialMediaPerformancePost,
} from './store/types';

export type SetSocialPostDelete = (p: {
  id: number | string;
  campaignId: number;
  platform: string;
  section: string;
}) => void;

type Row = ReturnType<typeof getRows>[0];

const getCols = (
  hasSharesOrRetweets: boolean,
  allImpressionsAreEst: boolean,
  setSocialPostDelete: SetSocialPostDelete,
  isViewOnly: boolean,
  isDiscovery: boolean,
  handleRowSelect: (id: string) => void
) =>
  [
    {
      label: 'Creator',
      key: 'creator',
      render: (row: Row) => (
        <div
          style={{ position: 'relative', maxWidth: '150px' }}
          className={clsx({ 'padding-on-hover': !isViewOnly })}
        >
          {!isViewOnly && (
            <div
              style={{
                position: 'absolute',
                left: -32,
                top: '50%',
                transform: 'translateY(-50%)',
                height: ROW_HEIGHT,
                width: 48,
                background: 'rgba(0, 0, 0, .1)',
                zIndex: 5,
              }}
              className="slide-on-hover"
              onClick={(e) => e.stopPropagation()}
            >
              <Checkbox
                color="primary"
                checked={row.isSelected}
                onChange={() => {
                  handleRowSelect(row.postId);
                }}
                inputProps={{ 'aria-label': 'Select video' }}
              />
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Tooltip placement="top" title="Delete Post">
                  <IconButton
                    onClick={() => {
                      setSocialPostDelete({
                        id: row.postId,
                        campaignId: row.influencer?.campaign_id,
                        platform: row.platform,
                        section: Sections.PERFORMANCE,
                      });
                    }}
                  >
                    <DeleteIcon style={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
          <InfluencerAvatar influencer={row.influencer} />
        </div>
      ),
      stretch: true,
    },
    {
      label: 'Post',
      key: 'date',
      sortKey: 'date_unix',
      render: (row: Row) => <PostCell {...row} />,
      stretch: true,
      flex: 3,
    },
    hasSharesOrRetweets
      ? {
          label: 'Retweets/Shares',
          key: 'retweets',
          render: (row: Row) => (
            <StatBox
              stat={row.retweets}
              format={row.platform === 'instagram' ? null : undefined}
            />
          ),
        }
      : null,
    {
      label: 'Likes',
      key: 'likes',
      render: (row: Row) => <StatBox stat={row.likes} />,
    },
    {
      label: 'Replies/Comm',
      key: 'replies',
      render: (row: Row) => <StatBox stat={row.replies} />,
    },
    {
      label: allImpressionsAreEst ? 'Est. Impressions' : 'Impressions',
      key: 'impressions',
      render: (row: Row) => <StatBox stat={row.impressions} />,
    },
    {
      label: 'Media Value',
      key: 'emv',
      render: (row: Row) => (
        <StatBox stat={row.emv} format="0,[0].[0]a" prefix="$" />
      ),
    },
  ].filter((col) => col !== null);

const getRows = ({
  usedInfluencers,
  timeZone,
  posts,
  selectedRows,
}: {
  timeZone: string;
  usedInfluencers: CampaignInfluencer[];
  posts: SocialMediaPerformancePost[];
  selectedRows: string[];
}) =>
  posts.map((post) => {
    const influencer = findInfluencerById(
      post.campaign_influencer_id,
      usedInfluencers
    );

    const key = getPlatformKey(post);
    const text = xss(getText(post));
    const date = momentTz.tz(post.published_at, timeZone);
    const link = getPermalink(post);
    const creator = getCreator(post);
    const platform = getPlatform(post);
    const retweets = getRetweets(post);
    const likes = getLikes(post);
    const replies = getReplies(post);
    const postId = getPostId(post);
    const image = getImage(post);
    const impressions = getImpressions(post);

    return {
      key: key,
      text,
      date: date.format('DD MMM YYYY, h:mm A'),
      date_unix: date.unix(),
      image,
      link,
      influencer,
      creator,
      platform,
      retweets,
      likes,
      replies,
      impressionsAreEst: post.hasOwnProperty('est_impression_count'),
      impressions,
      emv: post.emv,
      postId,
      isSelected: selectedRows.includes(postId),
    };
  });

interface DetailedTableProps {
  posts: SocialMediaPerformancePost[];
  usedInfluencers: CampaignInfluencer[];
  timeZone: string;
  hasSharesOrRetweets: boolean;
  setSocialPostDelete: SetSocialPostDelete;
  setSelectedCampaignInfluencer: (id: number) => void;
  campaignId: number;
}

export const DetailedTable = ({
  usedInfluencers,
  timeZone,
  posts,
  hasSharesOrRetweets,
  setSocialPostDelete,
  setSelectedCampaignInfluencer,
  campaignId,
}: DetailedTableProps) => {
  const dispatch = useDispatch();

  const isViewOnly = useSelector(getIsViewOnly);

  const features = useSelector(getUserFeatures);

  const usedPostsIds = useMemo(() => posts.map((p) => getPostId(p)), [posts]);

  const {
    selectedItems: selectedRows,
    handleAllItemsSelect: handleAllRowsSelect,
    handleItemSelect: handleRowSelect,
  } = useMultiItemsSelect(usedPostsIds);

  const rows = useMemo(
    () =>
      getRows({
        usedInfluencers,
        timeZone,
        posts,
        selectedRows,
      }),
    [usedInfluencers, timeZone, posts, selectedRows]
  );

  const allImpressionsAreEst = rows.some(
    ({ impressionsAreEst }) => impressionsAreEst
  );

  const onCreatorColClick = useCallback(
    ({ influencer }) => setSelectedCampaignInfluencer(influencer.id),
    [setSelectedCampaignInfluencer]
  );
  const idTable = 'performanceTab-socialMedia-detailed';

  const getPostsByPostId = (postIds: string[]) => {
    if (!postIds || !postIds.length) return [];

    return postIds.map((postId) => {
      const post = posts.find((p) => getPostId(p) === postId);
      return post;
    });
  };

  const getSocialPostsMappedByInfluencer = (postIds: string[]) => {
    const foundPosts = getPostsByPostId(postIds);

    return foundPosts.reduce((acc, currentPost) => {
      const platform = getPlatform(currentPost);
      const postId = getPostId(currentPost);

      const influencerKey = currentPost.campaign_influencer_id;
      const platformKey = platform;

      if (!acc[influencerKey]) {
        acc[influencerKey] = {};
      }

      if (!acc[influencerKey][platformKey]) {
        acc[influencerKey][platformKey] = [];
      }

      acc[influencerKey][platformKey].push(postId);

      return acc;
    }, {} as Record<number, Record<string, (string | number)[]>>);
  };

  const deleteMultipleSocialPosts = (
    payload: DeleteMultipleSocialPostsPayload
  ) => {
    dispatch(deleteSocialPostsAction(payload));
  };

  return (
    <>
      {selectedRows.length > 0 && (
        <div style={{ padding: 4, display: 'flex' }}>
          <Checkbox
            color="primary"
            onChange={(_, checked) => {
              handleAllRowsSelect(checked);
            }}
          />
          <CampaignMultipleItemsDeleteDialog
            items={selectedRows}
            onDelete={(items) => {
              deleteMultipleSocialPosts({
                campaignId,
                postIds: items as (string | number)[],
                influencersPostsMap: getSocialPostsMappedByInfluencer(items),
              });
            }}
            title={'Delete Posts'}
          />
        </div>
      )}
      {/* @ts-expect-error Table component is not typed */}
      <Table
        cols={getCols(
          hasSharesOrRetweets,
          allImpressionsAreEst,
          setSocialPostDelete,
          isViewOnly,
          features.discovery,
          handleRowSelect
        )}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        minWidth={1300}
        defaultSortCol="date"
        idTable={idTable}
        onCreatorColClick={onCreatorColClick}
      />
    </>
  );
};

export default DetailedTable;
