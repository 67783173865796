import { v4 as uuidv4 } from 'uuid';

import {
  PATH_SIGNIN_TIKTOK_CALLBACK,
  PATH_SIGNIN_TWITCH_CALLBACK,
  PATH_SIGNIN_YOUTUBE_CALLBACK,
} from '../common/constants/paths';

export const redirectTwitch = (target?: string) => async () => {
  window.location.href =
    'https://id.twitch.tv/oauth2/authorize' +
    `?client_id=${process.env.REACT_APP_TWITCH_CLIENT}` +
    `&redirect_uri=${encodeURI(
      `${window.location.origin}${PATH_SIGNIN_TWITCH_CALLBACK}${
        target ? `?t=${target}` : ''
      }`
    )}` +
    '&response_type=token' +
    '&scope=user:read:email analytics:read:games analytics:read:extensions channel:read:subscriptions user:read:broadcast channel_read';
};

export const redirectYoutube = (target?: string) => async () => {
  window.location.href =
    'https://accounts.google.com/o/oauth2/v2/auth' +
    `?client_id=${process.env.REACT_APP_YOUTUBE_CLIENT}&` +
    `scope=https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/userinfo.email&` +
    `include_granted_scopes=true&` +
    `state=state_parameter_passthrough_value&` +
    `redirect_uri=${encodeURI(
      `${window.location.origin}${PATH_SIGNIN_YOUTUBE_CALLBACK}${
        target ? `?t=${target}` : ''
      }`
    )}&` +
    `response_type=code&` +
    `access_type=offline`;
};

export const redirectTwitter = (oauth_token) => {
  window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${oauth_token}`;
};

export const FACEBOOK_SCOPE =
  'instagram_basic,instagram_manage_insights,public_profile,email,business_management,read_insights,pages_show_list,pages_read_engagement,pages_read_user_content'; // The account link dialog only allows for public profile
// Removing user_videos, user_posts for now since we don't have permissions for them.

export const TIKTOK_SCOPE = 'user.info.basic,video.list';

export const TIKTOK_STATE_STORAGE_KEY = 'TIKTOK_LOGIN_STATE';

export const redirectTiktok = (target) => async () => {
  // remove port for development
  const REDIRECT_URI = `${window.location.origin.replace(
    /:\d+/g,
    ''
  )}${PATH_SIGNIN_TIKTOK_CALLBACK}?t=${target}`;

  const state = uuidv4();

  window.localStorage.setItem(TIKTOK_STATE_STORAGE_KEY, state);

  window.location.href = `https://www.tiktok.com/v2/auth/authorize?client_key=${process.env.REACT_APP_TIKTOK_CLIENT_KEY}&response_type=code&scope=${TIKTOK_SCOPE}&redirect_uri=${REDIRECT_URI}&state=${state}`;
};
