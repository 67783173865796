import {
  Box,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import clsx from 'clsx';
import React, { useState } from 'react';

import withDataMissing from '../hocs/withDataMissing';
import percent from '../utils/percent';
import through from '../utils/through';

const Traits = (props) => {
  const { traits } = props;

  const [pagination, setPagination] = useState(0);

  const traitsBySignificance = traits.sort(
    (traitA, traitB) => traitB.percentile - traitA.percentile
  );

  const visibleTraits = traitsBySignificance.slice(
    pagination * 4,
    (pagination + 1) * 4
  );

  const buttons = through(0, traits.length / 4).map((i) => {
    const onClick = () => {
      setPagination(i);
    };

    const className = clsx('btn', 'btn-light', {
      active: pagination === i,
    });

    return (
      <ToggleButton
        key={traitsBySignificance[i * 4].name}
        className={className}
        onClick={onClick}
        value={i + 1}
      >
        {i + 1}
      </ToggleButton>
    );
  });

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <List disablePadding style={{ minHeight: 200 }}>
          {visibleTraits.map((trait) => {
            const rank = traitsBySignificance.findIndex((t) => t === trait);

            const { name, percentile } = trait;

            const significancePercentage = percent(1, percentile);

            return (
              <ListItem
                key={name}
                style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
              >
                <ListItemText primary={`${rank + 1}. ${name}`} />
                <ListItemText
                  style={{ textAlign: 'right' }}
                  primary={`${significancePercentage}%`}
                />
                <Box clone mt={1} width="100%">
                  <LinearProgress
                    variant="determinate"
                    value={significancePercentage}
                  />
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid item>
        <Box textAlign="center">
          <ToggleButtonGroup size="small" exclusive value={pagination}>
            {buttons}
          </ToggleButtonGroup>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withDataMissing(Traits);
