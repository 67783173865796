import _sortBy from 'lodash/sortBy';
import React, { useCallback } from 'react';

import Table, { StatBox, TableText } from '../Table';
import SectionContainer from './SectionContainer';
import { SectionTitle } from './shared';

const COLS = [
  {
    label: 'URL',
    key: 'link',
    render: (data) => <TableText>{data.link}</TableText>,
    stretch: true,
  },
  {
    label: 'Shares',
    key: 'count',
    render: (data) => <StatBox stat={data.count} />,
  },
  {
    label: 'Videos',
    key: 'streams',
    render: (data) => <StatBox stat={data.streams} />,
  },
];

export const LinkSharing = ({
  linkTrackings,
  onViewMore,
  isSponsorship,
  setNumberPage,
  activeTab,
}) => {
  const onRowClick = useCallback(
    ({ id, link }) => {
      if (activeTab !== 'summary') {
        return setNumberPage(
          Math.floor(
            _sortBy(linkTrackings, 'link').findIndex(
              (linkTracking) => linkTracking.link === link
            ) / 5
          )
        );
      }
      onViewMore(id);
    },
    [onViewMore]
  );
  const idTable = 'link-sharing';
  return (
    <SectionContainer isSponsorship={isSponsorship}>
      <SectionTitle onViewMore={onViewMore}>Link Sharing</SectionTitle>
      <Table
        noBorders={true}
        cols={COLS}
        rows={linkTrackings.map((t) => ({ ...t, key: t.link }))}
        defaultSortCol="shares"
        onRowClick={onRowClick}
        idTable={idTable}
      />
    </SectionContainer>
  );
};

export default LinkSharing;
