import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import clsx from 'clsx';
import { connect, FieldArray } from 'formik';
import React from 'react';
import ReactSelect from 'react-select';

import CardContent from '../../../components/CardContent';
import CardTitle from '../../../components/CardTitle';
import Chip from '../../../components/Chip';
import ClickSelectMultiple from '../../../components/ClickSelectMultiple';
import FormControl from '../../../components/FormControl';
import FormLabel from '../../../components/FormLabel';
import IconButton from '../../../components/IconButton';
import InputAdornment from '../../../components/InputAdornment';
import MenuItem from '../../../components/MenuItem';
import OutlinedInput from '../../../components/OutlinedInput';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';
import interestCategories from '../../../helpers/interest-categories';
import useInputValue from '../../../hooks/use-input-value';
import capitalize from '../../../utils/capitalize';
import countries from '../../../utils/country-list';

const ageGroups = [
  {
    value: '12-17',
    label: '12-17',
  },
  {
    value: '18-24',
    label: '18-24',
  },
  {
    value: '25-34',
    label: '25-34',
  },
  {
    value: '35-44',
    label: '35-44',
  },
  {
    value: '45-54',
    label: '45-54',
  },
  {
    value: '55-64',
    label: '55-64',
  },
  {
    value: '65-200',
    label: '65+',
  },
];

const languages = [
  { value: 'af', label: 'Afrikaans' },
  { value: 'sq', label: 'Albanian' },
  { value: 'ar', label: 'Arabic' },
  { value: 'an', label: 'Aragonese' },
  { value: 'hy', label: 'Armenian' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'eu', label: 'Basque' },
  { value: 'be', label: 'Belarusian' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bh', label: 'Bihari languages' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'br', label: 'Breton' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'my', label: 'Burmese' },
  { value: 'ca', label: 'Catalan; Valencian' },
  { value: 'km', label: 'Central Khmer' },
  { value: 'zh', label: 'Chinese' },
  { value: 'kw', label: 'Cornish' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'nl', label: 'Dutch; Flemish' },
  { value: 'en', label: 'English' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'et', label: 'Estonian' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'French' },
  { value: 'gl', label: 'Galician' },
  { value: 'ka', label: 'Georgian' },
  { value: 'de', label: 'German' },
  { value: 'el', label: 'Greek' },
  { value: 'he', label: 'Hebrew' },
  { value: 'hi', label: 'Hindi' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'is', label: 'Icelandic' },
  { value: 'id', label: 'Indonesian' },
  { value: 'ga', label: 'Irish' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'jv', label: 'Javanese' },
  { value: 'kn', label: 'Kannada' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'ko', label: 'Korean' },
  { value: 'ku', label: 'Kurdish' },
  { value: 'la', label: 'Latin' },
  { value: 'lv', label: 'Latvian' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'lb', label: 'Luxembourgish; Letzeburgesch' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'ms', label: 'Malay' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mr', label: 'Marathi' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'no', label: 'Norwegian' },
  { value: 'nn', label: 'Norwegian Nynorsk; Nynorsk, Norwegian' },
  { value: 'oc', label: 'Occitan (post 1500)' },
  { value: 'fa', label: 'Persian' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'sr', label: 'Serbian' },
  { value: 'si', label: 'Sinhala; Sinhalese' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'so', label: 'Somali' },
  { value: 'es', label: 'Spanish; Castilian' },
  { value: 'su', label: 'Sundanese' },
  { value: 'sw', label: 'Swahili' },
  { value: 'sv', label: 'Swedish' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'ta', label: 'Tamil' },
  { value: 'tt', label: 'Tatar' },
  { value: 'te', label: 'Telugu' },
  { value: 'th', label: 'Thai' },
  { value: 'tr', label: 'Turkish' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'cy', label: 'Welsh' },
  { value: 'fy', label: 'Western Frisian' },
];

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    padding: 0,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  subHeader: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  trialDisabled: {
    pointerEvents: 'none',
    backgroundColor: theme.palette.text.disabled,
  },
}));

function AudienceParameters(props) {
  const {
    formik: { values, handleChange, setFieldValue },
    trialAccountSearch,
  } = props;
  const classes = useStyles();

  const audienceTalkingAbout = useInputValue('');

  const defaultCountry = { value: '', label: 'Any' };
  const myTheme = useTheme();

  function renderAgeGroups() {
    function handleAgeGroupsChange(values) {
      setFieldValue('audience_age_groups', values);
    }

    const ageGroupValues = ageGroups.map(({ value }) => value);

    const ageGroupsByAge = values.audience_age_groups.sort(
      (ageGroupA, ageGroupB) =>
        ageGroupValues.indexOf(ageGroupA) - ageGroupValues.indexOf(ageGroupB)
    );

    return (
      <FormControl>
        <FormLabel>Age Group</FormLabel>
        <FieldArray
          name="audience_age_groups"
          render={({ remove }) => (
            <Grid container alignItems="center" spacing={1}>
              {ageGroupsByAge.map((ageGroup) => (
                <Grid item key={ageGroup} data-id={`ageGroup-${ageGroup}`}>
                  <Chip
                    variant="outlined"
                    label={
                      ageGroups.find(({ value }) => value === ageGroup).label
                    }
                    onDelete={() => {
                      remove(values.audience_age_groups.indexOf(ageGroup));
                    }}
                  />
                </Grid>
              ))}
              <Grid item>
                <ClickSelectMultiple
                  onChange={handleAgeGroupsChange}
                  values={values.audience_age_groups}
                  items={ageGroups}
                  opener={
                    <IconButton
                      size="small"
                      edge="start"
                      color="primary"
                      data-id="age-group-button"
                    >
                      <Icon fontSize="large">add_circle</Icon>
                    </IconButton>
                  }
                >
                  {({ handleChange }) =>
                    ageGroups.map(({ value, label }) => (
                      <MenuItem
                        button
                        key={value}
                        onClick={() => handleChange(value)}
                        selected={values.audience_age_groups.includes(value)}
                      >
                        {label}
                      </MenuItem>
                    ))
                  }
                </ClickSelectMultiple>
              </Grid>
            </Grid>
          )}
        />
      </FormControl>
    );
  }

  function renderLocation() {
    function GrayArrowDropDownOutlinedIcon(props) {
      return (
        <Icon
          style={{ cursor: 'pointer' }}
          component={ArrowDropDownOutlinedIcon}
          color="action"
          data-id="target-audience-country-icon"
        ></Icon>
      );
    }

    return (
      <FormControl style={{ zIndex: 100 }}>
        <FormLabel>Country</FormLabel>
        <ReactSelect
          id="target-audience-country"
          value={getSelectedCountry()}
          onChange={onCountryChange}
          options={renderCountriesList()}
          components={{ DropdownIndicator: GrayArrowDropDownOutlinedIcon }}
          placeholder="e.g., United States"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: myTheme.palette.primary.main,
              primary25: myTheme.palette.action.selected,
            },
          })}
          styles={{
            control: (styles) => ({
              ...styles,
              width: 170,
              height: 48,
              fontSize: '16px',
            }),
            option: (styles) => ({
              ...styles,
              width: 170,
              fontSize: '16px',
            }),
            input: (styles) => ({
              ...styles,
              width: 170,
              height: 40,
              cursor: 'text',
              fontSize: '16px',
              padding: '10px 0px',
            }),
          }}
          name="audience_country"
          // components={
          //   data-id: 'target-audience-country',
          // }
        />
      </FormControl>
    );
  }

  function renderGender() {
    return (
      <FormControl>
        <FormLabel>Gender</FormLabel>
        <Select
          displayEmpty
          name="audience_gender"
          value={values.audience_gender}
          onChange={handleChange}
          variant="outlined"
          input={<OutlinedInput />}
          style={{ minWidth: 140 }}
        >
          <MenuItem value="">Any</MenuItem>
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
        </Select>
      </FormControl>
    );
  }

  function renderAudienceTalkingAbout() {
    return (
      <FormControl>
        <FormLabel>Talking about</FormLabel>
        <FieldArray
          name="audience_talking_about"
          render={({ push, remove }) => {
            function handleAdd() {
              if (audienceTalkingAbout.value) {
                push(audienceTalkingAbout.value);

                audienceTalkingAbout.set('');
              }
            }

            return (
              <Grid
                container
                spacing={1}
                alignItems="center"
                className={clsx({
                  [classes.trialDisabled]: trialAccountSearch,
                })}
              >
                <Grid item>
                  <TextField
                    variant="outlined"
                    placeholder="Type a keyword"
                    value={audienceTalkingAbout.value}
                    onChange={audienceTalkingAbout.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          variant="filled"
                          data-id="talking-about-button"
                        >
                          <IconButton
                            data-id="talking-about-button-icon"
                            size="small"
                            color="primary"
                            edge="end"
                            disabled={!audienceTalkingAbout.value}
                            onClick={handleAdd}
                          >
                            <Icon
                              data-id="talking-about-button-icon-span"
                              fontSize="large"
                            >
                              add_circle
                            </Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                      'data-id': 'talking-about-input',
                    }}
                    onKeyDown={({ key }) => {
                      if (key === 'Enter') {
                        handleAdd();
                      }
                    }}
                  />
                </Grid>
                {values.audience_talking_about.map((word, index) => (
                  <Grid item key={index}>
                    <Chip
                      variant="outlined"
                      label={word}
                      onDelete={() => remove(index)}
                    />
                  </Grid>
                ))}
              </Grid>
            );
          }}
        />
      </FormControl>
    );
  }

  const AudienceInterestCategories = (props) => {
    return (
      <FormControl>
        <FormLabel>Interest categories</FormLabel>
        <FieldArray
          name="audience_interest_categories"
          render={({ remove }) => {
            return (
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <ClickSelectMultiple
                    withSearchInput
                    values={values.audience_interest_categories}
                    onChange={(values) => {
                      setFieldValue('audience_interest_categories', values);
                    }}
                    opener={
                      <IconButton
                        size="small"
                        edge="start"
                        color="primary"
                        data-id="target-audience-interest-categories-button"
                      >
                        <Icon fontSize="large">add_circle</Icon>
                      </IconButton>
                    }
                  >
                    {({ handleChange, withSearchFilter }) => (
                      <>
                        <List className={classes.list} subheader={<li />}>
                          {withSearchFilter(interestCategories).map(
                            (interestCategoryGroup) => (
                              <li
                                key={interestCategoryGroup.name}
                                className={classes.listSection}
                              >
                                <ul className={classes.ul}>
                                  <ListSubheader className={classes.subHeader}>
                                    {interestCategoryGroup.name}
                                  </ListSubheader>
                                  {interestCategoryGroup.items.map(
                                    (category) => (
                                      <ListItem
                                        button
                                        key={category}
                                        onClick={() => handleChange(category)}
                                        selected={values.audience_interest_categories.includes(
                                          category
                                        )}
                                      >
                                        <ListItemText
                                          primary={capitalize(category)}
                                        />
                                      </ListItem>
                                    )
                                  )}
                                </ul>
                              </li>
                            )
                          )}
                        </List>
                      </>
                    )}
                  </ClickSelectMultiple>
                </Grid>
                {values.audience_interest_categories.map((category, index) => (
                  <Grid
                    item
                    key={index}
                    data-id={`target-audience-interest-categories-chip-${index}`}
                  >
                    <Chip
                      variant="outlined"
                      label={category}
                      onDelete={() => remove(index)}
                    />
                  </Grid>
                ))}
              </Grid>
            );
          }}
        />
      </FormControl>
    );
  };

  function renderLanguages() {
    function handleLanguagesChange(values) {
      setFieldValue('audience_language', values);
    }

    return (
      <FormControl>
        <FormLabel>Language</FormLabel>
        <FieldArray
          name="audience_language"
          render={({ remove }) => (
            <Grid container alignItems="center" spacing={1}>
              {values.audience_language.map((language, i) => (
                <Grid
                  item
                  key={language}
                  data-id={`target-audience-language-chip-${i}`}
                >
                  <Chip
                    variant="outlined"
                    label={
                      languages.find(({ value }) => value === language).label
                    }
                    onDelete={() => {
                      remove(i);
                    }}
                  />
                </Grid>
              ))}
              <Grid item>
                <ClickSelectMultiple
                  onChange={handleLanguagesChange}
                  values={values.audience_language}
                  opener={
                    <IconButton
                      size="small"
                      edge="start"
                      color="primary"
                      data-id="target-audience-language-button"
                    >
                      <Icon fontSize="large">add_circle</Icon>
                    </IconButton>
                  }
                >
                  {({ handleChange }) =>
                    languages.map(({ value, label }) => (
                      <MenuItem
                        button
                        key={value}
                        onClick={() => handleChange(value)}
                        selected={values.audience_language.includes(value)}
                      >
                        {label}
                      </MenuItem>
                    ))
                  }
                </ClickSelectMultiple>
              </Grid>
            </Grid>
          )}
        />
      </FormControl>
    );
  }

  function onCountryChange({ value }) {
    setFieldValue('audience_country', value);
  }

  function renderCountriesList() {
    return [
      defaultCountry,
      ...countries
        .filter((e) => !!e.inSearch)
        .map(({ code, name }) => {
          return { value: code, label: name };
        }),
    ];
  }

  function getSelectedCountry() {
    let country = countries.find(
      ({ code, name }) => code === values.audience_country
    );

    if (country) {
      return { value: country.code, label: country.name };
    } else {
      return defaultCountry;
    }
  }

  return (
    <>
      <CardContent>
        <CardTitle>Demographics</CardTitle>
      </CardContent>

      <CardContent>
        <Grid container spacing={4}>
          <Grid item>{renderAgeGroups()}</Grid>
          <Grid item>{renderGender()}</Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <CardTitle>Interests</CardTitle>
      </CardContent>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <AudienceInterestCategories />
          </Grid>
          <Grid item>{renderAudienceTalkingAbout()}</Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <CardTitle>Geographics</CardTitle>
      </CardContent>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item>{renderLocation()}</Grid>
          <Grid item>{renderLanguages()}</Grid>
        </Grid>
      </CardContent>
    </>
  );
}

export default connect(AudienceParameters);
