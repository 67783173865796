import { AxisBottom } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar, BarRounded } from '@visx/shape';
import {
  defaultStyles as tooltipDefaultStyles,
  TooltipWithBounds as Tooltip,
  useTooltip,
} from '@visx/tooltip';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import numeral from 'numeral';
import React, { useMemo } from 'react';

import {
  BannerData,
  BannerDeliverable,
} from '../DeliverablesTab/Banners/store/types';
import { BrushBounds, getBarSize } from './utils';

const TRACK_HEIGHT = 10;

const TRACK_PADDING = { top: 48, left: 32, right: 32, bottom: 48 };

const HEIGHT = TRACK_HEIGHT + TRACK_PADDING.top + TRACK_PADDING.bottom;

const OCCURRENCE_WIDTH = 12;

type Occurrence = BannerData & { start_at: number; end_at: number };

export type ExtendedBannerDeliverable = BannerDeliverable & {
  occurrences: Occurrence[];
};

interface BannerTrackProps {
  deliverable: ExtendedBannerDeliverable;
  duration: number;
  width: number;
  brushBounds: BrushBounds;
  svgOnly: boolean;
  color: string;
}

const BannerTrack = ({
  deliverable,
  duration,
  width,
  brushBounds,
  svgOnly,
  color,
}: BannerTrackProps) => {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<Occurrence & { count: number }>();

  const fullTrackMaxX = width - TRACK_PADDING.right - TRACK_PADDING.left;
  const barSize = getBarSize(duration);

  const numberOfBars = brushBounds
    ? brushBounds.end - brushBounds.start
    : Math.ceil(duration / barSize) + 1;

  const mentionsBarsScale = useMemo(
    () =>
      scaleBand({
        domain: Array.from(
          {
            length: numberOfBars,
          },
          (_, i) => i
        ),
        padding: 0.2,
        range: [0, fullTrackMaxX],
      }),
    [fullTrackMaxX, numberOfBars]
  );

  const axisPadding = mentionsBarsScale(0) + mentionsBarsScale.bandwidth() / 2;

  const trackMaxX = fullTrackMaxX - 2 * axisPadding;

  const trackScale = useMemo(
    () =>
      scaleLinear({
        domain: brushBounds
          ? [brushBounds.start * barSize, brushBounds.end * barSize]
          : [
              0,
              Math.ceil(duration) + barSize - (Math.ceil(duration) % barSize),
            ],
        range: [0, trackMaxX],
      }),
    [brushBounds, barSize, duration, trackMaxX]
  );

  const axisScale = useMemo(
    () =>
      scaleLinear({
        domain: brushBounds
          ? [brushBounds.start, brushBounds.end - 1]
          : [0, Math.ceil(duration / barSize) + 1],
        range: [0, trackMaxX],
      }),
    [brushBounds, duration, barSize, trackMaxX]
  );

  const sortedOccurrences = useMemo(
    () => deliverable.occurrences.sort((a, b) => a.start_at - b.end_at),
    [deliverable.occurrences]
  );

  const unclusteredOccurrences = useMemo(
    () =>
      brushBounds
        ? sortedOccurrences.filter(
            (occurrence) =>
              occurrence.start_at >= brushBounds.start * barSize &&
              occurrence.end_at <= brushBounds.end * barSize
          )
        : sortedOccurrences,
    [barSize, brushBounds, sortedOccurrences]
  );

  const occurrences = useMemo(
    () =>
      unclusteredOccurrences.reduce((prev, oldOccurrence) => {
        const occurrence = { ..._cloneDeep(oldOccurrence), count: 1 };

        if (prev.length === 0) return [occurrence];

        const last = prev.length - 1;

        const lastEnd = Math.max(
          trackScale(prev[last].end_at),
          trackScale(prev[last].start_at) + OCCURRENCE_WIDTH
        );

        if (lastEnd >= trackScale(occurrence.start_at)) {
          prev[last].count += 1;
          prev[last].end_at = occurrence.end_at;
        } else {
          prev.push(occurrence);
        }

        return prev;
      }, []),
    [unclusteredOccurrences, trackScale]
  );

  const onBarMove = (index) => (e) => {
    showTooltip({
      tooltipData: occurrences[index],
      tooltipLeft: e.pageX,
      tooltipTop: e.pageY,
    });
  };

  const onBarLeave = () => {
    hideTooltip();
  };

  const svg = (
    <svg width={width} height={HEIGHT}>
      <image
        x={0}
        y={0}
        height={TRACK_PADDING.top * 0.75}
        href={deliverable.image}
      />
      <Group left={TRACK_PADDING.left + axisPadding} top={TRACK_PADDING.top}>
        {occurrences.map((occurrence, idx) => {
          const x = trackScale(occurrence.start_at);
          const width = Math.max(
            OCCURRENCE_WIDTH,
            trackScale(occurrence.end_at) - x
          );

          return (
            <React.Fragment key={idx}>
              <BarRounded
                x={x}
                radius={2}
                width={width}
                height={TRACK_HEIGHT}
                y={0}
                fill={color}
                onMouseMove={onBarMove(idx)}
                onMouseLeave={onBarLeave}
              />
            </React.Fragment>
          );
        })}
      </Group>
      <Group top={TRACK_PADDING.top + TRACK_HEIGHT} left={TRACK_PADDING.left}>
        <Bar width={axisPadding} height={2} x={0} y={-1} fill="#0048f2" />
        <Bar
          width={axisPadding}
          height={2}
          x={trackMaxX + axisPadding}
          y={-1}
          fill="#0048f2"
        />
        <AxisBottom
          scale={axisScale}
          numTicks={12}
          left={axisPadding}
          tickStroke="#0048f2"
          tickLength={4}
          stroke="#0048f2"
          strokeWidth={2}
          tickFormat={(t: number) =>
            moment
              .unix(t * barSize)
              .utc()
              .format('HH:mm')
          }
        />
      </Group>
    </svg>
  );

  if (svgOnly) return svg;

  return (
    <>
      {svg}

      {tooltipOpen && tooltipData && (
        <Tooltip
          style={{ ...tooltipDefaultStyles, position: 'fixed' }}
          top={tooltipTop}
          left={tooltipLeft}
          key={Math.random()}
        >
          <p>
            {tooltipData.count} banner occurrence
            {tooltipData.count > 1 ? 's' : ''} at{' '}
            {moment.utc(tooltipData.start_at * 1000).format('HH:mm:ss')}-
            {moment.utc(tooltipData.end_at * 1000).format('HH:mm:ss')}
            <br />
            <strong>Avg. CCV:</strong>{' '}
            {numeral(tooltipData.avg_ccv).format('0.[0]a')}
          </p>
        </Tooltip>
      )}
    </>
  );
};

export default BannerTrack;
