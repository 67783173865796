import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import facebookIcon from '../../assets-2.0/facebook.svg';
import facebookGamingIcon from '../../assets-2.0/facebookGaming.svg';
import instagramIcon from '../../assets-2.0/instagram.svg';
import tiktokIcon from '../../assets-2.0/tiktok.svg';
import twitchIcon from '../../assets-2.0/twitch.svg';
import twitterIcon from '../../assets-2.0/twitter.svg';
import youtubeIcon from '../../assets-2.0/youtube.svg';
import OutlinedInput from '../../components/OutlinedInput';
import { streamingPlatforms } from './utils';

// TODO: Refactor this, since Streaming_Platform now include social posts platforms that have videos (such as TikTok and Twitter)
const STREAMING_PLATFORMS = [
  {
    key: 'twitch',
    color: '#6441a5',
    iconSrc: twitchIcon,
    label: 'Twitch',
  },
  {
    key: 'youtube',
    color: '#c4302b',
    iconSrc: youtubeIcon,
    label: 'Youtube',
  },
  {
    key: 'tiktok',
    color: '#010101',
    iconSrc: tiktokIcon,
    label: 'Tiktok Posts',
  },
  {
    key: 'tiktok_video',
    color: '#010101',
    iconSrc: tiktokIcon,
    label: 'Tiktok',
  },
  {
    key: 'facebook_gaming',
    color: '#005af0',
    iconSrc: facebookGamingIcon,
    label: 'Facebook Gaming',
  },
];

const SOCIAL_PLATFORMS = [
  {
    key: 'twitter',
    social: true,
    color: '#1DA0F1',
    iconSrc: twitterIcon,
    label: 'Twitter',
  },
  {
    key: 'instagram',
    social: true,
    color: '#dd2a7b',
    iconSrc: instagramIcon,
    label: 'Instagram',
  },
  {
    key: 'tiktok',
    social: true,
    color: '#010101',
    iconSrc: tiktokIcon,
    label: 'Tiktok Posts',
  },
  {
    key: 'facebook',
    social: true,
    color: '#325a9c',
    iconSrc: facebookIcon,
    label: 'Facebook',
  },
];

const useStyles = makeStyles({
  select: {
    padding: 4,
    background: 'white',
  },
  label: {
    fontSize: 16,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
  },
});

export const CampaignPlatformFilter = ({
  platformFilter,
  setPlatformFilter,
  showSocialPlatforms,
  campaign,
  hideSocialPlatformsWithVideos,
}) => {
  const onChange = ({ target: { value } }) => setPlatformFilter(value);

  const classes = useStyles();

  const platforms = showSocialPlatforms
    ? SOCIAL_PLATFORMS.filter((p) =>
        campaign.campaign_influencers.some(
          (i) => !!i[`${p.key}_url`] || !!i[`past_${p.key}`].length
        )
      )
    : STREAMING_PLATFORMS.filter((p) =>
        campaign.videos.some((v) => v.resource === p.key)
      ).filter((platform) => {
        if (hideSocialPlatformsWithVideos) {
          return streamingPlatforms.includes(platform.key);
        }

        return true;
      });

  if (platforms.length <= 1) return null;

  return (
    <>
      <span className={classes.label}>Platform: </span>
      <Select
        value={platformFilter}
        classes={{ selectMenu: classes.select }}
        input={<OutlinedInput />}
        onChange={onChange}
      >
        <MenuItem value="all" style={{ padding: 10 }}>
          <Typography
            variant="h6"
            style={{
              padding: '3.36px 12px',
              margin: 0,
            }}
          >
            All
          </Typography>
        </MenuItem>
        {platforms
          .filter((p) => !!p.social === showSocialPlatforms)
          .map(({ key, iconSrc, color, label }) => {
            return (
              <MenuItem key={key} value={key} style={{ padding: 10 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ padding: '3.36px 12px' }}
                >
                  <img
                    src={iconSrc}
                    alt="platform logo"
                    style={{ height: '1.5em', marginRight: 8 }}
                  />
                  <Typography
                    style={{
                      color,
                    }}
                    variant="h6"
                  >
                    {label}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })}
      </Select>
    </>
  );
};

export default CampaignPlatformFilter;
