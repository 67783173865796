import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import numeral from 'numeral';
import React from 'react';

import CardTitle from '../../../components/CardTitle';
import MissingData from '../../../components/MissingData';
import isNumber from '../../../utils/is-number';

const Engagement = (props) => {
  const { title, amount, amountLabel, tooltipTitle, amountFormat } = props;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card style={{ height: '100%' }}>
        <CardContent>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <CardTitle>{title}</CardTitle>
            </Grid>
            {tooltipTitle && (
              <Grid item>
                <Tooltip placement="top" title={tooltipTitle}>
                  <IconButton size="small">
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <CardContent>
          {isNumber(amount) ? (
            <div className="number-chart padding-tb-20">
              <p className="number-chart-digit">
                {numeral(amount).format(amountFormat || '0.[0]a')}
              </p>
              <p className="number-chart-text">{amountLabel}</p>
            </div>
          ) : (
            <MissingData communication="influencer" name={amountLabel} />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const InfluencerAudienceEngagement = (props) => {
  const { activePlatform } = props;

  let main;

  if (activePlatform === 'twitch') {
    const { twitchAudienceEngagement } = props;

    const {
      viewsDays,
      averageViewersDays,
      peakViewersDays,
      followerVirality,
      engagementAverage,
      engagementPeak,
    } = twitchAudienceEngagement || {};

    main = (
      <Grid container spacing={3}>
        {isNumber(viewsDays) && (
          <Engagement
            title="Views"
            amount={viewsDays}
            amountLabel="views"
            tooltipTitle="Total number of views on the selected timeframe."
          />
        )}

        {isNumber(averageViewersDays) && (
          <Engagement
            title="Average Concurrent Viewers"
            amount={averageViewersDays}
            amountLabel="viewers/video"
            tooltipTitle="Average number of viewers who watched the stream live."
          />
        )}

        {isNumber(peakViewersDays) && (
          <Engagement
            title="Max Concurrent Viewers"
            amount={peakViewersDays}
            amountLabel="viewers"
            tooltipTitle="Maximum number of viewers who watched the stream live."
          />
        )}

        {
          /*
           * TODO: this is presumably incorrect
           */
          isNumber(followerVirality) && (
            <Engagement
              title="Followers Growth Rate"
              amount={followerVirality / 100}
              amountFormat={'0[.][0]%'}
              tooltipTitle="The percentage of the channel's followers which were received within the last 6 months."
            />
          )
        }

        {isNumber(engagementAverage) && (
          <Engagement
            title="Average view rate"
            amount={engagementAverage / 100}
            amountFormat={'0[.][0]%'}
            tooltipTitle="The average percentage of the creator's audience watching the live streams"
          />
        )}

        {isNumber(engagementPeak) && (
          <Engagement
            title="Peak view rate"
            amount={engagementPeak / 100}
            amountFormat={'0[.][0]%'}
            tooltipTitle="The peak percentage of the creator's audience watching the live streams"
          />
        )}
      </Grid>
    );
  } else {
    const { youtubeAudienceEngagement } = props;

    const {
      viewCount90,
      averageViewCountPerVideo,
      averageCommentsPerVideo,
      likes90,
    } = youtubeAudienceEngagement || {};

    main = (
      <div className="masonry-item w-100">
        <div className="row gap-20">
          {
            <Engagement
              title="Views"
              tooltipTitle="Number of views in the last 90 days"
              amount={viewCount90}
              amountLabel="views"
            />
          }

          {
            <Engagement
              title="Average Views Per Video"
              tooltipTitle="Average number of views per video in the last 90 days"
              amount={averageViewCountPerVideo}
              amountLabel="views/video"
            />
          }

          {
            <Engagement
              title="Average Comments per Video"
              tooltipTitle="Average number of comments per video in the last 90 days."
              amount={averageCommentsPerVideo}
              amountLabel="comments/video"
            />
          }

          {
            <Engagement
              title="Likes"
              tooltipTitle="The number of likes received on the videos posted in the last 90 days."
              amount={likes90}
              amountLabel="likes"
            />
          }
        </div>
      </div>
    );
  }

  return main;
};

export default InfluencerAudienceEngagement;
