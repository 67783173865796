import {
  createEntityAdapter,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';

import { LoadingState } from '../../../types';
import {
  deleteChatMention,
  deleteMultipleChatMentions,
  fetchChatMentionDeliverable,
} from './ChatMentionDeliverables.thunks';
import { ChatMentionDeliverable, LoadingStateMap, SLICE_NAME } from './types';

const chatMentionDeliverablesAdapter =
  createEntityAdapter<ChatMentionDeliverable>();

export const initialState = chatMentionDeliverablesAdapter.getInitialState<{
  loadingMap: LoadingStateMap;
  error: SerializedError | null;
}>({ loadingMap: {}, error: null });

const chatMentionDeliverablesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatMentionDeliverable.pending, (state, action) => {
        state.loadingMap[action.meta.arg.chatMentionDeliverableId] =
          LoadingState.PENDING;
        state.error = null;
      })
      .addCase(fetchChatMentionDeliverable.fulfilled, (state, action) => {
        state.loadingMap[action.meta.arg.chatMentionDeliverableId] =
          LoadingState.FULLFILLED;
        state.error = null;

        chatMentionDeliverablesAdapter.addOne(state, action.payload);
      })
      .addCase(fetchChatMentionDeliverable.rejected, (state, action) => {
        state.loadingMap[action.meta.arg.chatMentionDeliverableId] =
          LoadingState.REJECTED;
        state.error = action.error;
      })
      .addCase(deleteChatMention.pending, (state, action) => {
        const {
          commentId,
          chatMentionDeliverableId,
          deleteAll,
          commenterExternalId,
        } = action.meta.arg;

        if (deleteAll) {
          Object.keys(state.entities).forEach((deliverableId) => {
            const deliverable = state.entities[deliverableId];

            deliverable.video_comments = deliverable.video_comments.filter(
              (comment) => comment.commenter_external_id !== commenterExternalId
            );
          });
        } else {
          const deliverable = state.entities[chatMentionDeliverableId];

          // filter out deleted comment data
          deliverable.video_comments = deliverable.video_comments.filter(
            (comment) => comment.id !== commentId
          );
        }
      })

      .addCase(deleteMultipleChatMentions.fulfilled, (state, action) => {
        const { commentIds, chatMentionDeliverableId } = action.meta.arg;

        const deliverable = state.entities[chatMentionDeliverableId];

        // filter out deleted comment data
        deliverable.video_comments = deliverable.video_comments.filter(
          (comment) => !commentIds.includes(comment.id)
        );
      });
  },
});

export const { reducer } = chatMentionDeliverablesSlice;
