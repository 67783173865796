import { createSelector } from 'reselect';

import { STORAGE_PREFIX as prefix } from './redux';

export const getDateRangeFilter = (state) => state[prefix].dateRangeFilter;

export const getData = (state) => state[prefix].data || {};

export const getIsReady = createSelector(
  getData,
  ({
    youtube_streamer,
    twitch_streamer,
    facebook_page,
    instagram_user,
    twitter,
  }) =>
    !!(youtube_streamer && youtube_streamer.channel_id) ||
    !!(twitch_streamer && twitch_streamer.login) ||
    !!(facebook_page && facebook_page.id) ||
    !!(instagram_user && instagram_user.id) ||
    !!(twitter && twitter.username)
);

export const getYoutubeStreamer = createSelector(
  getData,
  ({ youtube_streamer }) => youtube_streamer || {}
);

export const getTwitchStreamer = createSelector(
  getData,
  ({ twitch_streamer }) => twitch_streamer || {}
);

export const getFacebookPage = createSelector(
  getData,
  ({ facebook_page }) => facebook_page || {}
);

export const getInstagramUser = createSelector(
  getData,
  ({ instagram_user }) => instagram_user || {}
);

export const getTwitterProfile = createSelector(
  getData,
  ({ twitter }) => twitter || {}
);

export const getTopBrands = (state) => state[prefix].topBrands;

export const getTwitchAudienceBehaviour = createSelector(
  getData,
  ({ twitch_streamer }) =>
    (twitch_streamer &&
      twitch_streamer.behaviour &&
      twitch_streamer.behaviour) ||
    []
);

export const getYoutubeAudienceBehaviour = createSelector(
  getData,
  ({ youtube_streamer }) =>
    (youtube_streamer &&
      youtube_streamer.behaviour &&
      youtube_streamer.behaviour) ||
    []
);

export const getPlayedGames = createSelector(
  getData,
  ({ played_games }) => played_games || []
);

export const getEngagementData = createSelector(
  getData,
  ({ engagement_data }) => engagement_data
);
