import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import SectionTitle from '../../../../components/SectionTitle';
import { InfluencerInfo } from '../../store/types';
import { InfluencerOverviewPerformance } from './InfluencerOverviewPerformance';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    title: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 16,
    },
  },
}));

export const Overview = (props: InfluencerInfo) => {
  const classes = useStyles();
  const {
    onboarding_facebook_pages,
    onboarding_instagram_pages,
    onboarding_tiktok_pages,
    onboarding_twitch_pages,
    onboarding_twitter_pages,
    onboarding_youtube_pages,
  } = props;

  return (
    <Grid container direction="column" wrap="nowrap" spacing={4}>
      <Grid item>
        <SectionTitle className={classes.title}>
          Channels Performance
        </SectionTitle>
      </Grid>
      <InfluencerOverviewPerformance
        platform={'twitch'}
        platformValue={onboarding_twitch_pages}
      />
      <InfluencerOverviewPerformance
        platform={'twitter'}
        platformValue={onboarding_twitter_pages}
      />
      <InfluencerOverviewPerformance
        platform={'instagram'}
        platformValue={onboarding_instagram_pages}
      />
      <InfluencerOverviewPerformance
        platform={'facebook'}
        platformValue={onboarding_facebook_pages}
      />
      <InfluencerOverviewPerformance
        platform={'tiktok'}
        platformValue={onboarding_tiktok_pages}
      />
      <InfluencerOverviewPerformance
        platform={'youtube'}
        platformValue={onboarding_youtube_pages}
      />
      <InfluencerOverviewPerformance
        platform={'Facebook Gaming'}
        platformValue={onboarding_facebook_pages}
      />
    </Grid>
  );
};
