import { Box } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FavoriteIcon from '@material-ui/icons/Favorite';
import InstagramIcon from '@material-ui/icons/Instagram';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonIcon from '@material-ui/icons/Person';
import ShareIcon from '@material-ui/icons/Share';
import TwitterIcon from '@material-ui/icons/Twitter';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useMemo } from 'react';

import facebookIcon from '../../../../assets-2.0/facebook.svg';
import tiktokIcon from '../../../../assets-2.0/tiktok.svg';
import CampaignCreatorAndDayFilter from '../../CampaignCreatorAndDayFilter';
import DetailedViewToggle, {
  useDetailedViewToggle,
} from '../../components/DetailedViewToggle';
import OverviewCard from '../../components/OverviewCard';
import SectionTitle from '../../components/SectionTitle';
import { StatBox } from '../../Table';
import { getTrackedPlatforms } from '../../utils';
import DetailedTable from './DetailedTable';
import SummaryTable, { computeSummary } from './SummaryTable';

const computeOverview = ({
  instagramPosts,
  twitterPosts,
  tiktokPosts,
  facebookPosts,
}) => {
  const creators = new Set(
    instagramPosts
      .concat(twitterPosts)
      .concat(tiktokPosts)
      .concat(facebookPosts)
      .map((post) => post.influencer_id)
  ).size;

  const {
    mentions,
    twitterMentions,
    instagramMentions,
    tiktokMentions,
    facebookMentions,
    likes,
    comments,
    totalEngagements,
    totalImpressions,
    totalEMV,
    impressionsAreEst,
    shares,
  } = computeSummary({
    instagramPosts,
    twitterPosts,
    tiktokPosts,
    facebookPosts,
  });

  return {
    shares,
    creators,
    mentions,
    twitterMentions,
    instagramMentions,
    tiktokMentions,
    facebookMentions,
    likes,
    comments,
    totalEngagements,
    totalImpressions,
    totalEMV,
    impressionsAreEst,
  };
};

const Overview = React.memo(
  ({
    keyword,
    instagramPosts,
    twitterPosts,
    tiktokPosts,
    facebookPosts,
    platformFilter,
    usedInfluencers,
  }) => {
    const {
      creators,
      mentions,
      twitterMentions,
      instagramMentions,
      tiktokMentions,
      facebookMentions,
      likes,
      comments,
      totalEngagements,
      totalImpressions,
      totalEMV,
      impressionsAreEst,
      shares,
    } = computeOverview({
      instagramPosts,
      twitterPosts,
      tiktokPosts,
      facebookPosts,
    });

    const trackedPlatforms = useMemo(
      () => getTrackedPlatforms(usedInfluencers),
      [usedInfluencers]
    );

    return (
      <div>
        <SectionTitle>
          <b>{keyword}</b> Social Media Mentions
        </SectionTitle>
        <OverviewCard.Container>
          <OverviewCard
            label="Creators"
            color="teal"
            main={{ stat: creators }}
            icon={<PersonIcon />}
            outOf={usedInfluencers.length}
            id={`sponsorshipVal-socialmentions-creators-${keyword.replace(
              /\s+/g,
              ''
            )}`}
          />
          <OverviewCard
            label="Mentions"
            color="blue"
            main={{ stat: mentions }}
            icon={<ChatBubbleIcon />}
            secondaryComponent={
              platformFilter === 'all' ? (
                <Box>
                  {trackedPlatforms.twitter && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <StatBox stat={twitterMentions} />
                      <TwitterIcon
                        style={{ marginLeft: 8, color: '#1DA1F2', width: 24 }}
                      />
                    </Box>
                  )}
                  {trackedPlatforms.instagram && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <StatBox stat={instagramMentions} />
                      <InstagramIcon
                        style={{ marginLeft: 8, color: '#DD2A7B', width: 24 }}
                      />
                    </Box>
                  )}
                  {trackedPlatforms.tiktok && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <StatBox stat={tiktokMentions} />
                      <img
                        src={tiktokIcon}
                        alt="TikTok Icon"
                        style={{ marginLeft: 8, color: '#DD2A7B', width: 24 }}
                      />
                    </Box>
                  )}
                  {trackedPlatforms.facebook && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <StatBox stat={facebookMentions} />
                      <img
                        src={facebookIcon}
                        alt="Facebook Icon"
                        style={{ marginLeft: 8, color: '#DD2A7B', width: 24 }}
                      />
                    </Box>
                  )}
                </Box>
              ) : null
            }
            id={`sponsorshipVal-socialmentions-mentions-${keyword.replace(
              /\s+/g,
              ''
            )}`}
          />
          <OverviewCard
            label="Engagements"
            color="orange"
            main={{ stat: totalEngagements }}
            icon={<FavoriteIcon />}
            secondaryComponent={
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <StatBox stat={comments} />
                  <ChatBubbleIcon
                    style={{
                      marginLeft: 8,
                      color: '#777',
                      transform: 'scaleX(-1)',
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <StatBox stat={likes} />
                  <FavoriteIcon style={{ marginLeft: 8, color: '#FF5C26' }} />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <StatBox stat={shares} />
                  <ShareIcon style={{ marginLeft: 8, color: '#777' }} />
                </Box>
              </Box>
            }
            id={`sponsorshipVal-socialmentions-enganments-${keyword.replace(
              /\s+/g,
              ''
            )}`}
          />
          <OverviewCard
            label={impressionsAreEst ? 'Est. Impressions' : 'Impressions'}
            color="purple"
            main={{ stat: totalImpressions }}
            icon={<VisibilityIcon />}
            id={`sponsorshipVal-socialmentions-impressions-${keyword.replace(
              /\s+/g,
              ''
            )}`}
          />
          <OverviewCard
            label="Media value"
            color="green"
            main={{ stat: totalEMV, prefix: '$', format: '0,[0].[0]a' }}
            icon={<LocalAtmIcon />}
            id={`sponsorshipVal-socialmentions-mediavalue-${keyword.replace(
              /\s+/g,
              ''
            )}`}
          />
        </OverviewCard.Container>
      </div>
    );
  }
);

export const SocialMentionDeliverable = React.memo(
  ({
    socialMentionDeliverable,
    usedInfluencers,
    timeZone,
    platformFilter,
    setSelectedCampaignInfluencer,
    isSponsorship,
    isFilteredByCreator,
    setByCreator,
    setSocialPostDelete,
  }) => {
    const { detailed, setDetailed } = useDetailedViewToggle(usedInfluencers);
    const {
      instagramPosts,
      twitterPosts,
      tiktokPosts,
      facebookPosts,
      keyword,
    } = socialMentionDeliverable;

    return (
      <div style={{ paddingBottom: 100 }} id={`social-media/${keyword}`}>
        <Overview
          {...socialMentionDeliverable}
          usedInfluencers={usedInfluencers}
          platformFilter={platformFilter}
        />
        {instagramPosts.length +
          twitterPosts.length +
          tiktokPosts.length +
          facebookPosts.length >
          0 && (
          <>
            <DetailedViewToggle
              {...{ detailed, setDetailed, usedInfluencers }}
            />
            {isSponsorship && !detailed && (
              <CampaignCreatorAndDayFilter
                setByCreator={setByCreator}
                isFilteredByCreator={isFilteredByCreator}
              />
            )}

            {!detailed && (
              <SummaryTable
                usedInfluencers={usedInfluencers}
                twitterPosts={twitterPosts}
                instagramPosts={instagramPosts}
                tiktokPosts={tiktokPosts}
                facebookPosts={facebookPosts}
                platformFilter={platformFilter}
                setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
                isSponsorship={isSponsorship}
                isFilteredByCreator={isFilteredByCreator}
                timeZone={timeZone}
                keyword={keyword}
              />
            )}
            {detailed && (
              <DetailedTable
                timeZone={timeZone}
                usedInfluencers={usedInfluencers}
                twitterPosts={twitterPosts}
                instagramPosts={instagramPosts}
                tiktokPosts={tiktokPosts}
                facebookPosts={facebookPosts}
                keyword={keyword}
                platformFilter={platformFilter}
                setSocialPostDelete={setSocialPostDelete}
                isSponsorship={isSponsorship}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default SocialMentionDeliverable;
