import { SocialMediaOverview, SocialMediaPost } from './types';

export const fakePosts: SocialMediaPost[] = [
  {
    comments_count: 7812,
    engagements_count: 0,
    external_id: '123',
    id: 1,
    impressions_count: 98134,
    likes_count: 5123,
    page_external_id: '123',
    permalink: '',
    reactions_count: null,
    shares_count: 34,
    text: 'MY BEST GAME THIS CHAPTER??!',
    owner_name: 'auronplay',
    owner_avatar:
      'https://static-cdn.jtvnw.net/jtv_user_pictures/6342dd1b-3c2c-41dd-9d01-35c5c6660ef9-profile_image-300x300.png',
    published_at: '30th Nov 2021, 07:00 PM',
    emv: 1423,
    image: null,
    platform: 'twitter',
    attachments: [],
  },
  {
    comments_count: 7812,
    engagements_count: 0,
    external_id: '223',
    id: 2,
    impressions_count: 98134,
    likes_count: 300,
    page_external_id: '223',
    permalink: '',
    reactions_count: null,
    shares_count: null,
    text: 'a day well spent!!!',
    owner_name: 'auronplay',
    owner_avatar:
      'https://static-cdn.jtvnw.net/jtv_user_pictures/6342dd1b-3c2c-41dd-9d01-35c5c6660ef9-profile_image-300x300.png',
    published_at: '25th Nov 2021, 05:34 PM',
    emv: 5423,
    image: null,
    platform: 'instagram',
    attachments: [],
  },
  {
    comments_count: 812,
    engagements_count: 0,
    external_id: '323',
    id: 3,
    impressions_count: 8134,
    likes_count: 300,
    page_external_id: '323',
    permalink: '',
    reactions_count: null,
    shares_count: null,
    text: 'Care to share? how you all feeling about the new netflix series Something something',
    owner_name: 'auronplay',
    owner_avatar:
      'https://static-cdn.jtvnw.net/jtv_user_pictures/6342dd1b-3c2c-41dd-9d01-35c5c6660ef9-profile_image-300x300.png',
    published_at: '24th Nov 2021, 09:40 PM',
    emv: 1423,
    image: null,
    platform: 'facebook',
    attachments: [],
  },
  {
    comments_count: 812,
    engagements_count: 0,
    external_id: '423',
    id: 4,
    impressions_count: 8134,
    likes_count: 300,
    page_external_id: '423',
    permalink: '',
    reactions_count: null,
    shares_count: null,
    text: 'The best way to complete this mission!!',
    owner_name: 'auronplay',
    owner_avatar:
      'https://static-cdn.jtvnw.net/jtv_user_pictures/6342dd1b-3c2c-41dd-9d01-35c5c6660ef9-profile_image-300x300.png',
    published_at: '18th Nov 2021, 10:24 PM',
    emv: 1423,
    image: null,
    platform: 'tiktok',
    attachments: [],
  },
];

export const fakeSocialOverview: SocialMediaOverview = {
  emv: 43920,
  engagements_count: 345812,
  facebook_count: 1,
  impressions: 2751314,
  instagram_count: 3,
  likes_count: 14830,
  replies_cmnts_count: 18914,
  retweets_shares_count: 234,
  tiktok_count: 1,
  total_count: 11,
  twitter_count: 6,
};
