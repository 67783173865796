import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';

import { LoadingState } from '../../../types';
import {
  deleteMultipleShoutouts,
  deleteShoutout,
  fetchShoutoutDeliverable,
} from './ShoutoutDeliverables.thunks';
import { LoadingStateMap, ShoutoutDeliverable, SLICE_NAME } from './types';

const shoutoutDeliverablesAdapter = createEntityAdapter<ShoutoutDeliverable>({
  // move shoutout ids that have no shoutouts to end
  sortComparer: (a, b) => {
    return a.id - b.id;
  },
});

export const initialState = shoutoutDeliverablesAdapter.getInitialState<{
  loadingMap: LoadingStateMap;
  error: SerializedError | null;
}>({ loadingMap: {}, error: null });

const shoutoutDeliverablesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetCampaignShoutouts: (state, action: PayloadAction<number>) => {
      const idsToRemove = state.ids.filter(
        (id) => state.entities[id].campaign_id === action.payload
      );

      // reset loading state
      for (const id of idsToRemove) {
        state.loadingMap[id] = LoadingState.IDLE;
      }

      // remove data
      shoutoutDeliverablesAdapter.removeMany(state, idsToRemove);
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch deliverables reducers
      .addCase(fetchShoutoutDeliverable.pending, (state, action) => {
        state.loadingMap[action.meta.arg.shoutoutDeliverableId] =
          LoadingState.PENDING;
        state.error = null;
      })
      .addCase(fetchShoutoutDeliverable.fulfilled, (state, action) => {
        state.loadingMap[action.meta.arg.shoutoutDeliverableId] =
          LoadingState.FULLFILLED;
        state.error = null;

        shoutoutDeliverablesAdapter.addOne(state, action.payload);
      })
      .addCase(fetchShoutoutDeliverable.rejected, (state, action) => {
        state.loadingMap[action.meta.arg.shoutoutDeliverableId] =
          LoadingState.REJECTED;
        state.error = action.error;
      })
      // delete shoutout reducers
      .addCase(deleteShoutout.pending, (state, action) => {
        const { shoutoutDeliverableId, shoutoutId } = action.meta.arg;

        const deliverable = state.entities[shoutoutDeliverableId];

        // filter out deleted shoutout
        deliverable.shoutouts = deliverable.shoutouts.filter(
          (shoutout) => shoutout.id !== shoutoutId
        );

        state.entities[shoutoutDeliverableId] = deliverable;
      })
      .addCase(deleteMultipleShoutouts.fulfilled, (state, action) => {
        const { shoutoutDeliverableId, shoutoutIds } = action.meta.arg;

        const deliverable = shoutoutDeliverablesAdapter
          .getSelectors()
          .selectById(state, shoutoutDeliverableId);
        const newShoutouts = deliverable.shoutouts.filter(
          (shoutout) => !shoutoutIds.includes(shoutout.id)
        );

        shoutoutDeliverablesAdapter.updateOne(state, {
          id: shoutoutDeliverableId,
          changes: {
            shoutouts: newShoutouts,
          },
        });
      });
  },
});

export const {
  reducer,
  actions: { resetCampaignShoutouts },
} = shoutoutDeliverablesSlice;

export { fetchShoutoutDeliverable, deleteShoutout };
