import {
  Button,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import _find from 'lodash/find';
import { useHistory } from 'react-router-dom';

import facebookGamingIcon from '../../../../assets-2.0/facebookGaming.png';
import facebookIcon from '../../../../assets-2.0/images-influencer/facebook.svg';
import instagramIcon from '../../../../assets-2.0/instagram.svg';
import tiktokIcon from '../../../../assets-2.0/tiktok.svg';
import twitchIcon from '../../../../assets-2.0/twitch.svg';
import twitterIcon from '../../../../assets-2.0/twitter.svg';
import youtubeIcon from '../../../../assets-2.0/youtube.svg';
import { PROFILE } from '../../../../common/constants/paths';
import StatBox from '../../../../components/StatBox';
import { PlatformName, SocialPage } from '../../store/types';
import { OverviewPerformanceFacebook } from './OverviewPerformancePlatforms/OverviewPerformanceFacebook';
import { OverviewPerformanceFacebookGaming } from './OverviewPerformancePlatforms/OverviewPerformanceFacebookGaming';
import { OverviewPerformanceInstagram } from './OverviewPerformancePlatforms/OverviewPerformanceInstagram';
import { OverviewPerformanceTikTok } from './OverviewPerformancePlatforms/OverviewPerformanceTikTok';
import { OverviewPerformanceTwitch } from './OverviewPerformancePlatforms/OverviewPerformanceTwitch';
import { OverviewPerformanceTwitter } from './OverviewPerformancePlatforms/OverviewPerformanceTwitter';
import { OverviewPerformanceYouTube } from './OverviewPerformancePlatforms/OverviewPerformanceYouTube';

const useStyles = makeStyles((theme) => ({
  statsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '0 10px',
    marginTop: 18,
  },
  text: {
    color: 'black',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    minWidth: 125,
    minHeight: 33,
    textTransform: 'capitalize',
  },
  iconImage: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  allPlatform: {
    padding: theme.spacing(2),
  },
  locked: {
    '& .lockedValue': {
      textShadow: '0px 1px 2px rgba(0,0,0,0.2)',
      filter: 'blur(5px)',
    },
  },
  connectProfile: {
    display: 'flex',
    '& button': {
      fontSize: 12,
      borderRadius: '10px',
    },
    '& a': {
      marginLeft: 10,
    },
  },
  buttonConnectProfile: {
    color: 'white',
  },
  comingSoon: {
    border: '3px solid #69ddff!important',

    color: 'black!important',
  },
  boxInfo: {
    '& div:only-child': {
      display: 'flex',
      flexDirection: 'column-reverse',
      width: 'max-content',
      alignItems: 'self-start',
      '& div:first-child': {
        fontSize: '30px !important',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px !important',
        },
      },
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: 20,
    },
  },
  [theme.breakpoints.only('lg')]: {
    statsContainer: { gridTemplateColumns: 'repeat(4, 1fr)' },
  },
  [theme.breakpoints.only('md')]: {
    statsContainer: { gridTemplateColumns: 'repeat(3, 1fr)' },
  },
  [theme.breakpoints.down('sm')]: {
    statsContainer: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    allPlatform: {
      padding: '16px 0px',
    },
    card: {
      padding: '16px 12px',
    },
    text: {
      minWidth: 95,
      fontSize: 14,
    },
    connectProfile: {
      '& button': { fontSize: 8, padding: '6px 6px' },
    },
  },
}));

const ALL_PLATFORMS = [
  { name: 'twitch', icon: twitchIcon, colorText: '#5a3e85' },
  { name: 'youtube', icon: youtubeIcon, colorText: '#e60707' },
  {
    name: 'Facebook Gaming',
    icon: facebookGamingIcon,
    colorText: '#005af0',
  },
  { name: 'facebook', icon: facebookIcon, colorText: '#325a9c' },
  { name: 'instagram', icon: instagramIcon, colorText: '#eb0089' },
  { name: 'tiktok', icon: tiktokIcon, colorText: '#010101' },
  { name: 'twitter', icon: twitterIcon, colorText: '#6fbbf7' },
];

interface IBoxInfo {
  label: string;
  stat: number;
  info: string;
  format?: string;
  prefix?: string;
  suffix?: string;
}

interface IPlatformInfo {
  platform: PlatformName;
  platformValue: Array<SocialPage>;
}

export const BoxInfo = ({
  label,
  stat,
  info,
  format,
  prefix,
  suffix,
}: IBoxInfo) => {
  const classes = useStyles();
  const props = {
    label,
    stat,
    format,
    prefix,
    info,
    suffix,
    disableTooltip: true,
  };
  return (
    <div className={classes.boxInfo}>
      <StatBox {...props} />
    </div>
  );
};

const Icon = ({ namePlatform }) => {
  const classes = useStyles();
  const icon = _find(
    ALL_PLATFORMS,
    (platform) => platform.name === namePlatform
  );
  return (
    <Typography
      variant="h4"
      style={{ color: icon.colorText }}
      className={classes.text}
    >
      <img src={icon.icon} className={classes.iconImage} alt={icon.name} />
      {icon.name}
    </Typography>
  );
};

export const InfluencerOverviewPerformance = ({
  platform,
  platformValue,
}: IPlatformInfo) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid className={classes.allPlatform}>
      <Card className={classes.card}>
        <CardContent>
          {platformValue && platformValue.length ? (
            <Icon namePlatform={platform} />
          ) : (
            <div className={classes.connectProfile}>
              <Icon namePlatform={platform} />
              <Button
                className={classes.buttonConnectProfile}
                color="primary"
                variant="contained"
                onClick={() => history.push(`${PROFILE}?socialCallback=true`)}
              >
                Connect to your {platform} account to unlock
              </Button>
            </div>
          )}

          <div
            className={clsx(
              classes.statsContainer,
              platformValue && platformValue.length === 0 && classes.locked
            )}
          >
            {platformValue && platform === 'twitch' && (
              <OverviewPerformanceTwitch {...platformValue[0]} />
            )}
            {platformValue && platform === 'twitter' && (
              <OverviewPerformanceTwitter {...platformValue[0]} />
            )}

            {platformValue && platform === 'instagram' && (
              <OverviewPerformanceInstagram {...platformValue[0]} />
            )}

            {platformValue && platform === 'facebook' && (
              <OverviewPerformanceFacebook {...platformValue[0]} />
            )}

            {platformValue && platform === 'tiktok' && (
              <OverviewPerformanceTikTok {...platformValue[0]} />
            )}
            {platformValue && platform === 'youtube' && (
              <OverviewPerformanceYouTube {...platformValue[0]} />
            )}
            {platformValue && platform === 'Facebook Gaming' && (
              <OverviewPerformanceFacebookGaming {...platformValue[0]} />
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};
