import { compose, withHandlers, withState } from 'recompose';

import {
  getCampaignOnboardingForPageName,
  getTwitterToken,
  postTwitterCampaignOnboarding,
} from '../../../../ajax';
import { ONBOARDING_TWITTER } from '../../../../common/constants/paths';
import { Dialog } from '../../../../common/containers';
import {
  redirectTwitch,
  redirectTwitter,
  redirectYoutube,
} from '../../../../helpers/influencer-login';

export default compose(
  withState('errors', 'setErrors', []),
  withState('onboardingCompleted', 'setOnboardingCompleted', false),
  withState('onboardingInProgress', 'setOnboardingInProgress', false),
  withState('onboardingCompanies', 'setOnboardingCompanies', []),
  withHandlers({
    redirectTwitch,
    redirectYoutube,
    loginTwitter:
      ({ match }) =>
      async () => {
        getTwitterToken(`${ONBOARDING_TWITTER}/success`).then((response) => {
          const { oauth_token } = response;

          redirectTwitter(oauth_token);
        });
      },
    completeTwitterOnboarding:
      ({ setOnboardingInProgress, setOnboardingCompleted }) =>
      async (oauthToken, oauthVerifier) => {
        postTwitterCampaignOnboarding(oauthToken, oauthVerifier)
          .then((response) => {
            setOnboardingInProgress(false);
            setOnboardingCompleted(true);
          })
          .catch((errors) => {
            let message;
            if (errors.status === 500) {
              message = errors.bodyJson && errors.bodyJson.error;
            } else {
              message = errors.bodyJson && errors.bodyJson['errors'].join(', ');
            }

            Dialog.show({
              caption: 'Error',
              message,
              buttons: ['Got it'],
            });
          });
      },
    getOnboardingCampaignData:
      ({ match, setOnboardingCompanies }) =>
      async () => {
        const {
          params: { pageName },
        } = match;

        getCampaignOnboardingForPageName(pageName, 'twitter').then(
          (response) => {
            setOnboardingCompanies(response);
          }
        );
      },
  })
);
