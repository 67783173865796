import { IconButton } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Link,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  MoreHoriz as MoreHorizIcon,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const RENAME = 'RENAME';
const DELETE = 'DELETE';

const StyledListItem = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(7),
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    width: 'unset',
    '&.Mui-selected': {
      background: '#EBEBEB',
    },
  },
}))(ListItem);

const useStyles = makeStyles((theme) => ({
  listItemText: {
    margin: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  link: {
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: 16,
  },
  menuItem: {
    paddingLeft: theme.spacing(0.5),
  },
  iconButton: {
    marginRight: 5,
  },
  styledListItem: {
    marginLeft: 50,
  },
}));

const RenameWatchlistDialog = ({ open, watchlist, onCancel, onRename }) => {
  const [name, setName] = useState(watchlist.name);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      onExited={() => setName(watchlist.name)}
    >
      <DialogTitle>Rename watchlist</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>Watchlist name</FormLabel>
          <OutlinedInput
            placeholder="List 1"
            name="watchlistName"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: 320 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => name.trim().length > 0 && onRename(name)}
          color="primary"
          disabled={name.trim().length === 0 || name === watchlist.name}
          variant="contained"
        >
          Rename
        </Button>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteWatchlistDialog = ({ open, watchlist, onCancel, onDelete }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Delete watchlist</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete '{watchlist.name}'? This action cannot
          be reversed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onDelete}>
          Delete
        </Button>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const WatchlistItem = ({
  watchlist,
  onRename,
  onDelete,
  watchlistsLength,
  id,
}) => {
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [hovered, setHovered] = useState(false);

  const [visibleDialog, setVisibleDialog] = useState(false);

  const { pathname } = useLocation();

  const href = `/my-watchlist/${watchlist.id}`;

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget.parentElement);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (item) => () => {
    handleMenuClose();
    setVisibleDialog(item);
  };

  const closeDialog = handleMenuItemClick(null);

  return (
    <>
      <RenameWatchlistDialog
        watchlist={watchlist}
        open={visibleDialog === RENAME}
        onCancel={closeDialog}
        onRename={(name) => {
          closeDialog();
          onRename(name);
        }}
      />
      <DeleteWatchlistDialog
        watchlist={watchlist}
        open={visibleDialog === DELETE}
        onCancel={closeDialog}
        onDelete={() => {
          closeDialog();
          onDelete();
        }}
      />

      <div
        data-id={`watchlist-${id}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <StyledListItem
          className={classes.styledListItem}
          button
          selected={href === pathname}
        >
          <Link
            component={RouterLink}
            underline="none"
            color="inherit"
            className={classes.link}
            to={href}
            title={watchlist.name}
          >
            <Typography className={classes.listItemText}>
              {watchlist.name}
            </Typography>
          </Link>
          <ListItemSecondaryAction>
            {hovered ? (
              <IconButton
                size="small"
                className={classes.iconButton}
                onClick={handleMenuClick}
                edge="end"
              >
                <MoreHorizIcon />
              </IconButton>
            ) : (
              <span style={{ marginRight: 10 }}>{watchlist.influencers}</span>
            )}
          </ListItemSecondaryAction>
        </StyledListItem>
      </div>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={!!menuAnchorEl}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={handleMenuItemClick(RENAME)}
          dense
          className={classes.menuItem}
          data-id={`rename-watchlist-${id}`}
        >
          <EditIcon
            data-id={`my-watchlist-edit-icon-${id}`}
            fontSize="small"
            className={classes.icon}
          />{' '}
          Rename
        </MenuItem>
        {watchlistsLength > 1 && (
          <MenuItem
            onClick={handleMenuItemClick(DELETE)}
            dense
            className={classes.menuItem}
            data-id={`delete-watchlist-${id}`}
          >
            <DeleteOutlineIcon
              data-id={`my-watchlist-delete-icon-${id}`}
              className={classes.icon}
            />{' '}
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default WatchlistItem;
