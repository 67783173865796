import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import { FilterList } from '@material-ui/icons';
import React, { ReactComponentElement, ReactElement } from 'react';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const contentIsEmpty = (content: ReactElement) => {
  return (
    !content.props.children ||
    content.props.children.length === 0 ||
    content.props.children.every((child: ReactComponentElement<any>) => !child)
  );
};

export const FiltersDrawer = ({ content }: { content: ReactElement }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (contentIsEmpty(content)) {
    return null;
  }

  return (
    <div>
      <Button color="primary" onClick={handleClick} startIcon={<FilterList />}>
        Filters
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div style={{ minWidth: 360, padding: 24, minHeight: 200 }}>
          {content}
        </div>
      </StyledMenu>
    </div>
  );
};
