import _maxBy from 'lodash/maxBy';

export const enhanceInfluencerData = (influencers) => {
  const maxMatchScore =
    influencers.length > 0
      ? _maxBy(influencers, (i) => i.match_score || 0).match_score
      : 1;

  const maxTargetScore =
    influencers.length > 0
      ? _maxBy(influencers, (i) => i.target_score || 0).target_score
      : 1;

  influencers.forEach((inf) => {
    const { network, total_watch_time_days_30, stream_time_days_30 } = inf;

    inf.ama_days_30 = null;
    inf.yt_engagement_90 = null;

    inf.match_score = inf.match_score / maxMatchScore; // normalize such that the largest match score is always 100%
    inf.target_score = inf.target_score / maxTargetScore; // normalize such that the largest target score is always 100%

    if (network === 'twitch') {
      inf.ama_days_30 = calculateAMA(
        total_watch_time_days_30,
        stream_time_days_30
      );
    }

    if (network === 'youtube') {
      inf.yt_engagement_90 = calculateYoutubeEngagement(inf);
    }
  });
};

export const calculateAMA = (total_watch_time, stream_time) => {
  if (stream_time > 0) {
    return total_watch_time / 60 / (stream_time / 60);
  } else {
    return 0;
  }
};

export const calculateYoutubeEngagement = (youtubeStreamer) => {
  if (
    youtubeStreamer.subscriber_count > 0 &&
    youtubeStreamer.video_count_90 > 0
  ) {
    return (
      (youtubeStreamer.view_count_90 +
        youtubeStreamer.likes_90 +
        youtubeStreamer.dislikes_90) /
      youtubeStreamer.video_count_90 /
      youtubeStreamer.subscriber_count
    );
  } else {
    return 0;
  }
};
