import { Avatar, Box, makeStyles, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import cx from 'classnames';
import momentTz from 'moment-timezone';
import React from 'react';

import facebookGamingLogo from '../../../assets-2.0/facebookGaming.svg';
import thumbnailPlaceholder from '../../../assets-2.0/thumbnailPlaceholder.png';
import tiktokLogo from '../../../assets-2.0/white-logos/tiktok.png';
import twitchLogo from '../../../assets-2.0/white-logos/twitch.svg';
import youtubeLogo from '../../../assets-2.0/youtube-white.svg';

const ICONS = {
  youtube: youtubeLogo,
  twitch: twitchLogo,
  tiktok: tiktokLogo,
  tiktok_video: tiktokLogo,
  facebook_gaming: facebookGamingLogo,
};
const COLORS = {
  youtube: '#F5423E',
  twitch: '#6D4CA8',
  tiktok: '#000',
  tiktok_video: '#000',
  facebook_gaming: '#2A62FF',
};

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    position: 'relative',
    marginRight: 16,
  },
  networkContainer: {
    borderRadius: 4,
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: 'translate(25%, 25%)',
    height: 20,
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  networkLogo: {
    width: '66%',
  },
  videoPreviewContainer: {
    display: 'grid',
    marginBottom: 32,
    gridTemplateColumns: '4fr 1fr',
  },
  thumbnailContainer: {
    display: 'grid',
    placeItems: 'center',
  },
  thumbnail: {
    height: '100%',
    maxWidth: '100%',
    maxHeight: '120px',
    background: '#bdbdbd',
  },
  bannerThumbnail: {
    minWidth: 400,
  },
  link: {
    marginLeft: 20,
  },
}));

export const VideoPreview = ({
  video,
  influencer,
  timeZone,
  momentUrl = null,
  timeOnScreen = null,
  bannerThumbnail = null,
}) => {
  const classes = useStyles();

  const videoTime = momentTz.tz(video.published_at, timeZone);

  const startTime = videoTime.format('ddd, MMMM Do YYYY, h:mma');
  const endTime = videoTime
    .add(video.duration, 'seconds')
    .format('ddd, MMMM Do YYYY, h:mma z');

  const duration = momentTz.duration(video.duration, 'seconds');
  //date any
  const durationToHHMMSS = momentTz(
    `2022-01-01 ${duration.hours()}:${duration.minutes()}:${duration.seconds()}`
  ).format('HH:mm:ss');

  const getPlatformName = (video_resource) => {
    if (video_resource === 'facebook_gaming') {
      return 'FACEBOOK GAMING';
    }

    if (video_resource === 'tiktok_video') {
      return 'TIKTOK';
    }

    return video_resource.toUpperCase();
  };

  const thumbnail =
    bannerThumbnail ||
    (video.thumbnails &&
      video.thumbnails.default &&
      video.thumbnails.default.url) ||
    video.thumbnails?.original;

  return (
    <div className={classes.videoPreviewContainer}>
      <Box pr={2}>
        <Box display="flex" alignItems="center" pb={2}>
          <div className={classes.avatarContainer}>
            <Avatar src={influencer.avatar} />
            <div
              className={classes.networkContainer}
              style={{
                background: COLORS[video.resource],
              }}
            >
              <img
                className={classes.networkLogo}
                alt={video.resource}
                src={ICONS[video.resource]}
              />
            </div>
          </div>
          <Typography style={{ fontWeight: 'bold' }}>
            {influencer.name}
          </Typography>
        </Box>
        <Typography>{video.video_title}</Typography>
        <Typography>
          <strong>Time:</strong> {startTime} - {endTime}
        </Typography>
        <Typography>
          <strong>Stream duration:</strong> {durationToHHMMSS}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography>
            <strong>Voice mention timestamp:</strong> {timeOnScreen}
          </Typography>

          <a
            href={momentUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={`show-on-hover ${classes.link}`}
          >
            <OpenInNewIcon fontSize="inherit" style={{ marginRight: 4 }} />
            <span>VIEW ON {getPlatformName(video.resource)}</span>
          </a>
        </Box>
      </Box>
      <div className={classes.thumbnailContainer}>
        <img
          src={thumbnail || thumbnailPlaceholder}
          alt="video thumbnail"
          className={cx(classes.thumbnail, {
            [classes.bannerThumbnail]: !!bannerThumbnail,
          })}
        />
      </div>
    </div>
  );
};

export default VideoPreview;
