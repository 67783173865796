import {
  CREATE_WATCHLIST_FAILED,
  CREATE_WATCHLIST_REQUESTED,
  CREATE_WATCHLIST_SUCCEEDED,
  DELETE_WATCHLIST_FAILED,
  DELETE_WATCHLIST_REQUESTED,
  DELETE_WATCHLIST_SUCCEEDED,
  RENAME_WATCHLIST_FAILED,
  RENAME_WATCHLIST_REQUESTED,
  RENAME_WATCHLIST_SUCCEEDED,
  WATCHLISTS_REQUEST_FAILED,
  WATCHLISTS_REQUEST_SUCCEEDED,
  WATCHLISTS_REQUESTED,
} from '../../action-types';
import createAction from '../../create-action';

export const watchlistsRequested = createAction(WATCHLISTS_REQUESTED);
export const watchlistsRequestSucceeded = createAction(
  WATCHLISTS_REQUEST_SUCCEEDED
);
export const watchlistsRequestFailed = createAction(WATCHLISTS_REQUEST_FAILED);

export const renameWatchlistRequested = createAction(
  RENAME_WATCHLIST_REQUESTED
);
export const renameWatchlistSucceeded = createAction(
  RENAME_WATCHLIST_SUCCEEDED
);
export const renameWatchlistFailed = createAction(RENAME_WATCHLIST_FAILED);

export const deleteWatchlistRequested = createAction(
  DELETE_WATCHLIST_REQUESTED
);
export const deleteWatchlistSucceeded = createAction(
  DELETE_WATCHLIST_SUCCEEDED
);
export const deleteWatchlistFailed = createAction(DELETE_WATCHLIST_FAILED);

export const createWatchlistRequested = createAction(
  CREATE_WATCHLIST_REQUESTED
);
export const createWatchlistSucceeded = createAction(
  CREATE_WATCHLIST_SUCCEEDED
);
export const createWatchlistFailed = createAction(CREATE_WATCHLIST_FAILED);
