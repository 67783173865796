export const selectSearchInfluencers = ({ searchInfluencers: { list } }) =>
  list;

export const selectSearchGameIdList = ({ searchInfluencers: { gameIdList } }) =>
  gameIdList;

export const selectSearchPageIndex = ({ searchInfluencers: { pageIndex } }) =>
  pageIndex;

export const selectSearchFetchingNextPage = ({
  searchInfluencers: { fetchingNextPage },
}) => fetchingNextPage;

export const selectSearchHasNextPage = ({
  searchInfluencers: { hasNextPage },
}) => hasNextPage;

export const selectSearchQuery = ({ searchInfluencers: { query } }) => query;

export const selectTotalResults = ({ searchInfluencers: { totalResults } }) =>
  totalResults;
