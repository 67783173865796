import {
  Box,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

import StatBox from '../../../components/StatBox';

const useStyles = makeStyles(() => ({
  card: {
    marginLeft: 12,
    marginBottom: 12,
  },

  placeholderContent: {
    height: 143,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 16,
  },
}));

export const colors = {
  blue: '#0048f2',
  lightBlue: '#32A9F2',
  teal: '#14b8a4',
  orange: '#ee8434',
  purple: '#733a99',
  green: '#24c866',
  black: '#000000',
  grey: '#6C969D',
  red: '#EE6055',
};

interface OverviewCardProps {
  label: string;
  color: keyof typeof colors;
  icon: JSX.Element;
  main: any; // Props of <StatBox />
  secondary?: any; // Props of <StatBox />
  info: string;
  outOf: string | number;
  secondaryComponent: JSX.Element;
  id: string;
}

export const OverviewCard = ({
  label,
  color,
  icon,
  main,
  secondary,
  secondaryComponent,
  info,
  outOf,
  id,
}: Partial<OverviewCardProps>) => {
  const classes = useStyles();
  const cardColor = colors[color] || color;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="flex-start"
        >
          <Typography>
            {label}
            {info && (
              <Tooltip placement="top" title={info}>
                <IconButton size="small">
                  <InfoOutlinedIcon
                    style={{ width: '0.65em', height: '0.65em' }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
          <Box
            width={38}
            height={38}
            borderRadius="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            style={{ overflow: 'hidden', color: cardColor, fontSize: 24 }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: cardColor,
                opacity: 0.2,
              }}
            />
            {icon}
          </Box>
        </Box>
        <Box
          display="flex"
          mt={2}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <StatBox id={id} size="lg" noBold color="#444444" {...main} />
            {outOf && (
              <span
                style={{ marginBottom: 3, fontSize: '1.5rem', color: '#888' }}
              >
                /{outOf}
              </span>
            )}
          </div>
          {secondaryComponent}
          {secondary && (
            <StatBox color={cardColor} size="overviewCard" {...secondary} />
          )}
          {!secondaryComponent && !secondary && <Box />}
        </Box>
      </CardContent>
    </Card>
  );
};

OverviewCard.colors = colors;

const useContainerStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 16,
    display: 'grid',
    marginLeft: -12,
    gridTemplateColumns: 'repeat(6, 1fr)',
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}));

export const OverviewCardsContainer = (props) => {
  const classes = useContainerStyles();

  return <div className={classes.container} {...props} />;
};

OverviewCard.Container = OverviewCardsContainer;

const PlaceholderOverviewCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.placeholderContent}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="flex-start"
        >
          <Skeleton variant="rect" width={128} height={24} />
          <Skeleton variant="circle" width={38} height={38} />
        </Box>
        <Skeleton variant="rect" width={150} height={24} />
        <Skeleton variant="rect" width={100} height={24} />
      </CardContent>
    </Card>
  );
};

interface PlaceholderOverviewRowProps {
  nCards: number;
}

export const PlaceholderOverviewRow = React.memo<PlaceholderOverviewRowProps>(
  ({ nCards }) => (
    <OverviewCardsContainer data-id="loading-overview-cards">
      {Array(nCards)
        .fill(0)
        .map((_, idx) => (
          <PlaceholderOverviewCard key={idx} />
        ))}
    </OverviewCardsContainer>
  )
);

export default OverviewCard;
