import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  circularNumber: {
    color: 'black',
    fontSize: '2.1rem',
    textAlign: 'center',
  },
});

const CircularProgressWithLabel = (props) => {
  const classes = useStyles();
  return (
    <Box sx={{ position: props.position, display: 'inline-flex' }}>
      <CircularProgress
        style={{ height: 142, width: 142, color: props.color }}
        className={classes.circularProgress}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {props.progress && (
            <>
              <Typography className={classes.circularNumber}>
                {`${Math.round(props.value)}%`}
              </Typography>
              <Typography style={{ fontSize: 16, color: '#666666' }}>
                Completed
              </Typography>
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
