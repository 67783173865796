import moment from 'moment';

const validateDatesArray = (datesArray: string[]): boolean => {
  if (datesArray.length === 0) {
    return false;
  }

  const validationResultsArray = datesArray.map((date) => {
    return moment(date).isValid();
  });

  return !validationResultsArray.includes(false);
};

export default validateDatesArray;
