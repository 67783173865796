import { Stream, Video } from './types';

export const streamsToVideos = (streams: Stream[]): Video[] => {
  return streams.map((stream) => {
    return {
      published_at: stream.started_at || stream.published_at,
      id: stream.id,
      avg_cc_viewers: stream.avg_ccv,
      max_cc_viewers: stream.peak_ccv,
      total_views: stream.views_count,
      computed_total_views: stream.views_count,
      video_title: stream.title,
      resource: stream.platform,
      followers_gained: stream.followers,
      duration: stream.duration * 60, // convert to seconds, temporary until backend returns duration in seconds
      minutes_watched_by_audience: stream.minutes_watched,
      video_segments: stream.video_segments,
      comments_count: stream.comments_count,
      video_type: getVideoType(stream.video_type),
      likes_count: stream.likes_count,

      // TODO: The below properties are currently not available for Streams - to be updated and used once backend adds support for some of them
      link: null,
      emv: null,
      engagement_rate: null,
      campaign_influencer_ids: null,
      sentiment: null,
      time_zone: 'Europe/London',
      thumbnails: null,
      influencer_id: null,
      campaign_id: null,
      language: null,
      video_followers: null,
      streamer_id: null,
      external_id: null,
      live_chat_count: null,
      is_live: null,
      recording_link: null,
    };
  });
};

const getVideoType = (
  streamVideoType: Stream['video_type']
): Video['video_type'] => {
  if (!streamVideoType || !streamVideoType.length) {
    return null;
  }

  if (
    streamVideoType.includes('live_stream') &&
    streamVideoType.includes('vod')
  ) {
    return 'live-vod';
  }

  if (streamVideoType.includes('live_stream')) {
    return 'live';
  }

  if (streamVideoType.includes('vod')) {
    return 'vod';
  }

  if (streamVideoType.includes('video')) {
    return 'video';
  }
};
