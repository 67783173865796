import { persistReducer } from 'redux-persist';
import persistStorage from 'redux-persist/lib/storage';
import { createActions, createReducer } from 'reduxsauce';

export const STORAGE_PREFIX = 'config';

export const INITIAL_STATE = {
  loggedInView: 'company', // options - 'company', 'influencer'
};

const { Types, Creators } = createActions(
  {
    setLoggedInViewCompany: [],
    setLoggedInViewInfluencer: [],
  },
  {
    prefix: `${STORAGE_PREFIX}/`,
  }
);

/* reducers */

export const setLoggedInViewCompany = (state) => {
  return { ...state, loggedInView: 'company' };
};

export const setLoggedInViewInfluencer = (state) => {
  return { ...state, loggedInView: 'influencer' };
};

export const HANDLERS = {
  [Types.SET_LOGGED_IN_VIEW_COMPANY]: setLoggedInViewCompany,
  [Types.SET_LOGGED_IN_VIEW_INFLUENCER]: setLoggedInViewInfluencer,
};

const persistConfig = {
  key: STORAGE_PREFIX,
  storage: persistStorage,
};

export const reducer = persistReducer(
  persistConfig,
  createReducer(INITIAL_STATE, HANDLERS)
);
export const actionTypes = Types;
export default Creators;
