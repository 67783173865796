import {
  BRAND_CREATION_FAILED,
  BRAND_CREATION_REQUESTED,
  BRAND_CREATION_SUCCEEDED,
  BRAND_DELETE_FAILED,
  BRAND_DELETE_REQUESTED,
  BRAND_DELETE_SUCCEEDED,
  BRAND_UPDATE_FAILED,
  BRAND_UPDATE_REQUESTED,
  BRAND_UPDATE_SUCCEEDED,
  BRANDS_REQUEST_FAILED,
  BRANDS_REQUEST_SUCCEEDED,
  BRANDS_REQUESTED,
} from '../../action-types';
import createAction from '../../create-action';

export const myBrandsRequested = createAction(BRANDS_REQUESTED, {
  request: {
    name: 'brands',
    type: 'requested',
  },
});

export const myBrandsRequestSucceeded = createAction(BRANDS_REQUEST_SUCCEEDED, {
  request: {
    type: 'succeeded',
    name: `brands`,
  },
});

export const myBrandsRequestFailed = createAction(BRANDS_REQUEST_FAILED, {
  request: {
    name: 'brands',
    type: 'failed',
  },
});
export const brandCreationRequested = createAction(BRAND_CREATION_REQUESTED, {
  request: {
    name: 'brand-creation',
    type: 'requested',
  },
});

export const brandCreationSucceeded = createAction(BRAND_CREATION_SUCCEEDED, {
  request: {
    name: 'brand-creation',
    type: 'succeeded',
  },
  toast: {
    message: 'Brand created successfully',
    type: 'success',
    lifespan: 4000,
  },
});

export const brandCreationFailed = (payload) => ({
  type: BRAND_CREATION_FAILED,
  payload,
  meta: {
    request: {
      name: 'brand-creation',
      type: 'failed',
    },
    toast: {
      message: payload.error,
      type: 'error',
      lifespan: 4000,
    },
  },
});

export const brandUpdateRequested = createAction(BRAND_UPDATE_REQUESTED, {
  request: {
    name: 'brand-update',
    type: 'requested',
  },
});

export const brandUpdateSucceeded = createAction(BRAND_UPDATE_SUCCEEDED, {
  request: {
    name: 'brand-update',
    type: 'succeeded',
  },
  toast: {
    message: 'Brand updated successfully',
    type: 'success',
    lifespan: 4000,
  },
});

export const brandUpdateFailed = (payload) => ({
  type: BRAND_UPDATE_FAILED,
  payload,
  meta: {
    request: {
      name: 'brand-update',
      type: 'failed',
    },
    toast: {
      message: payload.error,
      type: 'error',
      lifespan: 4000,
    },
  },
});

export const myBrandDeleteRequested = (payload) => ({
  type: BRAND_DELETE_REQUESTED,
  payload,
  meta: {
    request: {
      type: 'requested',
      name: `myBrand-delete-${payload.id}`,
    },
  },
});

export const myBrandDeleteSucceeded = (payload) => ({
  type: BRAND_DELETE_SUCCEEDED,
  payload,
  meta: {
    request: {
      type: 'succeeded',
      name: `myBrand-delete-${payload.id}`,
    },
    toast: {
      message: 'The brand was successfully deleted',
      type: 'success',
      lifespan: 4000,
    },
  },
});

export const myBrandDeleteFailed = (payload) => ({
  type: BRAND_DELETE_FAILED,
  payload,
  meta: {
    request: {
      type: 'failed',
      name: `myBrand-delete-${payload.id}`,
    },
  },
});
