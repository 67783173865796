import { useMediaQuery, useTheme } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PeopleIcon from '@material-ui/icons/People';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimerIcon from '@material-ui/icons/Timer';
import VideocamIcon from '@material-ui/icons/Videocam';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _pickBy from 'lodash/pickBy';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  OverviewCard,
  PlaceholderOverviewRow,
} from '../../../../components/OverviewCard/OverviewCard';
import { OverviewCardPlatformsStack } from '../../../../components/OverviewCard/OverviewCardPlatformsStack';
import EyeIcon from '../../../Campaign/components/EyeIcon';
import PointerIcon from '../../../Campaign/components/PointerIcon';
import { SectionTitle } from '../../../Campaign/components/SectionTitle';
import { selectInfluencerHasStreamingAccounts } from '../../store/InfluencerInfo.selectors';
import { fakeStreamsOverview } from './store/fakeData';
import {
  selectStreamsOverview,
  selectStreamsPlatformFilter,
} from './store/Streams.selectors';

export const StreamingOverview = ({
  isLoading,
  bluredData,
}: {
  isLoading: boolean;
  bluredData: boolean;
}) => {
  const theme = useTheme();
  const size = useMediaQuery(theme.breakpoints.down('sm')) ? 'sm' : null;

  const hasStreamingAccounts = useSelector(
    selectInfluencerHasStreamingAccounts()
  );

  const streamsOverview = useSelector(selectStreamsOverview());
  const platformFilter = useSelector(selectStreamsPlatformFilter());

  const isLocked = !hasStreamingAccounts || bluredData;

  const overviewData = isLocked ? fakeStreamsOverview : streamsOverview;

  if (isLoading) return <PlaceholderOverviewRow nCards={9} />;

  const {
    avgCCV,
    comments,
    emv,
    engagement,
    followersGained,
    hrsStreamed,
    hrsWatched,
    streamsCount,
    totalViews,
    streamsCountByPlatform,
    peakCCV,
  } = overviewData;

  return (
    <div>
      <SectionTitle>Video performance</SectionTitle>
      <OverviewCard.Container>
        <OverviewCard
          label="Streams"
          color="orange"
          main={{ stat: streamsCount }}
          icon={<VideocamIcon />}
          id={`myStats-streaming-streams`}
          size={size}
          secondaryComponent={
            platformFilter === 'all' ? (
              <OverviewCardPlatformsStack
                counts={{
                  ..._pickBy(streamsCountByPlatform, (count) => count > 0),
                }}
              />
            ) : null
          }
          isLocked={isLocked}
        />
        <OverviewCard
          label="Hours watched"
          color="blue"
          main={{ stat: hrsWatched }}
          icon={<ScheduleIcon />}
          id={`myStats-streaming-hrswtch`}
          size={size}
          isLocked={isLocked}
        />
        <OverviewCard
          label="Duration hrs."
          color="red"
          main={{ stat: hrsStreamed }}
          icon={<TimerIcon />}
          id={`myStats-streaming-hrsstrmd`}
          isLocked={isLocked}
          size={size}
        />
        <OverviewCard
          label="Followers Gained"
          color="grey"
          main={{ stat: followersGained }}
          icon={<PeopleIcon />}
          size={size}
          isLocked={isLocked}
        />

        <OverviewCard
          label="Comments"
          color="black"
          main={{ stat: comments }}
          icon={<ChatBubbleIcon />}
          info="The total number of comments posted by the channel's viewers on the live chat and video comments section."
          id={`myStats-streaming-comments`}
          size={size}
          isLocked={isLocked}
        />
        <OverviewCard
          label="Engagement rate"
          color="purple"
          main={{ stat: engagement, suffix: '%' }}
          icon={<PointerIcon />}
          info="The engagement rate measures the number of interactions (comments, likes, dislikes) relative to the number of views."
          id={`myStats-streaming-engagement-rate`}
          size={size}
          isLocked={isLocked}
        />
        <OverviewCard
          label="Average CCV"
          color="purple"
          main={{ stat: avgCCV }}
          secondary={{
            label: 'peak',
            stat: peakCCV,
            info: 'The peak number of concurrent viewers who watched the stream live. To calculate this number we are checking the number of viewers every minute during the stream.',
          }}
          icon={<EyeIcon />}
          info="The average number of concurrent viewers who watched the stream live. To calculate this number we are checking the number of viewers every minute during the stream."
          id={`myStats-streaming-averageCCV`}
          size={size}
          isLocked={isLocked}
        />
        <OverviewCard
          label="Total views"
          color="purple"
          main={{ stat: totalViews }}
          icon={<VisibilityIcon />}
          info="The sum of live views and offline VOD views."
          id={`myStats-streaming-ttlvws`}
          size={size}
          isLocked={isLocked}
        />
        <OverviewCard
          label="Media value"
          color="green"
          main={{ stat: emv, prefix: '$', format: '0,[0].[0]a' }}
          icon={<LocalAtmIcon />}
          info="We are valuing the price of impressions against a standardized CPM. We don't add any multipliers to EMV values based on perceived emotional values of engagements or actions as these can lead to inflated values."
          id={`myStats-streaming-mediavalue`}
          size={size}
          isLocked={isLocked}
        />
      </OverviewCard.Container>
    </div>
  );
};
