import { Box, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form, useFormikContext, withFormik } from 'formik';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import qs from 'query-string';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withHandlers } from 'recompose';
import { compose } from 'redux';
import { array, date, object, string } from 'yup';

import * as paths from '../../common/constants/paths';
import { getUserOrganizationRestrictions } from '../../common/containers/Account/selectors';
import Container from '../../components/Container';
import LoadingButton from '../../components/LoadingButton';
import TopBar from '../../components/TopBar';
// import CampaignWizardKPIs from './CampaignWizardKPIs';
import usePrevious from '../../hooks/use-previous';
import {
  brandsMounted,
  campaignCreationFormSubmitted,
  campaignCreationStepAdvanced,
} from '../../store/events';
import { selectCampaignById } from '../../store/models/campaigns/campaigns.selectors';
import { selectCommunication } from '../../store/models/communication/communication.selectors';
import { selectMyBrands } from '../../store/models/my-brands/my-brands.selectors';
import { getTrialAccount } from '../../store/models/user/user.selectors';
import getImageSize from '../../utils/getImageSize';
import { searchIdenticalUsers } from '../../utils/searchIdenticalUsers';
import toObject from '../../utils/to-object';
import validateDatesArray from '../../utils/validateDatesArray';
import CampaignWizardBasicInformation from './CampaignWizardBasicInformation';
import CampaignWizardDeliverables from './CampaignWizardDeliverables';
import CampaignWizardInfluencers, {
  addToggles,
} from './CampaignWizardInfluencers';
import CampaignWizardSponsorshipValuation from './CampaignWizardSponsorshipValuation';
// import CampaignWizardBrands from './CampaignWizardBrands';
import CampaignWizardStepper from './CampaignWizardStepper';
import CampaignWizardTrackingOptions from './CampaignWizardTrackingOptions';
import { createEmptyInfluencer, generateInfluencerToken } from './types';

const EMPTY_INFLUENCER = createEmptyInfluencer();

const useStyles = makeStyles({
  container: {
    padding: '94px 48px 0 48px',
    maxWidth: 960,
    margin: 'auto',
    overflow: 'unset',
  },
  scrollContainer: {
    overflow: 'auto',
    padding: 0,
  },
});

export const CampaignWizardContext = createContext({});

const mapPropsToValues = ({
  location: { search },
  campaign,
  organizationRestrictions,
}) => {
  const values = {
    campaignName: '',
    newEndDate: moment().add(1, 'days').format('MMM DD, YYYY'),
    newStartDate: moment().format('MMM DD, YYYY'),
    campaignDays: [
      moment().format('YYYY-MM-DD'),
      moment().add(1, 'day').format('YYYY-MM-DD'),
    ].sort(),
    campaignDaysFrame: [
      moment().format('YYYY-MM-DD'),
      moment().add(1, 'day').format('YYYY-MM-DD'),
    ].sort(),
    isScheduleDateType: false,
    campaignTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    campaignGoal: '',
    campaignTypes: [1, 3],
    brands: [],
    brandId: [],
    brandsClone: false,
    influencersSocials: [
      search !== '' &&
        !campaign && {
          influencerYoutubeUrl: '',
          influencerTwitchUrl: '',
          influencerTwitterUrl: '',
          influencerFacebookUrl: '',
          influencerInstagramUrl: '',
          influencerTiktokUrl: '',
          influencerFacebookGamingUrl: '',
          ...addToggles(qs.parse(search)),
        },
    ].filter((v) => !!v),
    brandsSocials: [
      {
        brandTwitterUrl: '',
        brandInstagramUrl: '',
      },
    ],
    shoutoutChecked: false,
    shoutoutContent: '',
    shoutouts: [],
    socialMediaMentionChecked: false,
    socialMediaMentionContent: [],
    createSocialMediaMentionContent: '',
    bannerChecked: false,
    bannerImages: [
      {
        bannerFile: undefined,
        bannerUrl: '',
        bannerUrlTemp: '',
      },
    ],
    imageChecked: false,
    imageUrl: '',
    linkSharingChecked: false,
    links: [],
    newLink: '',
    checked: false,
    kpiEngagementChecked: true,
    kpiActivityChecked: true,
    kpiKeywordMentionChecked: false,
    kpiKeywordMentionList: [],
    createKpiKeywordMentionList: '',
  };
  if (campaign) {
    values.brandsClone = true;
    values.campaignName = `Copy of ${campaign.name}`;
    values.campaignTimezone = campaign.time_zone;
    values.campaignTypes = campaign.campaign_types.map((el) => {
      if (el.campaign_type === 'performance') {
        return 1;
      } else if (el.campaign_type === 'media_tracking') {
        return 3;
      } else {
        return 2;
      }
    });
    values.influencersSocials = campaign.campaign_influencers.map((inf) => ({
      profile_image_url: inf.avatar,
      display_name: inf.name,
      token: generateInfluencerToken(),
      ...addToggles({
        influencerYoutubeUrl: inf.youtube_url || '',
        influencerTwitchUrl: inf.twitch_url || '',
        influencerTwitterUrl: inf.twitter_url || '',
        influencerFacebookUrl: inf.facebook_url || '',
        influencerInstagramUrl: inf.instagram_url || '',
        influencerTiktokUrl: inf.tiktok_url || '',
        influencerFacebookGamingUrl: inf.facebook_gaming_url || '',
      }),
    }));

    if (campaign.campaign_brands.length > 0)
      values.brandsSocials = campaign.campaign_brands.map((b) => ({
        brandTwitterUrl: b.twitter_url || '',
        brandInstagramUrl: b.instagram_url || '',
      }));

    values.kpiEngagementChecked = campaign.kpi_engagement;
    values.kpiActivityChecked = campaign.kpi_activity;
    values.kpiKeywordMentionChecked =
      campaign.keyword_mention_deliverables.filter(
        (sht) => !sht.campaign_brand_id
      ).length > 0;
    values.kpiKeywordMentionList = campaign.keyword_mention_deliverables
      .filter((sht) => !sht.campaign_brand_id)
      .map((d) => d.keyword);

    const { ui_disable_shoutouts, ui_disable_banners } =
      organizationRestrictions;

    if (!ui_disable_shoutouts) {
      values.shoutoutChecked =
        campaign.all_shoutout_deliverables.filter(
          (sht) => !sht.campaign_brand_id
        ).length > 0;
      values.shoutouts = campaign.all_shoutout_deliverables
        .filter((sht) => !sht.campaign_brand_id)
        .map(
          ({
            v2,
            name,
            content,
            inclusion_terms,
            exclusion_terms,
            structured,
            id,
          }) => {
            if (!v2)
              return {
                id,
                structured: false,
                name: content,
                inclusionTerms: [content],
                exclusionTerms: [],
              };

            return {
              id,
              structured,
              name,
              inclusionTerms: inclusion_terms,
              exclusionTerms: exclusion_terms,
            };
          }
        );
    }

    const socialDeliverables = campaign.social_media_mention_deliverables.length
      ? campaign.social_media_mention_deliverables.filter(
          (s) => !s.campaign_brand_id
        )
      : [];

    values.socialMediaMentionChecked = socialDeliverables.length > 0;
    values.socialMediaMentionContent = Array.from(
      new Set(socialDeliverables.map((d) => d.keyword))
    );

    if (
      campaign.link_tracking_deliverables.filter(
        (sht) => !sht.campaign_brand_id
      ).length > 0
    ) {
      values.linkSharingChecked = true;
      values.links = campaign.link_tracking_deliverables
        .filter((s) => !s.campaign_brand_id)
        .map((d) => d.link);
    }

    if (
      !ui_disable_banners &&
      campaign.banner_deliverables.filter((sht) => !sht.campaign_brand_id)
        .length > 0
    ) {
      values.bannerChecked = true;
      values.bannerImages = [
        ...campaign.banner_deliverables
          .filter((sht) => !sht.campaign_brand_id)
          .map((d) => ({
            bannerUrl: d.image,
            allowSmallSize: true,
            initialBannerDeliverableId: d.id,
          })),
        ...values.bannerImages,
      ];
    }
  }

  if (campaign?.campaign_brands.length > 0) {
    values.brands = campaign?.campaign_brands.map((el) => ({
      brandName: el.brand_name,
      id: el.organization_brand_id,
      shoutouts: campaign.all_shoutout_deliverables
        .filter((sht) => sht.campaign_brand_id === el.id)
        .map(
          ({
            v2,
            name,
            content,
            inclusion_terms,
            exclusion_terms,
            structured,
            id,
          }) => {
            if (!v2)
              return {
                id,
                structured: false,
                name: content,
                inclusionTerms: [content],
                exclusionTerms: [],
              };

            return {
              id,
              structured,
              name,
              inclusionTerms: inclusion_terms,
              exclusionTerms: exclusion_terms,
            };
          }
        ),
      shoutoutContent: '',
      shoutoutChecked:
        campaign.all_shoutout_deliverables.filter(
          (sht) => sht.campaign_brand_id === el.id
        ).length > 0,
      bannerImages: [
        ...campaign.banner_deliverables
          .filter((b) => b.campaign_brand_id === el.id)
          .map((d) => ({
            bannerUrl: d.image,
            allowSmallSize: true,
            initialBannerDeliverableId: d.id,
            campaign_brand_id: d.campaign_brand_id,
          })),
        {
          bannerFile: undefined,
          bannerUrl: '',
          bannerUrlTemp: '',
        },
      ],
      imageUrl: '',
      bannerChecked:
        campaign.banner_deliverables.filter(
          (sht) => sht.campaign_brand_id === el.id
        ).length > 0,
      checked: false,
      links: campaign.link_tracking_deliverables
        .filter((sht) => sht.campaign_brand_id === el.id)
        .map((d) => d.link),
      newLink: '',
      linkSharingChecked:
        campaign.link_tracking_deliverables.filter(
          (sht) => sht.campaign_brand_id === el.id
        ).length > 0,
      kpiKeywordMentionList: campaign.keyword_mention_deliverables
        .filter((sht) => sht.campaign_brand_id === el.id)
        .map((d) => d.keyword),
      createKpiKeywordMentionList: '',
      kpiKeywordMentionChecked:
        campaign.keyword_mention_deliverables.filter(
          (sht) => sht.campaign_brand_id === el.id
        ).length > 0,
      socialMediaMentionContent: campaign.social_media_mention_deliverables
        .filter((s) => s.campaign_brand_id === el.id)
        .map((d) => d.keyword),
      createSocialMediaMentionContent: '',
      socialMediaMentionChecked:
        campaign.social_media_mention_deliverables.filter(
          (s) => s.campaign_brand_id === el.id
        ).length > 0,
    }));
  }

  return values;
};
const validateCampaign = async (values, props) => {
  const {
    influencersSocials,
    brandsSocials,
    kpiKeywordMentionChecked,
    kpiKeywordMentionList,
    socialMediaMentionChecked,
    socialMediaMentionContent,
    bannerChecked,
    bannerImages,
    brands,
  } = values;

  let error = {};

  if (socialMediaMentionChecked && !socialMediaMentionContent) {
    error.socialMediaMentionContent = 'Please add the content';
  }

  if (kpiKeywordMentionChecked && !kpiKeywordMentionList) {
    error.kpiKeywordMentionList = 'Please add a keyword';
  }

  error.brandsSocials = [];
  brandsSocials.forEach((brand, index) => {
    error.brandsSocials[index] = {};

    if (
      !!brand.brandInstagramUrl &&
      (!brand.brandInstagramUrl.toLowerCase().match(/instagram.com\/(\S+)/) ||
        !!brand.brandInstagramUrl
          .toLowerCase()
          .match(/instagram.com\/p\/(\S+)/))
    ) {
      error.brandsSocials[index].brandInstagramUrl =
        'Is not a valid url. Should include instagram.com and not /p/';
    }

    if (
      !!brand.brandTwitterUrl &&
      !brand.brandTwitterUrl.toLowerCase().match(/twitter.com\/(\S+)/)
    ) {
      error.brandsSocials[index].brandTwitterUrl =
        'Is not a valid url. Must Include: twitter.com';
    }
  });

  if (
    error.brandsSocials.filter((value) => Object.keys(value).length !== 0)
      .length === 0
  ) {
    delete error.brandsSocials;
  }

  error.influencersSocials = [];
  influencersSocials.forEach((unfilteredInfluencer, index) => {
    error.influencersSocials[index] = {};

    const influencer = Object.keys(unfilteredInfluencer)
      .filter(
        (k) => unfilteredInfluencer[`${k}Checked`] && k in EMPTY_INFLUENCER
      ) // allow only platorm urls that are checked
      .reduce((prev, k) => {
        prev[k] = unfilteredInfluencer[k];

        return prev;
      }, {});

    if (Object.keys(influencer).length === 0) {
      error.influencersSocials[index] =
        'You must add and check at least one platform';

      return;
    }

    if (
      !!influencer.influencerInstagramUrl &&
      (!influencer.influencerInstagramUrl
        .toLowerCase()
        .match(/instagram.com\/(\S+)/) ||
        !!influencer.influencerInstagramUrl
          .toLowerCase()
          .match(/instagram.com\/p\/(\S+)/))
    ) {
      error.influencersSocials[index].influencerInstagramUrl =
        'Is not a valid url. Should include instagram.com and not /p/';
    }
    if (
      !!influencer.influencerFacebookUrl &&
      !influencer.influencerFacebookUrl
        .toLowerCase()
        .match(/facebook.com\/(\S+)/) &&
      !influencer.influencerFacebookUrl.toLowerCase().match(/fb.me\/(\S+)/)
    ) {
      error.influencersSocials[index].influencerFacebookUrl =
        'Is not a valid url. Should include facebook.com or fb.me';
    }

    if (
      !!influencer.influencerFacebookGamingUrl &&
      !influencer.influencerFacebookGamingUrl
        .toLowerCase()
        .match(/facebook.com\/(\S+)/) &&
      !influencer.influencerFacebookGamingUrl
        .toLowerCase()
        .match(/fb.me\/(\S+)/)
    ) {
      error.influencersSocials[index].influencerFacebookGamingUrl =
        'Is not a valid url. Should include facebook.com or fb.me';
    }

    if (
      !!influencer.influencerTwitterUrl &&
      !influencer.influencerTwitterUrl.toLowerCase().match(/twitter.com\/(\S+)/)
    ) {
      error.influencersSocials[index].influencerTwitterUrl =
        'Is not a valid url. Must Include: twitter.com';
    }

    if (
      !!influencer.influencerTiktokUrl &&
      !influencer.influencerTiktokUrl.toLowerCase().match(/tiktok.com\/(\S+)/)
    ) {
      error.influencersSocials[index].influencerTiktokUrl =
        'Is not a valid url. Must Include: tiktok.com';
    }

    if (
      !!influencer.influencerTwitchUrl &&
      !influencer.influencerTwitchUrl.toLowerCase().match(/twitch.tv\/(\S+)/)
    ) {
      error.influencersSocials[index].influencerTwitchUrl =
        'Is not a valid url. Must Include: twitch.tv';
    }

    if (
      !!influencer.influencerYoutubeUrl &&
      !influencer.influencerYoutubeUrl
        .toLowerCase()
        .match(/youtube.com\/[A-Z0-9._%+-]*/)
    ) {
      error.influencersSocials[index].influencerYoutubeUrl =
        'Is not a valid url. Must Include: youtube.com/*';
    }
  });

  if (
    error.influencersSocials.filter((value) => Object.keys(value).length !== 0)
      .length === 0
  ) {
    delete error.influencersSocials;
  }

  if (influencersSocials.length === 0)
    error.influencersSocials = 'You need to add at least one creator';

  if (brands.find((brand) => brand.bannerChecked === true)) {
    error.brandsBannerImages = await Promise.all(
      brands.map(async (brand) => {
        const arrayBannerErrors = [];

        for (let banner of brand.bannerImages) {
          if (
            (!banner.bannerUrl && !banner.bannerFile) ||
            banner.allowSmallSize
          ) {
            arrayBannerErrors.push(null);
          } else {
            const { width, height } = await getImageSize(
              banner.bannerUrl && banner.bannerUrl.length > 0
                ? banner.bannerUrl
                : banner.bannerFile
            );

            if (width < 60 || height < 60)
              arrayBannerErrors.push('Image has to be at least 60x60 pixels.');
          }
        }
        return arrayBannerErrors;
      })
    );

    error.brandsBannerImages.forEach((brandBannerImage, index) => {
      if (brandBannerImage.every((e) => e === null))
        error.brandsBannerImages[index] = null;
    });

    if (error.brandsBannerImages.every((e) => e === null))
      delete error.brandsBannerImages;
  }

  if (bannerChecked) {
    error.bannerImages = await Promise.all(
      bannerImages.map(async (banner) => {
        if ((!banner.bannerUrl && !banner.bannerFile) || banner.allowSmallSize)
          return null;
        const { width, height } = await getImageSize(
          banner.bannerUrl && banner.bannerUrl.length > 0
            ? banner.bannerUrl
            : banner.bannerFile
        );

        if (width < 60 || height < 60)
          return 'Image has to be at least 60x60 pixels.';

        return null;
      })
    );

    if (error.bannerImages.every((e) => e === null)) delete error.bannerImages;
  }

  return error;
};

const mapStateToProps = (state, ownProps) => ({
  brands: selectMyBrands(state),
  trialAccount: getTrialAccount(state),
  organizationRestrictions: getUserOrganizationRestrictions(state),
  account: state.account,
  campaign: selectCampaignById(
    parseInt(qs.parse(ownProps.location.search).clone)
  )(state),
  // trialCampaignsExceeded: getTrialAccountCampaignsExceeded(state),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, null),
  withFormik({
    mapPropsToValues,
    validationSchema: object().shape({
      campaignName: string().required('Choose a name for your campaign'),
      newEndDate: date().required(),
      newStartDate: date().required(),
      // newLink: string().matches(regex.URL, 'Not a valid url'),
      shoutoutContent: array(),
      createShoutoutContent: string(),
      influencersSocials: array(),
      brandsSocials: array(),
    }),
    handleSubmit: (unclonedValues, bag) => {
      const values = _cloneDeep(unclonedValues);

      values.influencersSocials = values.influencersSocials.map((influencer) =>
        Object.keys(influencer)
          .filter((k) => !!influencer[`${k}Checked`] || k === 'token')
          .reduce((prev, k) => {
            prev[k] = influencer[k];

            return prev;
          }, {})
      );

      bag.props.dispatch(campaignCreationFormSubmitted({ values }));
    },
    validateOnChange: true,
    validate: validateCampaign,
  }),
  withHandlers({
    onNextStep:
      ({ dispatch }) =>
      async (step, values) => {
        dispatch(campaignCreationStepAdvanced({ step, ...values }));
      },
  })
);

function CampaignWizard(props) {
  const { step: stepParam } = props.match.params;
  const { brands } = props;
  const { values, setFieldValue, errors } = useFormikContext();
  const [performanceFlag, setPerformanceFlag] = useState(true);
  const [SVFlag, setSVFlag] = useState(false);
  const [mediaFlag, setMediaFlag] = useState(true);
  useEffect(function () {
    props.dispatch(brandsMounted());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const arrayErrors = useMemo(
    () => searchIdenticalUsers(values.influencersSocials),
    [values.influencersSocials]
  );

  // function getRange(startDate, endDate, type) {
  //   let fromDate = moment(startDate);
  //   let toDate = moment(endDate);
  //   let diff = toDate.diff(fromDate, type);
  //   let range = [];
  //   for (let i = 0; i <= diff; i++) {
  //     range.push(moment(startDate).add(i, type).format('YYYY-MM-DD'));
  //   }
  //   return range.sort();
  // }
  // useEffect(() => {
  //   let arr = getRange(
  //     values.campaignDays[0],
  //     values.campaignDays[values.campaignDays.length - 1],
  //     'days'
  //   );
  //   setFieldValue('campaignDays', arr);
  // }, [values.isScheduleDateType]);

  const STEP_PARAMS = [
    'basic',
    'creators',
    'options',
    ((values?.campaignTypes.length > 0 && values?.campaignTypes.includes(2)) ||
      SVFlag) &&
      'sponsorship',
    ((values?.campaignTypes.length > 0 && values?.campaignTypes.includes(3)) ||
      mediaFlag) &&
      'deliverables',
  ];
  const steps = [
    {
      component: CampaignWizardBasicInformation,
      title: 'Basic information',
      subtitle: 'Add a name and choose the time frame for your campaign',
      fields: ['campaignName', 'campaignGoal'],
      flag: true,
    },
    {
      component: CampaignWizardInfluencers,
      title: 'Content creator',
      subtitle: 'Add the creator you are collaborating with in this campaign',
      fields: ['influencersSocials'],
      flag: true,
    },
    {
      component: CampaignWizardTrackingOptions,
      title: 'Tracking options',
      subtitle: 'Choose what to track for this campaign',
      fields: ['trackingOptionsSocial'],
      flag: true,
    },
    {
      component: CampaignWizardSponsorshipValuation,
      title: 'Sponsorship Valuation',
      subtitle: 'Add Brands to be tracked in this campaign',
      fields: ['SVSocials'],
      flag:
        (values?.campaignTypes.length > 0 &&
          values?.campaignTypes.includes(2)) ||
        SVFlag,
    },

    // {
    //   component: CampaignWizardBrands,
    //   title: 'Brand',
    //   subtitle:
    //     "Add your brand links to track social media account's growth in this campaign",
    //   fields: ['brandsSocials'],
    // },
    // {
    //   component: CampaignWizardKPIs,
    //   title: 'Channel Performance',
    //   subtitle:
    //     'Monitor the performance of the creator’s channels during the campaign',
    //   fields: ['kpiEngagementChecked', 'kpiActivityChecked'],
    // },
    {
      component: CampaignWizardDeliverables,
      title: 'Media tracking',
      subtitle: 'Add the media items to be tracked in this campaign.',
      fields: [
        'shoutoutChecked',
        'shoutoutContent',
        'createShoutoutContent',
        'bannerChecked',
        'bannerImages',
        'imageChecked',
        'imageUrl',
        'linkSharingChecked',
        'linkSharingAddress',
        'socialMediaMentionChecked',
        'socialMediaMentionContent',
        'kpiKeywordMentionChecked',
        'kpiKeywordMentionList',
      ],
      flag:
        (values?.campaignTypes.length > 0 &&
          values?.campaignTypes.includes(3)) ||
        mediaFlag,
      // props: {
      //   saveBrands() {
      //     // setFieldValue('outstandingBrand', {
      //     //   ...brands,
      //     //   [brandId]: brandData
      //     // })
      //   }
      // }
    },
  ];
  const filterStepParams = STEP_PARAMS.filter((item) => item !== false);
  let filterSteps = steps.filter((item) => item.flag === true);

  const step = Math.max(0, filterStepParams.indexOf(stepParam));
  const setStep = (newStep) => {
    if (newStep === step) props.history.goBack();

    props.history.push(`${paths.TRACK_CAMPAIGN}/${filterStepParams[newStep]}`);
  };
  const {
    trialAccount,
    // trialCampaignsExceeded,
    account: { authUser },
    organizationRestrictions,
  } = props;

  function goToPreviousStep() {
    setStep(step - 1);
  }
  function goToNextStep() {
    props.validateForm(props.values).then((errors) => {
      const isValid = Object.keys(errors).reduce(
        (isValid, key) =>
          isValid && filterSteps[step].fields.indexOf(key) === -1,
        true
      );

      if (isValid) {
        props.onNextStep(filterSteps[step].title, props.values);
        setStep(step + 1);
      } else {
        setAllTouched();
      }
    });
  }

  function setAllTouched() {
    props.setTouched({
      ...props.touched,
      ...toObject(
        (field) => field,
        () => true
      )(filterSteps[previousStep].fields),
    });
  }

  const previousStep = usePrevious(step);

  useEffect(() => {
    if (previousStep < step) {
      setAllTouched();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    // if (trialCampaignsExceeded) { history.push(CAMPAIGNS) }
    if (trialAccount) {
      setFieldValue('kpiAudienceReactionChecked', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trialAccount]);

  const campaignCreationCommunication = useSelector(
    selectCommunication('campaign-creation')
  );

  const classes = useStyles();

  const campaignWizardContext = {
    step,
    setStep,
    goToNextStep,
    goToPreviousStep,
  };

  // Check if the next button should be disabled based on current step
  const isNextDisabled = (step) => {
    const {
      campaignName,
      isScheduleDateType,
      newEndDate,
      newStartDate,
      campaignDaysFrame,
      campaignDays,
      influencersSocials,
    } = values;
    // If we're in step 1 - check dates validity and campaign name presence
    if (step === 1) {
      if (isScheduleDateType && !validateDatesArray(campaignDays)) return false;

      if (
        (!isScheduleDateType && !moment(newEndDate)?.isValid()) ||
        !moment(newStartDate)?.isValid() ||
        !validateDatesArray(campaignDaysFrame)
      )
        return false;

      if (influencersSocials.length === 0) {
        return true;
      }

      if (
        influencersSocials.some(
          (item) =>
            !item.influencerFacebookUrl &&
            !item.influencerInstagramUrl &&
            !item.influencerTwitchUrl &&
            !item.influencerTwitterUrl &&
            !item.influencerYoutubeUrl &&
            !item.influencerTiktokUrl &&
            !item.influencerFacebookGamingUrl
        )
      ) {
        return false;
      }
    }

    // Validate campaign days and name
    if (campaignName.trim().length === 0) {
      return true;
    } else if (isScheduleDateType) {
      return !validateDatesArray(campaignDays);
    } else {
      return !(
        moment(newEndDate)?.isValid() &&
        moment(newStartDate)?.isValid() &&
        validateDatesArray(campaignDaysFrame)
      );
    }
  };

  // Redirect to the first step (campaign type selection) if there's no campaign type select and we are past that step
  // Run only on mount as this happens after a refresh
  useEffect(() => {
    const { step } = campaignWizardContext;
    const { campaignTypes } = props.values;
    const { history } = props;

    if (step > 0 && campaignTypes?.length === 0) {
      history.replace(`${paths.TRACK_CAMPAIGN}/${STEP_PARAMS[0]}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { title, subtitle, component: StepComponent } = filterSteps[step];

  const { loading } = campaignCreationCommunication;

  return (
    <CampaignWizardContext.Provider value={campaignWizardContext}>
      <TopBar>
        <CampaignWizardStepper
          brands={brands}
          step={step}
          steps={filterSteps}
        />
      </TopBar>
      <Container className={classes.scrollContainer}>
        <Container className={classes.container}>
          <Form>
            <Grid
              container
              spacing={2}
              wrap="nowrap"
              direction="column"
              justify="space-between"
              style={{ height: '100%' }}
            >
              <Grid item>
                <Box my={4}>
                  <Box textAlign="center" fontSize="2.5rem">
                    {title}
                  </Box>
                  <Box textAlign="center" fontSize="1.125rem">
                    {subtitle}
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <StepComponent
                  authUser={authUser}
                  organizationRestrictions={organizationRestrictions}
                  trialAccount={trialAccount}
                  setPerformanceFlag={setPerformanceFlag}
                  setSVFlag={setSVFlag}
                  setMediaFlag={setMediaFlag}
                  performanceFlag={
                    (values?.campaignTypes.length > 0 &&
                      values?.campaignTypes.includes(1)) ||
                    performanceFlag
                  }
                  SVFlag={
                    (values?.campaignTypes.length > 0 &&
                      values?.campaignTypes.includes(2)) ||
                    SVFlag
                  }
                  mediaFlag={
                    (values?.campaignTypes.length > 0 &&
                      values?.campaignTypes.includes(3)) ||
                    mediaFlag
                  }
                  steps={filterSteps}
                  brands={brands[0]}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item style={{ flex: 1 }}>
                    {step !== 0 && (
                      <Button
                        onClick={goToPreviousStep}
                        variant="outlined"
                        color="primary"
                        type="button"
                      >
                        PREV
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    <Link to="/campaigns">
                      <Button type="button" color="primary">
                        CANCEL
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item>
                    {step === filterSteps.length - 1 ? (
                      <LoadingButton
                        key="submit"
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={loading}
                        disabled={
                          values?.campaignTypes.length === 0 ||
                          (filterSteps.length === 4 &&
                            values?.campaignTypes.includes(2) &&
                            !values.brands.some((el) => el.checked)) ||
                          loading
                        }
                      >
                        SUBMIT
                      </LoadingButton>
                    ) : (
                      <>
                        {step !== 3 ? (
                          <Button
                            disabled={
                              isNextDisabled(step) ||
                              (arrayErrors.length !== 0 && step === 1)
                            }
                            key="next"
                            onClick={goToNextStep}
                            variant="contained"
                            color="primary"
                            type="button"
                          >
                            NEXT
                          </Button>
                        ) : (
                          <Button
                            disabled={
                              (values?.campaignTypes.includes(2) &&
                                !values.brands.some((el) => el.checked)) ||
                              !_isEmpty(errors)
                            }
                            key="next"
                            onClick={goToNextStep}
                            variant="contained"
                            color="primary"
                            type="button"
                          >
                            NEXT
                          </Button>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Container>
      </Container>
    </CampaignWizardContext.Provider>
  );
}

export default enhance(CampaignWizard);
