import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import numeral from 'numeral';
import React from 'react';

import * as paths from '../../common/constants/paths';
import InfluencerSize from '../../components/InfluencerSize';
import NewBadge from '../../components/NewBadge';
import OrderableTableCell from '../../components/OrderableTableCell';
import TableCellWithLink from '../../components/TableCellWithLink';
import { getInfluencerSize } from '../../helpers/influencer-sizes';
import { history } from '../../store';
import clamp from '../../utils/clamp';
import cmdClickNewTab from '../../utils/cmd-click-new-tab';
import compose from '../../utils/compose';
import isNumber from '../../utils/is-number';
import { InfluencersContext } from './components/Influencers';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    overflowX: 'auto',
    display: 'inline-block',
  },
  table: {
    minWidth: 650,
  },
  row: {
    cursor: 'pointer',
    transition: 'none',
  },
}));

const prettyPercentage = compose(Math.round, clamp(0, 100));

function InfluencersTable(props) {
  const classes = useStyles();

  const {
    influencers,
    network,
    searchWatchlist,
    hasCampaignGoal,
    loading,
    onFetchMore,
    hasNextPage,
  } = props;

  function renderTwitchTable() {
    function renderInfluencer(influencer) {
      const {
        id,
        profile_image_url,
        display_name,
        view_rate_30,
        match_score,
        target_score,
        follower_virality,
        total_followers,
        views_days_30,
        login,
        network,
        created_at,
        avg_viewers_days_30,
        stream_time_days_30,
        ama_days_30,
      } = influencer;
      const influencerLink = `${paths.INFLUENCER}/${encodeURI(
        login
      )}/${network}`;

      const size = getInfluencerSize(total_followers).toUpperCase();
      const fallbackImage = '../../fallback/img.png';

      return (
        <TableRow
          hover
          key={id}
          onClick={() => history.push(influencerLink)}
          className={classes.row}
        >
          <TableCellWithLink
            link={influencerLink}
            className="influencer-name-avatar"
          >
            <NewBadge created_at={created_at} />
            <Avatar
              src={profile_image_url ? profile_image_url : fallbackImage}
              alt={display_name?.toUpperCase()}
            />
            <div
              className="influencer-name"
              onClick={(event) => cmdClickNewTab(event, influencerLink)}
            >
              <InfluencerSize sizeOnly>{`${size} INFLUENCER`}</InfluencerSize>
              <Typography variant="body2" className="hover-row">
                {display_name}
              </Typography>
            </div>
          </TableCellWithLink>
          {!searchWatchlist && hasCampaignGoal && (
            <TableCellWithLink alignRight link={influencerLink}>
              {prettyPercentage(match_score * 100)}%
            </TableCellWithLink>
          )}
          <TableCellWithLink alignRight link={influencerLink}>
            {prettyPercentage(target_score * 100)}%
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {prettyPercentage(follower_virality)}%
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(total_followers)
              ? numeral(total_followers).format('0.[0]a')
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(view_rate_30)
              ? `${numeral(view_rate_30).format('0.[0]')}%`
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(avg_viewers_days_30)
              ? numeral(avg_viewers_days_30).format('0.[0]a')
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(stream_time_days_30)
              ? numeral(stream_time_days_30).format('0.[0]')
              : '-'}
            h
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(ama_days_30)
              ? numeral(ama_days_30).format('0.[0]a')
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(views_days_30)
              ? numeral(views_days_30).format('0.[0]a')
              : '-'}
          </TableCellWithLink>
        </TableRow>
      );
    }

    return (
      <Paper className={classes.root}>
        <Table className={clsx('influencers-table', classes.table)}>
          <TableHead>
            <TableRow>
              <TableCell>Creator</TableCell>
              {!searchWatchlist && hasCampaignGoal && (
                <OrderableTableCell
                  context={InfluencersContext}
                  TableCellProps={{ align: 'right' }}
                  orderByValue="match_score"
                >
                  Matching score
                </OrderableTableCell>
              )}

              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="target_score"
              >
                Target score
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="follower_virality"
              >
                Growth score
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="total_followers"
              >
                Followers
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="view_rate_30"
              >
                View rate
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="avg_viewers_days_30"
                css={{ 'white-space': 'nowrap' }}
              >
                Avg. CCV (30d)
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="stream_time_days_30"
                css={{ 'white-space': 'nowrap' }}
              >
                Stream Time (30d)
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="ama_days_30"
                css={{ 'white-space': 'nowrap' }}
              >
                AMA (30d)
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="views_days_30"
                css={{ 'white-space': 'nowrap' }}
              >
                Views (30d)
              </OrderableTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{influencers.map(renderInfluencer)}</TableBody>
        </Table>
        {(loading || hasNextPage) && (
          <Box
            height={128}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button onClick={onFetchMore} variant="contained" color="primary">
                Load more results
              </Button>
            )}
          </Box>
        )}
      </Paper>
    );
  }

  function renderYoutubeTable() {
    function renderInfluencer(influencer) {
      const {
        id,
        profile_image_url,
        display_name,
        match_score,
        target_score,
        login,
        network,
        subscriber_count,
        likes_90,
        dislikes_90,
        like_dislike_ratio,
        avg_view_count_per_video,
        view_count_90,
        yt_engagement_90,
        created_at,
        video_count,
      } = influencer;

      const size = getInfluencerSize(subscriber_count).toUpperCase();

      const influencerLink = `${paths.INFLUENCER}/${encodeURI(
        login
      )}/${network}`;
      const fallbackImage = '../../fallback/img.png';

      return (
        <TableRow
          hover
          key={id}
          className={classes.row}
          onClick={() => history.push(influencerLink)}
        >
          <TableCellWithLink
            link={influencerLink}
            className="influencer-name-avatar"
          >
            <NewBadge created_at={created_at} />
            <Avatar
              src={profile_image_url ? profile_image_url : fallbackImage}
              alt={display_name?.toUpperCase()}
            />
            <div
              className="influencer-name"
              onClick={(event) => cmdClickNewTab(event, influencerLink)}
            >
              <InfluencerSize sizeOnly>{`${size} INFLUENCER`}</InfluencerSize>
              <Typography variant="body2" className="hover-row">
                {display_name}
              </Typography>
            </div>
          </TableCellWithLink>
          {!searchWatchlist && hasCampaignGoal && (
            <TableCellWithLink alignRight link={influencerLink}>
              {prettyPercentage(match_score * 100)}%
            </TableCellWithLink>
          )}
          <TableCellWithLink alignRight link={influencerLink}>
            {prettyPercentage(target_score * 100)}%
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(subscriber_count)
              ? numeral(subscriber_count).format('0.[0]a')
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(likes_90) ? numeral(likes_90).format('0.[0]a') : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(dislikes_90)
              ? numeral(dislikes_90).format('0.[0]a')
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(like_dislike_ratio)
              ? numeral(like_dislike_ratio).format('0.[0]a')
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(view_count_90)
              ? numeral(view_count_90).format('0.[0]a')
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(yt_engagement_90)
              ? numeral(yt_engagement_90).format('0[.][0]%')
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(avg_view_count_per_video)
              ? numeral(avg_view_count_per_video).format('0.[0]a')
              : '-'}
          </TableCellWithLink>
          <TableCellWithLink alignRight link={influencerLink}>
            {isNumber(video_count) ? numeral(video_count).format('0,[0]') : '-'}
          </TableCellWithLink>
        </TableRow>
      );
    }

    return (
      <Paper className={classes.root}>
        <Table className={clsx('influencers-table', classes.table)}>
          <TableHead>
            <TableRow>
              <TableCell>Creator</TableCell>
              {!searchWatchlist && hasCampaignGoal && (
                <OrderableTableCell
                  context={InfluencersContext}
                  TableCellProps={{ align: 'right' }}
                  orderByValue="match_score"
                >
                  Matching score
                </OrderableTableCell>
              )}
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="target_score"
              >
                Target score
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="subscriber_count"
              >
                Subscribers
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="likes_90"
              >
                Likes (90d)
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="dislikes_90"
              >
                Dislikes (90d)
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="like_dislike_ratio"
              >
                Like / Dislike (90d)
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="view_count_90"
              >
                Views (90d)
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="yt_engagement_90"
              >
                Engagement Rate (90d)
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="avg_view_count_per_video"
              >
                Views / Video (90d)
              </OrderableTableCell>
              <OrderableTableCell
                context={InfluencersContext}
                TableCellProps={{ align: 'right' }}
                orderByValue="video_count"
              >
                Videos
              </OrderableTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{influencers.map(renderInfluencer)}</TableBody>
        </Table>
        {(loading || hasNextPage) && (
          <Box
            height={128}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button onClick={onFetchMore} variant="contained" color="primary">
                Load more results
              </Button>
            )}
          </Box>
        )}
      </Paper>
    );
  }

  switch (network) {
    case 'youtube': {
      return renderYoutubeTable();
    }
    case 'twitch': {
      return renderTwitchTable();
    }

    default:
      return null;
  }
}

export default InfluencersTable;
