import { Avatar, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import * as PATHS from '../../../common/constants/paths';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const CreatorAvatar = ({ influencer, platform, isDiscovery }) => {
  const classes = useStyles();
  const fallbackImage = '../../fallback/img.png';
  let nameLink = influencer[`${platform}_url`];

  if (!!influencer?.twitch_external_id)
    nameLink = `${PATHS.INFLUENCER}/${influencer.twitch_external_id}/twitch`;
  else if (!!influencer?.youtube_external_id)
    nameLink = `${PATHS.INFLUENCER}/${influencer.youtube_external_id}/youtube`;

  const influencerName =
    typeof influencer === 'string'
      ? influencer
      : influencer.name ||
        influencer.twitch_external_id ||
        influencer.twitter_external_id ||
        influencer.instagram_external_id ||
        influencer.youtube_external_id ||
        'unknown';

  return (
    <>
      {isDiscovery ? (
        <Link
          to={nameLink}
          onClick={(e) => {
            if (nameLink) e.stopPropagation();
          }}
        >
          <div
            style={{
              maxWidth: '150px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              src={influencer.avatar ? influencer.avatar : fallbackImage}
              alt={influencer.name?.toUpperCase()}
            />

            <Tooltip placement="top" title={influencerName}>
              <Typography
                className={classes.link}
                style={{
                  fontWeight: 'bold',
                  paddingLeft: 16,
                  flex: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {influencerName}
              </Typography>
            </Tooltip>
          </div>
        </Link>
      ) : (
        <div
          style={{
            maxWidth: '150px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            src={influencer.avatar ? influencer.avatar : fallbackImage}
            alt={influencer.name?.toUpperCase()}
          />

          <Tooltip placement="top" title={influencerName}>
            <Typography
              className={classes.link}
              style={{
                fontWeight: 'bold',
                paddingLeft: 16,
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {influencerName}
            </Typography>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default CreatorAvatar;
