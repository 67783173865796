import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  makeStyles,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import momentTz from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import VideoPreview from '../../views/Campaign/components/VideoPreview';
import { Video } from '../../views/Campaign/types';
import { submitStreamSegment } from '../../views/influencerViews/Stats/Streaming/store/Streams.thunks';
import LoadingButton from '../LoadingButton';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  valuesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
    color: theme.palette.error.main,
  },
  headSegment: {
    position: 'relative',
    padding: '24px 35px 30px 35px',
  },
  closeIcon: {
    position: 'absolute',
    right: 27,
    top: 15,
  },
  mainSegment: {
    padding: '0px 35px 0px 35px',
  },
  footerSegment: {
    padding: '0px 35px 75px 35px',
  },
  timeUnderInput: {
    fontSize: 16,
  },
}));

interface StreamSegmentProps {
  setModalStreamSegment?: (value: null) => void;
  modalStreamSegment?: number | null;
  video: Video;
  influencer: {
    name: string;
    avatar: string;
  };
}

const getSecondsFromHHMMSS = (value) => {
  const [str1, str2, str3] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    // seconds
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    // minutes * 60 + seconds
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    // hours * 60 * 60 + minutes * 60 + seconds
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

export const StreamSegment = ({
  setModalStreamSegment,
  modalStreamSegment,
  video,
  influencer,
}: StreamSegmentProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, duration, published_at, time_zone } = video;

  const [range, setRange] = useState([0, duration]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [startInput, setStartInput] = useState('');
  const [endInput, setEndInput] = useState('');

  const [start, end] = range;

  useEffect(() => {
    if (id) {
      let start = 0;
      let end = duration;

      if (video.video_segments && video.video_segments.length > 0) {
        start = video.video_segments[0].start_at;
        end = video.video_segments[0].end_at;
      }

      setRange([start, end]);
      setStartInput(toHHMMSS(start));
      setEndInput(toHHMMSS(end));
    }

    if (!id) {
      setLoading(false);
      setErrorMessage(null);
    }
  }, [id, duration, video.video_segments]);

  const onInputChange = (input) => (event) => {
    const { value } = event.target;
    if (input === 'start') setStartInput(value);
    else setEndInput(value);

    let seconds;
    if (input === 'start')
      seconds = Math.min(Math.max(0, getSecondsFromHHMMSS(value)), end);
    else
      seconds = Math.min(
        Math.max(start, getSecondsFromHHMMSS(value)),
        duration
      );

    if (input === 'start') setRange([seconds, end]);
    else setRange([start, seconds]);
  };

  const onInputBlur = (input) => () => {
    if (input === 'start') {
      setStartInput(toHHMMSS(start));
    } else {
      setEndInput(toHHMMSS(end));
    }
  };

  const onChange = (_, [start, end]) => {
    if (start >= end) return;

    setStartInput(toHHMMSS(start));
    setEndInput(toHHMMSS(end));

    setRange([start, end]);
  };

  const toHHMMSS = (secs = 0) => {
    const duration = momentTz.duration(secs, 'seconds');
    //date any
    return momentTz(
      `2022-01-01 ${duration.hours()}:${duration.minutes()}:${duration.seconds()}`
    ).format('HH:mm:ss');
  };

  const onClose = () => {
    if (loading) return;
    setModalStreamSegment(null);
  };

  const onSubmit = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      await dispatch(
        submitStreamSegment({
          videoId: id,
          startAt: start,
          endAt: end,
        })
      );
      onClose();
    } catch (e) {
      setErrorMessage(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={modalStreamSegment === id}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle className={classes.headSegment} disableTypography>
        <Typography variant="h6">TIME FRAME SELECT</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          disabled={loading}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.mainSegment}>
        <VideoPreview
          video={video}
          influencer={influencer}
          timeZone={time_zone}
          bannerThumbnail={false}
        />
        <div className={classes.sliderContainer}>
          <Slider
            value={range}
            min={0}
            max={duration}
            onChange={onChange}
            valueLabelFormat={toHHMMSS}
            valueLabelDisplay="off"
          />
        </div>
        <div className={classes.valuesContainer}>
          <div>
            <FormLabel>From</FormLabel>
            <br />
            <TextField
              variant="outlined"
              value={startInput}
              onChange={onInputChange('start')}
              onBlur={onInputBlur('start')}
              placeholder="hh:mm:ss"
            />
            <p className={classes.timeUnderInput}>
              {momentTz(published_at)
                .tz(time_zone)
                .add(start, 'seconds')
                .format('hh:mm:ss A')}
            </p>
          </div>
          <div>
            <Button
              disabled={
                startInput === toHHMMSS(0) && endInput === toHHMMSS(duration)
              }
              color="primary"
              variant="outlined"
              onClick={() => {
                setStartInput(toHHMMSS(0));
                setEndInput(toHHMMSS(duration));
                setRange([0, duration]);
              }}
            >
              RESET SELECTION
            </Button>
          </div>
          <div>
            <FormLabel>To</FormLabel>
            <br />
            <TextField
              variant="outlined"
              value={endInput}
              onChange={onInputChange('end')}
              onBlur={onInputBlur('end')}
              placeholder="hh:mm:ss"
            />
            <p className={classes.timeUnderInput}>
              {momentTz(published_at)
                .tz(time_zone)
                .add(end, 'seconds')
                .format('hh:mm:ss A')}
            </p>
          </div>
        </div>
        {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
      </DialogContent>
      <DialogActions className={classes.footerSegment}>
        <LoadingButton
          onClick={onSubmit}
          color="primary"
          variant="contained"
          loading={loading}
          disabled={loading}
        >
          Apply
        </LoadingButton>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
