import { FormLabel } from '@material-ui/core';
import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import { Formik, FormikBag } from 'formik';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import {
  ErrorMessage,
  getErrorMessageByField,
} from '../../../../../common/ErrorMessageUtil';
import FormikMuiTextField from '../../../../../components/FormikMuiTextField';
import { changeInfluencerPassword } from '../../../store/InfluencerInfo.thunks';
import { ModalShell } from './ModalShell';

const INITIAL_VALUES = {
  current_password: '',
  password: '',
  repeat_password: '',
};

const validationSchema = yup.object({
  current_password: yup.string().required('Enter current password'),
  password: yup.string().required('Enter password'),
  repeat_password: yup
    .string()
    .required('Repeat password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

type FormValues = typeof INITIAL_VALUES;

interface ChangePasswordModalProps {
  onClose: () => void;
  open: boolean;
}

export const ChangePasswordModal = ({
  onClose,
  open,
}: ChangePasswordModalProps) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<ErrorMessage[]>(null);

  const onSubmit = useCallback(
    async (
      { repeat_password: _, ...values }: typeof INITIAL_VALUES,
      { resetForm }: FormikBag<any, FormValues>
    ) => {
      const result = await dispatch(changeInfluencerPassword(values));
      if (isFulfilled(result)) {
        onClose();
        resetForm();
      } else if (isRejectedWithValue(result)) {
        setError(result.payload as ErrorMessage[]);
      }
    },
    [onClose, dispatch]
  );

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      isInitialValid={false}
    >
      {(formik) => {
        const { submitForm, resetForm, isValid } = formik;

        return (
          <ModalShell
            title="CHANGE PASSWORD"
            open={open}
            onClose={() => {
              onClose();
              resetForm();
              setError(null);
            }}
            onConfirm={submitForm}
            disableConfirm={!isValid}
          >
            <FormLabel style={{ marginBottom: 15 }}>Current Password</FormLabel>
            <FormikMuiTextField
              fullWidth
              type="password"
              placeholder="Enter your current password"
              variant="outlined"
              style={{ marginBottom: 16 }}
              name="current_password"
              errorMessage={getErrorMessageByField('current_password', error)}
            />
            <FormLabel style={{ marginBottom: 15 }}>New Password</FormLabel>
            <FormikMuiTextField
              fullWidth
              type="password"
              placeholder="Type your new password"
              variant="outlined"
              style={{ marginBottom: 16 }}
              name="password"
              errorMessage={getErrorMessageByField('password', error)}
            />
            <FormLabel>Repeat New Password</FormLabel>
            <FormikMuiTextField
              fullWidth
              type="password"
              placeholder="Retype your new password"
              variant="outlined"
              style={{ marginBottom: 16 }}
              name="repeat_password"
            />
          </ModalShell>
        );
      }}
    </Formik>
  );
};
