import { CardContent, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import AddInfluencerBySearch from '../../../components/AddInfluencerBySearch';
import FormikMuiTextField from '../../../components/FormikMuiTextField';
import LoadingButton from '../../../components/LoadingButton';
import enhancer from './enhancer';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: '8px',
    padding: '8px 24px',
    alignSelf: 'center',
  },
  disabledInput: {
    background: '#efefef',
    borderRadius: 4,
    '& input': {
      cursor: 'not-allowed',
    },
  },
}));

const ManuallyAddToWatchlistForm = enhancer(function (props) {
  const classes = useStyles();

  const { loading, handleSubmit, values, handleChange } = props;

  let isError = true;
  if (values.twitch_url || values.youtube_url) {
    isError = false;
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <CardContent>
        <FormLabel>Twitch Profile</FormLabel>
        <FormikMuiTextField
          fullWidth
          name="twitch_url"
          value={values.twitch_url}
          placeholder="Enter Twitch URL"
          variant="outlined"
          onChange={handleChange}
          disabled={!!props.twitch_url}
          className={!!props.twitch_url ? classes.disabledInput : ''}
        />
      </CardContent>
      <CardContent>
        <FormLabel>YouTube Channel</FormLabel>
        <FormikMuiTextField
          fullWidth
          name="youtube_url"
          value={values.youtube_url}
          placeholder="Enter YouTube URL"
          variant="outlined"
          onChange={handleChange}
          className={!!props.youtube_url ? classes.disabledInput : ''}
          disabled={!!props.youtube_url}
        />
      </CardContent>
      {/*      <CardContent>
        <FormLabel>Facebook Gaming Page</FormLabel>
        <FormikMuiTextField
          fullWidth
          name="facebook_url"
          value={values.facebook_url}
          placeholder="Enter Facebook URL"
          variant="outlined"
          onChange={handleChange}
          disabled={!!props.facebook_url}
          className={!!props.facebook_url ? classes.disabledInput : ''}
        />
      </CardContent>*/}
      <CardContent>
        <FormLabel>Twitter Account</FormLabel>
        <FormikMuiTextField
          fullWidth
          name="twitter_url"
          value={values.twitter_url}
          placeholder="Enter Twitter URL"
          variant="outlined"
          onChange={handleChange}
          disabled={!!props.twitter_url}
          className={!!props.twitter_url ? classes.disabledInput : ''}
        />
      </CardContent>
      {/*<CardContent>
        <FormLabel>Instagram Account</FormLabel>
        <FormikMuiTextField
          fullWidth
          name="instagram_url"
          value={values.instagram_url}
          placeholder="Enter Instagram URL"
          variant="outlined"
          onChange={handleChange}
          disabled={!!props.instagram_url}
          className={!!props.instagram_url ? classes.disabledInput : ''}
        />
      </CardContent>*/}
      {isError && (
        <CardContent>
          <Alert severity="warning">Please add a Twitch or YouTube URL</Alert>
        </CardContent>
      )}
      <LoadingButton
        key="submit"
        type="submit"
        color="primary"
        variant="contained"
        loading={loading}
        disabled={loading || isError}
        className={classes.button}
      >
        Add Creator
      </LoadingButton>
    </form>
  );
});

function AddToWatchlistForm() {
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);

  if (selectedInfluencer)
    return (
      <div style={{ width: 600 }}>
        <ManuallyAddToWatchlistForm {...selectedInfluencer} />
      </div>
    );

  return (
    <div style={{ width: 600 }}>
      <AddInfluencerBySearch
        onInfluencerClick={setSelectedInfluencer}
        onAddEmptyInfluencer={() => setSelectedInfluencer({})}
        listName="watchlist"
      />
    </div>
  );
}

export default AddToWatchlistForm;
