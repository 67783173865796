import uuid from 'uuid/v4';

export interface InfluencerSocials {
  id?: number;
  display_name?: string;
  profile_image_url?: string;

  // used for onboarding
  token: string;

  influencerYoutubeUrl: string;
  influencerTwitchUrl: string;
  influencerTwitterUrl: string;
  influencerFacebookUrl: string;
  influencerInstagramUrl: string;
  influencerTiktokUrl: string;
  influencerFacebookGamingUrl: string;
}

export interface CampaignWizardFormValues {
  campaignName: string;

  influencersSocials: InfluencerSocials[];

  // TODO: fill this in
}

export const PLATFORMS_THAT_REQUIRE_ONBOARDING = [
  'Tiktok',
  'Facebook',
  'FacebookGaming',
];

export const doesRequireOnboarding = (platform: string) => {
  return PLATFORMS_THAT_REQUIRE_ONBOARDING.includes(platform);
};

const EMPTY_INFLUENCER: InfluencerSocials = {
  token: 'empty',

  influencerYoutubeUrl: '',
  influencerTwitchUrl: '',
  influencerTwitterUrl: '',
  influencerFacebookUrl: '',
  influencerInstagramUrl: '',
  influencerTiktokUrl: '',
  influencerFacebookGamingUrl: '',
};

export const generateInfluencerToken = () => uuid();

export const createEmptyInfluencer = () => {
  return { ...EMPTY_INFLUENCER, token: uuid() };
};
