import { z } from 'zod';

const TopMomentSchema = z.object({
  'moment in video': z.string(),
  streamer: z.string(),
  topic: z.string(),
  creator_summary: z.string().optional(),
  creator_sentiment: z
    .union([
      z.literal('positive'),
      z.literal('negative'),
      z.literal('neutral'),
      z.literal(''),
    ])
    .optional(),
  audience_summary: z.string(),
  audience_sentiment: z.union([
    z.literal('positive'),
    z.literal('negative'),
    z.literal('neutral'),
    z.literal(''),
  ]),
  moment_length: z.number(),
  moment_starts_here: z.union([
    z.literal('TRUE'),
    z.literal('FALSE'),
    z.literal('True'),
    z.literal('False'),
    z.literal('true'),
    z.literal('false'),
  ]),
  transcript_started_at: z.number(),
  moment_ccv: z.union([z.number(), z.literal('')]),
  category: z.string(),
  video_id: z.string().optional(),
  region: z.string().optional(),
  platform: z.string().optional(),
  video_title: z.string().optional(),

  // Those are not really used for now so they are optional
  'Timestamp(absolute)': z.string().optional(),
  'Timestamp(in video (s))': z.number().optional(),
  transcript_ended_at: z.number().optional(),
  transcript_text: z.string().optional(),
  comment: z.string().optional(),
  timeframe: z.string().optional(),
  campaign_influencer_id: z.union([z.number(), z.string()]).optional(), //TODO: We will use this later, we need to refactor some code before
});

export const TopMomentsSchema = z.array(TopMomentSchema);

export type TopMoment = z.infer<typeof TopMomentSchema>;
