async function readFileAsDataURL(file) {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.onerror = (e) => console.error('bad file');
    fileReader.readAsDataURL(file);
  });

  return result_base64;
}

export default readFileAsDataURL;
