import { takeEvery } from 'redux-saga/effects';

function handleRequestFailed(action) {
  console.warn('Aggero Request Failed:', action.payload.error);
}

export default function* root() {
  yield takeEvery(
    ({ meta }) => meta && meta.request && meta.request.type === 'failed',
    handleRequestFailed
  );
}
