import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import FacebookIcon from '../assets-2.0/images-influencer/facebook.svg';

const useStyles = makeStyles({
  text: {
    color: '#3b5998',
    fontSize: 16,
    display: 'inline-block',
  },
  iconImage: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
});

function FacebookFlag(props) {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.text}>
      <img
        src={FacebookIcon}
        className={classes.iconImage}
        alt="facebook icon"
      />
      Facebook
    </Typography>
  );
}

export default FacebookFlag;
