import { useQuery } from 'react-query';

import * as ajax from '../../../ajax';

export const useCCVData = ({ campaignId, videoId, platform }) => {
  const { data, isLoading } = useQuery(`ccv-data-${videoId}`, async () => {
    if (!campaignId) {
      return ajax.getStreamCCVData(videoId, platform);

      // return {
      //   avg_ccv: ccv.map((d) => ({ count: d.count, start_at: d.started_at })), // TODO: remove this once backend returns data in the correct format
      // };
    }

    return ajax.getDeliverablesChartData(campaignId, videoId);
  });

  return { ccvData: data && data.avg_ccv, ccvDataLoading: isLoading };
};

export const useShoutoutDeliverablesV2ByVideoId = (campaign, videoId) => {
  const { isLoading, error, data } = useQuery(
    `campaign-${campaign?.id}-shoutoutsV2-video-${videoId}`,
    async () => {
      if (!campaign) {
        return { shoutout_deliverables: [] };
      }

      const { shoutout_deliverables_v2 } = await ajax.getShoutoutDeliverablesV2(
        campaign.id,
        videoId
      );

      return {
        shoutout_deliverables: shoutout_deliverables_v2.map((d) => ({
          ...d,
          v2: true,
        })),
      };
    }
  );

  return { isLoading, data, error };
};

export const useChatMentionDeliverablesByVideoId = (campaign, videoId) => {
  return useQuery(
    `campaign-${campaign?.id}-chat-mentions-video-${videoId}`,
    async () => {
      if (!campaign) {
        return {
          keyword_mention_deliverables: [],
        };
      }

      return await ajax.getChatMentionDeliverables(campaign.id, videoId);
    }
  );
};

export const useSocialMediaOffstreamTrackings = (campaign) => {
  return useQuery(`campaign-${campaign.id}-social-offstream-trackings`, () =>
    ajax.getSocialOffstreamTrackings(campaign.id)
  );
};

export const useBannerDelivrables = (campaign) =>
  useQuery(`campaign-${campaign?.id}-banners`, async () => {
    if (!campaign) {
      return [];
    }

    return await ajax.getBannerDeliverables(campaign.id);
  });

export const useBannerData = ({ campaign, videoId }) => {
  const { data: deliverables, isLoading: deliverablesLoading } =
    useBannerDelivrables(campaign);

  const { data, isLoading } = useQuery(
    `banner-data-with-occ-${videoId}`,
    () =>
      Promise.all(
        deliverables
          .filter(({ data }) => data[videoId])
          .map(async (deliverable) => {
            const occurrences = await ajax.getCampaignBannerOccurrences(
              campaign.id,
              deliverable.id,
              videoId
            );

            return { ...deliverable, occurrences };
          })
      ),
    { enabled: !!deliverables }
  );

  return {
    bannerData: data,
    bannerDataLoading: isLoading || deliverablesLoading,
  };
};
