import _sortBy from 'lodash/sortBy';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Table, { StatBox, TableText } from '../Table';
import { setShoutoutsFilterMediaTracking } from '../utils/CampaignFiltersStore';
import SectionContainer from './SectionContainer';
import { SectionTitle } from './shared';

const COLS = [
  {
    label: 'Keyword',
    key: 'keyword',
    stretch: true,
    render: (data) => <TableText>{data.keyword}</TableText>,
  },
  {
    label: 'Mentions',
    key: 'mentions',
    render: (data) => <StatBox stat={data.mentions} />,
  },
  {
    label: 'Creators',
    key: 'creators',
    render: (data) => <StatBox stat={data.creators} />,
  },
  {
    label: 'Videos',
    key: 'streams',
    render: (data) => <StatBox stat={data.streams} />,
  },
  {
    label: 'Avg. CCV',
    key: 'avgCCV',
    render: (data) => <StatBox stat={data.avgCCV} />,
  },
  {
    label: 'Peak CCV',
    key: 'peakCCV',
    render: (data) => <StatBox stat={data.peakCCV} />,
  },
  {
    label: 'Views',
    key: 'totalViews',
    render: (data) => <StatBox stat={data.totalViews} />,
  },

  {
    label: 'Media Value',
    key: 'totalEMV',
    render: (data) => (
      <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
    ),
  },
];

const getRows = (shoutouts, isSponsorship) =>
  shoutouts.map((s, key) => ({
    keyword: isSponsorship ? s.name : s.keyword,
    mentions: isSponsorship ? s.mentions : s.count,
    streams: s.streams,
    creators: s.creators || s.creators_count,
    avgCCV: s.avg_ccv,
    peakCCV: s.peak_ccv,
    totalViews: s.viewers,
    totalEMV: s.emv,
    key: s.id,
  }));

export const ShoutoutDeliverables = ({
  shoutouts,
  onViewMore,
  isSponsorship,
  setNumberPage,
  activeTab,
}) => {
  const rows = useMemo(() => getRows(shoutouts, isSponsorship), [shoutouts]);
  const dispatch = useDispatch();

  const onRowClick = useCallback(
    ({ key, keyword }) => {
      if (activeTab !== 'summary' && activeTab !== 'overview') {
        return setNumberPage(
          Math.floor(
            _sortBy(shoutouts, 'name').findIndex(
              (shoutout) => shoutout.name === keyword
            ) / 5
          )
        );
      }

      if (activeTab === 'overview') {
        dispatch(setShoutoutsFilterMediaTracking(key));
      }

      onViewMore(key);
    },
    [onViewMore]
  );
  const idTable = 'shotouts';

  return (
    <SectionContainer isSponsorship={isSponsorship}>
      <SectionTitle onViewMore={() => onViewMore()}>
        Voice Mentions
      </SectionTitle>
      <Table
        noBorders={true}
        rows={rows}
        cols={COLS}
        rowHeight={96}
        defaultSortCol="mentions"
        onRowClick={onRowClick}
        idTable={idTable}
      />
    </SectionContainer>
  );
};

export default ShoutoutDeliverables;
