import Alert from '@material-ui/lab/Alert';
import React, { useEffect } from 'react';

import { CampaignInfluencersFilter } from '../../CampaignInfluencersFilter';
import { CampaignLanguageFilter } from '../../CampaignLanguageFilter';
import { Campaign } from '../../types';
import {
  campaignHasStaticTopMoments,
  findInfluencerById,
  getCampaignUsedLanguages,
} from '../../utils';
import { fetchTopMoments } from '../fetchTopMoments';
import { FiltersDrawer } from '../FiltersDrawer';
import { SimpleDropdownFilter } from '../SimpleDropdown';
import { data_by_campaign_id } from '../static_data';
import { TopMoment } from '../types';
import { getCategories, getPlatforms, getRegions } from '../utils';
import { TopTopicsTable } from './TopTopicsTable';

type Props = {
  campaign: Campaign;
  setActiveTab: (tab: string) => void;
  setStatsProps: (props: any) => void;
};

export const TopTopicsTab = (props: Props) => {
  const [selectedInfluencer, setSelectedInfluencer] = React.useState<
    'all' | number
  >('all');
  const [selectedCategory, setSelectedCategory] = React.useState('all');
  const [selectedRegion, setSelectedRegion] = React.useState('all');
  const [selectedPlatform, setSelectedPlatform] = React.useState('all');
  const [data, setData] = React.useState<TopMoment[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [languageFilter, setLanguageFilter] = React.useState([]);

  useEffect(() => {
    // If the campaign has static data, use it, otherwise we fetch it from google storage
    if (campaignHasStaticTopMoments(props.campaign.id)) {
      setData(data_by_campaign_id[props.campaign.id]);
    } else {
      fetchTopMoments({
        campaignId: props.campaign.id,
        onComplete: setData,
        setLoading,
        setError,
      });
    }
  }, [props.campaign.id]);

  if (loading) {
    return <Alert severity="info">Loading...</Alert>;
  }

  if (error) {
    return (
      <Alert severity="info">Top Moments are currently processing...</Alert>
    );
  }

  if (data.length === 0) {
    return null;
  }

  // Get the influencers name from the data
  const usedInfluencersNames = data.map((moment) =>
    moment.streamer.toLowerCase()
  );
  const usedInfluencers = props.campaign.campaign_influencers.filter(
    (influencer) => {
      return usedInfluencersNames.includes(influencer.name?.toLowerCase());
    }
  );

  const currentInfluencerName =
    selectedInfluencer === 'all'
      ? 'all'
      : findInfluencerById(
          selectedInfluencer,
          usedInfluencers
        ).name.toLowerCase();

  const categories = getCategories(data);
  const regions = getRegions(data);
  const platforms = getPlatforms(data);
  const availableLanguages = getCampaignUsedLanguages(props.campaign);

  const renderFilters = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {usedInfluencers.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <CampaignInfluencersFilter
              campaignInfluencers={usedInfluencers}
              value={selectedInfluencer}
              onChange={(value) => {
                setSelectedInfluencer(value);
              }}
            />
          </div>
        ) : null}
        {categories.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <SimpleDropdownFilter
              options={categories}
              value={selectedCategory}
              onChange={(value) => {
                setSelectedCategory(value);
              }}
              title="Category"
              key={'category_filter'}
            />
          </div>
        ) : null}
        {availableLanguages.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <CampaignLanguageFilter
              availableLanguages={availableLanguages}
              onChange={setLanguageFilter}
              values={languageFilter}
            />
          </div>
        ) : null}
        {regions.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <SimpleDropdownFilter
              options={regions}
              value={selectedRegion}
              onChange={(value) => {
                setSelectedRegion(value);
              }}
              title="Region"
              key={'region_filter'}
            />
          </div>
        ) : null}
        {platforms.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <SimpleDropdownFilter
              options={platforms}
              value={selectedPlatform}
              onChange={(value) => {
                setSelectedPlatform(value);
              }}
              title="Platform"
              key={'platform_filter'}
            />
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          position: 'absolute',
          zIndex: '2',
          right: '24px',
          top: '95px',
        }}
      >
        <FiltersDrawer content={renderFilters()} />
      </div>

      <TopTopicsTable
        data={data as TopMoment[]}
        campaign={props.campaign}
        filters={{
          selectedCategory,
          selectedInfluencer: currentInfluencerName,
          languageFilter,
          selectedRegion,
          selectedPlatform,
        }}
        setActiveTab={props.setActiveTab}
        setStatsProps={props.setStatsProps}
      />
    </>
  );
};
