import { Box, Container, LinearProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import TopBar from '../../components/TopBar';
import TopBarContent from '../../components/TopBarContent';
import TopBarTitle from '../../components/TopBarTitle';
import mapSelectorsToProps from '../../helpers/map-selectors-to-props';
import { brandsMounted } from '../../store/events';
import { selectCommunication } from '../../store/models/communication/communication.selectors';
import {
  getTrialAccountBrandsExceeded,
  selectMyBrands,
} from '../../store/models/my-brands/my-brands.selectors';
import { getIsViewOnly } from '../../store/models/user/user.selectors';
import AccordionMyBrands from './components/AccordionMyBrands';
import MyBrandsEdit from './components/MyBrandsEdit';
import MyBrandsWizard from './components/MyBrandsWizard';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 94,
    overflow: 'scroll',
    height: '100%',
  },
  container_items: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(4),
  },
  brandNone: {
    padding: '25px 25px 0',
  },
  leftColumn: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
  },
  rightColumn: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  containerButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px',
  },
  defaultContainerButton: {
    width: '100%',
    display: 'contents',
  },
}));
const enhance = connect(
  mapSelectorsToProps({
    brands: selectMyBrands,
    brandsCommunication: selectCommunication('brands'),
    trialCampaignsExceeded: getTrialAccountBrandsExceeded,
  })
);

const MyBrandsMain = (props) => {
  const isViewOnly = useSelector(getIsViewOnly);

  const {
    brands,
    brandsCommunication: { loading },
    isWizardModal,
    onClose,
    setNewWizardBrand,
  } = props;
  document.title = 'My Brands';
  const [wizard, isWizard] = useState(false);
  const classes = useStyles();

  const [openBrandName, setOpenBrandName] = useState('');
  const handleSetBrandName = (currName) => {
    if (openBrandName === currName) setOpenBrandName('');
    if (openBrandName !== currName) setOpenBrandName(currName);
  };

  useEffect(function () {
    props.dispatch(brandsMounted());
  }, []);

  return (
    <>
      {isWizardModal ? (
        <MyBrandsWizard
          brands={brands}
          wizard={wizard}
          isWizard={isWizard}
          isWizardModal={isWizardModal}
          onClose={onClose}
          setNewWizardBrand={setNewWizardBrand}
        />
      ) : (
        <div className={classes.container}>
          <TopBar>
            <TopBarContent>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={4}
              >
                <Grid item style={{ flex: 1 }}>
                  <TopBarTitle>My Brands</TopBarTitle>
                </Grid>
              </Grid>
            </TopBarContent>
          </TopBar>
          {loading && (
            <Box width="100%" position="absolute" top={0} left={0} zIndex={1}>
              <LinearProgress variant="query" />
            </Box>
          )}
          <Container>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} md={10}>
                <Card
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {brands[0]?.map((item, index) => (
                    <AccordionMyBrands
                      isViewOnly={isViewOnly}
                      openBrandName={openBrandName}
                      handleSetBrandName={handleSetBrandName}
                      key={index}
                      index={index}
                      brand={item}
                    >
                      <MyBrandsEdit
                        handleSetBrandName={handleSetBrandName}
                        brand={item}
                        isViewOnly={isViewOnly}
                      />
                    </AccordionMyBrands>
                  ))}
                  {wizard && (
                    <MyBrandsWizard
                      brands={brands}
                      wizard={wizard}
                      isWizard={isWizard}
                      setNewWizardBrand={setNewWizardBrand}
                    />
                  )}
                  <div
                    className={
                      wizard
                        ? classes.containerButton
                        : classes.defaultContainerButton
                    }
                  >
                    {brands[0]?.length === 0 && !wizard && (
                      <div className={classes.brandNone}>
                        <Typography>
                          You don't have any brands. You can easily add a brand
                          and define the related media content. When tracking
                          channels you can select a brand to understand the
                          overall impact and measure the marketing performance
                          during an event or campaign.
                        </Typography>
                      </div>
                    )}
                    {!wizard && !isViewOnly && (
                      <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        disabled={loading}
                        color="primary"
                        style={{ margin: '25px 0px' }}
                        onClick={() => isWizard(!wizard)}
                      >
                        add new brand
                      </Button>
                    )}
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  );
};

export default enhance(MyBrandsMain);
