export const APP_MOUNTED = 'APP_MOUNTED';

export const INFLUENCER_MOUNTED = 'INFLUENCER_MOUNTED';
export const INFLUENCER_ONBOARDING_MOUNTED = 'INFLUENCER_ONBOARDING_MOUNTED';
export const INFLUENCERS_MOUNTED = 'INFLUENCERS_MOUNTED';
export const INFLUENCERS_FETCH_NEXT_PAGE = 'INFLUENCERS_FETCH_NEXT_PAGE';
export const INFLUENCERS_SEARCH_MOUNTED = 'INFLUENCERS_SEARCH_MOUNTED';
export const MY_WATCHLIST_MOUNTED_YOUTUBE = 'MY_WATCHLIST_MOUNTED_YOUTUBE';
export const MY_WATCHLIST_MOUNTED_TWITCH = 'MY_WATCHLIST_MOUNTED_TWITCH';

export const INFLUENCER_ONBOARDING_SUBMITTED =
  'INFLUENCER_ONBOARDING_SUBMITTED';
export const CAMPAIGN_DELETE_CLICKED = 'CAMPAIGN_DELETE_CLICKED';
export const CAMPAIGN_VIDEO_DELETE_CLICKED = 'CAMPAIGN_VIDEO_DELETE_CLICKED';
export const CAMPAIGN_MULTIPLE_VIDEOS_DELETED_CLICKED =
  'CAMPAIGN_MULTIPLE_VIDEOS_DELETED_CLICKED';
export const CAMPAIGN_SOCIAL_POST_DELETE = 'CAMPAIGN_SOCIAL_POST_DELETE';
export const CAMPAIGN_SOCIAL_POSTS_MENTIONS_DELETE =
  'CAMPAIGN_SOCIAL_POSTS_MENTIONS_DELETE';
export const CAMPAIGN_SHOUTOUT_DELETE_CLICKED =
  'CAMPAIGN_SHOUTOUT_DELETE_CLICKED';

export const CAMPAIGN_MOUNTED = 'CAMPAIGN_MOUNTED';
export const CAMPAIGNS_MOUNTED = 'CAMPAIGNS_MOUNTED';
export const CAMPAIGN_CREATION_SUBMITTED = 'CAMPAIGN_CREATION_SUBMITTED';
export const CAMPAIGN_UPDATE_SUBMITTED = 'CAMPAIGN_UPDATE_SUBMITTED';
export const CAMPAIGN_CREATION_STEP_ADVANCED =
  'CAMPAIGN_CREATION_STEP_ADVANCED';
export const SET_CAMPAIGN_TAB = 'SET_CAMPAIGN_TAB';
export const REFINE_SEARCH_CLICKED = 'REFINE_SEARCH_CLICKED';
export const MY_STATS_MOUNTED = 'MY_STATS_MOUNTED';

/*
 * this distinction is important do not unify
 *
 * above are events dispatched from the application rather than
 * traditional redux-action-types, they mainly exist for purposes
 * of de-coupling what happened in the application from what is
 * going to happen as a result of that (side-effect, handled in the sagas)
 *
 * generally these events don't directly cause changes of the state
 * but rather are used for other listeners (sagas / middlewares / reducers)
 * to listen and react to them any way they wish
 *
 * the events below are generally seen as effects / deltas / actions
 * the below ones do not get dispatched from the application but rather
 * as a reaction to previous events and will usually affect the state as
 * they pass through in one way or another
 *
 * the reason for this architecture is so that we can use redux as a pluggable
 * event system rather that a read-write machine
 *
 * ps: please keep alphabetical for sanity reasons :)
 * */

export const CAMPAIGN_CREATION_REQUESTED = 'CAMPAIGN_CREATION_REQUESTED';
export const CAMPAIGN_CREATION_SUCCEEDED = 'CAMPAIGN_CREATION_SUCCEEDED';
export const CAMPAIGN_CREATION_FAILED = 'CAMPAIGN_CREATION_FAILED';
export const CAMPAIGN_DELIVERABLES_REQUESTED =
  'CAMPAIGN_DELIVERABLES_REQUESTED';
export const CAMPAIGN_DELIVERABLES_REQUEST_SUCCEEDED =
  'CAMPAIGN_DELIVERABLES_REQUEST_SUCCEEDED';
export const CAMPAIGN_DELIVERABLES_REQUEST_FAILED =
  'CAMPAIGN_DELIVERABLES_REQUEST_FAILED';
export const CAMPAIGN_REQUESTED = 'CAMPAIGN_REQUESTED';
export const CAMPAIGN_REQUEST_SUCCEEDED = 'CAMPAIGN_REQUEST_SUCCEEDED';
export const CAMPAIGN_REQUEST_FAILED = 'CAMPAIGN_REQUEST_FAILED';
export const CAMPAIGN_REQUEST_RESET = 'CAMPAIGN_REQUEST_RESET';
export const CAMPAIGN_DELETE_REQUESTED = 'CAMPAIGN_DELETE_REQUESTED';
export const CAMPAIGN_DELETE_SUCCEEDED = 'CAMPAIGN_DELETE_REQUEST_SUCCEEDED';
export const CAMPAIGN_DELETE_FAILED = 'CAMPAIGN_DELETE_REQUEST_FAILED';
export const CAMPAIGN_VIDEO_DELETE_REQUESTED =
  'CAMPAIGN_VIDEO_DELETE_REQUESTED';
export const CAMPAIGN_VIDEO_DELETE_SUCCEEDED =
  'CAMPAIGN_VIDEO_DELETE_SUCCEEDED';
export const CAMPAIGN_VIDEO_DELETE_FAILED = 'CAMPAIGN_VIDEO_DELETE_FAILED';
export const CAMPAIGN_MULTIPLE_VIDEOS_DELETE_REQUESTED =
  'CAMPAIGN_MULTIPLE_VIDEOS_DELETE_REQUESTED';
export const CAMPAIGN_MULTIPLE_VIDEOS_DELETE_SUCCEEDED =
  'CAMPAIGN_MULTIPLE_VIDEOS_DELETE_SUCCEEDED';
export const CAMPAIGN_MULTIPLE_VIDEOS_DELETE_FAILED =
  'CAMPAIGN_MULTIPLE_VIDEOS_DELETE_FAILED';
export const CAMPAIGN_UPDATE_REQUESTED = 'CAMPAIGN_UPDATE_REQUESTED';
export const CAMPAIGN_UPDATE_SUCCEEDED = 'CAMPAIGN_UPDATE_SUCCEEDED';
export const CAMPAIGN_UPDATE_FAILED = 'CAMPAIGN_UPDATE_FAILED';
export const CAMPAIGNS_REQUESTED = 'CAMPAIGNS_REQUESTED';
export const CAMPAIGNS_REQUEST_SUCCEEDED = 'CAMPAIGNS_REQUEST_SUCCEEDED';
export const CAMPAIGNS_REQUEST_FAILED = 'CAMPAIGNS_REQUEST_FAILED';
export const DELIVERABLE_SHOUTOUTS_REQUESTED =
  'DELIVERABLE_SHOUTOUTS_REQUESTED';
export const DELIVERABLE_SHOUTOUTS_REQUEST_SUCCEEDED =
  'DELIVERABLE_SHOUTOUTS_REQUEST_SUCCEEDED';
export const DELIVERABLE_SHOUTOUTS_REQUEST_FAILED =
  'DELIVERABLE_SHOUTOUTS_REQUEST_FAILED';
export const INFLUENCER_REQUESTED = 'INFLUENCER_REQUESTED';
export const INFLUENCER_REQUEST_SUCCEEDED = 'INFLUENCER_REQUEST_SUCCEEDED';
export const INFLUENCER_REQUEST_FAILED = 'INFLUENCER_REQUEST_FAILED';
export const INFLUENCERS_SEARCH_RESET = 'INFLUENCERS_SEARCH_RESET';
export const INFLUENCERS_SEARCH_REQUESTED = 'INFLUENCERS_SEARCH_REQUESTED';
export const INFLUENCERS_SEARCH_REQUEST_SUCCEEDED =
  'INFLUENCERS_SEARCH_REQUEST_SUCCEEDED';
export const INFLUENCERS_SEARCH_REQUEST_FAILED =
  'INFLUENCERS_SEARCH_REQUEST_FAILED';
export const TOAST_EXPIRED = 'TOAST_EXPIRED';
export const TOAST_SPAWNED = 'TOAST_SPAWNED';
export const USER_REQUEST_SUCCEEDED = 'USER_REQUEST_SUCCEEDED';
export const USER_REQUEST_FAILED = 'USER_REQUEST_FAILED';
export const CAMPAIGN_SOCIAL_POST_DELETE_REQUESTED =
  'CAMPAIGN_SOCIAL_POST_DELETE_REQUESTED';
export const CAMPAIGN_SOCIAL_POST_DELETE_SUCCEEDED =
  'CAMPAIGN_SOCIAL_POST_DELETE_SUCCEEDED';
export const CAMPAIGN_SOCIAL_POST_DELETE_FAILED =
  'CAMPAIGN_SOCIAL_POST_DELETE_FAILED';

// watchlist actions
export const WATCHLISTS_REQUESTED = 'WATCHLISTS_REQUESTED';
export const WATCHLISTS_REQUEST_SUCCEEDED = 'WATCHLISTS_REQUEST_SUCCEEDED';
export const WATCHLISTS_REQUEST_FAILED = 'WATCHLISTS_REQUEST_FAILED';

export const RENAME_WATCHLIST_REQUESTED = 'RENAME_WATCHLIST_REQUESTED';
export const RENAME_WATCHLIST_SUCCEEDED = 'RENAME_WATCHLIST_SUCCEEDED';
export const RENAME_WATCHLIST_FAILED = 'RENAME_WATCHLIST_FAILED';

export const DELETE_WATCHLIST_REQUESTED = 'DELETE_WATCHLIST_REQUESTED';
export const DELETE_WATCHLIST_SUCCEEDED = 'DELETE_WATCHLIST_SUCCEEDED';
export const DELETE_WATCHLIST_FAILED = 'DELETE_WATCHLIST_FAILED';

export const CREATE_WATCHLIST_REQUESTED = 'CREATE_WATCHLIST_REQUESTED';
export const CREATE_WATCHLIST_SUCCEEDED = 'CREATE_WATCHLIST_SUCCEEDED';
export const CREATE_WATCHLIST_FAILED = 'CREATE_WATCHLIST_FAILED';

export const CAMPAIGN_VIDEO_ADD_FILTER_SUCCEEDED =
  'CAMPAIGN_VIDEO_ADD_FILTER_SUCCEEDED';

//Brand actions
export const BRANDS_REQUESTED = 'BRANDS_REQUESTED';
export const BRANDS_REQUEST_SUCCEEDED = 'BRANDS_REQUEST_SUCCEEDED';
export const BRANDS_REQUEST_FAILED = 'BRANDS_REQUEST_FAILED';

export const BRAND_DELETE_REQUESTED = 'BRAND_DELETE_REQUESTED';
export const BRAND_DELETE_SUCCEEDED = 'BRAND_DELETE_REQUEST_SUCCEEDED';
export const BRAND_DELETE_FAILED = 'BRAND_DELETE_REQUEST_FAILED';
export const BRAND_DELETE_CLICKED = 'BRAND_DELETE_CLICKED';

export const BRAND_MOUNTED = 'BRAND_MOUNTED';
export const BRANDS_MOUNTED = 'BRANDS_MOUNTED';

export const BRAND_CREATION_SUBMITTED = 'BRAND_CREATION_SUBMITTED';
export const BRAND_CREATION_REQUESTED = 'BRAND_CREATION_REQUESTED';
export const BRAND_CREATION_SUCCEEDED = 'BRAND_CREATION_SUCCEEDED';
export const BRAND_CREATION_FAILED = 'BRAND_CREATION_FAILED';

export const BRAND_UPDATE_SUBMITTED = 'BRAND_UPDATE_SUBMITTED';
export const BRAND_UPDATE_REQUESTED = 'BRAND_UPDATE_REQUESTED';
export const BRAND_UPDATE_SUCCEEDED = 'BRAND_UPDATE_SUCCEEDED';
export const BRAND_UPDATE_FAILED = 'BRAND_UPDATE_FAILED';
