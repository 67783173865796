import { isInfluencerView } from '../../../common/containers/Config/selectors';
import { State } from '../../../store/types';
import { LoadingState, shouldShowLoading } from '../../Campaign/types';
import { SLICE_NAME } from './types';

export const selectInfluencerInfoShowLoading = () => (state: State) =>
  shouldShowLoading(state[SLICE_NAME].dataLoadingState);

export const selectInfluencerInfoPending = () => (state: State) =>
  state[SLICE_NAME].dataLoadingState === LoadingState.PENDING;

export const selectInfluencerInfo = () => (state: State) =>
  state[SLICE_NAME].data;

export const selectInfluencerInfoError = () => (state: State) =>
  state[SLICE_NAME].dataError;

export const selectInfluencerDisconnectPlatformLoadingMap =
  () => (state: State) =>
    state[SLICE_NAME].disconnectPlatformLoadingMap;

export const selectInfluencerEmail = () => (state: State) =>
  selectInfluencerInfo()(state)?.email;

export const selectShouldFetchInfluencerInfo = () => (state: State) =>
  state[SLICE_NAME].dataLoadingState === LoadingState.IDLE &&
  isInfluencerView(state);

export const selectPutInfluencerInfoPending = () => (state: State) =>
  state[SLICE_NAME].putInfluencerInfoLoadingState === LoadingState.PENDING;

export const selectPutInfluencerInfoError = () => (state: State) =>
  state[SLICE_NAME].putInfluencerInfoError;

export const selectInfluencerHasSocialMediaAccounts = () => (state: State) => {
  return !!(
    state[SLICE_NAME].data?.onboarding_facebook_pages.length ||
    state[SLICE_NAME].data?.onboarding_twitter_pages.length ||
    state[SLICE_NAME].data?.onboarding_instagram_pages.length ||
    state[SLICE_NAME].data?.onboarding_tiktok_pages.length
  );
};

export const selectInfluencerHasSocialMediaAccountsIsProcessing =
  () => (state: State) => {
    return !!(
      !state[SLICE_NAME].data?.onboarding_facebook_pages[0]?.processing ||
      !state[SLICE_NAME].data?.onboarding_twitter_pages[0]?.processing ||
      !state[SLICE_NAME].data?.onboarding_instagram_pages[0]?.processing ||
      !state[SLICE_NAME].data?.onboarding_tiktok_pages[0]?.processing
    );
  };

export const selectInfluencerHasStreamingAccounts = () => (state: State) => {
  return !!(
    state[SLICE_NAME].data?.onboarding_twitch_pages.length ||
    state[SLICE_NAME].data?.onboarding_youtube_pages.length ||
    state[SLICE_NAME].data?.onboarding_facebook_pages.length
  );
};
