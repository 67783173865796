import {
  Button,
  Card,
  CardContent,
  Chip,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import React, { useState } from 'react';

import FormikMuiTextField from '../../../../components/FormikMuiTextField';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipLabel: {
    maxWidth: 350,
  },
}));

export const Links = ({ isViewOnly, id }) => {
  const classes = useStyles();
  const [duplicate, isDuplicate] = useState(false);
  const { values, setFieldValue, errors, isValidating } = useFormikContext();

  const onAdd = (arrayHelpers) => {
    if (!(values.newLink && !errors.newLink && !isValidating)) return;
    if (!values.links.includes(values.newLink)) {
      isDuplicate(false);
      arrayHelpers.push(values.newLink.trim());
      setFieldValue('newLink', '');
    } else {
      isDuplicate(true);
    }
  };
  return (
    <Card className={classes.container}>
      <CardContent>
        <Typography variant="h2">Link sharing</Typography>
      </CardContent>
      <FieldArray
        name="links"
        render={(arrayHelpers) => (
          <>
            <CardContent>
              {!isViewOnly && (
                <div className={classes.inputContainer}>
                  <FormikMuiTextField
                    fullWidth
                    placeholder="Add link"
                    variant="outlined"
                    value={values.newLink}
                    name="newLink"
                    onChange={(e) => {
                      setFieldValue('newLink', e.target.value);
                      isDuplicate(false);
                    }}
                    className={classes.input}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          onAdd(arrayHelpers);
                        }
                      },
                      'data-id': `input-links-${id}`,
                    }}
                  />
                  <Button
                    data-id={`button-links-${id}`}
                    size="large"
                    variant="outlined"
                    color="primary"
                    style={{ padding: 9.5 }}
                    disabled={
                      !values.newLink || !!errors.newLink || isValidating
                    }
                    onClick={() => onAdd(arrayHelpers)}
                  >
                    Add
                  </Button>
                </div>
              )}
              {duplicate && (
                <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                  "{values.newLink}" is already added
                </p>
              )}
            </CardContent>
            <CardContent component="ul" className={classes.chipsContainer}>
              {values.links.map((keyword, index) => (
                <li key={index} data-id={`chip-links-${id}-${index}`}>
                  <Chip
                    label={keyword}
                    variant="outlined"
                    className={classes.chip}
                    onDelete={
                      isViewOnly
                        ? undefined
                        : () => {
                            arrayHelpers.remove(index);
                            keyword === values.newLink && isDuplicate(false);
                          }
                    }
                    classes={{ label: classes.chipLabel }}
                    title={keyword}
                  />
                </li>
              ))}
            </CardContent>
          </>
        )}
      />
    </Card>
  );
};

export default Links;
