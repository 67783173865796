import { useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PROFILE } from '../../../../common/constants/paths';
// import { DeleteCell } from '../../../../components/DeleteCell/DeleteCell';
import { InfluencerAvatar } from '../../../../components/InfluencerAvatar/InfluencerAvatar';
import { LabelButton } from '../../../../components/LabelButton/LabelButton';
import { PostCell } from '../../../../components/PostCell/PostCell';
import { SocialMediaPlatform } from '../../../../types';
import { ComponentSize } from '../../../../utils/ComponentSize';
import Table, { StatBox } from '../../../Campaign/Table';
import { selectInfluencerHasSocialMediaAccounts } from '../../store/InfluencerInfo.selectors';
import { fakePosts } from './store/fakeData';
import { selectAllSocialMediaPosts } from './store/SocialMediaPosts.slice';
import { SocialMediaPost, SocialPostAttachment } from './store/types';

type Row = {
  owner: {
    name: string;
    avatar: string;
  };
  comments: number;
  engagement: number;
  id: number;
  impressions: number;
  likes: number;
  link: string;
  platform: SocialMediaPlatform;
  date: string;
  reactions: number;
  shares: number;
  text: string;
  key: string;
  image: string;
  emv: number;
  attachments?: SocialPostAttachment[];
  thumbnailUrl?: string;
  isLocked?: boolean;
  lockedContent?: JSX.Element;
};

const getCols = ({
  size,
  rowHeight,
}: {
  size?: Extract<ComponentSize, 'sm'>;
  rowHeight: number;
}) => {
  const statFontSize = size === 'sm' ? '1.25rem' : '1.5rem';

  return [
    {
      label: 'Username',
      key: 'creator',
      render: (row: Row) => (
        <InfluencerAvatar influencer={row.owner} size={size} />
      ),
      // row.isLocked ? (
      //   <InfluencerAvatar influencer={row.owner} size={size} />
      // ) : (
      //   <DeleteCell
      //     size={size}
      //     content={<InfluencerAvatar influencer={row.owner} size={size} />}
      //     onClick={() => {
      //       console.log('do something');
      //     }}
      //     height={rowHeight}
      //     title="Delete Post"
      //   />
      // ),
      flex: size === 'sm' ? 1 : 2,
      stretch: true,
    },
    {
      label: 'Posts',
      key: 'date',
      sortKey: 'date_unix',
      render: (row: Row) => (
        <PostCell
          text={row.text}
          attachments={row.attachments}
          link={row.link}
          platform={row.platform}
          date={row.date}
          size={size}
          rowHeight={rowHeight}
          thumbnailUrl={row.thumbnailUrl}
        />
      ),
      stretch: true,
      flex: size === 'sm' ? 1 : 2,
    },
    {
      label: 'Retweets/Shares',
      key: 'shares',
      render: (row: Row) => (
        <StatBox stat={row.shares} statFontSize={statFontSize} />
      ),
    },
    {
      label: 'Likes',
      key: 'likes',
      render: (row: Row) => (
        <StatBox stat={row.likes} statFontSize={statFontSize} />
      ),
    },
    {
      label: 'Replies/Comments',
      key: 'comments',
      render: (row: Row) => (
        <StatBox stat={row.comments} statFontSize={statFontSize} />
      ),
    },
    {
      label: 'Impressions',
      key: 'impressions',
      render: (row: Row) => (
        <StatBox stat={row.impressions} statFontSize={statFontSize} />
      ),
    },
    {
      label: 'Media Value',
      key: 'emv',
      render: (row: Row) => (
        <StatBox
          stat={row.emv}
          format="0,[0].[0]a"
          prefix="$"
          statFontSize={statFontSize}
        />
      ),
    },
  ].filter((col) => col !== null);
};

const getRows = ({
  posts,
  size,
  locked,
  bluredData,
}: {
  posts: SocialMediaPost[];
  size?: Extract<ComponentSize, 'sm'>;
  locked?: boolean;
  bluredData?: boolean;
}): Row[] => {
  return posts.map((post) => {
    const {
      owner_avatar,
      owner_name,
      comments_count,
      engagements_count,
      id,
      impressions_count,
      likes_count,
      permalink,
      shares_count,
      text,
      platform,
      published_at,
      reactions_count,
      image,
      emv,
      attachments,
      media_url,
      thumbnail_url,
      replies,
      retweets,
    } = post;
    const date = moment(new Date(published_at));

    return {
      owner: {
        name: owner_name,
        avatar: owner_avatar,
      },
      date: date.format('DD MMM YYYY, h:mm A'),
      date_unix: date.unix(),
      reactions: reactions_count,
      comments: comments_count || replies,
      link: permalink,
      engagement: engagements_count,
      id,
      impressions: impressions_count,
      likes: likes_count,
      platform,
      shares: shares_count || retweets,
      text,
      image,
      emv,
      attachments,
      key: `${platform}_${id}`,
      thumbnailUrl: media_url || thumbnail_url,
      isLocked: bluredData || locked,
      lockedContent: !bluredData && (
        <LockedRowContent size={size} platform={platform} />
      ),
    };
  });
};

export const SocialMediaTable = ({
  isLoading,
  bluredData,
}: {
  isLoading: boolean;
  bluredData?: boolean;
}) => {
  const theme = useTheme();
  const size = useMediaQuery(theme.breakpoints.down('sm')) ? 'sm' : null;
  const rowHeight = size === 'sm' ? 112 : 148;
  const minWidth = size === 'sm' ? 1000 : 1300;
  const posts = useSelector(selectAllSocialMediaPosts);
  const hasSocialMediaAccounts = useSelector(
    selectInfluencerHasSocialMediaAccounts()
  );
  const usedPosts = bluredData
    ? fakePosts
    : hasSocialMediaAccounts
    ? posts
    : fakePosts;

  const rows = useMemo(
    () =>
      getRows({
        size,
        posts: usedPosts,
        locked: !hasSocialMediaAccounts,
        bluredData,
      }),
    [size, usedPosts, hasSocialMediaAccounts, bluredData]
  );
  const cols = useMemo(() => getCols({ size, rowHeight }), [size, rowHeight]);

  const idTable = 'mystats-social-media-table';

  if (isLoading) {
    return null;
  }

  return (
    <Table
      cols={cols}
      rows={rows}
      rowHeight={rowHeight}
      minWidth={minWidth}
      defaultSortCol="date_unix"
      idTable={idTable}
      // Added the below props otherwise TS would complain
      sortKey={undefined}
      noBorders={undefined}
      defaultIncreasing={undefined}
      onRowClick={undefined}
      onCreatorColClick={undefined}
    />
  );
};

const LockedRowContent = ({
  size,
  platform,
}: {
  size: Extract<ComponentSize, 'sm'>;
  platform: SocialMediaPlatform;
}) => {
  const history = useHistory();

  return (
    <LabelButton
      text={`Connect to your ${platform} account to unlock`}
      size={size}
      onClick={() => {
        history.push(`${PROFILE}?socialCallback=true`);
      }}
    />
  );
};
