import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import React from 'react';

export default function CampaignDeleteConfirmation(props) {
  const { isOpen, handleClose, handleConfirm } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this campaign?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: '12px 24px' }}
        >
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleConfirm} color="primary">
            <Box clone mr={1}>
              <Icon>delete_outline</Icon>
            </Box>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
