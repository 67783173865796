import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CampaignCommentDeleteConfirmation, {
  useCampaignCommentDeleteConfirmation,
} from '../../CampaignCommentDeleteConfirmation';
import { DeliverablePaginator } from '../../components/DeliverablePaginator';
import ChatMentionDeliverable from './ChatMentionDeliverable';
import {
  selectChatMentionDeliverablesError,
  selectFilteredChatMentionDeliverablesStructures,
} from './store/ChatMentionDeliverables.selectors';
import { deleteChatMention } from './store/ChatMentionDeliverables.thunks';
import { ChatMention, ChatMentionDeliverableStructure } from './store/types';

export const ChatMentions = ({
  campaign,
  usedVideos,
  usedVideosIds,
  usedInfluencers,
  setSelectedCampaignInfluencer,
  isSponsorship,
  brandFilter,
  isFilteredByCreator,
  setByCreator,
  numberPage,
  setNumberPage,
}) => {
  const dispatch = useDispatch();

  const { id: campaignId } = campaign;

  const error = useSelector(selectChatMentionDeliverablesError());

  const chatMentionDeliverablesStructures = useSelector(
    selectFilteredChatMentionDeliverablesStructures({
      campaignId: campaign.id,
      brandIdFilter: brandFilter,
      onlyBrandDeliverables: isSponsorship,
      sortBy: { key: 'results_count', order: 'desc' },
    })
  );

  const { setCommentToDelete, commentToDelete } =
    useCampaignCommentDeleteConfirmation();

  useEffect(() => {
    if (!!numberPage) {
      return () => {
        setNumberPage(0);
      };
    }
  }, [numberPage, setNumberPage]);

  const handleConfirmDeleteComment = useCallback(
    (
      comment: ChatMention & { chatMentionDeliverableId: number },
      deleteAll: boolean
    ) => {
      console.log({ comment });

      dispatch(
        deleteChatMention({
          chatMentionDeliverableId: comment.chatMentionDeliverableId,
          commentId: comment.id,
          campaignId,
          deleteAll,
          commenterExternalId: comment.commenter_external_id,
        })
      );
    },
    [campaignId, dispatch]
  );

  const renderDeliverable = useCallback(
    (chatMentionDeliverableStructure: ChatMentionDeliverableStructure) => (
      <ChatMentionDeliverable
        key={chatMentionDeliverableStructure.id}
        deleteComment={setCommentToDelete}
        chatMentionDeliverableStructure={chatMentionDeliverableStructure}
        usedVideos={usedVideos}
        usedVideosIds={usedVideosIds}
        usedInfluencers={usedInfluencers}
        timeZone={campaign.time_zone}
        setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
        isFilteredByCreator={isFilteredByCreator}
        setByCreator={setByCreator}
        isSponsorship={isSponsorship}
      />
    ),
    [
      usedVideos,
      usedVideosIds,
      usedInfluencers,
      campaign,
      setSelectedCampaignInfluencer,
      isFilteredByCreator,
      setByCreator,
      isSponsorship,
      setCommentToDelete,
    ]
  );

  if (error) return <p>error</p>;

  return (
    <div style={{ paddingTop: 24 }}>
      <CampaignCommentDeleteConfirmation
        commentToDelete={commentToDelete}
        handleConfirmDeleteComment={handleConfirmDeleteComment}
        setCommentToDelete={setCommentToDelete}
      />
      <DeliverablePaginator
        renderDeliverable={renderDeliverable}
        deliverables={chatMentionDeliverablesStructures}
        forcePageIndex={numberPage}
      />
    </div>
  );
};

export default ChatMentions;
