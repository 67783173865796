import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _uniqBy from 'lodash/uniqBy';
import React from 'react';

import CampaignWizardInfluencers from '../CampaignWizard/CampaignWizardInfluencers';

export const ChannelsTab = (props) => {
  const errorsLinks = _uniqBy(
    props.arrayErrors.filter((el) => el.hasOwnProperty('link')),
    'link'
  );
  // const errorsEmptyData = props.arrayErrors.filter((el) =>
  //   el.hasOwnProperty('name')
  // );

  return (
    <Grid item>
      <Alert severity="warning" style={{ marginBottom: 16 }}>
        New channels will be tracked starting from the moment they were added.
      </Alert>
      {errorsLinks.length !== 0 && (
        <Alert severity="error" style={{ marginBottom: 16 }}>
          Remove duplicate fields.
          {errorsLinks.map((err, index) => (
            <b key={`link-${index}`}>
              <br />
              {err.link}
            </b>
          ))}
        </Alert>
      )}
      {/* {errorsEmptyData.length !== 0 && (
        <Alert severity="error" style={{ marginBottom: 16 }}>
          Add data to these users:
          {errorsEmptyData.map((err, index) => (
            <b key={`name-${index}`}>
              <br />
              {err.name}
            </b>
          ))}
        </Alert>
      )} */}
      <CampaignWizardInfluencers {...props} dontExpand shouldConfirmRemoval />
    </Grid>
  );
};

export default ChannelsTab;
