import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { capitalize } from 'lodash';
import React, { useMemo } from 'react';

import Table, { TableText } from '../Table';
import { TopMoment } from './types';

const getCols = () => {
  return [
    {
      label: 'Comment',
      key: 'comment',
      render: (row: Row) => {
        return (
          <TableText style={{ whiteSpace: 'normal' }} fontSize="0.8rem">
            {row.comment}
          </TableText>
        );
      },
      stretch: true,
      flex: 2,
    },
    {
      label: 'Topic',
      key: 'topic',
      render: (row: Row) => {
        return <TableText fontSize="0.8rem">{row.topic}</TableText>;
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Category',
      key: 'category',
      render: (row: Row) => {
        return <TableText fontSize="0.8rem">{row.category}</TableText>;
      },
      stretch: true,
      flex: 0.5,
    },
  ];
};

const getRows = (data: TopMoment[]) => {
  return data.map((row) => {
    return {
      comment: row.comment,
      topic: capitalize(row.topic),
      category: capitalize(row.category),
    };
  });
};

type Row = ReturnType<typeof getRows>[0];

export const CommentsTableAccordion = ({ data }: { data: TopMoment[] }) => {
  const rows = useMemo(() => getRows(data), [data]);
  const cols = useMemo(() => getCols(), []);

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Show Comments</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 8 }}>
          <div style={{ width: '100%' }}>
            <Table
              cols={cols}
              rows={rows}
              rowHeight={80}
              minWidth={800}
              defaultSortCol="comment"
              idTable={'comments_table'}
              sortKey={undefined}
              noBorders={undefined}
              defaultIncreasing={undefined}
              onRowClick={undefined}
              onCreatorColClick={undefined}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
