import _filter from 'lodash/filter';
import _maxBy from 'lodash/maxBy';
import _meanBy from 'lodash/meanBy';
import _sumBy from 'lodash/sumBy';
import React, { useCallback, useMemo } from 'react';

import InfluencerAvatar from '../../components/InfluencerAvatar';
import Table, { StatBox } from '../../Table';
import { findInfluencerByVideo, getVideoDuration } from '../../utils';

export const computeSummary = ({ usedVideos }) => {
  const followers = _sumBy(usedVideos, 'followers_gained');

  const streams = usedVideos.length;

  const hoursStreamed = _sumBy(usedVideos, getVideoDuration) / (60 * 60);

  const hoursWatched =
    _sumBy(usedVideos, (v) => getVideoDuration(v) * v.avg_cc_viewers) /
    (60 * 60);

  const comments = _sumBy(usedVideos, 'comments_count');

  const comments_YT = _sumBy(
    _filter(usedVideos, function (usedVideo) {
      return usedVideo.resource === 'youtube';
    }),
    'comments_count'
  );

  const engagementRate = _meanBy(usedVideos, 'engagement_rate');

  const totalViews = _sumBy(usedVideos, (v) => {
    return v.computed_total_views || v.total_views;
  });

  const avgCCV = _meanBy(
    usedVideos.filter((el) => el.avg_cc_viewers !== null),
    'avg_cc_viewers'
  );

  const peakCCV = (
    _maxBy(usedVideos, 'max_cc_viewers') || { max_cc_viewers: null }
  ).max_cc_viewers;

  const totalEMV = _sumBy(usedVideos, 'emv');

  return {
    streams,
    hoursStreamed,
    hoursWatched,
    comments,
    engagementRate,
    totalViews,
    avgCCV,
    peakCCV,
    totalEMV,
    followers,
    comments_YT,
  };
};

const getRows = ({ usedInfluencers, usedVideos }) =>
  usedInfluencers.map((influencer) => {
    const videos = usedVideos.filter(
      (video) =>
        findInfluencerByVideo(video, usedInfluencers).id === influencer.id
    );

    const {
      streams,
      hoursStreamed,
      hoursWatched,
      comments,
      engagementRate,
      totalViews,
      avgCCV,
      peakCCV,
      totalEMV,
      followers,
    } = computeSummary({
      usedVideos: videos,
    });

    return {
      influencer,
      creator: influencer.name,
      followers,
      streams,
      hoursStreamed,
      hoursWatched,
      comments,
      engagementRate,
      totalViews,
      avgCCV,
      peakCCV,
      totalEMV,
      key: influencer.id,
    };
  });

const getCols = () =>
  [
    {
      label: 'Creator',
      key: 'creator',
      render: (row) => <InfluencerAvatar influencer={row.influencer} />,
      stretch: true,
    },
    {
      label: 'Videos',
      key: 'streams',
      render: (data) => <StatBox stat={data.streams} />,
    },
    {
      label: 'Hrs. Watched',
      key: 'hoursWatched',
      render: (row) => <StatBox stat={row.hoursWatched} />,
    },
    {
      label: 'Duration hrs.',
      key: 'hoursStreamed',
      render: (row) => <StatBox stat={row.hoursStreamed} />,
    },
    {
      label: 'Followers',
      key: 'followers',
      render: (row) => <StatBox stat={row.streams && row.followers} />,
    },
    {
      label: 'Comments',
      key: 'comments',
      render: (row) => <StatBox stat={row.comments} />,
    },
    {
      label: 'Engagement',
      key: 'engagementRate',
      render: (row) => <StatBox stat={row.engagementRate} suffix="%" />,
    },
    {
      label: 'Avg. CCV',
      key: 'avgCCV',
      render: (data) => <StatBox stat={data.avgCCV} />,
    },
    {
      label: 'Peak CCV',
      key: 'peakCCV',
      render: (data) => <StatBox stat={data.peakCCV} />,
    },
    {
      label: 'Views',
      key: 'totalViews',
      render: (data) => <StatBox stat={data.totalViews} />,
    },
    {
      label: 'Value',
      key: 'totalEMV',
      render: (data) => (
        <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
      ),
    },
  ].filter((c) => c !== null);

export const SummaryTable = ({
  usedInfluencers,
  usedVideos,
  hasTwitch,
  setSelectedCampaignInfluencer,
}) => {
  const rows = useMemo(
    () => getRows({ usedInfluencers, usedVideos }),
    [usedVideos, usedInfluencers]
  );

  const cols = useMemo(() => getCols(), []);

  const onRowClick = useCallback(
    ({ key }) => setSelectedCampaignInfluencer(key),
    [setSelectedCampaignInfluencer]
  );
  const idTable = 'performanceTab-streaming-summary';

  return (
    <Table
      cols={cols}
      rows={rows}
      defaultSortCol="streams"
      minWidth={1390}
      onRowClick={onRowClick}
      idTable={idTable}
    />
  );
};

export default SummaryTable;
