import { createSlice } from '@reduxjs/toolkit';

import { ErrorMessage } from '../../../../common/ErrorMessageUtil';
import { LoadingState } from '../../../Campaign/types';
import { signinInfluencer } from './InfluencerSignin.thunks';
import { InfluencerSigninState, SLICE_NAME } from './types';

const initialState: InfluencerSigninState = {
  loadingState: LoadingState.IDLE,
  error: null,
};

export const { reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(signinInfluencer.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.error = null;
      })
      .addCase(signinInfluencer.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.error = action.payload as ErrorMessage[];
      })
      .addCase(signinInfluencer.fulfilled, (state) => {
        state.loadingState = LoadingState.FULLFILLED;
      });
  },
});
