import moment from 'moment';
import momentTz from 'moment-timezone';

import { getVideoDuration, videoIsFromStreamingPlatform } from '../utils';
import { getInfluencer } from './utils';

const computeStreams = ({ videos, campaign_influencers, time_zone }) => {
  const rows = [];

  rows.push([
    'Creator Name',
    'Platform',
    'Type',
    'Title',
    'Start Date',
    'End Date',
    'Duration',
    'EngagementRate',
    'Average CCV',
    'Peak CCV',
    'Total Views',
    'Hours Watched',
    'Comments',
    'Media Value',
    'Language',
    'Link',
  ]);

  // Only use the videos from the streaming platforms
  const streamVideos = videos.filter((video) =>
    videoIsFromStreamingPlatform(video)
  );

  rows.push(
    ...streamVideos.map((video) => {
      const influencer = getInfluencer(
        campaign_influencers,
        video.campaign_influencer_ids
      );

      const videoDuration = getVideoDuration(video);

      return [
        influencer.name,
        video.resource,
        video.video_type === 'live-vod' ? 'Live Stream' : 'VOD',
        video.video_title,
        momentTz.tz(video.published_at, time_zone).format('MM/DD/YYYY'),
        momentTz
          .tz(video.published_at, time_zone)
          .add(videoDuration, 'seconds')
          .format('MM/DD/YYYY'),
        moment.duration(videoDuration, 'seconds').format('HH:mm:ss'),
        video.engagement_rate,
        video.avg_cc_viewers,
        video.max_cc_viewers,
        video.computed_total_views,
        parseFloat(
          ((videoDuration * video.avg_cc_viewers) / (60 * 60)).toFixed(2)
        ),
        video.comments_count,
        video.emv,
        video.language,
        video.link,
      ];
    })
  );

  return rows;
};

export default computeStreams;
