import moment from 'moment';
import qs from 'query-string';

import { PATH_SIGNIN_YOUTUBE_CALLBACK } from './common/constants/paths';
import { PATH_SIGNIN_TIKTOK_CALLBACK } from './common/constants/paths';
import { getUnixOneMonthAgo } from './helpers/timeAgoFilterValues';
import { store } from './store';
import between from './utils/between';
import overwriteDeep from './utils/overwrite-deep';
export const aggero = process.env.REACT_APP_AGGERO_API_URL;
const search = process.env.REACT_APP_AGGERO_SEARCH_API_URL;
const searchApiKey = process.env.REACT_APP_AGGERO_SEARCH_API_KEY;
export const MAX_CONCURRENT_REQUESTS = 2;

const request = (url, params = {}, forceToken) => {
  let headers = {};

  const { token } = store.getState().account.authUser || {};

  if (token || forceToken) {
    headers['Authorization'] = `Bearer ${token || forceToken}`;
  }

  return fetch(url, overwriteDeep({ headers }, params)).then(status).then(json);
};

const status = (response) => {
  if (between(400, 600)(response.status)) {
    return response.json().then((result) => {
      response.bodyJson = result;

      throw response;
    });
  }

  return response;
};

const json = (response) => response.json().catch(() => response);

export const getStaticGames = () => request(`${aggero}/static/games`);

export const getCampaigns = () => request(`${aggero}/campaigns`);

export const getCampaign = (campaignId) =>
  request(`${aggero}/campaigns/${campaignId}`);

export const getCampaignDeliverables = (campaignId) =>
  request(`${aggero}/campaigns/${campaignId}/deliverables`);

export const getCampaignBannerOccurrences = (
  campaignId,
  deliverableId,
  videoId
) =>
  request(
    `${aggero}/campaigns/${campaignId}/deliverables/${deliverableId}/banner_occurrences?video_id=${videoId}`
  );

export const getDeliverablesChartData = (campaignId, videoId) =>
  request(`${aggero}/campaigns/${campaignId}/videos/${videoId}/graph_data`);

export const recordUserSession = ({ action, misc }) => {
  const { email = '' } = store.getState().account.authUser || {};

  if (email.indexOf('@aggero') === -1) {
    request(`${aggero}/user_sessions`, {
      method: 'POST',
      body: JSON.stringify({
        user_action: action,
        url: window.location.href,
        misc,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
  }
};

export const postCampaign = (data) =>
  request(`${aggero}/campaigns`, {
    method: 'POST',
    body: JSON.stringify({ ...data, v2: true }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postCampaignBlacklistVideoComment = (
  campaignId,
  video_comment_ids,
  commenter_external_ids
) => {
  return request(`${aggero}/campaigns/${campaignId}/blacklist_video_comments`, {
    method: 'POST',
    body: JSON.stringify({
      video_comment_ids: video_comment_ids
        ? video_comment_ids.toString()
        : null,
      commenter_external_ids: commenter_external_ids
        ? commenter_external_ids.toString()
        : null,
      campaign_id: campaignId,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
};

export const putCampaign = (id, data) =>
  request(`${aggero}/campaigns/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });

export const deleteCampaign = (campaignId) =>
  request(`${aggero}/campaigns/${campaignId}`, {
    method: 'DELETE',
  });

export const deleteCampaignVideo = (videoId, campaignId) =>
  request(`${aggero}/campaigns/${campaignId}/videos/${videoId}`, {
    method: 'DELETE',
  });

export const deleteMultipleCampaignVideos = ({ videoIds, campaignId }) => {
  return request(
    `${aggero}/campaigns/${campaignId}/videos/delete_all?video_ids=${videoIds.toString()}`,
    {
      method: 'DELETE',
    }
  );
};

export const deleteSocialPostsMentions = ({
  campaignId,
  section,
  twitterIds,
  instagramIds,
  facebookIds,
  tiktokIds,
}) => {
  return request(`${aggero}/campaigns/${campaignId}/blacklist_social_media`, {
    method: 'POST',
    body: JSON.stringify({
      campaign_id: campaignId,
      section,
      twitter_ids: twitterIds.toString(),
      instagram_ids: instagramIds.toString(),
      facebook_ids: facebookIds.toString(),
      tiktok_ids: tiktokIds.toString(),
    }),
    headers: { 'Content-Type': 'application/json' },
  });
};

// @TODO - change this with delete social post api when ready
export const deleteSocialPost = (campaignId, payload) =>
  request(`${aggero}/campaigns/${campaignId}/blacklist_social_media`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
  });

export const deleteMultipleSocialPosts = ({ campaign_id, postsMap }) => {
  return request(
    `${aggero}/campaigns/${campaign_id}/social_media_performances/delete_social_posts`,
    {
      method: 'DELETE',
      body: JSON.stringify({
        campaign_id,
        data: postsMap,
      }),
      headers: { 'Content-Type': 'application/json' },
    }
  );
};

export const getMyStats = (influencerId) =>
  request(`${aggero}/api/v1/my_stats`);

export const getInfluencerWordcloud = (influencerId) =>
  request(`${aggero}/api/v1/influencer/${influencerId}/wordclouds/generate`);

export const getInstagramPostsByTag = (tag, options = {}) => {
  const after = {
    '1_month': getUnixOneMonthAgo(),
    '10_years': moment().subtract(10, 'years').unix(),
  }[options.time || '10_years'];

  if (!after) {
    console.error('Invalid option `time` passed to getInstagramPostsByTag');
  }

  return request(
    `${search}/instagram/posts/tag/${tag}?key=${searchApiKey}&after=${after}&page_size=10000`
  );
};

export const getUserData = (token) =>
  request(`${aggero}/api/v1/user`, {}, token);

export const postMissingSearchResults = ({ filters, results_count }) =>
  request(`${aggero}/missing_search_results`, {
    method: 'POST',
    body: JSON.stringify({
      filters,
      results_count,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postRecommendedStreamerIrrelevant = ({ influencerName }) =>
  request(
    `${aggero}/api/v1/recommended_streamers/${influencerName}/irrelevant`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const saveUserData = (payload) =>
  request(`${aggero}/api/v1/user`, {
    method: 'POST',
    body: JSON.stringify({
      user: payload,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const getInfluencer = (influencerName, network) =>
  request(`${aggero}/api/v1/influencers/${network}/${influencerName}`);

export const getInfluencerV2 = (influencerName, network) =>
  request(
    `${aggero}/api/v2/influencers/${network}/${encodeURI(influencerName)}`
  );

export const postInfluencerMissingData = (data, options) =>
  request(`${aggero}/api/v2/influencers/missing_data`, {
    method: 'POST',
    body: JSON.stringify({
      ...options,
      data,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const getTwitterToken = (redirectRoute) => {
  return request(
    `${aggero}/api/v1/twitter/oauth?oauth_callback=${window.location.origin}${redirectRoute}`
  );
};

export const postFacebookLogin = ({ accessToken, userID }) =>
  request(`${aggero}/api/v1/sessions/facebook`, {
    method: 'POST',
    body: JSON.stringify({
      access_token: accessToken,
      user_id: userID,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postTwitterLogin = (oauthToken, oauthVerifier) =>
  request(`${aggero}/api/v1/sessions/twitter`, {
    method: 'POST',
    body: JSON.stringify({
      oauth_token: oauthToken,
      oauth_verifier: oauthVerifier,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postTwitterCampaignOnboarding = (oauthToken, oauthVerifier) =>
  request(`${aggero}/api/v1/campaign_onboarding/twitter`, {
    method: 'POST',
    body: JSON.stringify({
      oauth_token: oauthToken,
      oauth_verifier: oauthVerifier,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postTikTokCampaignOnboarding = (access_code) =>
  request(`${aggero}/api/v1/campaign_onboarding/twitter`, {
    method: 'POST',
    body: JSON.stringify({
      access_code,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const getCampaignOnboardingForPageName = (pageName, socialNetwork) =>
  request(
    `${aggero}/api/v1/campaign_onboarding/for_name?name=${pageName}&network=${socialNetwork}`
  );

export const postFacebookCampaignOnboarding = ({
  accessToken,
  userID,
  pageName,
  onboardingNetwork,
}) =>
  request(`${aggero}/api/v1/campaign_onboarding/facebook`, {
    method: 'POST',
    body: JSON.stringify({
      access_token: accessToken,
      user_id: userID,
      page_name: pageName,
      onboarding_network: onboardingNetwork,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postTwitchLogin = (accessToken) =>
  request(`${aggero}/api/v1/sessions/twitch`, {
    method: 'POST',
    body: JSON.stringify({
      access_token: accessToken,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postYoutubeLogin = (accessCode, target) =>
  request(`${aggero}/api/v1/sessions/youtube`, {
    method: 'POST',
    body: JSON.stringify({
      access_code: accessCode,
      redirect_uri: `${window.location.origin}${PATH_SIGNIN_YOUTUBE_CALLBACK}${
        target ? `?t=${target}` : ''
      }`,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postTwitchConnect = (accessToken) =>
  request(`${aggero}/api/v1/sessions/twitch_connect`, {
    method: 'POST',
    body: JSON.stringify({
      access_token: accessToken,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postYoutubeConnect = (accessCode, target) =>
  request(`${aggero}/api/v1/sessions/youtube_connect`, {
    method: 'POST',
    body: JSON.stringify({
      access_code: accessCode,
      redirect_uri: `${window.location.origin}${PATH_SIGNIN_YOUTUBE_CALLBACK}${
        target ? `?t=${target}` : ''
      }`,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postSessionLogin = (email, password, withOrganization = true) =>
  request(`${aggero}/api/v1/sessions?with_organization=${withOrganization}`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postTiktokLogin = async (code, scopes, redirectUri) =>
  request(`${aggero}/api/v1/sessions/tiktok`, {
    method: 'POST',
    body: JSON.stringify({
      access_code: code,
      redirect_uri: `${
        window.location.origin
      }${PATH_SIGNIN_TIKTOK_CALLBACK}?t=${
        qs.parseUrl(window.location.href).query.t
      }`,
      scopes: scopes,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const signupInfluencer = (email, password) =>
  request(`${aggero}/api/v1/creator`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
export const putInfoInfluencer = (creator) =>
  request(`${aggero}/api/v1/creator`, {
    method: 'PUT',
    body: JSON.stringify({
      creator,
    }),
    headers: { 'Content-Type': 'application/json' },
  });
export const putNewPasswordInfluencer = (current_password, password) =>
  request(`${aggero}/api/v1/creator/change_password`, {
    method: 'PUT',
    body: JSON.stringify({
      current_password,
      password,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const putUserPassword = (data) =>
  request(`${aggero}/api/v1/users/change_password`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });

export const putUser = (id, data) =>
  request(`${aggero}/api/v1/users/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });

export const confirmInfluencerEmailChange = (token) =>
  request(`${aggero}/api/v1/users/change_email`, {
    method: 'POST',
    body: JSON.stringify({
      confirmation_token: token,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postForgotPassword = (email) =>
  request(`${aggero}/api/v1/users/forgot_password`, {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const postResetPassword = (password, token) =>
  request(`${aggero}/api/v1/users/reset_password`, {
    method: 'POST',
    body: JSON.stringify({
      password,
      token,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

// export const searchInfluencers = params => {
//   const q = qs.stringify(
//     { ...params, key: searchApiKey, campaign_goal: params.campaign_goal || 'awareness' },
//     { arrayFormat: 'comma' }
//   )

//   return request(
//     `${search}/search?${q}`
//   )
// }

export const getRecommendedInfluencers = (companyId, params) =>
  request(
    `${search}/recommended_influencers/${companyId}?${qs.stringify(
      { ...params, key: searchApiKey },
      { arrayFormat: 'comma' }
    )}`
  );

export const addInfluencerToWatchlist = (influencerId, watchlistId) =>
  request(
    `${aggero}/api/v1/influencers/${influencerId}/follow?watchlist_id=${watchlistId}`,
    {
      method: 'POST',
      body: JSON.stringify({}),
      headers: { 'Content-Type': 'application/json' },
    }
  );
export const getInfoInfluencer = () =>
  request(`${aggero}/api/v1/creator`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

export const removeInfluencerFromWatchlist = (influencerId, watchlistId) =>
  request(
    `${aggero}/api/v1/influencers/${influencerId}/unfollow?watchlist_id=${watchlistId}`,
    {
      method: 'POST',
      body: JSON.stringify({}),
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const getMyWatchlistData = ({ network, watchlistId }) =>
  request(
    `${aggero}/api/v1/user/watchlist?network=${network}&watchlist_id=${watchlistId}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const myWatchlistAddCreator = (urls, watchlistId) =>
  request(`${aggero}/api/v1/user/watchlist-add`, {
    method: 'POST',
    body: JSON.stringify({
      ...urls,
      watchlist_id: watchlistId,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const getWatchlists = () => request(`${aggero}/api/v1/watchlists`);

export const renameWatchlist = ({ id, name }) =>
  request(`${aggero}/api/v1/watchlists/${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      name,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const deleteWatchlist = ({ id }) =>
  request(`${aggero}/api/v1/watchlists/${id}`, {
    method: 'DELETE',
  });

export const createWatchlist = ({ name }) =>
  request(`${aggero}/api/v1/watchlists`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const searchInfluencers = (query) => {
  if (!query) {
    return [];
  }
  return request(`${aggero}/api/v2/influencers/search?q=${query}`);
};

export const getShoutoutDeliverables = (campaignId, videoId) =>
  request(
    `${aggero}/campaigns/${campaignId}/shoutout_deliverables${
      videoId != null ? `?video_id=${videoId}` : ''
    }`
  );

export const getShoutoutDeliverableById = (campaignId, deliverableId) =>
  request(
    `${aggero}/campaigns/${campaignId}/shoutout_deliverables/${deliverableId}`
  );

export const getShoutoutDeliverablesV2 = (campaignId, videoId) =>
  request(
    `${aggero}/campaigns/${campaignId}/shoutout_deliverables_v2${
      videoId != null ? `?video_id=${videoId}` : ''
    }`
  );

export const getShoutoutDeliverableV2ById = (campaignId, deliverableId) =>
  request(
    `${aggero}/campaigns/${campaignId}/shoutout_deliverables_v2/${deliverableId}`
  );

export const deleteShoutoutDeliverables = (shv2_id, campaignId, id, sh_id) =>
  request(
    `${aggero}/campaigns/${campaignId}/shoutout_deliverables_v2/${
      shv2_id || sh_id
    }/shoutouts/${id}?`,
    {
      method: 'DELETE',
      body: JSON.stringify({
        v1: !!sh_id,
      }),
    }
  );

export const deleteMultipleShoutoutsMentions = ({
  campaign_id,
  shoutout_deliverables_v2_id,
  shoutout_deliverables_v1_id,
  shoutout_ids,
}) => {
  return request(
    `${aggero}/campaigns/${campaign_id}/shoutout_deliverables_v2/${
      shoutout_deliverables_v2_id || shoutout_deliverables_v1_id
    }/shoutouts/delete_all?shoutout_ids=${shoutout_ids.toString()}`,
    {
      method: 'DELETE',
      body: JSON.stringify({
        campaign_id,
        shoutout_deliverables_v2_id,
        shoutout_deliverables_v1_id,
        shoutout_ids,
        v1: !!shoutout_deliverables_v1_id,
      }),
    }
  );
};

export const deleteImageOccurrence = (campaignId, deliverableId, videoId) =>
  request(
    `${aggero}/campaigns/${campaignId}/deliverables/${deliverableId}/banner_occurrences/delete_all?video_ids=${videoId}`,
    {
      method: 'DELETE',
    }
  );

export const deleteMultipleImageOccurrence = ({
  campaignId,
  bannerDeliverableId,
  videoIds,
}) => {
  return request(
    `${aggero}/campaigns/${campaignId}/deliverables/${bannerDeliverableId}/banner_occurrences/delete_all?video_ids=${videoIds.toString()}`,
    {
      method: 'DELETE',
    }
  );
};

export const getSocialMentionsDeliverables = (campaignId) =>
  request(
    `${aggero}/campaigns/${campaignId}/social_media_mentions_deliverables`
  );

export const getLinkSharingDeliverables = (campaignId) =>
  request(`${aggero}/campaigns/${campaignId}/link_tracking_deliverables`);

export const getLinkTrackingDeliverableById = (campaignId, deliverableId) =>
  request(
    `${aggero}/campaigns/${campaignId}/link_tracking_deliverables/${deliverableId}`
  );

export const getBannerDeliverables = (campaignId) =>
  request(`${aggero}/campaigns/${campaignId}/banner_deliverables`);

export const getBannerDeliverableById = (campaignId, deliverableId) =>
  request(
    `${aggero}/campaigns/${campaignId}/banner_deliverables/${deliverableId}`
  );

export const getChatMentionDeliverables = (campaignId, videoId) =>
  request(
    `${aggero}/campaigns/${campaignId}/keyword_mention_deliverables${
      videoId != null ? `?video_id=${videoId}` : ''
    }`
  );

export const getChatMentionDeliverableById = (campaignId, deliverableId) =>
  request(
    `${aggero}/campaigns/${campaignId}/keyword_mention_deliverables/${deliverableId}`
  );

export const deleteBannerOccurence = ({
  campaignId,
  deliverableId,
  occurrenceId,
}) =>
  request(
    `${aggero}/campaigns/${campaignId}/deliverables/${deliverableId}/banner_occurrences/${occurrenceId}`,
    {
      method: 'DELETE',
    }
  );

export const getOffstreamTrackings = (campaignId) =>
  request(`${aggero}/campaigns/${campaignId}/offstream_trackings`);

export const getBrandOffstreamTrackings = (campaignId) =>
  request(
    `${aggero}/campaigns/${campaignId}/offstream_trackings?type=CampaignBrand`
  );

export const getSocialOffstreamTrackings = (campaignId) =>
  request(
    `${aggero}/campaigns/${campaignId}/offstream_trackings?type=CampaignInfluencer&network=twitter,instagram,tiktok`
  );

export const updateStreamTimeframe = (
  campaignId,
  videoId,
  [start, end],
  segmentId
) =>
  request(`${aggero}/campaigns/${campaignId}/videos/${videoId}`, {
    method: 'PUT',
    body: JSON.stringify({
      video_segments_attributes: [
        { end_at: end, start_at: start, id: segmentId },
      ],
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const myStatsUpdateStreamSegments = (videoId, segment) =>
  request(`${aggero}/api/v1/marketplace_twitch_streams/${videoId}`, {
    method: 'PUT',
    body: JSON.stringify({
      video_segments_attributes: [segment],
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const getMyBrands = () =>
  request(`${aggero}/api/v1/organization_brands`);

export const deleteMyBrands = (brandId) =>
  request(`${aggero}/api/v1/organization_brands/${brandId}`, {
    method: 'DELETE',
  });

export const postBrand = (data) =>
  request(`${aggero}/api/v1/organization_brands`, {
    method: 'POST',
    body: JSON.stringify({ ...data, v2: true }),
    headers: { 'Content-Type': 'application/json' },
  });

export const putBrand = (id, data) =>
  request(`${aggero}/api/v1/organization_brands/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });

export const disconnectInfluencerSocial = (page_id, platform) =>
  request(`${aggero}/api/v1/creator/disconnect_onboarding`, {
    method: 'DELETE',
    body: JSON.stringify({
      creator: { platform, page_id },
    }),
    headers: { 'Content-Type': 'application/json' },
  });

export const redoInfluencerSocialLinkCall = (data) => {
  return request(`${aggero}/api/v2/influencers/redo_linking`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
};

// Influencer / Marketplace
export const getCreatorSocialPosts = ({ platformFilter, dates }) => {
  const { start_date, end_date } = dates;
  let url = `${aggero}/api/v1/creator/social_posts?`;

  const queryString = qs.stringify({
    network: platformFilter,
    start_at: start_date,
    end_at: end_date,
  });

  url = url.concat(queryString);
  return request(url);
};

export const getCreatorStreams = ({
  platformFilter,
  dates,
  videoTypeFilter,
}) => {
  const { start_date, end_date } = dates;
  let url = `${aggero}/api/v1/creator/streams?`;

  const queryString = qs.stringify({
    network: platformFilter,
    video_type_filter: videoTypeFilter !== 'all' ? videoTypeFilter : undefined,
    start_at: start_date,
    end_at: end_date,
  });

  url = url.concat(queryString);
  return request(url);
};

export const getCreatorAudience = () =>
  request(`${aggero}/api/v1/creator/audience`);

export const getStreamCCVData = (streamId, platform) =>
  request(`${aggero}/api/v1/creator/streams/${platform}?stream_id=${streamId}`);

export const getSocialMediaPerformanceSummary = (
  campaignId,
  influencerIds,
  network,
  startTime,
  endTime
) => {
  const searchParams = {
    campaign_influencer_ids: influencerIds.join(','),
    network,
    start_time: startTime,
    end_time: endTime,
  };

  // dont send network if it is 'all'
  if (searchParams.network === 'all') {
    delete searchParams.network;
  }

  const query = qs.stringify(searchParams);

  return request(
    `${aggero}/campaigns/${campaignId}/social_media_performances/summary?${query}`
  );
};

export const getSocialMediaPerformancePosts = (
  campaignId,
  influencerIds,
  network,
  startTime,
  endTime
) => {
  const searchParams = {
    campaign_influencer_ids: influencerIds.join(','),
    network,
    start_time: startTime,
    end_time: endTime,
  };

  // dont send network if it is 'all'
  if (searchParams.network === 'all') {
    delete searchParams.network;
  }

  const query = qs.stringify(searchParams);

  return request(
    `${aggero}/campaigns/${campaignId}/social_media_performances/posts?${query}`
  );
};

export const getQuotaUsage = () =>
  request(`${aggero}/api/v1/admin/organizations/quota_usage`);

export const getOrganizationUsers = () =>
  request(`${aggero}/api/v1/admin/users`);

export const createNewUser = (data) =>
  request(`${aggero}/api/v1/admin/users`, {
    method: 'POST',
    body: JSON.stringify({ ...data }),
    headers: { 'Content-Type': 'application/json' },
  });

export const updateUser = (id, data) =>
  request(`${aggero}/api/v1/admin/users/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ ...data }),
    headers: { 'Content-Type': 'application/json' },
  });

export const removeUser = (id, data) =>
  request(`${aggero}/api/v1/admin/users/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });

export const authenticateNFTUser = (did_token) => {
  return request(`${aggero}/api/v2/nft_influencers/authenticate_nft_user`, {
    method: 'GET',
    headers: { 'Did-Token': did_token },
  });
};

export const addVideosToCampaign = (campaignId, videosPayload) => {
  return request(`${aggero}/campaigns/${campaignId}/add_video`, {
    method: 'POST',
    body: JSON.stringify({ videos: videosPayload }),
    headers: {
      'Content-Type': 'application/json',
      'Private-Api-key':
        'ce2b26e4f8790a2070646e1af7f7a2c1ac41e9db30a7d4c60c67d90fabb87fae',
    },
  });
};
