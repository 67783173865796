import React from 'react';

import SubMenu, { usePerformanceSubMenu } from '../components/SubMenu';
import { useUsedVideos } from '../hooks/useUsedVideos';
import { CampaignContext } from '../utils/CampaignContext';
import SocialMedia from './SocialMedia';
import Streams from './Streams';

export const PerformanceTab = ({
  campaign,
  timeFrame,
  timeZone,
  selectedCampaignInfluencer,
  setSelectedCampaignInfluencer,
  platformFilter,
  setShowSocialPlatforms,
  setCampaignVideoDeleteId,
  setSocialPostDelete,
  activeTab: forcedActiveTab,
  setActiveTab: setForcedActiveTab,
  campaignSocialDeleteSuccess,
  socialPostDelete,
  setSocialPerformanceIdDelete,
  socialPerformanceIdDelete,
  languageFilter,
}) => {
  const { tabs, activeTab, onTabClick } = usePerformanceSubMenu(
    campaign,
    setShowSocialPlatforms,
    forcedActiveTab,
    setForcedActiveTab
  );

  const { usedVideos, usedVideosIds } = useUsedVideos({
    campaign,
    selectedCampaignInfluencer,
    platformFilter,
    timeFrame,
    onlyStreams: activeTab === 'streaming',
    languageFilter,
  });

  const usedInfluencers =
    selectedCampaignInfluencer === 'all'
      ? campaign.campaign_influencers
      : [selectedCampaignInfluencer];

  const usedInfluencersIds = new Set(usedInfluencers.map((i) => i.id));

  const tabProps = {
    timeFrame,
    timeZone,
    usedVideos,
    usedVideosIds,
    campaign,
    selectedCampaignInfluencer,
    setSelectedCampaignInfluencer,
    platformFilter,
    usedInfluencers,
    usedInfluencersIds,
    setCampaignVideoDeleteId,
    setSocialPostDelete,
    campaignSocialDeleteSuccess,
    socialPostDelete,
    setSocialPerformanceIdDelete,
    socialPerformanceIdDelete,
  };

  return (
    <CampaignContext.Provider value={tabProps}>
      <SubMenu tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
      {activeTab === 'streaming' && <Streams {...tabProps} />}
      {activeTab === 'social-media' && <SocialMedia {...tabProps} />}
    </CampaignContext.Provider>
  );
};

export default PerformanceTab;
