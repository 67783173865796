import { LoadingState, PublishedRecord } from '../../../types';
import { Deliverable } from '../../types';
import { initialState } from './ChatMentionDeliverables.slice';

export const SLICE_NAME = 'chatMentionDeliverables';
export interface ChatMention extends PublishedRecord {
  commenter_avatar_url: string;
  comment: string;
  is_live: boolean;
  link: string | null;
  name: string;
  platform: string;
  sentiment: number | null;
  video_id: number;
  commenter_external_id: number;
}

export interface ChatMentionDeliverable extends Deliverable {
  keyword: string;
  video_comments: ChatMention[];
}

export type ChatMentionDeliverableStructure = Omit<
  ChatMentionDeliverable,
  'video_comments'
>;

// maps deliverableId to its loading state
export type LoadingStateMap = { [key: number]: LoadingState };

export type ChatMentionDeliverablesState = typeof initialState;
