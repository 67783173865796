import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridColumns, GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar, BarRounded } from '@visx/shape';
import { Text } from '@visx/text';
import { max } from 'd3-array';
import numeral from 'numeral';
import React, { useMemo } from 'react';

import { ccvGetCCV, ccvGetLabel, groupCCV } from './utils';

const CCV_HEIGHT = 216;

const CCV_PADDING = { top: 64, bottom: 32, left: 32, right: 32 };

const ccvYMax = CCV_HEIGHT - CCV_PADDING.top - CCV_PADDING.bottom;

interface AverageCCVProps {
  width: number;
  ccvBars: ReturnType<typeof groupCCV>;
  getOpacity: (label: string) => number;
  onBarLeave: () => void;
  onBarMove: (b: {
    barIndex: number;
    label: string;
  }) => (e: React.MouseEvent) => void;
}

const AverageCCV = ({
  width,
  ccvBars,
  getOpacity,
  onBarLeave,
  onBarMove,
}: AverageCCVProps) => {
  const ccvCCVScale = useMemo(
    () =>
      scaleLinear({
        range: [ccvYMax, 0],
        domain: [0, max(ccvBars, ccvGetCCV)],
      }),
    [ccvBars]
  );

  const ccvXMax = width - CCV_PADDING.left - CCV_PADDING.right;

  const ccvBarsScale = useMemo(
    () =>
      scaleBand({
        domain: ccvBars.map(ccvGetLabel),
        range: [0, ccvXMax],
        padding: 0.2,
      }),
    [ccvBars, ccvXMax]
  );

  return (
    <svg width={width} height={CCV_HEIGHT} xmlns="http://www.w3.org/2000/svg">
      <Group top={0} left={0}>
        <GridColumns
          scale={ccvBarsScale}
          height={CCV_HEIGHT}
          left={CCV_PADDING.left}
        />
        <Group top={CCV_PADDING.top} left={CCV_PADDING.left}>
          <Text fontSize={16} y={-12}>
            Average Concurrent Viewers
          </Text>
          <GridRows scale={ccvCCVScale} width={ccvXMax} numTicks={4} />
          {ccvBars.map(({ label, count }, barIndex) => {
            const barHeight = ccvYMax - ccvCCVScale(count);
            const x = ccvBarsScale(label);
            const y = ccvYMax - barHeight;

            return (
              <React.Fragment key={barIndex}>
                <BarRounded
                  key={barIndex}
                  x={x}
                  y={y}
                  height={barHeight}
                  width={ccvBarsScale.bandwidth()}
                  fill="#0048F2"
                  opacity={getOpacity(label)}
                  top
                  radius={2}
                  bottom={false}
                  onMouseLeave={onBarLeave}
                />
                <Bar
                  height={ccvYMax}
                  width={ccvBarsScale.bandwidth()}
                  y={0}
                  x={x}
                  fill="transparent"
                  onMouseLeave={onBarLeave}
                  onMouseMove={onBarMove({ label, barIndex })}
                />
              </React.Fragment>
            );
          })}
        </Group>
        <AxisBottom
          scale={ccvBarsScale}
          top={CCV_PADDING.top + ccvYMax}
          left={CCV_PADDING.left}
          numTicks={12}
          tickStroke="#0048f2"
          tickLength={4}
          stroke="#0048f2"
          strokeWidth={2}
        />
        <AxisLeft
          scale={ccvCCVScale}
          top={CCV_PADDING.top}
          left={CCV_PADDING.left}
          tickFormat={(t) => numeral(t).format('0.[0]a')}
          numTicks={4}
          hideAxisLine
          hideTicks
        />
      </Group>
    </svg>
  );
};

export default AverageCCV;
