import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectCommunication } from '../store/models/communication/communication.selectors';

export default function MissingData(props) {
  const { communication } = props;

  const { succeeded } = useSelector(selectCommunication(communication));

  if (!succeeded) return null;

  return (
    <Box
      clone
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      minHeight={200}
      paddingX={8}
    >
      <Typography>
        We are currently processing this data. Please come back later.
      </Typography>
    </Box>
  );
}
