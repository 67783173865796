import { useLocation, useRouteMatch } from 'react-router-dom';

function useRouter() {
  const match = useRouteMatch();
  const location = useLocation();

  return { match, location };
}

export default useRouter;
