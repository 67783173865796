import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { ParentSize } from '@visx/responsive';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PROFILE } from '../../../../common/constants/paths';
import CardTitle from '../../../../components/CardTitle';
import SectionTitle from '../../../../components/SectionTitle';
import { UnlockBanner } from '../../../../components/UnlockBanner/UnlockBanner';
import { useFetchOnMount } from '../../../../hooks/useFetchOnMount';
import {
  selectInfluencerHasStreamingAccounts,
  selectInfluencerInfoShowLoading,
} from '../../store/InfluencerInfo.selectors';
import { AudienceBrandAffinities } from './components/AudienceBrandAffinities/AudienceBrandAffinities';
import {
  TOP_BRANDS,
  TOP_CATEGORIES,
} from './components/AudienceBrandAffinities/AudienceBrandAffinities.stories';
import { AudienceChoropleth } from './components/AudienceChoropleth/AudienceChoropleth';
import { TOP_COUNTRIES } from './components/AudienceChoropleth/AudienceChoropleth.stories';
import {
  AGE_COLOR_SCALE,
  AudienceDonutChart,
  GENDER_COLOR_SCALE,
} from './components/AudienceDonutChart/AudienceDonutChart';
import {
  AGE_DATA,
  GENDER_DATA,
} from './components/AudienceDonutChart/AudienceDonutChart.stories';
import { AudienceOverlap } from './components/AudienceOverlap/AudienceOverlap';
import { TOP_FOLLOWED } from './components/AudienceOverlap/AudienceOverlap.stories';
import { TOP_LANGUAGES } from './components/AudienceTopLanguagesChart/AudienceTopLanguages.stories';
import { AudienceTopLanguagesChart } from './components/AudienceTopLanguagesChart/AudienceTopLanguagesChart';
import {
  selectAudienceAgeData,
  selectAudienceBrandCategories,
  selectAudienceGenderData,
  selectAudienceIsLoading,
  selectAudienceLanguageData,
  selectAudienceLocationData,
  selectAudienceTopBrands,
  selectAudienceTopFollowed,
  selectHasAudienceInterestsData,
  selectShouldFetchAudience,
} from './store/Audience.selectors';
import { fetchCreatorAudience } from './store/Audience.thunks';

const useAudienceStyles = makeStyles((theme) => ({
  chartCardContent: {
    paddingTop: `${(9 / 16) * 100}%`, // 16:9 aspeect ratio
    position: 'relative',
  },
  chartWrapper: {
    position: 'absolute',
    paddingBottom: 8,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  bannerContainer: {
    display: 'flex',
    padding: '0px 100px',

    [theme.breakpoints.down('md')]: {
      padding: '0px 20px',
    },
  },
  lockedChartWrapper: {
    filter: 'blur(5px)',
    userSelect: 'none',
    pointerEvents: 'none',
  },
}));

export const Audience = React.memo(() => {
  const isLocked = !useSelector(selectInfluencerHasStreamingAccounts());
  const classes = useAudienceStyles();
  const history = useHistory();

  useFetchOnMount(selectShouldFetchAudience(), fetchCreatorAudience);

  let locationData = useSelector(selectAudienceLocationData());
  let ageData = useSelector(selectAudienceAgeData());
  let genderData = useSelector(selectAudienceGenderData());
  let languageData = useSelector(selectAudienceLanguageData());

  let hasAudienceInterestsData = useSelector(selectHasAudienceInterestsData());
  let topBrands = useSelector(selectAudienceTopBrands());
  let brandCategories = useSelector(selectAudienceBrandCategories());
  let topFollowed = useSelector(selectAudienceTopFollowed());

  if (isLocked) {
    locationData = TOP_COUNTRIES;
    ageData = AGE_DATA;
    genderData = GENDER_DATA;
    languageData = TOP_LANGUAGES;

    topBrands = TOP_BRANDS;
    brandCategories = TOP_CATEGORIES;
    topFollowed = TOP_FOLLOWED;
  }

  const audienceLoading = useSelector(selectAudienceIsLoading());

  const influencerInfoLoading = useSelector(selectInfluencerInfoShowLoading());

  const isLoading = audienceLoading || influencerInfoLoading;

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={300}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Grid container spacing={4} direction="column">
      {isLocked && (
        <Grid item>
          <div className={classes.bannerContainer}>
            <UnlockBanner
              title="Understand Your Fans!"
              bodyText="Connect to social media to find their demographics"
              onCTA={() => {
                history.push(`${PROFILE}?socialCallback=true`);
              }}
            />
          </div>
        </Grid>
      )}
      <Grid item>
        <SectionTitle>Audience Demographics</SectionTitle>
      </Grid>
      <Grid item>
        <Grid container spacing={3} alignItems="stretch">
          {locationData && (
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <CardTitle>Top Locations</CardTitle>
                </CardContent>
                <CardContent className={classes.chartCardContent}>
                  <ParentSize
                    className={clsx(classes.chartWrapper, {
                      [classes.lockedChartWrapper]: isLocked,
                    })}
                  >
                    {({ width, height }) => (
                      <AudienceChoropleth
                        width={width}
                        height={height}
                        topCountries={locationData}
                      />
                    )}
                  </ParentSize>
                </CardContent>
              </Card>
            </Grid>
          )}
          {ageData && (
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <CardTitle>Age Groups</CardTitle>
                </CardContent>
                <CardContent className={classes.chartCardContent}>
                  <ParentSize
                    className={clsx(classes.chartWrapper, {
                      [classes.lockedChartWrapper]: isLocked,
                    })}
                  >
                    {({ width, height }) => (
                      <AudienceDonutChart
                        width={width}
                        height={height}
                        data={ageData}
                        colorScale={AGE_COLOR_SCALE}
                      />
                    )}
                  </ParentSize>
                </CardContent>
              </Card>
            </Grid>
          )}
          {genderData && (
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <CardTitle>Gender Distribution</CardTitle>
                </CardContent>
                <CardContent className={classes.chartCardContent}>
                  <ParentSize
                    className={clsx(classes.chartWrapper, {
                      [classes.lockedChartWrapper]: isLocked,
                    })}
                  >
                    {({ width, height }) => (
                      <AudienceDonutChart
                        width={width}
                        height={height}
                        data={genderData}
                        colorScale={GENDER_COLOR_SCALE}
                      />
                    )}
                  </ParentSize>
                </CardContent>
              </Card>
            </Grid>
          )}
          {languageData && (
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <CardTitle>Top languages</CardTitle>
                </CardContent>
                <CardContent className={classes.chartCardContent}>
                  <ParentSize
                    className={clsx(classes.chartWrapper, {
                      [classes.lockedChartWrapper]: isLocked,
                    })}
                  >
                    {({ width, height }) => (
                      <AudienceTopLanguagesChart
                        width={width}
                        height={height}
                        topLanguages={languageData}
                      />
                    )}
                  </ParentSize>
                </CardContent>
              </Card>
            </Grid>
          )}
          {hasAudienceInterestsData && (
            <>
              <Grid item>
                <SectionTitle>Audience Interests</SectionTitle>
              </Grid>
              <Grid item xs={12}>
                <AudienceOverlap topFollowed={topFollowed} locked={isLocked} />
              </Grid>
              {topBrands?.length && brandCategories && (
                <Grid item xs={12}>
                  <AudienceBrandAffinities
                    locked={isLocked}
                    topBrands={topBrands}
                    topCategories={brandCategories}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});
