import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { localPoint } from '@visx/event';
import { scaleLinear } from '@visx/scale';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import React, { useCallback, useMemo, useRef } from 'react';

import GeoUtils from '../../../../../../utils/GeoUtils';
import { TopCountries } from '../../store/types';
import { Map, MapProps } from './Map';

const BOTTOM_TEXT_HEIGHT = 48;

const useAudienceChoroplethStyles = makeStyles<
  Theme,
  { width: number; height: number }
>(() => ({
  container: ({ width, height }) => ({ width, height, position: 'relative' }),
  bottomTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: BOTTOM_TEXT_HEIGHT,
    alignItems: 'center',
    padding: '0 12px',
  },
  percentage: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    marginTop: -8,
  },
  label: {
    fontSize: '.8rem',
  },
}));

export interface AudienceChoroplethProps {
  topCountries: TopCountries;
  width: number;
  height: number;
}

export const AudienceChoropleth = ({
  topCountries,
  width,
  height,
}: AudienceChoroplethProps) => {
  const {
    hideTooltip,
    showTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip<string>();

  const mapRef = useRef();

  const mapHeight = height - BOTTOM_TEXT_HEIGHT;

  const classes = useAudienceChoroplethStyles({
    width,
    height,
  });

  const onHoverCountry: MapProps['onHoverCountry'] = useCallback(
    (event, countryId) => {
      const point = localPoint(mapRef.current, event);

      showTooltip({
        tooltipLeft: point.x,
        tooltipTop: point.y,
        tooltipData: countryId,
      });
    },
    [showTooltip, mapRef]
  );

  // change two letter codes to three letter codes as this is the topojson standard
  const data = useMemo(
    () =>
      Object.keys(topCountries).reduce((data, key) => {
        if (key === 'other') {
          data['other'] = topCountries[key];
        } else {
          data[GeoUtils.twoLetterCountryCode2ThreeLetter(key)] =
            topCountries[key];
        }

        return data;
      }, {} as typeof topCountries),
    [topCountries]
  );

  const colorScale = useMemo(() => {
    const { other: _, ...rest } = data;

    return scaleLinear({
      domain: [
        Math.min(...Object.values(rest)),
        Math.max(...Object.values(rest)),
      ],
      range: ['rgb(178, 192, 225)', '#0048F2'],
    });
  }, [data]);

  // ParentSize initialy sends 0
  if (width < 10) return null;

  return (
    <div className={classes.container}>
      <Map // extracted for memoization
        width={width}
        height={mapHeight}
        colorScale={colorScale}
        onHideTooltip={hideTooltip}
        onHoverCountry={onHoverCountry}
        data={data}
        ref={mapRef}
      />
      {tooltipOpen && tooltipData && tooltipLeft != null && tooltipTop != null && (
        <TooltipWithBounds left={tooltipLeft + 10} top={tooltipTop + 10}>
          <strong>{GeoUtils.countryCode2Name(tooltipData)}</strong>
          {tooltipData in data && `: ${data[tooltipData]}%`}
        </TooltipWithBounds>
      )}
      <div className={classes.bottomTextContainer}>
        {Object.keys(topCountries).map((country) => (
          <div key={country}>
            <Typography
              variant="caption"
              style={{
                color: colorScale(topCountries[country]),
              }}
              className={classes.label}
            >
              {country}
            </Typography>
            <Typography
              style={{
                color: colorScale(topCountries[country]),
              }}
              className={classes.percentage}
            >
              {topCountries[country]}%
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
