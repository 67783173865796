import Grid from '@material-ui/core/Grid';
import { connect } from 'formik';
import React from 'react';

import FormControl from '../../../components/FormControl';
import ExplainedFormLabel from '../../../components/FormLabel';
import MenuItem from '../../../components/MenuItem';
import OutlinedInput from '../../../components/OutlinedInput';
import Select from '../../../components/Select';

const enhance = connect;

function CampaignParameters(props) {
  const {
    formik: { values, handleChange },
  } = props;

  return (
    <Grid container spacing={4}>
      <Grid item>
        <FormControl style={{ maxWidth: 400 }}>
          <ExplainedFormLabel
            explanation={
              'Discover influencers who can achieve your campaign goals in terms of increasing your brand awareness, improving sales or improving your social engagement. "Find the next ninja" option will search for creators who are hot and growing, having high growth scores in terms of followers, but also good general metrics.'
            }
          >
            Your campaign goal
          </ExplainedFormLabel>
          <Select
            variant="outlined"
            input={<OutlinedInput />}
            style={{ minWidth: 240 }}
            name="campaign_goal"
            value={values.campaign_goal}
            onChange={handleChange}
          >
            <MenuItem value="best_fit">None</MenuItem>
            <MenuItem value="awareness">Expand brand awareness</MenuItem>
            <MenuItem value="conversions">Improve sales conversion</MenuItem>
            <MenuItem value="engagement">Increase engagement</MenuItem>
            <MenuItem value="virality">Virality</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default enhance(CampaignParameters);
