import { selectStreamsOverview } from './Streams.selectors';
import { Video } from './types';

export const fakeStreamsOverview: ReturnType<
  ReturnType<typeof selectStreamsOverview>
> = {
  avgCCV: 8432,
  comments: 21234,
  emv: 125940,
  engagement: 10,
  followersGained: 12348,
  hrsStreamed: 857,
  hrsWatched: 23989,
  streamsCount: 24,
  totalViews: 3034998,
  peakCCV: 12830,
  streamsCountByPlatform: {
    twitch: 10,
    youtube: 9,
    facebook: 5,
  },
};

export const fakeStreams: Video[] = [
  {
    id: 225511,
    resource: 'youtube',
    video_title: 'FINALS | Red Bull Wololo V Day 6',
    published_at: '2021-09-19T11:14:54.000Z',
    language: 'en',
    thumbnails: {
      original: {
        url: 'https://i.ytimg.com/vi/5XDrgmy9JdM/default.jpg',
      },
      default: {
        url: 'https://aggero1storage.blob.core.windows.net/thumbnails/yt-5XDrgmy9JdM-default.jpg',
      },
    },
    influencer_id: 3456,
    total_views: 285169,
    comments_count: 203,
    max_cc_viewers: 10355,
    avg_cc_viewers: 5703,
    engagement_rate: 0.1,
    campaign_id: 1572,
    duration: 36691,
    link: 'https://www.youtube.com/watch?v=5XDrgmy9JdM',
    video_type: 'live-vod',
    video_followers: null,
    minutes_watched_by_audience: 3487551,
    streamer_id: 444793136,
    followers_gained: null,
    external_id: '5XDrgmy9JdM',
    emv: 3423,
    live_chat_count: 317,
    is_live: false,
    recording_link: null,
    computed_total_views: 285169,
    campaign_influencer_ids: [3719],
    time_zone: 'Europe/London',
    video_segments: [
      {
        id: 62,
        start_at: 0,
        end_at: 36691,
      },
    ],
  },

  {
    id: 2255144,
    resource: 'twitch',
    video_title: 'FINALS | Red Bull Wololo V Day 6',
    published_at: '2021-09-19T11:14:54.000Z',
    language: 'en',
    thumbnails: {
      original: {
        url: 'https://i.ytimg.com/vi/5XDrgmy9JdM/default.jpg',
      },
      default: {
        url: 'https://aggero1storage.blob.core.windows.net/thumbnails/yt-5XDrgmy9JdM-default.jpg',
      },
    },
    influencer_id: 3456,
    total_views: 285169,
    comments_count: 203,
    max_cc_viewers: 10355,
    avg_cc_viewers: 5703,
    engagement_rate: 0.1,
    campaign_id: 1572,
    duration: 36695,
    link: 'https://www.youtube.com/watch?v=5XDrgmy9JdM',
    video_type: 'live-vod',
    video_followers: null,
    minutes_watched_by_audience: 3487551,
    streamer_id: 444793136,
    followers_gained: null,
    external_id: '5XDrgmy9JdM',
    emv: 3423,
    live_chat_count: 317,
    is_live: false,
    recording_link: null,
    computed_total_views: 285169,
    campaign_influencer_ids: [3719],
    time_zone: 'Europe/London',
    video_segments: [
      {
        id: 62,
        start_at: 0,
        end_at: 36695,
      },
    ],
  },
];

export const fakeStreamInfluencer = {
  name: 'ShivFPS',
  id: 3678,
  avatar:
    'https://static-cdn.jtvnw.net/jtv_user_pictures/6342dd1b-3c2c-41dd-9d01-35c5c6660ef9-profile_image-300x300.png',
};
