import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createActions, createReducer } from 'reduxsauce';

export const STORAGE_PREFIX = 'account';

export const INITIAL_STATE = {
  authUser: null,
  authInProgress: false,
  data: {},
  watched_influencers: [],
};

const { Types, Creators } = createActions(
  {
    userGet: ['userData'],
    userSignedIn: ['userData'],
    // signupWithPassword: ['name', 'email', 'password'],
    signinWithPassword: ['email', 'password'],
    // signinGoogle: null,
    signinTwitch: ['accessToken'],
    signinTwitter: ['oauthToken', 'oauthVerifier'],
    signinYoutube: ['accessCode', 'target'],
    signinFacebook: ['authResponse'],
    signinTiktok: ['code', 'scopes', 'state'],
    signout: null,
    resetPassword: ['password', 'token'],
    forgotPassword: ['email'],
    authInProgress: ['value'],
    addToWatchlist: ['influencerId'],
    removeFromWatchlist: ['influencerId'],
    updateUser: ['userData'],
  },
  {
    prefix: `${STORAGE_PREFIX}/`,
  }
);

/* reducers */

export const userSignedIn = (state, { userData }) => {
  const user = {};
  const data = {};
  const authFilter = [
    'id',
    'displayName',
    'roles',
    'email',
    'photoURL',
    'accessToken',
    'refreshToken',
    'token',
    'organization_id',
  ];
  const dataFilters = [
    'networks',
    'date_of_birth',
    'first_name',
    'gender',
    'interests',
    'last_name',
    'location',
    'onboarding_email',
    'website',
  ];
  user.displayName = userData.name;
  authFilter.forEach((item) => {
    if (userData.hasOwnProperty(item)) {
      user[item] = userData[item];
    }
  });
  dataFilters.forEach((item) => {
    if (userData.hasOwnProperty(item)) {
      data[item] = userData[item];
    }
  });
  const result = {
    ...state,
    firebaseData: userData.firebase_data,
    authUser: {
      ...state.authUser,
      ...user,
    },
    data: {
      ...state.data,
      ...data,
    },
  };

  if (userData.hasOwnProperty('watched_influencers')) {
    result.watched_influencers = userData.watched_influencers;
  }

  if (userData.hasOwnProperty('organization')) {
    result.organization = userData.organization;
  }

  return result;
};

export const signinWithPassword = (state) => ({
  ...state,
  authInProgress: true,
});

// export const signinGoogle = state => ({
//   ...state, authInProgress: true,
// })

export const signinTwitter = (state) => ({
  ...state,
  authInProgress: true,
});

export const signinTwitch = (state) => ({
  ...state,
  authInProgress: true,
});

export const signinYoutube = (state) => ({
  ...state,
  authInProgress: true,
});

export const signinFacebook = (state) => ({
  ...state,
  authInProgress: true,
});

export const signinTiktok = (state) => ({ ...state, authInProgress: true });

// export const signupWithPassword = state => ({
//   ...state, authInProgress: true,
// })

export const resetPassword = (state) => ({
  ...state,
  authInProgress: true,
});

export const forgotPassword = (state) => ({
  ...state,
  authInProgress: true,
});

export const authInProgress = (state, { value }) => ({
  ...state,
  authInProgress: !!value,
});

export const addToWatchlist = (state, { influencerId }) => {
  let watchedInfluencers = state.watched_influencers;

  if (watchedInfluencers.indexOf(influencerId) === -1) {
    watchedInfluencers.push(influencerId);
  }

  return {
    ...state,
    watched_influencers: watchedInfluencers,
  };
};

export const removeFromWatchlist = (state, { influencerId }) => {
  return {
    ...state,
    watched_influencers: state.watched_influencers.filter(
      (e) => e !== influencerId
    ),
  };
};

export const signout = (state) => state;

export const updateUser = (state, result) => {
  return {
    ...state,
    authUser: {
      ...state.authUser,
      ...(result.userData || {}),
    },
  };
};

export const HANDLERS = {
  [Types.USER_GET]: userSignedIn,
  [Types.USER_SIGNED_IN]: userSignedIn,
  [Types.SIGNOUT]: signout,
  [Types.SIGNIN_WITH_PASSWORD]: signinWithPassword,
  // [Types.SIGNIN_GOOGLE]: signinGoogle,
  [Types.SIGNIN_FACEBOOK]: signinFacebook,
  [Types.SIGNIN_TWITTER]: signinTwitter,
  [Types.SIGNIN_TWITCH]: signinTwitch,
  [Types.SIGNIN_YOUTUBE]: signinYoutube,
  [Types.SIGNIN_TIKTOK]: signinTiktok,
  [Types.RESET_PASSWORD]: resetPassword,
  [Types.FORGOT_PASSWORD]: forgotPassword,
  [Types.AUTH_IN_PROGRESS]: authInProgress,
  [Types.ADD_TO_WATCHLIST]: addToWatchlist,
  [Types.REMOVE_FROM_WATCHLIST]: removeFromWatchlist,
  [Types.UPDATE_USER]: updateUser,
};

const persistConfig = {
  key: STORAGE_PREFIX,
  storage,
  blacklist: ['authInProgress', 'data'],
};

export const reducer = persistReducer(
  persistConfig,
  createReducer(INITIAL_STATE, HANDLERS)
);
export const actionTypes = Types;
export default Creators;
