import { Box, Icon } from '@material-ui/core';
import React from 'react';

import percentage from '../utils/percent';

export default function Change({ before, after }) {
  const incline = before < after;

  const stale = before === after;

  const change = incline
    ? percentage(before, after - before)
    : percentage(after, before - after);

  return (
    <Box
      color={stale ? 'white' : incline ? 'common.darkGreen' : 'common.darkRed'}
      bgcolor={
        stale ? 'gray' : incline ? 'common.lightGreen' : 'common.lightRed'
      }
      borderRadius={4}
      fontWeight="bold"
      padding={1}
      display="inline-flex"
      alignItems="center"
    >
      {!stale && (
        <Box clone mr={1}>
          <Icon fontSize="small">
            {incline ? 'arrow_upward' : 'arrow_downward'}
          </Icon>
        </Box>
      )}
      {change}%
    </Box>
  );
}
