import { postInfluencerMissingData } from '../../ajax';
import shouldHaveTwitch from '../../helpers/should-have-twitch';
import shouldHaveYoutube from '../../helpers/should-have-youtube';
import truthy from '../../utils/truthy';
import { INFLUENCER_DATA_REQUEST_SUCCEEDED } from '../../views/Influencer/redux';

export const missingDataOnDemand = (store, errors) => {
  const { influencerDetail } = store.getState();
  const {
    network,
    name,
    influencer: { youtube_streamer },
    twitterProfile,
  } = influencerDetail;
  const { channel_id } = youtube_streamer || {};

  let options = {};
  options.influencer_name = name;
  options.influencer_network = network;

  if (network === 'youtube') {
    options.channel_id = channel_id;
  } else {
    options.twitter_user = twitterProfile && twitterProfile.username;
  }

  try {
    if (errors.length) {
      postInfluencerMissingData(errors, options);
      console.log('errors', errors);
    }
  } catch (e) {
    console.error('Failed to POST on missing_data', e);
  }
};

const missingDataMiddleware = (store) => (next) => (action) => {
  next(action);

  if (action.type !== INFLUENCER_DATA_REQUEST_SUCCEEDED) return;

  const { influencer, twitch, influencerNetwork, youtubeComments } =
    action.payload;

  const twitchStreamerProfile = influencer.twitch_streamer || {};
  const youtubeStreamerProfile = influencer.youtube_streamer || {};
  const twitchAudienceBehaviour = twitchStreamerProfile.behaviour;
  const twitchInfluencerBehaviour = twitchStreamerProfile.behaviour;
  const youtubeInfluencerBehaviour = youtubeStreamerProfile.behaviour;
  const youtubeAudienceBehaviour = youtubeComments && youtubeComments.behaviour;
  const totalFollowers = twitchStreamerProfile.total_followers;
  const totalSubscribers = youtubeStreamerProfile.subscriber_count;
  const twitchWordCloud = twitchStreamerProfile.wordcloud;
  const youtubeWordCloud = youtubeStreamerProfile.wordcloud;
  const twitchComments = twitch && twitch.comments;
  const youtubeCommentsArr = youtubeComments && youtubeComments.comments;

  const onTwitch = influencerNetwork === 'twitch';
  const onYoutube = influencerNetwork === 'youtube';

  const errors = [
    !influencer && 'influencer',

    // onTwitch && shouldHaveTwitch(influencer) &&
    // !twitch && 'twitch-chat',

    onTwitch &&
      shouldHaveTwitch(influencer) &&
      !(twitchComments || []).length && [
        'twitch-gender-distribution',
        'twitch-reaction-sentiment',
      ],

    onTwitch &&
      shouldHaveTwitch(influencer) &&
      !totalFollowers &&
      'twitch-followers',

    onTwitch &&
      shouldHaveTwitch(influencer) &&
      !(twitchAudienceBehaviour || []).length &&
      'twitch-audience-behaviour',

    onTwitch &&
      shouldHaveTwitch(influencer) &&
      !(twitchInfluencerBehaviour || []).length &&
      'twitch-influencer-behaviour',

    // onTwitch && shouldHaveTwitch(influencer) &&
    // !(twitchProfileTweets || []).length && 'twitch-audience-top-languages',

    onTwitch &&
      shouldHaveTwitch(influencer) &&
      !Object.keys(twitchWordCloud || {}).length &&
      'twitch-influencer-talking-about',

    // YOUTUBE BELOW THIS LINE
    onYoutube &&
      shouldHaveYoutube(influencer) &&
      !(youtubeCommentsArr || []).length && [
        'youtube-gender-distribution',
        'youtube-audience-reaction-sentiment',
      ],

    onYoutube &&
      shouldHaveYoutube(influencer) &&
      !totalSubscribers &&
      'youtube-total-subscribers',

    onYoutube &&
      shouldHaveYoutube(influencer) &&
      !(youtubeAudienceBehaviour || []).length &&
      'youtube-audience-behaviour',

    onYoutube &&
      shouldHaveYoutube(influencer) &&
      !(youtubeInfluencerBehaviour || []).length &&
      'youtube-influencer-behaviour',

    onYoutube &&
      shouldHaveYoutube(influencer) &&
      !Object.keys(youtubeWordCloud || {}).length &&
      'youtube-influencer-talking-about',

    // onYoutube && shouldHaveYoutube(influencer) &&
    // !youtube && 'youtube-channel',

    // onYoutube && shouldHaveYoutube(influencer) &&
    // !youtubeComments && 'youtube-comments',
  ]
    .flat(1)
    .filter(truthy);

  missingDataOnDemand(store, errors);
};

export default missingDataMiddleware;
