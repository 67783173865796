import { Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import CampaignCard from './CampaignCard';

export const CampaignsDisplayed = ({ searchTerm, displayedCampaigns }) => {
  let filterCampaigns;
  if (searchTerm) {
    filterCampaigns =
      displayedCampaigns.length > 0 &&
      displayedCampaigns.filter(({ name, campaign_influencers }) => {
        if (!!searchTerm) {
          let isInfluenc = false;
          campaign_influencers?.forEach((influencer) => {
            if (
              influencer.name
                ?.toLowerCase()
                .indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              isInfluenc = true;
            }
          });
          return (
            isInfluenc ||
            (name || '').toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );
        } else {
          return true;
        }
      });
  } else filterCampaigns = displayedCampaigns;
  return filterCampaigns.map(
    (campaign) =>
      campaign.id &&
      campaign.overview && ( // needed to send to CampaignCard if undefined - application crashes
        <Grid item key={campaign.id} data-id={`campaign-grid-${campaign.id}`}>
          <Link to={`/campaigns/${campaign.id}`}>
            <CampaignCard campaignId={campaign.id} />
          </Link>
        </Grid>
      )
  );
};
