import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import { getUserData } from '../../ajax';
import * as paths from '../../common/constants/paths';
import { useLoadingState } from '../../hooks/useLoadingState';
import { actions } from '../../store/actions';
import { LoadingState } from '../Campaign/types';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const LoginViaToken = () => {
  const classes = useStyles();
  const [status, setStatus] = useLoadingState();
  const dispatch = useDispatch();

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (!token) return;

    (async () => {
      try {
        const user = await getUserData(token);
        dispatch(
          // @ts-ignore actions are imported using require(glob); this is very dumb and breaks ts
          actions.userSignedIn({
            ...user,
            displayName: user.name,
          })
        );

        setStatus.setFullfilled();
      } catch {
        setStatus.setRejected();
      }
    })();
  }, [dispatch, setStatus, token]);

  let content = <h1>Logging you in...</h1>;

  if (status === LoadingState.REJECTED) {
    content = (
      <h1>
        <Redirect to={paths.PATH_SIGNIN} />
        Something went wrong...
      </h1>
    );
  }

  if (status === LoadingState.FULLFILLED) {
    content = (
      <>
        <Redirect to={paths.PATH_ROOT} />
        {content}
      </>
    );
  }

  return <div className={classes.container}>{content}</div>;
};
