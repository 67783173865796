import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import InstagramIcon from '../assets-2.0/instagram.svg';

const useStyles = makeStyles({
  text: {
    color: '#dc2c7c',
    fontSize: 16,
    display: 'inline-block',
  },
  iconImage: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
});

function InstagramFlag(props) {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.text}>
      <img
        src={InstagramIcon}
        className={classes.iconImage}
        alt="Instagram icon"
      />
      Instagram
    </Typography>
  );
}

export default InstagramFlag;
