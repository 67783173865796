import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import largestRemainderRound from '../utils/largest-remainder-round';
import sum from '../utils/sum';

const AgeGroups = (props) => {
  const { data } = props;

  const labels = Object.keys(data).map((l) => (l === '65-200' ? '65+' : l));
  const values = Object.values(data);
  const total = sum(values);

  const roundedPercentageValues = largestRemainderRound(
    values.map((val) => (val / total) * 100),
    100
  );

  return (
    <Doughnut
      data={{
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: [
              '#174EA8',
              '#1E5FCA',
              '#3677E2',
              '#6194E9',
              '#8CB0EF',
              '#C3D6F7',
              '#E3EAF7',
            ],
            hoverBackgroundColor: [
              '#174EA8',
              '#1E5FCA',
              '#3677E2',
              '#6194E9',
              '#8CB0EF',
              '#C3D6F7',
              '#E3EAF7',
            ],
          },
        ],
      }}
      options={{
        tooltips: {
          callbacks: {
            label: ({ index }, { labels }) => {
              return `${labels[index]}: ${roundedPercentageValues[index]}%`;
            },
          },
        },
      }}
    />
  );
};

export default AgeGroups;
