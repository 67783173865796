import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { useState } from 'react';

import { CommentsTableAccordion } from './CommentsTableAccordion';
import { TranscriptAccordion } from './TranscriptAccordion';
import { getVideoIdFromUrl } from './utils';

export const SummaryModal = ({
  link,
  creatorSummary,
  audienceSummary,
  startTime,
  transcript,
  comments,
}) => {
  const [open, setOpen] = useState(false);

  const videoId = getVideoIdFromUrl(link);
  const parent =
    window.location.hostname === 'localhost'
      ? 'localhost'
      : window.location.hostname;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        style={{
          borderWidth: 1,
        }}
        onClick={handleClickOpen}
      >
        View Summary
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Moment Summary</DialogTitle>
        <DialogContent style={{ width: '100%' }}>
          <DialogContentText id="alert-dialog-description">
            {creatorSummary && (
              <>
                <h4 style={{ marginTop: 2 }}>Creator Summary</h4>
                <div
                  style={{
                    padding: '12px 24px',
                    borderRadius: 12,
                    backgroundColor: '#E6E6FC',
                    color: 'black',
                    marginBottom: 24,
                  }}
                >
                  <div>{creatorSummary}</div>
                </div>
                <TranscriptAccordion transcript={transcript} />
              </>
            )}

            {audienceSummary && (
              <>
                <h4 style={{ marginTop: 24 }}>Audience Summary</h4>
                <div
                  style={{
                    padding: '12px 24px',
                    borderRadius: 12,
                    backgroundColor: '#FCEBD5',
                    color: 'black',
                    marginBottom: 24,
                  }}
                >
                  <div>{audienceSummary}</div>
                </div>
                <CommentsTableAccordion data={comments} />
              </>
            )}

            <div style={{ marginTop: 24, minWidth: 500 }}>
              {link.includes('twitch') && (
                <iframe
                  src={`https://player.twitch.tv/?video=${videoId}&parent=${parent}&time=${
                    startTime + 's'
                  }`}
                  height="450"
                  width="100%"
                  title="video_twitch"
                  style={{
                    border: '1px solid rgb(221, 221, 221)',
                    borderRadius: 4,
                  }}
                ></iframe>
              )}
              {(link.includes('youtube') || link.includes('youtu.be')) && (
                <iframe
                  width="100%"
                  height="450"
                  src={`https://www.youtube.com/embed/${videoId}?start=${startTime}`}
                  title="video_youtube"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  style={{
                    border: '1px solid rgb(221, 221, 221)',
                    borderRadius: 4,
                  }}
                ></iframe>
              )}
              {link.includes('tiktok') && (
                <iframe
                  src={`https://www.tiktok.com/embed/v2/${videoId}`}
                  height="750"
                  width="100%"
                  title="tiktok_video"
                  frameBorder={0}
                ></iframe>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '12px 24px' }}>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
