import 'moment-duration-format';

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';

import CardTitle from '../../../components/CardTitle';
import capitalizeWord from '../../../utils/capitalizeWord';

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: theme.spacing(48),
    overflowY: 'scroll',
    flexGrow: 1,
    paddingTop: theme.spacing(4),
  },
  italic: {
    fontSize: 13,
    fontStyle: 'italic',
    display: 'inline',
  },
}));
const InfluencerPlayedGames = (props) => {
  const { playedGames, lastGame } = props;

  const classes = useStyles();

  let timeframeStart = null;
  let timeframeEnd = null;
  let totalDuration = null;

  const data = Object.values(
    playedGames.reduce((result, { image, game_name, duration, start_at }) => {
      if (!result[game_name]) {
        result[game_name] = {
          game_name: capitalizeWord(game_name),
          image,
          duration: 0,
        };
      }

      result[game_name].duration += duration;

      if (!timeframeStart || timeframeStart.isAfter(moment(start_at))) {
        timeframeStart = moment(start_at);
      }

      if (!timeframeEnd || timeframeEnd.isBefore(moment(start_at))) {
        timeframeEnd = moment(start_at);
      }

      totalDuration += duration;

      return result;
    }, {})
  ).sort((a, b) => b.duration - a.duration);

  const mostPlayed = data[0];
  const lastPlayed = playedGames.sort(
    (a, b) => moment(a.start_at).valueOf() - moment(b.start_at).valueOf()
  )[0];
  const lastGameDisplay = lastGame || (lastPlayed && lastPlayed.game_name);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} justify="space-between" alignItems="center">
          <Grid item xs={4}>
            <CardTitle>Top streamed games on Twitch</CardTitle>
          </Grid>
          <Grid
            item
            className={classes.italic}
            xs={6}
            style={{ textAlign: 'right' }}
          >
            <Typography className={classes.italic} color="textSecondary">
              Based on time frame:&nbsp;
              {timeframeStart.format('MMMM Do')}
              &nbsp;-&nbsp;
              {timeframeEnd.format('MMMM Do YYYY')}
            </Typography>
          </Grid>
          {mostPlayed && (
            <Grid item xs={6}>
              <span style={{ fontSize: 11 }}> MOST PLAYED GAME </span>
              <br />
              <Typography variant="h2">
                {capitalizeWord(mostPlayed.game_name)}
              </Typography>
            </Grid>
          )}
          {lastGameDisplay && (
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <span style={{ fontSize: 11 }}> LAST PLAYED GAME </span>
              <br />
              <Typography variant="h2">
                {capitalizeWord(lastGameDisplay)}
              </Typography>
            </Grid>
          )}
        </Grid>
        <br />
        <List className={classes.list}>
          {Object.values(data).map(function (playedGame, i) {
            const { duration, game_name, image } = playedGame;

            return (
              <ListItem key={i} button>
                <Box width="100%">
                  <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Avatar src={image} />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            component="span"
                            display="block"
                          >
                            {game_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {duration >= 60 && (
                        <Box fontSize="1.25rem" fontWeight="bold">
                          {moment.duration(duration, 'minutes').format('H')}
                          &nbsp; hours
                        </Box>
                      )}
                      {duration < 60 && (
                        <Box fontSize="1.25rem" fontWeight="bold">
                          {'<1 hour'}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Box clone mt={1}>
                    <LinearProgress
                      variant="determinate"
                      value={Math.round((duration / totalDuration) * 100)}
                    />
                  </Box>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

export default InfluencerPlayedGames;
