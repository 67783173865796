import { groupBy, maxBy, sumBy } from 'lodash';
import React, { useMemo } from 'react';

import InfluencerAvatar from '../components/InfluencerAvatar';
import Table, { StatBox } from '../Table';
import { Campaign, CampaignInfluencer } from '../types';
import { findInfluencerByName } from '../utils';
import { TopMoment } from './types';
import { getVideoByMoment, getVideoIdByMoment } from './utils';

const ROW_HEIGHT = 130;

const getCols = (data: TopMoment[]) => {
  const hasCreatorSentimentColumn = () => {
    return data.some((row) => !!row.creator_sentiment);
  };

  const hasAudienceSentimentColumn = () => {
    return data.some((row) => !!row.audience_sentiment);
  };

  return [
    {
      label: 'Creator',
      key: 'creator',
      render: (row: Row) => {
        return (
          <InfluencerAvatar
            influencer={row.streamer_profile}
            platform={''}
            isDiscovery={false}
          />
        );
      },
      stretch: true,
      flex: 0.75,
    },
    {
      label: 'Videos',
      key: 'videos',
      render: (row: Row) => {
        return (
          <StatBox
            stat={row.videos.length}
            info={`Videos that have top moments`}
          />
        );
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Comments',
      key: 'sumMomentsLength',
      render: (row: Row) => {
        return <StatBox stat={row.sumMomentsLength} />;
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Moments',
      key: 'topMomentsCount',
      render: (row: Row) => {
        return <StatBox stat={row.topMomentsCount} />;
      },
      stretch: true,
      flex: 0.5,
    },
    hasCreatorSentimentColumn && {
      label: 'Creator Positive Sentiment',
      key: 'creatorPositiveSentimentRatio',
      render: (row: Row) => {
        return (
          <StatBox
            stat={row.creatorPositiveSentimentRatio * 100}
            suffix="%"
            info={`${row.creatorPositiveSentimentMoments.length} positive top moments transcripts out of ${row.topMomentsCount}`}
          />
        );
      },
      stretch: true,
      flex: 0.75,
    },
    hasAudienceSentimentColumn && {
      label: 'Audience Positive Sentiment',
      key: 'audiencePositiveSentimentRatio',
      render: (row: Row) => {
        return (
          <StatBox
            stat={row.audiencePositiveSentimentRatio * 100}
            suffix="%"
            info={`${row.audiencePositiveSentimentMoments.length} positive comments out of ${row.allCommentsCount} comments in ${row.topMomentsCount} top moments`}
          />
        );
      },
      stretch: true,
      flex: 0.75,
    },
    {
      label: 'Avg CCV',
      key: 'avgCCV',
      render: (row: Row) => {
        return <StatBox stat={row.avgCCV} />;
      },
      stretch: true,
      flex: 0.5,
    },
    {
      label: 'Engagement Rate',
      key: 'engagementRate',
      render: (row: Row) => {
        const infoText = row.hasFilteredTopMoments
          ? `Number of comments from filtered top moments / Number of comments from all top moments`
          : `Number of comments from top moments / Total number of comments from videos`;

        return (
          <StatBox stat={row.engagementRate * 100} suffix="%" info={infoText} />
        );
      },
      stretch: true,
      flex: 0.5,
    },
  ];
};

const filterData = ({
  data,
  filters,
  campaign,
}: {
  data: TopMoment[];
  filters: Filters;
  campaign: Campaign;
}) => {
  return data.filter((moment) => {
    const video = getVideoByMoment(moment, campaign.videos) || {};

    return (
      (filters.selectedInfluencer === 'all' ||
        moment.streamer.toLowerCase() ===
          filters.selectedInfluencer.toLowerCase()) &&
      (filters.selectedTopic === 'all' ||
        moment.topic.toLowerCase() === filters.selectedTopic.toLowerCase()) &&
      (filters.selectedCategory === 'all' ||
        moment.category.toLowerCase() ===
          filters.selectedCategory.toLowerCase()) &&
      (filters.languageFilter.length === 0 ||
        filters.languageFilter.includes(video.language)) &&
      (filters.selectedRegion === 'all' ||
        moment.region.toLowerCase() === filters.selectedRegion.toLowerCase()) &&
      (filters.selectedPlatform === 'all' ||
        moment.platform.toLowerCase() ===
          filters.selectedPlatform.toLowerCase())
    );
  });
};

const computeTopStat = (data: TopMoment[], statKey: string) => {
  // I want to compute for each category from the data the sum of moment_length
  // and then find the category with the highest sum
  const statMoments = groupBy(data, statKey);
  const statMomentsSum = Object.keys(statMoments).map((stat) => {
    return {
      stat,
      sum: sumBy(statMoments[statKey], (row) => row.moment_length),
    };
  });

  return maxBy(statMomentsSum, 'sum')?.stat || null;
};

const computeCreatorStats = ({
  data,
  campaign,
  filters,
  originalData,
}: {
  data: TopMoment[];
  campaign: Campaign;
  filters: Filters;
  originalData: TopMoment[];
}) => {
  const topMoments = data.filter(
    (topMoment) => topMoment.moment_starts_here.toLowerCase() === 'true'
  );
  const originalTopMoments = originalData.filter(
    (topMoment) => topMoment.moment_starts_here.toLowerCase() === 'true'
  );
  const sumMomentsLength = sumBy(topMoments, (row) => row.moment_length);
  const topCategory = computeTopStat(topMoments, 'category');
  const topTopic = computeTopStat(topMoments, 'topic');
  const avgCCV =
    sumBy(topMoments, (row) => row.moment_ccv || 0) / topMoments.length;

  const uniqueVideosIds = new Set(
    topMoments.map((row) => getVideoIdByMoment(row))
  );

  const videos = campaign.videos.filter((video) =>
    uniqueVideosIds.has(video.external_id)
  );

  const videoCommentsSum = sumBy(videos, (video) => video.comments_count);

  const hasFilteredTopMoments =
    filters.selectedTopic !== 'all' ||
    filters.selectedCategory !== 'all' ||
    filters.languageFilter.length > 0;
  let engagementRate = null;

  // If there is a topic or category filter, we compute the engagement rate as the sum of moments length divided by the sum of moments length of the unfiltered data
  if (hasFilteredTopMoments) {
    engagementRate =
      sumMomentsLength / sumBy(originalTopMoments, (row) => row.moment_length);
  } else {
    engagementRate = sumMomentsLength / videoCommentsSum;
  }

  // Creator positive sentiment ratio
  const creatorPositiveSentimentMoments = topMoments.filter(
    (row) => row.creator_sentiment === 'positive'
  );
  const creatorPositiveSentimentRatio =
    creatorPositiveSentimentMoments.length / topMoments.length;

  // Audience positive sentiment ratio
  const audiencePositiveSentimentMoments = data.filter(
    (row) => row.audience_sentiment === 'positive'
  );
  const audiencePositiveSentimentRatio =
    audiencePositiveSentimentMoments.length / data.length;

  return {
    topMomentsCount: topMoments.length,
    allCommentsCount: data.length,
    sumMomentsLength,
    topCategory,
    topTopic,
    avgCCV,
    videos,
    creatorPositiveSentimentMoments,
    creatorPositiveSentimentRatio,
    audiencePositiveSentimentMoments,
    audiencePositiveSentimentRatio,
    engagementRate,
    hasFilteredTopMoments,
  };
};

const getRows = ({
  data,
  campaign,
  filters,
}: {
  data: TopMoment[];
  campaign: Campaign;
  filters: Filters;
}) => {
  const originalData = groupBy(data, 'streamer');
  const filteredData = filterData({ data, filters, campaign });
  const byStreamerFiltered = groupBy(filteredData, 'streamer');

  return Object.keys(byStreamerFiltered)
    .map((streamerName) => {
      const streamerProfile = findInfluencerByName(
        streamerName,
        campaign.campaign_influencers
      ) as CampaignInfluencer | undefined;

      return {
        streamer_name: streamerName,
        streamer_profile: streamerProfile || {
          name: streamerName,
          avatar: null,
        },
        key: streamerProfile?.id || streamerName,
        ...computeCreatorStats({
          data: byStreamerFiltered[streamerName],
          campaign,
          originalData: originalData[streamerName],
          filters,
        }),
      };
    })
    .filter((row) => row.topMomentsCount > 0);
};

type Row = ReturnType<typeof getRows>[0];

type Filters = {
  selectedTopic: string;
  selectedCategory: string;
  selectedInfluencer: string;
  languageFilter: string[];
  selectedRegion: string;
  selectedPlatform: string;
};

export const TopCreatorsTable = ({
  data,
  campaign,
  filters,
}: {
  data: TopMoment[];
  campaign: Campaign;
  filters: Filters;
}) => {
  const rows = useMemo(
    () =>
      getRows({
        data,
        campaign,
        filters,
      }),
    [data, campaign, filters]
  );

  const cols = useMemo(() => getCols(data), [data]);

  return (
    <>
      <Table
        cols={cols}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        minWidth={1300}
        defaultSortCol="sumMomentsLength"
        idTable={'top_creators_table'}
        sortKey={undefined}
        noBorders={undefined}
        defaultIncreasing={undefined}
        onRowClick={undefined}
        onCreatorColClick={undefined}
      />
    </>
  );
};
