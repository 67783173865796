import React from 'react';

import absolutize from '../helpers/absolutize';

/*
 * a little security precation against 'Reverse Tabnabbing' defaults for the 'a' tag
 *
 * https://www.owasp.org/index.php/Reverse_Tabnabbing
 *
 * also, link absolutization
 */

const Hyperlink = React.forwardRef((props, ref) => {
  let attributes = {};

  if (props.target === '_blank') {
    attributes = {
      rel: 'noopener noreferrer',
      href: absolutize(props.href),
    };
  }

  return <a {...props} {...attributes} ref={ref} />;
});

export default Hyperlink;
