import { Card, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AddToWatchlistForm from '../../../common/components/AddToWatchlistForm';
import GoBack from '../../../components/GoBack';
import TopBar from '../../../components/TopBar';
import TopBarContent from '../../../components/TopBarContent';
import TopBarTitle from '../../../components/TopBarTitle';
import { getWatchlistById } from '../../../store/models/watchlists/watchlists.selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '128px',
    paddingBottom: '64px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    overflowY: 'scroll',
    height: '100%',
  },
  cardTitle: {
    margin: theme.spacing(2),
    marginBottom: 0,
  },
}));

export default function AddToWatchlist() {
  const classes = useStyles();

  const { watchlistId } = useParams();

  const watchlist = useSelector(getWatchlistById(parseInt(watchlistId)));

  const watchlistName = watchlist ? watchlist.name : null;

  return (
    <>
      <TopBar>
        <TopBarContent>
          <Grid container alignItems="center">
            <Grid item>
              <GoBack>
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </GoBack>
            </Grid>
            <Grid item style={{ flex: 1, padding: '10px' }}>
              <TopBarTitle>{watchlistName}</TopBarTitle>
            </Grid>
          </Grid>
        </TopBarContent>
      </TopBar>
      <div className={classes.root}>
        <Card>
          <Typography className={classes.cardTitle} variant="h6">
            Add creator
          </Typography>
          <AddToWatchlistForm />
        </Card>
      </div>
    </>
  );
}
