import { Box, makeStyles, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';

import desktop from './images/desktop_mac.svg';
import desktop_active from './images/desktop_mac_active.svg';
import sports from './images/sports_esports.svg';
import sports_active from './images/sports_esports_active.svg';
const useStyles = makeStyles({
  formDescribe: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '20px',
    background: '#FFFFFF',
    border: '2px solid rgba(0, 0, 0, 0.23)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '240px',
    height: '214px',
    cursor: 'pointer',
  },
  title: {
    marginTop: 5,
    fontSize: '1.3rem',
    lineHeight: '24px',
  },
  subTitle: {
    marginTop: 12,
    fontSize: '1.1rem',
    lineHeight: '20px',
  },
  itemActive: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '20px',
    background: 'rgba(204, 219, 255, 0.16)',
    border: '2px solid #0048F2',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '240px',
    height: '214px',
    cursor: 'pointer',
  },
  img: {
    width: 52,
    height: 52,
  },
});
const variants = [
  {
    id: 1,
    title: 'Pro Player',
    subTitle: 'I play professional esports games',
    img: sports,
    img_active: sports_active,
  },
  {
    id: 2,
    title: 'Full-time Streamer',
    subTitle: 'I stream while playing on a regular weekly basis',
    img: desktop,
    img_active: desktop_active,
  },
];

const DescribesForm = () => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();
  const selectDescribe = (id) => {
    if (id === 1) {
      setFieldValue('player_type.pro_player', !values.player_type.pro_player);
    } else {
      setFieldValue('player_type.streamer', !values.player_type.streamer);
    }
  };
  return (
    <div className={classes.formDescribe}>
      {variants.map((item, index) => (
        <Box
          onClick={() => selectDescribe(item.id)}
          key={index}
          className={
            values.player_type[item.id === 1 ? 'pro_player' : 'streamer']
              ? classes.itemActive
              : classes.item
          }
        >
          <img
            className={classes.img}
            src={
              values.player_type[item.id === 1 ? 'pro_player' : 'streamer']
                ? item.img_active
                : item.img
            }
            alt={item.img}
          ></img>
          <Typography
            style={{
              color: values.player_type[
                item.id === 1 ? 'pro_player' : 'streamer'
              ]
                ? '#0048F2'
                : '#000000',
            }}
            className={classes.title}
          >
            {item.title}
          </Typography>
          <Typography
            style={{
              color: values.player_type[
                item.id === 1 ? 'pro_player' : 'streamer'
              ]
                ? '#0048F2'
                : '#000000',
            }}
            className={classes.subTitle}
          >
            {item.subTitle}
          </Typography>
        </Box>
      ))}
    </div>
  );
};

export default DescribesForm;
