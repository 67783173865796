import { Divider, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  inset: {
    width: '60%',
    margin: 'auto',
  },
  line: {
    height: 2,
  },
});

function SideLine(props) {
  const { children, inset } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      wrap="nowrap"
      className={clsx({ [classes.inset]: inset })}
    >
      <Grid item style={{ flex: 1 }}>
        <Divider className={classes.line} />
      </Grid>
      <Grid item>{children}</Grid>
      <Grid item style={{ flex: 1 }}>
        <Divider className={classes.line} />
      </Grid>
    </Grid>
  );
}

export default SideLine;
