const SIZES = {
  nano: [1e3, 1e4],
  micro: [1e4, 5e4],
  'mid-tier': [5e4, 5e5],
  macro: [5e5, 1e6],
  mega: [1e6, Infinity],
};

export const getInfluencerSize = (followers) => {
  const size = Object.keys(SIZES).find((size) => {
    const [min, max] = SIZES[size];

    return followers >= min && followers < max;
  });

  return size || '';
};

export const getFollowerRange = (size) => SIZES[size];
