import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const OopsPage = () => {
  return (
    <Box display="flex" justifyContent="center" flexDirection="column" flex="1">
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h3" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="h2">Looks like something went wrong!</Typography>
      </Box>
    </Box>
  );
};
