import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';

import { State } from '../../../../../store/types';
import { LoadingState, StreamingPlatform } from '../../../../../types';
import { setDateFilter } from '../../store/Stats.slice';
import { fetchStreams, submitStreamSegment } from './Streams.thunks';
import { SLICE_NAME, StreamsOverview, Video } from './types';
import { streamsToVideos } from './utils';

export const streamsAdapter = createEntityAdapter<Video>();

export const initialState = streamsAdapter.getInitialState<{
  loading: LoadingState;
  overview: StreamsOverview;
  error: SerializedError | null;
  platformFilter: 'all' | StreamingPlatform;
  videoTypeFilter: 'all' | 'live_stream' | 'vod';
}>({
  loading: LoadingState.IDLE,
  error: null,
  overview: null,
  platformFilter: 'all',
  videoTypeFilter: 'all',
});

const streamsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setStreamsPlatformFilter: (
      state,
      action: PayloadAction<StreamsState['platformFilter']>
    ) => {
      state.platformFilter = action.payload;
      // "INVALIDATE" the cached data by setting the loading state to IDLE
      state.loading = LoadingState.IDLE;
    },

    setStreamsVideoTypeFilter: (
      state,
      action: PayloadAction<StreamsState['videoTypeFilter']>
    ) => {
      state.videoTypeFilter = action.payload;
      // "INVALIDATE" the cached data by setting the loading state to IDLE
      state.loading = LoadingState.IDLE;
    },
  },
  extraReducers: (builder) => {
    // FULFILLED
    builder.addCase(fetchStreams.fulfilled, (state, action) => {
      const { streams, ...overviewData } = action.payload;

      streamsAdapter.setAll(state, streamsToVideos(streams));
      state.overview = overviewData;

      state.loading = LoadingState.FULFILLED;
      state.error = null;
    });

    // PENDING
    builder.addCase(fetchStreams.pending, (state) => {
      state.loading = LoadingState.PENDING;
      state.error = null;
    });

    // REJECTED
    builder.addCase(fetchStreams.rejected, (state, action) => {
      state.loading = LoadingState.REJECTED;
      state.error = action.error;
    });

    builder.addCase(setDateFilter, (state) => {
      state.loading = LoadingState.IDLE;
    });

    // trigger a re-fetch of the streams when modifiying the stream segments
    builder.addCase(submitStreamSegment.fulfilled, (state) => {
      state.loading = LoadingState.IDLE;
    });
  },
});

export const {
  reducer,
  actions: { setStreamsPlatformFilter, setStreamsVideoTypeFilter },
} = streamsSlice;
export type StreamsState = typeof initialState;

// We have to export this selector that depends on the adapter from here (instead of .selectors.ts) in order to avoid circular dependency issues
export const selectAllStreams = streamsAdapter.getSelectors(
  (state: State) => state[SLICE_NAME]
).selectAll;
