import _memoize from 'lodash/memoize';

import { isoLanguagesMap } from '../../common/iso-languages';

export const findInfluencerByVideo = _memoize(
  (video, campaignInfluencers) => {
    const influencer = campaignInfluencers?.find(
      ({ id }) => video?.campaign_influencer_ids?.indexOf(id) !== -1
    );

    if (!influencer) {
      console.log({ video, campaignInfluencers });
    }

    return influencer || {};
  },
  (video) => video.id
);

export const findInfluencerById = _memoize(
  (id, campaignInfluencers) =>
    campaignInfluencers?.find((influencer) => influencer.id === id) || {}
);

export const findInfluencerByName = _memoize(
  (name, campaignInfluencers) =>
    campaignInfluencers?.find(
      (influencer) => influencer?.name?.toLowerCase() === name.toLowerCase()
    ) || null
);

export const findVideoById = _memoize(
  (id, videos) => videos.find((videos) => videos.id === id) || {}
);

export const findVideoByLink = _memoize(
  (link, videos) => videos.find((videos) => videos.link === link) || null
);

export const findVideoByExternalId = _memoize(
  (external_id, videos) =>
    videos.find((videos) => videos.external_id === external_id) || null
);

export const getVideoDuration = ({ video_segments, duration }) => {
  if (video_segments && video_segments.length > 0) {
    const [{ start_at, end_at }] = video_segments;

    return end_at - start_at;
  }

  return duration;
};

export const segmentNotEqualToVideo = (video, { start_at, end_at }) =>
  start_at !== 0 || end_at !== video.duration;

export const videoHasSegments = ({ video_segments, ...video }) => {
  return (
    video_segments &&
    video_segments.length > 0 &&
    segmentNotEqualToVideo(video, video_segments[0])
  );
};

export const getTrackedPlatforms = (campaignInfluencers) => {
  const twitch = campaignInfluencers.some(
    (influencer) => !!influencer.twitch_url
  );
  const youtube = campaignInfluencers.some(
    (influencer) => !!influencer.youtube_url
  );

  const twitter = campaignInfluencers.some(
    (influencer) => !!influencer.twitter_url
  );
  const instagram = campaignInfluencers.some(
    (influencer) => !!influencer.instagram_url
  );
  const tiktok = campaignInfluencers.some(
    (influencer) => !!influencer.tiktok_url
  );
  const facebook = campaignInfluencers.some(
    (influencer) => !!influencer.facebook_url
  );
  const facebookGaming = campaignInfluencers.some(
    (influencer) => !!influencer.facebook_gaming_url
  );

  return {
    twitch,
    youtube,
    twitter,
    instagram,
    tiktok,
    facebook,
    facebookGaming,
  };
};

// segment_start is in epoch time (milliseconds)
export const getMomentURL = (video, segment_start) => {
  if (!video || !video.link) {
    return null;
  }

  let momentURL = video.link;

  if (video.resource === 'facebook_gaming' || video.resource === 'facebook') {
    momentURL = momentURL + `?t=${segment_start / 1000}`;
  } else {
    momentURL += `${momentURL.includes('?') ? '&' : '?'}t=${Math.floor(
      segment_start / 1000
    )}s`;
    // https://www.twitch.tv/videos/603719988?t=0h33m52s
  }

  return momentURL;
};

export const streamingPlatforms = [
  'twitch',
  'youtube',
  'facebook_gaming',
  'facebook',
  'tiktok_video',
];

export const videoIsFromStreamingPlatform = (video) => {
  return streamingPlatforms.includes(video.resource);
};

// Possible values: media_tracking, performance, sponsorship_valuation
export const getCampaignTypes = (campaign) => {
  return campaign.campaign_types.map((data) => data.campaign_type);
};

//TODO: This is a temporary solution to display Top Moments data for some campaigns. Newer campaigns will have this data in the Google Storage and fetch it from there.
export const campaignHasStaticTopMoments = (campaign_id) => {
  const campaignsWithTopMoments = [4241, 4232, 4192, 4047];

  return campaignsWithTopMoments.includes(campaign_id);
};

export const campaignHasTopMoments = (campaign) => {
  return (
    campaignHasStaticTopMoments(campaign?.id) || campaign?.top_moments === true
  );
};

export const campaignHasStreamlitStats = (campaign) => {
  return campaign?.perform_campaign_insights_for !== 'off';
};

// Returns the language key from the language string. If the language is 'other', returns null. If the key is in the "en-US" format returns "en".
export const normalizeLanguageKey = (language) => {
  if (!language || language === 'other') {
    return null;
  }

  const parsedLanguage = language?.split('-')[0];

  if (!isoLanguagesMap[parsedLanguage]) {
    return null;
  }

  return parsedLanguage;
};

export const getCampaignUsedLanguages = (campaign) => {
  const languages = campaign.videos
    .map((video) => video.language)
    .filter(Boolean);

  return [...new Set(languages)];
};

export const getCampaignUsedPlatforms = (campaign) => {
  const platforms = campaign.videos
    .map((video) => video.resource)
    .filter(Boolean);

  return [...new Set(platforms)];
};

// This is a "hack" to clear the Flowise chat localStorage.
// This is necessary because the chat messages are stored in the localStorage and when the user changes the campaign, the chat messages from the previous campaign are still displayed.
export const clearFlowiseChatsLocalStorage = () => {
  localStorage.removeItem('6854595d-e7f4-4375-9ccb-d4a2755d264f_EXTERNAL');
  localStorage.removeItem('4028d344-2e7e-43d8-a6d2-346583e8ef49_EXTERNAL');
};
