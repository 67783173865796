import objectMap from '../utils/object-map';

/*
 * object => object => object
 *
 * takes an object of type { prop-name: selector }
 * returns a mapStateToProp function that resolves
 * to the same object structure as initially passed
 * but with the selectors replaced with their resolved
 * values given the application state
 * */
function mapSelectorsToProps(selectors) {
  return function (state) {
    const mapper = (selector) => selector(state);

    return objectMap(mapper)(selectors);
  };
}

export default mapSelectorsToProps;
