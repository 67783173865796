import { Box, CircularProgress } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import momentTz from 'moment-timezone';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { getSocialMentionsDeliverables } from '../../../../ajax';
import { DeliverablePaginator } from '../../components/DeliverablePaginator';
import useScrollToActivePoint from '../../hooks/useScrollToActivePoint';
import SocialMentionDeliverable from './SocialMentionDeliverable';

export const SocialMentions = ({
  campaign,
  usedInfluencers,
  timeFrame,
  selectedCampaignInfluencer,
  setSelectedCampaignInfluencer,
  brandFilter,
  platformFilter,
  activePoint,
  setActivePoint,
  isSponsorship,
  isFilteredByCreator,
  setByCreator,
  setSocialPostDelete,
  socialPerformanceIdDelete,
  numberPage,
  setNumberPage,
}) => {
  useScrollToActivePoint(activePoint, setActivePoint);
  const { isLoading, error, data } = useQuery(
    `campaign-${campaign.id}-social-mentions`,
    async () => {
      return await getSocialMentionsDeliverables(campaign.id);
    }
  );

  useEffect(() => {
    if (!!numberPage) {
      return () => {
        setNumberPage(0);
      };
    }
  }, []);

  const socialMentionDeliverables = useMemo(() => {
    if (!data) return null;
    const keywords = [
      ...data.instagram_keyword_mention_deliverables.map((d) => {
        return { keyword: d.keyword, campaign_brand_id: d.campaign_brand_id };
      }),
      ...data.twitter_keyword_mention_deliverables.map((d) => {
        return { keyword: d.keyword, campaign_brand_id: d.campaign_brand_id };
      }),
      ...data.tiktok_keyword_mention_deliverables,
      ...data.facebook_keyword_mention_deliverables,
    ].reduce(
      (acc, x) =>
        acc.concat(
          acc.find(
            (y) =>
              y.keyword === x.keyword &&
              y.campaign_brand_id === x.campaign_brand_id
          )
            ? []
            : [x]
        ),
      []
    );

    const reducer = (l, d) =>
      l.concat(
        (d.posts || d.tweets)
          .filter((p) =>
            momentTz(p.published_at).isBetween(timeFrame.start, timeFrame.end)
          )
          .map((p) => ({
            influencer_id: d.campaign_influencer_id,
            network: d.network,
            username: d.username,
            ...p,
          }))
      );

    const filter = (keyword) => (d) =>
      d.keyword === keyword &&
      (selectedCampaignInfluencer === 'all' ||
        d.campaign_influencer_id === selectedCampaignInfluencer.id) &&
      (brandFilter === 'all' ? true : d.campaign_brand_id === brandFilter);

    const deliverables = keywords.map((k) => ({
      keyword: k.keyword,
      twitterPosts:
        platformFilter === 'all' || platformFilter === 'twitter'
          ? _uniqBy(
              data.twitter_keyword_mention_deliverables
                .filter((elem) =>
                  isSponsorship
                    ? elem.campaign_brand_id
                    : elem.campaign_brand_id === null
                )
                .filter(filter(k.keyword))
                .reduce(reducer, []),
              'tweet_id'
            )
          : [],
      instagramPosts:
        platformFilter === 'all' || platformFilter === 'instagram'
          ? _uniqBy(
              data.instagram_keyword_mention_deliverables
                .filter((elem) =>
                  isSponsorship
                    ? elem.campaign_brand_id
                    : elem.campaign_brand_id === null
                )
                .filter(filter(k.keyword))
                .reduce(reducer, []),
              'shortcode'
            )
          : [],
      tiktokPosts:
        platformFilter === 'all' || platformFilter === 'tiktok'
          ? _uniqBy(
              data.tiktok_keyword_mention_deliverables
                .filter((elem) =>
                  isSponsorship
                    ? elem.campaign_brand_id
                    : elem.campaign_brand_id === null
                )
                .filter(filter(k.keyword))
                .reduce(reducer, []),
              'id'
            )
          : [],
      facebookPosts:
        platformFilter === 'all' || platformFilter === 'facebook'
          ? _uniqBy(
              data.facebook_keyword_mention_deliverables
                .filter((elem) =>
                  isSponsorship
                    ? elem.campaign_brand_id
                    : elem.campaign_brand_id === null
                )
                .filter(filter(k.keyword))
                .reduce(reducer, []),
              'id'
            )
          : [],
      campaign_brand_id: k.campaign_brand_id,
    }));

    return _sortBy(
      isSponsorship
        ? deliverables
            .filter((el) => el.campaign_brand_id)
            .filter((el) =>
              brandFilter === 'all' ? el : el.campaign_brand_id === brandFilter
            )
        : deliverables.filter((el) => !el.campaign_brand_id),
      (d) =>
        d.twitterPosts.length +
          d.instagramPosts.length +
          d.tiktokPosts.length +
          d.facebookPosts.length ===
        0
    ).map((vcs) => {
      vcs.twitterPosts = vcs.twitterPosts.filter(
        (vc) => !socialPerformanceIdDelete.includes(vc.tweet_id)
      );
      vcs.instagramPosts = vcs.instagramPosts.filter(
        (vc) => !socialPerformanceIdDelete.includes(vc.shortcode)
      );

      vcs.tiktokPosts = vcs.tiktokPosts.filter(
        (vc) => !socialPerformanceIdDelete.includes(vc.id)
      );

      vcs.facebookPosts = vcs.facebookPosts.filter(
        (vc) => !socialPerformanceIdDelete.includes(vc.id)
      );

      return vcs;
    });
  }, [
    data,
    selectedCampaignInfluencer,
    timeFrame,
    brandFilter,
    isSponsorship,
    socialPerformanceIdDelete,
  ]);

  const renderDeliverable = useCallback(
    (socialMentionDeliverable) => (
      <SocialMentionDeliverable
        key={`${socialMentionDeliverable.campaign_brand_id}-${socialMentionDeliverable.keyword}`}
        socialMentionDeliverable={socialMentionDeliverable}
        setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
        usedInfluencers={usedInfluencers}
        timeZone={campaign.time_zone}
        platformFilter={platformFilter}
        isSponsorship={isSponsorship}
        isFilteredByCreator={isFilteredByCreator}
        setByCreator={setByCreator}
        setSocialPostDelete={setSocialPostDelete}
      />
    ),
    [
      setSelectedCampaignInfluencer,
      usedInfluencers,
      campaign,
      platformFilter,
      isSponsorship,
      isFilteredByCreator,
      setByCreator,
      setSocialPostDelete,
    ]
  );

  if (isLoading)
    return (
      <Box
        height={400}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  if (error) return <p>error</p>;

  return (
    <div style={{ paddingTop: 24 }}>
      <DeliverablePaginator
        deliverables={socialMentionDeliverables}
        renderDeliverable={renderDeliverable}
        forcePageIndex={numberPage}
      />
    </div>
  );
};

export default SocialMentions;
