import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  OutlinedInput,
} from '@material-ui/core';
import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createWatchlistRequested } from '../store/models/watchlists/watchlists.actions';
import {
  getWatchlists,
  getWatchlistsList,
} from '../store/models/watchlists/watchlists.selectors';
import actions from '../views/Influencer/redux';
import LoadingButton from './LoadingButton';

export function useCreateWatchlistDialog() {
  const dispatch = useDispatch();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const { createWatchlistLoading } = useSelector(getWatchlists());
  const watchlist = useSelector(getWatchlistsList());
  const onCreate = (name, setFollow) => {
    dispatch(createWatchlistRequested({ name }));
    if (setFollow) setCheck(true);
  };
  useEffect(() => {
    if (!createWatchlistLoading && createDialogOpen) {
      setCreateDialogOpen(false);
      if (check) {
        dispatch(actions.addToWatchlist(watchlist[0].id));
      }
    }
  }, [createWatchlistLoading]);

  return {
    onCreate,
    createWatchlistLoading,
    createDialogOpen,
    setCreateDialogOpen,
  };
}

const CreateWatchlistDialog = ({
  onCreate,
  onCancel,
  open,
  loading,
  setFollow,
}) => {
  const [name, setName] = useState('');

  return (
    <Dialog open={open} onClose={onCancel} onExited={() => setName('')}>
      <DialogTitle>Create watchlist</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>Watchlist name</FormLabel>
          <OutlinedInput
            placeholder="List 1"
            name="watchlistName"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: 320 }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={() => name.trim().length > 0 && onCreate(name, setFollow)}
          color="primary"
          disabled={name.trim().length === 0}
          variant="contained"
          loading={loading}
        >
          Create
        </LoadingButton>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateWatchlistDialog;
