import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import InstagramIcon from '../assets-2.0/instagram.svg';
import RedditIcon from '../assets-2.0/reddit.svg';
import TwitchIcon from '../assets-2.0/twitch.svg';
import TwitterIcon from '../assets-2.0/twitter.svg';
import YoutubeIcon from '../assets-2.0/youtube.svg';
import CardContent from './CardContent';

const useStyles = makeStyles({
  icon: {
    width: 24,
    height: 24,
  },
});

const iconMap = {
  twitter: TwitterIcon,
  reddit: RedditIcon,
  twitch: TwitchIcon,
  youtube: YoutubeIcon,
  instagram: InstagramIcon,
};

export default function CardIcons({
  twitter,
  reddit,
  twitch,
  youtube,
  instagram,
}) {
  const classes = useStyles();

  function renderIcon(iconName) {
    return (
      <Grid item>
        <img
          className={classes.icon}
          src={iconMap[iconName]}
          alt={`${iconName} icon`}
        />
      </Grid>
    );
  }

  return (
    <CardContent>
      <Grid container spacing={2} alignItems="center" justify="flex-end">
        {twitter && renderIcon('twitter')}
        {reddit && renderIcon('reddit')}
        {twitch && renderIcon('twitch')}
        {youtube && renderIcon('youtube')}
        {instagram && renderIcon('instagram')}
      </Grid>
    </CardContent>
  );
}
