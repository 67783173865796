import { createSlice } from '@reduxjs/toolkit';

import { InfluencerStats, SLICE_NAME } from './types';

const initialState: InfluencerStats = {
  start_date: '',
  end_date: '',
};

const {
  reducer,
  actions: { setDateFilter },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setDateFilter: (state, action) => {
      state.start_date = action.payload.start_date;
      state.end_date = action.payload.end_date;
    },
  },
});

export { reducer, setDateFilter };
