import PersonIcon from '@material-ui/icons/Person';
import ReplyIcon from '@material-ui/icons/Reply';
import VideocamIcon from '@material-ui/icons/Videocam';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DetailedViewToggle, {
  useDetailedViewToggle,
} from '../../components/DetailedViewToggle';
import OverviewCard, {
  PlaceholderOverviewRow,
} from '../../components/OverviewCard';
import SectionTitle from '../../components/SectionTitle';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { findInfluencerByVideo, findVideoById } from '../../utils';
import DetailedTable from './DetailedTable';
import {
  selectLinkTrackingDeliverableById,
  selectLinkTrackingDeliverableShowLoading,
  selectShouldFetchLinkTrackingDeliverable,
} from './store/LinkTrackingDeliverables.selectors';
import { fetchLinkTrackingDeliverable } from './store/LinkTrackingDeliverables.thunks';
import {
  LinkTrackingDeliverableStructure,
  LinkTrackingOccurrence,
} from './store/types';
import SummaryTable, { computeSummary } from './SummaryTable';

// import EyeIcon from '../../components/EyeIcon';

const computeOverview = ({
  usedLinkOccurrences,
  usedInfluencers,
  usedVideos,
}) => {
  const creators = new Set(
    usedLinkOccurrences.map(
      (linkTrackingOccurrence: LinkTrackingOccurrence) =>
        findInfluencerByVideo(
          findVideoById(linkTrackingOccurrence.video_id, usedVideos),
          usedInfluencers
        ).id
    )
  ).size;

  const { occurrences, streams, avgCCV, peakCCV, totalViewers, totalEMV } =
    computeSummary(usedLinkOccurrences);

  return {
    occurrences,
    creators,
    streams,
    avgCCV,
    peakCCV,
    totalViewers,
    totalEMV,
  };
};

interface OverviewProps {
  link: string;
  usedLinkOccurrences: LinkTrackingOccurrence[];
  usedInfluencers: any[];
  usedVideos: any[];
  showLoading: boolean;
  linkTrackingDeliverableId: number;
}

const Overview = React.memo<OverviewProps>(
  ({
    link,
    usedLinkOccurrences,
    usedInfluencers,
    usedVideos,
    showLoading,
    linkTrackingDeliverableId,
  }) => {
    const header = (
      <SectionTitle>
        <b style={{ overflowWrap: 'anywhere' }}>{link}</b>
        <p style={{ margin: 0 }}>Link Sharing</p>
      </SectionTitle>
    );

    if (showLoading)
      return (
        <div>
          {header}
          <PlaceholderOverviewRow nCards={usedInfluencers.length > 1 ? 3 : 2} />
        </div>
      );

    const {
      occurrences,
      creators,
      streams,
      // avgCCV,
      // peakCCV,
      // totalViewers,
      // totalEMV,
    } = computeOverview({
      usedLinkOccurrences,
      usedVideos,
      usedInfluencers,
    });

    return (
      <div>
        {header}
        <OverviewCard.Container>
          <OverviewCard
            label="Creators"
            color="teal"
            main={{ stat: creators }}
            icon={<PersonIcon />}
            outOf={usedInfluencers.length}
            id={`SV-ls-creators-${linkTrackingDeliverableId}`}
          />
          <OverviewCard
            label="Videos"
            color="orange"
            main={{ stat: streams }}
            icon={<VideocamIcon />}
            id={`SV-ls-streams-${linkTrackingDeliverableId}`}
          />
          <OverviewCard
            label="Shares"
            color="blue"
            main={{ stat: occurrences }}
            icon={<ReplyIcon style={{ transform: 'scaleX(-1)' }} />}
            id={`SV-ls-shares-${linkTrackingDeliverableId}`}
          />
          {/*<OverviewCard
            label="Average CCV"
            color="purple"
            main={{ stat: avgCCV }}
            secondary={{ label: 'peak', stat: peakCCV }}
            icon={<EyeIcon />}
          />
          <OverviewCard
            label="Total viewers"
            color="purple"
            main={{ stat: totalViewers }}
            icon={<VisibilityIcon />}
          />
          <OverviewCard
            label="Media value"
            color="green"
            main={{ stat: totalEMV, prefix: '$', format: '0,[0].[0]a' }}
            icon={<LocalAtmIcon />}
          />*/}
        </OverviewCard.Container>
      </div>
    );
  }
);

interface LinkSharingDeliverableProps {
  linkTrackingDeliverableStructure: LinkTrackingDeliverableStructure;
  usedInfluencers: any[];
  usedVideos: any[];
  usedVideosIds: Set<number>;
  timeZone: string;
  setSelectedCampaignInfluencer: (inf: any) => void;
}

export const LinkSharingDeliverable = React.memo<LinkSharingDeliverableProps>(
  ({
    linkTrackingDeliverableStructure,
    usedInfluencers,
    usedVideos,
    usedVideosIds,
    timeZone,
    setSelectedCampaignInfluencer,
  }) => {
    const {
      id: linkTrackingDeliverableId,
      link,
      campaign_id: campaignId,
    } = linkTrackingDeliverableStructure;

    const dispatch = useDispatch();

    const showLoading = useSelector(
      selectLinkTrackingDeliverableShowLoading(linkTrackingDeliverableId)
    );

    const shouldFetch = useSelector(
      selectShouldFetchLinkTrackingDeliverable(linkTrackingDeliverableId)
    );

    useEffect(() => {
      if (shouldFetch) {
        dispatch(
          fetchLinkTrackingDeliverable({
            linkTrackingDeliverableId,
            campaignId,
          })
        );
      }
    }, [dispatch, shouldFetch, linkTrackingDeliverableId, campaignId]);

    const linkTrackingDeliverable = useSelector(
      selectLinkTrackingDeliverableById(linkTrackingDeliverableId)
    );

    const usedLinkOccurrences = useMemo(
      () =>
        showLoading || linkTrackingDeliverable == null
          ? []
          : linkTrackingDeliverable.link_tracking_occurrences.filter(
              (occurrence) => usedVideosIds.has(occurrence.video_id)
            ),
      [linkTrackingDeliverable, showLoading, usedVideosIds]
    );

    const { detailed, setDetailed } = useDetailedViewToggle(usedInfluencers);
    return (
      <div
        style={{ paddingBottom: 100 }}
        data-id={`link-sharing/${linkTrackingDeliverableId}`}
      >
        <Overview
          link={link}
          usedLinkOccurrences={usedLinkOccurrences}
          usedInfluencers={usedInfluencers}
          usedVideos={usedVideos}
          showLoading={showLoading}
          linkTrackingDeliverableId={linkTrackingDeliverableId}
        />
        {usedLinkOccurrences.length > 0 && (
          <>
            <DetailedViewToggle
              {...{ detailed, setDetailed, usedInfluencers }}
            />
            {!detailed && (
              <SummaryTable
                usedInfluencers={usedInfluencers}
                usedVideos={usedVideos}
                usedLinkOccurrences={usedLinkOccurrences}
                setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
                link={link}
              />
            )}
            {detailed && (
              <DetailedTable
                usedInfluencers={usedInfluencers}
                timeZone={timeZone}
                usedVideos={usedVideos}
                usedLinkOccurrences={usedLinkOccurrences}
                link={link}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default LinkSharingDeliverable;
