import { call, put, takeLatest } from 'redux-saga/effects';

import { postCampaign } from '../../ajax';
import { CAMPAIGN_CREATION_SUBMITTED } from '../action-types';
import {
  campaignCreationFailed,
  campaignCreationRequested,
  campaignCreationSucceeded,
} from '../models/campaigns/campaigns.actions';

function* createCampaign(action) {
  const {
    payload: { values },
  } = action;
  yield put(campaignCreationRequested());

  const {
    campaignName,
    campaignTimezone,
    campaignDays,
    campaignDaysFrame,
    isScheduleDateType,
    influencersSocials,
    brandsSocials,
    brands,
    brandId,
    bannerChecked,
    bannerImages,
    shoutouts,
    shoutoutChecked,
    socialMediaMentionChecked,
    socialMediaMentionContent,
    linkSharingChecked,
    links,
    kpiEngagementChecked,
    kpiActivityChecked,
    kpiAudienceReactionChecked,
    kpiKeywordMentionChecked,
    kpiKeywordMentionList,
    campaignTypes,
  } = values;
  const deliverables = {};

  deliverables.social_media_mention_deliverables_attributes = [];
  deliverables.shoutout_deliverables_v2_attributes = [];
  deliverables.banner_deliverables_attributes = [];
  deliverables.keyword_mention_deliverables_attributes = [];
  deliverables.link_tracking_deliverables_attributes = [];
  if (socialMediaMentionChecked) {
    socialMediaMentionContent.forEach((kw) => {
      if (kw && kw.trim().length) {
        deliverables.social_media_mention_deliverables_attributes.push({
          keyword: kw.trim(),
        });
      }
    });
  }
  if (linkSharingChecked) {
    links.forEach((kw) => {
      if (kw && kw.trim().length) {
        deliverables.link_tracking_deliverables_attributes.push({
          link: kw.trim(),
        });
      }
    });
  }
  if (brands && brands.length > 0) {
    brands.forEach((item) => {
      if (brandId && brandId.length > 0 && brandId.includes(item.id)) {
        if (item.socialMediaMentionChecked) {
          item.socialMediaMentionContent.forEach((kw) => {
            if (kw) {
              deliverables.social_media_mention_deliverables_attributes.push({
                keyword: kw.trim(),
                organization_brand_id: item.id,
              });
            }
          });
        }
        if (item.shoutoutChecked && item.shoutouts.length > 0) {
          item.shoutouts.forEach((s) => {
            deliverables.shoutout_deliverables_v2_attributes.push({
              name: s.name,
              inclusion_terms: s.inclusionTerms,
              exclusion_terms: s.exclusionTerms,
              structured: s.structured,
              organization_brand_id: item.id,
            });
          });
        }
        if (item.kpiKeywordMentionChecked && item.kpiKeywordMentionList) {
          item.kpiKeywordMentionList.forEach((kw) => {
            if (kw && kw.trim().length) {
              deliverables.keyword_mention_deliverables_attributes.push({
                keyword: kw.trim(),
                organization_brand_id: item.id,
              });
            }
          });
        }
        if (item.linkSharingChecked) {
          item.links.forEach((kw) => {
            if (kw && kw.trim().length) {
              deliverables.link_tracking_deliverables_attributes.push({
                link: kw.trim(),
                organization_brand_id: item.id,
              });
            }
          });
        }
        if (item.bannerChecked) {
          let bannerDeliverable = null;

          item.bannerImages.forEach(({ bannerUrl, bannerFile }) => {
            bannerDeliverable = {};

            if (bannerUrl) {
              bannerDeliverable.raw_banner_url = bannerUrl;
              bannerDeliverable.organization_brand_id = item.id;
            } else if (bannerFile) {
              bannerDeliverable.raw_banner = { data: bannerFile };
              bannerDeliverable.organization_brand_id = item.id;
            }

            deliverables.banner_deliverables_attributes.push(bannerDeliverable);
          });
        }
      }
    });
  }

  if (shoutoutChecked && shoutouts.length > 0) {
    shoutouts.forEach((s) => {
      deliverables.shoutout_deliverables_v2_attributes.push({
        name: s.name,
        inclusion_terms: s.inclusionTerms,
        exclusion_terms: s.exclusionTerms,
        structured: s.structured,
      });
    });
  }

  if (campaignTypes && campaignTypes.length > 0) {
    deliverables.campaign_types_attributes = [];
    deliverables.campaign_types_attributes = campaignTypes.map((id) => ({
      campaign_type: id,
    }));
  }
  if (kpiKeywordMentionChecked && kpiKeywordMentionList) {
    kpiKeywordMentionList.forEach((kw) => {
      if (kw && kw.trim().length) {
        deliverables.keyword_mention_deliverables_attributes.push({
          keyword: kw.trim(),
        });
      }
    });
  }

  if (bannerChecked) {
    let bannerDeliverable = null;

    bannerImages.forEach(
      ({ bannerUrl, bannerFile, initialBannerDeliverableId }) => {
        bannerDeliverable = {};

        if (initialBannerDeliverableId) {
          bannerDeliverable.initial_banner_deliverable_id =
            initialBannerDeliverableId;
        }

        if (bannerUrl) {
          bannerDeliverable.raw_banner_url = bannerUrl;
        } else if (bannerFile) {
          bannerDeliverable.raw_banner = { data: bannerFile };
        }

        deliverables.banner_deliverables_attributes.push(bannerDeliverable);
      }
    );
  }

  const data = {
    campaign: {
      name: campaignName.trim(),
      time_zone: campaignTimezone,
      kpi_activity: kpiActivityChecked,
      kpi_audience_reaction: kpiAudienceReactionChecked,
      kpi_engagement: kpiEngagementChecked,
      campaign_days: isScheduleDateType ? campaignDays : campaignDaysFrame,
      ...deliverables,
    },
  };

  data.campaign.campaign_influencers_attributes = [];

  influencersSocials.forEach(
    ({
      influencerYoutubeUrl,
      influencerTwitchUrl,
      influencerFacebookUrl,
      influencerInstagramUrl,
      influencerTwitterUrl,
      influencerTiktokUrl,
      influencerFacebookGamingUrl,
      token,
    }) => {
      data.campaign.campaign_influencers_attributes.push({
        youtube_url: !!influencerYoutubeUrl ? influencerYoutubeUrl : null,
        facebook_url: !!influencerFacebookUrl ? influencerFacebookUrl : null,
        twitch_url: !!influencerTwitchUrl ? influencerTwitchUrl : null,
        twitter_url: !!influencerTwitterUrl ? influencerTwitterUrl : null,
        instagram_url: !!influencerInstagramUrl ? influencerInstagramUrl : null,
        tiktok_url: !!influencerTiktokUrl ? influencerTiktokUrl : null,
        facebook_gaming_url: !!influencerFacebookGamingUrl
          ? influencerFacebookGamingUrl
          : null,
        influencer_token: token ?? null,
      });
    }
  );
  data.campaign.campaign_brands_attributes = [];
  if (brandId) {
    data.campaign.campaign_brands_attributes = brandId.map((id) => ({
      organization_brand_id: id,
    }));
  }

  brandsSocials.forEach(({ brandInstagramUrl, brandTwitterUrl }) => {
    if (!!brandInstagramUrl || !!brandTwitterUrl) {
      data.campaign.campaign_brands_attributes.push({
        instagram_url: !!brandInstagramUrl ? brandInstagramUrl : null,
        twitter_url: !!brandTwitterUrl ? brandTwitterUrl : null,
      });
    }
  });
  try {
    const campaign = yield call(postCampaign, data);
    yield put(campaignCreationSucceeded({ campaign }));
  } catch (e) {
    console.error('Campaign Create Error - ', e);

    let message = null;

    if (e.status === 422 || e.status === 408) {
      message = e.bodyJson && e.bodyJson.errors;
    } else if (e.status === 500) {
      message = e.bodyJson && `${e.bodyJson.error} - ${e.bodyJson.exception}`;
    }

    yield put(
      campaignCreationFailed({ error: message || 'Campaign Create Error' })
    );
  }
}

export default function* root() {
  yield takeLatest(CAMPAIGN_CREATION_SUBMITTED, createCampaign);
}
