import remove from './remove';

function arrayToggle(array, value) {
  if (array.includes(value)) {
    return remove(array, value);
  }

  return [...array, value];
}

export default arrayToggle;
