import { AxisTop } from '@visx/axis';
import { Brush as VisxBrush } from '@visx/brush';
import { Bounds } from '@visx/brush/lib/types';
import { curveBasis } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { PatternLines } from '@visx/pattern';
import { scaleLinear } from '@visx/scale';
import { AreaClosed, BarRounded } from '@visx/shape';
import { extent, max } from 'd3-array';
import moment from 'moment';
import React, { useMemo } from 'react';

import { CCVData, ccvGetCCV, ccvGetTime, getBarSize } from './utils';

const BRUSH_HEIGHT = 48;
const BRUSH_PADDING = { top: 8, bottom: 0, left: 0, right: 0 };

const RECTANGLE_PADDING = { top: 32, left: 32, right: 32, bottom: 0 };

const brushYMax = BRUSH_HEIGHT - BRUSH_PADDING.top - BRUSH_PADDING.bottom;

interface BrushProps {
  ccvData: CCVData[];
  width: number;
  onBrushChange: (bounds: Bounds) => void;
  onBrushClick: () => void;
  duration: number;
}

const Brush = ({
  ccvData,
  width,
  onBrushChange,
  onBrushClick,
  duration,
}: BrushProps) => {
  const barSize = getBarSize(duration);
  const brushXMax =
    width -
    BRUSH_PADDING.left -
    BRUSH_PADDING.right -
    RECTANGLE_PADDING.left -
    RECTANGLE_PADDING.right;

  const brushTimeScale = useMemo(
    () =>
      scaleLinear({
        range: [0, brushXMax],
        domain: extent(ccvData, ccvGetTime),
      }),
    [brushXMax, ccvData]
  );

  const brushCCVScale = useMemo(
    () =>
      scaleLinear({
        range: [brushYMax, 0],
        domain: [0, max(ccvData, ccvGetCCV)],
      }),
    [ccvData]
  );

  const initialBrushPosition = {
    start: { x: 0 },
    end: {
      x: 0,
    },
  };

  const brushTimeSelectionScale = scaleLinear({
    domain: [0, Math.ceil(duration / barSize) - 1],
    range: [0, brushXMax],
  });

  return (
    <svg
      height={BRUSH_HEIGHT + RECTANGLE_PADDING.top + RECTANGLE_PADDING.bottom}
      width={width}
    >
      <PatternLines
        id="brush-pattern"
        height={8}
        width={8}
        stroke={'#FFCC00'}
        strokeWidth={1}
        orientation={['diagonal']}
      />
      <LinearGradient from="#0048f2" to="#0048f2" id="brush-bg-gradient" />
      <LinearGradient
        from="#fff"
        to="#fff"
        toOpacity={0.8}
        id="brush-gradient-area"
      />
      <Group left={RECTANGLE_PADDING.left} top={RECTANGLE_PADDING.top}>
        <AxisTop
          scale={brushTimeSelectionScale}
          top={0}
          left={1}
          tickFormat={(t: number) =>
            moment
              .unix(t * barSize)
              .utc()
              .format('HH:mm:ss')
          }
          tickStroke="#0048f2"
          tickLength={4}
          hideAxisLine
        />
        <BarRounded
          x={0}
          y={0}
          width={width - RECTANGLE_PADDING.left - RECTANGLE_PADDING.right + 2}
          height={BRUSH_HEIGHT}
          bottom
          radius={8}
          fill="url(#brush-bg-gradient)"
        />
        <Group top={BRUSH_PADDING.top} left={BRUSH_PADDING.left + 2}>
          <AreaClosed
            curve={curveBasis}
            data={ccvData}
            yScale={brushCCVScale}
            x={(d) => brushTimeScale(ccvGetTime(d))}
            y={(d) => brushCCVScale(ccvGetCCV(d))}
            fill="url(#brush-gradient-area)"
          />
        </Group>
        <VisxBrush
          xScale={brushTimeSelectionScale}
          yScale={brushCCVScale}
          height={BRUSH_HEIGHT}
          handleSize={8}
          width={brushXMax}
          resizeTriggerAreas={['left', 'right']}
          brushDirection="horizontal"
          initialBrushPosition={initialBrushPosition}
          onChange={onBrushChange}
          onClick={onBrushClick}
          selectedBoxStyle={{
            fill: 'url(#brush-pattern)',
            stroke: '#FFCC00',
            strokeWidth: 2,
          }}
          margin={RECTANGLE_PADDING}
        />
      </Group>
    </svg>
  );
};

export default Brush;
