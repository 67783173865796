import { compose, withHandlers } from 'recompose';

import { getTwitterToken } from '../../../../ajax';
import { UserIsNotAuthenticated } from '../../../../common/auth';
import { PATH_SIGNIN_TWITTER_CALLBACK } from '../../../../common/constants/paths';
import {
  FACEBOOK_SCOPE,
  redirectTiktok,
  redirectTwitch,
  redirectTwitter,
  redirectYoutube,
} from '../../../../helpers/influencer-login';
import { actions } from '../../../../store/actions';

export default compose(
  UserIsNotAuthenticated,
  withHandlers({
    redirectTwitch,
    redirectYoutube,
    redirectTiktok,
    loginTwitter: () => async () => {
      getTwitterToken(PATH_SIGNIN_TWITTER_CALLBACK).then((response) => {
        const { oauth_token } = response;

        redirectTwitter(oauth_token);
      });
    },
    loginFacebook:
      ({ dispatch }) =>
      async () => {
        window.FB.login(
          (response) => {
            if (response.status !== 'connected') return;

            dispatch(actions.signinFacebook(response.authResponse));
          },
          {
            scope: FACEBOOK_SCOPE,
          }
        );
      },
  })
);
