import { call, put, takeLatest } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import { BRAND_DELETE_CLICKED } from '../action-types';
import { brandsMounted } from '../events';
import {
  myBrandDeleteFailed,
  myBrandDeleteRequested,
  myBrandDeleteSucceeded,
} from '../models/my-brands/my-brands.actions';

function* deleteMyBrand(action) {
  const id = action.payload;

  yield put(myBrandDeleteRequested({ id }));

  try {
    yield call(ajax.deleteMyBrands, id);

    yield put(myBrandDeleteSucceeded({ id }));
    yield put(brandsMounted());
  } catch (e) {
    console.error('Brand Delete Error - ', e);
    yield put(
      myBrandDeleteFailed({
        error: (e.bodyJson && e.bodyJson.errors) || 'Brand Delete Error',
        id,
      })
    );
  }
}

export default function* root() {
  yield takeLatest(BRAND_DELETE_CLICKED, deleteMyBrand);
}
