import momentTz from 'moment-timezone';

export const computeSocialPostsDeliverables = ({
  instagram_keyword_mention_deliverables,
  twitter_keyword_mention_deliverables,
  tiktok_keyword_mention_deliverables,
  facebook_keyword_mention_deliverables,
  time_zone,
}) => {
  const rows = [];

  rows.push([
    'Keyword',
    'Creator',
    'Platform',
    'Post',
    'Time Posted',
    'Retweets / Shares',
    'Replies',
    'Likes',
    'Comments',
    'Impressions',
    'Media Value',
  ]);

  twitter_keyword_mention_deliverables.forEach((deliverable) => {
    deliverable.tweets.forEach((tweet) => {
      rows.push([
        deliverable.keyword,
        tweet.username,
        'Twitter',
        tweet.permalink,
        momentTz
          .tz(tweet.published_at, time_zone)
          .format('MM/DD/YYYY HH:mm:ss'),
        tweet.retweets,
        tweet.replies || 0,
        tweet.favorites,
        'N/A',
        tweet.impression_count || tweet.est_impression_count,
        tweet.emv,
      ]);
    });
  });

  instagram_keyword_mention_deliverables.forEach((deliverable) => {
    deliverable.posts.forEach((post) => {
      rows.push([
        deliverable.keyword,
        post.owner_username,
        'Instagram',
        `https://instagram.com/p/${post.shortcode}`,
        momentTz.tz(post.published_at, time_zone).format('MM/DD/YYYY HH:mm:ss'),
        'N/A',
        'N/A',
        post.likes,
        post.comment_count,
        post.impression_count || post.est_impression_count,
        post.emv,
      ]);
    });
  });

  tiktok_keyword_mention_deliverables.forEach((deliverable) => {
    deliverable.posts.forEach((post) => {
      rows.push([
        deliverable.keyword,
        post.owner_username,
        'TikTok',
        post.permalink,
        momentTz.tz(post.published_at, time_zone).format('MM/DD/YYYY HH:mm:ss'),
        post.shares_count,
        'N/A',
        post.likes_count,
        post.comments_count,
        post.impression_count ?? post.est_impression_count,
        post.emv,
      ]);
    });
  });

  facebook_keyword_mention_deliverables.forEach((deliverable) => {
    deliverable.posts.forEach((post) => {
      rows.push([
        deliverable.keyword,
        post.owner_username,
        'Facebook',
        post.permalink,
        momentTz.tz(post.published_at, time_zone).format('MM/DD/YYYY HH:mm:ss'),
        post.shares_count,
        'N/A',
        post.likes_count,
        post.comments_count,
        post.impression_count ?? post.est_impression_count,
        post.emv,
      ]);
    });
  });

  return rows;
};

export default computeSocialPostsDeliverables;
