export const getInfluencerIdAndNetwork = ({ account }) => {
  const { data } = account;
  let influencerId,
    influencerNetwork = null;

  // We take the first network for now. Maybe later we can add a
  // toggle between multiple networks, if needed
  if (data && data.networks && data.networks.length) {
    const twitchNetwork = data.networks.find(
      ({ network }) => network === 'twitch'
    );

    const youtubeNetwork = data.networks.find(
      ({ network }) => network === 'youtube'
    );

    if (twitchNetwork) {
      // Twitch has priority
      influencerId = twitchNetwork.name;
      influencerNetwork = twitchNetwork.network;
    } else if (youtubeNetwork) {
      influencerId = youtubeNetwork.name;
      influencerNetwork = twitchNetwork.network;
    }
  }

  return {
    influencerId,
    influencerNetwork,
  };
};
