import {
  Button,
  Card,
  CardContent,
  Chip,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import React, { useState } from 'react';

import FormikMuiTextField from '../../../components/FormikMuiTextField';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: 340,
  },
}));

export const ChatMentions = ({ brandId }) => {
  const classes = useStyles();
  const [duplicate, isDuplicate] = useState(false);
  const [newChatMentionKeyword, setNewChatMentionKeyword] = useState('');

  const { values } = useFormikContext();
  const onAdd = (arrayHelpers) => {
    const content = newChatMentionKeyword.split(',');
    content.forEach((el, index) => {
      content[index] = el.trim().toLowerCase();
    });
    new Set(content).forEach((newContent) => {
      if (!newContent) return;
      if (
        !values.chatMentionKeywords.some((el) => el.keyword === newContent) ||
        !values.chatMentionKeywords
          .filter((el) => el.campaign_brand_id === brandId)
          .some((el) => el.keyword === newContent)
      ) {
        isDuplicate(false);
        arrayHelpers.push({
          keyword: newContent,
          campaign_brand_id: brandId,
        });
        setNewChatMentionKeyword('');
      } else {
        setNewChatMentionKeyword(newContent);
        isDuplicate(true);
      }
    });
  };
  return (
    <Card className={classes.container}>
      <CardContent>
        <Typography variant="h2">Comments / chat mentions</Typography>
      </CardContent>
      <FieldArray
        name="chatMentionKeywords"
        render={(arrayHelpers) => (
          <>
            <CardContent>
              <div className={classes.inputContainer}>
                <FormikMuiTextField
                  fullWidth
                  placeholder="Add keyword"
                  variant="outlined"
                  onChange={(e) => {
                    setNewChatMentionKeyword(e.target.value);
                    isDuplicate(false);
                  }}
                  value={newChatMentionKeyword}
                  name="newChatMentionKeyword"
                  className={classes.input}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        onAdd(arrayHelpers);
                      }
                    },
                  }}
                />
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  style={{ padding: 9.5 }}
                  disabled={!newChatMentionKeyword.trim()}
                  onClick={() => onAdd(arrayHelpers)}
                >
                  Add
                </Button>
              </div>
              {duplicate && (
                <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                  "{newChatMentionKeyword}" is already added
                </p>
              )}
            </CardContent>
            <CardContent component="ul" className={classes.chipsContainer}>
              {brandId ? (
                <>
                  {values.chatMentionKeywords.map((item, index) => (
                    <>
                      {item.campaign_brand_id === brandId && (
                        <li key={index}>
                          <Chip
                            label={item.keyword}
                            variant="outlined"
                            className={classes.chip}
                            onDelete={() => {
                              arrayHelpers.remove(index);
                              item.keyword === newChatMentionKeyword &&
                                isDuplicate(false);
                            }}
                          />
                        </li>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {values.chatMentionKeywords.map((item, index) => (
                    <>
                      {!item.campaign_brand_id && (
                        <li key={index}>
                          <Chip
                            label={item.keyword}
                            variant="outlined"
                            className={classes.chip}
                            onDelete={() => {
                              arrayHelpers.remove(index);
                              item.keyword === newChatMentionKeyword &&
                                isDuplicate(false);
                            }}
                          />
                        </li>
                      )}
                    </>
                  ))}
                </>
              )}
            </CardContent>
          </>
        )}
      />
    </Card>
  );
};

export default ChatMentions;
