import { makeStyles, Theme, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';

import facebookIcon from '../../assets-2.0/images-influencer/facebook.svg';
import instagramIcon from '../../assets-2.0/instagram.svg';
import tiktokIcon from '../../assets-2.0/tiktok.svg';
import twitterIcon from '../../assets-2.0/twitter.svg';
import { SocialMediaPlatform } from '../../types';
import { ComponentSize } from '../../utils/ComponentSize';
import getMediaUrl from '../../utils/getMediaUrl';
import { SocialPostAttachment } from '../../views/influencerViews/Stats/SocialMedia/store/types';

const useMentionTextStyles = makeStyles<
  Theme,
  Pick<Props, 'size' | 'rowHeight'> & { hasThumbnail: boolean }
>((theme) => ({
  container: ({ rowHeight }) => ({
    height: rowHeight,
    paddingTop: 8,
    display: 'flex',
    flexDirection: 'column',
    '&:hover a': {
      opacity: 1,
    },
  }),

  grid: ({ hasThumbnail }) => {
    return {
      paddingTop: 8,
      display: 'grid',
      columnGap: 8,
      gridTemplateColumns: hasThumbnail ? '25% 75%' : '100%',
    };
  },

  text: ({ size }) => {
    return {
      maxWidth: size === 'sm' ? '250px' : '450px',
      fontSize: '1rem',
      lineHeight: '1.33rem',
      '--lines': 4,
      margin: 0,
    };
  },

  link: {
    color: `${theme.palette.primary.main} !important`,
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
  },

  postImage: ({ rowHeight }) => ({
    maxWidth: '90%',
    maxHeight: rowHeight * 0.7,
    width: 'auto',
    display: 'block',
  }),

  platformDateContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  platformImage: ({ size }) => {
    return {
      height: size === 'sm' ? 15 : 20,
      marginRight: 8,
    };
  },

  dateText: ({ size }) => {
    return {
      fontSize: size === 'sm' ? '0.625rem' : '0.8rem',
      flex: 1,
    };
  },

  ctaText: ({ size }) => ({
    fontSize: size === 'sm' ? '0.75rem' : '0.875rem',
  }),
}));

interface Props {
  text: string;
  link: string;
  platform: SocialMediaPlatform;
  attachments?: SocialPostAttachment[];
  date: string;
  rowHeight?: number;
  size?: Extract<ComponentSize, 'sm'>;
  thumbnailUrl?: string;
}

const PlatformIconMap = {
  twitter: twitterIcon,
  instagram: instagramIcon,
  facebook: facebookIcon,
  tiktok: tiktokIcon,
};

export const PostCell = ({
  text,
  link,
  platform,
  date,
  size,
  attachments,
  rowHeight = 148,
  thumbnailUrl,
}: Props) => {
  const photoAttachment = attachments?.find(
    (item) => item.attachment_type === 'photo'
  ); // Only render the first PHOTO attachment for now

  const thumbnailURL = photoAttachment?.url || thumbnailUrl;
  const description = photoAttachment?.description || `${platform} post`;

  const classes = useMentionTextStyles({
    size,
    hasThumbnail: !!thumbnailURL,
    rowHeight,
  });

  const renderThumbnail = (thumbnailURL: string, description?: string) => {
    return (
      <img
        alt={description || 'social post'}
        src={getMediaUrl(thumbnailURL)}
        className={classes.postImage}
      />
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.platformDateContainer}>
        <img
          src={PlatformIconMap[platform]}
          alt={`${platform} logo`}
          className={classes.platformImage}
        />
        <Typography color="textSecondary" className={classes.dateText}>
          {date}
        </Typography>
      </div>
      <div className={classes.grid}>
        {thumbnailURL && (
          <div>{renderThumbnail(thumbnailURL, description)}</div>
        )}
        <div>
          <p
            dangerouslySetInnerHTML={{ __html: text }}
            className={`text-ellipsis ${classes.text}`}
          />
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              <OpenInNewIcon fontSize="inherit" style={{ marginRight: 4 }} />
              <span className={classes.ctaText}>
                VIEW {platform === 'twitter' ? 'TWEET' : 'POST'}
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
