import _filter from 'lodash/filter';
// import _meanBy from 'lodash/meanBy';
// import _maxBy from 'lodash/maxBy';
import _sumBy from 'lodash/sumBy';
import momentTz from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';

import InfluencerAvatar from '../../components/InfluencerAvatar';
import Table, { StatBox } from '../../Table';
import { findInfluencerByVideo, findVideoById } from '../../utils';

export const computeSummary = ({ usedComments, usedVideos }) => {
  const mentions = usedComments.length;

  const mentions_YT = [];

  _filter(usedVideos, function (usedVideo) {
    return usedVideo.resource === 'youtube';
  }).forEach((usedVideoYT) => {
    usedComments.forEach((usedComment) => {
      usedComment.video_id === usedVideoYT.id && mentions_YT.push(usedComment);
    });
  });

  const totalComments = _sumBy(usedVideos, 'comments_count');

  const perTotalComments =
    totalComments === 0
      ? 0
      : isNaN(mentions / totalComments)
      ? 0
      : (mentions / totalComments) * 100;

  const streams = new Set(usedComments.map((comment) => comment.video_id)).size;

  // const totalImpressions = _sumBy(usedComments, 'impressions') || 0;
  // const totalEMV = _sumBy(usedComments, 'emv') || 0;

  return {
    mentions,
    streams,
    perTotalComments,
    mentions_YT: mentions_YT.length,
    // totalImpressions, totalEMV
  };
};

const getRows = ({ usedInfluencers, usedVideos, usedComments }) =>
  usedInfluencers.map((influencer) => {
    const videos = usedVideos.filter(
      (v) => findInfluencerByVideo(v, usedInfluencers).id === influencer.id
    );

    const comments = usedComments.filter(
      (shoutout) =>
        findInfluencerByVideo(
          findVideoById(shoutout.video_id, usedVideos),
          usedInfluencers
        ).id === influencer.id
    );

    const {
      mentions,
      streams,
      perTotalComments,

      // totalImpressions, totalEMV
    } = computeSummary({ usedComments: comments, usedVideos: videos });

    return {
      influencer,
      creatorOrDate: influencer.name,
      mentions,
      streams,
      perTotalComments,
      // totalImpressions, totalEMV
      key: influencer.id,
    };
  });
const getRowsDay = ({
  usedVideos,
  usedComments,
  timeArray,
  isSponsorship,
  timeZone,
}) =>
  isSponsorship &&
  timeArray.map((date) => {
    const comments = usedComments.filter((comment) => {
      const video = findVideoById(comment.video_id, usedVideos);
      const timeObject = momentTz.tz(video.published_at, timeZone);
      return timeObject.format('Do MMM YYYY') === date.time;
    });
    const { mentions, streams, perTotalComments } = computeSummary({
      usedComments: comments,
      usedVideos,
    });

    return {
      date: date.time,
      creatorOrDate: date.unixTime,
      mentions,
      streams,
      perTotalComments,
      // totalImpressions, totalEMV
    };
  });

const COLS = (isFilteredByCreator, isSponsorship) => {
  return [
    {
      label: isSponsorship
        ? isFilteredByCreator
          ? 'Creator'
          : 'Day'
        : 'Creator',
      key: 'creatorOrDate',
      render: (row) => (
        <InfluencerAvatar
          influencer={
            isSponsorship
              ? isFilteredByCreator
                ? row.influencer
                : row.date
              : row.influencer
          }
        />
      ),
      stretch: true,
    },
    {
      label: 'Videos',
      key: 'streams',
      render: (data) => <StatBox stat={data.streams} />,
    },
    {
      label: 'Mentions',
      key: 'mentions',
      render: (row) => <StatBox stat={row.mentions} />,
    },
    {
      label: '% of Total Cmnts.',
      key: 'perTotalComments',
      render: (row) => <StatBox stat={row.perTotalComments} suffix="%" />,
    },
    /* { */
    /*   label: 'Impressions', */
    /*   key: 'totalImpressions', */
    /*   render: (row) => <StatBox stat={row.totalImpressions} />, */
    /* }, */
    /* { */
    /*   label: 'Media Value', */
    /*   key: 'totalEMV', */
    /*   render: (data) => ( */
    /*     <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" /> */
    /*   ), */
    /* }, */
  ];
};

export const SummaryTable = ({
  usedInfluencers,
  usedComments,
  usedVideos,
  setSelectedCampaignInfluencer,
  isFilteredByCreator,
  isSponsorship,
  timeZone,
  content,
}) => {
  const timeArray =
    isSponsorship &&
    usedComments
      .map((elem) => {
        const video = findVideoById(elem.video_id, usedVideos);
        const timeObject = momentTz.tz(video.published_at, timeZone);
        return {
          time: timeObject.format('Do MMM YYYY'),
          unixTime: timeObject.unix(),
        };
      })
      .reduce(
        (acc, x) => acc.concat(acc.find((y) => y.time === x.time) ? [] : [x]),
        []
      );
  const rows = useMemo(
    () => getRows({ usedInfluencers, usedVideos, usedComments }),
    [usedVideos, usedComments, usedInfluencers]
  );
  const rowsDay = useMemo(
    () =>
      getRowsDay({
        usedVideos,
        usedComments,
        timeArray,
        isFilteredByCreator,
        isSponsorship,
        timeZone,
      }),
    [usedVideos, usedComments, timeArray, isFilteredByCreator]
  );

  const onRowClick = useCallback(
    ({ key }) => setSelectedCampaignInfluencer(key),
    [setSelectedCampaignInfluencer]
  );
  const idTable = `SV-chatMentions-summary-${content.replace(/\s+/g, '')}`;

  return (
    <Table
      cols={COLS(isFilteredByCreator, isSponsorship)}
      rows={isSponsorship ? (isFilteredByCreator ? rows : rowsDay) : rows}
      defaultSortCol="mentions"
      onRowClick={onRowClick}
      idTable={idTable}
    />
  );
};

export default SummaryTable;
