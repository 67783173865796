/*
 * TODO: parametrize once authorization & organizations are in place
 */
export const selectMyBrands = ({ brands: { byId } }) => Object.values(byId);

// export const selectCampaignById = (campaignId) => ({ campaigns: { byId } }) =>
//   byId[campaignId];

export const getTrialAccountBrandsExceeded = ({
  account: { firebaseData },
  brands: byId,
}) =>
  firebaseData && !!firebaseData.trial_account && Object.keys(byId).length >= 1;
