import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimerIcon from '@material-ui/icons/Timer';
import VideocamIcon from '@material-ui/icons/Videocam';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useMemo, useState } from 'react';

import twitchIcon from '../../../../assets-2.0/twitch.svg';
import DetailedViewToggle, {
  useDetailedViewToggle,
} from '../../components/DetailedViewToggle';
import EyeIcon from '../../components/EyeIcon';
import OverviewCard from '../../components/OverviewCard';
import PointerIcon from '../../components/PointerIcon';
import SectionTitle from '../../components/SectionTitle';
import TimeSegmentModal from '../../components/TimeSegmentModal';
import DetailedTable from './DetailedTable';
import SummaryTable, { computeSummary } from './SummaryTable';

const computeOverview = ({ usedVideos }) => {
  const creators = new Set(usedVideos.flatMap((v) => v.campaign_influencer_ids))
    .size;

  const {
    streams,
    hoursStreamed,
    hoursWatched,
    comments,
    engagementRate,
    followers,
    totalViews,
    avgCCV,
    peakCCV,
    totalEMV,
    comments_YT,
  } = computeSummary({
    usedVideos,
  });

  return {
    creators,
    streams,
    followers,
    hoursStreamed,
    hoursWatched,
    comments,
    engagementRate,
    totalViews,
    avgCCV,
    peakCCV,
    totalEMV,
    comments_YT,
  };
};

const Overview = React.memo(({ usedInfluencers, usedVideos, hasTwitch }) => {
  const {
    creators,
    streams,
    hoursStreamed,
    hoursWatched,
    comments,
    engagementRate,
    totalViews,
    avgCCV,
    peakCCV,
    totalEMV,
    followers,
    comments_YT,
  } = computeOverview({
    usedVideos,
  });

  return (
    <div>
      <SectionTitle>Video performance</SectionTitle>
      <OverviewCard.Container>
        <OverviewCard
          label="Creators"
          color="teal"
          main={{ stat: creators }}
          icon={<PersonIcon />}
          outOf={usedInfluencers.length}
          id={`performanceTab-streaming-creators`}
        />
        <OverviewCard
          label="Videos"
          color="orange"
          main={{ stat: streams }}
          icon={<VideocamIcon />}
          id={`performanceTab-streaming-streams`}
        />
        <OverviewCard
          label="Hours watched"
          color="blue"
          main={{ stat: hoursWatched }}
          icon={<ScheduleIcon />}
          id={`performanceTab-streaming-hrswtch`}
        />
        <OverviewCard
          label="Duration hrs."
          color="red"
          main={{ stat: hoursStreamed }}
          icon={<TimerIcon />}
          id={`performanceTab-streaming-hrsstrmd`}
        />
        {hasTwitch && (
          <OverviewCard
            label="Followers Gained"
            color="grey"
            main={{ stat: followers }}
            icon={<PeopleIcon />}
            secondaryComponent={
              <img alt="twitch-icon" style={{ width: 20 }} src={twitchIcon} />
            }
          />
        )}

        <OverviewCard
          label="Comments"
          color="black"
          main={{ stat: comments }}
          secondary={{
            label: 'video comm.',
            stat: comments_YT,
            info: 'The number of comments from YouTube video comments section.',
          }}
          icon={<ChatBubbleIcon />}
          info="The total number of comments posted by the channel's viewers on the live chat and video comments section."
          id={`performanceTab-streaming-comments`}
        />
        <OverviewCard
          label="Engagement rate"
          color="purple"
          main={{ stat: engagementRate, suffix: '%' }}
          icon={<PointerIcon />}
          info="The engagement rate measures the number of interactions (comments, likes, dislikes) relative to the number of views."
          id={`performanceTab-streaming-engagement-rate`}
        />
        <OverviewCard
          label="Average CCV"
          color="purple"
          main={{ stat: avgCCV }}
          secondary={{
            label: 'peak',
            stat: peakCCV,
            info: 'The peak number of concurrent viewers who watched the stream live. To calculate this number we are checking the number of viewers every minute during the stream.',
          }}
          icon={<EyeIcon />}
          info="The average number of concurrent viewers who watched the stream live. To calculate this number we are checking the number of viewers every minute during the stream."
          id={`performanceTab-streaming-averageCCV`}
        />
        <OverviewCard
          label="Total views"
          color="purple"
          main={{ stat: totalViews }}
          icon={<VisibilityIcon />}
          info="The sum of live views and offline VOD views."
          id={`performanceTab-streaming-ttlvws`}
        />
        <OverviewCard
          label="Media value"
          color="green"
          main={{ stat: totalEMV, prefix: '$', format: '0,[0].[0]a' }}
          icon={<LocalAtmIcon />}
          info="We are valuing the price of impressions against a standardized CPM. We don't add any multipliers to EMV values based on perceived emotional values of engagements or actions as these can lead to inflated values."
          id={`performanceTab-streaming-mediavalue`}
        />
      </OverviewCard.Container>
    </div>
  );
});

export const Streams = ({
  usedVideos,
  usedInfluencers,
  campaign,
  setSelectedCampaignInfluencer,
  platformFilter,
  setCampaignVideoDeleteId,
}) => {
  const [timeSegmentVideoId, setTimeSegmentVideoId] = useState(null);

  const { detailed, setDetailed } = useDetailedViewToggle(usedInfluencers);

  const hasTwitch = useMemo(
    () =>
      platformFilter !== 'youtube' &&
      usedVideos.some((v) => v.resource === 'twitch'),
    [usedVideos]
  );

  return (
    <div style={{ paddingTop: 64, paddingBottom: 100 }}>
      <TimeSegmentModal
        videoId={timeSegmentVideoId}
        usedVideos={usedVideos}
        usedInfluencers={usedInfluencers}
        campaign={campaign}
        onClose={() => setTimeSegmentVideoId(null)}
      />
      <Overview
        usedVideos={usedVideos}
        usedInfluencers={usedInfluencers}
        hasTwitch={hasTwitch}
      />
      {usedVideos.length > 0 && (
        <>
          <DetailedViewToggle {...{ detailed, setDetailed, usedInfluencers }} />
          {!detailed && (
            <SummaryTable
              usedInfluencers={usedInfluencers}
              usedVideos={usedVideos}
              hasTwitch={hasTwitch}
              setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
            />
          )}
          {detailed && (
            <DetailedTable
              setCampaignVideoDeleteId={setCampaignVideoDeleteId}
              usedInfluencers={usedInfluencers}
              usedVideos={usedVideos}
              hasTwitch={hasTwitch}
              timeZone={campaign.time_zone}
              campaignId={campaign.id}
              setTimeSegmentVideoId={setTimeSegmentVideoId}
              setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Streams;
