import { makeStyles, Tooltip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Form } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { default as MegaphoneSvg } from '../../assets-2.0/megaphone';
import LoadingButton from '../../components/LoadingButton';
import useRouter from '../../hooks/use-router';
import { campaignMounted } from '../../store/events';
import { selectCommunication } from '../../store/models/communication/communication.selectors';
import { searchIdenticalUsers } from '../../utils/searchIdenticalUsers';
import validateDatesArray from '../../utils/validateDatesArray';
import CampaignProgress from '../Campaign/CampaignProgress';
import BasicTab from './BasicTab';
import ChannelsTab from './ChannelsTab';
import MediaTrackingTab from './MediaTrackingTab';
import SponsorshipValuationTab from './SponsorshipValuationTab';

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    padding: 16,
    flex: 1,
    overflow: 'scroll',
  },
  contentContainer: {
    maxWidth: 960,
    margin: 'auto',
  },
  tabs: {
    color: '#333',
    borderTop: '1px solid #e4e4e4',
    borderBottom: '1px solid #e4e4e4',
  },
  button: {
    padding: theme.spacing(2),
    background: 'none',
    fontSize: 16,
    color: 'black',
    textTransform: 'uppercase',
    border: 'none',
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    width: 250,
  },
  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

const getTabs = (trialAccount, campaignTypes) =>
  [
    {
      key: 'basic',
      title: 'Basic',
    },
    {
      key: 'channels',
      title: 'Channels',
    },
    (campaignTypes.some((el) => el.campaign_type === 'media_tracking') ||
      !campaignTypes.length) &&
    !trialAccount
      ? {
          key: 'media-tracking',
          title: 'Media Tracking',
        }
      : null,
    campaignTypes.some((el) => el.campaign_type === 'sponsorship_valuation')
      ? {
          key: 'sv',
          title: 'Sponsorship Valuation',
        }
      : null,
  ].filter((t) => t !== null);

function CampaignEdit(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('basic');
  const [multiple, isMultiple] = useState(false);

  const { campaign, trialAccount, organizationRestrictions, ...formik } = props;
  const {
    match: {
      params: { campaignId },
    },
  } = useRouter();

  const { values, setFieldValue } = props;

  const arrayErrors = useMemo(
    () => searchIdenticalUsers(values.influencersSocials),
    [values.influencersSocials]
  );

  const { succeeded: campaignSucceeded, loading: campaignLoading } =
    useSelector(selectCommunication(`campaign-${campaignId}`));

  useEffect(() => {
    if (values.campaignDays && values.campaignDays.length) {
      moment(values.campaignDays[values.campaignDays.length - 1]).diff(
        moment(values.campaignDays[0]),
        'days'
      ) +
        1 ===
      values.campaignDays.length
        ? isMultiple(false)
        : isMultiple(true);
    }
  }, [values.campaignDays]);
  useEffect(
    function () {
      if (campaignLoading) {
        return;
      }

      if (!campaignSucceeded || !campaign.structure_loaded) {
        dispatch(campaignMounted({ campaignId: campaignId }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaignSucceeded, campaignLoading]
  );

  const campaignIsOver =
    campaign &&
    campaign.campaign_days &&
    moment(campaign?.campaign_days[campaign.campaign_days.length - 1]) <
      moment(new Date().toDateString());

  if (campaign) {
    document.title = campaign.name;
  } else {
    document.title = 'Campaign';
  }

  const { loading } = useSelector(selectCommunication('campaign-update'));
  if (!values.campaignLoaded) {
    return (
      <Box width="100%" position="absolute" top={0} left={0} zIndex={1}>
        <LinearProgress variant="query" />
      </Box>
    );
  }

  const canSave = () => {
    const {
      campaignName,
      campaignDays,
      campaignDaysFrame,
      isScheduleDateType,
      influencersSocials,
    } = values;

    if (loading) {
      return false;
    }

    if (!influencersSocials.length) {
      return false;
    }

    if (campaignName.trim().length === 0) {
      return false;
    }

    if (campaignIsOver) {
      return false;
    }

    if (isScheduleDateType) {
      return (
        validateDatesArray(campaignDays) &&
        campaignDays[campaignDays.length - 1] >= campaignDays[0]
      );
    } else {
      return (
        validateDatesArray(campaignDaysFrame) &&
        campaignDaysFrame[campaignDaysFrame.length - 1] >= campaignDaysFrame[0]
      );
    }
  };

  const tabProps = {
    campaign,
    setFieldValue,
    values,
    formik,
    organizationRestrictions,
    campaignIsOver,
  };
  return (
    <div className={classes.wrapper}>
      <CampaignProgress campaign={campaign} />
      <Grid container wrap="nowrap" direction="column">
        <Grid item style={{ width: '100%' }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ padding: 16 }}
          >
            <Grid item>
              <Avatar style={{ background: '#e0d3ed' }}>
                <MegaphoneSvg />
              </Avatar>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <Typography variant="h2">{campaign && campaign.name}</Typography>
              <Typography color="textSecondary" variant="h4">
                {campaign && moment(values.campaignDays[0]).format('DD MMM - ')}
                {campaign &&
                  moment(
                    values.campaignDays[values.campaignDays.length - 1]
                  ).format('DD MMM, YYYY')}
                {values.isScheduleDateType &&
                  multiple &&
                  ` (${values.campaignDays.length} days)`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.tabs}>
        <Grid item>
          {getTabs(trialAccount, values.campaignTypes).map(({ key, title }) => {
            return (
              <button
                key={key}
                onClick={() => setActiveTab(key)}
                className={clsx(classes.button, {
                  [classes.active]: activeTab === key,
                })}
              >
                {title}
              </button>
            );
          })}
        </Grid>
      </Grid>
      <div className={classes.scrollContainer}>
        <div className={classes.contentContainer}>
          <Form>
            {activeTab === 'basic' && <BasicTab {...tabProps} />}
            {activeTab === 'channels' && (
              <ChannelsTab {...tabProps} arrayErrors={arrayErrors} />
            )}
            {activeTab === 'sv' && <SponsorshipValuationTab {...tabProps} />}
            {activeTab === 'media-tracking' && (
              <MediaTrackingTab {...tabProps} />
            )}
            <Grid
              container
              justify="space-between"
              spacing={2}
              style={{ marginTop: 16 }}
            >
              <Grid item>
                <Link to={`/campaigns/${campaignId}`}>
                  <Button color="primary">Cancel</Button>
                </Link>
              </Grid>
              {campaignIsOver ? (
                <Tooltip
                  style={{ margin: 8, padding: 0 }}
                  title={'You cannot change the details of an ended campaign.'}
                >
                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={true}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Tooltip>
              ) : (
                <Grid item>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={loading}
                    disabled={!canSave()}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default CampaignEdit;
