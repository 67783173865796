import React from 'react';

function FileInput(props) {
  const { setWrongFileType, onChange, ...rest } = props;
  function handleChange({ target: { files } }) {
    if (!/(jpg|jpeg|png|bmp)/.test(files[0].type)) {
      setWrongFileType(true);
    } else {
      if (!props.multiple) {
        const [file] = files;

        props.onChange(file);
      } else {
        props.onChange(files);
      }
      setWrongFileType(false);
    }
  }

  return <input type="file" onChange={handleChange} {...rest} />;
}

export default FileInput;
