import between from '../utils/between';

const influencerTypeBySubscriberCount = (amountOfSubscribers) => {
  if (between(0, 9999)(amountOfSubscribers)) return 'NANO INFLUENCER';

  if (between(10000, 99999)(amountOfSubscribers)) return 'MICRO INFLUENCER';

  if (between(100000, 999999)(amountOfSubscribers)) return 'MACRO INFLUENCER';

  return 'MEGA INFLUENCER';
};

export default influencerTypeBySubscriberCount;
