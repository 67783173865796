const handleRequested = (
  state,
  {
    meta: {
      request: { name },
    },
  }
) => ({
  ...state,
  [name]: {
    loading: true,
    error: undefined,
  },
});

const handleRequestFailed = (
  state,
  {
    payload,
    meta: {
      request: { name },
    },
  }
) => ({
  ...state,
  [name]: {
    loading: false,
    error: payload.error,
  },
});

const handleRequestSucceeded = (
  state,
  {
    meta: {
      request: { name },
    },
  }
) => ({
  ...state,
  [name]: {
    loading: false,
    succeeded: true,
    error: undefined,
  },
});

const handleRequestReset = (
  state,
  {
    meta: {
      request: { name },
    },
  }
) => {
  const { [name]: value, ...withoutProp } = state;

  return {
    ...withoutProp,
  };
};

export default (state = {}, action) => {
  const { meta: { request } = {} } = action;

  if (!request) return state;

  const { type } = request;

  switch (type) {
    case 'requested': {
      return handleRequested(state, action);
    }

    case 'failed': {
      return handleRequestFailed(state, action);
    }

    case 'succeeded': {
      return handleRequestSucceeded(state, action);
    }

    case 'reset': {
      return handleRequestReset(state, action);
    }

    default:
      return state;
  }
};
