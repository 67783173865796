import React from 'react';

import bgImage from '../../../../assets/images/big-blue-bgr-logo.png';
import { useLogRocket } from '../../../../utils/useLogRocket';

export const SigninCallbackPage = () => {
  useLogRocket(true);

  return (
    <div className="peers ai-s fxw-nw h-100vh">
      <div
        className="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <div id="loader"></div>
    </div>
  );
};
