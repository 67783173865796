import { Icon } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import React from 'react';
import ReactSelect, {
  components,
  OptionProps,
  ValueContainerProps,
} from 'react-select';

function GrayArrowDropDownOutlinedIcon(props) {
  return (
    <Icon
      style={{ cursor: 'pointer' }}
      component={ArrowDropDownOutlinedIcon}
      color="action"
    ></Icon>
  );
}

const getBackgroundColor = (props) => {
  if (props.isSelected) {
    return '#DBDBDB';
  }

  if (props.isFocused) {
    return '#EBEBEB';
  }

  return 'white';
};

const CustomAvatar = ({ src, alt, style = {} }) => {
  const fallbackImage = '../../fallback/img.png';

  return (
    <Avatar
      style={{ ...style, width: 30, height: 30 }}
      src={src || fallbackImage}
      alt={alt}
    />
  );
};

const Option = (props: OptionProps) => {
  return (
    <div
      style={{
        padding: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: getBackgroundColor(props),
      }}
    >
      {props.data.value !== 'all' && (
        <CustomAvatar
          style={{ marginRight: 8 }}
          src={props.data.avatar}
          alt={props.data.label?.toUpperCase()}
        />
      )}
      <components.Option {...props} />
    </div>
  );
};

const ValueContainer = ({ children, ...props }: ValueContainerProps) => {
  const data = props?.getValue()[0];
  const showAvatar =
    (data?.value !== 'all' && !props.selectProps?.menuIsOpen) ||
    (data?.value !== 'all' && !props.selectProps?.isSearchable);

  return (
    <>
      {showAvatar && (
        <CustomAvatar src={data?.avatar} alt={data?.label?.toUpperCase()} />
      )}
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </>
  );
};

export const CampaignInfluencersFilter = ({
  value,
  onChange,
  campaignInfluencers,
}) => {
  const myTheme = useTheme();
  const defaultValue = {
    value: 'all',
    label: 'All',
    avatar: null,
    hasAvatar: false,
  };

  const influencers = campaignInfluencers
    .map((item) => {
      return {
        value: item.id,
        label: item.name || String(item.id),
        avatar: item.avatar,
        hasAvatar: true,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const allValues = [defaultValue, ...influencers];
  const currentValue =
    allValues.find((item) => item.value === value) || defaultValue;

  return (
    <>
      <span style={{ fontSize: 16, marginRight: 8 }}>Creator: </span>
      <ReactSelect
        id="influencers-filter"
        value={currentValue}
        isSearchable={campaignInfluencers.length > 10}
        onChange={(item) => {
          onChange(item.value);
        }}
        options={allValues}
        components={{
          DropdownIndicator: GrayArrowDropDownOutlinedIcon,
          IndicatorSeparator: () => null,
          Option,
          ValueContainer,
        }}
        menuPosition="fixed"
        menuPlacement="auto"
        placeholder=""
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: myTheme.palette.primary.main,
            primary25: myTheme.palette.action.selected,
          },
        })}
        styles={{
          control: (styles) => ({
            ...styles,
            height: 48,
            fontSize: '1.2rem',
            paddingLeft: 5,
            cursor: 'pointer',
            ':hover': {
              borderColor: myTheme.palette.text.primary,
            },
          }),
          option: (styles, state) => ({
            ...styles,
            fontSize: '1.2rem',
            backgroundColor: getBackgroundColor(state),
            color: 'black',
            cursor: 'pointer',
            padding: 0,
            ':active': {
              backgroundColor: getBackgroundColor(state),
            },
          }),
          menu: (styles) => ({
            ...styles,
          }),
          input: (styles) => ({
            ...styles,
            height: 40,
            cursor: 'text',
            fontSize: '1.2rem',
            paddingTop: '6px',
          }),
          group: (styles) => ({
            ...styles,
            borderBottom: '#C8C8C8 1px solid',
          }),
        }}
        name="influencers-filter"
      />
    </>
  );
};
