import { useCallback, useMemo, useState } from 'react';

import { LoadingState } from '../views/Campaign/types';

export const useLoadingState = (
  initialState: LoadingState = LoadingState.IDLE
): [
  LoadingState,
  {
    setIdle: () => void;
    setPending: () => void;
    setFullfilled: () => void;
    setRejected: () => void;
  }
] => {
  const [state, setState] = useState(initialState);

  const setIdle = useCallback(() => setState(LoadingState.IDLE), []);
  const setPending = useCallback(() => setState(LoadingState.PENDING), []);
  const setFullfilled = useCallback(
    () => setState(LoadingState.FULLFILLED),
    []
  );
  const setRejected = useCallback(() => setState(LoadingState.REJECTED), []);

  const setters = useMemo(
    () => ({ setIdle, setPending, setFullfilled, setRejected }),
    [setIdle, setPending, setFullfilled, setRejected]
  );

  return useMemo(() => [state, setters], [state, setters]);
};
