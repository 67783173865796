import { Moment } from 'moment';

import { BannerDeliverableStructure } from './DeliverablesTab/Banners/store/types';
import { ChatMentionDeliverableStructure } from './DeliverablesTab/ChatMentions/store/types';
import { LinkTrackingDeliverableStructure } from './DeliverablesTab/LinkSharing/store/types';
import { ShoutoutDeliverableStructure } from './DeliverablesTab/Shoutouts/store/types';
import { Deliverable } from './DeliverablesTab/types';

export interface RailsRecord {
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  id: number;
}

export interface PublishedRecord extends RailsRecord {
  published_at: string;
}

export enum LoadingState {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  FULLFILLED = 'FULLFILLED',
  REJECTED = 'REJECTED',
}

export const shouldShowLoading = (loadingState: LoadingState) =>
  [LoadingState.IDLE, LoadingState.PENDING].includes(loadingState);

export const shouldFetch = (loadingState: LoadingState) =>
  loadingState === LoadingState.IDLE;

export const SLICE_NAME = 'campaigns';

export enum TrackableType {
  TwitchStreamer = 'TwitchStreamer',
  YoutubeStreamer = 'YoutubeStreamer',
  TwitterUser = 'TwitterUser',
  InstagramUser = 'InstagramUser',
  TiktokUser = 'TiktokUser',
}

export interface OffstreamTracking extends RailsRecord {
  trackable_type: TrackableType;
}

export interface CampaignInfluencer extends RailsRecord {
  id: number;
  name: string;
  campaign_id: number;
  avatar: string | null;
}

export type SelectedCampaignInfluencer = CampaignInfluencer | any;
export type PlatformFilter =
  | 'all'
  | 'twitch'
  | 'youtube'
  | 'twitter'
  | 'instagram'
  | 'tiktok';

export type VideoSegment = {
  id: number;
  end_at: number;
  start_at: number;
};

export interface Video {
  published_at: string;
  id: number;
  video_type: 'live' | 'video' | 'live-vod' | 'vod' | null;
  video_title: string;
  link: string;
  campaign_influencer_ids: number[];
  avg_cc_viewers: number;
  max_cc_viewers: number;
  total_views: number;
  computed_total_views: number;
  emv: number;
  comments_count: number;
  engagement_rate: number;
  duration: number;
  sentiment?: string;
  video_segments: VideoSegment[];
  time_zone: string;
  followers_gained: number;
  resource: 'twitch' | 'youtube' | 'facebook' | 'tiktok';
  thumbnails: { [key: string]: { url: string } };
  influencer_id: number;
  campaign_id: number;
  language: string;
  video_followers: number;
  minutes_watched_by_audience: number;
  streamer_id: number;
  external_id: string;
  live_chat_count: number;
  is_live: boolean;
  recording_link: string;
  likes_count?: number;
}

export interface Campaign extends RailsRecord {
  name: string;

  time_zone: string;

  campaign_influencers?: CampaignInfluencer[];

  offstream_trackings?: OffstreamTracking[];

  start_date: string | null; // Not used on newer campaigns
  end_date: string | null; // Not used on newer campaigns
  campaign_days: string[];

  videos: Partial<Video>[];

  // Deliverables
  shoutout_deliverables?: ShoutoutDeliverableStructure[];
  shoutout_deliverables_v2?: ShoutoutDeliverableStructure[];
  all_shoutout_deliverables: ShoutoutDeliverableStructure[];
  banner_deliverables?: BannerDeliverableStructure[];
  link_tracking_deliverables?: LinkTrackingDeliverableStructure[];
  keyword_mention_deliverables?: ChatMentionDeliverableStructure[];
  social_media_mention_deliverables?: Deliverable[];

  top_moments: boolean;
  flowise_id?: string;

  // to be filled in as we go
}
export interface CampaignsState {
  byId: {
    [key: number]: Campaign;
  };
}

export interface TimeFrame {
  start: Moment;
  end: Moment;
}
