import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getCampaigns, recordUserSession } from '../../ajax';
import { CAMPAIGNS_MOUNTED } from '../action-types';
import {
  campaignRequestReset,
  campaignsRequested,
  campaignsRequestFailed,
  campaignsRequestSucceeded,
} from '../models/campaigns/campaigns.actions';
import { selectAllCampaignsCommunicationKeys } from '../models/communication/communication.selectors';
import { getUserId } from '../models/user/user.selectors';

function* requestCampaigns() {
  let campaigns;

  try {
    const oldUserId = yield select(getUserId);

    yield put(campaignsRequested());

    campaigns = yield call(getCampaigns);

    yield call(recordUserSession, { action: 'get-campaigns' });

    const newUserId = yield select(getUserId);

    const loadedCampaignsKeys = yield select(
      selectAllCampaignsCommunicationKeys
    );

    if (newUserId === oldUserId) {
      yield put(campaignsRequestSucceeded(campaigns));

      // If there are already loaded campaigns, we reset their communication
      if (loadedCampaignsKeys.length) {
        yield call(resetLoadedCampaignsCommunication, loadedCampaignsKeys);
      }
    }
  } catch (e) {
    console.error('Campaigns Request Error - ', e);
    yield put(
      campaignsRequestFailed({
        error: (e.bodyJson && e.bodyJson.errors) || 'Campaigns Request Error',
      })
    );
  }
}

function* resetLoadedCampaignsCommunication(keys) {
  yield all(keys.map((key) => put(campaignRequestReset({ key }))));
}

export default function* () {
  yield takeLatest(CAMPAIGNS_MOUNTED, requestCampaigns);
}
