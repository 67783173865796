import { SerializedError } from '@reduxjs/toolkit';

import { ErrorMessage } from '../../../common/ErrorMessageUtil';
import { LoadingState } from '../../Campaign/types';

export const SLICE_NAME = 'influencerInfo';

export type PlatformName =
  | 'twitter'
  | 'twitch'
  | 'youtube'
  | 'facebook'
  | 'instagram'
  | 'tiktok'
  | 'Facebook Gaming';

export interface SocialPage {
  id: number;
  profile_image_url: string;
  account_name: string;
  user_login: string;
  page_title: string;
  processing: boolean;
  display_name?: string;
}

export interface FacebookSocialPage extends SocialPage {
  avg_comments_per_post?: string;
  avg_likes_per_post?: string;
  avg_posts_per_day?: string;
  avg_shares_per_post?: string;
  followers_count?: number;
}
export interface FacebookGamingSocialPage extends SocialPage {
  followers?: number;
  total_views?: number;
  avg_ccv?: number;
  peak_ccv?: number;
  hrs_streamed?: number;
  hrs_watched?: number;
  ama?: number;
  emv_video?: number;
}
export interface InstagramSocialPage extends SocialPage {
  followers_count?: number;
  posts_count?: number;
}
export interface TikTokSocialPage extends SocialPage {
  followers?: number;
  following?: number;
  likes?: number;
  total_comments?: number;
  total_shares?: number;
  total_views?: number;
  videos_count?: number;
}
export interface TwitchSocialPage extends SocialPage {
  followers?: number;
  view_count?: number;
  avg_ccv?: number;
  peak_ccv?: number;
  hrs_streamed?: number;
  hrs_watched?: number;
  ama?: number;
  emv_video?: number;
}
export interface TwitterSocialPage extends SocialPage {
  followers_count?: number;
  following_count?: number;
  avg_likes_per_post?: number;
  avg_posts_per_day?: number;
  avg_shares_per_post?: number;
  posts?: number;
  likes?: number;
  retweets?: number;
}
export interface YoutubeSocialPage extends SocialPage {
  subscriber_count?: number;
  view_count?: number;
  avg_views?: number;
  avg_comments?: number;
  total_likes?: number;
  total_dislikes?: number;
  engagement_rate?: number;
  emv?: number;
  video_count?: number;
}
export interface InfluencerInfo {
  id: number;

  email: string;
  gender: string | null;
  date_of_birth: string | null;
  name: string | null;
  first_name: string | null;
  last_name: string | null;
  location: string | null;

  interests: string[] | null;
  other_interests: string[] | null;

  onboarding_email: string | null;
  website: string | null;
  phone: string | null;
  discord_username: string | null;
  contact_by_email: boolean | null;
  player_type: {
    streamer: boolean;
    pro_player: boolean;
  };

  onboarding_twitch_pages: SocialPage[];
  onboarding_youtube_pages: SocialPage[];
  onboarding_facebook_pages: SocialPage[];
  onboarding_instagram_pages: SocialPage[];
  onboarding_twitter_pages: SocialPage[];
  onboarding_tiktok_pages: SocialPage[];

  // marks whether the influencer has been onboarded
  wizard_completed: boolean;
}

export interface InfluencerInfoState {
  data: InfluencerInfo | null;
  dataLoadingState: LoadingState;
  dataError: null | SerializedError;
  putInfluencerInfoLoadingState: LoadingState;
  putInfluencerInfoError: null | ErrorMessage[];
  disconnectPlatformLoadingMap: { [key: string]: LoadingState };
}
