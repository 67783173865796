import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  main: {
    maxHeight: '100%',
    minHeight: '100%',
    overflow: 'auto',
    padding: '32px 16px',
  },
});

function Container(props) {
  const { className } = props;

  const { main } = useStyles();

  return <div {...props} className={clsx(main, className)} />;
}

export default Container;
