import { Avatar, Grid, makeStyles } from '@material-ui/core';
import numeral from 'numeral';

import facebookGaming from '../../assets-2.0/facebookGaming.png';
import facebook from '../../assets-2.0/images-influencer/facebook.svg';
import instagram from '../../assets-2.0/instagram.svg';
import tiktok from '../../assets-2.0/tiktok.svg';
import twitch from '../../assets-2.0/twitch.svg';
import twitter from '../../assets-2.0/twitter.svg';
import youtube from '../../assets-2.0/youtube.svg';
import {
  FacebookSocialPage,
  InstagramSocialPage,
  SocialPage,
  TikTokSocialPage,
  TwitchSocialPage,
  TwitterSocialPage,
  YoutubeSocialPage,
} from '../../views/influencerViews/store/types';
import locationIcon from './images/location.svg';

const useStyles = makeStyles((theme) => ({
  influencerInfo: {
    background: '#ffffff',
    padding: '16px 30px 16px 18px',
    border: '2px solid #dddddd',
    borderRadius: 4,
    minHeight: 270,
    display: 'flex',
    fontSize: 16,
    position: 'relative',
  },
  InfoInfluencerSM: {
    display: 'none',
    width: '100%',
  },
  InfoInfluencer: {
    display: 'flex',
    width: '100%',
  },
  nameIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 160,
    height: 160,
    fontSize: 40,
    textTransform: 'uppercase',
  },
  moreInfoInfluencer: {
    marginLeft: 30,
  },
  statusInfluencer: {
    padding: '2px 20px',
    background: theme.palette.primary.main,
    textTransform: 'uppercase',
    color: '#ffffff',
    width: 'max-content',
  },
  influencerLogin: {
    fontSize: 30,
    fontWeight: 'bold',
    margin: 0,
    lineHeight: '50px',
  },
  influencerIcons: {
    display: 'flex',
    '& img': {
      height: 20,
      marginLeft: 15,
    },
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 25,
      height: 25,
    },
    '& p': {
      margin: '0px 6px',
    },
  },
  languages: {
    display: 'flex',
    flexDirection: 'column',
  },
  totalInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 'auto',
    '& p': {
      textTransform: 'uppercase',
      margin: 0,
    },
  },
  allText: {
    display: 'flex',
    width: '100%',
  },
  totalInfoNumber: {
    color: theme.palette.primary.main,
    fontSize: 52,
    fontWeight: 'bold',
    lineHeight: '46px',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  connectButton: {
    border: '1px solid #5a3e85',
    position: 'absolute',
    right: 30,
    fontSize: 10,
    fontWeight: 'bold',
    color: '#5a3e85',
    padding: 5,
    '&:hover': {
      background: '#5a3e85',
      color: 'white',
    },
  },
  [theme.breakpoints.down('sm')]: {
    InfoInfluencer: {
      display: 'none',
    },
    InfoInfluencerSM: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    influencerInfo: {
      padding: '16px 24px 16px 24px',
      minHeight: 220,
      display: 'flex',
      fontSize: 14,
    },
    avatar: {
      width: 70,
      height: 70,
      textTransform: 'uppercase',
    },
    statusInfluencer: {
      padding: '2px 5px',
      fontSize: 12,
    },
    influencerLogin: {
      fontSize: 18,
      lineHeight: '25px',
    },
    smallDataInfluencer: {
      padding: '10px 0px 0px 15px',
    },
    totalInfoNumber: {
      fontSize: 20,
      lineHeight: '20px',
    },
    totalInfo: {
      paddingTop: 25,
      width: 'max-content',
      '& p': {
        fontSize: 14,
      },
    },
    moreInfoInfluencer: {
      margin: 0,
      width: '100%',
    },
    languages: {
      '& b': {
        fontSize: 16,
        margin: 0,
      },
      '& label': {
        margin: 0,
      },
    },
    description: {
      marginTop: 15,
    },
    langIcons: {
      display: 'flex',
      alignItems: 'end',
    },
    influencerIcons: {
      marginLeft: 'auto',
      '& img': {
        height: 15,
      },
    },
    connectButton: {
      right: 23,
      bottom: 40,
      fontSize: 6,
      padding: 3,
    },
  },
}));

const iconsArray = {
  twitch: {
    icon: twitch,
    name: 'twitch',
  },
  youtube: {
    icon: youtube,
    name: 'youtube',
  },
  instagram: {
    icon: instagram,
    name: 'instagram',
  },
  twitter: {
    icon: twitter,
    name: 'twitter',
  },
  facebook: {
    icon: facebook,
    name: 'facebook',
  },
  tiktok: {
    icon: tiktok,
    name: 'tiktok',
  },
  facebookGaming: {
    icon: facebookGaming,
    name: 'facebookGaming',
  },
};

interface InfluencerCardProps {
  onboarding_facebook_pages?: Array<FacebookSocialPage>;
  onboarding_instagram_pages?: Array<InstagramSocialPage>;
  onboarding_tiktok_pages?: Array<TikTokSocialPage>;
  onboarding_twitch_pages?: Array<TwitchSocialPage>;
  onboarding_twitter_pages?: Array<TwitterSocialPage>;
  onboarding_youtube_pages?: Array<YoutubeSocialPage>;
  name?: string;
  language?: string;
  location?: string;
  status?: string;
  description?: string;
}

export const InfluencerCard = ({
  onboarding_facebook_pages,
  onboarding_instagram_pages,
  onboarding_tiktok_pages,
  onboarding_twitch_pages,
  onboarding_twitter_pages,
  onboarding_youtube_pages,
  name,
  language,
  location,
  status,
  description,
}: InfluencerCardProps) => {
  const classes = useStyles();

  const totalAudience =
    ((onboarding_twitter_pages.length &&
      onboarding_twitter_pages[0].followers_count) ||
      0) +
    ((onboarding_facebook_pages.length &&
      onboarding_facebook_pages[0].followers_count) ||
      0) +
    ((onboarding_twitch_pages.length && onboarding_twitch_pages[0].followers) ||
      0) +
    ((onboarding_youtube_pages.length &&
      onboarding_youtube_pages[0].subscriber_count) ||
      0) +
    ((onboarding_instagram_pages.length &&
      onboarding_instagram_pages[0].followers_count) ||
      0) +
    ((onboarding_tiktok_pages.length && onboarding_tiktok_pages[0].followers) ||
      0);

  const priorityMedia = (): SocialPage[][] => {
    return [
      onboarding_twitch_pages,
      onboarding_youtube_pages,
      onboarding_facebook_pages,
      onboarding_twitter_pages,
      onboarding_instagram_pages,
      onboarding_tiktok_pages,
    ].filter((item) => {
      return item.length;
    });
  };

  const influencerAvatar = () => {
    let result = name?.[0];
    for (let social of priorityMedia()) {
      if (social[0].profile_image_url) {
        return (result = social[0].profile_image_url);
      }
    }
    return result;
  };

  const influencerName = () => {
    let result = name;
    for (let social of priorityMedia()) {
      if (social[0].page_title || social[0].display_name) {
        return social[0].page_title || social[0].display_name;
      }
    }
    return result;
  };

  const influencerCardInfo = (
    influencerAvatar: string,
    influencerName: string
  ) => {
    return influencerAvatar ? (
      <Avatar className={classes.avatar} src={influencerAvatar} />
    ) : influencerName ? (
      <Avatar className={classes.avatar}>{influencerName[0]}</Avatar>
    ) : (
      <Avatar className={classes.avatar} />
    );
  };

  return (
    <Grid className={classes.influencerInfo}>
      <div className={classes.InfoInfluencer}>
        {influencerCardInfo(influencerAvatar(), influencerName())}
        <div className={classes.allText}>
          <div className={classes.moreInfoInfluencer}>
            {status && <div className={classes.statusInfluencer}>{status}</div>}
            <div className={classes.nameIcons}>
              {influencerName() && (
                <p className={classes.influencerLogin}>{influencerName()}</p>
              )}
              <div className={classes.influencerIcons}>
                {onboarding_twitch_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.twitch.name}`}
                    src={iconsArray.twitch.icon}
                  />
                )}
                {onboarding_youtube_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.youtube.name}`}
                    src={iconsArray.youtube.icon}
                  />
                )}
                {onboarding_instagram_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.instagram.name}`}
                    src={iconsArray.instagram.icon}
                  />
                )}
                {onboarding_twitter_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.twitter.name}`}
                    src={iconsArray.twitter.icon}
                  />
                )}
                {onboarding_facebook_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.facebook.name}`}
                    src={iconsArray.facebook.icon}
                  />
                )}
                {onboarding_tiktok_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.tiktok.name}`}
                    src={iconsArray.tiktok.icon}
                  />
                )}
              </div>
            </div>
            {location && (
              <div className={classes.location}>
                <img alt="location" src={locationIcon} />
                <p>{location}</p>
              </div>
            )}
            {description && <p>{description}</p>}

            {language && (
              <div className={classes.languages}>
                <b>LANGUAGE</b>
                <label style={{ marginTop: 5 }}>{language}</label>
              </div>
            )}
          </div>
          <div className={classes.totalInfo}>
            <div className={classes.totalInfoNumber}>
              {numeral(totalAudience).format('0.[0]a')}
            </div>
            <p>Total audience</p>
          </div>
        </div>
      </div>

      <div className={classes.InfoInfluencerSM}>
        <div style={{ display: 'flex' }}>
          {influencerCardInfo(influencerAvatar(), influencerName())}
          <div className={classes.smallDataInfluencer}>
            {status && <div className={classes.statusInfluencer}>{status}</div>}
            {influencerName() && (
              <p className={classes.influencerLogin}>{influencerName()}</p>
            )}
          </div>
        </div>

        <div className={classes.totalInfo}>
          <div className={classes.totalInfoNumber}>
            {numeral(totalAudience).format('0.[0]a')}
          </div>
          <p>Total audience</p>
        </div>

        <div className={classes.allText}>
          <div className={classes.moreInfoInfluencer}>
            {location && (
              <div className={classes.location}>
                <img alt="location" src={locationIcon} />
                <p>{location}</p>
              </div>
            )}
            {description && (
              <p className={classes.description}>{description}</p>
            )}
            <div className={classes.langIcons}>
              {language && (
                <div className={classes.languages}>
                  <b>LANGUAGE</b>
                  <label>{language}</label>
                </div>
              )}
              <div className={classes.influencerIcons}>
                {onboarding_twitch_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.twitch.name}`}
                    src={iconsArray.twitch.icon}
                  />
                )}
                {onboarding_youtube_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.youtube.name}`}
                    src={iconsArray.youtube.icon}
                  />
                )}
                {onboarding_instagram_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.instagram.name}`}
                    src={iconsArray.instagram.icon}
                  />
                )}
                {onboarding_twitter_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.twitter.name}`}
                    src={iconsArray.twitter.icon}
                  />
                )}
                {onboarding_facebook_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.facebook.name}`}
                    src={iconsArray.facebook.icon}
                  />
                )}
                {onboarding_tiktok_pages.length > 0 && (
                  <img
                    alt={`${iconsArray.tiktok.name}`}
                    src={iconsArray.tiktok.icon}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
