import { Avatar, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import * as PATHS from '../../../common/constants/paths';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const CreatorAvatar = ({
  avatar,
  name: explicitName,
  influencer,
  isDiscovery,
}) => {
  const classes = useStyles();
  const fallbackImage = '../../fallback/img.png';

  let nameLink;
  if (!!influencer?.twitch_external_id)
    nameLink = `${PATHS.INFLUENCER}/${influencer.twitch_external_id}/twitch`;
  else if (!!influencer?.youtube_external_id)
    nameLink = `${PATHS.INFLUENCER}/${influencer.youtube_external_id}/youtube`;

  const name = influencer.name || explicitName;

  return (
    <div
      style={{
        maxWidth: '150px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Avatar src={avatar ? avatar : fallbackImage} alt={name?.toUpperCase()} />
      <Tooltip placement="top" title={name}>
        {isDiscovery ? (
          <Link
            to={nameLink}
            onClick={(e) => {
              if (nameLink) e.stopPropagation();
            }}
          >
            <Typography
              className={classes.link}
              style={{
                fontWeight: 'bold',
                paddingLeft: 16,
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {name}
            </Typography>
          </Link>
        ) : (
          <Typography
            className={classes.link}
            style={{
              fontWeight: 'bold',
              paddingLeft: 16,
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {name}
          </Typography>
        )}
      </Tooltip>
    </div>
  );
};

export default CreatorAvatar;
