import React from 'react';

import { YoutubeSocialPage } from '../../../store/types';
import { BoxInfo } from '../InfluencerOverviewPerformance';

export const OverviewPerformanceYouTube = ({
  subscriber_count,
  view_count,
  video_count,
  avg_comments,
  total_likes,
  total_dislikes,
  engagement_rate,
  emv,
}: YoutubeSocialPage) => {
  return (
    <>
      <BoxInfo
        label="SUBSCRIBERS"
        info="Total lifetime subscribers."
        stat={subscriber_count}
      />
      <BoxInfo
        label="TOTAL VIEWS"
        info="Total lifetime views."
        stat={view_count}
      />
      <BoxInfo
        label="AVG. VIEWS"
        info="Average number of views per video in the last 90 days."
        stat={view_count / video_count ?? null}
      />
      <BoxInfo
        label="AVG. COMMENTS"
        info="Average number of comments per video in the last 90 days."
        stat={avg_comments}
      />
      <BoxInfo
        label="TOTAL LIKES"
        info="The number of likes received on the videos posted in the last 90 days."
        stat={total_likes}
      />
      <BoxInfo
        label="TOTAL DISLIKES"
        info="The number of dislikes received on the videos posted in the last 90 days."
        stat={total_dislikes}
      />
      <BoxInfo
        label="ENGAGEMENT RATE"
        info="The engagement rate is based on the interactions (views, likes, and dislikes) to subscribers ratio and it is calculated for videos from the last 90 days."
        stat={engagement_rate}
        suffix="%"
      />
      <BoxInfo
        label="EMV/VIDEO"
        info="This is an estimation for the average media value per video on this channel. The value relies on the videos streamed in the last three months and it's calculated by valuing the price of impressions against a standardized CPM"
        stat={emv}
        format="0,0"
        prefix="$"
      />
    </>
  );
};
