import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonIcon from '@material-ui/icons/Person';
import VideocamIcon from '@material-ui/icons/Videocam';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CampaignCreatorAndDayFilter from '../../CampaignCreatorAndDayFilter';
import CampaignVideoFlag from '../../CampaignVideoFlag';
import DetailedViewToggle, {
  useDetailedViewToggle,
} from '../../components/DetailedViewToggle';
import EyeIcon from '../../components/EyeIcon';
import OverviewCard, {
  PlaceholderOverviewRow,
} from '../../components/OverviewCard';
import SectionTitle from '../../components/SectionTitle';
import { findInfluencerByVideo, findVideoById } from '../../utils';
import DetailedTable from './DetailedTable';
import {
  selectShouldFetchShoutoutDeliverable,
  selectShoutoutDeliverableById,
  selectShoutoutDeliverableShowLoading,
} from './store/ShoutoutDeliverables.selectors';
import {
  deleteMultipleShoutouts,
  fetchShoutoutDeliverable,
} from './store/ShoutoutDeliverables.thunks';
import {
  isShoutoutDeliverableStructureV1,
  isShoutoutDeliverableStructureV2,
  Shoutout,
  ShoutoutDeliverableStructure,
  ShoutoutDeliverableStructureV1,
} from './store/types';
import SummaryTable, { computeSummary } from './SummaryTable';

const computeOverview = ({ usedShoutouts, usedInfluencers, usedVideos }) => {
  const creators = new Set(
    usedShoutouts.map(
      (shoutout) =>
        findInfluencerByVideo(
          findVideoById(shoutout.video_id, usedVideos),
          usedInfluencers
        ).id
    )
  ).size;

  const { mentions, streams, avgCCV, peakCCV, totalViewers, totalEMV } =
    computeSummary(usedShoutouts, usedVideos);

  return {
    mentions,
    creators,
    streams,
    avgCCV,
    peakCCV,
    totalViewers,
    totalEMV,
  };
};

interface OverviewProps {
  shoutoutDeliverableStructure: ShoutoutDeliverableStructure;
  usedShoutouts: Shoutout[];
  usedInfluencers: any[];
  usedVideos: any[];
  showLoading: boolean;
}

const Overview = React.memo<OverviewProps>(
  ({
    shoutoutDeliverableStructure,
    usedShoutouts,
    usedInfluencers,
    usedVideos,
    showLoading,
  }) => {
    const { processing } = shoutoutDeliverableStructure;

    let content: string;

    if (isShoutoutDeliverableStructureV2(shoutoutDeliverableStructure)) {
      content = shoutoutDeliverableStructure.name;
    } else {
      content = (shoutoutDeliverableStructure as ShoutoutDeliverableStructureV1)
        .content;
    }

    const header = (
      <>
        <SectionTitle>
          <b>{content}</b> voice mentions
          {processing && (
            <CampaignVideoFlag
              title="UPDATING"
              color="rgb(238, 132, 52)"
              style={{ margin: '0 0 -2px 10px' }}
            />
          )}
        </SectionTitle>
        {isShoutoutDeliverableStructureV2(shoutoutDeliverableStructure) &&
          shoutoutDeliverableStructure.structured && (
            <>
              <p style={{ margin: 0, marginTop: -28 }}>
                Includes:{' '}
                <b>{shoutoutDeliverableStructure.inclusion_terms.join(', ')}</b>
              </p>
              {shoutoutDeliverableStructure.exclusion_terms.length > 0 && (
                <p style={{ margin: 0, marginBottom: 8 }}>
                  Excludes:{' '}
                  <b>
                    {shoutoutDeliverableStructure.exclusion_terms.join(', ')}
                  </b>
                </p>
              )}
            </>
          )}
      </>
    );

    if (showLoading) {
      return (
        <div>
          {header}
          <PlaceholderOverviewRow nCards={usedInfluencers.length > 1 ? 6 : 5} />
        </div>
      );
    }

    const {
      mentions,
      creators,
      streams,
      avgCCV,
      peakCCV,
      totalViewers,
      totalEMV,
    } = computeOverview({
      usedShoutouts,
      usedVideos,
      usedInfluencers,
    });

    return (
      <div>
        {header}
        <OverviewCard.Container>
          <OverviewCard
            label="Creators"
            color="teal"
            main={{ stat: creators }}
            icon={<PersonIcon />}
            outOf={usedInfluencers.length}
            id={`sponsorshipVal-vm-creators-${content.replace(/\s+/g, '')}`}
          />
          <OverviewCard
            label="Videos"
            color="orange"
            main={{ stat: streams }}
            icon={<VideocamIcon />}
            id={`sponsorshipVal-vm-streams-${content.replace(/\s+/g, '')}`}
          />
          <OverviewCard
            label="Mentions"
            color="blue"
            main={{ stat: mentions }}
            icon={<VolumeDownIcon style={{ transform: 'rotate(-45deg)' }} />}
            id={`sponsorshipVal-vm-mentions-${content.replace(/\s+/g, '')}`}
          />
          <OverviewCard
            label="Average CCV"
            color="purple"
            main={{ stat: avgCCV }}
            secondary={{ label: 'peak', stat: peakCCV }}
            icon={<EyeIcon />}
            id={`sponsorshipVal-vm-averagessv-${content.replace(/\s+/g, '')}`}
          />
          <OverviewCard
            label="Total viewers"
            color="purple"
            main={{ stat: totalViewers }}
            icon={<VisibilityIcon />}
            id={`sponsorshipVal-vm-totalvwrs-${content.replace(/\s+/g, '')}`}
          />
          <OverviewCard
            label="Media value"
            color="green"
            main={{ stat: totalEMV, prefix: '$', format: '0,[0].[0]a' }}
            icon={<LocalAtmIcon />}
            id={`sponsorshipVal-vm-mediavalue-${content.replace(/\s+/g, '')}`}
          />
        </OverviewCard.Container>
      </div>
    );
  }
);

interface ShoutoutDeliverableProps {
  shoutoutDeliverableStructure: ShoutoutDeliverableStructure;
  usedInfluencers: any[];
  usedVideos: any[];
  usedVideosIds: Set<number>;
  timeZone: string;
  setSelectedCampaignInfluencer: (inf: any) => void;
  deleteShoutout: (shoutout: Shoutout) => void;
  isSponsorship: boolean;
  isFilteredByCreator: boolean;
  setByCreator: (inf: any) => void;
}

export const ShoutoutDeliverable = React.memo<ShoutoutDeliverableProps>(
  ({
    shoutoutDeliverableStructure,
    usedInfluencers,
    usedVideos,
    usedVideosIds,
    timeZone,
    setSelectedCampaignInfluencer,
    deleteShoutout,
    isSponsorship,
    isFilteredByCreator,
    setByCreator,
  }) => {
    const {
      id: shoutoutDeliverableId,
      campaign_id: campaignId,
      v2,
    } = shoutoutDeliverableStructure;

    const shoutoutDeliverable = useSelector(
      selectShoutoutDeliverableById(shoutoutDeliverableId)
    );

    const showLoading = useSelector(
      selectShoutoutDeliverableShowLoading(shoutoutDeliverableId)
    );

    const shouldFetch = useSelector(
      selectShouldFetchShoutoutDeliverable(shoutoutDeliverableId)
    );

    const dispatch = useDispatch();

    useEffect(() => {
      if (shouldFetch) {
        dispatch(
          fetchShoutoutDeliverable({
            shoutoutDeliverableId,
            campaignId,
            v2,
          })
        );
      }
    }, [
      dispatch,
      shouldFetch,
      shoutoutDeliverableStructure,
      campaignId,
      v2,
      shoutoutDeliverableId,
    ]);

    const usedShoutouts = useMemo(
      () =>
        showLoading || shoutoutDeliverable == null
          ? []
          : shoutoutDeliverable.shoutouts.filter((shoutout) =>
              usedVideosIds.has(shoutout.video_id)
            ),
      [showLoading, shoutoutDeliverable, usedVideosIds]
    );

    const { detailed, setDetailed } = useDetailedViewToggle(usedInfluencers);

    const onDeleteShoutoutMentions = (ids: number[]) => {
      dispatch(
        deleteMultipleShoutouts({
          campaignId: shoutoutDeliverableStructure.campaign_id,
          shoutoutDeliverableId: shoutoutDeliverableStructure.id,
          shoutoutIds: ids,
          v2: shoutoutDeliverableStructure.v2,
        })
      );
    };

    return (
      <div
        style={{ paddingBottom: 100 }}
        id={`shoutouts/${shoutoutDeliverableStructure.id}`}
      >
        <Overview
          usedShoutouts={usedShoutouts}
          usedInfluencers={usedInfluencers}
          usedVideos={usedVideos}
          shoutoutDeliverableStructure={shoutoutDeliverableStructure}
          showLoading={showLoading}
        />
        {usedShoutouts.length > 0 && (
          <>
            <DetailedViewToggle
              {...{ detailed, setDetailed, usedInfluencers }}
            />
            {isSponsorship && !detailed && (
              <CampaignCreatorAndDayFilter
                setByCreator={setByCreator}
                isFilteredByCreator={isFilteredByCreator}
              />
            )}
            {!detailed && (
              <SummaryTable
                usedInfluencers={usedInfluencers}
                usedVideos={usedVideos}
                usedShoutouts={usedShoutouts}
                timeZone={timeZone}
                setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
                isFilteredByCreator={isFilteredByCreator}
                isSponsorship={isSponsorship}
                words={
                  isShoutoutDeliverableStructureV2(shoutoutDeliverableStructure)
                    ? shoutoutDeliverableStructure.inclusion_terms
                    : [
                        (
                          shoutoutDeliverableStructure as ShoutoutDeliverableStructureV1
                        ).content,
                      ]
                }
              />
            )}
            {detailed && (
              <DetailedTable
                setCampaignShoutoutDelete={deleteShoutout}
                usedInfluencers={usedInfluencers}
                timeZone={timeZone}
                usedVideos={usedVideos}
                usedShoutouts={usedShoutouts}
                content={
                  isShoutoutDeliverableStructureV1(shoutoutDeliverableStructure)
                    ? shoutoutDeliverableStructure.content
                    : ''
                }
                words={
                  isShoutoutDeliverableStructureV2(shoutoutDeliverableStructure)
                    ? shoutoutDeliverableStructure.inclusion_terms
                    : []
                }
                v2={v2}
                onDeleteShoutoutMentions={onDeleteShoutoutMentions}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default ShoutoutDeliverable;
