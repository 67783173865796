import MuiAppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ExitIcon from '@material-ui/icons/ExitToApp';
import FolderSpecialOutlined from '@material-ui/icons/FolderSpecialOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import React, { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as paths from '../common/constants/paths';
import { isInfluencerView } from '../common/containers/Config/selectors';
import Button from '../components/Button';
import { actions } from '../store/actions';
import {
  getUserFeatures,
  // isAggeroUser,
} from '../store/models/user/user.selectors';
import { selectors } from '../store/selectors';
import { selectInfluencerEmail } from '../views/influencerViews/store/InfluencerInfo.selectors';
import { AppContext } from './App';
import SearchByName from './SearchByName';

const mapStateToProps = (state) => ({
  userName: selectors.getUserName(state),
  userImage: selectors.getUserImage(state),
  userEmail: selectors.getUserEmail(state),
  userEmailInfluencer: selectInfluencerEmail()(state),

  features: getUserFeatures(state),
});

const mapDispatchToProps = { signout: actions.signout };

const enhance = connect(mapStateToProps, mapDispatchToProps);

const useStyles = makeStyles((theme) => ({
  exitIcon: {
    marginRight: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menu: {
    minWidth: 200,
  },
  floatLeft: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const AppBar = (props) => {
  const {
    signout,
    userName,
    userEmail,
    userEmailInfluencer,
    userImage,
    features,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const { toggleMenu } = useContext(AppContext);

  const classes = useStyles();

  const isInfluencer = useSelector(isInfluencerView);
  // const aggeroUser = useSelector(isAggeroUser);

  return (
    <MuiAppBar elevation={1} color="inherit" position="static">
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <IconButton
          onClick={toggleMenu}
          className={classes.menuButton}
          aria-label="menu"
          color="inherit"
        >
          <MenuIcon color="primary" />
        </IconButton>
        <div className={classes.floatLeft}>
          {!isInfluencer && features.discovery !== false && <SearchByName />}
          <Button onClick={handleClick} data-id="app-bar-avatar">
            {userImage ? (
              <Avatar alt="logged in user's profile avatar" src={userImage} />
            ) : (
              <Avatar>
                <PersonIcon />
              </Avatar>
            )}
          </Button>
          <Menu
            keepMounted
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            className={classes.menu}
          >
            <MenuItem divider>
              <ListItemText
                primary={userName}
                secondary={
                  userEmailInfluencer ? userEmailInfluencer : userEmail
                }
              />
            </MenuItem>
            {!isInfluencer && (
              <Link
                style={{ color: '#333' }}
                to={`${paths.MY_AGGERO}/profile`}
              >
              <MenuItem>
                <SettingsOutlinedIcon className={classes.exitIcon} />
                  My Aggero
                </MenuItem>
              </Link>
            )}
            {!isInfluencer && (
              <Link style={{ color: '#333' }} to={paths.MY_BRANDS}>
                <MenuItem>
                  <FolderSpecialOutlined className={classes.exitIcon} />
                  My Brands
                </MenuItem>
              </Link>
            )}
            <MenuItem onClick={signout}>
              <ExitIcon className={classes.exitIcon} />
              Sign Out
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
      {/*<Divider />*/}
    </MuiAppBar>
  );
};

export default enhance(AppBar);
