import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSelector } from 'react-redux';

import OutlinedInput from '../../components/OutlinedInput';
import { selectShoutoutsFilterSponsorship } from './utils/CampaignFiltersStore';

const useStyles = makeStyles({
  select: {
    padding: 4,
    background: 'white',
  },
  label: {
    fontSize: 16,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
  },
});

export const CampaignBrandFilter = ({
  brandFilter,
  setBrandFilter,
  campaign,
  tab,
}) => {
  const onChange = ({ target: { value } }) => setBrandFilter(value);
  const classes = useStyles();
  const shoutoutsFilterSponsorship = useSelector(
    selectShoutoutsFilterSponsorship
  );

  let brandIdWithShoutout = null;

  if (shoutoutsFilterSponsorship !== 'all' && tab === 'shoutouts') {
    const shoutouts = [
      ...campaign.shoutout_deliverables_v2,
      ...campaign.shoutout_deliverables,
    ];

    const shoutout = shoutouts.find((s) => s.id === shoutoutsFilterSponsorship);

    brandIdWithShoutout = shoutout?.campaign_brand_id;
  }

  const brands = campaign?.campaign_brands?.filter(({ id }) => {
    if (brandIdWithShoutout) {
      return id === brandIdWithShoutout;
    }

    return true;
  });

  if (brands.length <= 1 && !brandIdWithShoutout) return null;
  return (
    <>
      <span className={classes.label}>Brand: </span>
      <Select
        value={brandFilter}
        classes={{ selectMenu: classes.select }}
        input={<OutlinedInput />}
        onChange={onChange}
      >
        <MenuItem value="all" style={{ padding: 10 }}>
          <Typography
            variant="h6"
            style={{
              padding: '3.36px 12px',
              margin: 0,
            }}
          >
            All
          </Typography>
        </MenuItem>
        {brands.map(({ brand_name, id }, index) => {
          return (
            <MenuItem key={index} value={id} style={{ padding: 10 }}>
              <Box
                display="flex"
                alignItems="center"
                style={{ padding: '3.36px 12px' }}
              >
                <Typography variant="h6">{brand_name}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default CampaignBrandFilter;
