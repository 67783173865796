import { Button, makeStyles, Theme } from '@material-ui/core';

import { ComponentSize } from '../../utils/ComponentSize';

const useStyles = makeStyles<
  Theme,
  Pick<LabelButtonProps, 'variant' | 'size' | 'disabled'>
>((theme) => ({
  button: ({ variant, size, disabled }) => {
    return {
      backgroundColor:
        variant === 'contained' ? theme.palette.primary.main : 'white',
      minWidth: size === 'sm' ? 229 : 285,
      height: size === 'sm' ? 22 : 30,
      borderRadius: 10,
      fontSize: size === 'sm' ? 10 : 12,
      border: variant === 'outlined' ? '2px solid #69ddff' : null,
      color: variant === 'outlined' ? 'black' : 'white;',
      pointerEvents: disabled ? 'none' : 'auto',

      '&:hover': {
        border: variant === 'outlined' ? '2px solid #69ddff' : null,
      },
    };
  },
}));

interface LabelButtonProps {
  text: string;
  onClick?: (any) => void;
  variant?: 'contained' | 'outlined';
  size?: Extract<ComponentSize, 'sm' | 'md'>;
  disabled?: boolean;
}

export const LabelButton = ({
  text,
  onClick,
  variant = 'contained',
  size = 'md',
  disabled,
}: LabelButtonProps) => {
  const classes = useStyles({ variant, size, disabled });
  return (
    <Button
      onClick={onClick}
      className={classes.button}
      color="primary"
      variant={variant}
    >
      {text}
    </Button>
  );
};
