import { Typography } from '@material-ui/core';
import React from 'react';

export const SectionTitle = (props) => (
  <Typography
    variant="h2"
    {...props}
    style={{
      textTransform: 'uppercase',
      marginBottom: 32,
      fontFamily: 'Open Sans',
      fontSize: '1.3rem',
      ...props.style,
    }}
  />
);

export default SectionTitle;
