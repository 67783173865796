import {
  Box,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import React from 'react';

import { ComponentSize } from '../../utils/ComponentSize';
import { StatBox } from '../StatBox';

const useStyles = makeStyles<
  Theme,
  Pick<OverviewCardProps, 'size' | 'isLocked'>
>({
  card: {
    marginLeft: 12,
    marginBottom: 12,
  },

  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  iconContainer: ({ size }) => {
    if (size === 'sm') {
      return {
        width: 30,
        height: 30,
        minWidth: 30,
      };
    }

    return {
      width: 38,
      height: 38,
    };
  },

  icon: ({ size }) => {
    if (size === 'sm') {
      return {
        width: 15,
        height: 15,
      };
    }
  },

  cardTypography: ({ size }) => {
    if (size === 'sm') {
      return {
        fontSize: '0.75rem',
        wordBreak: 'break-word',
      };
    }
  },

  outOf: ({ size }) => {
    const defaultStyles = {
      marginBottom: 3,
      fontSize: '1.5rem',
      color: '#888',
    };

    if (size === 'sm') {
      return { ...defaultStyles, fontSize: '1rem' };
    }

    return defaultStyles;
  },

  placeholderContent: {
    height: 143,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 16,
  },

  lockedContainer: {
    filter: 'blur(6px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
});

export const colors = {
  blue: '#0048f2',
  lightBlue: '#32A9F2',
  teal: '#14b8a4',
  orange: '#ee8434',
  purple: '#733a99',
  green: '#24c866',
  black: '#000000',
  grey: '#6C969D',
  red: '#EE6055',
};

interface OverviewCardProps {
  label: string;
  color: keyof typeof colors | string;
  icon: JSX.Element;
  main: any; // Props of StatBox;
  secondary?: any; // Props of StatBox;
  info: string;
  outOf: string | number;
  secondaryComponent: JSX.Element;
  size?: Extract<ComponentSize, 'sm'>;
  isLocked?: boolean;
  id: string;
}

export const OverviewCard = ({
  label,
  color,
  icon,
  main,
  secondary,
  secondaryComponent,
  info,
  outOf,
  size,
  id,
  isLocked,
}: Partial<OverviewCardProps>) => {
  const classes = useStyles({ size, isLocked });
  const cardColor = colors[color] || color;

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="flex-start"
        >
          <Typography className={classes.cardTypography}>
            {label}
            {info && (
              <Tooltip placement="top" title={info}>
                <IconButton size="small">
                  <InfoOutlinedIcon
                    style={{ width: '0.65em', height: '0.65em' }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
          <Box
            className={classes.iconContainer}
            borderRadius="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            style={{ overflow: 'hidden', color: cardColor, fontSize: 24 }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: cardColor,
                opacity: 0.2,
              }}
            ></div>
            <SvgIcon className={classes.icon}>{icon}</SvgIcon>
          </Box>
        </Box>
        <Box
          display="flex"
          mt={2}
          justifyContent="space-between"
          alignItems="flex-end"
          className={clsx({ [classes.lockedContainer]: isLocked })}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <StatBox
              id={id}
              size={'lg'}
              noBold
              color="#444444"
              statFontSize={size === 'sm' ? '2rem' : null}
              {...main}
            />
            {outOf && <span className={classes.outOf}>/{outOf}</span>}
          </div>
          {secondaryComponent}
          {secondary && (
            <StatBox color={cardColor} size="overviewCard" {...secondary} />
          )}
          {!secondaryComponent && !secondary && <Box />}
        </Box>
      </CardContent>
    </Card>
  );
};

OverviewCard.colors = colors;

const useContainerStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 16,
    display: 'grid',
    marginLeft: -12,
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridAutoRows: '1fr',
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}));

export const OverviewCardsContainer = (props) => {
  const classes = useContainerStyles();

  return <div className={classes.container} {...props} />;
};

OverviewCard.Container = OverviewCardsContainer;

const PlaceholderOverviewCard = () => {
  const classes = useStyles({});

  return (
    <Card className={classes.card}>
      <CardContent className={classes.placeholderContent}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="flex-start"
        >
          <Skeleton variant="rect" width={128} height={24} />
          <Skeleton variant="circle" width={38} height={38} />
        </Box>
        <Skeleton variant="rect" width={150} height={24} />
        <Skeleton variant="rect" width={100} height={24} />
      </CardContent>
    </Card>
  );
};

interface PlaceholderOverviewRowProps {
  nCards: number;
}

export const PlaceholderOverviewRow = React.memo<PlaceholderOverviewRowProps>(
  ({ nCards }) => (
    <OverviewCardsContainer>
      {Array(nCards)
        .fill(0)
        .map((_, idx) => (
          <PlaceholderOverviewCard key={idx} />
        ))}
    </OverviewCardsContainer>
  )
);

export default OverviewCard;
