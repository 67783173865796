import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';

import Banners from './Banners';
import ChatMentions from './ChatMentions';
import Links from './Links';
import Shoutouts from './Shoutouts';
import SocialMediaMentions from './SocialMediaMentions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(4),
  },
  leftColumn: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    '& .MuiCard-root:first-child': {
      marginTop: 16,
    },
  },
  rightColumn: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
    '& .MuiCard-root:first-child': {
      marginTop: 16,
    },
  },
  trialDisabled: {
    pointerEvents: 'none',
    backgroundColor: '#ccc',
  },
}));

export const MediaTrackingTab = ({
  organizationRestrictions,
  values,
  setFieldValue,
}) => {
  const classes = useStyles();
  const {
    shoutouts,
    banners,
    links,
    chatMentionKeywords,
    socialMediaKeywords,
    mediaTrakinkWarning,
  } = values;

  const trialClassName = classes.trialDisabled;

  const {
    ui_disable_shoutouts,
    ui_disable_banners,
    ui_banner_limit_per_campaign,
  } = organizationRestrictions;

  useEffect(() => {
    if (mediaTrakinkWarning === 'wait') {
      setFieldValue('mediaTrakinkWarning', false);
    } else if (mediaTrakinkWarning === false) {
      setFieldValue('mediaTrakinkWarning', true);
    }
  }, [shoutouts, banners, links, chatMentionKeywords, socialMediaKeywords]);

  return (
    <>
      {mediaTrakinkWarning && (
        <Alert severity="warning">
          Please note that the process for analyzing newly added media items
          might take up to 15 minutes.
        </Alert>
      )}
      <div className={classes.container}>
        <div className={classes.leftColumn}>
          <Shoutouts
            trialClassName={trialClassName}
            disabled={ui_disable_shoutouts}
          />
          <ChatMentions />
          <SocialMediaMentions />
        </div>
        <div className={classes.rightColumn}>
          <Banners
            trialClassName={trialClassName}
            disabled={ui_disable_banners}
            limit={
              !!ui_banner_limit_per_campaign
                ? ui_banner_limit_per_campaign
                : Infinity
            }
          />
          <Links />
        </div>
      </div>
    </>
  );
};

export default MediaTrackingTab;
