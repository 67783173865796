import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import {
  CREATE_WATCHLIST_REQUESTED,
  DELETE_WATCHLIST_REQUESTED,
  RENAME_WATCHLIST_REQUESTED,
} from '../action-types';
import {
  createWatchlistFailed,
  createWatchlistSucceeded,
  deleteWatchlistFailed,
  deleteWatchlistSucceeded,
  renameWatchlistFailed,
  renameWatchlistSucceeded,
} from '../models/watchlists/watchlists.actions';
import {
  getWatchlistById,
  getWatchlistIndexById,
} from '../models/watchlists/watchlists.selectors';

function* renameWatchlist({ payload: { name, id } }) {
  const oldWatchlist = yield select(getWatchlistById(id));

  try {
    yield put(renameWatchlistSucceeded({ name, id }));

    yield call(ajax.renameWatchlist, { name, id });
  } catch (error) {
    yield put(renameWatchlistFailed({ error, name: oldWatchlist.name, id }));
  }
}

function* deleteWatchlist({ payload: { id } }) {
  const oldWatchlist = yield select(getWatchlistById(id));
  const oldWatchlistIndex = yield select(getWatchlistIndexById(id));

  try {
    yield put(deleteWatchlistSucceeded({ id }));

    yield call(ajax.deleteWatchlist, { id });
  } catch (error) {
    yield put(
      deleteWatchlistFailed({
        error,
        watchlist: oldWatchlist,
        index: oldWatchlistIndex,
      })
    );
  }
}

function* createWatchlist({ payload: { name } }) {
  try {
    const { watchlist } = yield call(ajax.createWatchlist, { name });

    yield put(createWatchlistSucceeded({ watchlist }));
  } catch (error) {
    yield put(createWatchlistFailed({ error }));
  }
}

export default function* root() {
  yield takeEvery(RENAME_WATCHLIST_REQUESTED, renameWatchlist);
  yield takeEvery(DELETE_WATCHLIST_REQUESTED, deleteWatchlist);
  yield takeEvery(CREATE_WATCHLIST_REQUESTED, createWatchlist);
}
