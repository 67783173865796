import { push } from 'connected-react-router';
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  CAMPAIGN_CREATION_SUCCEEDED,
  CAMPAIGN_UPDATE_SUCCEEDED,
} from '../action-types';

function* redirectToCampaign(action) {
  yield put(push(`/campaigns/${action.payload.campaign.id}`));
}

export default function* root() {
  yield all([
    takeLatest(CAMPAIGN_CREATION_SUCCEEDED, redirectToCampaign),
    takeLatest(CAMPAIGN_UPDATE_SUCCEEDED, redirectToCampaign),
  ]);
}
