import { Box, CircularProgress, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SerializedError } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';

import CustomizedMenus from '../../../components/CustomizedMenus';
import mixpanelTrack from '../../../utils/mixpanel-track';
import { triggerDownload as downloadExcel } from '../DownloadCampaign';
import { useAllDeliverables } from '../hooks/useAllDeliverables';
import { Campaign, TimeFrame } from '../types';
import { fixTimeFrameIfEmpty, isValidTimeFrame } from '../utils/timeFrameUtils';

interface DownloadButtonDumbProps {
  loading: boolean;
  invalidTimeFrame: boolean;
  onDownloadPdf: () => void;
  onDownloadExcel: () => void;
}

const useStyles = makeStyles(() => ({ error: { color: '#f00' } }));

const DownloadButtonDumb = ({
  loading,
  invalidTimeFrame,
  onDownloadPdf,
  onDownloadExcel,
}: DownloadButtonDumbProps) => {
  const onButtonClick = (key: 'pdf' | 'excel') => {
    if (key === 'pdf') return onDownloadPdf();

    onDownloadExcel();
  };

  return (
    //@ts-ignore until we type CustomizedMenu
    <CustomizedMenus
      buttonText={'Download'}
      buttonIcon={
        <Box clone mr={1}>
          {loading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            <Icon>get_app</Icon>
          )}
        </Box>
      }
      variant="outlined"
      disabled={loading || invalidTimeFrame}
      onClick={onButtonClick}
      options={[
        {
          text: 'Portable document format (.pdf)',
          value: 'pdf',
        },
        {
          text: 'Microsoft Excel (.xlsx)',
          value: 'xlsx',
        },
      ]}
    />
  );
};

interface DownloadbuttonProps {
  campaign: Campaign;
  timeFrame: Partial<TimeFrame>;
  campaignStartTime: Moment;
  campaignEndTime: Moment;
}

export const DownloadButton = ({
  campaign: slimCampaign,
  timeFrame,
  campaignStartTime,
  campaignEndTime,
}: DownloadbuttonProps) => {
  const classes = useStyles();

  const [downloadRequested, setDownloadRequested] = useState<
    false | 'excel' | 'pdf'
  >(false);

  const [showLoadingGenerating, setShowLoadingGenerating] =
    useState<boolean>(false);

  const [generationError, setGenerationError] =
    useState<null | SerializedError>(null);

  const {
    showLoading: showLoadingData,
    shoutoutDeliverables,
    bannerDeliverables,
    linkTrackingDeliverables,
    chatMentionDeliverables,
    twitterMentionDeliverables,
    instagramMentionDeliverables,
    tikTokMentionDeliverables,
    facebookMentionDeliverables,
    socialPerformanceSummary,
    hasError: hasDataFetchingError,
  } = useAllDeliverables(slimCampaign, timeFrame, downloadRequested !== false);

  const filledTimeFrame = fixTimeFrameIfEmpty(
    timeFrame,
    campaignStartTime,
    campaignEndTime
  );

  const invalidTimeFrame = !isValidTimeFrame(filledTimeFrame);

  const createPDFDocument = useCallback(
    async (campaign) => {
      const fileName = `${campaign.name.replace(/ /g, '_')}_Aggero_report.pdf`;

      import('@react-pdf/renderer')
        .then(async ({ pdf }) => {
          import('./PDFReport')
            .then(async ({ PDFReport }) => {
              const document = (
                <PDFReport campaign={campaign} timeFrame={filledTimeFrame} />
              );

              const blob = await pdf(document).toBlob();

              saveAs(blob, fileName);
            })
            .catch((err) => console.error('PDFReport.tsx', err));
        })
        .catch((error) => console.error('@react-pdf/renderer', error));
    },
    [filledTimeFrame]
  );

  useEffect(() => {
    (async () => {
      if (showLoadingData || downloadRequested === false) return;
      setDownloadRequested(false);

      const campaign = {
        ...slimCampaign,

        shoutout_deliverables: shoutoutDeliverables,
        banner_deliverables: bannerDeliverables,
        link_tracking_deliverables: linkTrackingDeliverables,
        twitter_keyword_mention_deliverables: twitterMentionDeliverables,
        instagram_keyword_mention_deliverables: instagramMentionDeliverables,
        tiktok_keyword_mention_deliverables: tikTokMentionDeliverables,
        facebook_keyword_mention_deliverables: facebookMentionDeliverables,
        keyword_mention_deliverables: chatMentionDeliverables,
        social_media_performances_summary: socialPerformanceSummary,
      };

      try {
        if (downloadRequested === 'excel') {
          downloadExcel(campaign)();
          mixpanelTrack('XLSX Report Generated');
        } else {
          createPDFDocument(campaign);
          mixpanelTrack('PDF Report Generated');
        }
      } catch (error) {
        console.error(error);
        setGenerationError(error);
      } finally {
        setShowLoadingGenerating(false);
      }
    })();
  }, [
    bannerDeliverables,
    chatMentionDeliverables,
    downloadRequested,
    instagramMentionDeliverables,
    tikTokMentionDeliverables,
    facebookMentionDeliverables,
    linkTrackingDeliverables,
    shoutoutDeliverables,
    slimCampaign,
    twitterMentionDeliverables,
    filledTimeFrame,
    showLoadingData,
    createPDFDocument,
    socialPerformanceSummary,
  ]);

  const onDownloadPdf = useCallback(() => {
    setShowLoadingGenerating(true);
    setDownloadRequested('pdf');
  }, []);

  const onDownloadExcel = useCallback(() => {
    setShowLoadingGenerating(true);
    setDownloadRequested('excel');
  }, []);

  if (generationError != null) {
    return <p className={classes.error}>Failed to generate report</p>;
  }

  if (hasDataFetchingError) {
    return <p className={classes.error}>Failed to fetch report data</p>;
  }

  return (
    <DownloadButtonDumb
      loading={showLoadingGenerating}
      invalidTimeFrame={invalidTimeFrame}
      onDownloadPdf={onDownloadPdf}
      onDownloadExcel={onDownloadExcel}
    />
  );
};
