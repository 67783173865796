import { useState } from 'react';

function useInputValue(initialValue) {
  const [inputValue, setInputValue] = useState(initialValue);

  function handleChange({ target: { value } }) {
    setInputValue(value);
  }

  return {
    handleChange,
    set: setInputValue,
    value: inputValue,
  };
}

export default useInputValue;
