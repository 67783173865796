import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    fontSize: '1.8rem',
  },
});

function TopBarTitle(props) {
  const classes = useStyles();

  return <Typography variant="h1" className={classes.root} {...props} />;
}

export default TopBarTitle;
