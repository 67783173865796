import {
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import React, { useState } from 'react';

import FileInput from '../../../../components/FileInput';
import FormikMuiTextField from '../../../../components/FormikMuiTextField';
import ImagePreview from '../../../../components/ImagePreview';
import SideLine from '../../../../components/Sideline';
import readFileAsDataURL from '../../../../utils/readFileAsDataURL';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  bannersContainer: {},
  banner: { margin: theme.spacing(0.24, 0) },
  label: {
    fontSize: '24px',
    fontWeight: 'bold',
    paddingLeft: 0,
  },
}));

export const Banners = ({ disabled, limit, isViewOnly, id }) => {
  const classes = useStyles();
  const { values, setFieldValue, errors } = useFormikContext();
  const [wrongFileType, setWrongFileType] = useState(false);
  function handleFileChange(arrayHelpers) {
    return async function (file) {
      arrayHelpers.push({ bannerFile: await readFileAsDataURL(file) });
    };
  }

  const onAdd = (arrayHelpers) => {
    if (!values.newBannerUrl.trim()) return;
    if (!/(jpg|jpeg|png|bmp)/.test(values.newBannerUrl)) {
      setWrongFileType(true);
    } else {
      arrayHelpers.push({
        bannerUrl: values.newBannerUrl.trim(),
      });
      setFieldValue('newBannerUrl', '');
      setWrongFileType(false);
    }
  };
  return (
    <Card>
      <CardContent>
        <Typography className={classes.label} variant="h2">
          Image tracking
        </Typography>
      </CardContent>
      <FieldArray
        name="banners"
        render={(arrayHelpers) => (
          <>
            <CardContent className={classes.bannersContainer}>
              {values.banners.map(({ bannerUrl, bannerFile }, index) => (
                <div
                  className={classes.banner}
                  key={index}
                  data-id={`preview-banner-${id}-${index}`}
                >
                  <ImagePreview
                    src={bannerUrl || bannerFile}
                    onDelete={
                      isViewOnly
                        ? undefined
                        : () => {
                            arrayHelpers.remove(index);
                          }
                    }
                  />
                  {errors && errors.banners && errors.banners[index] ? (
                    <p style={{ color: 'red' }}>{errors.banners[index]}</p>
                  ) : null}
                </div>
              ))}
            </CardContent>

            {values.banners.length < limit && !disabled && !isViewOnly && (
              <>
                <CardContent>
                  <FormLabel>Image URL</FormLabel>
                  <div className={classes.inputContainer}>
                    <FormikMuiTextField
                      data-id={`input-banners-${id}`}
                      fullWidth
                      placeholder="Enter the URL of the banner image"
                      variant="outlined"
                      value={values.newBannerUrl}
                      name="newBannerUrl"
                      className={classes.input}
                      inputProps={{
                        onKeyDown: (e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                            onAdd(arrayHelpers);
                          }
                        },
                      }}
                    />
                    <Button
                      data-id={`button-banners-${id}`}
                      size="large"
                      variant="outlined"
                      color="primary"
                      style={{ padding: 9.5 }}
                      disabled={!values.newBannerUrl}
                      onClick={() => onAdd(arrayHelpers)}
                    >
                      Add
                    </Button>
                  </div>
                </CardContent>
                <CardContent>
                  <SideLine inset>
                    <Box fontSize="1rem">or</Box>
                  </SideLine>
                </CardContent>
                <CardContent>
                  <FormLabel style={{ width: '100%' }}>
                    <FileInput
                      setWrongFileType={setWrongFileType}
                      hidden
                      accept="image/*"
                      onChange={(...args) => {
                        handleFileChange(arrayHelpers)(...args);
                      }}
                    />
                    {wrongFileType && (
                      <p style={{ color: 'red' }}>
                        Image format must be png, jpg or bmp
                      </p>
                    )}
                    <Button
                      fullWidth
                      size="large"
                      variant="outlined"
                      color="primary"
                      component="span"
                      style={{ fontSize: 12 }}
                    >
                      select an image from your computer
                    </Button>
                  </FormLabel>
                </CardContent>
              </>
            )}
          </>
        )}
      />
    </Card>
  );
};

export default Banners;
