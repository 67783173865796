export default {
  network: 'Network',
  influencer_sizes: 'Size',
  influencer_game_title_or_sector: 'Game Title',
  streamer_language: 'Language',
  country: 'Country',
  streamer_talking_about: 'Talking About',
  streamer_interest_categories: 'Interest Categories',
  game_categories: 'Game Categories',
  min_ccv: 'Min Avg. CCV (30d)',
  max_ccv: 'Max Avg. CCV (30d)',
  min_avg_view_count_per_video: 'Min Views / Video',
  max_avg_view_count_per_video: 'Max Views / Video',
  audience_age_groups: 'Audience Age Group',
  audience_gender: 'Audience Gender',
  audience_country: 'Audience Location',
  audience_talking_about: 'Audience Talking About',
  audience_interest_keywords: 'Audience Interest',
  audience_language: 'Audience Language',
  audience_interest_categories: 'Audience Interest Categories',
  campaign_goal: 'Campaign Goal',
};
