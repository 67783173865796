import { connect } from 'react-redux';
import { compose } from 'recompose';

import mapSelectorsToProps from '../../helpers/map-selectors-to-props';
import { selectCampaignsByOrganizationId } from '../../store/models/campaigns/campaigns.selectors';
import { selectCommunication } from '../../store/models/communication/communication.selectors';

export default compose(
  connect(
    mapSelectorsToProps({
      campaigns: selectCampaignsByOrganizationId,
      campaignsCommunication: selectCommunication('campaigns'),
    })
  )
);
