import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { InputField } from '../../../../common/components';

export const ForgotForm = ({ pristine, authInProgress, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        placeholder="required"
        name="email"
        type="email"
        component={InputField}
        label="Email"
      />
      <div className="form-group">
        <div className="peers ai-c jc-sb fxw-nw">
          <div className="peer">
            <button
              className="btn btn-primary"
              disabled={pristine || authInProgress}
            >
              {authInProgress ? 'Submitting' : 'Send reset link to my email'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

ForgotForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  authInProgress: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
