import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import {
  AddBox as AddBoxIcon,
  PersonOutline as PersonOutlineIcon,
} from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import * as paths from '../common/constants/paths';
import {
  deleteWatchlistRequested,
  renameWatchlistRequested,
} from '../store/models/watchlists/watchlists.actions';
import { getWatchlists } from '../store/models/watchlists/watchlists.selectors';
import CreateWatchlistDialog, {
  useCreateWatchlistDialog,
} from './CreateWatchlistDialog';
import WatchlistItem from './WatchlistItem';

const WatchlistMenuItems = ({ iconClassName }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const { loaded, watchlists } = useSelector(getWatchlists());

  const [showWatchlists, setShowWatchlists] = useState(true);
  const [hovered, setHovered] = useState(false);

  const toggleShowWatchlists = () => setShowWatchlists(!showWatchlists);

  const {
    onCreate,
    createWatchlistLoading,
    createDialogOpen,
    setCreateDialogOpen,
  } = useCreateWatchlistDialog();

  const onRename = (id) => (name) => {
    dispatch(renameWatchlistRequested({ name, id }));
  };

  const onDelete = (id) => () => {
    dispatch(deleteWatchlistRequested({ id }));

    if (pathname === `${paths.MY_WATCHLIST}/${id}`) {
      const filteredWatchlists = watchlists.filter(
        (watchlist) => watchlist.id !== id
      );

      if (filteredWatchlists.length > 0)
        history.replace(`${paths.MY_WATCHLIST}/${filteredWatchlists[0].id}`);
      else history.replace('/');
    }
  };

  return (
    <>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <ListItem button onClick={toggleShowWatchlists}>
          <ListItemIcon className={iconClassName}>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="My watchlists" />
          <ListItemSecondaryAction>
            {hovered && (
              <IconButton
                edge="end"
                size="small"
                onClick={() => setCreateDialogOpen(true)}
                data-id="myWatchlist-add-button"
              >
                <AddBoxIcon size="small" data-id="myWatchlist-add-icon" />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </div>
      <CreateWatchlistDialog
        open={createDialogOpen}
        onCancel={() => !createWatchlistLoading && setCreateDialogOpen(false)}
        onCreate={onCreate}
        loading={createWatchlistLoading}
      />
      {showWatchlists &&
        loaded &&
        watchlists.map((watchlist) => (
          <WatchlistItem
            watchlistsLength={watchlists.length}
            watchlist={watchlist}
            key={watchlist.id}
            onRename={onRename(watchlist.id)}
            onDelete={onDelete(watchlist.id)}
            id={watchlist.id}
          />
        ))}
    </>
  );
};

export default WatchlistMenuItems;
