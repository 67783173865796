import { useEffect } from 'react';

import usePrevious from './use-previous';
import useRouter from './use-router';

export default function useRouteChange(onRouteChange) {
  const router = useRouter();

  const {
    location: { pathname },
  } = router;

  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    if (pathname !== previousPathname) {
      onRouteChange(router);
    }
  }, [pathname, previousPathname]);
}
