import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import TwitchIcon from '../assets-2.0/twitch.svg';

const useStyles = makeStyles({
  text: {
    color: '#6441A5',
    fontSize: 16,
    display: 'inline-block',
  },
  iconImage: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
});

function TwitchFlag(props) {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.text}>
      <img src={TwitchIcon} className={classes.iconImage} alt="Twitch icon" />
      Twitch
    </Typography>
  );
}

export default TwitchFlag;
