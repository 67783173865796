import qs from 'query-string';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import {
  INFLUENCERS_SIGNUP,
  PATH_ROOT,
  PATH_SIGNIN,
} from '../../../../common/constants/paths';
import { history } from '../../../../store';
import { actions } from '../../../../store/actions';

const withDidMount = lifecycle({
  componentDidMount() {
    const {
      match: {
        params: { provider },
      },
      location: { hash, search },
    } = this.props;

    const toParse = !!hash ? hash.replace('#', '') : search;
    const query = qs.parse(toParse);

    const target = qs.parse(search)['t'];

    const accessToken = query['access_token'];
    const accessCode = query['code'];
    const oauthToken = query['oauth_token'];
    const oauthVerifier = query['oauth_verifier'];
    const code = query['code'];
    const scopes = query['scopes'];
    const state = query['state'];

    if (accessCode && provider === 'youtube') {
      this.props.dispatch(actions.signinYoutube(accessCode, target));
    } else if (accessToken && provider === 'twitch') {
      this.props.dispatch(actions.signinTwitch(accessToken));
    } else if (oauthToken && provider === 'twitter') {
      this.props.dispatch(actions.signinTwitter(oauthToken, oauthVerifier));
    } else if (code && scopes && state && provider === 'tiktok') {
      this.props.dispatch(actions.signinTiktok(code, scopes, state));
    } else {
      history.replace(PATH_SIGNIN);
    }
  },
});

const withDidUpdate = lifecycle({
  componentDidUpdate(prevProps) {
    if (this.props.authUser.email) {
      const {
        location: { search },
      } = this.props;

      let target = qs.parse(search)['t'] ?? PATH_ROOT;

      if (target.includes(INFLUENCERS_SIGNUP) && !!prevProps.authUser?.email) {
        // previously logged in means a platform was connected as opposed to simple sign in
        target = `${target}/social`;
      }

      history.replace(`${target}?socialCallback=true`);
    }
  },
});

const mapStateToProps = ({ account }) => ({
  authUser: account.authUser,
});

export default compose(connect(mapStateToProps), withDidMount, withDidUpdate);
