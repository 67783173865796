import { createAsyncThunk } from '@reduxjs/toolkit';

import { signupInfluencer } from '../../../../ajax';
import { getErrorMessagesFromResponse } from '../../../../common/ErrorMessageUtil';
import actions from '../../../../store/actions';
import { SLICE_NAME } from './types';

export interface SignupInfluencerWithPasswordPayload {
  email: string;
  password: string;
  // TODO: use configureStore in order to be able to use dispatch(...).unwrap()
  onSuccess: () => void;
}

export const signupInfluencerWithPassword = createAsyncThunk<
  any,
  SignupInfluencerWithPasswordPayload
>(
  `${SLICE_NAME}/signupWithPassword`,
  async ({ email, password, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const res = await signupInfluencer(email, password);

      dispatch(
        // @ts-ignore actions are imported using require(glob); this is very dumb and breaks ts
        actions.userSignedIn({
          ...res.user,
          displayName: res.user.name,
        })
      );
      dispatch(
        // @ts-ignore actions are imported using require(glob); this is very dumb and breaks ts
        actions.setLoggedInViewInfluencer()
      );
      onSuccess();
      return res;
    } catch (err) {
      return rejectWithValue(getErrorMessagesFromResponse(err));
    }
  }
);
