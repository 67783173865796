import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import * as paths from '../../common/constants/paths';

export const CampaignSettingsMenu = ({ onDelete, campaignId }) => {
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);

  const onDeleteClick = () => {
    setSettingsMenuAnchor(null);
    onDelete();
  };

  return (
    <>
      <Tooltip title="Settings">
        <IconButton onClick={(e) => setSettingsMenuAnchor(e.target)}>
          <Icon>settings</Icon>
        </IconButton>
      </Tooltip>
      <Menu
        open={!!settingsMenuAnchor}
        onClose={() => setSettingsMenuAnchor(null)}
        anchorEl={settingsMenuAnchor}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        <Link to={`${paths.CAMPAIGNS}/${campaignId}/edit`}>
          <MenuItem style={{ color: '#000' }}>
            <Box clone mr={1}>
              <Icon>edit</Icon>
            </Box>
            Edit
          </MenuItem>
        </Link>
        <Link to={`${paths.TRACK_CAMPAIGN}/basic?clone=${campaignId}`}>
          <MenuItem style={{ color: '#000' }}>
            <Box clone mr={1}>
              <Icon>file_copy</Icon>
            </Box>
            Clone
          </MenuItem>
        </Link>
        <MenuItem onClick={onDeleteClick}>
          <Box clone mr={1}>
            <Icon>delete_outline</Icon>
          </Box>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default CampaignSettingsMenu;
