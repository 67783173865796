import {
  Button,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  withStyles,
} from '@material-ui/core';
import { AddBox as AddBoxIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CreateWatchlistDialog, {
  useCreateWatchlistDialog,
} from '../../../components/CreateWatchlistDialog';
import { getWatchlistsList } from '../../../store/models/watchlists/watchlists.selectors';
import actions from '../redux';
import { getInfluencerWatchlists } from '../selectors';

const StyledMenu = withStyles({
  paper: {
    width: '175px',
    padding: 0,
  },
})(Menu);

const WatchlistDropdown = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    onCreate,
    createWatchlistLoading,
    createDialogOpen,
    setCreateDialogOpen,
  } = useCreateWatchlistDialog();
  const selectedWatchlists = new Set(useSelector(getInfluencerWatchlists));
  const watchlists = useSelector(getWatchlistsList()).map((watchlist) => {
    watchlist.selected = selectedWatchlists.has(watchlist.id);

    return watchlist;
  });
  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onAddToWatchlist = (id) => () => {
    dispatch(actions.addToWatchlist(id));
  };

  const onRemoveFromWatchlist = (id) => () => {
    dispatch(actions.removeFromWatchlist(id));
  };

  return (
    <>
      <CreateWatchlistDialog
        setFollow={true}
        open={createDialogOpen}
        onCancel={() => !createWatchlistLoading && setCreateDialogOpen(false)}
        onCreate={onCreate}
        loading={createWatchlistLoading}
      />
      <Button
        type="button"
        variant="outlined"
        color={'primary'}
        style={{ float: 'right' }}
        onClick={handleOpen}
      >
        + Add to Watchlist
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {watchlists.map((watchlist) => (
          <ListItem
            button
            dense
            key={watchlist.id}
            onClick={
              watchlist.selected
                ? onRemoveFromWatchlist(watchlist.id)
                : onAddToWatchlist(watchlist.id)
            }
          >
            <ListItemIcon>
              <Checkbox
                color="primary"
                edge="start"
                disableRipple
                checked={watchlist.selected}
              />
            </ListItemIcon>
            <ListItemText>{watchlist.name}</ListItemText>
          </ListItem>
        ))}
        <ListItem button onClick={() => setCreateDialogOpen(true)}>
          <ListItemIcon>
            <AddBoxIcon size="small" />
          </ListItemIcon>
          <ListItemText>New watchlist</ListItemText>
        </ListItem>
      </StyledMenu>
    </>
  );
};

export default WatchlistDropdown;
