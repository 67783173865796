import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import moment from 'moment';
import momentTz from 'moment-timezone';
import qs from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { default as MegaphoneSvg } from '../../assets-2.0/megaphone';
import * as paths from '../../common/constants/paths';
import Container from '../../components/Container';
import useRouter from '../../hooks/use-router';
import {
  campaignDeleteClicked,
  campaignMounted,
  campaignSocialPostDelete,
  campaignVideoDeleteClicked,
} from '../../store/events';
import { selectCommunication } from '../../store/models/communication/communication.selectors';
import {
  getIsViewOnly,
  isAggeroUser,
} from '../../store/models/user/user.selectors';
import { stopMountedCampaign } from '../../store/sagas-folder/campaign-mounted';
import { CampaignAddVideosDialog } from './CampaignAddVideosDialog';
import CampaignBrandFilter from './CampaignBrandFilter';
import CampaignDateFilter from './CampaignDateFilter';
import CampaignDeleteConfirmation from './CampaignDeleteConfirmation';
import { CampaignInfluencersFilter } from './CampaignInfluencersFilter';
import { CampaignLanguageFilter } from './CampaignLanguageFilter';
import CampaignPlatformFilter from './CampaignPlatformFilter';
import CampaignProgress from './CampaignProgress';
import CampaignSettingsMenu from './CampaignSettingsMenu';
import { CampaignShoutoutsFilter } from './CampaignShoutoutsFilter';
import CampaignVideoDeleteConfirmation from './CampaignVideoDeleteConfirmation';
import DeliverablesTab from './DeliverablesTab';
import OverviewTab from './OverviewTab';
import { DownloadButton as DownloadReportV2 } from './PDFReportV2/DownloadButton';
import PerformanceTab from './PerformanceTab';
import { FiltersDrawer } from './TopMomentsTab/FiltersDrawer';
import { Insights } from './TopMomentsTab/Insights';
import {
  campaignHasTopMoments,
  clearFlowiseChatsLocalStorage,
  getCampaignUsedLanguages,
} from './utils';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 16,
    height: 'calc(100vh - 200px)',
    maxHeight: 'max-content',
    minHeight: 'auto',
  },
  button: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    background: 'none',
    fontSize: 16,
    color: 'black',
    textTransform: 'uppercase',
    border: 'none',
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  influencerSelect: {
    padding: 4,
    background: 'white',
  },
  tabs: {
    color: '#333',
    borderTop: '1px solid #e4e4e4',
    borderBottom: '1px solid #e4e4e4',
  },
  menuDiv: {
    display: 'inline-block',
  },
  creatorLabel: {
    fontSize: 16,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
  },
  selectDiv: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24,
  },
  infMenuItem: {
    width: 19,
    height: 19,
    float: 'left',
    marginRight: 10,
  },
  reportContainer: {
    width: '75vw',
    height: '75vh',
  },
}));

function Campaign(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isFilteredByCreator, setByCreator] = useState(true);
  const [idUser, setIdUser] = useState('');
  const [numberPage, setNumberPage] = useState(0);
  const { campaign, match } = props;
  const {
    match: {
      params: { campaignId, tab },
    },
  } = useRouter();
  const location = useLocation();
  const history = useHistory();
  const { succeeded: campaignSucceeded } = useSelector(
    selectCommunication(`campaign-${campaignId}`)
  );

  const isViewOnly = useSelector(getIsViewOnly);

  useEffect(function () {
    if (!campaignSucceeded) {
      dispatch(campaignMounted({ campaignId: match.params.campaignId }));
      return () => {
        stopMountedCampaign();
      };
    }
  }, []);

  useEffect(() => {
    clearFlowiseChatsLocalStorage();
  }, [campaignId]);

  if (campaign) {
    document.title = campaign.name;
  } else {
    document.title = 'Tracking';
  }

  const selectedCampaignInfluencer =
    parseInt(qs.parse(location.search).i) || 'all';
  const setSelectedCampaignInfluencer = (id) => {
    if (id === 'all') {
      if (idUser) setIdUser('');
      return history.push(`${location.pathname}`);
    }
    setIdUser(id);
    history.push(`${location.pathname}?i=${id}`);
  };

  const MainActiveTab = tab || 'overview';
  const [activeTab, setActiveTab] = useState();
  const [isHideFilters, setHideFilters] = useState(false);

  const setMainActiveTab = (tab) => {
    if (tab === 'overview')
      return history.push(`${paths.CAMPAIGNS}/${campaignId}`);

    return history.push(
      `${paths.CAMPAIGNS}/${campaignId}/${tab}${idUser && `?i=${idUser}`}`
    );
  };

  const [activePoint, setActivePoint] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [campaignFilterStart, setCampaignFilterStart] = useState(null);
  const [campaignFilterEnd, setCampaignFilterEnd] = useState(null);
  const [campaignDeleteOpen, setCampaignDeleteOpen] = useState(false);
  const [campaignVideoDeleteId, setCampaignVideoDeleteId] = useState(null);
  const [socialPerformanceIdDelete, setSocialPerformanceIdDelete] = useState(
    []
  );

  const [socialPostDelete, setSocialPostDelete] = useState(null);

  const [platformFilter, setPlatformFilter] = useState('all');
  const [brandFilter, setBrandFilter] = useState('all');
  const [showSocialPlatforms, setShowSocialPlatformsInternal] = useState(false);
  const [showBrand, setBrandInternal] = useState(false);
  const [multiple, isMultiple] = useState(false);
  const [languageFilter, setLanguageFilter] = useState([]);

  const {
    time_zone,
    campaign_days,
    all_shoutout_deliverables,
    social_media_mention_deliverables,
    keyword_mention_deliverables,
    link_tracking_deliverables,
    banner_deliverables,
    campaign_influencers = [],
  } = campaign || {};
  const campaignInfluencersWStreaming = campaign_influencers.filter(
    ({ twitch_external_id, youtube_external_id, facebook_external_id }) =>
      twitch_external_id || youtube_external_id || facebook_external_id
  );

  const timeZone = time_zone || 'UTC';
  const newDays = campaign_days && campaign_days.length && campaign_days.sort();
  const campaignStartTime =
    newDays &&
    newDays.length &&
    momentTz.tz(newDays[0], timeZone).startOf('day');
  const campaignEndTime =
    newDays &&
    newDays.length &&
    momentTz.tz(newDays[newDays.length - 1], timeZone).endOf('day');

  useEffect(() => {
    setCampaignFilterStart(campaignStartTime);
    setCampaignFilterEnd(campaignEndTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign && campaign.id]);

  const aggeroUser = useSelector(isAggeroUser);

  const tabs = [
    {
      key: 'overview',
      title: 'Overview',
      display: true,
      hasInfluencersDropdown: false,
      hasAllInfluencersOption: true,
    },
    {
      key: 'performance',
      title: 'Performance',
      display:
        // Show performance section for aggero accounts; for monitoring purposes
        (campaign?.processed &&
          (aggeroUser ||
            campaign?.campaign_types?.find(function (el, index) {
              if (el.campaign_type === 'performance') {
                return true;
              } else {
                return false;
              }
            }))) ||
        campaign?.campaign_types?.length === 0,

      hasInfluencersDropdown: true,
      hasAllInfluencersOption: true,
      hasAllPlatfromFilter: true,
      hasLanguageFilter: true,
    },
    {
      key: 'sponsorship',
      title: 'Sponsorship Valuation',
      display: campaign?.campaign_types?.find(function (el, index) {
        if (
          el.campaign_type === 'sponsorship_valuation' &&
          (all_shoutout_deliverables.filter((el) => el.campaign_brand_id)
            ?.length !== 0 ||
            social_media_mention_deliverables.filter(
              (el) => el.campaign_brand_id
            )?.length !== 0 ||
            keyword_mention_deliverables.filter((el) => el.campaign_brand_id)
              ?.length !== 0 ||
            banner_deliverables.filter((el) => el.campaign_brand_id)?.length !==
              0 ||
            link_tracking_deliverables.filter((el) => el.campaign_brand_id)
              ?.length !== 0)
        ) {
          return true;
        } else {
          return false;
        }
      }),
      hasInfluencersDropdown: true,
      hasAllInfluencersOption: true,
      hasAllPlatfromFilter: true,
      hasAllBrandFilter: false,
      hasNewBrandFilter: true,
    },
    {
      key: 'deliverables',
      title: 'Media Tracking',
      display:
        campaign?.campaign_types?.find(function (el, index) {
          if (
            el.campaign_type === 'media_tracking' &&
            (all_shoutout_deliverables.filter((el) => !el.campaign_brand_id)
              ?.length !== 0 ||
              social_media_mention_deliverables.filter(
                (el) => !el.campaign_brand_id
              )?.length !== 0 ||
              keyword_mention_deliverables.filter((el) => !el.campaign_brand_id)
                ?.length !== 0 ||
              banner_deliverables.filter((el) => !el.campaign_brand_id)
                ?.length !== 0 ||
              link_tracking_deliverables.filter((el) => !el.campaign_brand_id)
                ?.length !== 0)
          ) {
            return true;
          } else {
            return false;
          }
        }) || campaign?.campaign_types?.length === 0,
      hasInfluencersDropdown: true,
      hasAllInfluencersOption: true,
      hasAllPlatfromFilter: true,
    },
    {
      key: 'insights',
      title: 'Insights',
      display: campaignHasTopMoments(campaign),
      hasInfluencersDropdown: false,
      hasAllInfluencersOption: true,
      hasAllPlatfromFilter: false,
    },
  ];

  useEffect(() => {
    if (newDays && newDays.length)
      moment(newDays[newDays.length - 1]).diff(moment(newDays[0]), 'days') +
        1 ===
      newDays.length
        ? isMultiple(false)
        : isMultiple(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_days]);

  const setShowSocialPlatforms = useCallback(
    (value) => {
      if (value === showSocialPlatforms) return;

      setPlatformFilter('all');

      setShowSocialPlatformsInternal(value);
    },
    [showSocialPlatforms, setPlatformFilter, setShowSocialPlatformsInternal]
  );

  const setShowBrand = useCallback(
    (value) => {
      if (value === showBrand) return;

      setBrandFilter('all');

      setBrandInternal(value);
    },
    [showBrand, setBrandFilter, setBrandInternal]
  );

  const currentTab = tabs.find(({ key }) => key === MainActiveTab);
  function changeTab(key) {
    // If you change from one tab to another reset selected influencer
    // in case the currently selected is not available
    const newTab = tabs.find((e) => e.key === key);
    const newTabInfluencers =
      newTab.hasInfluencersDropdown && newTab.onlyStreamingInfluencers
        ? campaignInfluencersWStreaming
        : campaign_influencers;
    if (
      (selectedCampaignInfluencer !== 'all' &&
        !newTabInfluencers.find((e) => e.id === selectedCampaignInfluencer)) ||
      (!newTab.hasAllInfluencersOption && selectedCampaignInfluencer === 'all')
    ) {
      setSelectedCampaignInfluencer(newTabInfluencers[0].id);
    }

    if (!currentTab.hasAllInfluencersOption && newTab.hasAllInfluencersOption) {
      setSelectedCampaignInfluencer('all');
    }

    // Clear the active "sub-tab" when changing the main tab
    setActiveTab(null);

    setMainActiveTab(key);

    const elem = document.getElementsByClassName('main-container')[0];
    if (elem) {
      elem.scrollTo(0, 0);
    }
  }

  function hasDateFilter() {
    return (
      MainActiveTab === 'deliverables' ||
      MainActiveTab === 'performance' ||
      MainActiveTab === 'sponsorship'
    );
  }

  useEffect(() => {
    // the sponsorship tab summary section hides the filters, so we need to reset this when we switch from that tab
    if (MainActiveTab !== 'sponsorship') setHideFilters(false);
  }, [MainActiveTab]);

  function getCampaignTabs() {
    const currentTabInfluencers =
      currentTab.hasInfluencersDropdown && currentTab.onlyStreamingInfluencers
        ? campaignInfluencersWStreaming
        : campaign_influencers;

    const hideSocialPlatformsWithVideos =
      (MainActiveTab === 'performance') & !showSocialPlatforms ||
      activeTab === 'link-sharing' ||
      activeTab === 'chat-mentions';

    const hasShoutoutsFilter =
      (MainActiveTab === 'deliverables' || MainActiveTab === 'sponsorship') &&
      activeTab === 'shoutouts';

    const availableLanguages = getCampaignUsedLanguages(campaign);

    const renderFilters = () => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!isHideFilters &&
            campaign_influencers.length > 1 &&
            selectedCampaignInfluencer &&
            currentTab.hasInfluencersDropdown && (
              <>
                <div className={classes.selectDiv}>
                  <CampaignInfluencersFilter
                    campaignInfluencers={currentTabInfluencers}
                    value={selectedCampaignInfluencer}
                    onChange={(value) => setSelectedCampaignInfluencer(value)}
                  />
                </div>
              </>
            )}
          {!isHideFilters && currentTab.hasAllPlatfromFilter && (
            <div className={classes.selectDiv}>
              <CampaignPlatformFilter
                campaign={campaign}
                setPlatformFilter={setPlatformFilter}
                platformFilter={platformFilter}
                showSocialPlatforms={showSocialPlatforms}
                /* 
                There are social platform that have videos but we display them as social posts instead of videos. 
                 So we have to hide them from the filtering on the performance streaming tab .& other tabs
                 Only display them in the deliverables tabs 
                */
                hideSocialPlatformsWithVideos={hideSocialPlatformsWithVideos}
              />
            </div>
          )}
          {currentTab.hasNewBrandFilter && (
            <div className={classes.selectDiv}>
              <CampaignBrandFilter
                campaign={campaign}
                setBrandFilter={setBrandFilter}
                brandFilter={brandFilter}
                tab={activeTab}
              />
            </div>
          )}

          {hasShoutoutsFilter && (
            <div className={classes.selectDiv}>
              <CampaignShoutoutsFilter
                campaign={campaign}
                tab={MainActiveTab}
                brandFilter={brandFilter}
              />
            </div>
          )}

          {currentTab.hasLanguageFilter && (
            <div className={classes.selectDiv}>
              <CampaignLanguageFilter
                availableLanguages={availableLanguages}
                onChange={setLanguageFilter}
                values={languageFilter}
              />
            </div>
          )}
        </div>
      );
    };

    return (
      <Grid container alignItems="center" className={classes.tabs}>
        <Grid item style={{ flex: 1 }}>
          <div className={classes.menuDiv}>
            {tabs
              .filter(({ display }) => !!display)
              .map(({ key, title }, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => changeTab(key)}
                    className={clsx(classes.button, {
                      [classes.active]: MainActiveTab === key,
                    })}
                  >
                    {title}
                  </button>
                );
              })}
          </div>
        </Grid>
        {!isHideFilters && (
          <Grid item style={{ marginRight: 24 }}>
            <FiltersDrawer content={renderFilters()} />
          </Grid>
        )}
      </Grid>
    );
  }

  function handleDeleteCampaignConfirm() {
    dispatch(campaignDeleteClicked({ id: campaignId }));
    setCampaignDeleteOpen(false);
  }

  function handleDeleteVideoCampaignConfirm() {
    dispatch(
      campaignVideoDeleteClicked({ id: campaignVideoDeleteId, campaignId })
    );
    setCampaignVideoDeleteId(null);
  }

  function handleDeleteSocialPost() {
    const { id, platform, section, campaignId } = socialPostDelete;
    setSocialPerformanceIdDelete([...socialPerformanceIdDelete, id]);

    dispatch(
      campaignSocialPostDelete({
        [`${platform}_ids`]: id,
        postId: id,
        platform,
        section,
        campaignId,
      })
    );
    setSocialPostDelete(null);
  }

  if (!campaignSucceeded) {
    return (
      <Box width="100%" position="absolute" top={0} left={0} zIndex={1}>
        <LinearProgress variant="query" />
      </Box>
    );
  }
  function getTabComponent() {
    const campaignProps = {
      campaign,
      timeZone,
      campaignFilterStart,
      campaignFilterEnd,
      campaignStartTime,
      campaignEndTime,
      selectedCampaignInfluencer:
        campaign_influencers.find(
          ({ id }) => selectedCampaignInfluencer === id
        ) || 'all',
      setSelectedCampaignInfluencer,
      setCampaignVideoDeleteId,
      setSocialPostDelete,
      socialPostDelete,
      activePoint,
      setActivePoint,
      platformFilter,
      brandFilter,
      setPlatformFilter,
      showSocialPlatforms,
      isFilteredByCreator,
      setByCreator,
      setShowSocialPlatforms,
      setShowBrand,
      timeFrame: { start: campaignFilterStart, end: campaignFilterEnd },
      activeTab,
      setActiveTab,
      activeSubMenu,
      setActiveSubMenu,
      setSocialPerformanceIdDelete,
      socialPerformanceIdDelete,
      numberPage,
      setNumberPage,
      MainActiveTab,
      setHideFilters,
      languageFilter,
    };

    switch (MainActiveTab) {
      case 'overview': {
        return (
          <OverviewTab
            {...campaignProps}
            onChangeTab={changeTab}
            onSetActiveSubMenu={setActiveSubMenu}
          />
        );
      }
      case 'deliverables': {
        return <DeliverablesTab {...campaignProps} />;
      }
      case 'sponsorship': {
        return <DeliverablesTab {...campaignProps} isSponsorship={true} />;
      }
      case 'insights': {
        return <Insights {...campaignProps} />;
      }
      default: {
        return <PerformanceTab {...campaignProps} />;
      }
    }
  }
  return (
    <>
      <CampaignProgress campaign={campaign} />
      {campaignSucceeded && !campaign?.processed ? (
        <Alert style={{ margin: 25 }} severity="error">
          It looks like there's a problem on our side. Please try to refresh the
          page. If the problem persists contact Aggero Support.
        </Alert>
      ) : (
        <>
          <Grid container wrap="nowrap" direction="column">
            <Grid item style={{ width: '100%' }}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ padding: 16, background: '#F7F9FB' }}
              >
                <Grid item>
                  <Avatar style={{ background: '#e0d3ed' }}>
                    <MegaphoneSvg />
                  </Avatar>
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Typography variant="h2">
                    {campaign && campaign.name}
                  </Typography>
                  <Typography color="textSecondary" variant="h4">
                    {campaign && moment(newDays[0]).format('DD MMM - ')}
                    {campaign &&
                      moment(newDays[newDays.length - 1]).format(
                        'DD MMM, YYYY'
                      )}
                    {multiple && ` (${newDays.length} days)`}
                  </Typography>
                </Grid>
                <Grid item>
                  {hasDateFilter() && !isHideFilters && (
                    <CampaignDateFilter
                      campaignFilterStart={campaignFilterStart}
                      campaignFilterEnd={campaignFilterEnd}
                      setCampaignFilterStart={setCampaignFilterStart}
                      setCampaignFilterEnd={setCampaignFilterEnd}
                      campaignStartTime={campaignStartTime}
                      campaignEndTime={campaignEndTime}
                      style={{
                        div: { display: 'flex', alignItems: 'center' },
                        picker: { width: 180, height: 36 },
                      }}
                    />
                  )}
                </Grid>
                <Grid item>
                  <CampaignAddVideosDialog campaignId={campaignId} />
                </Grid>
                <Grid item>
                  <DownloadReportV2
                    campaign={campaign}
                    timeFrame={{
                      end: campaignFilterEnd,
                      start: campaignFilterStart,
                    }}
                    campaignStartTime={campaignStartTime}
                    campaignEndTime={campaignEndTime}
                  />
                </Grid>
                {!isViewOnly && (
                  <Grid item>
                    <CampaignSettingsMenu
                      campaignId={campaignId}
                      onDelete={() => setCampaignDeleteOpen(true)}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item style={{ width: '100%', background: '#f4f6f8' }}>
              {campaignSucceeded &&
                campaign &&
                selectedCampaignInfluencer &&
                getCampaignTabs()}
            </Grid>
          </Grid>
          <Container className={clsx(classes.container, 'main-container')}>
            {campaignSucceeded &&
              campaign &&
              selectedCampaignInfluencer &&
              getTabComponent()}
          </Container>

          <CampaignDeleteConfirmation
            isOpen={campaignDeleteOpen}
            handleClose={() => setCampaignDeleteOpen(false)}
            handleConfirm={handleDeleteCampaignConfirm}
          />
          <CampaignVideoDeleteConfirmation
            isOpen={!!campaignVideoDeleteId}
            handleClose={() => setCampaignVideoDeleteId(null)}
            handleConfirm={handleDeleteVideoCampaignConfirm}
          />
          <CampaignVideoDeleteConfirmation
            isOpen={!!socialPostDelete}
            handleClose={() => setSocialPostDelete(null)}
            handleConfirm={handleDeleteSocialPost}
            title="Delete Social Post"
            content="The action is not reversible. Are you sure you want to delete this social post?"
          />
        </>
      )}
    </>
  );
}

export default Campaign;
