import {
  Box,
  Button,
  Card,
  CardContent,
  FormLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';
import { FieldArray, useFormikContext } from 'formik';
import React, { useState } from 'react';

import * as regex from '../../../common/constants/regex';
import FileInput from '../../../components/FileInput';
import FormikMuiTextField from '../../../components/FormikMuiTextField';
import ImagePreview from '../../../components/ImagePreview';
import SideLine from '../../../components/Sideline';
import readFileAsDataURL from '../../../utils/readFileAsDataURL';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  bannersContainer: {},
  banner: { margin: theme.spacing(2, 0) },
}));

export const Banners = ({ disabled, limit, trialClassName, brandId }) => {
  const classes = useStyles();
  const [wrongFileType, setWrongFileType] = useState(false);

  const { values, setFieldValue, errors } = useFormikContext();

  function handleFileChange(arrayHelpers) {
    return async function (file) {
      arrayHelpers.push({
        bannerFile: await readFileAsDataURL(file),
        campaign_brand_id: brandId,
      });
    };
  }

  const onAdd = (arrayHelpers) => {
    if (!values.newBannerUrl.trim()) return;
    if (!regex.URL_IMAGE.test(values.newBannerUrl)) {
      setWrongFileType(true);
    } else {
      arrayHelpers.push({
        bannerUrl: values.newBannerUrl.trim(),
        campaign_brand_id: brandId,
      });
      setFieldValue('newBannerUrl', '');
      setWrongFileType(false);
    }
  };

  return (
    <Card className={cx(classes.container, { [trialClassName]: disabled })}>
      <CardContent>
        <Typography variant="h2">Image tracking</Typography>
      </CardContent>
      <FieldArray
        name="banners"
        render={(arrayHelpers) => (
          <>
            {brandId ? (
              <>
                <CardContent className={classes.bannersContainer}>
                  {values.banners.map(
                    ({ bannerUrl, bannerFile, campaign_brand_id }, index) => (
                      <>
                        {campaign_brand_id === brandId && (
                          <div className={classes.banner} key={index}>
                            <ImagePreview
                              src={bannerUrl || bannerFile}
                              onDelete={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            {errors &&
                            errors.banners &&
                            errors.banners[index] ? (
                              <p style={{ color: 'red' }}>
                                {errors.banners[index]}
                              </p>
                            ) : null}
                          </div>
                        )}
                      </>
                    )
                  )}
                </CardContent>
              </>
            ) : (
              <>
                <CardContent className={classes.bannersContainer}>
                  {values.banners.map(
                    ({ bannerUrl, bannerFile, campaign_brand_id }, index) => (
                      <>
                        {!campaign_brand_id && (
                          <div className={classes.banner} key={index}>
                            <ImagePreview
                              src={bannerUrl || bannerFile}
                              onDelete={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                            {errors &&
                            errors.banners &&
                            errors.banners[index] ? (
                              <p style={{ color: 'red' }}>
                                {errors.banners[index]}
                              </p>
                            ) : null}
                          </div>
                        )}
                      </>
                    )
                  )}
                </CardContent>
              </>
            )}

            {values.banners.length < limit && !disabled && (
              <>
                <CardContent>
                  <FormLabel>Image URL</FormLabel>
                  <div className={classes.inputContainer}>
                    <FormikMuiTextField
                      fullWidth
                      placeholder="Enter the URL of the banner image"
                      variant="outlined"
                      value={values.newBannerUrl}
                      name="newBannerUrl"
                      className={classes.input}
                      inputProps={{
                        onKeyDown: (e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                            onAdd(arrayHelpers);
                          }
                        },
                      }}
                    />
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      style={{ padding: 9.5 }}
                      disabled={!values.newBannerUrl}
                      onClick={() => onAdd(arrayHelpers)}
                    >
                      Add
                    </Button>
                  </div>
                </CardContent>
                <CardContent>
                  <SideLine inset>
                    <Box fontSize="1rem">or</Box>
                  </SideLine>
                </CardContent>
                <CardContent>
                  <FormLabel style={{ width: '100%' }}>
                    <FileInput
                      hidden
                      accept="image/*"
                      onChange={(...args) => {
                        handleFileChange(arrayHelpers)(...args);
                      }}
                      setWrongFileType={setWrongFileType}
                    />
                    {wrongFileType && (
                      <p style={{ color: 'red' }}>
                        Image format must be png, jpg or bmp
                      </p>
                    )}
                    <Button
                      fullWidth
                      size="large"
                      variant="outlined"
                      color="primary"
                      component="span"
                      style={{ fontSize: 12 }}
                    >
                      select an image from your computer
                    </Button>
                  </FormLabel>
                </CardContent>
              </>
            )}

            {values.banners.length >= limit && (
              <CardContent>
                <Typography className="fs-i" color="textSecondary">
                  You can add only {limit} image(s) for a campaign. Please
                  upgrade your plan if you want to track more images.
                </Typography>
              </CardContent>
            )}

            {!!disabled && (
              <CardContent>
                <Typography className="fs-i" color="textSecondary">
                  This feature is disabled for your account. Please contact
                  Aggero support to upgrade your plan.
                </Typography>
              </CardContent>
            )}
          </>
        )}
      />
    </Card>
  );
};

export default Banners;
