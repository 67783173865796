import { createSelector } from 'reselect';

import isNumber from '../../utils/is-number';
import largestRemainderRound from '../../utils/largest-remainder-round';
import { STORAGE_PREFIX as prefix } from './redux';

export const getInfluencerViewData = (state) => state[prefix];

export const getInfluencerName = (state) => state[prefix].name;

export const getvideoWordcloudData = (state) =>
  state[prefix].videoWordcloudData;

export const getInfluencer = (state) => state[prefix].influencer || {};

export const getInfluencerInternalId = createSelector(
  getInfluencer,
  ({ id }) => id
);

export const getInstagramUser = createSelector(
  getInfluencer,
  ({ instagram_user }) => instagram_user || {}
);

export const getFacebookPage = createSelector(
  getInfluencer,
  ({ facebook_page }) => facebook_page || {}
);

export const getInfluencerStreamer = createSelector(
  getInfluencer,
  ({ youtube_streamer, twitch_streamer }) => {
    return twitch_streamer && Object.keys(twitch_streamer).length > 0
      ? twitch_streamer
      : youtube_streamer || {};
  }
);

export const getInfluencerWatchlists = createSelector(
  getInfluencer,
  (state) => {
    return state.watchlists || [];
  }
);

export const getInfluencerTwitterProfile = createSelector(
  getInfluencer,
  ({ twitter }) => twitter || {}
);

export const getInfluencerYoutubeStreamer = createSelector(
  getInfluencer,
  ({ youtube_streamer }) => youtube_streamer || {}
);

export const getInfluencerTwitchStreamer = createSelector(
  getInfluencer,
  ({ twitch_streamer }) => twitch_streamer || {}
);

export const getStreamerTotalViews = createSelector(
  getInfluencerStreamer,
  ({ view_count }) => view_count
);

export const getStreamerTwitchFollowers = createSelector(
  getInfluencerTwitchStreamer,
  ({ total_followers }) => total_followers
);

export const getStreamerAudience = createSelector(
  getInfluencer,
  ({ youtube_streamer_audience, twitch_streamer_audience }) =>
    twitch_streamer_audience && Object.keys(twitch_streamer_audience).length > 0
      ? twitch_streamer_audience
      : youtube_streamer_audience || {}
);

export const getStreamerAudienceAges = createSelector(
  getStreamerAudience,
  ({ ages }) => ages || {}
);

export const getStreamerAudienceGenders = createSelector(
  getStreamerAudience,
  ({ genders }) => genders || {}
);

export const getStreamerAudienceLanguages = createSelector(
  getStreamerAudience,
  ({ languages }) => languages || {}
);

export const getStreamerAudienceLocations = createSelector(
  getStreamerAudience,
  ({ audience_location }) => audience_location || {}
);

export const getPlayedGames = createSelector(
  getInfluencer,
  ({ played_games }) => played_games || []
);

export const getInfluencerTopBrands = (state) => state[prefix].topBrands;

export const getInfluencerNetworkFilter = (state) => state[prefix].network;

export const getInfluencerProfileWordcloud = (state) =>
  state[prefix].influencerWordcloud;

export const getInfluencerTotalYoutubeSubscribers = createSelector(
  getInfluencerYoutubeStreamer,
  ({ subscriber_count }) => subscriber_count
);

export const getDateRangeFilter = (state) => state[prefix].dateRangeFilter;

export const getInfluencerActivity = createSelector(
  getInfluencerTwitchStreamer,
  getDateRangeFilter,
  (twitchStreamer, dateRangeFilter) => {
    let days = !dateRangeFilter ? 365 : dateRangeFilter / (1000 * 60 * 60 * 24);

    return {
      views_days: twitchStreamer[`views_days_${days}`],
      avg_viewers_days: twitchStreamer[`avg_viewers_days_${days}`],
      peak_viewers_days: twitchStreamer[`peak_viewers_days_${days}`],
      stream_time_days: twitchStreamer[`stream_time_days_${days}`],
      total_watch_time_days: twitchStreamer[`total_watch_time_days_${days}`],
    };
  }
);

export const getInfluencerStreamerVideoCount = createSelector(
  getInfluencerYoutubeStreamer,
  ({ video_count }) => video_count
);

export const getInfluencerYoutubeAudienceEngagement = createSelector(
  getInfluencerYoutubeStreamer,
  (streamer) => {
    const {
      view_count_90,
      avg_view_count_per_video,
      like_dislike_ratio,
      comment_count,
      avg_comments_per_video,
      subscriber_count,
      likes_90,
      dislikes_90,
    } = streamer;

    return {
      viewCount90: isNumber(view_count_90) && view_count_90,
      averageViewCountPerVideo:
        isNumber(avg_view_count_per_video) && avg_view_count_per_video,
      likeDislikeRatio: isNumber(like_dislike_ratio) && like_dislike_ratio,
      commentCount: isNumber(comment_count) && comment_count,
      averageCommentsPerVideo:
        isNumber(avg_comments_per_video) && avg_comments_per_video,
      subscriberCount: isNumber(subscriber_count) && subscriber_count,
      likes90: likes_90,
      dislikes90: dislikes_90,
    };
  }
);

export const getInfluencerTwitchAudienceEngagement = createSelector(
  getInfluencerActivity,
  getInfluencerTwitchStreamer,
  (influencerActivity = {}, twitchStreamer = {}) => {
    const { follower_virality, engagement_avg, engagement_peak } =
      twitchStreamer;

    const { views_days, avg_viewers_days, peak_viewers_days } =
      influencerActivity;

    return {
      viewsDays: views_days,
      averageViewersDays: avg_viewers_days,
      peakViewersDays: peak_viewers_days,
      followerVirality: follower_virality,
      engagementAverage: engagement_avg,
      engagementPeak: engagement_peak,
    };
  }
);

export const getInfluencerWordcloud = createSelector(
  getInfluencerNetworkFilter,
  getInfluencerTwitchStreamer,
  getInfluencerYoutubeStreamer,
  getInfluencerProfileWordcloud,
  (
    networkFilter,
    twitchStreamer,
    youtubeStreamer,
    influencerProfileWordcloud
  ) => {
    const wordcloud =
      influencerProfileWordcloud && influencerProfileWordcloud.length > 0
        ? influencerProfileWordcloud
        : networkFilter === 'twitch'
        ? twitchStreamer.vod_wordcloud || twitchStreamer.wordcloud
        : youtubeStreamer.video_wordcloud;

    if (!wordcloud) return [];

    return Object.keys(wordcloud)
      .sort((wordOne, wordTwo) => wordcloud[wordTwo] - wordcloud[wordOne])
      .map((word) => ({ text: word, value: wordcloud[word] }))
      .slice(0, 20);
  }
);

export const getAudienceMembersByLanguage = createSelector(
  getStreamerAudienceLanguages,
  (languages = {}) => {
    if (!languages || !Object.keys(languages).length) {
      return {};
    }

    const values = Object.values(languages);
    const total = values.reduce((acc, elem) => acc + elem, 0);

    if (total > 0) {
      const roundedPercentageValues = largestRemainderRound(
        values.map((val) => (val / total) * 100),
        100
      );

      return Object.keys(languages).reduce((withAcc, language, index) => {
        if (roundedPercentageValues[index] > 0) {
          withAcc[language] = roundedPercentageValues[index];
        }

        return withAcc;
      }, {});
    } else {
      return {};
    }
  }
);

export const getAudienceLocations = createSelector(
  getStreamerAudienceLocations,
  (locations = {}) => {
    if (!locations || !Object.keys(locations).length) {
      return {};
    }

    const LOCATIONS_LIMIT = 5;
    const values = Object.values(locations);
    const total = values.reduce((acc, elem) => acc + elem, 0);

    if (total > 0) {
      const roundedPercentageValues = largestRemainderRound(
        values.map((val) => (val / total) * 100),
        100
      );

      const countriesWPercentage = Object.keys(locations).reduce(
        (withAcc, location, index) => {
          withAcc[location] = roundedPercentageValues[index];
          return withAcc;
        },
        {}
      );

      const countriesSorted = Object.keys(countriesWPercentage).sort(
        (key1, key2) => countriesWPercentage[key2] - countriesWPercentage[key1]
      );

      return countriesSorted.reduce((withAcc, location, index) => {
        if (index <= LOCATIONS_LIMIT) {
          withAcc[location] = countriesWPercentage[location];
        } else if (countriesWPercentage[location] > 0) {
          if (!withAcc.Other) {
            withAcc.Other = 0;
          }

          withAcc.Other += countriesWPercentage[location];
        }

        return withAcc;
      }, {});
    } else {
      return {};
    }
  }
);

export const getInfluencerValues = createSelector(
  getInfluencer,
  ({ twitch_streamer, youtube_streamer }) =>
    (twitch_streamer &&
      twitch_streamer.personality &&
      twitch_streamer.personality) ||
    (youtube_streamer &&
      youtube_streamer.personality &&
      youtube_streamer.personality) ||
    []
);

export const getInfluencerBehaviour = createSelector(
  getInfluencer,
  ({ twitch_streamer, youtube_streamer }) =>
    (twitch_streamer &&
      twitch_streamer.behaviour &&
      twitch_streamer.behaviour) ||
    (youtube_streamer &&
      youtube_streamer.behaviour &&
      youtube_streamer.behaviour) ||
    []
);

export const getTwitchAudienceBehaviour = createSelector(
  getInfluencer,
  ({ twitch_streamer }) =>
    (twitch_streamer &&
      twitch_streamer.behaviour &&
      twitch_streamer.behaviour) ||
    []
);

export const getYoutubeAudienceBehaviour = createSelector(
  getInfluencer,
  ({ youtube_streamer }) =>
    (youtube_streamer &&
      youtube_streamer.behaviour &&
      youtube_streamer.behaviour) ||
    []
);

export const getIsOnWatchlist = (influencerNetwork) => (state) => {
  const { id } = state[prefix].influencer || {};
  return state?.account?.watched_influencers?.indexOf(id) >= 0;
};

export const getGenderDistribution = createSelector(
  getStreamerAudienceGenders,
  (genders = {}) => {
    if (!Object.keys(genders).length) {
      return [];
    }

    return [
      {
        gender: 'male',
        members: genders[0],
      },
      {
        gender: 'female',
        members: genders[1],
      },
    ];
  }
);

export const getEngagementData = createSelector(
  getInfluencer,
  ({ engagement_data }) => engagement_data
);
