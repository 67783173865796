import { useFormikContext } from 'formik';
import _debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef } from 'react';

interface AutoSubmitArgs {
  debounceMs?: number;
}

export const useAutoSubmit = ({ debounceMs = 1000 }: AutoSubmitArgs) => {
  // we don't want to submit on initial load
  const isFirstRun = useRef(true);

  const { submitForm, values } = useFormikContext();

  const debouncedSubmit = useMemo(
    () => _debounce(() => submitForm(), debounceMs),
    [submitForm, debounceMs]
  );

  useEffect(() => {
    if (!isFirstRun.current) {
      debouncedSubmit();
    }

    isFirstRun.current = false;
  }, [debouncedSubmit, values]);
};

export const AutoSubmit = (props: AutoSubmitArgs) => {
  useAutoSubmit(props);

  return null;
};
