import { store } from '../store';
const MixpanelNotFound = { message: 'Missing window.mixpanel' };
const whitelisted_emails = ['mastercard@aggero.io'];

function mixpanelTrack(name, payload = {}) {
  try {
    const { mixpanel } = window;

    if (process.env.NODE_ENV === 'development') {
      return;
    } // Don't track development for now

    if (!mixpanel) {
      throw MixpanelNotFound;
    }

    const { id, email } = store.getState().account.authUser || {};

    if (!email) {
      return;
    }

    if (
      process.env.NODE_ENV === 'production' &&
      window.location.host.includes('now.sh') === false &&
      email.includes('@aggero.io') &&
      whitelisted_emails.indexOf(email) === -1
    ) {
      // console.warn("Mixpanel - skipped event", name, "for dev account -", email)

      return true;
    } else {
      console.log('Mixpanel - tracking event', name, 'with payload -', payload);
    }

    mixpanel.identify(id || email);
    mixpanel.people.set({
      $email: email,
      $id: id,
    });

    mixpanel.track(name, payload);
  } catch (e) {
    console.error('Mixpanel Event Track Failed - ', e);
  }
}

export default mixpanelTrack;
