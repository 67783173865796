import { useMediaQuery, useTheme } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSelector } from 'react-redux';

import {
  OverviewCard,
  PlaceholderOverviewRow,
} from '../../../../components/OverviewCard/OverviewCard';
import { OverviewCardPlatformsStack } from '../../../../components/OverviewCard/OverviewCardPlatformsStack';
import { SocialMediaPlatform } from '../../../../types';
import PointerIcon from '../../../Campaign/components/PointerIcon';
import { SectionTitle } from '../../../Campaign/components/SectionTitle';
import { selectInfluencerHasSocialMediaAccounts } from '../../store/InfluencerInfo.selectors';
import { fakeSocialOverview } from './store/fakeData';
import { selectSocialMediaOverview } from './store/SocialMediaPosts.selectors';

export const SocialMediaOverview = ({
  isLoading,
  bluredData,
}: {
  isLoading: boolean;
  bluredData?: boolean;
}) => {
  const theme = useTheme();
  const size = useMediaQuery(theme.breakpoints.down('sm')) ? 'sm' : null;
  const socialMediaOverview = useSelector(selectSocialMediaOverview());
  const hasSocialMediaAccounts = useSelector(
    selectInfluencerHasSocialMediaAccounts()
  );

  const usedSocialMediaOverview = hasSocialMediaAccounts
    ? socialMediaOverview
    : fakeSocialOverview;

  if (isLoading) {
    return <LoadingOverview />;
  }

  const getPostsOverviewStack = () => {
    const counts: { [key in SocialMediaPlatform]?: number } = {
      facebook: usedSocialMediaOverview.facebook_count,
      instagram: usedSocialMediaOverview.instagram_count,
      tiktok: usedSocialMediaOverview.tiktok_count,
      twitter: usedSocialMediaOverview.twitter_count,
    };

    return <OverviewCardPlatformsStack counts={counts} />;
  };

  return (
    <div>
      <SectionTitle>Social media performance</SectionTitle>
      <OverviewCard.Container>
        <OverviewCard
          label="POSTS"
          color="blue"
          main={{ stat: usedSocialMediaOverview.total_count }}
          secondaryComponent={getPostsOverviewStack()}
          icon={<ChatBubbleIcon />}
          id={`myStats-socialMedia-posts`}
          size={size}
          isLocked={bluredData || !hasSocialMediaAccounts}
        />
        <OverviewCard
          label="FOLLOWERS GAINED"
          color="#6e979e"
          main={{ stat: usedSocialMediaOverview.retweets_shares_count }}
          icon={<PersonAddIcon />}
          id={`myStats-socialMedia-followersgained`}
          size={size}
          isLocked={bluredData || !hasSocialMediaAccounts}
        />
        <OverviewCard
          label="RETWEETS/SHARES"
          color="lightBlue"
          main={{ stat: usedSocialMediaOverview.retweets_shares_count }}
          icon={<CachedIcon />}
          id={`myStats-socialMedia-retweets`}
          size={size}
          isLocked={bluredData || !hasSocialMediaAccounts}
        />
        <OverviewCard
          label="REPLIES/CMNTs"
          color="black"
          main={{ stat: usedSocialMediaOverview.replies_cmnts_count }}
          icon={<ChatBubbleIcon />}
          id={`myStats-socialMedia-replies`}
          size={size}
          isLocked={bluredData || !hasSocialMediaAccounts}
        />
        <OverviewCard
          label="LIKES"
          color="red"
          main={{ stat: usedSocialMediaOverview.likes_count }}
          icon={<FavoriteIcon />}
          id={`myStats-socialMedia-likes`}
          size={size}
          isLocked={bluredData || !hasSocialMediaAccounts}
        />
        <OverviewCard
          label="ENGAGEMENTS"
          color="orange"
          main={{ stat: usedSocialMediaOverview.engagements_count }}
          icon={<PointerIcon />}
          id={`myStats-socialMedia-engagements`}
          size={size}
          isLocked={bluredData || !hasSocialMediaAccounts}
        />
        <OverviewCard
          label={'IMPRESSIONS'}
          color="purple"
          main={{ stat: usedSocialMediaOverview.impressions }}
          icon={<VisibilityIcon />}
          id={`myStats-socialMedia-impressions`}
          size={size}
          isLocked={bluredData || !hasSocialMediaAccounts}
        />
        <OverviewCard
          label="MEDIA VALUE"
          color="green"
          main={{
            stat: usedSocialMediaOverview.emv,
            prefix: '$',
            format: '0,[0].[0]a',
          }}
          icon={<LocalAtmIcon />}
          info="We are valuing the price of impressions against a standardized CPM. We don't add any multipliers to EMV values based on perceived emotional values of engagements or actions as these can lead to inflated values."
          id={`myStats-socialMedia-mediavle`}
          size={size}
          isLocked={bluredData || !hasSocialMediaAccounts}
        />
      </OverviewCard.Container>
    </div>
  );
};

const LoadingOverview = () => {
  return <PlaceholderOverviewRow nCards={7} />;
};
