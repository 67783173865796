import { LoadingState } from '../../../types';
import { Deliverable } from '../../types';
import { initialState } from './BannerDeliverables.slice';

export const SLICE_NAME = 'bannerDeliverables';

export interface BannerData {
  avg_ccv: number;
  duration: number;
  emv: number;
  impressions: number;
  occurrences: number;
  peak_ccv: number;
  video_id: number;
}

export interface BannerDeliverable extends Deliverable {
  image: string | null;
  name: string;
  pos_x: number;
  pos_y: number;
  raw_banner_image: string | null;
  raw_banner_url: string | null;
  thumbnail: string | null;
  // video id to BannerData
  data: { [key: number]: Omit<BannerData, 'video_id'> };
  data_array: BannerData[];
}

// Structure returned by the initial campaign call
export type BannerDeliverableStructure = Omit<
  BannerDeliverable,
  'data' | 'data_array'
>;

// maps deliverableId to its loading state
export type LoadingStateMap = { [key: number]: LoadingState };

export type BannerDeliverablesState = typeof initialState;
