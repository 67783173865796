import { RailsRecord } from '../types';

export interface Deliverable extends RailsRecord {
  id: number;
  campaign_id: number;
  campaign_brand_id?: number | null;
  results_count?: number; // For now this applies only for Keywords, Shoutouts, Banners & LinkTracking
}

export type BrandDeliverable = Deliverable & { campaign_brand_id: number };

export const isBrandDeliverable = (
  deliverable: Deliverable
): deliverable is BrandDeliverable => deliverable.campaign_brand_id != null;

export type BrandFilter = 'all' | number;
