import { Video, VideoSegment } from '../../../../Campaign/types';

export type { Video };

export type Stream = {
  id: number;
  followers: number;
  likes_count: number;
  comments_count: number;
  minutes_watched: number;
  duration: number;
  peak_ccv: number;
  avg_ccv: number;
  platform: 'twitch' | 'youtube';

  // twitch videos come with started_at
  started_at?: string;

  // facebook videos come with start_time
  published_at?: string;

  title: string;
  views_count: number;

  twitch_stream_id?: string;
  user_tw_id?: string;

  video_segments?: VideoSegment[];
  video_type?: ('live_stream' | 'vod' | 'video')[];
};

export type PlatformOverview = {
  comments: number;
  hrs_streamed: number;
  hrs_watched: number;
  streams_count: number;
  total_views: number;
};

export type StreamsAPIResponse = {
  facebook?: PlatformOverview;
  youtube?: PlatformOverview;

  // only twitch has avg_ccv
  twitch?: PlatformOverview & { avg_ccv: number };

  streams: Stream[];
};

export type StreamsOverview = Omit<StreamsAPIResponse, 'streams'>;

export const SLICE_NAME = 'influencerStreams';
