import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PATH_ROOT } from '../common/constants/paths';
import { history } from '../store';
import { getAuthUser } from '../store/models/user/user.selectors';

export const useRedirectSignedIn = (onlyCheckOnce: boolean = false) => {
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  const signedIn = !!useSelector(getAuthUser);

  useEffect(() => {
    if (onlyCheckOnce && shouldRender) return;

    if (signedIn) {
      history.replace(PATH_ROOT);
    } else {
      setShouldRender(true);
    }
  }, [onlyCheckOnce, shouldRender, signedIn]);

  return shouldRender;
};
