import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';

export const SectionTitle = ({ onViewMore, ...props }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    mt={2}
    mb={2}
    pl={2}
    pr={2}
  >
    <Typography
      variant="h2"
      {...props}
      style={{
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        ...props.style,
      }}
    />
    <Button
      color="primary"
      variant="outlined"
      onClick={onViewMore}
      size="small"
      style={{ background: '#FBFCFF' }}
    >
      VIEW MORE
    </Button>
  </Box>
);
