import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.button.fontSize,
  },
}));

export default (props) => {
  const classes = useStyles();

  return <Chip {...props} classes={{ ...classes, ...props.classes }} />;
};
