import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import mapSelectorsToProps from '../../../helpers/map-selectors-to-props';
import { actions } from '../../../store/actions';
import { influencerMounted } from '../../../store/events';
import { selectCommunication } from '../../../store/models/communication/communication.selectors';
import creators from '../redux';
import {
  getDateRangeFilter,
  getEngagementData,
  getFacebookPage,
  getInfluencerNetworkFilter,
  getInfluencerTopBrands,
  getInfluencerTwitchStreamer,
  getInfluencerTwitterProfile,
  getInfluencerViewData,
  getInfluencerYoutubeStreamer,
  getInstagramUser,
  getIsOnWatchlist,
  getPlayedGames,
  getTwitchAudienceBehaviour,
  getvideoWordcloudData,
  getYoutubeAudienceBehaviour,
} from '../selectors';

const mapStateToProps = (
  state,
  {
    match: {
      params: { influencerId, influencerNetwork },
    },
  }
) => ({
  ...getInfluencerViewData(state),
  ...mapSelectorsToProps({
    influencerCommunication: selectCommunication('influencer'),
    influencerTwitchStreamer: getInfluencerTwitchStreamer,
    influencerYoutubeStreamer: getInfluencerYoutubeStreamer,
    instagramUser: getInstagramUser,
    facebookPage: getFacebookPage,
    twitterProfile: getInfluencerTwitterProfile,
    videoWordcloudData: getvideoWordcloudData,
    dateRangeFilter: getDateRangeFilter,
    networkFilter: getInfluencerNetworkFilter,
    twitchAudienceBehaviour: getTwitchAudienceBehaviour,
    youtubeAudienceBehaviour: getYoutubeAudienceBehaviour,
    playedGames: getPlayedGames,
    topBrands: getInfluencerTopBrands,
    isOnWatchlist: getIsOnWatchlist(influencerNetwork),
    engagementData: getEngagementData,
  })(state),
});

const mapDispatchToProps = {
  influencerMounted,
  dateRangeFilterChanged: creators.dateRangeFilterChanged,
  requestInfluencerDetailsData: creators.requestInfluencerDetailsData,
  addToWatchlist: actions.addToWatchlist,
  removeFromWatchlist: actions.removeFromWatchlist,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);
