import { createActions, createReducer } from 'reduxsauce';

export const STORAGE_PREFIX = 'myWatchlist';

const INITIAL_STATE = {
  youtubeInfluencers: [],
  youtubeLoaded: false,
  twitchInfluencers: [],
  twitchLoaded: false,
  addFormSubmitted: false,
  youtubeWatchlistId: null,
  twitchWatchlistId: null,
};

export const requestServerDataYoutube = (state, { watchlistId }) => {
  if (watchlistId === state.youtubeWatchlistId) return state;

  return {
    ...state,
    youtubeInfluencers: [],
    youtubeLoaded: false,
    youtubeWatchlistId: watchlistId,
  };
};

export const serverDataReceivedYoutube = (state, { payload }) => {
  return {
    ...state,
    youtubeLoaded: true,
    youtubeInfluencers: payload,
  };
};

export const requestServerDataTwitch = (state, { watchlistId }) => {
  if (watchlistId === state.twitchWatchlistId) return state;

  return {
    ...state,
    twitchInfluencers: [],
    twitchLoaded: false,
    twitchWatchlistId: watchlistId,
  };
};

export const serverDataReceivedTwitch = (state, { payload }) => {
  return {
    ...state,
    twitchLoaded: true,
    twitchInfluencers: payload,
  };
};

export const myWatchlistAddRequest = (state) => {
  return {
    ...state,
    addFormSubmitted: true,
  };
};

export const myWatchlistAddSuccess = (state, { network, watchlistId }) => {
  return {
    ...state,
    [network === 'twitch' ? 'twitchInfluencers' : 'youtubeInfluencers']: [],
    [network === 'twitch' ? 'twitchLoaded' : 'youtubeLoaded']: false,

    loading: false,
    addFormSubmitted: false,
  };
};

export const myWatchlistAddError = (state, { payload }) => {
  return {
    ...state,
    addFormSubmitted: false,
  };
};

export const myWatchlistRemoveInfluencer = (state, { influencerId }) => {
  return {
    ...state,
    twitchInfluencers: state.twitchInfluencers.length
      ? state.twitchInfluencers.filter((inf) => inf.id !== influencerId)
      : [],
    youtubeInfluencers: state.youtubeInfluencers.length
      ? state.youtubeInfluencers.filter((inf) => inf.id !== influencerId)
      : [],
  };
};

const { Types, Creators } = createActions(
  {
    myWatchlistDataYoutubeRequest: ['watchlistId'],
    myWatchlistDataYoutubeSuccess: ['payload'],
    myWatchlistDataTwitchSuccess: ['payload'],
    myWatchlistDataTwitchRequest: ['watchlistId'],
    myWatchlistAddRequest: ['urls', 'withDialog', 'watchlistId', 'network'],
    myWatchlistAddSuccess: ['network', 'watchlistId'],
    myWatchlistAddError: ['payload'],
    myWatchlistRemoveInfluencer: ['influencerId', 'watchlistId'],
    myWatchlistRemoveInfluencerSucceeded: ['influencerId', 'watchlistId'],
    myWatchlistRemoveInfluencerFailed: ['influencerId', 'watchlistId', 'error'],
  },
  {
    prefix: `${STORAGE_PREFIX}/`,
  }
);

export const HANDLERS = {
  [Types.MY_WATCHLIST_DATA_YOUTUBE_REQUEST]: requestServerDataYoutube,
  [Types.MY_WATCHLIST_DATA_YOUTUBE_SUCCESS]: serverDataReceivedYoutube,

  [Types.MY_WATCHLIST_DATA_TWITCH_REQUEST]: requestServerDataTwitch,
  [Types.MY_WATCHLIST_DATA_TWITCH_SUCCESS]: serverDataReceivedTwitch,

  [Types.MY_WATCHLIST_ADD_REQUEST]: myWatchlistAddRequest,
  [Types.MY_WATCHLIST_ADD_SUCCESS]: myWatchlistAddSuccess,
  [Types.MY_WATCHLIST_ADD_ERROR]: myWatchlistAddError,
  [Types.MY_WATCHLIST_REMOVE_INFLUENCER_SUCCEEDED]: myWatchlistRemoveInfluencer,
};

export const reducer = createReducer(INITIAL_STATE, HANDLERS);

export const actionTypes = Types;

export default Creators;
