import React, { useEffect } from 'react';

import SubMenu, {
  useDeliverablesSubMenu,
  useSponsorshipSubMenu,
} from '../components/SubMenu';
import { useUsedVideos } from '../hooks/useUsedVideos';
import {
  Campaign,
  PlatformFilter,
  SelectedCampaignInfluencer,
  TimeFrame,
} from '../types';
import { CampaignContext } from '../utils/CampaignContext';
import Banners from './Banners';
import ChatMentions from './ChatMentions';
import LinkSharing from './LinkSharing';
import Shoutouts from './Shoutouts';
import SocialMentions from './SocialMentions';
import Summary from './Summary/Summary';
import { BrandFilter } from './types';

interface DeliverablesTabProps {
  campaign: Campaign;
  activePoint: string;
  setActivePoint: (activePoint: string) => void;
  timeFrame: TimeFrame;
  selectedCampaignInfluencer: SelectedCampaignInfluencer;
  setSelectedCampaignInfluencer: (
    selectedCampaignInfluencer: SelectedCampaignInfluencer
  ) => void;
  platformFilter: PlatformFilter;
  brandFilter: BrandFilter;
  setShowSocialPlatforms: (showSocialPlatforms: boolean) => void;
  isSponsorship: boolean;
  setShowBrand: (showBrand: boolean) => void;
  isFilteredByCreator: boolean;
  setByCreator: (byCreator: boolean) => void;
  setSocialPostDelete: (any) => void;
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  activeSubMenu: string;
  setActiveSubMenu: (activeSubMenu: string) => void;
  setSocialPerformanceIdDelete: (id: number) => void;
  socialPerformanceIdDelete: number;
  numberPage: number;
  setNumberPage: (number) => void;
  MainActiveTab: string;
  setHideFilters: (boolean) => void;
}

export const DeliverablesTab = ({
  campaign,
  activePoint,
  setActivePoint,
  timeFrame,
  selectedCampaignInfluencer,
  setSelectedCampaignInfluencer,
  platformFilter,
  brandFilter,
  setShowSocialPlatforms,
  isSponsorship,
  isFilteredByCreator,
  setByCreator,
  setSocialPostDelete,
  activeTab: forcedActiveTab,
  setActiveTab,
  setSocialPerformanceIdDelete,
  socialPerformanceIdDelete,
  numberPage,
  setNumberPage,
  MainActiveTab,
  setHideFilters,
}: DeliverablesTabProps) => {
  const deliverablesSubMenuProps = useDeliverablesSubMenu(
    campaign,
    setShowSocialPlatforms,
    forcedActiveTab,
    setActiveTab,
    !isSponsorship
  );

  const sponsorshipSubMenuProps = useSponsorshipSubMenu(
    campaign,
    setShowSocialPlatforms,
    forcedActiveTab,
    setActiveTab,
    setHideFilters,
    MainActiveTab,
    isSponsorship
  );

  const { usedVideos, usedVideosIds } = useUsedVideos({
    campaign,
    selectedCampaignInfluencer,
    platformFilter,
    timeFrame,
  });

  const tabProps = {
    timeFrame,
    usedVideos,
    usedVideosIds,
    campaign,
    selectedCampaignInfluencer,
    setSelectedCampaignInfluencer,
    platformFilter,
    brandFilter,
    activePoint,
    setActivePoint,
    isSponsorship,
    isFilteredByCreator,
    setByCreator,
    setActiveTab,
    usedInfluencers:
      selectedCampaignInfluencer === 'all'
        ? campaign?.campaign_influencers ?? []
        : [selectedCampaignInfluencer],
    setSocialPostDelete,
    setSocialPerformanceIdDelete,
    socialPerformanceIdDelete,
    numberPage,
    setNumberPage,
  };

  const subMenuProps = isSponsorship
    ? sponsorshipSubMenuProps
    : deliverablesSubMenuProps;

  const { activeTab, tabs, onTabClick } = subMenuProps;

  useEffect(() => {
    setActiveTab(activeTab);
  }, [activeTab, setActiveTab, isSponsorship]);

  return (
    <CampaignContext.Provider value={tabProps}>
      <SubMenu tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
      {activeTab === 'summary' && (
        <Summary {...tabProps} activeTab={activeTab} />
      )}
      {activeTab === 'shoutouts' && <Shoutouts {...tabProps} />}
      {activeTab === 'banners' && <Banners {...tabProps} />}
      {activeTab === 'link-sharing' && <LinkSharing {...tabProps} />}
      {activeTab === 'chat-mentions' && <ChatMentions {...tabProps} />}
      {activeTab === 'social-media' && <SocialMentions {...tabProps} />}
    </CampaignContext.Provider>
  );
};

export default DeliverablesTab;
