function toMentionFromTwitchComment(comment) {
  const { sentiment, language, age, gender, created_at } = comment;

  return {
    sentiment,
    language,
    age,
    gender: Array.isArray(gender) ? gender[0] : gender,
    platform: 'twitch',
    author_name: comment.author,
    author_avatar: undefined,
    date: undefined,
    text: comment.comment || comment.tweet,
    link: undefined,
    created_at: created_at && created_at.seconds,
  };
}

export default toMentionFromTwitchComment;
