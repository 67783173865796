function toObject(getKey, getValue) {
  return (array) => {
    return array.reduce(
      (object, item, i) => ({
        ...object,
        [getKey(item, i)]: getValue(item, i),
      }),
      {}
    );
  };
}

export default toObject;
