import { Checkbox, FormLabel, Grid, makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import { getErrorMessageByField } from '../../../../common/ErrorMessageUtil';
import FormikMuiTextField from '../../../../components/FormikMuiTextField';
import { selectPutInfluencerInfoError } from '../../store/InfluencerInfo.selectors';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelContainer: {
    maxWidth: 'max-content',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    fontWeight: 'bold',
  },
  checkBox: {
    paddingRight: 0,
    paddingBottom: 10,
    marginRight: theme.spacing(1.8),
  },
  checkedActive: {
    marginLeft: theme.spacing(6),
  },
  formLabelStyle: {
    fontSize: '0.9rem',
    marginBottom: 12,
  },
}));

const SubInfoForm = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const putInfluencerInfoError = useSelector(selectPutInfluencerInfoError());

  return (
    <>
      <Grid>
        <div className={classes.inputContainer}>
          <label className={classes.labelContainer}>
            <Checkbox
              checked={values.emailChecked}
              onChange={() =>
                setFieldValue('emailChecked', !values.emailChecked)
              }
              className={classes.checkBox}
              color="primary"
            />
            Email
          </label>
          <label className={classes.labelContainer}>
            <Checkbox
              checked={values.phoneChecked}
              onChange={() =>
                setFieldValue('phoneChecked', !values.phoneChecked)
              }
              className={classes.checkBox}
              color="primary"
            />
            Phone
          </label>
          {values.phoneChecked && (
            <div className={classes.checkedActive}>
              <FormLabel className={classes.formLabelStyle}>
                Phone Number
              </FormLabel>
              <FormikMuiTextField
                name="phone"
                fullWidth
                placeholder="e.g. +44 771662177"
                variant="outlined"
                className={classes.input}
                style={{ marginBottom: 16 }}
                errorMessage={getErrorMessageByField(
                  'phone',
                  putInfluencerInfoError
                )}
                value={values.phone}
              />
            </div>
          )}
          <label className={classes.labelContainer}>
            <Checkbox
              checked={values.discordChecked}
              onChange={() =>
                setFieldValue('discordChecked', !values.discordChecked)
              }
              className={classes.checkBox}
              color="primary"
            />
            Discord
          </label>
          {values.discordChecked && (
            <div className={classes.checkedActive}>
              <FormLabel className={classes.formLabelStyle}>
                Discord Username
              </FormLabel>
              <FormikMuiTextField
                name="discord_username"
                fullWidth
                placeholder="Insert discord username"
                variant="outlined"
                className={classes.input}
                style={{ marginBottom: 16 }}
                errorMessage={getErrorMessageByField(
                  'discord_username',
                  putInfluencerInfoError
                )}
                value={values.discord_username}
              />
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default SubInfoForm;
