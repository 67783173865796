import { BrandFilter, Deliverable, isBrandDeliverable } from './types';

export function filterBrandDeliverables<T extends Deliverable>(
  deliverables: T[],
  onlyBrandDeliverables: boolean,
  brandIdFilter: BrandFilter
) {
  return deliverables.filter((deliverable) => {
    // only select deliverables that have a brand id
    if (onlyBrandDeliverables) {
      if (!isBrandDeliverable(deliverable)) {
        return false;
      }

      if (brandIdFilter !== 'all') {
        return deliverable.campaign_brand_id === brandIdFilter;
      }

      return true;
    }

    return !isBrandDeliverable(deliverable);
  });
}
