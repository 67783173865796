import Alert from '@material-ui/lab/Alert';
import { BubbleChat } from 'flowise-embed-react';
import React, { useEffect } from 'react';

import { CampaignInfluencersFilter } from '../CampaignInfluencersFilter';
import { CampaignLanguageFilter } from '../CampaignLanguageFilter';
import { Campaign } from '../types';
import {
  campaignHasStaticTopMoments,
  findInfluencerById,
  getCampaignUsedLanguages,
} from '../utils';
import { fetchTopMoments } from './fetchTopMoments';
import { FiltersDrawer } from './FiltersDrawer';
import { SimpleDropdownFilter } from './SimpleDropdown';
import { data_by_campaign_id } from './static_data';
import { TopMomentsTable } from './Table';
import { TopMoment } from './types';
import {
  getCategories,
  getPlatforms,
  getRegions,
  getTopics,
  getVideoByMoment,
} from './utils';

type Props = {
  campaign: Campaign;
};

export const TopMomentsTab = (props: Props) => {
  const [selectedInfluencer, setSelectedInfluencer] = React.useState<
    'all' | number
  >('all');
  const [selectedTopic, setSelectedTopic] = React.useState('all');
  const [selectedCategory, setSelectedCategory] = React.useState('all');
  const [selectedRegion, setSelectedRegion] = React.useState('all');
  const [selectedPlatform, setSelectedPlatform] = React.useState('all');
  const [languageFilter, setLanguageFilter] = React.useState([]);
  const [data, setData] = React.useState<TopMoment[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  useEffect(() => {
    // If the campaign has static data, use it, otherwise we fetch it from google storage
    if (campaignHasStaticTopMoments(props.campaign.id)) {
      setData(data_by_campaign_id[props.campaign.id]);
    } else {
      fetchTopMoments({
        campaignId: props.campaign.id,
        onComplete: setData,
        setLoading,
        setError,
      });
    }
  }, [props.campaign.id]);

  if (loading) {
    return <Alert severity="info">Loading...</Alert>;
  }

  if (error) {
    return (
      <Alert severity="info">Top Moments are currently processing...</Alert>
    );
  }

  if (data.length === 0) {
    return null;
  }

  // Get the influencers name from the data
  const usedInfluencersNames = data.map((moment) =>
    moment.streamer.toLowerCase()
  );
  const usedInfluencers = props.campaign.campaign_influencers.filter(
    (influencer) => {
      return usedInfluencersNames.includes(influencer.name?.toLowerCase());
    }
  );

  const currentInfluencerName =
    selectedInfluencer === 'all'
      ? 'all'
      : findInfluencerById(
          selectedInfluencer,
          usedInfluencers
        ).name.toLowerCase();

  const getFilteredData = () => {
    return data.filter((moment) => {
      const video = getVideoByMoment(moment, props.campaign.videos) || {};

      return (
        (currentInfluencerName === 'all' ||
          moment.streamer.toLowerCase() ===
            currentInfluencerName.toLowerCase()) &&
        (selectedTopic === 'all' ||
          moment.topic.toLowerCase() === selectedTopic.toLowerCase()) &&
        (selectedCategory === 'all' ||
          moment.category.toLowerCase() === selectedCategory.toLowerCase()) &&
        (languageFilter.length === 0 ||
          languageFilter.includes(video.language)) &&
        (selectedRegion === 'all' ||
          moment.region.toLowerCase() === selectedRegion.toLowerCase()) &&
        (selectedPlatform === 'all' ||
          moment.platform.toLowerCase() === selectedPlatform.toLowerCase())
      );
    });
  };

  const filteredData = getFilteredData();
  const topics = getTopics(data);
  const categories = getCategories(data);
  const availableLanguages = getCampaignUsedLanguages(props.campaign);
  const regions = getRegions(data);
  const platforms = getPlatforms(data);

  const renderFilters = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {usedInfluencers.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <CampaignInfluencersFilter
              campaignInfluencers={usedInfluencers}
              value={selectedInfluencer}
              onChange={(value) => {
                setSelectedInfluencer(value);
              }}
            />
          </div>
        ) : null}
        {categories.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <SimpleDropdownFilter
              options={categories}
              value={selectedCategory}
              onChange={(value) => {
                setSelectedCategory(value);
              }}
              title="Category"
              key={'category_filter'}
            />
          </div>
        ) : null}
        {topics.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <SimpleDropdownFilter
              options={topics}
              value={selectedTopic}
              onChange={(value) => {
                setSelectedTopic(value);
              }}
              title="Topic"
              key={'topic_filter'}
            />
          </div>
        ) : null}
        {availableLanguages.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <CampaignLanguageFilter
              availableLanguages={availableLanguages}
              onChange={setLanguageFilter}
              values={languageFilter}
            />
          </div>
        ) : null}
        {regions.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <SimpleDropdownFilter
              options={regions}
              value={selectedRegion}
              onChange={(value) => {
                setSelectedRegion(value);
              }}
              title="Region"
              key={'region_filter'}
            />
          </div>
        ) : null}
        {platforms.length > 1 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
          >
            <SimpleDropdownFilter
              options={platforms}
              value={selectedPlatform}
              onChange={(value) => {
                setSelectedPlatform(value);
              }}
              title="Platform"
              key={'platform_filter'}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderAskAudience = () => {
    // Campaigns with their own comments-<campaign_id> collection.
    if (
      [
        4483, 4771, 4777, 4772, 4516, 4698, 4880, 4884, 4879, 4913, 4853, 4924,
        4850, 5039, 5050,
      ].includes(props.campaign.id)
    ) {
      return (
        <>
          <BubbleChat
            chatflowid={'6854595d-e7f4-4375-9ccb-d4a2755d264f'}
            apiHost="https://flowise-aggero.onrender.com"
            theme={{
              button: {
                right: 90,
              },
              chatWindow: {
                title: 'Ask the audience',
                welcomeMessage:
                  'Hi! Here you can ask questions and get answers based on the audience comments',
                poweredByTextColor: '#fff',
              },
            }}
            chatflowConfig={{
              collectionName: {
                chroma_1: `comments-${props.campaign.id}`,
              },
            }}
          />
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          position: 'absolute',
          zIndex: '2',
          right: '24px',
          top: '95px',
        }}
      >
        <FiltersDrawer content={renderFilters()} />
      </div>
      {renderAskAudience()}
      <TopMomentsTable
        data={filteredData as TopMoment[]}
        campaign={props.campaign}
        originalData={data}
      />
    </>
  );
};
