export const selectCommunication =
  (namespace) =>
  ({ communication }) =>
    communication[namespace] || {};

export const selectAllCampaignsCommunicationKeys = ({ communication }) => {
  const keys = Object.keys(communication).filter((key) =>
    key.includes('campaign-')
  );

  return keys;
};
