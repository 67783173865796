import {
  Box,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import numeral from 'numeral';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.2em',
  },
  bold: {
    fontWeight: 'bold',
  },
  red: {
    color: '#f44336',
    margin: 0,
  },
  green: {
    color: '#4caf50',
    margin: 0,
  },
  statbox: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const StatBox = React.memo(
  ({
    label,
    stat,
    format,
    suffix,
    prefix,
    info,
    deltaInfo,
    size,
    color,
    delta,
    deltaSuffix,
    noBold,
    statFontSize,
    id,
    disableTooltip,
  }) => {
    const classes = useStyles();

    let rounded =
      format !== null && stat !== null && stat !== undefined
        ? numeral(stat).format(format).toUpperCase()
        : stat || 'N/A';

    const tooltipTitle =
      rounded !== 'N/A' &&
      format &&
      rounded !== numeral(stat).format('0,0.[00]')
        ? `${prefix}${numeral(stat).format('0,0.[00]')}${suffix}`
        : '';

    let statSize = '2em';
    let labelSize = '.75rem';
    let fontWeight = noBold ? 'normal' : 'bold';
    let align = 'center';

    if (format && stat && suffix === '%' && stat < 1) {
      rounded = '<1';
    }

    if (size === 'overviewCard') {
      statSize = '1.2em';
      align = 'right';
    }

    if (size === 'table') {
      statSize = '1.5em';
      fontWeight = 'normal';
      align = 'right';
    }

    if (size === 'lg') {
      statSize = '3rem';
      labelSize = '1rem';
    }

    if (size === 'xl') {
      statSize = '4.5rem';
      labelSize = '1.5rem';
    }

    return (
      <div className={classes.statbox}>
        <Tooltip disableHoverListener={disableTooltip} title={tooltipTitle}>
          <div data-id={id}>
            {delta && Number.isFinite(delta) ? (
              <Box
                fontWeight="bold"
                fontSize="1em"
                textAlign="center"
                display="flex"
                alignItems="center"
              >
                {delta >= 0 && (
                  <p className={classes.green}>
                    <span className="triangle triangle-green" />+
                    {`${numeral(delta).format('0,0.[00]')}${deltaSuffix || ''}`}
                  </p>
                )}
                {delta < 0 && (
                  <p className={classes.red}>
                    <span className="triangle triangle-red" />
                    {`${numeral(delta).format('0,0.[00]')}${deltaSuffix || ''}`}
                  </p>
                )}
                {deltaInfo && (
                  <Tooltip placement="top" title={deltaInfo}>
                    <IconButton size="small">
                      <InfoOutlinedIcon
                        style={{ width: '0.65em', height: '0.65em' }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ) : null}
            <div
              style={{
                fontWeight: fontWeight,
                fontSize: statFontSize ?? statSize,
                lineHeight: '1.2em',
                textAlign: align,
                color,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              className="lockedValue"
            >
              {rounded !== 'N/A' ? prefix : ''}
              {rounded}
              {rounded !== 'N/A' ? suffix : ''}
              {!label && info && (
                <Tooltip placement="top" title={info}>
                  <IconButton size="small">
                    <InfoOutlinedIcon
                      style={{ width: '0.65em', height: '0.65em' }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </Tooltip>
        {label && (
          <Typography
            align={align}
            fontSize={labelSize}
            variant="caption"
            display="block"
            style={{ color, wordBreak: 'break-word' }}
          >
            {label}
            {info && (
              <Tooltip placement="top" title={info}>
                <IconButton size="small">
                  <InfoOutlinedIcon
                    style={{ width: '0.65em', height: '0.65em' }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        )}
      </div>
    );
  }
);

StatBox.defaultProps = {
  format: '0.[0]a',
  suffix: '',
  prefix: '',
  color: 'black',
};

export default StatBox;
