import moment from 'moment';

export const computeOverviewChatMentionData = ({
  timeFrame,
  keywordMentionDeliverable,
  campaign,
}) => {
  const { video_comments: unfilteredVideoComments, keyword } =
    keywordMentionDeliverable;

  const videoComments = unfilteredVideoComments.filter(({ published_at }) =>
    moment(published_at).isBetween(timeFrame.start, timeFrame.end)
  );

  const sentiment = videoComments.length
    ? videoComments.reduce((prev, { sentiment }) => prev + sentiment, 0) /
      videoComments.length
    : 0;

  const videos = campaign.videos.filter(({ published_at }) =>
    moment(published_at).isBetween(timeFrame.start, timeFrame.end)
  );

  const numberOfMentions = videoComments.length;

  const videosSet = videoComments.reduce(
    (set, { video_id }) => set.add(video_id),
    new Set()
  );

  const liveStreams = videosSet.size;

  const totalComments = videos.reduce(
    (total, { comments_count }) => total + comments_count,
    0
  );

  const streamingChannels = videos.reduce(
    (
      set,
      { influencer_id, resource, id, streamer_id, campaign_influencer_ids }
    ) => {
      if (videosSet.has(id)) {
        const influencerId =
          streamer_id || influencer_id || campaign_influencer_ids[0];

        set.add(influencerId.toString() + resource);
      }

      return set;
    },
    new Set()
  ).size;

  return {
    numberOfMentions,
    liveStreams,
    totalComments,
    streamingChannels,
    keyword,
    sentiment,
    videoComments,
  };
};
