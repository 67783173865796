import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

export const InfluencerOverviewSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item>
            <Skeleton variant="circle" width={160} height={160} />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Skeleton variant="rect" width={134} height={24} />
              </Grid>
              <Grid item>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Skeleton width={280} height={36} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant="circle" width={36} height={36} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Skeleton width={370} height={16} />
                <Skeleton width={360} height={16} />
                <Skeleton width={380} height={16} />
                <Skeleton width={375} height={16} />
                <Skeleton width={355} height={16} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
