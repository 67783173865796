import {
  Box,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import facebookIcon from '../../assets-2.0/facebook.svg';
import facebookGamingIcon from '../../assets-2.0/facebookGaming.svg';
import instagramIcon from '../../assets-2.0/instagram.svg';
import tiktokIcon from '../../assets-2.0/tiktok.svg';
import twitchIcon from '../../assets-2.0/twitch.svg';
import twitterIcon from '../../assets-2.0/twitter.svg';
import youtubeIcon from '../../assets-2.0/youtube.svg';
import { Platform } from '../../types';
import { selectInfluencerInfo } from '../../views/influencerViews/store/InfluencerInfo.selectors';

const STREAMING_PLATFORMS: DropdownPlatform[] = [
  { name: 'Twitch', icon: twitchIcon, color: '#5a3e85', key: 'twitch' },
  { name: 'YouTube', icon: youtubeIcon, color: '#e60707', key: 'youtube' },
  {
    name: 'Facebook Gaming',
    icon: facebookGamingIcon,
    color: '#005af0',
    key: 'facebook',
  },
];

const SOCIAL_PLATFORMS: DropdownPlatform[] = [
  { name: 'Facebook', icon: facebookIcon, color: '#325a9c', key: 'facebook' },
  {
    name: 'Instagram',
    icon: instagramIcon,
    color: '#eb0089',
    key: 'instagram',
  },
  { name: 'TikTok', icon: tiktokIcon, color: '#010101', key: 'tiktok' },
  { name: 'Twitter', icon: twitterIcon, color: '#6fbbf7', key: 'twitter' },
];

type DropdownPlatform = {
  name: string;
  icon: string;
  color: string;
  key: Platform;
};

type Props = {
  platformType: 'all' | 'social' | 'streaming';
  onChange: (platform: Platform | 'all') => void;
  value: Platform | 'all';
  className?: string;
};

export const PlatformDropdown = ({
  platformType = 'all',
  onChange,
  value = 'all',
  className,
}: Props) => {
  const classes = useStyles();
  const influencerInfo = useSelector(selectInfluencerInfo());

  const getPlatforms = () => {
    if (platformType === 'streaming') {
      return STREAMING_PLATFORMS.filter(
        (platform) =>
          influencerInfo?.[`onboarding_${platform.key}_pages`]?.length
      );
    }

    if (platformType === 'social') {
      return SOCIAL_PLATFORMS.filter(
        (platform) =>
          influencerInfo?.[`onboarding_${platform.key}_pages`]?.length
      );
    }

    return [...SOCIAL_PLATFORMS, ...STREAMING_PLATFORMS];
  };

  return (
    <Select
      id="platform-select"
      value={value}
      input={<OutlinedInput />}
      onChange={(e) => {
        onChange(e.target.value as Platform | 'all');
      }}
      className={clsx(classes.select, className)}
    >
      <MenuItem value="all">
        <Typography>All</Typography>
      </MenuItem>
      {getPlatforms().map(({ icon, color, name, key }) => {
        return (
          <MenuItem key={key} value={key}>
            <Box display="flex" alignItems="center">
              <img
                src={icon}
                alt={`${name} logo`}
                className={classes.platformIcon}
              />
              <Typography
                style={{
                  color,
                }}
              >
                {name}
              </Typography>
            </Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.palette.common.white,
  },

  platformIcon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));
