import { combineReducers } from 'redux';

import {
  INFLUENCERS_FETCH_NEXT_PAGE,
  INFLUENCERS_SEARCH_REQUEST_SUCCEEDED,
  INFLUENCERS_SEARCH_REQUESTED,
  INFLUENCERS_SEARCH_RESET,
} from '../action-types';

const query = (state = null, action) => {
  const { type, payload } = action;

  switch (type) {
    case INFLUENCERS_SEARCH_REQUEST_SUCCEEDED: {
      return payload.query;
    }
    case INFLUENCERS_SEARCH_RESET: {
      return null;
    }

    case INFLUENCERS_SEARCH_REQUESTED: {
      return payload.query;
    }

    default:
      return state;
  }
};

const totalResults = (state = 0, action) => {
  const { type, payload } = action;

  switch (type) {
    case INFLUENCERS_SEARCH_REQUEST_SUCCEEDED: {
      return payload.totalResults;
    }
    case INFLUENCERS_SEARCH_RESET: {
      return 0;
    }

    default:
      return state;
  }
};

const hasNextPage = (state = false, action) => {
  const { type, payload } = action;

  switch (type) {
    case INFLUENCERS_SEARCH_REQUEST_SUCCEEDED: {
      return payload.hasNextPage;
    }
    case INFLUENCERS_SEARCH_RESET: {
      return false;
    }

    default:
      return state;
  }
};

const pageIndex = (state = 0, action) => {
  const { type, payload } = action;

  switch (type) {
    case INFLUENCERS_SEARCH_REQUEST_SUCCEEDED: {
      return payload.pageIndex;
    }
    case INFLUENCERS_SEARCH_RESET: {
      return 0;
    }

    default:
      return state;
  }
};

/*
 * this is for the influencers returned by the search-api which
 * have a different format than those stored in firebase
 */
const list = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case INFLUENCERS_SEARCH_REQUEST_SUCCEEDED: {
      const { influencers } = payload;

      return influencers;
    }
    case INFLUENCERS_SEARCH_REQUESTED: {
      return [];
    }
    case INFLUENCERS_SEARCH_RESET: {
      return [];
    }

    default:
      return state;
  }
};

const fetchingNextPage = (state = false, action) => {
  const { type } = action;

  switch (type) {
    case INFLUENCERS_FETCH_NEXT_PAGE: {
      return true;
    }

    case INFLUENCERS_SEARCH_REQUEST_SUCCEEDED: {
      return false;
    }
    case INFLUENCERS_SEARCH_REQUESTED: {
      return false;
    }
    case INFLUENCERS_SEARCH_RESET: {
      return false;
    }

    default:
      return state;
  }
};

const gameIdList = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case INFLUENCERS_SEARCH_REQUEST_SUCCEEDED: {
      const { game_id_list = [] } = payload;

      return game_id_list;
    }

    case INFLUENCERS_SEARCH_RESET: {
      return [];
    }

    default:
      return state;
  }
};

export default combineReducers({
  list,
  gameIdList,
  pageIndex,
  fetchingNextPage,
  hasNextPage,
  query,
  totalResults,
});
