import { combineReducers } from 'redux';

import overwriteDeep from '../../../utils/overwrite-deep';
import {
  BRAND_DELETE_SUCCEEDED,
  BRANDS_REQUEST_SUCCEEDED,
} from '../../action-types';

const byId = (state = {}, action) => {
  const { type } = action;

  switch (type) {
    case BRANDS_REQUEST_SUCCEEDED: {
      const { payload } = action;
      return overwriteDeep(state, payload);
    }
    case BRAND_DELETE_SUCCEEDED: {
      const {
        payload: { id },
      } = action;

      const { [id]: deleted, ...remaining } = state;

      return remaining;
    }
    default:
      return state;
  }
};

export default combineReducers({ byId });
