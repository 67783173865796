import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useCampaignCardInfluencerStyles = makeStyles((theme) => ({
  avatar: {
    width: 48,
    height: 48,
    borderColor: theme.palette.primary.main,
    border: 'solid 2px',
  },
  avatarSmall: {
    width: 30,
    height: 30,
  },
  box: {
    margin: 4,
  },
  textOverflowEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: 8,
  },
}));

function CampaignCardInfluencer(props) {
  const { avatarLink, influencerName, avatarSmall, nameAsTooltip } = props;
  const fallbackImage = '../../fallback/img.png';
  const classes = useCampaignCardInfluencerStyles();

  const avatar = (
    <Avatar
      className={avatarSmall ? classes.avatarSmall : classes.avatar}
      src={avatarLink ? avatarLink : fallbackImage}
      alt={influencerName?.toUpperCase()}
    />
  );

  const name = (
    <Typography className={classes.textOverflowEllipsis} variant="h6">
      {' '}
      {influencerName}{' '}
    </Typography>
  );

  return (
    <Box
      display={nameAsTooltip ? 'inline-block' : 'flex'}
      className={classes.box}
      alignItems="center"
    >
      {nameAsTooltip && influencerName && (
        <Tooltip placement="top" title={influencerName}>
          {avatar}
        </Tooltip>
      )}
      {!nameAsTooltip && (
        <>
          {avatar}
          <Box fontSize="1.2rem" clone>
            {name}
          </Box>
        </>
      )}
    </Box>
  );
}

export default CampaignCardInfluencer;
