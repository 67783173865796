import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import numeral from 'numeral';
import React from 'react';

const StatBox = ({ label, stat, format, suffix, id }) => (
  <Grid item data-id={id}>
    <Box clone fontSize=".75rem">
      <Typography
        align="center"
        variant="caption"
        display="block"
        color="textSecondary"
      >
        {label}
      </Typography>
    </Box>
    <Box fontWeight="bold" fontSize="2em" lineHeight="1.2em" textAlign="center">
      <Tooltip title={numeral(stat).format('0,0.[0]')}>
        <span>
          {numeral(stat).format(format || '0.[0]a')}
          {suffix}
        </span>
      </Tooltip>
    </Box>
  </Grid>
);

export const YoutubeStats = ({ influencers }) => {
  const processedInfluencers = influencers.filter((i) => i.streamer_id);

  return (
    <Card style={{ marginBottom: 24 }} className="youTubeCard">
      <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <StatBox
            id="yt-channels"
            stat={influencers.length}
            label="CHANNELS"
          />
          <StatBox
            id="yt-total-reach"
            stat={processedInfluencers.reduce(
              (p, i) => p + (i.subscriber_count || 0),
              0
            )}
            label="TOTAL REACH"
          />
          <StatBox
            id="yt-avg-likes"
            stat={
              processedInfluencers.reduce(
                (p, i) => p + (i.like_dislike || i.view_rate_30 || 0),
                0
              ) / processedInfluencers.length
            }
            format="0.[0]a"
            label="AVG LIKE / DISLIKE (90D)"
          />
          <StatBox
            id="yt-total-views"
            stat={processedInfluencers.reduce(
              (p, i) => p + (i.view_count_90 || 0),
              0
            )}
            label="TOTAL VIEWS (90D)"
          />
          <StatBox
            id="yt-avg-engagement"
            stat={
              (processedInfluencers.reduce(
                (p, i) => p + (i.yt_engagement_90 || 0),
                0
              ) /
                processedInfluencers.length) *
              100
            }
            suffix="%"
            label="AVG ENGAGEMENT RATE (90D)"
          />
          <StatBox
            id="yt-avg-vievs"
            stat={
              processedInfluencers.reduce(
                (p, i) => p + (i.avg_view_count_per_video || 0),
                0
              ) / processedInfluencers.length
            }
            label="AVG VIEWS / VIDEO (90D)"
          />
          <StatBox
            id="yt-total-videos"
            stat={processedInfluencers.reduce(
              (p, i) => p + (i.video_count || 0),
              0
            )}
            label="TOTAL VIDEOS"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export const TwitchStats = ({ influencers }) => {
  const processedInfluencers = influencers.filter((i) => i.streamer_id);

  return (
    <Card style={{ marginBottom: 24 }} className="twitchCard">
      <CardContent>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <StatBox
            stat={influencers.length}
            label="CHANNELS"
            id="twitch-channels"
          />
          <StatBox
            id="twitch-total-reach"
            stat={processedInfluencers.reduce(
              (p, i) => p + (i.followers || 0),
              0
            )}
            label="TOTAL REACH"
          />
          <StatBox
            id="twitch-avg-rate"
            stat={
              processedInfluencers.reduce(
                (p, i) => p + (i.like_dislike || i.view_rate_30 || 0),
                0
              ) / processedInfluencers.length
            }
            suffix="%"
            label="AVG VIEW RATE"
          />
          <StatBox
            id="twitch-avg-ccv"
            stat={
              processedInfluencers.reduce(
                (p, i) => p + (i.avg_viewers_days_30 || 0),
                0
              ) / processedInfluencers.length
            }
            label="AVG CCV (30D)"
          />
          <StatBox
            id="twitch-total-streamtime"
            stat={processedInfluencers.reduce(
              (p, i) => p + (i.stream_time_days_30 || 0),
              0
            )}
            suffix=" h"
            label="TOTAL STREAM TIME (30D)"
          />
          <StatBox
            id="twitch-avg-ama"
            stat={
              processedInfluencers.reduce(
                (p, i) => p + (i.ama_days_30 || 0),
                0
              ) / processedInfluencers.length
            }
            label="AVG AMA (30D)"
          />
          <StatBox
            id="twitch-total-vievs"
            stat={processedInfluencers.reduce(
              (p, i) => p + (i.views_days_30 || 0),
              0
            )}
            label="TOTAL VIEWS (30D)"
          />
        </Box>
      </CardContent>
    </Card>
  );
};
