import { Theme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React, { ReactElement } from 'react';

import { InfluencerAvatar } from '../../../components/InfluencerAvatar/InfluencerAvatar';
import { CampaignInfluencer } from '../types';

type Props = {
  influencers: CampaignInfluencer[];
  content: ReactElement | string;
};

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxHeight: 500,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
}))(Tooltip);

export const InfluencersToolTip = ({ influencers, content }: Props) => {
  return (
    <HtmlTooltip
      interactive
      title={
        <>
          {influencers.map((influencer) => {
            return (
              <div style={{ padding: '6px' }} key={influencer.id}>
                <InfluencerAvatar size="sm" influencer={influencer} />
              </div>
            );
          })}
        </>
      }
    >
      <div>{content}</div>
    </HtmlTooltip>
  );
};
