import { Button, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useMultiItemsSelect } from '../../../../hooks/useMultiItemsSelect';
import { getIsViewOnly } from '../../../../store/models/user/user.selectors';
import { CampaignMultipleItemsDeleteDialog } from '../../CampaignMultipleItemsDeleteDialog';
import { CampaignDeliverableChartTrigger } from '../../components/DeliverablesChartTrigger';
import InfluencerAvatar from '../../components/InfluencerAvatar';
import VideoCell from '../../components/VideoCell';
import Table, { StatBox } from '../../Table';
import { findInfluencerByVideo, findVideoById } from '../../utils';
import TimestampsDialog from './TimestampsDialog';

const ROW_HEIGHT = 124;

const useStyles = makeStyles(() => ({
  container: {
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  button: {
    position: 'absolute',
    top: 'calc(100% + 4px)',
    padding: '2px 9px',
  },
}));

const useCreatorColStyles = makeStyles(() => ({
  outerSidebar: {
    position: 'absolute',
    left: -32,
    top: '50%',
    transform: 'translateY(-50%)',
    height: ROW_HEIGHT,
    width: 48,
    background: 'rgba(0, 0, 0, .1)',
    zIndex: 5,
  },
  innerSidebar: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteIcon: {
    color: 'red',
  },
}));

const CreatorCol = ({
  influencer,
  image,
  setCampaignImageDelete,
  isViewOnly,
  bannerDeliverableId,
  handleRowSelect,
}) => {
  const classes = useCreatorColStyles();

  return (
    <>
      <div style={{ position: 'relative' }} className="padding-on-hover">
        <div
          style={{}}
          className={clsx('slide-on-hover', classes.outerSidebar)}
        >
          <div className={classes.innerSidebar}>
            <Checkbox
              color="primary"
              checked={image.isSelected}
              onChange={() => {
                handleRowSelect(image.video.id);
              }}
              inputProps={{ 'aria-label': 'Select image tracking result' }}
            />
            <CampaignDeliverableChartTrigger
              videoId={image.video.id}
              bannerDeliverableId={bannerDeliverableId}
            />
            {!isViewOnly && (
              <Tooltip
                placement="top"
                title="Remove all image detections for this video"
              >
                <IconButton
                  onClick={() => {
                    setCampaignImageDelete(image);
                  }}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <InfluencerAvatar influencer={influencer} />
      </div>
    </>
  );
};

const TimeOnScreen = ({
  timeOnScreen,
  video,
  influencer,
  campaignId,
  bannerDeliverableId,
  timeZone,
  thumbnail,
}) => {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div className={classes.container}>
      <TimestampsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        video={video}
        influencer={influencer}
        campaignId={campaignId}
        bannerDeliverableId={bannerDeliverableId}
        timeZone={timeZone}
        thumbnail={thumbnail}
      />
      <div>
        <StatBox
          format={null}
          stat={moment
            .duration(timeOnScreen, 'seconds')
            .format('HH[h] : mm[m] : ss[s]')}
        />
      </div>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className={`${classes.button} show-on-hover`}
        onClick={() => setDialogOpen(true)}
      >
        View Timestamps
      </Button>
    </div>
  );
};

const getCols = ({
  setCampaignImageDelete,
  isViewOnly,
  bannerDeliverableId,
  handleRowSelect,
}) => [
  {
    label: 'Creator',
    key: 'creator',
    render: (row) => {
      return (
        <CreatorCol
          influencer={row.influencer}
          platform={row.platform}
          image={row}
          setCampaignImageDelete={setCampaignImageDelete}
          isViewOnly={isViewOnly}
          bannerDeliverableId={bannerDeliverableId}
          handleRowSelect={handleRowSelect}
        />
      );
    },
    stretch: true,
  },
  {
    label: 'Video',
    key: 'videoTimeStamp',
    render: (row) => <VideoCell video={row.video} videoTime={row.videoTime} />,
    stretch: true,
  },
  {
    label: 'Time on Screen',
    key: 'timeOnScreen',
    render: (data) => <TimeOnScreen {...data} />,
  },
  {
    label: '% TOTAL TIME',
    key: 'ofTotal',
    render: (data) => <StatBox stat={data.ofTotal} suffix="%" />,
  },
  {
    label: 'Avg. CCV',
    key: 'avgCCV',
    render: (data) => <StatBox stat={data.avgCCV} />,
  },
  {
    label: 'Peak CCV',
    key: 'peakCCV',
    render: (data) => <StatBox stat={data.peakCCV} />,
  },
  {
    label: 'Impressions',
    key: 'impressions',
    render: (data) => <StatBox stat={data.impressions} />,
  },
  {
    label: 'Media Value',
    key: 'emv',
    render: (data) => (
      <StatBox stat={data.emv} format="0,[0].[0]a" prefix="$" />
    ),
  },
];

const getRows = ({
  usedBannerData,
  usedVideos,
  usedInfluencers,
  timeZone,
  bannerDeliverableId,
  campaignId,
  thumbnails,
  selectedRows,
}) =>
  usedBannerData.map((data) => {
    const video = findVideoById(data.video_id, usedVideos);

    const videoTimeObject = momentTz.tz(video.published_at, timeZone);

    const videoTime = videoTimeObject.format('Do MMM YYYY, hh:mm A');

    const videoTimeStamp = videoTimeObject.unix();

    const influencer = findInfluencerByVideo(video, usedInfluencers);

    return {
      influencer,
      creator: influencer.name,
      key: data.video_id,
      platform: video.resource,
      emv: data.emv,
      videoTitle: video.video_title,
      video,
      videoTime,
      avgCCV: data.avg_ccv,
      peakCCV: data.peak_ccv,
      impressions: data.impressions,
      timeOnScreen: data.duration,
      ofTotal: Math.round((data.duration / video.duration) * 10000) / 100,
      bannerDeliverableId,
      campaignId,
      timeZone,
      videoTimeStamp,
      thumbnail: thumbnails[video.id],
      isSelected: selectedRows.includes(data.video_id),
    };
  });

export const DetailedTable = ({
  usedBannerData,
  usedVideos,
  usedInfluencers,
  timeZone,
  bannerDeliverableId,
  campaignId,
  thumbnails,
  setCampaignImageDelete,
  image,
  onDeleteMultipleBannerVideos,
}) => {
  const isViewOnly = useSelector(getIsViewOnly);

  const usedBannerVideosIds = useMemo(
    () => usedBannerData.map((data) => data.video_id),
    [usedBannerData]
  );

  const {
    selectedItems: selectedRows,
    handleAllItemsSelect: handleAllRowsSelect,
    handleItemSelect: handleRowSelect,
  } = useMultiItemsSelect(usedBannerVideosIds);

  const rows = useMemo(
    () =>
      getRows({
        usedBannerData,
        usedVideos,
        usedInfluencers,
        timeZone,
        bannerDeliverableId,
        campaignId,
        thumbnails,
        selectedRows,
      }),
    [
      usedBannerData,
      usedVideos,
      usedInfluencers,
      timeZone,
      thumbnails,
      selectedRows,
    ]
  );
  const cols = useMemo(
    () =>
      getCols({
        setCampaignImageDelete,
        isViewOnly,
        bannerDeliverableId,
        handleRowSelect,
      }),
    [setCampaignImageDelete, isViewOnly]
  );
  const idTable = `SV-imagetracking-detailed-${image.replace(/\s+/g, '')}`;

  return (
    <>
      {selectedRows.length > 0 && (
        <div style={{ padding: 4, display: 'flex' }}>
          <Checkbox
            color="primary"
            onChange={(_, checked) => {
              handleAllRowsSelect(checked);
            }}
          />
          <CampaignMultipleItemsDeleteDialog
            items={selectedRows}
            onDelete={onDeleteMultipleBannerVideos}
            title={'Delete Image Detections'}
          />
        </div>
      )}
      <Table
        cols={cols}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        defaultSortCol="videoTimeStamp"
        minWidth={1360}
        idTable={idTable}
      />
    </>
  );
};

export default DetailedTable;
