import moment from 'moment';

function toMentionFromYoutubeComment(comment) {
  const { sentiment, language, age, gender } = comment;

  const parsedDate = moment(comment.created_at, moment.ISO_8601, true);

  return {
    sentiment,
    language,
    age,
    gender: Array.isArray(gender) ? gender[0] : gender,
    platform: 'youtube',
    author_name: comment.author,
    author_avatar: undefined,
    date: parsedDate.isValid() ? parsedDate.unix() : comment.created_at,
    text: comment.text,
    link: undefined,
    created_at: parsedDate.isValid() ? parsedDate.unix() : null,
  };
}

export default toMentionFromYoutubeComment;
