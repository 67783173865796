import { LoadingState, PublishedRecord } from '../../../types';
import { Deliverable } from '../../types';
import { initialState } from './ShoutoutDeliverables.slice';

export const SLICE_NAME = 'shoutoutDeliverables';
export interface Shoutout extends PublishedRecord {
  id: number;
  timestamp: string | null;
  resource_url: string;
  video_id: number;
  text: string;
  views: number;
  emv: number;
  segment_start: number;
  segment_end: number;
  clip: string | null;
  shoutout_deliverable_id: number | null;
  shoutout_deliverables_v2_id: number | null;
}

interface CommonShoutoutDeliverable extends Deliverable {
  shoutouts: Shoutout[];
  processing: boolean;
}

export interface ShoutoutDeliverableV1 extends CommonShoutoutDeliverable {
  content: string;
  v2: never;
}

export interface ShoutoutDeliverableV2 extends CommonShoutoutDeliverable {
  v2: true;
  name: string;
  structured: boolean;
  inclusion_terms: string[];
  exclusion_terms: string[];
}

export type ShoutoutDeliverable = ShoutoutDeliverableV1 | ShoutoutDeliverableV2;

// Structure returned by the initial campaign call
export type ShoutoutDeliverableStructureV1 = Omit<
  ShoutoutDeliverableV1,
  'shoutouts'
>;

// Structure returned by the initial campaign call
export type ShoutoutDeliverableStructureV2 = Omit<
  ShoutoutDeliverableV2,
  'shoutouts'
>;

export type ShoutoutDeliverableStructure =
  | ShoutoutDeliverableStructureV1
  | ShoutoutDeliverableStructureV2;

export const isShoutoutDeliverableStructureV2 = (
  deliverable: ShoutoutDeliverableStructure
): deliverable is ShoutoutDeliverableV2 => !!deliverable.v2;

export const isShoutoutDeliverableStructureV1 = (
  deliverable: ShoutoutDeliverableStructure
): deliverable is ShoutoutDeliverableV1 => !deliverable.v2;

export const isShoutoutDeliverableV2 = (
  deliverable: ShoutoutDeliverableStructure
): deliverable is ShoutoutDeliverableV2 => !!deliverable.v2;

// maps shoutoutDeliverableId to its loading state
export type LoadingStateMap = { [key: number]: LoadingState };

export const getShoutoutTitle = (shoutout: ShoutoutDeliverable) => {
  if (isShoutoutDeliverableV2(shoutout)) return shoutout.name;

  return shoutout.content;
};

export type ShoutoutDeliverablesState = typeof initialState;
