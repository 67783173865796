import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { FORM_SIGNIN } from '../../../../common/constants/forms';
import { validateEmail } from '../../../../common/utils';
import { selectors } from '../../../../store/selectors';

const mapStateToProps = (state) => ({
  authInProgress: selectors.getAuthInProgress(state),
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_SIGNIN,
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Required';
      } else if (!validateEmail(values.email)) {
        errors.email = 'Invalid email address';
      }
      if (!values.password) {
        errors.password = 'Required';
      } else if (values.password.length < 6) {
        errors.password = 'Password is too short';
      }
      return errors;
    },
  })
);
