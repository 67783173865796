import {
  Avatar,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';

import { ComponentSize } from '../../utils/ComponentSize';

const useStyles = makeStyles<Theme, Pick<Props, 'size'>>((theme) => ({
  name: ({ size }) => {
    return {
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
      fontWeight: 'bold',
      paddingLeft: size === 'sm' ? 24 : 16,
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: size === 'sm' ? '0.875rem' : '1rem',
    };
  },

  avatar: ({ size }) => {
    if (size === 'sm') {
      return {
        width: 30,
        height: 30,
      };
    }
  },

  container: {
    maxWidth: '150px',
    display: 'flex',
    alignItems: 'center',
  },
}));

interface Props {
  influencer: {
    name: string;
    twitch_external_id?: number | string;
    twitter_external_id?: number | string;
    instagram_external_id?: number | string;
    youtube_external_id?: number | string;
    tiktok_external_id?: number | string;
    avatar?: string;
  };
  size?: Extract<ComponentSize, 'sm'>;
}

export const InfluencerAvatar = ({ influencer, size }: Props) => {
  const classes = useStyles({ size });
  const fallbackImage = '../../fallback/img.png';

  const influencerName =
    typeof influencer === 'string'
      ? influencer
      : influencer.name ||
        influencer.twitch_external_id ||
        influencer.twitter_external_id ||
        influencer.instagram_external_id ||
        influencer.youtube_external_id ||
        influencer.tiktok_external_id ||
        'unknown';

  return (
    <div className={classes.container}>
      <Avatar
        src={influencer.avatar ? influencer.avatar : fallbackImage}
        alt={influencer.name?.toUpperCase()}
        className={classes.avatar}
      />

      <Tooltip placement="top" title={influencerName}>
        <Typography className={classes.name}>{influencerName}</Typography>
      </Tooltip>
    </div>
  );
};
