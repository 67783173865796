import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
// import AddIcon from '@material-ui/icons/Add';
import { Formik } from 'formik';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { FloatingLoader } from '../../../components/FloatingLoader';
import SectionTitle from '../../../components/SectionTitle';
import { AutoSubmit } from '../../../hooks/useAutoSubmit';
import InfoForm from '../InfluencerSignupWizard/InfoForm/InfoForm';
import SocialAccountsForm from '../InfluencerSignupWizard/SocialAccountsForm/SocialAccountsForm';
import SubInfoForm from '../InfluencerSignupWizard/SubInfoForm/SubInfoForm';
import {
  selectInfluencerInfo,
  selectInfluencerInfoError,
  selectPutInfluencerInfoPending,
} from '../store/InfluencerInfo.selectors';
import { putInfluencerInfo } from '../store/InfluencerInfo.thunks';
import {
  formValuesToInfluencerInfo,
  InfluencerInfoFormValues,
  influencerInfoToFormValues,
} from '../utils/influencerInfoFormUtils';
import CircularProgressWithLabel from './components/CircularProgress/CircularProgressWithLabel';
import { ChangeEmailModal } from './components/modals/ChangeEmailModal';
import { ChangePasswordModal } from './components/modals/ChangePasswordModal';
import { SocialAccountsModal } from './components/modals/SocialAccountsModal';

const useStyles = makeStyles((theme) => ({
  main: {
    maxHeight: '100%',
    overflow: 'auto',
    padding: theme.spacing(2),
    fontSize: '1rem',
  },
  bigTypography: {
    color: 'black',
  },
  dataFillList: {
    padding: 0,
    color: '#666666',
    '& li': {
      fontSize: '1rem',
      paddingLeft: 0,
    },
    '& span': {
      fontSize: '1rem',
    },
  },
  active: {
    color: '#0048F2',
    '& svg': {
      fill: '#0048F2',
    },
  },
  iconList: {
    paddingRight: 20,
    minWidth: 'auto',
  },
  moreText: {
    margin: 0,
    lineHeight: '24px',
    '& p': {
      fontSize: 18,
      fontWeight: 'bold',
    },
  },
  otherText: {
    marginBottom: 10,
  },
  headBlock: {
    marginBottom: 5,
    lineHeight: 1,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  maxWidthBlock: {
    maxWidth: 520,
  },
  chapterText: {
    marginBottom: 5,
    lineHeight: 1,
    fontWeight: 'bold',
  },
}));

export const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { search } = useLocation();
  const influencerInfo = useSelector(selectInfluencerInfo());
  const influencerInfoError = useSelector(selectInfluencerInfoError());

  const influencerInfoLoaded = influencerInfo != null;

  useEffect(() => {
    const q = qs.parse(search);

    if (q.socialCallback) {
      const container = document.querySelector('main');

      // scroll to bottom
      container?.scrollTo({ top: container?.scrollHeight });
    }
  }, [search, influencerInfoLoaded]);

  const [openedModal, setOpenedModal] = useState<
    false | 'changePassword' | 'changeEmail' | 'addSocialAccounts'
  >(false);

  const putInfluencerInfoPending = useSelector(
    selectPutInfluencerInfoPending()
  );

  const onModalClose = useCallback(
    () => setOpenedModal(false),
    [setOpenedModal]
  );

  const onSubmit = useCallback(
    (values: InfluencerInfoFormValues) => {
      dispatch(
        putInfluencerInfo({
          influencerInfo: formValuesToInfluencerInfo(values),
          showToasts: true,
        })
      );
    },
    [dispatch]
  );

  if (influencerInfoError)
    return <p>Failed to load: {influencerInfoError.message}</p>;

  if (!influencerInfo) return null;

  const name = influencerInfo.name;

  return (
    <>
      {putInfluencerInfoPending && <FloatingLoader />}
      <ChangePasswordModal
        open={openedModal === 'changePassword'}
        onClose={onModalClose}
      />
      <ChangeEmailModal
        open={openedModal === 'changeEmail'}
        onClose={onModalClose}
      />
      <SocialAccountsModal
        open={openedModal === 'addSocialAccounts'}
        onClose={onModalClose}
      />
      <Formik
        initialValues={influencerInfoToFormValues(influencerInfo)}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <main className={classes.main}>
              <AutoSubmit />
              <Grid container spacing={4} direction="column" wrap="nowrap">
                <Grid item xs={12}>
                  <SectionTitle>Profile</SectionTitle>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <p className={classes.moreText}>
                        {name != null && <p>Hey {influencerInfo?.name}</p>}
                        This is your profile panel. From here you can edit,
                        remove, delete any details related to your Aggero
                        account.
                        <br /> <b>Tip:</b> Having a complete profile - i.e.
                        connecting your social accounts and providing more
                        details about you, will bring you more brand sponsorship
                        opportunities.
                        <br /> We are always here to help, drop us a line in the
                        chat from the right bottom corner.
                      </p>
                    </CardContent>
                  </Card>
                </Grid>

                {false && ( // hide for now
                  <Grid item xs={12}>
                    <Card style={{ display: 'flex', position: 'relative' }}>
                      <CardContent>
                        <CircularProgressWithLabel
                          position={'absolute'}
                          color={'#C4C4C4'}
                          value={100}
                          progress={false}
                        />
                        <CircularProgressWithLabel
                          position={'relative'}
                          color={'#0048F2'}
                          value={80}
                          progress={true}
                        />
                      </CardContent>
                      <CardContent>
                        <Typography
                          className={classes.bigTypography}
                          variant="h6"
                        >
                          Complete your profile
                        </Typography>
                        <Typography>Description</Typography>
                        <List className={classes.dataFillList}>
                          <ListItem className={classes.active}>
                            <ListItemIcon className={classes.iconList}>
                              <Check />
                            </ListItemIcon>
                            <ListItemText primary="Profile information" />
                          </ListItem>
                          <ListItem className={classes.active}>
                            <ListItemIcon className={classes.iconList}>
                              <Check />
                            </ListItemIcon>
                            <ListItemText primary="Communication preferences" />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon className={classes.iconList}>
                              <Close />
                            </ListItemIcon>
                            <ListItemText primary="Social media accounts" />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Card style={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>
                      <p className={classes.headBlock}>personal information</p>
                      <p className={classes.otherText}>
                        Add more details about yourself to get more sponsorship
                        opportunities
                      </p>
                    </CardContent>
                    <CardContent>
                      <p className={classes.chapterText}>Email</p>
                      <p className={classes.otherText}>
                        {influencerInfo.email}
                      </p>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: '1rem' }}
                        onClick={() => setOpenedModal('changePassword')}
                      >
                        CHANGE PASSWORD
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenedModal('changeEmail')}
                      >
                        CHANGE EMAIL
                      </Button>
                    </CardContent>
                    <CardContent className={classes.maxWidthBlock}>
                      <InfoForm />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent className={classes.maxWidthBlock}>
                      <p className={classes.headBlock}>
                        communication preferences
                      </p>
                      <p className={classes.otherText}>
                        Specify which channels would you like to be reached out
                        through
                      </p>
                      <SubInfoForm />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <div>
                        <p className={classes.headBlock}>social accounts</p>
                        <p className={classes.otherText}>
                          Connect your social account to get insights and
                          understand your audience across all your channels
                        </p>
                      </div>

                      <div className={classes.maxWidthBlock}>
                        <SocialAccountsForm profilePage />
                      </div>

                      {/* we do not support multiple accounts ATM
                      
                      <Button
                        startIcon={<AddIcon />}
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenedModal('addSocialAccounts')}
                      >
                        ADD MORE accounts
                      </Button> */}
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card style={{ display: 'flex' }}>
                    <CardContent>
                      <p
                        style={{ marginBottom: 20 }}
                        className={classes.headBlock}
                      >
                        Danger zone
                      </p>
                      <Button
                        variant="contained"
                        style={{ background: '#E94B4B', color: 'white' }}
                      >
                        CLOSE ACCOUNT
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </main>
          );
        }}
      </Formik>
    </>
  );
};
