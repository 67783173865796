import _maxBy from 'lodash/maxBy';
import _meanBy from 'lodash/meanBy';
import _sumBy from 'lodash/sumBy';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';

import InfluencerAvatar from '../../components/InfluencerAvatar';
import Table, { StatBox } from '../../Table';
import { findInfluencerByVideo, findVideoById } from '../../utils';

export const computeSummary = (usedBannerData) => {
  const streams = usedBannerData.length;

  const timeOnScreen = _sumBy(usedBannerData, 'duration') || 0;

  const avgCCV = _meanBy(usedBannerData, (data) => {
    if (data.avg_ccv === null) {
      return undefined;
    }

    return data.avg_ccv;
  });
  const peakCCV = (_maxBy(usedBannerData, 'peak_ccv') || { peak_ccv: null })
    .peak_ccv;

  const impressions = _sumBy(usedBannerData, 'impressions') || 0;

  const totalEMV = _sumBy(usedBannerData, 'emv') || 0;

  return {
    timeOnScreen,
    streams,
    avgCCV: isNaN(avgCCV) ? null : avgCCV,
    peakCCV,
    impressions,
    totalEMV,
  };
};

const getRows = ({ usedInfluencers, usedVideos, usedBannerData }) =>
  usedInfluencers.map((influencer) => {
    const bannerData = usedBannerData.filter(
      (data) =>
        findInfluencerByVideo(
          findVideoById(data.video_id, usedVideos),
          usedInfluencers
        ).id === influencer.id
    );

    const { timeOnScreen, streams, avgCCV, peakCCV, impressions, totalEMV } =
      computeSummary(bannerData);

    return {
      influencer,
      creatorOrDate: influencer.name,
      timeOnScreen,
      streams,
      avgCCV,
      peakCCV,
      impressions,
      totalEMV,
      key: influencer.id,
    };
  });

const getRowsDay = ({ usedBannerData, timeArray, timeZone, usedVideos }) =>
  timeArray.map((influencer) => {
    const bannerData = usedBannerData.filter((img) => {
      const video = findVideoById(img.video_id, usedVideos);
      const timeObject = momentTz.tz(video.published_at, timeZone);

      return timeObject.format('Do MMM YYYY') === influencer.time;
    });
    const { timeOnScreen, streams, avgCCV, peakCCV, impressions, totalEMV } =
      computeSummary(bannerData);

    return {
      influencer: influencer.time,
      creatorOrDate: influencer.unixTime,
      timeOnScreen,
      streams,
      avgCCV,
      peakCCV,
      impressions,
      totalEMV,
      key: influencer.id,
    };
  });

const COLS = (isFilteredByCreator, isSponsorship) => {
  return [
    {
      label: isSponsorship
        ? isFilteredByCreator
          ? 'Creator'
          : 'Day'
        : 'Creator',
      key: 'creatorOrDate',
      render: (row) => <InfluencerAvatar influencer={row.influencer} />,
      stretch: true,
    },
    {
      label: 'Videos',
      key: 'streams',
      render: (data) => <StatBox stat={data.streams} />,
    },
    {
      label: 'Time on Screen',
      key: 'timeOnScreen',
      render: (data) => (
        <StatBox
          format={null}
          stat={moment
            .duration(data.timeOnScreen, 'seconds')
            .format('HH[h] : mm[m] : ss[s]')}
        />
      ),
    },
    {
      label: 'Avg. CCV',
      key: 'avgCCV',
      render: (data) => <StatBox stat={data.avgCCV} />,
    },
    {
      label: 'Peak CCV',
      key: 'peakCCV',
      render: (data) => <StatBox stat={data.peakCCV} />,
    },
    {
      label: 'Impressions',
      key: 'impressions',
      render: (data) => <StatBox stat={data.impressions} />,
    },
    {
      label: 'Media Value',
      key: 'totalEMV',
      render: (data) => (
        <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
      ),
    },
  ];
};

export const SummaryTable = ({
  usedInfluencers,
  usedBannerData,
  usedVideos,
  setSelectedCampaignInfluencer,
  isFilteredByCreator,
  isSponsorship,
  timeZone,
  image,
}) => {
  const timeArray = usedBannerData
    .map((elem) => {
      const video = findVideoById(elem.video_id, usedVideos);
      const timeObject = momentTz.tz(video.published_at, timeZone);
      return {
        time: timeObject.format('Do MMM YYYY'),
        unixTime: timeObject.unix(),
      };
    })
    .reduce(
      (acc, x) => acc.concat(acc.find((y) => y.time === x.time) ? [] : [x]),
      []
    );
  const rows = useMemo(
    () => getRows({ usedInfluencers, usedVideos, usedBannerData }),
    [usedVideos, usedBannerData, usedInfluencers]
  );
  const rowsDay = useMemo(
    () =>
      getRowsDay({
        usedBannerData,
        timeArray,
        timeZone,
        usedVideos,
      }),
    [usedBannerData, timeArray, usedVideos]
  );

  const onRowClick = useCallback(
    ({ key }) => setSelectedCampaignInfluencer(key),
    [setSelectedCampaignInfluencer]
  );
  const idTable = `SV-imagetracking-summary-${image.replace(/\s+/g, '')}`;

  return (
    <Table
      cols={COLS(isFilteredByCreator, isSponsorship)}
      rows={isSponsorship ? (isFilteredByCreator ? rows : rowsDay) : rows}
      defaultSortCol="timeOnScreen"
      onRowClick={onRowClick}
      idTable={idTable}
    />
  );
};

export default SummaryTable;
