export default [
  {
    name: 'Beauty',
    items: ['Cosmetics', 'Skin care'],
  },
  {
    name: 'Business & Industrial',
    items: [
      'Aerospace',
      'Agriculture',
      'Automotive',
      'Aviation',
      'Business',
      'Chemical',
      'Construction',
      'Crowdfunding',
      'Digital marketing',
      'Electricity',
      'Email marketing',
      'Engineering',
      'Entertainment',
      'Funds',
      'Management',
      'Metals and mining',
      'News agency',
      'Non-profit',
      'Oil and gas',
      'Online advertising',
      'Package delivery',
      'Pharmaceutics',
      'Real estate',
      'Refrigeration',
      'Retail',
      'Social media',
      'Software',
      'Steel',
      'Technology',
      'Telecommunication',
      'Video hosting',
      'Visual effects',
    ],
  },
  {
    name: 'Esports',
    items: ['Associations', 'Betting'],
  },
  {
    name: 'Finance',
    items: [
      'Banking',
      'Credit cards',
      'Crypto Trading',
      'Financial services',
      'Insurance',
      'Investment banking',
      'Money transfers',
      'Stock / Forex Trading',
    ],
  },
  {
    name: 'Food & Drink',
    items: [
      'Alcoholic beverages',
      'Baked goods',
      'Beer',
      'Beverages',
      'Caffeinated snacks',
      'Coffee',
      'Coffeehouses',
      'Energy drinks',
      'Energy snacks',
      'Fast food restaurants',
      'Fast food',
      'Food Delivery Hubs/Apps',
      'Food delivery',
      'Food ordering',
      'Gluten-Free',
      'Organic food',
      'Pizza',
      'Restaurants',
      'Smart water',
      'Smoothie/Raw juice',
      'Snacks',
      'Tea',
      'Vegan snacks',
    ],
  },
  {
    name: 'Gaming',
    items: [
      'Action',
      'Adventure Game',
      'Card & Board Game',
      'Driving/Racing Game',
      'Educational Game',
      'FPS',
      'Fighting',
      'Game consoles',
      'Game developers',
      'Game news',
      'Games',
      'Gaming accessories',
      'Gaming chair',
      'Gaming components',
      'Gaming machines',
      'Gaming peripherals',
      'Horror',
      'MMORPG',
      'MOBA',
      'Open World',
      'Platformer',
      'RPG',
      'RTS',
      'Rhythm & Music Game',
      // 'Rogueelite',
      'Shooter',
      'Simulation',
      'Sports Game',
      'Strategy',
      'Streaming equipment',
      'TGC',
      'VR',
      'Video games',
    ],
  },
  {
    name: 'Health',
    items: [
      'Health news',
      'Healthcare services',
      'Medical marijuana',
      'Personal care',
    ],
  },
  {
    name: 'Lifestyles & Hobbies',
    items: [
      'Decoration',
      'Digital collectibles',
      'Environmentalism',
      'Magazines',
      'Musical instruments',
      'Pets',
      'Politics and social issues',
    ],
  },
  {
    name: 'Media & Entertainment',
    items: [
      'Anime',
      'Books',
      'Events',
      'Hentai Manga',
      'Magazines',
      'Mobile apps',
      'Movies',
      'Music',
      'News',
      'Podcasts',
      'Pornography',
      'Radio stations',
      'TV',
      'Video Streaming',
      'Websites',
    ],
  },
  {
    name: 'Shopping & Fashion',
    items: [
      'Clothing',
      'Eyewear',
      'Fashion accessories',
      'Hygiene and Toiletries',
      'Luxury goods',
      'Online shopping',
      'Shoes',
      'Shopping',
      'Toys',
      'Watches',
    ],
  },
  {
    name: 'Sports',
    items: [
      'Baseball',
      'Golf',
      'Hockey',
      'Martial arts',
      'Soccer',
      'Sports betting',
      'Sports events',
      'Sports news',
      'Sports teams',
      'Tennis',
      'WWE',
    ],
  },
  {
    name: 'Technology',
    items: [
      'Audio equipment',
      'Cameras',
      'Computer memory',
      'Computers',
      'Consumer electronics',
      'Data storage',
      'Dating apps',
      'Free software',
      'Graphic cards',
      'Language learning app',
      'Mobile phones',
      'PC Antivirus Software',
      'Software',
      'Technology magazines',
      'Technology news',
      'VPN',
    ],
  },
  {
    name: 'Travel',
    items: [
      'Air travel',
      'Car rentals',
      'Hotels',
      'Public transport',
      'Theme parks',
      'Tourism',
      'Train travel',
    ],
  },
  {
    name: 'Vehicles',
    items: ['Automobiles', 'Auto parts', 'Bicycles', 'Boats'],
  },
];
