import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import percent from '../utils/percent';
import sum from '../utils/sum';

const GenderDistribution = (props) => {
  const { data = [] } = props;

  const genderDistributionData = {
    labels: data.map(({ gender }) => gender),
    datasets: [
      {
        data: data.map(({ members }) => members),
        backgroundColor: ['#279EE0', '#FF977D'],
        hoverBackgroundColor: ['#279EE0', '#FF977D'],
      },
    ],
  };

  function renderLabel({ index }, { labels, datasets }) {
    const total = sum(data.map(({ members }) => members));

    return `${labels[index]}: ${percent(total, datasets[0].data[index])}%`;
  }

  return (
    <Doughnut
      data={genderDistributionData}
      options={{
        tooltips: {
          callbacks: {
            label: renderLabel,
          },
        },
      }}
    />
  );
};

export default GenderDistribution;
