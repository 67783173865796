export default {
  'read often': 'lifestyle',
  'eat out frequently': 'lifestyle',
  'have a gym membership': 'lifestyle',
  'be concerned about the environment': 'lifestyle',
  'consider starting a business in next few years': 'lifestyle',
  'have experience playing music': 'lifestyle',
  'attend live musical events': 'lifestyle',
  'volunteer for social causes': 'lifestyle',
  'like romance movies': 'lifestyle',
  'like science-fiction movies': 'lifestyle',
  'like war movies': 'lifestyle',
  'like adventure movies': 'lifestyle',
  'like historical movies': 'lifestyle',
  'like action movies': 'lifestyle',
  'like documentary movies': 'lifestyle',
  'like outdoor activities': 'lifestyle',
  'like horror movies': 'lifestyle',
  'like musical movies': 'lifestyle',
  'like drama movies': 'lifestyle',
  'like rock music': 'lifestyle',
  'like classical music': 'lifestyle',
  'like rap music': 'lifestyle',
  'like R&B music': 'lifestyle',
  'like hip hop music': 'lifestyle',
  'like Latin music': 'lifestyle',
  'like country music': 'lifestyle',
  'read non-fiction books': 'lifestyle',
  'read autobiographical books': 'lifestyle',
  'read entertainment magazines': 'lifestyle',
  'read financial investment books': 'lifestyle',
  'prefer quality when buying clothes': 'purchasing',
  'prefer comfort when buying clothes': 'purchasing',
  'prefer style when buying clothes': 'purchasing',
  'prefer safety when buying automobiles': 'purchasing',
  'be sensitive to ownership cost when buying automobiles': 'purchasing',
  'be influenced by product utility when making product purchases':
    'purchasing',
  'be influenced by online ads when making product purchases': 'purchasing',
  'be influenced by social media when making product purchases': 'purchasing',
  'be influenced by brand name when making product purchases': 'purchasing',
  'be influenced by family when making product purchases': 'purchasing',
  'prefer using credit cards for shopping': 'purchasing',
  'indulge in spur of the moment purchases': 'purchasing',
};
