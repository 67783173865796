import { State } from '../../../../../store/types';
import { LoadingState } from '../../../../../types/LoadingState';
import {
  FetchSocialMediaPerformancePayload,
  getCacheKey,
  SLICE_NAME,
} from './types';

export const selectSocialMediaPerformanceSummaryIsLoading =
  (payload: FetchSocialMediaPerformancePayload) => (state: State) => {
    const cacheKey = getCacheKey(payload);

    const stateSlice = state[SLICE_NAME];

    if (cacheKey !== stateSlice.summaryCacheKey) {
      return true;
    }

    return [LoadingState.IDLE, LoadingState.PENDING].includes(
      stateSlice.summaryLoadingState
    );
  };

export const selectShouldFetchSocialMediaPerformanceSummary =
  (payload: FetchSocialMediaPerformancePayload) => (state: State) => {
    const cacheKey = getCacheKey(payload);

    const stateSlice = state[SLICE_NAME];

    if (cacheKey !== stateSlice.summaryCacheKey) {
      return true;
    }

    return LoadingState.IDLE === stateSlice.summaryLoadingState;
  };

export const selectSocialMediaPerformanceSummary = () => (state: State) => {
  const stateSlice = state[SLICE_NAME];

  return stateSlice.summary;
};

export const selectSocialMediaPerformanceSummaryError =
  () => (state: State) => {
    const stateSlice = state[SLICE_NAME];

    return stateSlice.summaryError;
  };

export const selectSocialMediaPerformancePostsIsLoading =
  (payload: FetchSocialMediaPerformancePayload) => (state: State) => {
    const cacheKey = getCacheKey(payload);

    const stateSlice = state[SLICE_NAME];

    if (cacheKey !== stateSlice.postsCacheKey) {
      return true;
    }

    return [LoadingState.IDLE, LoadingState.PENDING].includes(
      stateSlice.postsLoadingState
    );
  };

export const selectShouldFetchSocialMediaPerformancePosts =
  (payload: FetchSocialMediaPerformancePayload) => (state: State) => {
    const cacheKey = getCacheKey(payload);

    const stateSlice = state[SLICE_NAME];

    if (cacheKey !== stateSlice.postsCacheKey) {
      return true;
    }

    return LoadingState.IDLE === stateSlice.postsLoadingState;
  };

export const selectSocialMediaPerformancePosts = () => (state: State) => {
  const stateSlice = state[SLICE_NAME];

  return stateSlice.posts;
};

export const selectSocialMediaPerformancePostsError = () => (state: State) => {
  const stateSlice = state[SLICE_NAME];

  return stateSlice.postsError;
};

export const selectSocialMediaPerformancePostsHasSharesOrRetweets =
  () => (state: State) => {
    const stateSlice = state[SLICE_NAME];

    return stateSlice.summary?.some(
      ({ posts_count_per_platform: { tiktok, twitter, facebook } }) =>
        tiktok > 0 || twitter > 0 || facebook > 0
    );
  };
