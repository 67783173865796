export const LOCATION_CHANGE = 'LOCATION_CHANGE';

export const locationChange = (location = '/') => ({
  type: LOCATION_CHANGE,
  payload: location,
});

export const updateLocation = ({ dispatch }) => (nextLocation) =>
  dispatch(locationChange(nextLocation));

const locationReducer = (state = null, action) =>
  action.type === LOCATION_CHANGE ? action.payload : state;

export default locationReducer;
