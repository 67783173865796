import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
    padding: '14.5px 14px',
    // background: theme.palette.background.default,
  },
}));

export default function (props) {
  const { input } = useStyles();

  return <OutlinedInput classes={{ input }} {...props} />;
}
