function toMentionFromRedditComment(comment) {
  const { sentiment, language, age, gender, fetched_at } = comment;

  return {
    sentiment,
    language,
    age,
    gender: Array.isArray(gender) ? gender[0] : gender,
    platform: 'reddit',
    type: comment.source,
    author_name: comment.author,
    author_avatar: undefined,
    date: comment.date,
    text: comment.textFragment,
    link: comment.post_url,
    created_at: fetched_at && fetched_at.seconds,
  };
}

export default toMentionFromRedditComment;
