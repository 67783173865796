import _filter from 'lodash/filter';
import _max from 'lodash/max';
import _mean from 'lodash/mean';
import _sumBy from 'lodash/sumBy';
import momentTz from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';

import InfluencerAvatar from '../../components/InfluencerAvatar';
import Table, { StatBox } from '../../Table';
import {
  findInfluencerByVideo,
  findVideoById,
  videoIsFromStreamingPlatform,
} from '../../utils';

export const computeSummary = (usedShoutouts, usedVideos) => {
  const mentions = usedShoutouts.length;

  const mentions_YT = _filter(usedShoutouts, function (usedShoutout) {
    return usedShoutout.resource_url.includes('youtube');
  }).length;

  const streams = new Set(usedShoutouts.map((shoutout) => shoutout.video_id))
    .size;

  const { peakCCV, avgCCV } = getAvgAndPeakCCV(usedShoutouts, usedVideos);
  const totalViewers = _sumBy(usedShoutouts, 'views') || 0;
  const totalEMV = _sumBy(usedShoutouts, 'emv') || 0;

  return {
    mentions,
    streams,
    avgCCV: isNaN(avgCCV) ? null : avgCCV,
    peakCCV,
    totalViewers,
    totalEMV,
    mentions_YT,
  };
};

const getAvgAndPeakCCV = (usedShoutouts, usedVideos) => {
  if (!usedShoutouts || !usedShoutouts.length) {
    return {
      avgCCV: 0,
      peakCCV: 0,
    };
  }

  const allCCV = usedShoutouts.map((shoutout) => {
    const video = findVideoById(shoutout.video_id, usedVideos);

    // Only shoutouts from streaming platforms have CCV
    if (videoIsFromStreamingPlatform(video)) {
      if (video.resource === 'tiktok_video') {
        return undefined;
      }

      return shoutout.views || 0;
    }

    return undefined;
  });

  return {
    avgCCV: _mean(allCCV),
    peakCCV: _max(allCCV),
  };
};

const getRows = ({ usedInfluencers, usedVideos, usedShoutouts }) =>
  usedInfluencers.map((influencer) => {
    const shoutouts = usedShoutouts.filter(
      (shoutout) =>
        findInfluencerByVideo(
          findVideoById(shoutout.video_id, usedVideos),
          usedInfluencers
        ).id === influencer.id
    );

    const { mentions, streams, avgCCV, peakCCV, totalViewers, totalEMV } =
      computeSummary(shoutouts, usedVideos);

    return {
      influencer,
      creatorOrDate: influencer.name,
      mentions,
      streams,
      avgCCV,
      peakCCV,
      totalViewers,
      totalEMV,
      key: influencer.id,
    };
  });
const getRowsDay = ({ usedShoutouts, timeArray, timeZone, usedVideos }) =>
  timeArray.map((influencer) => {
    const shoutouts = usedShoutouts.filter((shoutout) => {
      const video = findVideoById(shoutout.video_id, usedVideos);
      const timeObject = momentTz.tz(video.published_at, timeZone);
      return timeObject.format('Do MMM YYYY') === influencer.time;
    });
    const { mentions, streams, avgCCV, peakCCV, totalViewers, totalEMV } =
      computeSummary(shoutouts, usedVideos);

    return {
      influencer: influencer.time,
      creatorOrDate: influencer.unixTime,
      mentions,
      streams,
      avgCCV,
      peakCCV,
      totalViewers,
      totalEMV,
      key: influencer.id,
    };
  });

const COLS = (isFilteredByCreator, isSponsorship) => {
  return [
    {
      label: isSponsorship
        ? isFilteredByCreator
          ? 'Creator'
          : 'Day'
        : 'Creator',
      key: 'creatorOrDate',
      render: (row) => <InfluencerAvatar influencer={row.influencer} />,
      stretch: true,
    },
    {
      label: 'Videos',
      key: 'streams',
      render: (data) => <StatBox stat={data.streams} />,
    },
    {
      label: 'Mentions',
      key: 'mentions',
      render: (row) => <StatBox stat={row.mentions} />,
    },
    {
      label: 'Avg. CCV',
      key: 'avgCCV',
      render: (data) => <StatBox stat={data.avgCCV} />,
    },
    {
      label: 'Peak CCV',
      key: 'peakCCV',
      render: (data) => <StatBox stat={data.peakCCV} />,
    },
    {
      label: 'Total Viewers',
      key: 'totalViewers',
      render: (data) => <StatBox stat={data.totalViewers} />,
    },
    {
      label: 'Media Value',
      key: 'totalEMV',
      render: (data) => (
        <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
      ),
    },
  ];
};
export const SummaryTable = ({
  usedInfluencers,
  usedShoutouts,
  usedVideos,
  setSelectedCampaignInfluencer,
  isFilteredByCreator,
  isSponsorship,
  timeZone,
  words,
}) => {
  const timeArray = usedShoutouts
    .map((elem) => {
      const video = findVideoById(elem.video_id, usedVideos);
      const timeObject = momentTz.tz(video.published_at, timeZone);
      return {
        time: timeObject.format('Do MMM YYYY'),
        unixTime: timeObject.unix(),
      };
    })
    .reduce(
      (acc, x) => acc.concat(acc.find((y) => y.time === x.time) ? [] : [x]),
      []
    );
  const rows = useMemo(
    () => getRows({ usedInfluencers, usedVideos, usedShoutouts }),
    [usedVideos, usedShoutouts, usedInfluencers]
  );
  const rowsDay = useMemo(
    () =>
      getRowsDay({
        usedShoutouts,
        timeArray,
        timeZone,
        usedVideos,
      }),
    [usedShoutouts, timeArray, usedVideos]
  );
  const onRowClick = useCallback(
    ({ key }) => setSelectedCampaignInfluencer(key),
    [setSelectedCampaignInfluencer]
  );
  const idTable = `SV-voiceMentions-summary-${words[0].replace(/\s+/g, '')}`;

  return (
    <Table
      cols={COLS(isFilteredByCreator, isSponsorship)}
      rows={isSponsorship ? (isFilteredByCreator ? rows : rowsDay) : rows}
      defaultSortCol="mentions"
      onRowClick={isFilteredByCreator ? onRowClick : null}
      idTable={idTable}
    />
  );
};

export default SummaryTable;
