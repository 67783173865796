import { Button, Link, makeStyles } from '@material-ui/core';

import deals from './svg/deals.svg';
const DISCORD_LINK = 'https://discord.gg/GtTsCWWhMH';

const useStyles = makeStyles((theme) => ({
  allPageBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '40px 20px 0px 20px',
  },
  pageName: {
    width: '100%',
    display: 'none',
    fontSize: 28,
    fontWeight: 'bold',
  },
  dealsMain: {
    width: '100%',
    maxWidth: 1386,
    height: 685,
    display: 'flex',
    background: '#edf7ff',
    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
    flexDirection: 'column',
    alignItems: 'center',
  },
  img: {
    width: 390,
    height: 390,
  },
  textIsBold: {
    fontFamily: 'Calibri',
    fontSize: 30,
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: 'bold',
    lineHeight: 1,
    marginBottom: 10,
  },
  smallText: {
    fontFamily: 'Calibri',
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.5)',
    marginBottom: 15,
  },
  highlights_content: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    fontWeight: 'bold',
    width: 141,
    height: 48,
  },
  [theme.breakpoints.down('sm')]: {
    allPageBlock: {
      marginTop: 17,
    },
    pageName: {
      display: 'block',
      marginBottom: 27,
    },
    dealsMain: {
      height: 355,
      marginTop: 0,
    },
    img: {
      width: 165,
      height: 165,
    },
    textIsBold: {
      fontSize: 16,
    },
    smallText: {
      fontSize: 14,
      maxWidth: 210,
    },
  },
}));

export const Deals = () => {
  const classes = useStyles();

  return (
    <div className={classes.allPageBlock}>
      <div className={classes.pageName}>Deals</div>
      <div className={classes.dealsMain}>
        <div style={{ position: 'relative' }}>
          <img className={classes.img} alt="deals" src={deals} />
        </div>
        <div className={classes.highlights_content}>
          <div className={classes.textIsBold}>
            Looking For More Sponsorship Opportunities?
          </div>
          <div className={classes.smallText}>
            Each day brands search for creators to collaborate with. <br />
            We'll make sure they find you soon.
          </div>
          <Link target="_blank" href={DISCORD_LINK}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
            >
              JOIN DISCORD
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
