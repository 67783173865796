import { makeStyles } from '@material-ui/styles';
import React from 'react';

import facebookIcon from '../../assets-2.0/facebook.svg';
import facebookGamingIcon from '../../assets-2.0/facebookGaming.svg';
import instagramIcon from '../../assets-2.0/instagram.svg';
import tiktokIcon from '../../assets-2.0/tiktok.svg';
import twitchIcon from '../../assets-2.0/twitch.svg';
import twitterIcon from '../../assets-2.0/twitter.svg';
import youtubeIcon from '../../assets-2.0/youtube.svg';

const useOverviewCardPlatformsStackStyles = makeStyles(() => ({
  platformsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  platformIcon: {
    height: 18,
    width: 18,
  },
  platformContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  platformStreamCount: {
    marginRight: 4,
    fontSize: 16,
  },
}));

const PLATFORMS = {
  twitch: { icon: twitchIcon },
  facebookGaming: { icon: facebookGamingIcon },
  youtube: { icon: youtubeIcon },
  twitter: { icon: twitterIcon },
  instagram: { icon: instagramIcon },
  facebook: { icon: facebookIcon },
  tiktok: { icon: tiktokIcon },
};

type Platform = keyof typeof PLATFORMS;

type OverviewCardPlatformsStackProps = {
  counts: { [key in Platform]?: number };
};

export const OverviewCardPlatformsStack = ({
  counts,
}: OverviewCardPlatformsStackProps) => {
  const classes = useOverviewCardPlatformsStackStyles();

  return (
    <div className={classes.platformsContainer}>
      {Object.keys(counts)
        .filter((key) => counts[key])
        .sort()
        .map((platform) => (
          <div className={classes.platformContainer} key={platform}>
            <span className={classes.platformStreamCount}>
              {counts[platform]}
            </span>
            <img
              src={PLATFORMS[platform].icon}
              alt={`${platform} icon`}
              className={classes.platformIcon}
            />
          </div>
        ))}
    </div>
  );
};
