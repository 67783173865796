import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  tab: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: 16,
  },
  active: {
    color: theme.palette.primary.main,
  },
}));
const CampaignCreatorAndDayFilter = ({ isFilteredByCreator, setByCreator }) => {
  const classes = useStyles();
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div
          className={cx(classes.container)}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <span style={{ fontSize: 16 }}>View by</span>
          <span
            className={cx(classes.tab, {
              [classes.active]: isFilteredByCreator,
            })}
            onClick={() => setByCreator(true)}
          >
            CREATOR
          </span>
          <i>|</i>
          <span
            className={cx(classes.tab, {
              [classes.active]: !isFilteredByCreator,
            })}
            onClick={() => setByCreator(false)}
          >
            DAY
          </span>
        </div>
      </div>
    </div>
  );
};

export default CampaignCreatorAndDayFilter;
