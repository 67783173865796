import { State } from '../../../../store/types';
import { LoadingState } from '../../../Campaign/types';
import { SLICE_NAME } from './types';

export const selectSignupInfluencerWithPasswordShowLoading =
  () => (state: State) =>
    state[SLICE_NAME].signupInfluencerWithPasswordLoadingState ===
    LoadingState.PENDING;

export const selectSignupInfluencerWithPasswordError = () => (state: State) =>
  state[SLICE_NAME].signupInfluencerWithPasswordError;
