/*
 * function => object => object
 *
 * replaces every property on the object with the return value of
 * the mapper function given the current value and the current key
 * */

export function objectMap(mapper) {
  return function (object) {
    return Object.keys(object).reduce(
      (reduction, key) => ({
        ...reduction,
        [key]: mapper(object[key], key),
      }),
      {}
    );
  };
}

export default objectMap;
