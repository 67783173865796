function toMentionFromTweet(tweet) {
  const { sentiment, language, age, gender, created_at, new_age } = tweet;

  return {
    sentiment,
    language,
    age,
    new_age,
    gender: Array.isArray(gender) ? gender[0] : gender,
    platform: 'twitter',
    author_name: tweet.author,
    author_avatar: tweet.avatar,
    date: tweet.date,
    text: tweet.textFragment,
    link: tweet.permalink,
    created_at: created_at && created_at.seconds,
  };
}

export default toMentionFromTweet;
