import React from 'react';

import { TwitchSocialPage } from '../../../store/types';
import { BoxInfo } from '../InfluencerOverviewPerformance';

export const OverviewPerformanceTwitch = ({
  followers,
  view_count,
}: TwitchSocialPage) => {
  return (
    <>
      <BoxInfo
        label="FOLLOWERS"
        info="Total followers."
        stat={followers ? followers : null}
      />
      <BoxInfo
        label="TOTAL VIEWS"
        info="Total lifetime views."
        stat={view_count}
      />
      <BoxInfo
        label="AVG. CCV"
        info="Average number of viewers who watched the stream live over the last 90 days."
        stat={0}
      />
      <BoxInfo
        label="PEAK CCV"
        info="Maximum number of viewers who watched the stream live over the last 90 days."
        stat={0}
      />
      <BoxInfo
        label="DURATION HRS."
        info="Total number of streamed hours over the last 90 days."
        stat={0}
      />
      <BoxInfo
        label="HRS. WATCHED"
        info="Total number of hours watched by the audience over the last 90 days."
        stat={0}
      />
      <BoxInfo
        label="AMA"
        info="Average minute audience (AMA) in the last 90 days is calculated by dividing the total minutes watched by the total minutes broadcasted."
        format="0.[0]a"
        stat={0}
      />
      <BoxInfo
        label="EMV/VIDEO"
        info="This is an estimation for the average media value per video on this channel. The value relies on the videos streamed in the last three months and it's calculated by valuing the price of impressions against a standardized CPM"
        stat={0}
        format="0,0"
        prefix="$"
      />
    </>
  );
};
