import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import OutlinedInput from '../../../components/OutlinedInput';

const useStyles = makeStyles({
  select: {
    padding: 4,
    background: 'white',
  },
  label: {
    fontSize: 16,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
  },
});

type Props = {
  options: { name: string; label?: string }[];
  value: string;
  onChange: (value: string) => void;
  title: string;
};

export const SimpleDropdownFilter = ({
  options,
  value,
  onChange,
  title,
}: Props) => {
  const classes = useStyles();
  const onChangeCb = ({ target: { value } }) => onChange(value);

  if (options.length <= 1) return null;

  return (
    <>
      <span className={classes.label}>{title}: </span>
      <Select
        value={value}
        classes={{ selectMenu: classes.select }}
        input={<OutlinedInput />}
        onChange={onChangeCb}
      >
        <MenuItem value="all" style={{ padding: 10 }}>
          <Typography
            variant="h6"
            style={{
              padding: '3.36px 12px',
              margin: 0,
            }}
          >
            All
          </Typography>
        </MenuItem>
        {options.map(({ name, label }, index) => {
          return (
            <MenuItem key={name} value={name} style={{ padding: 10 }}>
              <Box
                display="flex"
                alignItems="center"
                style={{ padding: '3.36px 12px' }}
              >
                <Typography variant="h6">{label || name}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};
