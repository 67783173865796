import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CardContent,
  CircularProgress,
  FormControlLabel as MuiFormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  makeStyles,
  Switch,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  Delete as DelteIcon,
  ErrorOutline as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { Alert } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { searchInfluencers } from '../../ajax';
import facebookGamingIcon from '../../assets-2.0/facebookGaming.svg';
import facebookIcon from '../../assets-2.0/images-influencer/facebook.svg';
import instagramIcon from '../../assets-2.0/instagram.svg';
import linkIcon from '../../assets-2.0/linkIcon.svg';
import tiktokIcon from '../../assets-2.0/tiktok.svg';
import twitchIcon from '../../assets-2.0/twitch.svg';
import twitterIcon from '../../assets-2.0/twitter.svg';
import youtubeIcon from '../../assets-2.0/youtube.svg';
import FormikMuiTextField from '../../components/FormikMuiTextField';
import { searchIdenticalUsers } from '../../utils/searchIdenticalUsers';
import { CampaignWizardFormValues, doesRequireOnboarding } from './types';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 0,
    '&:first-child': {
      borderTop: 0,
    },
    margin: '0 !important',
  },
})(MuiAccordion);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  removeButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  socialIcon: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  alert: {
    background: '#d6e4ff',
    marginTop: theme.spacing(4),
    border: 'none',
    '& svg': {
      fill: theme.palette.primary.main,
      width: 20,
    },
    '& .MuiAlert-message': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      color: theme.palette.primary.main,
      alignItems: 'center',
      '& div': {
        paddingRight: 10,
      },
    },
    '& button': {
      minWidth: 120,
      height: 'max-content',
    },
  },
}));

const FormControlLabel = withStyles({
  root: {
    margin: 0,
    marginBottom: -6,
  },
  label: {
    fontSize: 12,
    color: '#484848',
  },
})(MuiFormControlLabel);

const LabelWithToggle = ({
  label,
  checked,
  onChange,
  showOnboardingWarning,
}) => (
  <Box display="flex" alignItems="flex-end" justifyContent="space-between">
    <Box display="flex" alignItems="center" style={{ marginBottom: '0.5rem' }}>
      <FormLabel style={{ margin: 0 }}>{label}</FormLabel>

      <Tooltip
        title="This platform requires onboarding"
        disableHoverListener={!showOnboardingWarning}
      >
        <WarningIcon
          fontSize="small"
          style={{ color: '#0048f2', opacity: showOnboardingWarning ? 1 : 0 }}
        />
      </Tooltip>
    </Box>
    <Box display="flex" alignItems="baseline">
      <FormControlLabel
        control={
          <Switch checked={checked} onChange={onChange} color="primary" />
        }
        label="Track channel"
      />
    </Box>
  </Box>
);

interface CampaignWizardInfluencerExpandoProps {
  expanded: boolean;
  onExpandToggle: (event: any, expanded: boolean) => void;
  onRemove: () => void;
  index: number;
  onCheckPlatform: (platform: string) => void;
  shouldFetchPlatfromLinksFromDiscovery: boolean;
}

const CampaignWizardInfluencerExpando = ({
  expanded,
  onExpandToggle,
  onRemove,
  index,
  onCheckPlatform,
  shouldFetchPlatfromLinksFromDiscovery,
}: CampaignWizardInfluencerExpandoProps) => {
  const {
    handleChange,
    errors = {},
    touched,
    setFieldValue,
    values,
  } = useFormikContext<CampaignWizardFormValues>();

  const influencersArray = values.influencersSocials;
  const influencer = influencersArray[index];

  const onboardingUrl = `${window.location.origin}/onboarding/${influencer.token}`;

  const globalInfluencerError = errors?.influencersSocials?.[index];

  const hasGlobalInfluencerError = typeof globalInfluencerError === 'string';

  const classes = useStyles();

  const { data: platformLinks, isLoading } = useQuery(
    `campaign-influencer-platforms-links-${influencer.id}-${influencer.display_name}`,
    async () => {
      try {
        const results = await searchInfluencers(influencer.display_name);

        if (results.length > 0) {
          return results[0];
        }

        return {};
      } catch (e) {
        console.log(e);

        return {};
      }
    },
    {
      enabled: !!(
        shouldFetchPlatfromLinksFromDiscovery &&
        expanded &&
        influencer.id &&
        influencer.display_name
      ),
    }
  );

  useEffect(() => {
    if (platformLinks) {
      const currentValues = influencersArray[index];

      setFieldValue(`influencersSocials[${index}]`, {
        influencerTwitchUrl: platformLinks.twitch_url ?? '',
        influencerYoutubeUrl: platformLinks.youtube_url ?? '',
        influencerInstagramUrl: platformLinks.instagram_url ?? '',
        influencerFacebookUrl: platformLinks.facebook_url ?? '',
        influencerTiktokUrl: platformLinks.tiktok_url ?? '',
        influencerFacebookGamingUrl: platformLinks.facebook_gaming_url ?? '',
        ...currentValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformLinks]); // adding all of the dependencies here will cause an infinite loop

  const arrayErrors = useMemo(
    () => searchIdenticalUsers(influencersArray, index, influencer),
    [index, influencer, influencersArray]
  );

  const fallbackImage = '../../fallback/img.png';
  const onCheckChange = (platform) => () => onCheckPlatform(platform);

  const SocialIcon = (props) => (
    <img className={classes.socialIcon} {...props} alt="social-icon" />
  );

  const isChecked = (platform) =>
    influencer[`influencer${platform}UrlChecked`] || false;

  const hasOnboardingWarning = (platform) =>
    isChecked(platform) && doesRequireOnboarding(platform);

  const getFieldStyle = (platform) => ({
    background: hasOnboardingWarning(platform) ? '#eaf1ff' : '#fff',
  });

  const onChange = (platform) => (e) => {
    if (!isChecked(platform) && e.target.value) onCheckPlatform(platform);

    handleChange(e);
  };

  const renderForm = () => (
    <Box flex={1}>
      <CardContent>
        {touched.influencersSocials && hasGlobalInfluencerError && (
          <FormHelperText error>{globalInfluencerError}</FormHelperText>
        )}
      </CardContent>
      <CardContent>
        <LabelWithToggle
          label="Twitch Profile"
          checked={isChecked('Twitch')}
          onChange={onCheckChange('Twitch')}
          showOnboardingWarning={hasOnboardingWarning('Twitch')}
        />
        <FormikMuiTextField
          fullWidth
          name={`influencersSocials[${index}].influencerTwitchUrl`}
          placeholder="Enter Influencer Twitch link"
          variant="outlined"
          value={influencer.influencerTwitchUrl}
          onChange={onChange('Twitch')}
          style={getFieldStyle('Twitch')}
        />
      </CardContent>
      <CardContent>
        <LabelWithToggle
          label="YouTube channel"
          checked={isChecked('Youtube')}
          onChange={onCheckChange('Youtube')}
          showOnboardingWarning={hasOnboardingWarning('Youtube')}
        />
        <FormikMuiTextField
          fullWidth
          name={`influencersSocials[${index}].influencerYoutubeUrl`}
          placeholder="Enter Influencer Youtube link"
          variant="outlined"
          value={influencer.influencerYoutubeUrl}
          onChange={onChange('Youtube')}
          style={getFieldStyle('Youtube')}
        />
      </CardContent>
      <CardContent>
        <LabelWithToggle
          label="Twitter account"
          checked={isChecked('Twitter')}
          onChange={onCheckChange('Twitter')}
          showOnboardingWarning={hasOnboardingWarning('Twitter')}
        />
        <FormikMuiTextField
          fullWidth
          name={`influencersSocials[${index}].influencerTwitterUrl`}
          placeholder="Enter Influencer Twitter link"
          variant="outlined"
          value={influencer.influencerTwitterUrl}
          onChange={onChange('Twitter')}
          style={getFieldStyle('Twitter')}
        />
      </CardContent>

      <CardContent>
        <LabelWithToggle
          label="Instagram account"
          checked={isChecked('Instagram')}
          onChange={onCheckChange('Instagram')}
          showOnboardingWarning={hasOnboardingWarning('Instagram')}
        />
        <FormikMuiTextField
          fullWidth
          name={`influencersSocials[${index}].influencerInstagramUrl`}
          placeholder="Enter Influencer Instagram link"
          variant="outlined"
          value={influencer.influencerInstagramUrl}
          onChange={onChange('Instagram')}
          style={getFieldStyle('Instagram')}
        />
      </CardContent>

      <CardContent>
        <LabelWithToggle
          label="TikTok account"
          checked={isChecked('Tiktok')}
          onChange={onCheckChange('Tiktok')}
          showOnboardingWarning={hasOnboardingWarning('Tiktok')}
        />
        <FormikMuiTextField
          fullWidth
          name={`influencersSocials[${index}].influencerTiktokUrl`}
          placeholder="Enter Influencer TikTok link"
          variant="outlined"
          value={influencer.influencerTiktokUrl}
          onChange={onChange('Tiktok')}
          style={getFieldStyle('Tiktok')}
        />
      </CardContent>

      <CardContent>
        <LabelWithToggle
          label="Facebook Gaming Page"
          checked={isChecked('FacebookGaming')}
          onChange={onCheckChange('FacebookGaming')}
          showOnboardingWarning={hasOnboardingWarning('FacebookGaming')}
        />
        <FormikMuiTextField
          fullWidth
          name={`influencersSocials[${index}].influencerFacebookGamingUrl`}
          placeholder="Enter Influencer Facebook Gaming link"
          variant="outlined"
          value={influencer.influencerFacebookGamingUrl}
          onChange={onChange('FacebookGaming')}
          style={getFieldStyle('FacebookGaming')}
        />
      </CardContent>

      <CardContent>
        <LabelWithToggle
          label="Facebook Page"
          checked={isChecked('Facebook')}
          onChange={onCheckChange('Facebook')}
          showOnboardingWarning={hasOnboardingWarning('Facebook')}
        />
        <FormikMuiTextField
          fullWidth
          name={`influencersSocials[${index}].influencerFacebookUrl`}
          placeholder="Enter Influencer Facebook link"
          variant="outlined"
          value={influencer.influencerFacebookUrl}
          onChange={onChange('Facebook')}
          style={getFieldStyle('Facebook')}
        />
      </CardContent>

      <CardContent>
        <Alert className={classes.alert} icon={<WarningIcon />} severity="info">
          <div>
            The highlighted channels require owner's authorization for tracking.
            <br />
            Please send the following link to the creator for onboarding:
            <br />
            <b>{onboardingUrl}</b>
          </div>

          {navigator.clipboard && ( // hide button if clipboard API is not supported
            <Button
              onClick={(event) => {
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(onboardingUrl);
                }
              }}
              variant="contained"
              color="primary"
              startIcon={<img alt="linkIcon" src={linkIcon} />}
            >
              Copy URL
            </Button>
          )}
        </Alert>
      </CardContent>
    </Box>
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={onExpandToggle}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Box display="flex" alignItems="center">
            <Avatar
              src={
                influencer.profile_image_url
                  ? influencer.profile_image_url
                  : fallbackImage
              }
              className={classes.avatar}
              alt={influencer.display_name?.toUpperCase()}
            />
            <Typography style={{ fontWeight: 600, fontSize: 18 }}>
              {influencer.display_name}
            </Typography>
            {influencer.influencerTwitchUrl && isChecked('Twitch') && (
              <SocialIcon src={twitchIcon} />
            )}
            {influencer.influencerYoutubeUrl && isChecked('Youtube') && (
              <SocialIcon src={youtubeIcon} />
            )}
            {influencer.influencerFacebookUrl && isChecked('Facebook') && (
              <SocialIcon src={facebookIcon} />
            )}
            {influencer.influencerFacebookGamingUrl &&
              isChecked('FacebookGaming') && (
                <SocialIcon src={facebookGamingIcon} />
              )}
            {influencer.influencerTwitterUrl && isChecked('Twitter') && (
              <SocialIcon src={twitterIcon} />
            )}
            {influencer.influencerTiktokUrl && isChecked('Tiktok') && (
              <SocialIcon src={tiktokIcon} />
            )}
            {influencer.influencerInstagramUrl && isChecked('Instagram') && (
              <SocialIcon src={instagramIcon} />
            )}
          </Box>
          <Box display="flex" alignItems="center">
            {errors.influencersSocials && errors.influencersSocials[index]
              ? Object.values(errors.influencersSocials[index]).filter(
                  (e) => !!e
                ).length > 0 && (
                  <Tooltip title="The creator details are incorrect or incomplete">
                    <ErrorIcon className={classes.removeButton} />
                  </Tooltip>
                )
              : arrayErrors.length !== 0 && (
                  <Tooltip title="The creator details are incorrect or incomplete">
                    <ErrorIcon className={classes.removeButton} />
                  </Tooltip>
                )}
            <IconButton
              className={classes.removeButton}
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
            >
              <DelteIcon />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading && shouldFetchPlatfromLinksFromDiscovery ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={200}
            width="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          renderForm()
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CampaignWizardInfluencerExpando;
