import { makeStyles, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';

import facebookIcon from '../../../assets-2.0/facebook.svg';
import instagramIcon from '../../../assets-2.0/instagram.svg';
import tiktokIcon from '../../../assets-2.0/tiktok.svg';
import twitterIcon from '../../../assets-2.0/twitter.svg';
import getMediaUrl from '../../../utils/getMediaUrl';

export const ROW_HEIGHT = 148;

const useMentionTextStyles = makeStyles((theme) => ({
  container: {
    height: ROW_HEIGHT,
    paddingTop: 8,
    '&:hover a': {
      opacity: 1,
    },
  },
  grid: {
    paddingTop: 8,
    display: 'grid',
    columnGap: 8,
  },
  text: {
    fontSize: '1rem',
    lineHeight: '1.33rem',
    '--lines': 4,
    margin: 0,
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
  },
  postImage: {
    maxWidth: '90%',
    maxHeight: ROW_HEIGHT * 0.7,
    display: 'block',
  },
}));

export const PostCell = ({ text, image, link, platform, date }) => {
  const classes = useMentionTextStyles();
  const PLATFORM_ICONS = {
    tiktok: tiktokIcon,
    instagram: instagramIcon,
    twitter: twitterIcon,
    facebook: facebookIcon,
  };

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={PLATFORM_ICONS[platform]}
          alt="platform logo"
          style={{ height: '1.5em', marginRight: 8 }}
        />
        <Typography
          color="textSecondary"
          style={{ fontSize: '0.8rem', flex: 1 }}
        >
          {date}
        </Typography>
      </div>
      <div
        className={classes.grid}
        style={{
          gridTemplateColumns: image ? '25% 75%' : '100%',
        }}
      >
        {image && (
          <div>
            <img
              alt="post media"
              src={getMediaUrl(image)}
              className={classes.postImage}
            />
          </div>
        )}
        <div>
          <p
            style={{ maxWidth: '450px' }}
            dangerouslySetInnerHTML={{ __html: text }}
            className={`text-ellipsis ${classes.text}`}
          />
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <OpenInNewIcon fontSize="inherit" style={{ marginRight: 4 }} />
            <span>VIEW {platform === 'twitter' ? 'TWEET' : 'POST'}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PostCell;
