import { all, fork } from 'redux-saga/effects';

import appMountedSaga from './app-mounted';
import campaignCreatedSaga from './campaign-created';
import campaignCreationSaga from './campaign-creation';
import campaignDeleteClickedSaga from './campaign-delete-clicked';
import campaignMountedSaga from './campaign-mounted';
import campaignSocialPostDeleteSaga from './campaign-social-post-delete-clicked';
import campaignUpdateSaga from './campaign-update';
import campaignVideoDeleteClickedSaga from './campaign-video-delete-clicked';
import campaignsMountedSaga from './campaigns-mounted';
import editWatchlist from './edit-watchlist';
import influencersMountedSaga from './influencers-mounted';
import mixpanelSaga from './mixpanel';
import brandCreatedSaga from './my-brands-creation';
import myBrandDelete from './my-brands-delete-clicked';
import myBrandsMounted from './my-brands-mounted';
import brandUpdateSaga from './my-brands-update';
import requestFailedSaga from './request-failed';
import toastSpawnSaga from './toast-spawn';

export default function* () {
  yield all([
    fork(appMountedSaga),
    fork(campaignMountedSaga),
    fork(myBrandsMounted),
    fork(brandUpdateSaga),
    fork(myBrandDelete),
    fork(brandCreatedSaga),
    fork(campaignCreatedSaga),
    fork(campaignCreationSaga),
    fork(campaignUpdateSaga),
    fork(campaignsMountedSaga),
    fork(campaignDeleteClickedSaga),
    fork(campaignVideoDeleteClickedSaga),
    fork(campaignSocialPostDeleteSaga),
    fork(influencersMountedSaga),
    fork(requestFailedSaga),
    fork(toastSpawnSaga),
    fork(mixpanelSaga),
    fork(editWatchlist),
  ]);
}
