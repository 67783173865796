/*
 * TODO: parametrize once authorization & organizations are in place
 */
export const selectCampaignsByOrganizationId = ({ campaigns: { byId } }) =>
  Object.values(byId);

export const selectCampaignById =
  (campaignId) =>
  ({ campaigns: { byId } }) =>
    byId[campaignId];

export const getTrialAccountCampaignsExceeded = ({
  account: { firebaseData },
  campaigns: { byId },
}) =>
  firebaseData && !!firebaseData.trial_account && Object.keys(byId).length >= 1;
