import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import { connect } from 'formik';
import React, { useContext, useEffect } from 'react';

import usePrevious from '../../hooks/use-previous';
import { CampaignWizardContext } from './CampaignWizard';

const useStepIconStyles = makeStyles((theme) => ({
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: '1px solid gray',
    opacity: 0.5,
  },
  completed: {
    background: '#59c9a5',
    borderColor: '#59c9a5',
    opacity: 1,
    padding: 3,
    borderRadius: '50%',
    fill: '#fff',
  },
  active: {
    border: '2px solid #59c9a5',
    opacity: 1,
  },
  error: {
    background: theme.palette.error.main,
    opacity: 1,
  },
  warning: {
    color: theme.palette.error.main,
  },
}));

function StepIcon(props) {
  const classes = useStepIconStyles();

  const { active, completed, error } = props;

  if (error) {
    return <WarningIcon className={classes.warning} />;
  }
  if (completed) {
    return <CheckIcon className={classes.completed} />;
  }

  return (
    <div
      className={clsx(classes.circle, {
        [classes.active]: active,
        [classes.error]: error,
      })}
    />
  );
}

const useStyles = makeStyles({
  step: {
    cursor: 'pointer',
  },
  stepper: {
    margin: 'auto',
    maxWidth: 862,
    backgroundColor: 'inherit',
    border: 'none',
    '& .MuiStepConnector-completed span': {
      borderColor: '#59c9a5',
    },
    '& .MuiStepConnector-active span': {
      borderColor: '#59c9a5',
    },
  },
  stepInline: {
    inlineSize: 'max-content',
  },
});

function CampaignWizardStepper(props) {
  const { step, steps, formik } = props;
  const { setStep } = useContext(CampaignWizardContext);

  const classes = useStyles();

  const previousStep = usePrevious(step);

  useEffect(() => {
    if (step > previousStep) {
      steps[previousStep].fields.forEach(formik.setFieldTouched);
    }
  }, [step, previousStep]);

  function renderStep({ title, fields }, i) {
    const passedThisStep = step > i;

    const fieldHasError = Object.keys(formik.errors).some((key) =>
      fields.includes(key)
    );

    const fieldIsTouched = Object.keys(formik.touched).some((key) =>
      fields.includes(key)
    );

    return (
      <Step
        className={classes.step}
        key={title}
        onClick={() => passedThisStep && setStep(i)}
      >
        <StepLabel
          className={classes.stepInline}
          error={passedThisStep && fieldHasError && fieldIsTouched}
          StepIconComponent={StepIcon}
        >
          {title}
        </StepLabel>
      </Step>
    );
  }

  return (
    <Stepper className={classes.stepper} activeStep={step}>
      {steps.map(renderStep)}
    </Stepper>
  );
}

export default connect(CampaignWizardStepper);
