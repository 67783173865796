import { call, put, takeLatest } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import {
  CAMPAIGN_MULTIPLE_VIDEOS_DELETED_CLICKED,
  CAMPAIGN_VIDEO_DELETE_CLICKED,
} from '../action-types';
import {
  campaignMultipleVideosDeleteFailed,
  campaignMultipleVideosDeleteRequested,
  campaignMultipleVideosDeleteSucceeded,
  campaignVideoDeleteFailed,
  campaignVideoDeleteRequested,
  campaignVideoDeleteSucceeded,
} from '../models/campaigns/campaigns.actions';

function* deleteCampaignVideo(action) {
  const {
    payload: { id, campaignId },
  } = action;

  yield put(campaignVideoDeleteRequested({ id }));

  try {
    yield call(ajax.deleteCampaignVideo, id, campaignId);

    yield put(campaignVideoDeleteSucceeded(action.payload));

    // yield put(campaignMounted({ campaignId })); // Refetch campaign
  } catch (e) {
    console.error('Campaign Video Delete Error - ', e);
    yield put(
      campaignVideoDeleteFailed({
        error:
          (e.bodyJson && e.bodyJson.errors) || 'Campaign Video Delete Error',
        id,
      })
    );
  }
}

function* deleteMultipleCampaignVideos(action) {
  const {
    payload: { videoIds, campaignId },
  } = action;

  yield put(campaignMultipleVideosDeleteRequested({ videoIds }));

  try {
    yield call(ajax.deleteMultipleCampaignVideos, { videoIds, campaignId });

    yield put(campaignMultipleVideosDeleteSucceeded({ videoIds, campaignId }));

    // yield put(campaignMounted({ campaignId })); // Refetch campaign
  } catch (e) {
    console.error('Campaign Multiple Videos Delete Error - ', e);
    yield put(
      campaignMultipleVideosDeleteFailed({
        error:
          (e.bodyJson && e.bodyJson.errors) ||
          'Campaign Multiple Videos Delete Error',
        videoIds,
      })
    );
  }
}

export default function* root() {
  yield takeLatest(CAMPAIGN_VIDEO_DELETE_CLICKED, deleteCampaignVideo);
  yield takeLatest(
    CAMPAIGN_MULTIPLE_VIDEOS_DELETED_CLICKED,
    deleteMultipleCampaignVideos
  );
}
