import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { selectors } from '../../store/selectors';

const mapStateToProps = (state) => {
  return {
    isInfluencerView: selectors.isInfluencerView(state),
  };
};

export default compose(connect(mapStateToProps), withRouter);
