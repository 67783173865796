import _orderBy from 'lodash/orderBy';
import memoize from 'proxy-memoize';

import { State } from '../../../../../store/types';
import { LoadingState, shouldFetch, shouldShowLoading } from '../../../types';
import { BrandFilter } from '../../types';
import { filterBrandDeliverables } from '../../utils';
import { BannerDeliverableStructure, SLICE_NAME } from './types';

const memoizedFilteredBannerDeliverablesStructures = memoize(
  ({
    campaignId,
    onlyBrandDeliverables,
    brandIdFilter,
    state,
    sortBy,
  }: {
    campaignId: number;
    onlyBrandDeliverables: boolean;
    brandIdFilter: BrandFilter;
    state: State;
    sortBy?: { key: keyof BannerDeliverableStructure; order: 'asc' | 'desc' };
  }) => {
    const allBannerDeliverables =
      state.campaigns.byId[campaignId]?.banner_deliverables ?? [];

    return _orderBy(
      filterBrandDeliverables(
        allBannerDeliverables,
        onlyBrandDeliverables,
        brandIdFilter
      ),
      [sortBy.key || 'image'],
      [sortBy.order]
    );
  }
);

export const selectFilteredBannerDeliverablesStructures =
  ({
    campaignId,
    onlyBrandDeliverables,
    brandIdFilter,
    sortBy,
  }: {
    campaignId: number;
    onlyBrandDeliverables: boolean;
    brandIdFilter: BrandFilter;
    sortBy?: { key: keyof BannerDeliverableStructure; order: 'asc' | 'desc' };
  }) =>
  (state: State) =>
    memoizedFilteredBannerDeliverablesStructures({
      campaignId,
      onlyBrandDeliverables,
      brandIdFilter,
      state,
      sortBy,
    });

export const selectBannerDeliverableLoadingState =
  (bannerDeliverableId: number) => (state: State) =>
    state[SLICE_NAME].loadingMap[bannerDeliverableId] ?? LoadingState.IDLE;

export const selectBannerDeliverableShowLoading =
  (bannerDeliverableId: number) => (state: State) =>
    shouldShowLoading(
      selectBannerDeliverableLoadingState(bannerDeliverableId)(state)
    );

export const selectShouldFetchBannerDeliverable =
  (bannerDeliverableId: number) => (state: State) =>
    shouldFetch(
      selectBannerDeliverableLoadingState(bannerDeliverableId)(state)
    );

export const selectBannerDeliverableById =
  (bannerDeliverableId: number) => (state: State) =>
    state[SLICE_NAME].entities[bannerDeliverableId];

export const selectBannerDeliverablesError = () => (state: State) =>
  state[SLICE_NAME].error;

const memoizedAllBannerDeliverableIdsByCampaignId = memoize(
  ({ campaignId, state }: { campaignId: number; state: State }) =>
    state.campaigns.byId[campaignId]?.banner_deliverables.map(
      (deliverable) => deliverable.id
    ) ?? []
);

export const selectAllBannerDeliverableIdsByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedAllBannerDeliverableIdsByCampaignId({ campaignId, state });

// used by PDF report
const memoizedAllBannerDeliverablesByCampaignId = memoize(
  ({ campaignId, state }: { campaignId: number; state: State }) => {
    const allBannerDeliverableIds =
      selectAllBannerDeliverableIdsByCampaignId(campaignId)(state);

    return allBannerDeliverableIds.map((id) =>
      selectBannerDeliverableById(id)(state)
    );
  }
);

export const selectAllBannerDeliverablesByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedAllBannerDeliverablesByCampaignId({ campaignId, state });

export const selectAllBannerDeliverablesByCampaignIdShowLoading =
  (campaignId: number) => (state: State) => {
    const ids = selectAllBannerDeliverableIdsByCampaignId(campaignId)(state);

    return ids
      .map((id) => selectBannerDeliverableShowLoading(id)(state))
      .some((showLoading) => showLoading);
  };

const memoizedBannerDeliverablesToFetchByCampaignId = ({
  campaignId,
  state,
}: {
  campaignId: number;
  state: State;
}) => {
  const ids = selectAllBannerDeliverableIdsByCampaignId(campaignId)(state);

  return ids.filter((id) => selectShouldFetchBannerDeliverable(id)(state));
};

export const selectBannerDeliverablesToFetchByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedBannerDeliverablesToFetchByCampaignId({ campaignId, state });
