import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    top: 0,
    left: 0,
    zIndex: 102,
    width: '100%',
    position: 'absolute',
    background: '#f4f6f8',
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
});

function TopBar(props) {
  const { children } = props;

  const classes = useStyles();

  return (
    <Paper square elevation={0} className={classes.root}>
      {children}
    </Paper>
  );
}

export default TopBar;
