import { alpha, Box, InputBase, LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Container from '../../components/Container';
import TopBar from '../../components/TopBar';
import TopBarContent from '../../components/TopBarContent';
import TopBarTitle from '../../components/TopBarTitle';
import mapSelectorsToProps from '../../helpers/map-selectors-to-props';
import { campaignsMounted } from '../../store/events';
import { setCampaignTab } from '../../store/models/campaign-tab/campaign-tab.actions';
import { getCampaignTab } from '../../store/models/campaign-tab/campaign-tab.selectors';
import {
  getTrialAccountCampaignsExceeded,
  selectCampaignsByOrganizationId,
} from '../../store/models/campaigns/campaigns.selectors';
import { selectCommunication } from '../../store/models/communication/communication.selectors';
import {
  getDisableTrackCampaignButton,
  getIsViewOnly,
} from '../../store/models/user/user.selectors';
import { CampaignsDisplayed } from './CampaignsDisplayed';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 20,
    marginTop: 65,
    maxHeight: 'calc(100% - 70px)',
    minHeight: 'auto',
  },
  disabled: {
    pointerEvents: 'none',
  },
  search: {
    position: 'relative',
    border: '1px solid #333',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '16ch',
    },
  },
}));

const enhance = connect(
  mapSelectorsToProps({
    campaigns: selectCampaignsByOrganizationId,
    campaignsCommunication: selectCommunication('campaigns'),
    disableTrackCampaignButton: getDisableTrackCampaignButton,
    trialCampaignsExceeded: getTrialAccountCampaignsExceeded,
  })
);

const getDisplayedCampaigns = (campaignTab, campaigns) => {
  let displayedCampaigns;

  if (campaigns.length) {
    if (campaignTab === 'all') {
      displayedCampaigns = campaigns;
    } else {
      displayedCampaigns = campaigns.filter(({ campaign_days }) => {
        const endsInTheFuture = moment().isBefore(
          moment(campaign_days.sort()[campaign_days.length - 1]).endOf('day')
        );
        return campaignTab === 'active' ? endsInTheFuture : !endsInTheFuture;
      });
    }
    displayedCampaigns = _orderBy(
      displayedCampaigns,
      (campaign) => {
        return moment(campaign.campaign_days.sort()[0]).unix();
      },
      ['desc']
    );
  } else displayedCampaigns = [];

  return displayedCampaigns;
};

function Campaigns(props) {
  const { t } = useTranslation();

  document.title = 'Campaigns';
  const {
    campaigns,
    campaignsCommunication: { loading },
    disableTrackCampaignButton,
    trialCampaignsExceeded,
  } = props;

  const classes = useStyles();

  const campaignTab = useSelector(getCampaignTab);
  const dispatch = useDispatch();

  const isViewOnly = useSelector(getIsViewOnly);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(function () {
    props.dispatch(campaignsMounted());
  }, []);

  const displayedCampaigns = getDisplayedCampaigns(campaignTab, campaigns);

  const shouldDisableTrackCampaign =
    loading || disableTrackCampaignButton || trialCampaignsExceeded;
  return (
    <>
      <TopBar>
        <TopBarContent>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={4}
          >
            <Grid item style={{ flex: 1 }}>
              <TopBarTitle>{t('campaigns.campaignsList.title')}</TopBarTitle>
            </Grid>
            <Grid item style={{ padding: '10px' }}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  value={searchTerm}
                  onChange={({ target: { value } }) => setSearchTerm(value)}
                />
              </div>
            </Grid>
            <Grid item>
              <Box mr={2} display="inline">
                Show:
              </Box>
              <ToggleButtonGroup
                value={campaignTab}
                size="small"
                onChange={({ currentTarget: { value } }) =>
                  dispatch(setCampaignTab(value))
                }
              >
                <ToggleButton value="all">all</ToggleButton>
                <ToggleButton value="active">active</ToggleButton>
                <ToggleButton value="ended">ended</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {!isViewOnly && (
              <Grid item>
                <Link
                  to="/track-campaign/basic"
                  className={clsx({
                    [classes.disabled]: shouldDisableTrackCampaign,
                  })}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={shouldDisableTrackCampaign}
                  >
                    start tracking
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>
        </TopBarContent>
      </TopBar>

      {loading && (
        <Box width="100%" position="absolute" top={0} left={0} zIndex={1}>
          <LinearProgress variant="query" />
        </Box>
      )}

      <Container className={classes.container}>
        {displayedCampaigns.length === 0 &&
          !loading &&
          trialCampaignsExceeded &&
          campaignTab === 'active' && (
            <h5>
              {' '}
              This feature is limited for trial accounts. <br /> You can track
              only one campaign on your account. Please upgrade your plan.{' '}
              <br /> Click{' '}
              <button
                className="button-link"
                onClick={() => dispatch(setCampaignTab('ended'))}
              >
                {' '}
                here{' '}
              </button>{' '}
              to see your past campaign!{' '}
            </h5>
          )}
        {displayedCampaigns.length === 0 &&
          !loading &&
          disableTrackCampaignButton &&
          campaignTab === 'active' && (
            <h5>
              {' '}
              This feature is disabled for trial accounts. <br /> Please upgrade
              your plan. <br />{' '}
              <button
                className="button-link"
                onClick={() => dispatch(setCampaignTab('ended'))}
              >
                Click here
              </button>{' '}
              to check a campaign sample.
            </h5>
          )}
        {displayedCampaigns.length === 0 &&
          !loading &&
          !disableTrackCampaignButton &&
          !trialCampaignsExceeded &&
          (campaignTab === 'active' ? (
            <h5>
              {' '}
              You don't have active campaigns. Click{' '}
              <Link to="/track-campaign/basic"> here </Link> to create one!{' '}
            </h5>
          ) : (
            <h5> You don't have ended campaigns. </h5>
          ))}
        {displayedCampaigns.length > 0 && (
          <Grid container spacing={4} direction="column" wrap="nowrap">
            <CampaignsDisplayed
              searchTerm={searchTerm}
              displayedCampaigns={displayedCampaigns}
            />
          </Grid>
        )}
      </Container>
    </>
  );
}

export default enhance(Campaigns);
