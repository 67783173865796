import _mean from 'lodash/mean';
import _sum from 'lodash/sum';
import moment from 'moment';

import { ChatMentionDeliverable } from '../DeliverablesTab/ChatMentions/store/types';
import { ShoutoutDeliverableV2 } from '../DeliverablesTab/Shoutouts/store/types';

export const INTERCHART_PADDING = 48;

export type BrushBounds = {
  start: number;
  end: number;
};

export interface CCVData {
  count: number;
  label: string;
  start_at: string;
}

export type MentionsData = {
  label: string;
  [key: string]: number | string;
};

export type CCVMentionsData = CCVData & MentionsData;

export const ccvGetTime = (d: CCVData) => moment(d.start_at).unix();
export const ccvGetCCV = (d: CCVData) => d.count;

export const ccvGetLabel = (d: CCVData) => d.label;

export const mentionsGetMentionsKeys = (d: MentionsData) =>
  Object.keys(d).filter((k) => k !== 'label' && k !== 'start_at');

export const mentionsGetMentions = (d: CCVMentionsData) =>
  mentionsGetMentionsKeys(d).reduce((prev, k) => {
    prev[k] = d[k];

    return prev;
  }, {} as { [key: string]: number | string });

export const mentionsGetMentionsSum = (d: CCVMentionsData) =>
  _sum(Object.values(mentionsGetMentions(d)));

export const mentionsGetLabel = (d: CCVMentionsData) => d.label;

export const getBarSize = (duration: number) => {
  if (duration > 3600 * 16) {
    // over 16 hours
    return 15 * 60; // 15 minutes
  } else if (duration > 3600 * 8) {
    // 8 hours
    return 10 * 60; // 10 minutes
  } else {
    return 5 * 60;
  }
};

export const groupBarData = (
  data: { label: string; time: number }[],
  duration
) => {
  const barSize = getBarSize(duration);

  // create an object with zeros for each label
  const defaultKeys = Array.from(new Set(data.map((d) => d.label))).reduce(
    (prev, label) => {
      prev[label] = 0;
      return prev;
    },
    {} as { [key: string]: number }
  );

  const countsByBarIndex: { [key: number]: { [key: string]: number } } = {};

  data.forEach(({ label, time }) => {
    const barIndex = Math.floor(time / barSize);

    if (!countsByBarIndex[barIndex]) {
      countsByBarIndex[barIndex] = {};
    }
    if (!countsByBarIndex[barIndex][label]) {
      countsByBarIndex[barIndex][label] = 0;
    }

    countsByBarIndex[barIndex][label] += 1;
  });

  return Array.from({ length: Math.ceil(duration / barSize) + 1 }, (_, i) => {
    const second = i * barSize;

    const counts = { ...defaultKeys, ...countsByBarIndex[i] };

    return {
      // format as hh:mm
      label: `${String(Math.floor(second / 3600)).padStart(2, '0')}:${String(
        Math.floor((second % 3600) / 60)
      ).padStart(2, '0')}`,
      ...counts,
    };
  });
};

// group shoutouts in intervals of duration (in seconds)
export const groupShoutouts = ({
  deliverables,
  duration,
  startTime,
  videoId,
}: {
  deliverables: ShoutoutDeliverableV2[];
  duration: number;
  startTime: string;
  videoId: number;
}) => {
  return groupBarData(
    // flatten all mentions into a single array
    [].concat(
      ...deliverables.map(({ name, shoutouts }) =>
        shoutouts
          // filter by video id
          .filter(({ video_id }) => videoId === video_id)
          // format as { label: keyword, time: published_at }
          .map(({ segment_start }) => ({
            label: name,
            time: segment_start,
          }))
      )
    ),
    duration
  );
};

export const groupMentions = ({
  deliverables,
  duration,
  startTime,
  videoId,
}: {
  deliverables: ChatMentionDeliverable[];
  duration: number;
  startTime: string;
  videoId: number;
}) => {
  const startUnix = moment(startTime).unix();

  return groupBarData(
    // flatten all mentions into a single array
    [].concat(
      ...deliverables.map(({ keyword, video_comments }) =>
        video_comments
          // filter by video id
          .filter(({ video_id }) => videoId === video_id)
          // format as { label: keyword, time: published_at }
          .map(({ published_at }) => ({
            label: keyword,
            time: moment(published_at).unix() - startUnix,
          }))
      )
    ),
    duration
  );
};

export const groupCCV = ({
  duration,
  ccvData,
  startTime,
}: {
  duration: number;
  ccvData: CCVData[];
  startTime: string;
}) => {
  let index = 0;

  const groups = [[]];
  const barSize = getBarSize(duration);

  ccvData.forEach((data) => {
    const time = ccvGetTime(data) - moment(startTime).unix();

    if (time > index * barSize) {
      index += 1;
      groups.push([]);
    }
    groups[index].push(data);
  });

  let prevTime = startTime;

  return groups.map((group, index) => {
    if (group.length > 0) prevTime = group[0].start_at;

    let secs = index * barSize;

    return {
      label: `${String(Math.floor(secs / 3600)).padStart(2, '0')}:${String(
        Math.floor((secs % 3600) / 60)
      ).padStart(2, '0')}`,
      count: Math.round(_mean(group.map((g) => g.count))) || 0,
      start_at: prevTime,
    };
  });
};

export const STACK_COLORS = [
  '#4cd964',
  '#007aff',
  '#ff3b30',
  '#5ac8fa',
  '#ffcc00',
  '#5856d6',
  '#ff9500',
  '#ff2d55',
];
