const imageSizeCache = {};

const getImageSize = (url) =>
  new Promise((resolve) => {
    if (url in imageSizeCache) return resolve(imageSizeCache[url]);

    const img = new Image();
    img.src = url;
    img.onload = () => {
      imageSizeCache[url] = { width: img.width, height: img.height };
      resolve(imageSizeCache[url]);
    };
  });

export default getImageSize;
