import _sumBy from 'lodash/sumBy';
import momentTz from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';

import InfluencerAvatar from '../../components/InfluencerAvatar';
import Table, { StatBox } from '../../Table';

export const computeSummary = ({
  instagramPosts,
  twitterPosts,
  tiktokPosts,
  facebookPosts,
}) => {
  const twitterMentions = twitterPosts.length;
  const instagramMentions = instagramPosts.length;
  const tiktokMentions = tiktokPosts.length;
  const facebookMentions = facebookPosts.length;

  const mentions =
    twitterMentions + instagramMentions + tiktokMentions + facebookMentions;

  const posts = instagramPosts
    .concat(twitterPosts)
    .concat(tiktokPosts)
    .concat(facebookPosts);

  const impressionsAreEst = posts.some(
    ({ est_impression_count }) => !!est_impression_count
  );

  const likes =
    (_sumBy(instagramPosts, 'likes') || 0) +
    (_sumBy(twitterPosts, 'favorites') || 0) +
    (_sumBy(tiktokPosts, 'likes_count') || 0) +
    (_sumBy(facebookPosts, 'likes_count') || 0);

  const comments =
    (_sumBy(instagramPosts, 'comment_count') || 0) +
    (_sumBy(twitterPosts, 'replies') || 0) +
    (_sumBy(tiktokPosts, 'comments_count') || 0) +
    (_sumBy(facebookPosts, 'comments_count') || 0);

  // instagram posts share count is not available
  const shares =
    (_sumBy(twitterPosts, 'retweets') || 0) +
    (_sumBy(tiktokPosts, 'shares_count') || 0) +
    (_sumBy(facebookPosts, 'shares_count') || 0);

  const totalEngagements = likes + comments + shares;

  const totalImpressions =
    _sumBy(
      posts,
      (p) => p.impression_count || p.impressions || p.est_impression_count
    ) || 0;
  const totalEMV = _sumBy(posts, 'emv') || 0;

  return {
    shares,
    mentions,
    twitterMentions,
    instagramMentions,
    tiktokMentions,
    facebookMentions,
    likes,
    comments,
    totalEngagements,
    totalImpressions,
    totalEMV,
    impressionsAreEst,
  };
};

const getRows = ({
  usedInfluencers,
  twitterPosts,
  instagramPosts,
  tiktokPosts,
  facebookPosts,
}) =>
  usedInfluencers.map((influencer) => {
    const filter = (p) => p.influencer_id === influencer.id;

    const {
      mentions,
      twitterMentions,
      instagramMentions,
      tiktokMentions,
      facebookMentions,
      totalEngagements,
      totalImpressions,
      totalEMV,
      impressionsAreEst,
    } = computeSummary({
      twitterPosts: twitterPosts.filter(filter),
      instagramPosts: instagramPosts.filter(filter),
      tiktokPosts: tiktokPosts.filter(filter),
      facebookPosts: facebookPosts.filter(filter),
    });

    return {
      influencer,
      creatorOrDate: influencer.name,
      mentions,
      twitterMentions,
      instagramMentions,
      tiktokMentions,
      facebookMentions,
      totalEngagements,
      totalImpressions,
      totalEMV,
      impressionsAreEst,
      key: influencer.id,
    };
  });

const getRowsDay = ({
  twitterPosts,
  instagramPosts,
  timeArray,
  timeZone,
  tiktokPosts,
  facebookPosts,
}) =>
  timeArray.map((influencer) => {
    const socialInst = instagramPosts.filter((s) => {
      const timeObject = momentTz.tz(s.published_at, timeZone);
      return timeObject.format('Do MMM YYYY') === influencer.time;
    });
    const socialTwit = twitterPosts.filter((s) => {
      const timeObject = momentTz.tz(s.published_at, timeZone);
      return timeObject.format('Do MMM YYYY') === influencer.time;
    });
    const socialTiktok = tiktokPosts.filter((s) => {
      const timeObject = momentTz.tz(s.published_at, timeZone);
      return timeObject.format('Do MMM YYYY') === influencer.time;
    });
    const socialFacebook = facebookPosts.filter((s) => {
      const timeObject = momentTz.tz(s.published_at, timeZone);
      return timeObject.format('Do MMM YYYY') === influencer.time;
    });

    const {
      mentions,
      twitterMentions,
      instagramMentions,
      tiktokMentions,
      facebookMentions,
      totalEngagements,
      totalImpressions,
      totalEMV,
      impressionsAreEst,
    } = computeSummary({
      twitterPosts: socialTwit,
      instagramPosts: socialInst,
      tiktokPosts: socialTiktok,
      facebookPosts: socialFacebook,
    });

    return {
      influencer: influencer.time,
      creatorOrDate: influencer.unixTime,
      mentions,
      twitterMentions,
      tiktokMentions,
      facebookMentions,
      instagramMentions,
      totalEngagements,
      totalImpressions,
      totalEMV,
      impressionsAreEst,
      key: influencer.id,
    };
  });

const getCols = (
  platformFilter,
  impressionsAreEst,
  isFilteredByCreator,
  isSponsorship
) =>
  [
    {
      label: isSponsorship
        ? isFilteredByCreator
          ? 'Creator'
          : 'Day'
        : 'Creator',
      key: 'creatorOrDate',
      render: (row) => <InfluencerAvatar influencer={row.influencer} />,
      stretch: true,
    },
    {
      label: 'Mentions',
      key: 'mentions',
      render: (row) => <StatBox stat={row.mentions} />,
    },
    platformFilter === 'all'
      ? {
          label: 'Twitter',
          key: 'twitterMentions',
          render: (data) => <StatBox stat={data.twitterMentions} />,
        }
      : null,
    platformFilter === 'all'
      ? {
          label: 'Instagram',
          key: 'instagramMentions',
          render: (data) => <StatBox stat={data.instagramMentions} />,
        }
      : null,
    platformFilter === 'all'
      ? {
          label: 'TikTok',
          key: 'tiktokMentions',
          render: (data) => <StatBox stat={data.tiktokMentions} />,
        }
      : null,
    platformFilter === 'all'
      ? {
          label: 'Facebook',
          key: 'facebookMentions',
          render: (data) => <StatBox stat={data.facebookMentions} />,
        }
      : null,
    {
      label: 'Engagements',
      key: 'totalEngagements',
      render: (data) => <StatBox stat={data.totalEngagements} />,
    },
    {
      label: impressionsAreEst ? 'Est. Impressions' : 'Impressions',
      key: 'totalImpressions',
      render: (data) => <StatBox stat={data.totalImpressions} />,
    },
    {
      label: 'Media Value',
      key: 'totalEMV',
      render: (data) => (
        <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
      ),
    },
  ].filter((col) => col !== null);

export const SummaryTable = ({
  usedInfluencers,
  instagramPosts,
  twitterPosts,
  tiktokPosts,
  facebookPosts,
  platformFilter,
  setSelectedCampaignInfluencer,
  isFilteredByCreator,
  isSponsorship,
  timeZone,
  keyword,
}) => {
  const timeArray = twitterPosts
    .concat(instagramPosts)
    .concat(tiktokPosts)
    .concat(facebookPosts)
    .map((elem) => {
      const timeObject = momentTz.tz(elem.published_at, timeZone);
      return {
        time: timeObject.format('Do MMM YYYY'),
        unixTime: timeObject.unix(),
      };
    })
    .reduce(
      (acc, x) => acc.concat(acc.find((y) => y.time === x.time) ? [] : [x]),
      []
    );

  const rows = useMemo(
    () =>
      getRows({
        usedInfluencers,
        twitterPosts,
        instagramPosts,
        tiktokPosts,
        facebookPosts,
      }),
    [twitterPosts, instagramPosts, tiktokPosts, facebookPosts, usedInfluencers]
  );
  const rowsDay = useMemo(
    () =>
      getRowsDay({
        twitterPosts,
        instagramPosts,
        tiktokPosts,
        facebookPosts,
        timeArray,
        timeZone,
      }),
    [
      twitterPosts,
      instagramPosts,
      timeArray,
      tiktokPosts,
      facebookPosts,
      timeZone,
    ]
  );

  const allImpressionsAreEst = rows.some(
    ({ impressionsAreEst }) => impressionsAreEst
  );
  const allImpressionsAreEstDay = rowsDay.some(
    ({ impressionsAreEst }) => impressionsAreEst
  );

  const onRowClick = useCallback(
    ({ key }) => setSelectedCampaignInfluencer(key),
    [setSelectedCampaignInfluencer]
  );
  const idTable = `SV-socialmentions-summary-${keyword.replace(/\s+/g, '')}`;

  return (
    <Table
      cols={getCols(
        platformFilter,
        isSponsorship
          ? isFilteredByCreator
            ? allImpressionsAreEst
            : allImpressionsAreEstDay
          : allImpressionsAreEst,
        isFilteredByCreator,
        isSponsorship
      )}
      rows={isSponsorship ? (isFilteredByCreator ? rows : rowsDay) : rows}
      defaultSortCol="mentions"
      onRowClick={onRowClick}
      idTable={idTable}
    />
  );
};

export default SummaryTable;
