import { Box, makeStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import InstagramIcon from '@material-ui/icons/Instagram';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonIcon from '@material-ui/icons/Person';
import TwitterIcon from '@material-ui/icons/Twitter';
import VideocamIcon from '@material-ui/icons/Videocam';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import _sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';

import facebookIcon from '../../../../assets-2.0/facebook.svg';
import facebookGamingIcon from '../../../../assets-2.0/facebookGaming.svg';
import tiktokIcon from '../../../../assets-2.0/tiktok.svg';
import TwitchIcon from '../../../../assets-2.0/twitch.svg';
import YouTubeIcon from '../../../../assets-2.0/youtube.svg';
import StatBox from '../../../../components/StatBox';
import ChatsIcon from '../../components/ChatsIcon';
import OverviewCard from '../../components/OverviewCard';
import SectionTitle from '../../components/SectionTitle';
import BannerDeliverables from '../../OverviewTab/BannerDeliverables';
import ChatMentions from '../../OverviewTab/ChatMentions';
import LinkSharing from '../../OverviewTab/LinkSharing';
import ShoutoutDeliverables from '../../OverviewTab/ShoutoutDeliverables';
import SocialMediaMentions from '../../OverviewTab/SocialMediaMentions';
import { getTrackedPlatforms } from '../../utils';
const useStyles = makeStyles((theme) => ({
  img: { width: 22, height: 22, marginLeft: 8 },
}));
export const Summary = ({
  campaign,
  usedVideos,
  brandFilter,
  setActiveTab,
  setNumberPage,
  activeTab,
}) => {
  const classes = useStyles();
  const { campaign_influencers: campaignInfluencers, overview } = campaign;
  const totalDuration = _sumBy(usedVideos, 'duration') || 0;

  const trackedPlatforms = useMemo(
    () => getTrackedPlatforms(campaign.campaign_influencers),
    [campaign.campaign_influencers]
  );

  return (
    <>
      {Object.keys(overview.sponsorship_valuation)
        .filter(
          (el) => parseInt(el) === brandFilter || (brandFilter === 'all' && el)
        )
        .map((key) => {
          const valuationPerBrand =
            overview?.sponsorship_valuation_per_brand[key];
          const {
            shoutouts = [],
            banners = [],
            social_mentions = [],
            chat_mentions = [],
            link_sharings = [],
          } = valuationPerBrand;

          return (
            <div style={{ paddingTop: 64 }} key={key}>
              <SectionTitle>
                <b>{overview.sponsorship_valuation[key].brand_name}</b>{' '}
                Sponsorship Valuation summary
              </SectionTitle>
              <OverviewCard.Container>
                <OverviewCard
                  label="Creators"
                  color="teal"
                  main={{ stat: overview.sponsorship_valuation[key].creators }}
                  icon={<PersonIcon />}
                  id={`sponsorshipVal-summary-creators`}
                />
                <OverviewCard
                  label="Videos"
                  color="orange"
                  main={{ stat: overview.sponsorship_valuation[key].streams }}
                  icon={<VideocamIcon />}
                  secondaryComponent={
                    <Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <StatBox
                          stat={overview.sponsorship_valuation[key].streams_tw}
                          size="table"
                        />
                        <img
                          alt="TwitchIcon"
                          src={TwitchIcon}
                          className={classes.img}
                        />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <StatBox
                          stat={overview.sponsorship_valuation[key].streams_yt}
                          size="table"
                        />
                        <img
                          alt="YouTubeIcon"
                          src={YouTubeIcon}
                          className={classes.img}
                        />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <StatBox
                          stat={
                            overview.sponsorship_valuation[key]
                              .streams_fb_gaming
                          }
                          size="table"
                        />
                        <img
                          alt="FacebookGamingIcon"
                          src={facebookGamingIcon}
                          className={classes.img}
                        />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <StatBox
                          stat={overview.sponsorship_valuation[key].streams_fb}
                          size="table"
                        />
                        <img
                          alt="facebookIcon"
                          src={facebookIcon}
                          className={classes.img}
                        />
                      </Box>
                    </Box>
                  }
                  id={`sponsorshipVal-summary-streams`}
                />
                <OverviewCard
                  label="Voice Mentions"
                  color="blue"
                  id={`sponsorshipVal-summary-VM`}
                  main={{
                    stat: overview.sponsorship_valuation[key].shoutouts_count,
                  }}
                  icon={
                    <VolumeDownIcon style={{ transform: 'rotate(-45deg)' }} />
                  }
                  // secondaryComponent={
                  //   <Box>
                  //     <Box
                  //       display="flex"
                  //       alignItems="center"
                  //       justifyContent="flex-end"
                  //     >
                  //       <StatBox
                  //         stat={overview.sponsorship_valuation[key].streams_tw}
                  //         size="table"
                  //       />
                  //       <img
                  //         alt="TwitchIcon"
                  //         src={TwitchIcon}
                  //         className={classes.img}
                  //       />
                  //     </Box>
                  //     <Box
                  //       display="flex"
                  //       alignItems="center"
                  //       justifyContent="flex-end"
                  //     >
                  //       <StatBox
                  //         stat={overview.sponsorship_valuation[key].streams_yt}
                  //         size="table"
                  //       />
                  //       <img
                  //         alt="YouTubeIcon"
                  //         src={YouTubeIcon}
                  //         className={classes.img}
                  //       />
                  //     </Box>
                  //   </Box>
                  // }
                />
                <OverviewCard
                  label="Banner on-screen"
                  color="blue"
                  main={{
                    stat:
                      overview.sponsorship_valuation[key].logo_duration / 3600,
                    suffix: ' h',
                  }}
                  secondary={{
                    stat: totalDuration
                      ? Math.round(
                          (overview.sponsorship_valuation[key].logo_duration /
                            totalDuration) *
                            10000
                        ) / 100
                      : 0,
                    suffix: '%',
                    label: 'of total',
                  }}
                  icon={<AccessTimeIcon />}
                  id={`sponsorshipVal-summary-banneronscreen`}
                />
                <OverviewCard
                  label="Chat Mentions"
                  color="blue"
                  main={{
                    stat: overview.sponsorship_valuation[key]
                      .chat_comments_count,
                  }}
                  icon={<ChatsIcon />}
                  // secondaryComponent={
                  //   <Box>
                  //     <Box
                  //       display="flex"
                  //       alignItems="center"
                  //       justifyContent="flex-end"
                  //     >
                  //       <StatBox
                  //         stat={overview.sponsorship_valuation[key].streams_tw}
                  //         size="table"
                  //       />
                  //       <img
                  //         alt="TwitchIcon"
                  //         src={TwitchIcon}
                  //         className={classes.img}
                  //       />
                  //     </Box>
                  //     <Box
                  //       display="flex"
                  //       alignItems="center"
                  //       justifyContent="flex-end"
                  //     >
                  //       <StatBox
                  //         stat={overview.sponsorship_valuation[key].streams_yt}
                  //         size="table"
                  //       />
                  //       <img
                  //         alt="YouTubeIcon"
                  //         src={YouTubeIcon}
                  //         className={classes.img}
                  //       />
                  //     </Box>
                  //   </Box>
                  // }
                  id={`sponsorshipVal-summary-chatmentions`}
                />
                <OverviewCard
                  label="Social Mentions"
                  color="blue"
                  id={`sponsorshipVal-summary-socialmentions`}
                  main={{
                    stat: overview.sponsorship_valuation[key]
                      .social_media_count,
                  }}
                  icon={<ChatBubbleIcon />}
                  secondaryComponent={
                    <Box>
                      {trackedPlatforms.twitter && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <StatBox
                            stat={
                              overview.sponsorship_valuation[key]
                                .social_media_tweets_count
                            }
                            size="table"
                          />
                          <TwitterIcon
                            style={{ marginLeft: 8, color: '#1DA1F2' }}
                          />
                        </Box>
                      )}
                      {trackedPlatforms.instagram && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <StatBox
                            stat={
                              overview.sponsorship_valuation[key]
                                .social_media_instagram_count
                            }
                            size="table"
                          />
                          <InstagramIcon
                            style={{ marginLeft: 8, color: '#DD2A7B' }}
                          />
                        </Box>
                      )}
                      {trackedPlatforms.tiktok && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <StatBox
                            stat={
                              overview.sponsorship_valuation[key]
                                .social_media_tiktok_count
                            }
                            size="table"
                          />
                          <img
                            src={tiktokIcon}
                            alt="TikTok Icon"
                            style={{
                              marginLeft: 8,
                              color: '#DD2A7B',
                              height: 24,
                            }}
                          />
                        </Box>
                      )}
                      {trackedPlatforms.facebook && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <StatBox
                            stat={
                              overview.sponsorship_valuation[key]
                                .social_media_facebook_count
                            }
                            size="table"
                          />
                          <img
                            src={facebookIcon}
                            alt="Facebook Icon"
                            style={{
                              marginLeft: 8,
                              color: '#DD2A7B',
                              height: 24,
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  }
                />
                <OverviewCard
                  label="Impressions"
                  color="purple"
                  main={{
                    stat: overview.sponsorship_valuation[key].impressions,
                  }}
                  icon={<VisibilityIcon />}
                  id={`sponsorshipVal-summary-impressions`}
                />
                <OverviewCard
                  label="Media value"
                  color="green"
                  main={{
                    stat: overview.sponsorship_valuation[key].emv,
                    prefix: '$',
                    format: '0,[0].[0]a',
                  }}
                  icon={<LocalAtmIcon />}
                  id={`sponsorshipVal-summary-mediavalue`}
                />
              </OverviewCard.Container>
              {shoutouts.length ? (
                <ShoutoutDeliverables
                  isSponsorship={true}
                  shoutouts={shoutouts}
                  onViewMore={() => setActiveTab('shoutouts')}
                  setNumberPage={setNumberPage}
                  activeTab={activeTab}
                />
              ) : null}
              {banners.length ? (
                <BannerDeliverables
                  isSponsorship={true}
                  banners={banners}
                  onViewMore={() => setActiveTab('banners')}
                  setNumberPage={setNumberPage}
                  activeTab={activeTab}
                />
              ) : null}
              {social_mentions.length ? (
                <SocialMediaMentions
                  isSponsorship={true}
                  campaignInfluencers={campaignInfluencers}
                  mentions={social_mentions}
                  onViewMore={() => setActiveTab('social-media')}
                  setNumberPage={setNumberPage}
                  activeTab={activeTab}
                />
              ) : null}

              {chat_mentions.length ? (
                <ChatMentions
                  isSponsorship={true}
                  mentions={chat_mentions}
                  onViewMore={() => setActiveTab('chat-mentions')}
                  setNumberPage={setNumberPage}
                  activeTab={activeTab}
                />
              ) : null}

              {link_sharings.length ? (
                <LinkSharing
                  isSponsorship={true}
                  linkTrackings={link_sharings}
                  onViewMore={() => setActiveTab('link-sharing')}
                  setNumberPage={setNumberPage}
                  activeTab={activeTab}
                />
              ) : null}
            </div>
          );
        })}
    </>
  );
};

export default Summary;
