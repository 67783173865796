import { connect } from 'react-redux';
import { compose } from 'recompose';

import { selectCampaignById } from '../../store/models/campaigns/campaigns.selectors';

const mapStateToProps = (state, ownProps) => {
  const {
    params: { campaignId },
  } = ownProps.match;

  return {
    campaign: selectCampaignById(campaignId)(state),
  };
};

export default compose(connect(mapStateToProps, null));
