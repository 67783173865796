import { SerializedError } from '@reduxjs/toolkit';

import { LoadingState } from '../../../../../types';

export const SLICE_NAME = 'socialMediaPerformance';

export interface FetchSocialMediaPerformancePayload {
  campaignId: number;
  influencerIds: number[];
  platform: string;
  startTime: string;
  endTime: string;
}

export const getCacheKey = (payload: FetchSocialMediaPerformancePayload) =>
  `${payload.campaignId}-${payload.influencerIds.join('-')}-${
    payload.platform
  }-${payload.startTime}-${payload.endTime}`;

export interface SocialMediaPerformanceSummary {
  campaign_influencer_id: number;
  username: string;
  avatar: string;
  instagram_user_id: number;
  posts_count: number;
  posts_count_per_platform: {
    facebook: number;
    instagram: number;
    twitter: number;
    tiktok: number;
  };
  followers_before: number;
  followers_gained: number;
  likes_count: number;
  shares_count: number;
  comments_count: number;
  impressions_count: number;
  est_impressions_count: number;
  engagements: number;
  emv: number;
}

export interface SocialMediaPerformanceTweet {
  network?: string;
  campaign_influencer_id: number;
  tweet_id: string;
  username: string;
  text: string;
  date: string;
  permalink: string;
  favorites: number;
  retweets: number;
  replies: number;
  fetched_at: string;
  media_url: string;
  full_name: string;
  quotes: string;
  impression_count: string;
  twitter_user_id: string | number;
  published_at: string;
  emv: number;
  est_impression_count: string;
}

export interface SocialMediaPerformanceTiktok {
  network?: string;
  campaign_influencer_id: number;
  tiktok_post_id: string;
  owner_username: string;
  text: string;
  date: string;
  permalink: string;
  shares_count: number;
  likes_count: number;
  comments_count: number;
  impression_count: string;
  published_at: string;
  tiktok_user_id: string | number;
  emv: number;
  est_impression_count: string;
  cover_image_url: string;
  id: number;
  external_id: string;
}

export interface SocialMediaPerformanceInstagramPost {
  network?: string;
  campaign_influencer_id: number;
  shortcode: string;
  owner_username: string;
  media_url: string;
  caption: string;
  likes: number;
  comment_count: number;
  id: number;
  external_id: string;
  media_product_type: string;
  media_type: string;
  instagram_user_id: number;
  created_at: string;
  updated_at: string;
  posted_at: string;
  impression_count: number;
  user_external_business_id: string;
  published_at: string;
  date: string;
  emv: number;
  est_impression_count: number;
}

export type SocialMediaPerformanceFacebook = {
  network?: string;
  campaign_influencer_id: number;

  owner_username: string;
  media_url: string;
  comments_count: number;
  created_at: string;
  deleted_at: string | null;
  emv: number;
  engagement: number;
  external_id: string;
  id: number;
  impressions: number;
  likes_count: number;
  page_external_id: string;
  permalink: string;
  published_at: string;
  reactions_count: number;
  shares_count: number;
  text: string;
  updated_at: string;
  est_impression_count?: string;
  impressions_count?: number;
};

export type SocialMediaPerformancePost =
  | SocialMediaPerformanceTweet
  | SocialMediaPerformanceTiktok
  | SocialMediaPerformanceInstagramPost
  | SocialMediaPerformanceFacebook;

export const isTweet = (
  post: SocialMediaPerformancePost
): post is SocialMediaPerformanceTweet => 'tweet_id' in post;

export const isTiktok = (
  post: SocialMediaPerformancePost
): post is SocialMediaPerformanceTiktok =>
  'views_count' in post || post.network === 'tiktok';

export const isFacebook = (
  post: SocialMediaPerformancePost
): post is SocialMediaPerformanceFacebook =>
  post.network === 'facebook' || 'reactions_count' in post;

export interface SocialMediaPerformanceState {
  summary: SocialMediaPerformanceSummary[] | null;
  summaryCacheKey: string;
  summaryLoadingState: LoadingState;
  summaryError: SerializedError | null;

  posts: SocialMediaPerformancePost[] | null;
  postsCacheKey: string;
  postsLoadingState: LoadingState;
  postsError: SerializedError | null;
}

export const getPlatformKey = (post: SocialMediaPerformancePost) => {
  if (isTweet(post)) return `tw-${post.tweet_id}`;
  else if (isTiktok(post)) return post.external_id;
  else if (isFacebook(post)) return post.external_id;
  else return post.shortcode;
};

export const getPermalink = (post: SocialMediaPerformancePost) => {
  if (isTweet(post) || isTiktok(post) || isFacebook(post))
    return post.permalink;
  else return `https://instagram.com/p/${post.shortcode}`;
};

export const getText = (post: SocialMediaPerformancePost) => {
  if (isTweet(post) || isTiktok(post) || isFacebook(post)) return post.text;
  else return post.caption;
};

export const getCreator = (post: SocialMediaPerformancePost) => {
  if (isTweet(post)) return post.username;
  else return post.owner_username || 'unknown';
};

export const getPlatform = (post: SocialMediaPerformancePost) => {
  if (isTweet(post)) return 'twitter';
  else if (isTiktok(post)) return 'tiktok';
  else if (isFacebook(post)) return 'facebook';
  else return 'instagram';
};

export const getRetweets = (post: SocialMediaPerformancePost) => {
  if (isTweet(post)) return post.retweets;
  else if (isTiktok(post) || isFacebook(post)) return post.shares_count;

  return null;
};

export const getLikes = (post: SocialMediaPerformancePost) => {
  if (isTweet(post)) return post.favorites;
  else if (isTiktok(post) || isFacebook(post)) return post.likes_count;
  else return post.likes;
};

export const getReplies = (post: SocialMediaPerformancePost) => {
  if (isTweet(post)) return post.replies;
  else if (isTiktok(post) || isFacebook(post)) return post.comments_count;
  else return post.comment_count;
};

export const getPostId = (post: SocialMediaPerformancePost) => {
  if (isTweet(post)) return post.tweet_id;
  else if (isTiktok(post)) return post.external_id;
  else if (isFacebook(post)) return post.external_id;
  else return post.shortcode;
};

export const getImage = (post: SocialMediaPerformancePost) => {
  if (isTiktok(post)) return post.cover_image_url;

  return post.media_url;
};

export const getImpressions = (post: SocialMediaPerformancePost) => {
  if (post.hasOwnProperty('est_impression_count')) {
    return post.est_impression_count;
  }

  if (isFacebook(post)) {
    return post.impressions_count || post.impressions;
  }

  return post.impression_count;
};
