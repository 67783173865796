const IMAGE_PROXY_API = 'https://aggero-backend-prod1.herokuapp.com/static/ig';

export const getMediaUrl = (url, pdfReport) => {
  if (url.startsWith('data:')) {
    if (!pdfReport) return url;

    const format = url.includes('png') ? 'png' : 'jpg';

    const data = Buffer.from(url.slice(url.indexOf(',') + 1), 'base64');

    return { data, format };
  }

  return `${IMAGE_PROXY_API}?type=post&url=${Buffer.from(
    url || '',
    'utf-8'
  ).toString('base64')}`;
};

export default getMediaUrl;
