const DAY = 1000 * 60 * 60 * 24;
const ANYTIME = DAY * (365 * 25); // 25 years

// Keep the same keys in this too arrays.
export const timeAgoFilterValues = (ago = 'defaultTimeAgoOption') =>
  ({
    '90d': DAY * 90,
    '30d': DAY * 30,
    '7d': DAY * 7,
    defaultTimeAgoOption: ANYTIME,
  }[ago]);

export const timeAgoFilterOptions = (ago = 'defaultTimeAgoOption') =>
  ({
    '30d': [
      { title: 'Last 7 days', value: DAY * 7 },
      { title: 'Last month', value: DAY * 30 },
    ],
    '7d': [{ title: 'Last 7 days', value: DAY * 7 }],
    '90d': [
      { title: 'Last 7 days', value: DAY * 7 },
      { title: 'Last month', value: DAY * 30 },
      { title: 'Last 3 months', value: DAY * 90 },
    ],
    defaultTimeAgoOption: [
      { title: 'Anytime', value: ANYTIME },
      { title: 'Last 7 days', value: DAY * 7 },
      { title: 'Last month', value: DAY * 30 },
      { title: 'Last 3 months', value: DAY * 90 },
      { title: 'Last year', value: DAY * 365 },
      { title: 'Last 3 years', value: DAY * 365 * 3 },
      { title: 'Last 5 years', value: DAY * 365 * 5 },
    ],
  }[ago]);

export const getUnixOneMonthAgo = () =>
  parseInt((Date.now() - DAY * 30) / 1000);
