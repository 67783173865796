import _sortBy from 'lodash/sortBy';
import React, { useCallback, useMemo } from 'react';

import Table, { StatBox, TableText } from '../Table';
import SectionContainer from './SectionContainer';
import { SectionTitle } from './shared';

const COLS = [
  {
    label: 'Keyword',
    key: 'keyword',
    render: (data) => <TableText>{data.keyword}</TableText>,
    stretch: true,
  },
  {
    label: 'Mentions',
    key: 'mentions',
    render: (data) => <StatBox stat={data.mentions} />,
  },
  {
    label: 'Videos',
    key: 'streams',
    render: (data) => <StatBox stat={data.streams} />,
  },
  {
    label: '% of Total Comm.',
    key: 'perOfComments',
    render: (data) => <StatBox stat={data.perOfComments} suffix="%" />,
  },
  // {
  //   label: 'Impressions',
  //   key: 'impressions',
  //   render: (data) => <StatBox stat={data.impressions} />,
  // },
  // {
  //   label: 'Media Value',
  //   key: 'totalEMV',
  //   render: (data) => (
  //     <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
  //   ),
  // },
];

const getRows = (mentions) =>
  mentions.map((m, key) => ({
    key,
    keyword: m.keyword,
    mentions: m.mentions,
    streams: m.streams,
    perOfComments: m.per_of_total_comm,
    impressions: m.impressions,
    totalEMV: m.emv,
  }));

export const ChatMentions = ({
  mentions,
  onViewMore,
  isSponsorship,
  setNumberPage,
  activeTab,
}) => {
  const rows = useMemo(() => getRows(mentions), [mentions]);
  const onRowClick = useCallback(
    ({ keyword }) => {
      if (activeTab !== 'summary') {
        return setNumberPage(
          Math.floor(
            _sortBy(mentions, 'keyword').findIndex(
              (mention) => mention.keyword === keyword
            ) / 5
          )
        );
      }
      onViewMore(keyword);
    },
    [onViewMore]
  );
  const idTable = 'chat-mentions';

  return (
    <SectionContainer isSponsorship={isSponsorship}>
      <SectionTitle onViewMore={onViewMore}>
        Chat/Comments Mentions
      </SectionTitle>
      <Table
        noBorders={true}
        cols={COLS}
        rows={rows}
        defaultSortCol="mentions"
        onRowClick={onRowClick}
        idTable={idTable}
      />
    </SectionContainer>
  );
};

export default ChatMentions;
