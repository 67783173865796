import { makeStyles } from '@material-ui/core';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import React, { useMemo, useRef } from 'react';

import GeoUtils from '../../../../../../utils/GeoUtils';

type Language = {
  label: string;
  value: number;
};

const VERTICAL_MARGIN = 32;
const HORIZONTAL_MARGIN = 32;

const getLanguage = (language: Language) =>
  GeoUtils.languageCode2Name(language.label);
const getLanguagePercentage = (language: Language) => language.value;

const useAudienceTopLanguagesStyles = makeStyles(() => ({
  container: { position: 'relative' },
}));

interface AudienceTopLanguagesProps {
  width: number;
  height: number;
  topLanguages: Language[];
}

export const AudienceTopLanguagesChart = ({
  width,
  height,
  topLanguages,
}: AudienceTopLanguagesProps) => {
  const svgRef = useRef();
  const classes = useAudienceTopLanguagesStyles();

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip<Language>();

  // bounds
  const xMax = width - HORIZONTAL_MARGIN * 2; // left margin + right margin
  const yMax = height - VERTICAL_MARGIN * 2;

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: topLanguages.map(getLanguage),
        padding: 0.6,
      }),
    [topLanguages, xMax]
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...topLanguages.map(getLanguagePercentage))],
      }),
    [topLanguages, yMax]
  );

  return width < 10 ? null : (
    <div className={classes.container}>
      <svg width={width} height={height} ref={svgRef}>
        <rect width={width} height={height} fill="#fff" />
        <Group left={HORIZONTAL_MARGIN} top={VERTICAL_MARGIN}>
          {topLanguages.map((d) => {
            const language = getLanguage(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getLanguagePercentage(d)) ?? 0);
            const barX = xScale(language);
            const barY = yMax - barHeight;

            const onMouseMove = (
              event: React.MouseEvent | React.TouchEvent
            ) => {
              const point = localPoint(svgRef.current, event);

              showTooltip({
                tooltipData: d,
                tooltipLeft: point.x,
                tooltipTop: point.y,
              });
            };

            return (
              <Bar
                key={`bar-${language}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill="#009fff"
                onMouseMove={onMouseMove}
                onMouseLeave={hideTooltip}
                onTouchMove={onMouseMove}
                onTouchEnd={hideTooltip}
              />
            );
          })}
          <AxisBottom
            top={yMax}
            scale={xScale}
            hideTicks
            hideAxisLine
            tickLabelProps={() => ({
              fontSize: 16,
              textAnchor: 'middle',
            })}
          />
          <AxisLeft
            left={HORIZONTAL_MARGIN}
            scale={yScale}
            hideAxisLine
            hideTicks
            hideZero
            numTicks={5}
            tickLabelProps={() => ({
              fontSize: 16,
              textAnchor: 'middle',
            })}
          />
        </Group>
      </svg>
      {tooltipOpen && tooltipData && tooltipLeft != null && tooltipTop != null && (
        <TooltipWithBounds left={tooltipLeft + 10} top={tooltipTop + 10}>
          <strong>{getLanguage(tooltipData)}</strong>: {tooltipData.value}%
        </TooltipWithBounds>
      )}
    </div>
  );
};
