import { ComponentMeta, ComponentStory } from '@storybook/react';
import React from 'react';

import { AudienceTopLanguagesChart } from './AudienceTopLanguagesChart';

// More on default export: https://storybook.js.org/docs/react/writing-stories/introduction#default-export
export default {
  title: 'AudienceTopLanguagesChart',
  component: AudienceTopLanguagesChart,
  argTypes: {
    width: { type: 'number' },
    height: { type: 'number' },
  },
} as ComponentMeta<typeof AudienceTopLanguagesChart>;

export const TOP_LANGUAGES = [
  {
    label: 'es',
    value: 70,
  },
  {
    label: 'de',
    value: 10,
  },
  {
    label: 'hu',
    value: 10,
  },
  {
    label: 'en',
    value: 10,
  },
];

const Template: ComponentStory<typeof AudienceTopLanguagesChart> = (arg) => {
  return <AudienceTopLanguagesChart {...arg} />;
};

export const Primary = Template.bind({});
Primary.args = {
  topLanguages: TOP_LANGUAGES,
  width: 1000,
  height: 600,
};
