import { IconButton, Tooltip } from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import React, { useContext, useMemo, useState } from 'react';

import DeliverableChart from '../DeliverableChart';
import TimestampsDialog from '../DeliverablesTab/Banners/TimestampsDialog';
import { Campaign, CampaignInfluencer, Video } from '../types';
import { findInfluencerByVideo, findVideoById } from '../utils';
import { CampaignContext } from '../utils/CampaignContext';

interface CampaignDeliverableChartTriggerProps {
  videoId: number;
  bannerDeliverableId: Number;
}

export const CampaignDeliverableChartTrigger = ({
  videoId,
  bannerDeliverableId,
}: CampaignDeliverableChartTriggerProps) => {
  const { usedVideos, usedInfluencers, campaign } = useContext(CampaignContext);

  const video = useMemo(
    () => findVideoById(videoId, usedVideos),
    [videoId, usedVideos]
  );

  const influencer = useMemo(
    () => findInfluencerByVideo(video, usedInfluencers),
    [video, usedInfluencers]
  );

  return (
    <DeliverableChartTrigger
      video={video}
      influencer={influencer}
      bannerDeliverableId={bannerDeliverableId}
      campaign={campaign}
      videoId={videoId}
    />
  );
};

interface MarketplaceStreamChartTriggerProps {
  video: Video;
  influencer: CampaignInfluencer;
}

export const MarketplaceStreamChartTrigger = ({
  video,
  influencer,
}: MarketplaceStreamChartTriggerProps) => {
  const videoId = video.id;

  return (
    <DeliverableChartTrigger
      video={video}
      influencer={influencer}
      bannerDeliverableId={null}
      campaign={null}
      videoId={videoId}
    />
  );
};

interface DeliverableChartTriggerProps {
  videoId: Number;
  video: Video;
  influencer: CampaignInfluencer;
  bannerDeliverableId: Number | null;
  campaign: Campaign | null;
}

const DeliverableChartTrigger = ({
  videoId,
  video,
  influencer,
  bannerDeliverableId,
  campaign,
}: DeliverableChartTriggerProps) => {
  const [deliverablesChartVideoId, setDeliverablesChartVideoId] =
    useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const timeZone = campaign?.time_zone;

  return (
    <>
      {video.resource === 'tiktok' ? (
        <TimestampsDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          video={video}
          influencer={influencer}
          campaignId={campaign?.id}
          bannerDeliverableId={bannerDeliverableId}
          timeZone={timeZone}
          thumbnail={null}
        />
      ) : (
        <DeliverableChart
          videoId={deliverablesChartVideoId}
          video={video}
          influencer={influencer}
          campaign={campaign}
          onClose={() => setDeliverablesChartVideoId(null)}
        />
      )}
      <Tooltip placement="top" title="Show in-stream media performance">
        <IconButton
          style={{ height: 45 }}
          onClick={() => {
            setDeliverablesChartVideoId(videoId);
            setDialogOpen(true);
          }}
        >
          <EqualizerIcon style={{ color: '#0048f2' }} />
        </IconButton>
      </Tooltip>
    </>
  );
};
