import { createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '../../../../../types';
import { fetchCreatorAudience } from './Audience.thunks';
import { AudienceState, SLICE_NAME } from './types';

const initialState: AudienceState = {
  data: null,
  loadingState: LoadingState.IDLE,
  error: null,
};

export const { reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreatorAudience.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.error = null;
      })
      .addCase(fetchCreatorAudience.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.data = action.payload;
      })
      .addCase(fetchCreatorAudience.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.error = action.error;
      });
  },
});
