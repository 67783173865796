import {
  APP_MOUNTED,
  BRAND_CREATION_SUBMITTED,
  BRAND_DELETE_CLICKED,
  BRAND_MOUNTED,
  BRAND_UPDATE_SUBMITTED,
  BRANDS_MOUNTED,
  CAMPAIGN_CREATION_STEP_ADVANCED,
  CAMPAIGN_CREATION_SUBMITTED,
  CAMPAIGN_DELETE_CLICKED,
  CAMPAIGN_MOUNTED,
  CAMPAIGN_MULTIPLE_VIDEOS_DELETED_CLICKED,
  CAMPAIGN_SHOUTOUT_DELETE_CLICKED,
  CAMPAIGN_SOCIAL_POST_DELETE,
  CAMPAIGN_SOCIAL_POSTS_MENTIONS_DELETE,
  CAMPAIGN_UPDATE_SUBMITTED,
  CAMPAIGN_VIDEO_DELETE_CLICKED,
  CAMPAIGNS_MOUNTED,
  INFLUENCER_MOUNTED,
  INFLUENCER_ONBOARDING_MOUNTED,
  INFLUENCER_ONBOARDING_SUBMITTED,
  INFLUENCERS_FETCH_NEXT_PAGE,
  INFLUENCERS_MOUNTED,
  INFLUENCERS_SEARCH_MOUNTED,
  MY_STATS_MOUNTED,
  MY_WATCHLIST_MOUNTED_TWITCH,
  MY_WATCHLIST_MOUNTED_YOUTUBE,
} from './action-types';
import createAction from './create-action';

export const myStatsMounted = createAction(MY_STATS_MOUNTED);
export const appMounted = createAction(APP_MOUNTED);

export const influencerOnboardingMounted = createAction(
  INFLUENCER_ONBOARDING_MOUNTED
);
export const influencerOnboardingSubmitted = createAction(
  INFLUENCER_ONBOARDING_SUBMITTED
);

export const influencerMounted = createAction(INFLUENCER_MOUNTED);

export const influencersMounted = createAction(INFLUENCERS_MOUNTED);
export const influencersFetchNextPage = createAction(
  INFLUENCERS_FETCH_NEXT_PAGE
);

export const influencersSearchMounted = createAction(
  INFLUENCERS_SEARCH_MOUNTED
);

export const campaignMounted = createAction(CAMPAIGN_MOUNTED);

export const campaignsMounted = createAction(CAMPAIGNS_MOUNTED);

export const campaignCreationFormSubmitted = createAction(
  CAMPAIGN_CREATION_SUBMITTED
);
export const brandCreationFormSubmitted = createAction(
  BRAND_CREATION_SUBMITTED
);
export const campaignUpdateFormSubmitted = createAction(
  CAMPAIGN_UPDATE_SUBMITTED
);

export const campaignCreationStepAdvanced = createAction(
  CAMPAIGN_CREATION_STEP_ADVANCED
);

export const campaignDeleteClicked = createAction(CAMPAIGN_DELETE_CLICKED);
export const campaignVideoDeleteClicked = createAction(
  CAMPAIGN_VIDEO_DELETE_CLICKED
);
export const campaignMultipleVideosDeleteClicked = createAction(
  CAMPAIGN_MULTIPLE_VIDEOS_DELETED_CLICKED
);
export const campaignSocialPostDelete = createAction(
  CAMPAIGN_SOCIAL_POST_DELETE
);
export const campaignSocialPostsMentionsDelete = createAction(
  CAMPAIGN_SOCIAL_POSTS_MENTIONS_DELETE
);
export const campaignShoutoutDeleteClicked = createAction(
  CAMPAIGN_SHOUTOUT_DELETE_CLICKED
);

export const myWatchlistMountedYoutube = createAction(
  MY_WATCHLIST_MOUNTED_YOUTUBE
);
export const myWatchlistMountedTwitch = createAction(
  MY_WATCHLIST_MOUNTED_TWITCH
);

export const brandMounted = createAction(BRAND_MOUNTED);
export const brandsMounted = createAction(BRANDS_MOUNTED);
export const brandDeleteClicked = createAction(BRAND_DELETE_CLICKED);
export const brandUpdateFormSubmitted = createAction(BRAND_UPDATE_SUBMITTED);
