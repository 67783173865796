import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '../common/ErrorMessageUtil';

type Props = TextFieldProps & { errorMessage?: ErrorMessage };

function FormikMuiTextField({
  name,
  type = 'text',
  errorMessage,
  ...rest
}: Props) {
  const formik = useFormikContext();
  const [error, setError] =
    useState<{ message: string; isFormikError: boolean }>(null);
  const formikError =
    _get(formik.touched, name) === true && _get(formik.errors, name);

  const { handleChange, handleBlur } = formik;
  const { t } = useTranslation();

  useEffect(() => {
    if (formikError) {
      setError({ message: formikError, isFormikError: true });
    }

    if (!formikError && !errorMessage) {
      setError(null);
    }

    if (!formikError && errorMessage) {
      setError({
        message: t(`errors.${errorMessage.key}`, { ...errorMessage.vars }),
        isFormikError: false,
      });
    }
  }, [formikError, errorMessage, t]);

  return (
    <TextField
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyUp={() => {
        if (error && !error.isFormikError) {
          setError(null);
        }
      }}
      error={Boolean(error)}
      helperText={error?.message}
      name={name}
      type={type}
      {...rest}
    />
  );
}

export default FormikMuiTextField;
