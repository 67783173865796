import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import React, { useState } from 'react';

import { ChatMention } from './DeliverablesTab/ChatMentions/store/types';

export const useCampaignCommentDeleteConfirmation = () => {
  const [commentToDelete, setCommentToDelete] = useState<null | ChatMention>(
    null
  );

  return { commentToDelete, setCommentToDelete };
};

interface CampaignCommentDeleteConfirmationProps {
  commentToDelete: null | ChatMention;
  setCommentToDelete: (comment: null | ChatMention) => void;
  handleConfirmDeleteComment: (
    comment: ChatMention,
    deleteAll: boolean
  ) => void;
}

export default function CampaignCommentDeleteConfirmation({
  commentToDelete,
  setCommentToDelete,
  handleConfirmDeleteComment,
}: CampaignCommentDeleteConfirmationProps) {
  const [removeAll, setRemoveAll] = useState(false);

  const isOpen = !!commentToDelete;

  const authorName = commentToDelete?.name;

  const handleClose = () => setCommentToDelete(null);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Comment</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this comments from
            <span className="bold"> {authorName}? </span>
            Please note that this action cannot be undone.
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={removeAll}
                onChange={() => setRemoveAll(!removeAll)}
                value="true"
              />
            }
            label={`Remove all existing and future comments from ${authorName} for this campaign`}
          />
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: '12px 24px' }}
        >
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              handleClose();

              commentToDelete &&
                handleConfirmDeleteComment(commentToDelete, removeAll);
            }}
            color="primary"
          >
            <Box clone mr={1}>
              <Icon>delete_outline</Icon>
            </Box>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
