import { all, fork, select, takeLatest } from 'redux-saga/effects';

import { APP_MOUNTED } from '../action-types';
import { selectors } from '../selectors';
import requestUser from './request-user';
import requestWatchlists from './request-watchlists';

function* onAppMounted() {
  const wasLoggedOut = yield select(selectors.wasLoggedOut);
  const isInfluencerView = yield select(selectors.isInfluencerView);

  let forks = [fork(requestUser)];

  if (!isInfluencerView && !wasLoggedOut) {
    forks.push(fork(requestWatchlists));
  }

  yield all(forks);
}

export default function* root() {
  yield takeLatest(APP_MOUNTED, onAppMounted);
}
