import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import numeral from 'numeral';
import React from 'react';
import WordCloud from 'react-d3-cloud';
import { connect } from 'react-redux';
import { isNumber } from 'util';

import CardTitle from '../../../components/CardTitle';
import {
  getInfluencerActivity,
  getInfluencerStreamerVideoCount,
  getInfluencerWordcloud,
} from '../selectors';

const mapStateToProps = (state) => ({
  wordcloud: getInfluencerWordcloud(state),
  activity: getInfluencerActivity(state),
  video_count: getInfluencerStreamerVideoCount(state),
});

const enhance = connect(mapStateToProps);

const InfluencerActivity = (props) => {
  const {
    wordcloud = [],
    activity: { stream_time_days, total_watch_time_days } = {},
    video_count,
  } = props;

  return (
    <Grid container alignItems="stretch" spacing={4}>
      {isNumber(stream_time_days) && (
        <Grid item xs={12} md={4}>
          <Box height="100%" clone>
            <Card>
              <CardContent>
                <CardTitle>Total Streaming Time on Twitch</CardTitle>
              </CardContent>
              <CardContent>
                <div className="layer w-100">
                  <div className="number-chart pT-60 pB-60">
                    <p className="number-chart-digit">
                      {numeral(stream_time_days).format('0.[0]a')}
                    </p>
                    <p className="number-chart-text">hours</p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      )}
      {isNumber(total_watch_time_days) && (
        <Grid item xs={12} md={4}>
          <Box height="100%" clone>
            <Card>
              <CardContent>
                <CardTitle>Hours Watched by the Audience on Twitch</CardTitle>
              </CardContent>
              <CardContent>
                <div className="layer w-100">
                  <div className="number-chart pT-60 pB-60">
                    <p className="number-chart-digit">
                      {numeral(total_watch_time_days).format('0.[0]a')}
                    </p>
                    <p className="number-chart-text">hours</p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      )}
      {isNumber(video_count) && (
        <Grid item xs={12} md={4}>
          <Box height="100%" clone>
            <Card>
              <CardContent>
                <CardTitle>Total Number of Videos on Youtube</CardTitle>
              </CardContent>
              <CardContent>
                <div className="layer w-100">
                  <div className="number-chart pT-60 pB-60">
                    <p className="number-chart-digit">
                      {numeral(video_count).format('0,0')}
                    </p>
                    <p className="number-chart-text">videos</p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      )}

      {wordcloud && wordcloud.length > 0 && (
        <Grid item xs={12} md={4}>
          <Box height="100%" clone>
            <Card>
              <CardContent>
                <CardTitle>Talking About</CardTitle>
              </CardContent>
              <CardContent>
                <Box textAlign="center">
                  <WordCloud
                    height={280}
                    width={280}
                    font="Lato"
                    data={wordcloud}
                    fontSizeMapper={(word) =>
                      (word.value / wordcloud[0].value) * 24 + 16
                    }
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default enhance(InfluencerActivity);
