import { Box, useMediaQuery } from '@material-ui/core';
import _sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

import Table, { StatBox } from '../Table';
import SectionContainer from './SectionContainer';
import { SectionTitle } from './shared';

const TimeOnScren = ({ timeOnScreen }) => {
  const matches = useMediaQuery('(min-width: 1536px)');

  if (matches)
    return (
      <StatBox
        format={null}
        stat={moment
          .duration(timeOnScreen, 'seconds')
          .format('HH[h] : mm[m] : ss[s]')}
      />
    );

  return <StatBox stat={timeOnScreen / 3600} suffix="h" />;
};

const COLS = [
  {
    label: 'Banner',
    key: 'image',
    stretch: true,
    flex: 1.5,
    render: (data) => (
      <Box display="flex" alignItems="center">
        <img
          src={data.image}
          alt="banner"
          style={{ maxWidth: '90%', maxHeight: 44 }}
        />
      </Box>
    ),
  },
  {
    label: 'Time on Screen',
    key: 'timeOnScreen',
    render: (data) => <TimeOnScren timeOnScreen={data.timeOnScreen} />,
  },
  {
    label: 'Creators',
    key: 'creators',
    render: (data) => <StatBox stat={data.creators} />,
  },
  {
    label: 'Videos',
    key: 'streams',
    render: (data) => <StatBox stat={data.streams} />,
  },
  {
    label: 'Avg. CCV',
    key: 'avgCCV',
    render: (data) => <StatBox stat={data.avgCCV} />,
  },
  {
    label: 'Peak CCV',
    key: 'peakCCV',
    render: (data) => <StatBox stat={data.peakCCV} />,
  },
  {
    label: 'Impressions',
    key: 'impressions',
    render: (data) => <StatBox stat={data.impressions} />,
  },
  {
    label: 'Media Value',
    key: 'totalEMV',
    render: (data) => (
      <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
    ),
  },
];

const getRows = (banners) =>
  banners.map((b) => ({
    key: b.id,
    image: b.image,
    timeOnScreen: b.duration,
    creators: b.creators,
    streams: b.streams,
    avgCCV: b.avg_ccv,
    peakCCV: b.peak_ccv,
    impressions: b.impressions,
    totalEMV: b.emv,
  }));

export const BannerDeliverables = ({
  banners,
  onViewMore,
  isSponsorship,
  setNumberPage,
  activeTab,
}) => {
  const rows = useMemo(() => getRows(banners), [banners]);

  const onRowClick = useCallback(
    ({ key, image }) => {
      if (activeTab !== 'summary') {
        return setNumberPage(
          Math.floor(
            _sortBy(banners, 'image').findIndex(
              (banner) => banner.image === image
            ) / 5
          )
        );
      }
      onViewMore(key);
    },
    [onViewMore]
  );
  const idTable = 'banner';

  return (
    <SectionContainer isSponsorship={isSponsorship}>
      <SectionTitle onViewMore={onViewMore}>Image tracking</SectionTitle>
      <Table
        noBorders={true}
        cols={COLS}
        rows={rows}
        defaultSortCol="timeOnScreen"
        onRowClick={onRowClick}
        idTable={idTable}
      />
    </SectionContainer>
  );
};

export default BannerDeliverables;
