import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { ModalShell } from '../../influencerViews/Profile/components/modals/ModalShell';

export const RemoveUserModal = ({ user, onClose, onSubmit }) => {
  return (
    <ModalShell
      title="REMOVE USER"
      noActionButtons
      open={true}
      onClose={() => {
        onClose();
      }}
    >
      <p>The action is not reversible. Are you sure you want to delete this user?</p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={() => {
            onClose();
          }}
          style={{
            border: 'none',
          }}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            onSubmit(user);
            onClose();
          }}
          variant="contained"
          style={{
            color: 'red',
            border: '1px solid red',
            background: 'white',
          }}
        >
          <DeleteIcon color="error"/>
          Delete
        </Button>
      </div>
    </ModalShell>
  );
};
