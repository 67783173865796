import { connect } from 'react-redux';
import { compose } from 'recompose';

import { actions } from '../../../store/actions';
import {
  getMyWatchlistTwitchInfluencers,
  getMyWatchlistTwitchLoaded,
  getMyWatchlistYoutubeInfluencers,
  getMyWatchlistYoutubeLoaded,
} from '../selectors';

const mapStateToProps = (state) => {
  return {
    youtubeLoaded: getMyWatchlistYoutubeLoaded(state),
    youtubeInfluencers: getMyWatchlistYoutubeInfluencers(state),
    twitchLoaded: getMyWatchlistTwitchLoaded(state),
    twitchInfluencers: getMyWatchlistTwitchInfluencers(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  removeFromWatchlist: actions.myWatchlistRemoveInfluencer,
});

export default compose(connect(mapStateToProps, mapDispatchToProps));
