import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CampaignBannerDeleteConfirmation, {
  useCampaignBannerDeleteConfirmation,
} from '../../CampaignBannerDeleteConfirmation';
import { DeliverablePaginator } from '../../components/DeliverablePaginator';
import BannerDeliverable from './BannerDeliverable';
import {
  selectBannerDeliverablesError,
  selectFilteredBannerDeliverablesStructures,
} from './store/BannerDeliverables.selectors';
import { deleteBannerVideo } from './store/BannerDeliverables.thunks';
import { BannerDeliverableStructure } from './store/types';

export const Banners = ({
  campaign,
  usedVideos,
  usedVideosIds,
  usedInfluencers,
  setSelectedCampaignInfluencer,
  isSponsorship,
  brandFilter,
  isFilteredByCreator,
  setByCreator,
  numberPage,
  setNumberPage,
}) => {
  const dispatch = useDispatch();

  const campaignId = campaign.id;

  const { setBannerToDelete, bannerToDelete } =
    useCampaignBannerDeleteConfirmation();

  useEffect(() => {
    if (!!numberPage) {
      return () => {
        setNumberPage(0);
      };
    }
  }, [numberPage, setNumberPage]);

  const handleConfirmDeleteBanner = useCallback(() => {
    dispatch(
      deleteBannerVideo({
        videoId: bannerToDelete.video.id,
        bannerDeliverableId: bannerToDelete.bannerDeliverableId,
        campaignId,
      })
    );
  }, [dispatch, campaignId, bannerToDelete]);

  const bannerDeliverableStrctures = useSelector(
    selectFilteredBannerDeliverablesStructures({
      campaignId,
      onlyBrandDeliverables: isSponsorship,
      brandIdFilter: brandFilter,
      sortBy: { key: 'results_count', order: 'desc' },
    })
  );

  const error = useSelector(selectBannerDeliverablesError());

  const renderDeliverable = useCallback(
    (bannerDeliverableStructure: BannerDeliverableStructure) => (
      <BannerDeliverable
        key={bannerDeliverableStructure.id}
        bannerDeliverableStructure={bannerDeliverableStructure}
        usedVideos={usedVideos}
        usedVideosIds={usedVideosIds}
        usedInfluencers={usedInfluencers}
        timeZone={campaign.time_zone}
        campaignId={campaign.id}
        setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
        isSponsorship={isSponsorship}
        setByCreator={setByCreator}
        isFilteredByCreator={isFilteredByCreator}
        deleteBanner={setBannerToDelete}
      />
    ),
    [
      usedVideos,
      usedVideosIds,
      usedInfluencers,
      campaign,
      setSelectedCampaignInfluencer,
      isSponsorship,
      setByCreator,
      isFilteredByCreator,
      setBannerToDelete,
    ]
  );

  if (error) return <p>error</p>;

  return (
    <>
      <CampaignBannerDeleteConfirmation
        bannerToDelete={bannerToDelete}
        setBannerToDelete={setBannerToDelete}
        handleConfirmDeleteBanner={handleConfirmDeleteBanner}
      />
      <div style={{ paddingTop: 24 }}>
        <DeliverablePaginator
          deliverables={bannerDeliverableStrctures}
          renderDeliverable={renderDeliverable}
          forcePageIndex={numberPage}
        />
      </div>
    </>
  );
};

export default Banners;
