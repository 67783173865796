import { CircularProgress, makeStyles, Tab, Tabs } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';
import _orderBy from 'lodash/orderBy';
import React, { useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';

import CardTitle from '../../../../../../components/CardTitle';
import Hyperlink from '../../../../../../components/Hyperlink';
import toObject from '../../../../../../utils/to-object';
import { AudienceBrand, AudienceBrandCategory } from '../../store/types';

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: theme.spacing(48),
    overflowY: 'scroll',
    flexGrow: 1,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    textAlign: 'left',
  },
  mainCardContent: {
    display: 'flex',
    height: 400,
  },
  locked: {
    filter: 'blur(5px)',
    userSelect: 'none',
    pointerEvents: 'none',
  },
}));

interface AudienceBrandAffinitiesProps {
  topBrands: AudienceBrand[];
  topCategories: AudienceBrandCategory[];
  locked?: boolean;
}

export function AudienceBrandAffinities(props: AudienceBrandAffinitiesProps) {
  const classes = useStyles();

  const [tab, setTab] = useState('Any');

  const handleTabChange = (_: React.ChangeEvent, value: string) => {
    setTab(value);
  };

  const fallbackImage = '../../fallback/img.png';

  const topBrands = useMemo(
    () => _orderBy(props.topBrands, (b) => b.percentage, 'desc'),
    [props.topBrands]
  );

  const topCategories = useMemo(
    () => _orderBy(props.topCategories, (c) => c.percentage, 'desc'),
    [props.topCategories]
  );

  const brandsByCategory = {
    Any: topBrands,
    ...toObject(
      ({ category }) => category,
      ({ category }) =>
        topBrands.filter(
          ({ main_category_label }) => category === main_category_label
        )
    )(topCategories),
  };

  const categWithBrands = topCategories.filter(
    ({ category }) => brandsByCategory[category].length
  );

  return (
    <Card>
      <CardContent>
        <CardTitle>Audience brand affinities</CardTitle>
      </CardContent>
      <CardContent
        className={cx(classes.mainCardContent, {
          [classes.locked]: props.locked,
        })}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tab}
          onChange={handleTabChange}
          className={classes.tabs}
          indicatorColor="primary"
        >
          <Tab
            className={classes.tab}
            value="Any"
            label={<Box width="100%">Any</Box>}
          />
          {categWithBrands.map((category) => (
            <Tab
              className={classes.tab}
              key={category.category}
              value={category.category}
              label={
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {category.category}
                  <Box position="relative" width={48} height={48}>
                    <CircularProgress
                      style={{
                        border: '1px solid #bbb',
                        borderRadius: '50%',
                      }}
                      size={48}
                      variant="determinate"
                      value={Math.max(5, category.percentage)}
                    />
                    <Box
                      position="absolute"
                      fontSize="0.8rem"
                      fontWeight="bold"
                      top="50%"
                      left="50%"
                      style={{
                        transform: 'translateX(-50%) translateY(-50%)',
                      }}
                    >
                      {category.percentage.toFixed(1)}%
                    </Box>
                  </Box>
                </Box>
              }
            />
          ))}
        </Tabs>
        <List disablePadding className={classes.list}>
          <FixedSizeList
            itemSize={72}
            height={384}
            itemCount={brandsByCategory[tab] ? brandsByCategory[tab].length : 0}
          >
            {({ index, style }) => {
              const { brand, avatar_url, name, percentage } =
                brandsByCategory[tab][index];

              return (
                <ListItem
                  key={name}
                  button
                  // @ts-ignore this prop is broken for some reason
                  component={Hyperlink}
                  target="_blank"
                  href={`https://twitter.com/${brand}`}
                  style={style}
                >
                  <Box width="100%">
                    <Grid
                      container
                      justify="space-between"
                      alignItems="flex-end"
                    >
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <Avatar
                              src={avatar_url ? avatar_url : fallbackImage}
                              alt={name?.toUpperCase()}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              component="span"
                              display="block"
                            >
                              {name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="span"
                              display="block"
                            >
                              @{brand}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Box fontSize="1.25rem" fontWeight="bold">
                          {percentage < 1 ? '< 1' : percentage.toFixed(1)}%
                        </Box>
                      </Grid>
                    </Grid>
                    <Box clone mt={1}>
                      <LinearProgress
                        variant="determinate"
                        value={percentage}
                      />
                    </Box>
                  </Box>
                </ListItem>
              );
            }}
          </FixedSizeList>
        </List>
      </CardContent>
    </Card>
  );
}
