import _uniqBy from 'lodash/uniqBy';

import facebookIcon from '../../../assets-2.0/white-logos/facebook.png';
import facebookGamingIcon from '../../../assets-2.0/white-logos/facebookGaming.svg';
import instagramIcon from '../../../assets-2.0/white-logos/instagram.svg';
import tiktokIcon from '../../../assets-2.0/white-logos/tiktok.png';
import twitchIcon from '../../../assets-2.0/white-logos/twitch.svg';
import twitterIcon from '../../../assets-2.0/white-logos/twitter.svg';
import youtubeIcon from '../../../assets-2.0/white-logos/youtube.svg';
import {
  connectFacebook,
  connectTwitter,
  redirectTiktok,
  redirectTwitch,
  redirectYoutube,
} from '../platformsLogin';
import { Pages, Platform } from './types';

export const getPlatforms = ({
  pages,
  facebookCallback,
  influencerToken,
  disconnectPage,
}: {
  pages: Pages;
  influencerToken: string;
  disconnectPage: ({
    influencer_token,
    network,
    page_id,
  }: {
    influencer_token: string;
    network: keyof Pages;
    page_id: number;
  }) => void;
  facebookCallback?: (any) => void;
}): Platform[] => {
  const allPlatforms: Platform[] = [
    {
      icon: twitchIcon,
      color: '#5a3e84',
      key: 'twitch',
      connected: pages.twitch?.connected,
      url: pages.twitch?.twitch_url,
      avatar: pages.twitch?.avatar,
      pageId: pages.twitch?.page_id,
      pageExternalId: pages.twitch?.page_external_id,
      pageTitle:
        pages.twitch?.page_title ||
        getPageTitleFromUrl(pages.twitch?.twitch_url),
      onConnect: redirectTwitch,
      onDisconnect: () => {
        disconnectPage({
          influencer_token: influencerToken,
          network: 'twitch',
          page_id: pages.twitch?.page_id,
        });
      },
    },
    {
      icon: youtubeIcon,
      color: '#e61e1e',
      key: 'youtube',
      connected: pages.youtube?.connected,
      url: pages.youtube?.youtube_url,
      avatar: pages.youtube?.avatar,
      pageId: pages.youtube?.page_id,
      pageExternalId: pages.youtube?.page_external_id,
      pageTitle:
        pages.youtube?.page_title ||
        getPageTitleFromUrl(pages.youtube?.youtube_url),
      onConnect: redirectYoutube,
      onDisconnect: () => {
        disconnectPage({
          influencer_token: influencerToken,
          network: 'youtube',
          page_id: pages.youtube?.page_id,
        });
      },
    },
    {
      icon: instagramIcon,
      color: '#e8008f',
      key: 'instagram',
      connected: pages.instagram?.connected,
      url: pages.instagram?.instagram_url,
      avatar: pages.instagram?.avatar,
      pageId: pages.instagram?.page_id,
      pageExternalId: pages.instagram?.page_external_id,
      pageTitle:
        pages.instagram?.page_title ||
        getPageTitleFromUrl(pages.instagram?.instagram_url),
      onConnect: () => {
        connectFacebook((facebookAuthResponse) => {
          facebookCallback({
            ...facebookAuthResponse,
            pageName:
              pages.instagram?.page_title || pages.instagram?.page_external_id,
            onboardingNetwork: 'instagram',
            influencer_token: influencerToken,
          });
        });
      },
      onDisconnect: () => {
        disconnectPage({
          influencer_token: influencerToken,
          network: 'instagram',
          page_id: pages.instagram?.page_id,
        });
      },
    },
    {
      icon: twitterIcon,
      color: '#1da0f1',
      key: 'twitter',
      connected: pages.twitter?.connected,
      url: pages.twitter?.twitter_url,
      avatar: pages.twitter?.avatar,
      pageId: pages.twitter?.page_id,
      pageExternalId: pages.twitter?.page_external_id,
      pageTitle:
        pages.twitter?.page_title ||
        getPageTitleFromUrl(pages.twitter?.twitter_url),
      onConnect: connectTwitter,
      onDisconnect: () => {
        disconnectPage({
          influencer_token: influencerToken,
          network: 'twitter',
          page_id: pages.twitter?.page_id,
        });
      },
    },
    {
      icon: tiktokIcon,
      color: '#000000',
      key: 'tiktok',
      connected: pages.tiktok?.connected,
      url: pages.tiktok?.tiktok_url,
      avatar: pages.tiktok?.avatar,
      pageId: pages.tiktok?.page_id,
      pageExternalId: pages.tiktok?.page_external_id,
      pageTitle:
        pages.tiktok?.page_title ||
        getPageTitleFromUrl(pages.tiktok?.tiktok_url),
      onConnect: redirectTiktok,
      onDisconnect: () => {
        disconnectPage({
          influencer_token: influencerToken,
          network: 'tiktok',
          page_id: pages.tiktok?.page_id,
        });
      },
    },
    {
      icon: facebookIcon,
      color: '#1877f2',
      key: 'facebook',
      connected: pages.facebook?.connected,
      url: pages.facebook?.facebook_url,
      avatar: pages.facebook?.avatar,
      pageId: pages.facebook?.page_id,
      pageExternalId: pages.facebook?.page_external_id,
      pageTitle:
        pages.facebook?.page_title ||
        getPageTitleFromUrl(pages.facebook?.facebook_url),
      onConnect: () => {
        connectFacebook((facebookAuthResponse) => {
          facebookCallback({
            ...facebookAuthResponse,
            pageName:
              pages.facebook?.page_title || pages.facebook?.page_external_id,
            onboardingNetwork: 'facebook',
            influencer_token: influencerToken,
          });
        });
      },
      onDisconnect: () => {
        disconnectPage({
          influencer_token: influencerToken,
          network: 'facebook',
          page_id: pages.facebook?.page_id,
        });
      },
    },
    {
      icon: facebookGamingIcon,
      color: '#2A62FF',
      key: 'facebook_gaming',
      connected: pages.facebook_gaming?.connected,
      url: pages.facebook_gaming?.facebook_gaming_url,
      avatar: pages.facebook_gaming?.avatar,
      pageId: pages.facebook_gaming?.page_id,
      pageExternalId: pages.facebook_gaming?.page_external_id,
      pageTitle:
        pages.facebook_gaming?.page_title ||
        getPageTitleFromUrl(pages.facebook_gaming?.facebook_gaming_url),
      onConnect: () => {
        connectFacebook((facebookAuthResponse) => {
          facebookCallback({
            ...facebookAuthResponse,
            pageName:
              pages.facebook_gaming?.page_title ||
              pages.facebook_gaming?.page_external_id,
            onboardingNetwork: 'facebook',
            influencer_token: influencerToken,
          });
        });
      },
      onDisconnect: () => {
        disconnectPage({
          influencer_token: influencerToken,
          network: 'facebook',
          page_id: pages.facebook_gaming?.page_id,
        });
      },
    },
  ];

  const definedPlatforms = allPlatforms.filter(
    (platform) => !!pages[platform.key]
  ); // Filter out null pages
  return _uniqBy(definedPlatforms, 'url'); // Filter out pages with same url (for e.g. Facebook & Facebook Gaming)
};

const getPageTitleFromUrl = (pageUrl: string) => {
  if (!pageUrl) {
    return;
  }

  let url = pageUrl;

  // If the last character is "/", remove it
  if (url.charAt(url.length - 1) === '/') {
    url = url.slice(0, url.length - 1);
  }

  // eslint-disable-next-line no-useless-escape
  const regex = /([^\/]+$)/g;
  const match = url.match(regex);
  return match ? match[0] : '';
};
