import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from '@material-ui/core';
import React from 'react';
import { useState } from 'react';

export const CampaignMultipleItemsDeleteDialog = ({
  items,
  onDelete,
  title,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        style={{
          borderWidth: 2,
        }}
        onClick={handleClickOpen}
      >
        <div>Delete</div>
        <div style={{ fontWeight: 'bold', marginLeft: 3, marginRight: 3 }}>
          {items.length}
        </div>
        <div>Selected {items.length > 1 ? 'items' : 'item'}</div>
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete <b>{items.length}</b>{' '}
            {items.length === 1 ? 'item' : 'items'}? Please note that this
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: '12px 24px' }}
        >
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onDelete(items);
              handleClose();
            }}
            color="secondary"
          >
            <Box clone mr={1}>
              <Icon>delete_outline</Icon>
            </Box>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
