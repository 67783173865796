import moment from 'moment';
import React from 'react';

function NewBadge(props) {
  const { created_at, days } = props;

  if (!created_at) {
    return null;
  }

  if (moment(created_at).isBefore(moment().subtract(days || 10, 'days'))) {
    return null;
  }

  return (
    <div className="box" data-timestamp={created_at}>
      <div className="ribbon">
        <span>NEW</span>
      </div>
    </div>
  );
}

export default NewBadge;
