import Papa from 'papaparse';

// import * as Sentry from '@sentry/react';
import { TopMoment, TopMomentsSchema } from './types';

type fetchTopMomentSignature = {
  campaignId: number;
  onComplete: (top_moments: TopMoment[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: any) => void;
};

export function fetchTopMoments({
  campaignId,
  onComplete,
  setLoading,
  setError,
}: fetchTopMomentSignature) {
  const csvFilePath = `https://storage.googleapis.com/aggero-gaming-report/${campaignId}_categories_timeframes_with_transcript.csv`;

  setLoading(true);

  Papa.parse(csvFilePath, {
    download: true,
    header: true,
    skipEmptyLines: true,
    dynamicTyping: false,

    transform: function (value, header) {
      if (
        header === 'topic' ||
        header === 'category' ||
        header === 'streamer'
      ) {
        return String(value).toLowerCase().trim();
      }

      if (
        header === 'moment_length' ||
        header === 'transcript_started_at' ||
        header === 'transcript_ended_at' ||
        header === 'Timestamp(in video (s))' ||
        header === 'campaign_influencer_id' ||
        header === 'moment_ccv'
      ) {
        return Number(value);
      }

      return value;
    },

    complete: function (results) {
      const csvData = results.data;

      const parseResult = TopMomentsSchema.safeParse(csvData);

      if (parseResult.success === false) {
        // console.error(
        //   'Failed to parse Top Moments CSV data:',
        //   parseResult.error
        // );
        // Don't log this error to Sentry for now
        // Sentry.captureException(parseResult.error);
      }

      onComplete(csvData as TopMoment[]);
      setLoading(false);
    },
    error: function (err) {
      setError(err);
      setLoading(false);
    },
  });
}
