import { connect } from 'react-redux';
import { compose } from 'recompose';

import { actions } from '../../../store/actions';
import { selectors } from '../../../store/selectors';

const mapStateToProps = (state) => ({
  dialog: selectors.getDialog(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAccept: (index) => dispatch(actions.dialogAccept(index)),
  onCancel: () => dispatch(actions.dialogCancel()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps));
