import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

import useDelayedValue from '../hooks/use-delayed-value';

function LoadingButton(props) {
  const { loading, progressColor, ...buttonProps } = props;
  const delayedLoading = useDelayedValue(loading, 200);
  return (
    <Button {...buttonProps}>
      {loading && delayedLoading ? (
        <CircularProgress color={progressColor || 'primary'} size={20} />
      ) : (
        props.children
      )}
    </Button>
  );
}

export default LoadingButton;
