import _cloneDeep from 'lodash/cloneDeep';

import { actionTypes as influencerActionTypes } from '../../../views/Influencer/redux';
import { actionTypes as myWatchlistActionTypes } from '../../../views/MyWatchlist/redux';
import {
  CREATE_WATCHLIST_FAILED,
  CREATE_WATCHLIST_REQUESTED,
  CREATE_WATCHLIST_SUCCEEDED,
  DELETE_WATCHLIST_FAILED,
  DELETE_WATCHLIST_SUCCEEDED,
  RENAME_WATCHLIST_FAILED,
  RENAME_WATCHLIST_SUCCEEDED,
  WATCHLISTS_REQUEST_SUCCEEDED,
} from '../../action-types';

const INITIAL_STATE = {
  watchlists: null,
  loaded: false,
  createWatchlistLoading: false,
};

const user = (state = INITIAL_STATE, action) => {
  if (action.type === WATCHLISTS_REQUEST_SUCCEEDED) {
    const { watchlists, loaded } = action.payload;

    return { watchlists, loaded };
  }

  if (
    action.type === RENAME_WATCHLIST_SUCCEEDED ||
    action.type === RENAME_WATCHLIST_FAILED
  ) {
    const { name, id } = action.payload;

    const newState = _cloneDeep(state);

    if (newState.watchlists)
      newState.watchlists = newState.watchlists.map((watchlist) => {
        if (watchlist.id === id) watchlist.name = name;

        return watchlist;
      });

    return newState;
  }

  if (action.type === DELETE_WATCHLIST_SUCCEEDED) {
    const { id } = action.payload;

    const newState = _cloneDeep(state);

    if (newState.watchlists)
      newState.watchlists = newState.watchlists.filter(
        (watchlist) => watchlist.id !== id
      );

    return newState;
  }

  if (action.type === DELETE_WATCHLIST_FAILED) {
    const { watchlist, index } = action.payload;

    const newState = _cloneDeep(state);

    if (newState.watchlists)
      newState.watchlists = newState.watchlists
        .slice(0, index)
        .concat([watchlist])
        .concat(newState.watchlists.slice(index));

    return newState;
  }

  if (action.type === CREATE_WATCHLIST_REQUESTED) {
    const newState = _cloneDeep(state);

    newState.createWatchlistLoading = true;

    return newState;
  }

  if (action.type === CREATE_WATCHLIST_SUCCEEDED) {
    const newState = _cloneDeep(state);

    newState.createWatchlistLoading = false;

    if (!newState.watchlists) newState.watchlists = [];

    newState.watchlists.unshift({
      ...action.payload.watchlist,
      influencers: 0,
    });

    return newState;
  }

  if (action.type === CREATE_WATCHLIST_FAILED) {
    const newState = _cloneDeep(state);

    newState.createWatchlistLoading = false;

    return newState;
  }

  if (
    action.type === influencerActionTypes.ADD_TO_WATCHLIST_SUCCESS ||
    action.type === myWatchlistActionTypes.MY_WATCHLIST_ADD_SUCCESS
  ) {
    const newState = _cloneDeep(state);

    newState.watchlists = newState.watchlists.map((w) => {
      if (w.id === action.watchlistId) {
        w.influencers += 1;
      }

      return w;
    });

    return newState;
  }

  if (action.type === influencerActionTypes.REMOVE_FROM_WATCHLIST_SUCCESS) {
    const newState = _cloneDeep(state);

    newState.watchlists = newState.watchlists.map((w) => {
      if (w.id === action.watchlistId) {
        w.influencers -= 1;
      }

      return w;
    });

    return newState;
  }

  if (
    action.type ===
    myWatchlistActionTypes.MY_WATCHLIST_REMOVE_INFLUENCER_SUCCEEDED
  ) {
    const newState = _cloneDeep(state);

    newState.watchlists = newState.watchlists.map((w) => {
      if (w.id === action.watchlistId) {
        w.influencers -= 1;
      }

      return w;
    });

    return newState;
  }

  if (
    action.type === myWatchlistActionTypes.MY_WATCHLIST_REMOVE_INFLUENCER_FAILED
  ) {
    const newState = _cloneDeep(state);

    newState.watchlists = newState.watchlists.map((w) => {
      if (w.id === action.watchlistId) {
        w.influencers += 1;
      }

      return w;
    });
  }

  return state;
};

export default user;
