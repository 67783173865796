import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  makeStyles,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateStreamTimeframe } from '../../../ajax';
import LoadingButton from '../../../components/LoadingButton';
import queryClient from '../../../queryClient';
import { campaignVideoAddFilterSucceeded } from '../../../store/models/campaigns/campaigns.actions';
import VideoPreview from '../components/VideoPreview';
import { findInfluencerByVideo, findVideoById } from '../utils';

const getSecondsFromHHMMSS = (value) => {
  const [str1, str2, str3] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    // seconds
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    // minutes * 60 + seconds
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    // hours * 60 * 60 + minutes * 60 + seconds
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

const toHHMMSS = (secs = 0) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map((val) => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== '00' || index > 0)
    .join(':')
    .replace(/^0/, '');
};

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  valuesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));

export const TimeSegmentModal = ({
  onClose: onCloseExternal,
  videoId,
  campaign,
  usedVideos,
  usedInfluencers,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const open = !!videoId;

  const video = findVideoById(videoId, usedVideos);

  const influencer = findInfluencerByVideo(video, usedInfluencers);

  const { duration, published_at } = video;
  const { time_zone } = campaign;

  const [range, setRange] = useState([0, duration]);

  const [start, end] = range;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [startInput, setStartInput] = useState('');
  const [endInput, setEndInput] = useState('');

  const onClose = () => {
    if (loading) return;

    onCloseExternal();
  };
  useEffect(() => {
    if (videoId) {
      let start = 0;
      let end = duration;

      if (video.video_segments && video.video_segments.length > 0) {
        start = video.video_segments[0].start_at;
        end = video.video_segments[0].end_at;
      }

      setRange([start, end]);
      setStartInput(toHHMMSS(start));
      setEndInput(toHHMMSS(end));
    }

    if (!videoId) {
      setLoading(false);
      setErrorMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  if (!open) return null;

  const onChange = (_, [start, end]) => {
    if (start >= end) return;

    setStartInput(toHHMMSS(start));
    setEndInput(toHHMMSS(end));

    setRange([start, end]);
  };

  const onInputChange = (input) => (event) => {
    const { value } = event.target;
    if (input === 'start') setStartInput(value);
    else setEndInput(value);

    let seconds;

    if (input === 'start')
      seconds = Math.min(Math.max(0, getSecondsFromHHMMSS(value)), end);
    else
      seconds = Math.min(
        Math.max(start, getSecondsFromHHMMSS(value)),
        duration
      );

    if (input === 'start') setRange([seconds, end]);
    else setRange([start, seconds]);
  };

  const onInputBlur = (input) => () => {
    if (input === 'start') {
      setStartInput(toHHMMSS(start));
    } else {
      setEndInput(toHHMMSS(end));
    }
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      let segmentId;

      if (video.video_segments && video.video_segments.length > 0) {
        segmentId = video.video_segments[0].id;
      }

      const newVideo = await updateStreamTimeframe(
        campaign.id,
        videoId,
        range,
        segmentId
      );

      dispatch(
        campaignVideoAddFilterSucceeded({
          campaignId: campaign.id,
          videoId,
          video: newVideo,
        })
      );

      findVideoById.cache.set(videoId, newVideo);

      queryClient.clear();

      onCloseExternal();
    } catch {
      setErrorMessage('Failed to update video time frame');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle style={{ position: 'relative' }} disableTypography>
        <Typography variant="h6">Time frame select</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          disabled={loading}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <VideoPreview
          video={video}
          influencer={influencer}
          timeZone={campaign.time_zone}
        />
        <div className={classes.sliderContainer}>
          <Slider
            value={range}
            min={0}
            max={duration}
            onChange={onChange}
            valueLabelFormat={toHHMMSS}
            valueLabelDisplay="off"
          />
        </div>
        <div className={classes.valuesContainer}>
          <div>
            <FormLabel>From:</FormLabel>
            <br />
            <TextField
              variant="outlined"
              value={startInput}
              onChange={onInputChange('start')}
              onBlur={onInputBlur('start')}
              placeholder="hh:mm:ss"
            />
            <p>
              {moment(published_at)
                .tz(time_zone)
                .add(start, 'seconds')
                .format('hh:mm:ss A')}
            </p>
          </div>
          <div>
            <Button
              disabled={
                startInput === toHHMMSS(0) && endInput === toHHMMSS(duration)
              }
              color="primary"
              variant="outlined"
              onClick={() => {
                setStartInput(toHHMMSS(0));
                setEndInput(toHHMMSS(duration));
                setRange([0, duration]);
              }}
            >
              RESET SELECTION
            </Button>
          </div>
          <div>
            <FormLabel>To:</FormLabel>
            <br />
            <TextField
              variant="outlined"
              value={endInput}
              onChange={onInputChange('end')}
              onBlur={onInputBlur('end')}
              placeholder="hh:mm:ss"
            />
            <p>
              {moment(published_at)
                .tz(time_zone)
                .add(end, 'seconds')
                .format('hh:mm:ss A')}
            </p>
          </div>
        </div>
        {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={onSubmit}
          color="primary"
          variant="contained"
          loading={loading}
          disabled={loading}
        >
          Apply
        </LoadingButton>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeSegmentModal;
