import { makeStyles } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { selectAllSocialMediaPosts } from '../Stats/SocialMedia/store/SocialMediaPosts.slice';
import { selectDateFilter } from '../Stats/store/Stats.selectors';
import { setDateFilter } from '../Stats/store/Stats.slice';
// import { selectAllStreams } from '../Stats/Streaming/store/Streams.slice';
const useStyles = makeStyles((theme) => ({
  datePickersMain: {
    width: '100%',
    textAlign: 'right',
    marginBottom: 25,
  },
  datepicker: {
    width: 200,
    height: 35,
    '& svg': {
      fill: '#8c8c8c',
      cursor: 'pointer',
    },
    '& input': {
      color: '#8c8c8c',
      cursor: 'pointer',
      fontSize: 16,
    },
    '& label': {
      color: '#8c8c8c',
    },
  },
}));

function InfluencerDatePicker() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dates = useSelector(selectDateFilter());

  const [startDate, setStartDate] = useState(moment().format('YYYY/MM/DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY/MM/DD'));

  const setNewDate = (start_date, end_date) => {
    dispatch(
      setDateFilter({
        start_date,
        end_date,
      })
    );
  };
  return (
    <div className={classes.datePickersMain}>
      <DatePicker
        className={classes.datepicker}
        name="InfluencerStatsFilterStart"
        InputProps={{
          endAdornment: <EventAvailableIcon />,
        }}
        value={dates.start_date ? dates.start_date : startDate}
        label="From"
        inputVariant="outlined"
        format="DD/MM/YYYY"
        maxDate={dates.end_date ? dates.end_date : endDate}
        onChange={(e) => {
          setStartDate(moment(e).toISOString());
          setNewDate(moment(e).toISOString(), dates.end_date);
        }}
        labelFunc={() =>
          dates.start_date
            ? moment(dates.start_date).format('DD/MM/YYYY')
            : 'Select a date'
        }
      />

      <DatePicker
        className={classes.datepicker}
        style={{ marginLeft: 4 }}
        name="InfluencerStatsFilterEnd"
        InputProps={{
          endAdornment: <EventAvailableIcon />,
        }}
        value={dates.end_date ? dates.end_date : endDate}
        label="To"
        inputVariant="outlined"
        format="DD/MM/YYYY"
        minDate={dates.start_date ? dates.start_date : startDate}
        onChange={(e) => {
          const newDate = e.format('YYYY/MM/DD');
          setEndDate(e.toISOString());
          setNewDate(
            dates.start_date,
            moment(newDate)
              .add('days', 1)
              .subtract('milliseconds', 1)
              .toISOString()
          );
        }}
        labelFunc={() =>
          dates.end_date
            ? moment(dates.end_date).format('DD/MM/YYYY')
            : 'Select a date'
        }
      />
    </div>
  );
}

export default InfluencerDatePicker;
