import {
  ButtonBase,
  Card,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArtTrack, AttachMoney, Speed } from '@material-ui/icons';
import { connect, useFormikContext } from 'formik';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    display: 'flex',
    background: 'no-repeat',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '30px',
    borderRadius: '15px',
    '&:hover': {
      background: 'lightgray',
    },
  },
  base: {
    borderRadius: '15px',
    cursor: 'pointer',
    transition: '.5s',
    width: '30%',
  },
  innerPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 250,
  },
  img: {
    color: '#ff8346',
    width: '80px',
    border: '4px solid #0048F2',
    height: '80px',
    padding: '16px',
    borderRadius: '50%',
    marginBottom: '12px',
  },
  heading: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'center',
    marginTop: '10px',
  },
  margin: {
    margin: '0',
  },
}));

const enhance = connect;

const CampaignWizardTrackingOptions = ({
  mediaFlag,
  SVFlag,
  performanceFlag,
  setPerformanceFlag,
  setSVFlag,
  setMediaFlag,
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  return (
    <Card className={classes.root}>
      <ButtonBase className={classes.base}>
        <Paper
          className={classes.paper}
          style={{ border: performanceFlag && '1px solid #0048F2' }}
          onClick={() => {
            !performanceFlag
              ? setFieldValue('campaignTypes', [...values.campaignTypes, 1])
              : setFieldValue(
                  'campaignTypes',
                  values.campaignTypes.filter((id) => id !== 1)
                );
            setPerformanceFlag(!performanceFlag);
          }}
        >
          <div className={classes.innerPaper}>
            <Speed className={classes.img} />
            <Typography className={classes.heading}>
              Channel Performance
            </Typography>
            <Typography className={classes.text}>
              Monitor overall channel performance indicators.
            </Typography>
          </div>
          <FormControlLabel
            className={classes.margin}
            control={<Checkbox color="primary" checked={performanceFlag} />}
          />
          <Typography className={classes.text}> </Typography>
        </Paper>
      </ButtonBase>
      <ButtonBase className={classes.base}>
        <Paper
          className={classes.paper}
          style={{ border: SVFlag && '1px solid #0048F2' }}
          onClick={() => {
            !SVFlag
              ? setFieldValue('campaignTypes', [...values.campaignTypes, 2])
              : setFieldValue(
                  'campaignTypes',
                  values.campaignTypes.filter((id) => id !== 2)
                );
            setSVFlag(!SVFlag);
          }}
        >
          <div className={classes.innerPaper}>
            <AttachMoney className={classes.img} />
            <Typography className={classes.heading}>Brand Tracking</Typography>
            <Typography className={classes.text}>
              Track and measure brand logo placements, brand voice mentions, and
              brand text mentions to evaluate sponsorship.
            </Typography>
          </div>
          <FormControlLabel
            className={classes.margin}
            control={<Checkbox color="primary" checked={SVFlag} />}
          />
          <Typography className={classes.text}>
            Powered by <b>StreamScan™</b>
          </Typography>
        </Paper>
      </ButtonBase>
      <ButtonBase className={classes.base}>
        <Paper
          className={classes.paper}
          style={{ border: mediaFlag && '1px solid #0048F2' }}
          onClick={() => {
            !mediaFlag
              ? setFieldValue('campaignTypes', [...values.campaignTypes, 3])
              : setFieldValue(
                  'campaignTypes',
                  values.campaignTypes.filter((id) => id !== 3)
                );
            setMediaFlag(!mediaFlag);
          }}
        >
          <div className={classes.innerPaper}>
            <ArtTrack className={classes.img} />
            <Typography className={classes.heading}>Custom Tracking</Typography>
            <Typography className={classes.text}>
              Track any keyword text/voice mentions and image placements that
              are not related to a brand.
            </Typography>
          </div>
          <FormControlLabel
            className={classes.margin}
            control={<Checkbox color="primary" checked={mediaFlag} />}
          />
          <Typography className={classes.text}>
            Powered by <b>StreamScan™</b>
          </Typography>
        </Paper>
      </ButtonBase>
    </Card>
  );
};

export default enhance(CampaignWizardTrackingOptions);
