import React, { useCallback, useMemo } from 'react';

import Table, { StatBox, TableText } from '../Table';
import SectionContainer from './SectionContainer';
import { SectionTitle } from './shared';

const getCols = ({
  showTwitter,
  showInstagram,
  impressionsAreEst,
  showTiktok,
  showFacebook,
}) =>
  [
    {
      label: 'Keyword',
      key: 'keyword',
      render: (data) => <TableText>{data.keyword}</TableText>,
      stretch: true,
    },
    {
      label: 'Mentions',
      key: 'mentions',
      render: (data) => <StatBox stat={data.mentions} />,
    },
    {
      label: 'Creators',
      key: 'creators',
      render: (data) => <StatBox stat={data.creators} />,
    },
    showTwitter
      ? {
          label: 'Twitter',
          key: 'twitter',
          render: (data) => <StatBox stat={data.mentions && data.twitter} />,
        }
      : null,
    showInstagram
      ? {
          label: 'Instagram',
          key: 'instagram',
          render: (data) => <StatBox stat={data.mentions && data.instagram} />,
        }
      : null,
    showTiktok
      ? {
          label: 'TikTok',
          key: 'tiktok',
          render: (data) => <StatBox stat={data.mentions && data.tiktok} />,
        }
      : null,
    showFacebook
      ? {
          label: 'Facebook',
          key: 'facebook',
          render: (data) => <StatBox stat={data.mentions && data.facebook} />,
        }
      : null,
    {
      label: 'Engagements',
      key: 'engagements',
      render: (data) => <StatBox stat={data.engagements} />,
    },
    {
      label: impressionsAreEst ? 'Est. Impressions' : 'Impressions',
      key: 'impressions',
      render: (data) => <StatBox stat={data.impressions} />,
    },
    {
      label: 'Media Value',
      key: 'totalEMV',
      render: (data) => (
        <StatBox stat={data.totalEMV} format="0,[0].[0]a" prefix="$" />
      ),
    },
  ].filter((c) => c !== null);

const getRows = (mentions, isSponsorship) =>
  mentions.map((m, key) => ({
    key,
    keyword: m.keyword,
    mentions: m.mentions,
    creators: m.creators,
    twitter: isSponsorship ? m.mentions_tw : m.twitter,
    instagram: isSponsorship ? m.mentions_ig : m.instagram,
    tiktok: isSponsorship ? m.mentions_tt : m.tiktok,
    facebook: isSponsorship ? m.mentions_fb : m.facebook,
    engagements: m.engagements,
    impressions: m.impressions,
    totalEMV: m.emv,
    impressionsAreEst: m.impressionsAreEst,
  }));

export const SocialMediaMentions = ({
  mentions,
  onViewMore,
  campaignInfluencers,
  isSponsorship,
  setNumberPage,
  activeTab,
}) => {
  const rows = useMemo(() => getRows(mentions, isSponsorship), [mentions]);

  const showTwitter = campaignInfluencers.some((i) => i.twitter_url);
  const showInstagram = campaignInfluencers.some((i) => i.instagram_url);
  const showTiktok = campaignInfluencers.some((i) => i.tiktok_url);
  const showFacebook = campaignInfluencers.some((i) => i.facebook_url);

  const onRowClick = useCallback(
    ({ keyword }) => {
      if (activeTab !== 'summary') {
        return setNumberPage(
          Math.floor(
            mentions.findIndex((mention) => mention.keyword === keyword) / 5
          )
        );
      }
      onViewMore(keyword);
    },
    [onViewMore]
  );

  const allImpressionsAreEst = rows.some(
    ({ impressionsAreEst }) => impressionsAreEst
  );

  const cols = useMemo(
    () =>
      getCols({
        showInstagram,
        showTwitter,
        showTiktok,
        showFacebook,
        impressionsAreEst: allImpressionsAreEst,
      }),
    [showInstagram, showTwitter, showTiktok, showFacebook]
  );
  const idTable = 'social-media-mentions';

  return (
    <SectionContainer isSponsorship={isSponsorship}>
      <SectionTitle onViewMore={onViewMore}>Social posts mentions</SectionTitle>
      <Table
        noBorders={true}
        cols={cols}
        rows={rows}
        defaultSortCol="mentions"
        onRowClick={onRowClick}
        idTable={idTable}
      />
    </SectionContainer>
  );
};

export default SocialMediaMentions;
