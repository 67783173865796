import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import CardTitle from '../../../components/CardTitle';
import Hyperlink from '../../../components/Hyperlink';
import LinearProgress from '../../../components/LinearProgress';

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: theme.spacing(48),
    overflowY: 'scroll',
  },
}));

function InfluencerAudienceTopFollowed(props) {
  const { topFollowed } = props;

  const classes = useStyles();

  // 1st is Influencer Account, we don't show that
  const accounts = topFollowed.slice(1, topFollowed.length);

  return (
    <>
      <Grid item>
        <Typography variant="h5" component="h2">
          Audience Interests
        </Typography>
      </Grid>

      <Grid item>
        <Card>
          <CardContent>
            <CardTitle>AUDIENCE OVERLAP</CardTitle>
          </CardContent>
          <CardContent>
            <List disablePadding className={classes.list}>
              {accounts.map(function (
                { user, name, avatar_url, percentage },
                i
              ) {
                return (
                  <ListItem
                    button
                    key={name}
                    component={Hyperlink}
                    target="_blank"
                    href={`https://twitter.com/${user}`}
                  >
                    <Box width="100%">
                      <Grid
                        container
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <Grid item>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Avatar alt={name} src={avatar_url}>
                                {name &&
                                  name.charAt(0) &&
                                  name.charAt(0).toUpperCase()}
                              </Avatar>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                component="span"
                                display="block"
                              >
                                {name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="span"
                                display="block"
                              >
                                @{user}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Box fontSize="1.25rem" fontWeight="bold">
                            {percentage < 1 ? '< 1' : percentage.toFixed()}%
                          </Box>
                        </Grid>
                      </Grid>
                      <Box clone mt={1}>
                        <LinearProgress
                          variant="determinate"
                          value={percentage}
                        />
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default InfluencerAudienceTopFollowed;
