import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import mapSelectorsToProps from '../../../helpers/map-selectors-to-props';
import {
  influencersFetchNextPage,
  influencersMounted,
} from '../../../store/events';
import { selectCommunication } from '../../../store/models/communication/communication.selectors';
import {
  getCompanyName,
  getCompanySearchLimit,
} from '../../../store/models/user/user.selectors';
import {
  selectSearchFetchingNextPage,
  selectSearchGameIdList,
  selectSearchHasNextPage,
  selectSearchInfluencers,
  selectSearchPageIndex,
  selectTotalResults,
} from '../../../store/selectors-folder/search-influencers.selector';

const mapStateToProps = mapSelectorsToProps({
  influencers: selectSearchInfluencers,
  gameIdList: selectSearchGameIdList,
  influencersSearchCommunication: selectCommunication('influencers'),
  searchLimit: getCompanySearchLimit,
  companyName: getCompanyName,
  fetchingNextPage: selectSearchFetchingNextPage,
  hasNextPage: selectSearchHasNextPage,
  totalResults: selectTotalResults,
  pageIndex: selectSearchPageIndex,
});

const mapDispatchToProps = { influencersMounted, influencersFetchNextPage };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);
