import { en as ENTranslations } from '../i18n/en';
import { i18n } from '../i18n/i18n';

export type ErrorKey =
  `errors.${keyof typeof ENTranslations.translation.errors}`;

export interface ErrorMessage {
  key: keyof typeof ENTranslations.translation.errors;
  vars: {
    [key: string]: number | string;
  };
}

export const getErrorMessagesFromResponse = (err): ErrorMessage[] => {
  if (err.bodyJson?.messages) {
    return err.bodyJson.messages as ErrorMessage[];
  } else {
    return [
      {
        key: 'error',
        vars: {},
      },
    ];
  }
};

export const getErrorMessageByField = (
  field: string,
  errors: ErrorMessage[]
) => {
  if (!errors) {
    return null;
  }
  return errors.find(
    (err) => getFieldFromErrorKey(err.key).toLowerCase() === field.toLowerCase()
  );
};

const getFieldFromErrorKey = (key: string) => {
  return key.split('.')[0];
};

// should only be used programmatically, for ex in sagas. React components should use the useTranslation hook
export const translateErrorMessage = ({ key, vars }: ErrorMessage) => {
  return i18n.t(`errors.${key}`, { ...vars });
};
