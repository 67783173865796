import React from 'react';

import { FacebookSocialPage } from '../../../store/types';
import { BoxInfo } from '../InfluencerOverviewPerformance';

export const OverviewPerformanceFacebook = ({
  followers_count,
  avg_shares_per_post,
  avg_posts_per_day,
  avg_likes_per_post,
  avg_comments_per_post,
}: FacebookSocialPage) => {
  return (
    <>
      <BoxInfo
        label="FOLLOWERS"
        info="Total number of followers."
        stat={followers_count}
      />
      <BoxInfo
        label="AVG. POSTS / DAY"
        info="The average number of posts per day over the past 14 days."
        stat={avg_posts_per_day ? parseFloat(avg_posts_per_day) : null}
      />
      <BoxInfo
        label="AVG. SHARES / POST"
        info="The average number of shares per post over the past 14 days."
        stat={avg_shares_per_post ? parseFloat(avg_shares_per_post) : null}
      />
      <BoxInfo
        label="AVG. CMNTs / POST"
        info="The average number of comments per post over the past 14 days."
        stat={avg_comments_per_post ? parseFloat(avg_comments_per_post) : null}
      />
      <BoxInfo
        label="AVG. LIKES / POST"
        info="The average number of likes per post over the past 14 days."
        stat={avg_likes_per_post ? parseFloat(avg_likes_per_post) : null}
      />
    </>
  );
};
