import { Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';

import useInputValue from '../hooks/use-input-value';
import arrayToggle from '../utils/array-toggle';
import Popover from './Popover';

const useStyles = makeStyles({
  paper: {
    minWidth: 120,
  },
  searchInput: {
    padding: '5px 15px',
    width: '100%',
  },
});

function ClickSelectMultiple(props) {
  const { opener, children, values, onChange, withSearchInput, id } = props;

  const [anchor, setAnchor] = useState(null);
  const searchTerm = useInputValue('');

  function handleClick({ currentTarget }) {
    setAnchor(currentTarget);
  }

  function handleClose() {
    setAnchor(null);
  }

  function handleChange(value) {
    onChange(arrayToggle(values, value));
  }

  function withSearchFilterBasic(items) {
    const { value } = searchTerm;

    if (!value) {
      return items;
    }

    let filteredResult = items.filter(
      ({ label }) =>
        label.toLowerCase().indexOf(searchTerm.value.toLowerCase()) >= 0
    );

    if (!filteredResult.length) {
      filteredResult.push({
        label: 'No Results',
        noResults: true,
      });
    }

    return filteredResult;
  }

  function withSearchFilter(items) {
    const { value } = searchTerm;

    if (!value) {
      return items;
    }

    let filteredResult = items.reduce((acc, { name, items }) => {
      let filteredItems = items.filter(
        (it) => it.toLowerCase().indexOf(searchTerm.value.toLowerCase()) >= 0
      );

      if (filteredItems.length) {
        acc.push({ name, items: filteredItems });
      }

      return acc;
    }, []);

    if (!filteredResult.length) {
      filteredResult.push({
        name: 'No Results',
        noResults: true,
        items: [],
      });
    }

    return filteredResult;
  }

  const boundOpener = React.cloneElement(opener, { onClick: handleClick });

  const classes = useStyles();

  const content = children({
    handleChange,
    withSearchFilter,
    withSearchFilterBasic,
  });

  return (
    <>
      {boundOpener}
      <Popover
        data-id={id}
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        className={classes.paper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {withSearchInput && (
          <Input
            className={classes.searchInput}
            placeholder="Search"
            value={searchTerm.value}
            onChange={searchTerm.handleChange}
          />
        )}
        {content}
      </Popover>
    </>
  );
}

export default ClickSelectMultiple;
