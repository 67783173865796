export default function computeBannerStats({
  bannerDeliverable,
  usedVideosId,
  usedVideos,
}) {
  const { image, raw_banner_image } = bannerDeliverable;

  const pick = (...props) => (o) =>
    props.reduce((a, e) => {
      if (o[e]) {
        return { ...a, [e]: o[e] };
      } else {
        return a;
      }
    }, {});

  let data = pick(...usedVideosId)(bannerDeliverable.data || {}); // slice only values for used video ids

  const totalOccurrences = Object.values(data).reduce(
    (acc, { occurrences }) => (acc += occurrences),
    0
  );
  const inVideosId = Object.keys(data).map((e) => parseInt(e));
  const usedVideosForBannerDeliverable = usedVideos.filter(
    ({ id }) => inVideosId.indexOf(id) >= 0
  );
  const timeOnScreen = Object.values(data).reduce(
    (acc, { duration }) => (acc += duration),
    0
  );
  const avgCCViewers =
    Object.values(data).length > 0
      ? Object.values(data).reduce((acc, { avg_ccv }) => (acc += avg_ccv), 0) /
        Object.values(data).length
      : null;

  const peakCCViewers =
    Object.values(data).length > 0
      ? Object.values(data).reduce(
          (max, { peak_ccv }) => (peak_ccv > max ? peak_ccv : max),
          0
        )
      : null;

  const perOnScreen = usedVideosForBannerDeliverable.length
    ? (timeOnScreen /
        usedVideosForBannerDeliverable.reduce(
          (sum, { duration }) => sum + duration,
          0
        )) *
      100
    : 0;

  const totalEmv =
    Object.values(data).length > 0
      ? Object.values(data).reduce((acc, { emv }) => (acc += emv), 0)
      : null;

  return {
    image,
    raw_banner_image,
    totalOccurrences,
    timeOnScreen,
    avgCCViewers,
    perOnScreen,
    inVideosId,
    usedVideosForBannerDeliverable,
    peakCCViewers,
    totalEmv,
  };
}
