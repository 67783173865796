import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { default as MegaphoneSvg } from '../assets-2.0/megaphone';
import {
  DEALS,
  HIGHLIGHTS,
  INFLUENCERS_STATS,
  PROFILE,
} from '../common/constants/paths';
import { isInfluencerView as getIsInfluencerView } from '../common/containers/Config/selectors';
import { useFetchOnMount } from '../hooks/useFetchOnMount';
import { getUserFeatures } from '../store/models/user/user.selectors';
import handShake from '../views/influencerViews/Deals/svg/handshake.svg';
import handShakeActive from '../views/influencerViews/Deals/svg/handshakeActive.svg';
import {
  selectInfluencerInfo,
  selectShouldFetchInfluencerInfo,
} from '../views/influencerViews/store/InfluencerInfo.selectors';
import { fetchInfluencerInfo } from '../views/influencerViews/store/InfluencerInfo.thunks';
import { AppContext } from './App';
import WatchlistMenuItems from './WatchlistMenuItems';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 20,
    width: 20,
    marginLeft: 10,
    marginRight: 24,
  },
  drawer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: drawerWidth,
    height: '100vh',
    position: 'relative',
    overflowX: 'hidden',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: 56,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerContent: {
    minWidth: drawerWidth,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  menuItemIcon: {
    minWidth: 40,
  },
  iconActive: {
    fill: '#0048F2',
  },
  influencerLinksContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  profileItemContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  listContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const AppDrawer = () => {
  const history = useHistory();

  const influencerInfo = useSelector(selectInfluencerInfo());
  const isInfluencerView = useSelector(getIsInfluencerView);

  const features = useSelector(getUserFeatures);

  const { menuIsOpen } = useContext(AppContext);

  const classes = useStyles();

  useFetchOnMount(selectShouldFetchInfluencerInfo(), fetchInfluencerInfo);

  const renderCompanyLinks = () => {
    return (
      <>
        {(features.brand_insights !== false ||
          features.discovery !== false) && (
          <Link component={RouterLink} underline="none" color="inherit" to="/">
            <ListItem button>
              <ListItemIcon className={classes.menuItemIcon}>
                <ShowChartIcon />
              </ListItemIcon>
              <ListItemText primary="Overview" />
            </ListItem>
          </Link>
        )}
        {features.tracking && (
          <Link
            component={RouterLink}
            underline="none"
            color="inherit"
            to="/campaigns"
          >
            <ListItem button>
              <ListItemIcon className={classes.menuItemIcon}>
                <SvgIcon>
                  <MegaphoneSvg />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Tracking" />
            </ListItem>
          </Link>
        )}
        {features.discovery !== false && (
          <>
            <Link
              component={RouterLink}
              underline="none"
              color="inherit"
              to="/influencer-search"
            >
              <ListItem button>
                <ListItemIcon className={classes.menuItemIcon}>
                  <SearchOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Discovery" />
                <div className="ribbon-beta" style={{ marginRight: 10 }}>
                  {' '}
                  BETA{' '}
                </div>
              </ListItem>
            </Link>
            <WatchlistMenuItems iconClassName={classes.menuItemIcon} />
          </>
        )}
      </>
    );
  };

  const renderInfluencerLinks = () => {
    let profileItemContent = <Skeleton variant="text" width={180} />;

    if (influencerInfo != null) {
      let name = `${influencerInfo.first_name ?? ''} ${
        influencerInfo.last_name ?? ''
      }`.trim();
      let email = influencerInfo.email;

      if (name === '') {
        name = email;
        // don't render email secondary
        email = undefined;
      }

      profileItemContent = (
        <ListItemText
          primary={name}
          secondary={email}
          style={{ overflowWrap: 'break-word' }}
        />
      );
    }

    return (
      <div className={classes.influencerLinksContainer}>
        <Link
          component={RouterLink}
          underline="none"
          color="inherit"
          to={INFLUENCERS_STATS}
        >
          <ListItem button>
            <ListItemIcon className={classes.menuItemIcon}>
              <ShowChartIcon
                className={clsx({
                  [classes.iconActive]:
                    history.location.pathname === INFLUENCERS_STATS,
                })}
              />
            </ListItemIcon>
            <ListItemText primary="Stats" />
          </ListItem>
        </Link>
        {/* <Link
          component={RouterLink}
          underline="none"
          color="inherit"
          to={MESSAGING}
        >
          <ListItem button>
            <ListItemIcon className={classes.menuItemIcon}>
              <MailOutline
                className={clsx({
                  [classes.iconActive]: history.location.pathname === MESSAGING,
                })}
              />
            </ListItemIcon>
            <ListItemText primary="Messaging" />
          </ListItem>
        </Link> */}
        <Link
          component={RouterLink}
          underline="none"
          color="inherit"
          to={HIGHLIGHTS}
        >
          <ListItem button>
            <ListItemIcon className={classes.menuItemIcon}>
              <LiveTvIcon
                className={clsx({
                  [classes.iconActive]:
                    history.location.pathname === HIGHLIGHTS,
                })}
              />
            </ListItemIcon>
            <ListItemText primary="Highlights" />
          </ListItem>
        </Link>
        <Link
          component={RouterLink}
          underline="none"
          color="inherit"
          to={DEALS}
        >
          <ListItem button>
            <ListItemIcon className={classes.menuItemIcon}>
              <img
                alt="handShake"
                src={
                  history.location.pathname === DEALS
                    ? handShakeActive
                    : handShake
                }
              />
            </ListItemIcon>
            <ListItemText primary="Deals" />
          </ListItem>
        </Link>
        <div className={classes.profileItemContainer}>
          <Link
            component={RouterLink}
            underline="none"
            color="inherit"
            to={PROFILE}
          >
            <ListItem button>
              <ListItemIcon className={classes.menuItemIcon}>
                <PersonOutlineOutlinedIcon
                  className={clsx({
                    [classes.iconActive]: history.location.pathname === PROFILE,
                  })}
                />
              </ListItemIcon>
              {profileItemContent}
            </ListItem>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={menuIsOpen}
      className={classes.drawer}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: menuIsOpen,
          [classes.drawerClose]: !menuIsOpen,
        }),
      }}
    >
      <div className={classes.drawerContent}>
        <div className={classes.drawerHeader}>
          <img
            className={classes.logo}
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAdCAYAAABSZrcyAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNS8wNy8xObH1eZsAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAABcklEQVRIieWXz1HCQBSHPxjPiVYAHYg2EPW0RzqgBKECoQK1AikjN0MFYgWgFchW4OHtDuSPZHdZcvE3k8lks3nfe8nmlxcIldIpSo+Crwf6J1y7AAqUHnYLF+AjkALzbuFl4CS0en+40vfApDK67AYOTw1jmUnqjHClx0DmkVREOLwcOZehdPVxRIJL4EHLrEV8uNIp8Oowc4DS07hwmCHvtIvmJtkIcAnkXA2S5CwOXG63a9VWUxfjuXAI9AZsgTtg1JLIGvgE3h3i0nOZVJNUtV/5ebIKivNv5XbbpWm4Aq6ByyMzN8A3sCFPtmFwcbMbxMdP6VZWyCJckifrdriAlycAm/RFngyrg+X33N1GfdVou1WT8bFRX9Vsdw/3t1Ff1Wz3sPIQG/VVyXYFLgNejUCgSt2urfy5A7DVxP5s9E3jN+4QDqYd66F0wd9N4Tn1ICYjK91+Lm/NyaHZrNo+p1ZrYHdwXJj9D/AB7Kzb/QL8/lSe5DLG3wAAAABJRU5ErkJggg=="
            alt="aggero's logo"
          />
          <Typography variant="h6" className={classes.title} color="primary">
            Aggero
          </Typography>
        </div>
        <Divider />
        <List className={clsx('app-drawer-list', classes.listContainer)}>
          {isInfluencerView ? renderInfluencerLinks() : renderCompanyLinks()}
        </List>
      </div>
    </Drawer>
  );
};

export default AppDrawer;
