import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ParentSize } from '@visx/responsive';
import _sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';

import VideoPreview from '../components/VideoPreview';
import {
  useBannerData,
  useCCVData,
  useChatMentionDeliverablesByVideoId,
  useShoutoutDeliverablesV2ByVideoId,
} from '../hooks/fetchData';
import { Campaign, CampaignInfluencer, Video } from '../types';
import Chart from './Chart';

interface DeliverablesChartProps {
  duration: number;
  videoId: number;
  publishedAt: string;
  campaign: Campaign | null;
  platform?: 'twitch' | 'youtube' | 'facebook' | 'tiktok' | null;
}

const DeliverablesChart = ({
  duration,
  videoId,
  publishedAt,
  campaign = null,
  platform = null,
}: DeliverablesChartProps) => {
  const { bannerData, bannerDataLoading } = useBannerData({
    campaign,
    videoId,
  });

  const { ccvDataLoading, ccvData } = useCCVData({
    videoId,
    campaignId: campaign?.id,
    platform,
  });

  const { isLoading: chatMentionsLoading, data: chatMentionsData } =
    useChatMentionDeliverablesByVideoId(campaign, videoId);

  const { isLoading: shoutoutsLoading, data: shoutoutsData } =
    useShoutoutDeliverablesV2ByVideoId(campaign, videoId);

  const chatMentions = useMemo(() => {
    if (!chatMentionsData) {
      return [];
    }

    return chatMentionsData.keyword_mention_deliverables.filter((d) =>
      d.video_comments.some((v) => v.video_id === videoId)
    );
  }, [videoId, chatMentionsData]);

  const totalCCV = useMemo(() => _sumBy(ccvData, 'count') ?? 0, [ccvData]);

  const loading =
    bannerDataLoading ||
    ccvDataLoading ||
    shoutoutsLoading ||
    chatMentionsLoading;

  if (loading)
    return (
      <Box
        height={128}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  if (campaign === null && totalCCV === 0) {
    return (
      <Box
        height={128}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>No data available for this video.</Typography>
      </Box>
    );
  }

  return (
    <div>
      <ParentSize>
        {(parent) => (
          <Chart
            ccvData={ccvData}
            shoutouts={shoutoutsData.shoutout_deliverables}
            startTime={publishedAt}
            chatMentions={chatMentions}
            duration={duration}
            bannerDeliverables={bannerData}
            width={parent.width}
            videoId={videoId}
          />
        )}
      </ParentSize>
    </div>
  );
};

interface ModalProps {
  onClose: () => void;
  videoId: number;
  campaign: Campaign | null;
  video: Video;
  influencer: CampaignInfluencer;
}

export const Modal = ({
  onClose,
  videoId,
  campaign,
  video,
  influencer,
}: ModalProps) => {
  const open = !!videoId;

  const { duration, published_at: publishedAt } = video;

  if (!open) return null;
  const renderContent = () => {
    if (!open) return null;

    return (
      <DialogContent>
        <VideoPreview
          video={video}
          influencer={influencer}
          timeZone={campaign?.time_zone}
        />
        <DeliverablesChart
          {...{
            duration,
            videoId,
            publishedAt,
            campaign,
            platform: video?.resource,
          }}
        />
      </DialogContent>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle style={{ position: 'relative' }} disableTypography>
        <Typography variant="h6">In-stream media performance</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {renderContent()}
    </Dialog>
  );
};

export default Modal;
