import React from 'react';

import MissingData from '../components/MissingData';

function withDataMissing(Component) {
  return function (props) {
    const { available, communication, name, ...rest } = props;

    if (!available)
      return <MissingData communication={communication} name={name} />;

    return <Component {...rest} />;
  };
}

export default withDataMissing;
