import { makeStyles, Typography } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import aggeroLogo from '../../assets-2.0/aggeroLogo.png';
import useEnableFacebook from '../../hooks/enable-facebook';
import { showToast } from '../../store/commonActions';
import { PlatformAccountCard } from './PlatformAccountCard/PlatformAccountCard';
import { INFLUENCER_TOKEN_KEY } from './platformsLogin';
import {
  useAddFacebookPageMutation,
  useAddPageMutation,
  useDisconnectPageMutation,
  useGetPagesQuery,
} from './store/api';
import { getPlatforms } from './store/utils';
import { usePlatformsURLDecoder } from './usePlatformsURLDecoder';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: 91,
    paddingBottom: 36,
    paddingLeft: 86,
    maxWidth: 840,

    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },

  platformsContainer: {
    display: 'flex',
    marginLeft: 32,
  },

  learnMoreText: {
    fontSize: 12,
    color: '#8c8c8c',
  },

  dash: {
    width: 17,
    height: 1,
    marginLeft: 5,
    marginRight: 5,
    background: '#a5a5a5',
  },

  splitTextContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  learnMoreCTA: {
    fontSize: 14,
  },

  helpCTA: {
    color: '#357cff',
    cursor: 'pointer',
  },

  aggeroLogo: {
    height: 35,
    marginBottom: 60,
  },

  textList: {
    marginTop: 21,
    marginBottom: 29,
  },

  topText: {
    marginBottom: 30,
  },

  platformsGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '80px 50px',
    marginTop: 66,
    alignItems: 'center',
    justifyItems: 'center',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gap: 25,
    },
  },
}));

export const CampaignInfluencerOnboarding = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { influencerToken } =
    useParams<{ influencerToken?: string; platform?: string }>();

  const platformConfig = usePlatformsURLDecoder();

  const [addPage] = useAddPageMutation();
  const [addFacebookPage] = useAddFacebookPageMutation();
  const [disconnectPage] = useDisconnectPageMutation();

  const token = useMemo(() => {
    return influencerToken
      ? influencerToken
      : localStorage.getItem(INFLUENCER_TOKEN_KEY);
  }, [influencerToken]);

  const { data, isLoading } = useGetPagesQuery(token);

  const onDisconnectPage = ({ influencer_token, network, page_id }) => {
    disconnectPage({ influencer_token, network, page_id })
      .unwrap()
      .then((res: any) => {
        if (res.disconnected) {
          dispatch(
            showToast({
              message: `Your ${network} page has been disconnected successfully`,
              type: 'success',
            })
          );
        }
      })
      .catch((errRes) => {
        dispatch(
          showToast({
            message: `There was an error while trying to disconnect your ${network} page`,
            type: 'error',
          })
        );
      });
  };

  const onAddFacebookPage = ({
    accessToken,
    userID,
    pageName,
    onboardingNetwork,
    influencer_token,
  }) => {
    addFacebookPage({
      accessToken,
      userID,
      pageName,
      onboardingNetwork,
      influencer_token,
    })
      .unwrap()
      .then((res: any) => {
        if (res.connected) {
          dispatch(
            showToast({
              message: `Your ${onboardingNetwork} page has been connected successfully`,
              type: 'success',
            })
          );
        }
      })
      .catch((errRes) => {
        let errorMsg =
          (errRes.data && errRes.data.errors && errRes.data.errors[0]) ||
          `There was an error while trying to connect your ${onboardingNetwork} page`;

        dispatch(
          showToast({
            message: errorMsg,
            type: 'error',
          })
        );
      });
  };

  useEnableFacebook();

  // Store the influencer token key in localStorage since it will be lost on platform connection redirects
  useEffect(() => {
    if (influencerToken) {
      localStorage.setItem(INFLUENCER_TOKEN_KEY, influencerToken);
    }
  }, [influencerToken]);

  // Handle the add platform callback
  useEffect(() => {
    const { platform } = platformConfig;

    if (!platform || platform === 'facebook' || platform === 'instagram') {
      return;
    }

    addPage({
      ...platformConfig,
      influencer_token: token,
    })
      .unwrap()
      .then((res) => {
        dispatch(
          showToast({
            message: `Your ${platform} page has been connected successfully`,
            type: 'success',
          })
        );
      })
      .catch((errRes) => {
        dispatch(
          showToast({
            message:
              errRes.data?.errors[0] ||
              `There was an error while trying to connect your ${platform} page`,
            type: 'error',
          })
        );
      });
    // If the platforming config is added to the deps list it will keep on triggering this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const platforms =
    !!data && !isLoading
      ? getPlatforms({
          pages: data,
          influencerToken: token,
          facebookCallback: onAddFacebookPage,
          disconnectPage: onDisconnectPage,
        })
      : [];

  // TODO: Add a better loading state, maybe some skeletons for the platforms cards
  if (isLoading) {
    return null;
  }

  return (
    <div className={classes.pageContainer}>
      <img className={classes.aggeroLogo} src={aggeroLogo} alt="aggero logo" />
      <Typography className={classes.topText}>
        Please select your social media account so we can measure the impact of
        your content.
      </Typography>
      <Typography>
        Connect your social media account(s) so we can measure the impact of
        your content and monitor the campaign on behalf of your marketing
        partner.
      </Typography>

      <ul className={classes.textList}>
        <li>
          <Typography>
            Aggero <strong>does not</strong> ask for the ability to post on your
            accounts. We <strong>cannot</strong> post on your behalf.
          </Typography>
        </li>
        <li>
          <Typography>
            Aggero will <strong>never</strong> modify your accounts, follow
            other accounts, or like or comment on posts.
          </Typography>
        </li>
        <li>
          <Typography>
            Aggero respects your privacy and the security of your social
            accounts. We're here to make things easier for you and your partners
            to work together.
          </Typography>
        </li>
      </ul>

      <Typography className={classes.learnMoreText}>
        Learn more{' '}
        <a
          className={classes.learnMoreCTA}
          href="http://help.aggero.io/en/articles/5977586-connect-your-social-accounts"
          target="_blank"
          rel="noopener noreferrer"
        >
          &#187;
        </a>
      </Typography>

      <div className={classes.platformsContainer}>
        <div className={classes.platformsGridContainer}>
          {platforms.map((platform) => {
            return (
              <PlatformAccountCard key={platform.key} platform={platform} />
            );
          })}
        </div>
      </div>
    </div>
  );
};
