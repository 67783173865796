import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
    padding: '14.5px 14px',
    // background: theme.palette.background.default,
  },
}));

export default function (props) {
  const { input } = useStyles();

  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        classes: { input },
      }}
    />
  );
}
