import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  FacebookOnboarding,
  Forgot,
  ResetPassword,
  Signin,
  SigninCallback,
  TwitterOnboarding,
} from '../common/components';
import InfluencerLoginPage from '../common/components/InfluencerLogin';
import RouteChangeTracker from '../common/components/RouteChangeTracker';
import * as paths from '../common/constants/paths';
import { isInfluencerView } from '../common/containers/Config/selectors';
import useRouteChange from '../hooks/use-route-change';
import { useDidTokenAutoLogin } from '../hooks/useDidTokenAutoLogin';
import { useIsIframe } from '../hooks/useIsIframe';
import queryClient from '../queryClient';
import { appMounted } from '../store/events';
import {
  getIsViewOnly,
  getUserEmail,
  getUserFeatures,
  isAggeroUser,
  isUserLoaded,
} from '../store/models/user/user.selectors';
import { selectors } from '../store/selectors';
import { useLogRocket } from '../utils/useLogRocket';
import AddToWatchlist from '../views/AddToWatchlist';
import Campaign from '../views/Campaign';
import CampaignEdit from '../views/CampaignEdit';
import { CampaignInfluencerOnboarding } from '../views/CampaignInfluencerOnboarding/CampaignInfluencerOnboarding';
import Campaigns from '../views/Campaigns';
import CampaignWizard from '../views/CampaignWizard';
import Influencer from '../views/Influencer';
import Influencers from '../views/Influencers';
import InfluencerSearch from '../views/InfluencerSearch';
import { Deals } from '../views/influencerViews/Deals/Deals';
import { EmailChangeConfirmation } from '../views/influencerViews/EmailChangeConfirmation/EmailChangeConfirmation';
import { Highlights } from '../views/influencerViews/Highlights/Highlights';
import { InfluencerSignin } from '../views/influencerViews/InfluencerSignin/InfluencerSignin';
import { InfluencerSignupWizard } from '../views/influencerViews/InfluencerSignupWizard/InfluencerSignupWizard';
import { Profile } from '../views/influencerViews/Profile/Profile';
import { Stats } from '../views/influencerViews/Stats/Stats';
import { LoginViaToken } from '../views/LoginViaToken/LoginViaToken';
import MyAggeroMain from '../views/MyAggero/MyAggeroMain';
import MyBrandsMain from '../views/MyBrands/MyBrandsMain';
import MyWatchlist from '../views/MyWatchlist';
import RootPath from '../views/RootPath';
import AppBar from './AppBar';
import AppDrawer from './AppDrawer';
import { OopsPage } from './OopsPage';

const useStyles = makeStyles({
  fullViewportHeight: {
    height: '100vh',
  },
  flexGrow: {
    flexGrow: 1,
  },
  mainGridItem: {
    position: 'relative',
    maxHeight: 'calc(100vh - 64px)',
    height: '100%',
  },
});

export const AppContext = React.createContext({});

const Authenticated = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const userLoaded = useSelector(isUserLoaded);
  const features = useSelector(getUserFeatures);
  const isInfluencer = useSelector(isInfluencerView);
  const userEmail = useSelector(getUserEmail);
  const aggeroUser = useSelector(isAggeroUser);
  const isLoggedOut = useSelector(selectors.wasLoggedOut);

  const isViewOnly = useSelector(getIsViewOnly);
  const isIframe = useIsIframe();

  const Redirects = () => (
    <Switch>
      {!isInfluencer &&
        (features.brand_insights !== false || features.discovery !== false) && (
          <Redirect to={paths.PATH_ROOT} />
        )}
      {!isInfluencer && features.tracking === true && (
        <Redirect to={paths.CAMPAIGNS} />
      )}
      {isInfluencer && <Redirect to={paths.INFLUENCERS_STATS} />}
    </Switch>
  );

  useLogRocket();

  useEffect(() => {
    if (!aggeroUser) {
      if (process.env.REACT_APP_TRACKING_ID) {
        ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
        ReactGA.set({ userId: userEmail });
      } else {
        throw new Error('Missing REACT_APP_TRACKING_ID');
      }
    }

    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
    dispatch(appMounted());
  }, [aggeroUser, dispatch, userEmail]);

  useDidTokenAutoLogin({ isLoggedOut });

  if (!userLoaded) {
    return null;
  }

  return (
    <Grid
      container
      wrap="nowrap"
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      {!isIframe && (
        <Grid item className={classes.fullViewportHeight}>
          <AppDrawer />
        </Grid>
      )}
      <Grid item style={{ overflow: 'hidden' }} className={classes.flexGrow}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.fullViewportHeight}
        >
          {!isIframe && (
            <Grid item>
              <AppBar />
            </Grid>
          )}
          <Grid item className={clsx(classes.flexGrow, classes.mainGridItem)}>
            <Switch>
              {!isInfluencer &&
                (features.brand_insights !== false ||
                  features.discovery !== false) && (
                  <Route exact path={paths.PATH_ROOT} component={RootPath} />
                )}
              {!isInfluencer && features.discovery !== false && (
                <Route
                  path={paths.INFLUENCER_SEARCH}
                  component={InfluencerSearch}
                />
              )}
              {!isInfluencer && features.discovery !== false && (
                <Route path={paths.INFLUENCERS} component={Influencers} />
              )}
              {isInfluencer && (
                <Route path={paths.PROFILE} component={Profile} />
              )}
              {isInfluencer && (
                <Route path={paths.HIGHLIGHTS} component={Highlights} />
              )}
              {isInfluencer && <Route path={paths.DEALS} component={Deals} />}
              {isInfluencer && (
                <Route
                  path={paths.MESSAGING}
                  component={() => <h1>Messaging page placeholder</h1>}
                />
              )}
              {isInfluencer && (
                <Route path={paths.INFLUENCERS_STATS} component={Stats} exact />
              )}
              {isInfluencer && (
                <Route
                  path={`${paths.INFLUENCERS_STATS}/:tab`}
                  component={Stats}
                />
              )}
              {!isInfluencer && (
                <Route path={paths.MY_BRANDS} component={MyBrandsMain} />
              )}
              {!isInfluencer && (
                <Route
                  path={`${paths.MY_AGGERO}/:tab`}
                  component={MyAggeroMain}
                />
              )}
              {features.discovery !== false && (
                <Route
                  path={`${paths.INFLUENCER}/:influencerId/:influencerNetwork`}
                  component={Influencer}
                />
              )}
              {!isInfluencer && features.discovery !== false && (
                <Route
                  path={`${paths.ADD_TO_WATCHLIST}/:watchlistId`}
                  component={AddToWatchlist}
                  exact
                />
              )}
              {!isInfluencer && features.discovery !== false && (
                <Route
                  path={`${paths.ADD_TO_WATCHLIST}/:watchlistId/:network`}
                  component={AddToWatchlist}
                  exact
                />
              )}
              {!isInfluencer && features.discovery !== false && (
                <Route
                  path={`${paths.MY_WATCHLIST}/:watchlistId/:network`}
                  component={MyWatchlist}
                  exact
                />
              )}
              {!isInfluencer && features.discovery !== false && (
                <Route
                  path={`${paths.MY_WATCHLIST}/:watchlistId`}
                  component={MyWatchlist}
                />
              )}
              {!isInfluencer && features.tracking === true && (
                <Route exact path={paths.CAMPAIGNS} component={Campaigns} />
              )}
              {!isInfluencer && features.tracking === true && (
                <Route
                  path={`${paths.CAMPAIGNS}/:campaignId/edit`}
                  component={isViewOnly ? Redirects : CampaignEdit}
                />
              )}
              {!isInfluencer && features.tracking === true && (
                <Route
                  path={`${paths.CAMPAIGNS}/:campaignId/:tab`}
                  component={Campaign}
                />
              )}
              {!isInfluencer && features.tracking === true && (
                <Route
                  path={`${paths.CAMPAIGNS}/:campaignId`}
                  component={Campaign}
                />
              )}
              {!isInfluencer && features.tracking === true && !isViewOnly && (
                <Route
                  path={`${paths.TRACK_CAMPAIGN}/:step`}
                  component={CampaignWizard}
                  exact
                />
              )}
              {
                // You should be redirected to what you have permissions for.
                // E.g: You are on /campaigns but your permissions changed and you can only
                // access BI now. Since no previous route will match, we need to redirect to PATH_ROOT
              }
              <Redirects />
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const onlyHasTrackingFeature = (features) => {
  return (
    features.brand_insights === false &&
    features.discovery === false &&
    features.tracking === true
  );
};

const App = () => {
  const classes = useStyles();
  const features = useSelector(getUserFeatures);
  const [menuIsOpen, setMenuOpen] = useState(!onlyHasTrackingFeature(features));

  useRouteChange(() => window.scrollTo(0, 0));

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  function toggleMenu() {
    setMenuOpen(!menuIsOpen);
  }

  const context = {
    menuIsOpen,
    openMenu,
    closeMenu,
    toggleMenu,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={context}>
        <Switch>
          <Route path={paths.PATH_LOGIN_VIA_TOKEN} component={LoginViaToken} />
          <Route
            path={paths.INFLUENCER_EMAIL_CHANGE_CONFIRMATION}
            component={EmailChangeConfirmation}
          />
          <Route
            path={`${paths.PATH_SIGNIN_CALLBACK_ROOT}/:provider`}
            component={SigninCallback}
          />
          <Route
            path={`${paths.INFLUENCERS_SIGNUP}/:step`}
            component={InfluencerSignupWizard}
          />
          <Route
            path={paths.INFLUENCERS_SIGNUP}
            component={InfluencerSignupWizard}
          />
          <Route path={paths.INFLUENCERS_SIGNIN} component={InfluencerSignin} />
          <Route
            path={paths.INFLUENCERS_LEGACY_LOGIN}
            component={InfluencerLoginPage}
          />
          {/*Only preview*/}
          <Route
            path={paths.INFLUENCERS_LOGIN_CREATOR}
            render={() => (
              <Grid
                container
                wrap="nowrap"
                style={{ position: 'relative', overflow: 'hidden' }}
              >
                <Grid item className={classes.fullViewportHeight}>
                  <AppDrawer />
                </Grid>
                <Grid
                  item
                  style={{ overflow: 'hidden' }}
                  className={classes.flexGrow}
                >
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    className={classes.fullViewportHeight}
                  >
                    <Grid item>
                      <AppBar />
                    </Grid>
                    <Grid
                      item
                      className={clsx(classes.flexGrow, classes.mainGridItem)}
                    >
                      {' '}
                      <Profile />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          />
          <Route
            path={`${paths.ONBOARDING_FACEBOOK}/:pageName`}
            component={FacebookOnboarding}
          />
          <Route
            path={`${paths.ONBOARDING_INSTAGRAM}/:pageName`}
            component={FacebookOnboarding}
          />
          <Route
            path={`${paths.ONBOARDING_TWITTER}/success`}
            component={TwitterOnboarding}
          />
          <Route
            path={`${paths.ONBOARDING_TWITTER}/:pageName`}
            component={TwitterOnboarding}
          />
          <Route path={paths.PATH_SIGNIN} component={Signin} />
          {/*<Route
          path={paths.PATH_SIGNUP}
          component={Signup}
        />*/}
          <Route path={paths.PATH_FORGOT} component={Forgot} />
          <Route path={paths.PATH_RESET_PASSWORD} component={ResetPassword} />

          <Route
            path={'/onboarding/callback/:platform'}
            component={CampaignInfluencerOnboarding}
          />
          <Route
            path={'/onboarding/:influencerToken'}
            component={CampaignInfluencerOnboarding}
          />
          <Route path={paths.OOPS} component={OopsPage} />
          <Route component={Authenticated} />
        </Switch>
      </AppContext.Provider>
      <RouteChangeTracker />
    </QueryClientProvider>
  );
};

export default App;
