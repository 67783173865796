import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { connect } from 'react-redux';

import AgeGroups from '../../../components/AgeGroups';
import CardTitle from '../../../components/CardTitle';
import GenderDistribution from '../../../components/GenderDistribution';
import Languages from '../../../components/Languages';
import SectionTitle from '../../../components/SectionTitle';
import countries from '../../../utils/country-list-audience';
import languageIso from '../../../utils/language-iso';
import {
  getAudienceLocations,
  getAudienceMembersByLanguage,
  getGenderDistribution,
  getStreamerAudienceAges,
} from '../selectors';
import AudienceChoropleth from './AudienceChoropleth';

const mapStateToProps = (state) => {
  let genderDistribution = [];
  let ageDistribution = [];
  const audienceMembersByLanguage = getAudienceMembersByLanguage(state);
  const audienceLocations = getAudienceLocations(state);

  genderDistribution = getGenderDistribution(state);
  ageDistribution = getStreamerAudienceAges(state);

  return {
    genderDistribution,
    ageDistribution,
    audienceMembersByLanguage,
    audienceLocations,
  };
};

const enhance = connect(mapStateToProps);

const InfluencerAudienceDemographics = (props) => {
  const {
    audienceMembersByLanguage,
    genderDistribution,
    ageDistribution,
    audienceLocations,
  } = props;

  function renderAudienceTopLanguages() {
    const audienceMembersByLanguageData = Object.keys(audienceMembersByLanguage)
      .filter((language) => language.length === 2)
      .map((language) => ({
        language: languageIso[language],
        percentage: audienceMembersByLanguage[language],
      }))
      .sort(
        (languageDataOne, languageDataTwo) =>
          languageDataTwo.percentage - languageDataOne.percentage
      )
      .splice(0, 7);

    return <Languages data={audienceMembersByLanguageData} />;
  }

  function renderAudienceLocations() {
    const countriesObject = countries.reduce((acc, { name, code }) => {
      acc[code] = name;
      return acc;
    }, {});
    countriesObject.Other = 'Other';

    return <AudienceChoropleth topCountries={audienceLocations} />;
  }

  const genderDistributionExists =
    genderDistribution &&
    genderDistribution.reduce((acc, { members }) => acc + members, 0) > 0;

  const ageDistributionExists =
    ageDistribution &&
    Object.values(ageDistribution).reduce((a, b) => a + b, 0) > 0;

  const topLanguagesExists = Object.keys(audienceMembersByLanguage).length > 0;
  const locationsExists =
    audienceLocations && Object.keys(audienceLocations).length > 0;

  if (
    !genderDistributionExists &&
    !topLanguagesExists &&
    !ageDistributionExists &&
    !locationsExists
  )
    return null;

  return (
    <>
      <Grid item>
        <SectionTitle>Audience Demographics</SectionTitle>
      </Grid>

      <Grid item>
        <Grid container spacing={3} alignItems="stretch">
          {locationsExists && (
            <Grid item xs={12} sm={6}>
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <CardContent>
                  <CardTitle>Top Locations</CardTitle>
                </CardContent>
                <CardContent
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {renderAudienceLocations()}
                </CardContent>
              </Card>
            </Grid>
          )}

          {ageDistributionExists && (
            <Grid item xs={12} sm={6} style={{ minHeight: '100%' }}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <CardTitle>Age Groups</CardTitle>
                </CardContent>
                <CardContent>
                  <Box textAlign="center">
                    <AgeGroups data={ageDistribution} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}

          {genderDistributionExists && (
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <CardTitle>Gender Distribution</CardTitle>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Box textAlign="center">
                    <GenderDistribution data={genderDistribution} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}

          {topLanguagesExists && (
            <Grid item xs={12} sm={6}>
              <Card style={{ height: '100%' }}>
                <Grid container direction="column" style={{ height: '100%' }}>
                  <Grid item>
                    <CardContent>
                      <CardTitle>Top Languages</CardTitle>
                    </CardContent>
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    <CardContent style={{ height: '100%' }}>
                      {renderAudienceTopLanguages()}
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default enhance(InfluencerAudienceDemographics);
