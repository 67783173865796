import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

import Table, { StatBox, TableText } from '../Table';
import SectionContainer from './SectionContainer';
import { SectionTitle } from './shared';

const TimeOnScren = ({ timeOnScreen }) => {
  const matches = useMediaQuery('(min-width: 1536px)');

  if (matches)
    return (
      <StatBox
        format={null}
        stat={moment
          .duration(timeOnScreen, 'seconds')
          .format('HH[h] : mm[m] : ss[s]')}
      />
    );

  return <StatBox stat={timeOnScreen / 3600} suffix="h" />;
};

const getCols = () =>
  [
    {
      label: 'Brand',
      key: 'brand_name',
      render: (data) => <TableText>{data.brand_name}</TableText>,
      stretch: true,
    },
    {
      label: 'Creators',
      key: 'creators',
      render: (data) => <StatBox stat={data.creators}></StatBox>,
      stretch: true,
    },
    {
      label: 'Videos',
      key: 'Videos',
      render: (data) => <StatBox stat={data.streams} />,
      stretch: true,
    },
    {
      label: 'Voice Mentions',
      key: 'shoutouts_count',
      render: (data) => <StatBox stat={data.shoutouts_count} />,
      stretch: true,
    },
    {
      label: 'Logo on-screen',
      key: 'logo_duration',
      render: (data) => <TimeOnScren timeOnScreen={data.logo_duration} />,
      stretch: true,
    },
    {
      label: 'Chat Mentions',
      key: 'chat_comments_count',
      render: (data) => <StatBox stat={data.chat_comments_count} />,
      stretch: true,
    },
    {
      label: 'Social Mentions',
      key: 'social_media_count',
      render: (data) => <StatBox stat={data.social_media_count} />,
      stretch: true,
    },
    {
      label: 'Impressions',
      key: 'impressions',
      render: (data) => <StatBox stat={data.impressions} />,
      stretch: true,
    },
    {
      label: 'Media Value',
      key: 'emv',
      render: (data) => (
        <StatBox stat={data.emv} format="0,[0].[0]a" prefix="$" />
      ),
      stretch: true,
    },
  ].filter((c) => c !== null);

const getRows = (campaign) => {
  const { overview } = campaign;

  return Object.values(overview.sponsorship_valuation);
};

export const SponsorshipValuation = ({ campaign, onViewMore }) => {
  const rows = useMemo(() => getRows(campaign), [campaign]);
  const onRowClick = useCallback(({ key }) => onViewMore(key), [onViewMore]);
  const idTable = 'sponsorship-valuation';

  return (
    <SectionContainer>
      <SectionTitle onViewMore={onViewMore}>Brand Tracking</SectionTitle>
      <Table
        noBorders={true}
        cols={getCols()}
        rows={rows}
        rowHeight={96}
        defaultSortCol="mentions"
        onRowClick={onRowClick}
        idTable={idTable}
      />
    </SectionContainer>
  );
};

export default SponsorshipValuation;
