import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { connect, useSelector } from 'react-redux';

import { default as MegaphoneSvg } from '../../assets-2.0/megaphone';
import StatBox from '../../components/StatBox';
import mapSelectorsToProps from '../../helpers/map-selectors-to-props';
import { getCampaignTab } from '../../store/models/campaign-tab/campaign-tab.selectors';
import { selectCampaignById } from '../../store/models/campaigns/campaigns.selectors';
import CampaignCardInfluencer from './CampaignCardInfluencer';

const useCampaignCardStyles = makeStyles((theme) => ({
  maxParentWidth: {
    maxWidth: '100%',
  },
  textOverflowEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  campaignActive: {
    background: theme.palette.primary.main,
    position: 'absolute',
    height: '100%',
    width: 8,
  },
  overflowingInfluencersCaption: {
    marginLeft: '0.5rem',
    alignSelf: 'center',
  },
}));

const enhance = connect((state, { campaignId }) => {
  const selectorsToProps = {
    campaign: selectCampaignById(campaignId),
  };

  return mapSelectorsToProps(selectorsToProps)(state);
});

const MAXIMUM_INFLUENCERS_TO_SHOW = 15;

function CampaignCard(props) {
  const { campaignId } = props;
  const campaignTab = useSelector(getCampaignTab);
  const campaign = useSelector(selectCampaignById(campaignId));
  const { overview } = campaign;
  const end_date =
    campaign &&
    campaign.campaign_days &&
    campaign.campaign_days.length &&
    moment(
      campaign.campaign_days.sort()[campaign.campaign_days.length - 1]
    ).format('MMM D, YYYY');
  const start_date =
    campaign &&
    campaign.campaign_days &&
    campaign.campaign_days.length &&
    moment(campaign.campaign_days.sort()[0]).format('MMM D, YYYY');
  const {
    stream,
    social_media_performance: { posts },
  } = overview;

  const campaignIsActive = moment().isBefore(
    moment(
      campaign.campaign_days.sort()[campaign.campaign_days.length - 1]
    ).endOf('day')
  );

  // const emv =
  //   _sumBy(
  //     Object.values(overview).reduce((p, c) => {
  //       if (Array.isArray(c)) return p.concat(c);
  //       return p;
  //     }, []),
  //     (d) => d.emv || 0
  //   ) + stream.total_emv;

  const streams = stream.count;

  const classes = useCampaignCardStyles();

  const overflowingInfluencersCount =
    campaign.campaign_influencers.length - MAXIMUM_INFLUENCERS_TO_SHOW;

  return (
    <Card style={{ position: 'relative' }}>
      {campaignTab === 'all' && (
        <div
          className={classes.campaignActive}
          style={{
            background: !campaignIsActive && '#00000070',
          }}
        />
      )}
      <Grid container alignItems="center" justify="space-between">
        <Grid item sm={5} xs={6}>
          <CardHeader
            avatar={
              <Avatar style={{ background: '#e0d3ed' }}>
                <MegaphoneSvg />
              </Avatar>
            }
            title={campaign.name}
            titleTypographyProps={{
              variant: 'h5',
              className: classes.textOverflowEllipsis,
            }}
            classes={{
              content: clsx(
                classes.maxParentWidth,
                classes.textOverflowEllipsis
              ),
            }}
            subheader={
              <>
                <Box mr={2} display="inline">
                  <Box mr={0.5} clone>
                    <Typography
                      display="inline"
                      variant="caption"
                      color="textSecondary"
                    >
                      Started:
                    </Typography>
                  </Box>
                  <Typography display="inline" color="textPrimary">
                    {start_date}
                  </Typography>
                </Box>
                <Box mr={0.5} clone>
                  <Typography
                    display="inline"
                    variant="caption"
                    color="textSecondary"
                  >
                    Ending:
                  </Typography>
                </Box>
                <Typography display="inline" color="textPrimary">
                  {end_date}
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item sm={4} style={{ flexGrow: 1 }}>
          <CardContent style={{ display: 'flex', flexWrap: 'wrap' }}>
            {campaign.campaign_influencers
              .slice(0, MAXIMUM_INFLUENCERS_TO_SHOW)
              .map(({ id, avatar, name }) => (
                <CampaignCardInfluencer
                  key={id}
                  nameAsTooltip={campaign.campaign_influencers.length > 1}
                  avatarLink={avatar}
                  influencerName={name}
                />
              ))}
            {overflowingInfluencersCount > 0 && (
              <Typography
                variant="caption"
                display="inline"
                className={classes.overflowingInfluencersCaption}
              >
                and {overflowingInfluencersCount} other{' '}
                {overflowingInfluencersCount > 1 ? 'creators' : 'creator'}
              </Typography>
            )}
          </CardContent>
        </Grid>
        <Grid item sm={3}>
          <CardContent>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {!!streams && (
                <Box pr={4}>
                  <StatBox stat={streams} label="VIDEOS" />
                </Box>
              )}
              {!!posts && (
                <Box pr={4}>
                  <StatBox stat={posts} label="POSTS" />
                </Box>
              )}
              {/* {!!emv && (
                <Box pr={4}>
                  <StatBox
                    stat={emv}
                    format="0,[0].[0]a"
                    prefix="$"
                    label="MEDIA VALUE"
                  />
                </Box>
              )} */}
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}

export default enhance(CampaignCard);
