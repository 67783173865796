import qs from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

import { Pages } from './store/types';

export const usePlatformsURLDecoder = () => {
  const { hash, search } = useLocation();

  const { platform } =
    useParams<{ influencerToken?: string; platform?: keyof Pages }>();

  if (!platform) {
    return {};
  }

  const toParse = !!hash ? hash.replace('#', '') : search;
  const query = qs.parse(toParse);

  const target = qs.parse(search)['t'];

  const accessToken = query['access_token'];
  const accessCode = query['code'];
  const oauthToken = query['oauth_token'];
  const oauthVerifier = query['oauth_verifier'];
  const code = query['code'];
  const scopes = query['scopes'];
  const state = query['state'];

  return {
    platform,
    target,
    accessToken,
    accessCode,
    oauthToken,
    oauthVerifier,
    code,
    scopes,
    state,
  };
};
