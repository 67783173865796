import { makeStyles } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import React from 'react';

// import XLSX from 'xlsx';
import * as paths from '../../../common/constants/paths';
import CustomizedMenus from '../../../components/CustomizedMenus';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const getTwitchData = (influencers, network) => {
  const header = [
    'Creator',
    'Email',
    'Growth Score',
    'Followers',
    'View rate',
    'Avg. CCV (30d)',
    'Stream Time (30d)',
    'AMA (30d)',
    'Views (30d)',
    'Link',
    'Twitch Link',
  ];

  const body = influencers.map((i) => [
    i.display_name || i.login,
    i.email,
    i.growth_score,
    i.followers,
    i.view_rate_30,
    i.avg_viewers_days_30,
    i.stream_time_days_30 && `${i.stream_time_days_30}h`,
    i.ama_days_30,
    i.views_days_30,
    `https://app.aggero.io${paths.INFLUENCER}/${encodeURI(i.login)}/${network}`,
    `https://twitch.tv/${i.login}`,
  ]);

  return [header, ...body];
};

const getYoutubeData = (influencers, network) => {
  const header = [
    'Creator',
    'Email',
    'Subscribers',
    'Likes (90d)',
    'Dislikes (90d)',
    'Like / Dislike (90d)',
    'Views (90d)',
    'Engagement Rate (90d)',
    'Views / Video (90d)',
    'Video',
    'Link',
    'Youtube Link',
  ];

  const body = influencers.map((i) => [
    i.display_name,
    i.email,
    i.subscriber_count,
    i.likes_90,
    i.dislikes_90,
    i.like_dislike,
    i.view_count_90,
    i.yt_engagement_90,
    i.avg_view_count_per_video,
    i.video_count,
    `https://app.aggero.io${paths.INFLUENCER}/${encodeURI(
      i.display_name
    )}/${network}`,
    i.youtube_url,
  ]);

  return [header, ...body];
};

const triggerDownload = (influencers, network, watchlistName) => (key) => {
  const data =
    network === 'twitch'
      ? getTwitchData(influencers, network)
      : getYoutubeData(influencers, network);

  import('xlsx')
    .then((XLSX) => {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);

      XLSX.utils.book_append_sheet(wb, ws, 'Content Creators');
      XLSX.writeFile(
        wb,
        `Aggero-Watchlist-${watchlistName}-${new Date()
          .toLocaleString()
          .replace(',', '')}.${key}`
      );
    })
    .catch((error) => console.error('XLSX', error));
};

const DownloadInfluencers = ({ influencers, network, watchlistName }) => {
  const classes = useStyles();

  return (
    <CustomizedMenus
      buttonText={'Download'}
      buttonIcon={<GetAppIcon className={classes.icon} />}
      variant="outlined"
      onClick={triggerDownload(influencers, network, watchlistName)}
      options={[
        {
          text: 'Comma-separated values (.csv)',
          value: 'csv',
        },
        {
          text: 'Microsoft Excel (.xlsx)',
          value: 'xlsx',
        },
      ]}
    />
  );
};

export default DownloadInfluencers;
