import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';

import facebookGamingIcon from '../../assets-2.0/facebookGaming.svg';
import twitchIcon from '../../assets-2.0/twitch.svg';
import youtubeIcon from '../../assets-2.0/youtube.svg';
import { Video } from '../../views/Campaign/types';
import { videoHasSegments } from '../../views/Campaign/utils';
import { VideoFlag } from '../VideoFlag/VideoFlag';

export const ROW_HEIGHT = 140;

const useStyles = makeStyles((theme) => ({
  link: {
    color: `${theme.palette.text.primary} !important`,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
    },
    '& .icon': {
      opacity: 0,
    },
    '&:hover .icon': {
      opacity: 1,
    },
  },
}));

interface VideoCellProps {
  video: Video;
  videoTime: string;
  showType: boolean;
}

export const VideoCell = ({ video, videoTime, showType }: VideoCellProps) => {
  const classes = useStyles();

  const { video_type } = video;

  return (
    <div style={{ width: '100%' }}>
      {showType && (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: -10 }}>
          {(video_type === 'live-vod' || video_type === 'live') && (
            <VideoFlag title="LIVE VIDEO" color="#0036D9" />
          )}
          {(video_type === 'live-vod' || video_type === 'vod') && (
            <VideoFlag title="VOD" color="#A300D8" />
          )}
          {video_type === 'video' && (
            <VideoFlag title="VIDEO" color="#A300D8" />
          )}
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {video.resource === 'youtube' && (
          <img
            src={youtubeIcon}
            alt="platform logo"
            style={{ height: '1.5em', marginRight: 8 }}
          />
        )}

        {video.resource === 'twitch' && (
          <img
            src={twitchIcon}
            alt="platform logo"
            style={{ height: '1.5em', marginRight: 8 }}
          />
        )}

        {video.resource === 'facebook' && (
          <img
            src={facebookGamingIcon}
            alt="platform logo"
            style={{ height: '1.5em', marginRight: 8 }}
          />
        )}

        <Typography color="textSecondary" style={{ fontSize: '0.8rem' }}>
          {videoTime}
        </Typography>
        {videoHasSegments(video) && (
          <Tooltip
            placement="top"
            title="Showing stats only for the selected time frame."
          >
            <AvTimerIcon
              style={{ padding: 0, color: '#A300D8', marginLeft: 8 }}
            />
          </Tooltip>
        )}
      </div>
      <a href={video.link} target="_blank" rel="noopener noreferrer">
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          className={classes.link}
        >
          <Tooltip placement="top" title={video.video_title}>
            <Typography
              className="text-ellipsis title"
              style={
                { '--lines': 2, marginRight: 4, flex: 1 } as React.CSSProperties
              }
            >
              {video.video_title}
            </Typography>
          </Tooltip>

          {video.link && <OpenInNewIcon className="icon" />}
        </div>
      </a>
    </div>
  );
};
