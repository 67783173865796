import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    position: 'absolute',
    marginTop: 10,
    width: 260,
  },
}));

export const ErrorMessage = ({ message, style }) => {
  const classes = useStyles();
  return (
    <Alert style={style} className={classes.errorMessage} severity="warning">
      {message}
    </Alert>
  );
};
