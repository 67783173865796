import { Checkbox, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { isoLanguagesMap } from '../../common/iso-languages';
import OutlinedInput from '../../components/OutlinedInput';

const useStyles = makeStyles({
  select: {
    padding: 4,
    background: 'white',
  },
  label: {
    fontSize: 16,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
  },
});

type Props = {
  availableLanguages: string[];
  onChange: (value: string[]) => void;
  values: string[];
};

export const CampaignLanguageFilter = ({
  availableLanguages,
  onChange,
  values,
}: Props) => {
  const classes = useStyles();

  const options = availableLanguages.map((item) => {
    return {
      value: item,
      label: isoLanguagesMap[item]?.name,
    };
  });

  const getValueText = (selected: string[], options: any[]) => {
    if (selected.length === 0 || selected.length === options.length) {
      return 'All';
    }

    if (selected.length === 1) {
      return isoLanguagesMap[selected[0]].name;
    }

    if (selected.length > 1) {
      return isoLanguagesMap[selected[0]].name + ` + ${selected.length - 1}`;
    }
  };

  if (options.length <= 1) return null;

  return (
    <>
      <span className={classes.label}>Language: </span>
      <Select
        autoWidth
        multiple
        displayEmpty
        value={values}
        classes={{ selectMenu: classes.select }}
        renderValue={(selected: string[]) => {
          const text = getValueText(selected, options);

          return (
            <Box
              display="flex"
              alignItems="center"
              style={{ padding: '3.36px 12px' }}
            >
              <Typography variant="h6">{text}</Typography>
            </Box>
          );
        }}
        input={<OutlinedInput />}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
      >
        {options.map(({ label, value }, index) => {
          return (
            <MenuItem key={label} value={value} style={{ padding: 10 }}>
              <Box
                display="flex"
                alignItems="center"
                style={{ padding: '3.36px 12px' }}
              >
                <Checkbox
                  color="primary"
                  checked={values.includes(value)}
                  inputProps={{ 'aria-label': 'Select a language' }}
                />
                <Typography variant="h6">{label}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};
