import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  label: {
    margin: 0,
  },
});

export default function (props) {
  const { label } = useStyles();

  return <InputLabel {...props} className={clsx(label, props.className)} />;
}
