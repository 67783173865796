export const sentimentMap = {
  1: 'positive',
  2: 'negative',
  3: 'neutral',
};

export const oldSentimentMap = {
  1: 'positive',
  2: 'slightly positive',
  3: 'neutral',
  4: 'slightly negative',
  5: 'negative',
};
