import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLinkTrackingDeliverableById } from '../../../../../ajax';
import { LinkTrackingDeliverable, SLICE_NAME } from './types';

interface FetchLinkTrackingDeliverablePayload {
  campaignId: number;
  linkTrackingDeliverableId: number;
}

export const fetchLinkTrackingDeliverable = createAsyncThunk<
  LinkTrackingDeliverable,
  FetchLinkTrackingDeliverablePayload
>(
  `${SLICE_NAME}/fetchLinkTrackingDeliverable`,
  ({ campaignId, linkTrackingDeliverableId }) =>
    getLinkTrackingDeliverableById(campaignId, linkTrackingDeliverableId)
);
