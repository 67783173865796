import { Box, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import _sortBy from 'lodash/sortBy';
import momentTz from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import xss from 'xss';

import { useMultiItemsSelect } from '../../../../hooks/useMultiItemsSelect';
import { getIsViewOnly } from '../../../../store/models/user/user.selectors';
import { CampaignMultipleItemsDeleteDialog } from '../../CampaignMultipleItemsDeleteDialog';
import { CampaignDeliverableChartTrigger } from '../../components/DeliverablesChartTrigger';
import InfluencerAvatar from '../../components/InfluencerAvatar';
import VideoCell from '../../components/VideoCell';
import Table, { StatBox, TableText } from '../../Table';
import {
  findInfluencerByVideo,
  findVideoById,
  getMomentURL,
  videoIsFromStreamingPlatform,
} from '../../utils';
import ShoutoutClipDialog from './ShoutoutClipDialog';

const ROW_HEIGHT = 130;

const useMentionTextStyles = makeStyles((theme) => ({
  box: {
    height: ROW_HEIGHT,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    fontSize: '1rem',
    '--lines': 3,
    margin: 0,
    lineHeight: '1.25rem',
  },
  button: {
    position: 'absolute',
    left: 8,
    bottom: 4,
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
    position: 'absolute',
    left: 140,
    bottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontWeight: 'bold',
    margin: 0,
    lineHeight: '1em',
  },
}));

const CreatorCol = ({
  influencer,
  shoutout,
  setCampaignShoutoutDelete,
  isViewOnly,
  handleRowSelect,
}) => (
  <>
    <div style={{ position: 'relative' }} className="padding-on-hover">
      <div
        style={{
          position: 'absolute',
          left: -32,
          top: '50%',
          transform: 'translateY(-50%)',
          height: ROW_HEIGHT,
          width: 48,
          background: 'rgba(0, 0, 0, .1)',
          zIndex: 5,
        }}
        className="slide-on-hover"
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Checkbox
            color="primary"
            checked={shoutout.isSelected}
            onChange={() => handleRowSelect(shoutout.shoutoutId)}
            inputProps={{ 'aria-label': 'Select shoutout mention' }}
          />
          <CampaignDeliverableChartTrigger videoId={shoutout.video.id} />
          {!isViewOnly && (
            <Tooltip placement="top" title="Delete Shoutout">
              <IconButton
                onClick={() => {
                  setCampaignShoutoutDelete(shoutout);
                }}
              >
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <InfluencerAvatar influencer={influencer} />
    </div>
  </>
);

const MentionText = ({
  text,
  momentUrl,
  timeOnScreen,
  avatar,
  name,
  clip,
  influencer,
  timeZone,
  content,
  words,
  video,
}) => {
  const classes = useMentionTextStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const getPlatformName = (video_resource) => {
    if (video_resource === 'facebook_gaming') {
      return 'FACEBOOK GAMING';
    }

    if (video_resource === 'tiktok_video') {
      return 'TIKTOK';
    }

    return video_resource.toUpperCase();
  };

  return (
    <div className={classes.box}>
      <ShoutoutClipDialog
        open={dialogOpen}
        video={video}
        influencer={influencer}
        timeZone={timeZone}
        onClose={() => setDialogOpen(false)}
        content={content}
        clip={clip}
        momentUrl={momentUrl}
        timeOnScreen={timeOnScreen}
      />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pl={1}
        flex={1}
      >
        <p className={classes.name}>{name}</p>
        <p
          dangerouslySetInnerHTML={{ __html: text }}
          className={`text-ellipsis ${classes.text}`}
        />
      </Box>
      {!!clip && (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={`${classes.button} show-on-hover`}
          onClick={() => setDialogOpen(true)}
        >
          View Moment
        </Button>
      )}
      {!!momentUrl && (
        <a
          href={momentUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={`show-on-hover ${classes.link}`}
        >
          <OpenInNewIcon fontSize="inherit" style={{ marginRight: 4 }} />
          <span>VIEW ON {getPlatformName(video.resource)}</span>
        </a>
      )}
    </div>
  );
};

const getCols = ({
  setCampaignShoutoutDelete,
  isViewOnly,
  handleRowSelect,
}) => [
  {
    label: 'Creator',
    key: 'creator',
    render: (row) => {
      return (
        <CreatorCol
          influencer={row.influencer}
          platform={row.platform}
          shoutout={row}
          setCampaignShoutoutDelete={setCampaignShoutoutDelete}
          isViewOnly={isViewOnly}
          handleRowSelect={handleRowSelect}
        />
      );
    },
    stretch: true,
    flex: 1.5,
  },
  {
    label: 'Video',
    key: 'videoTimeStamp',
    render: (row) => <VideoCell video={row.video} videoTime={row.videoTime} />,
    stretch: true,
    flex: 1,
  },
  {
    label: 'Voice Mention',
    key: 'text',
    render: (row) => <MentionText {...row} />,
    stretch: true,
    flex: 2,
  },
  {
    label: 'Time',
    key: 'timeStamp',
    render: (row) => (
      <TableText fontSize="0.8rem">
        {row.date},
        <br />
        {row.time}
      </TableText>
    ),
    stretch: true,
    flex: 0.75,
  },
  {
    label: 'CCV',
    key: 'ccv',
    render: (data) => <StatBox stat={data.ccv} />,
  },
  {
    label: 'Media Value',
    key: 'emv',
    render: (data) => (
      <StatBox stat={data.emv} format="0,[0].[0]a" prefix="$" />
    ),
  },
];

const getRows = ({
  usedShoutouts,
  usedVideos,
  usedInfluencers,
  timeZone,
  content,
  setCampaignShoutoutDelete,
  words: unsortedWords,
  v2,
  selectedRows,
}) =>
  usedShoutouts.map((shoutout) => {
    const shoutout_deliverables_v2_id = shoutout.shoutout_deliverables_v2_id;
    const shoutout_deliverable_id = shoutout.shoutout_deliverable_id;
    const shoutoutId = shoutout.id;
    const video = findVideoById(shoutout.video_id, usedVideos);
    const videoTimeObject = momentTz.tz(video.published_at, timeZone);
    const videoTimeStamp = videoTimeObject.unix();
    const videoTime = videoTimeObject.format('Do MMM YYYY, hh:mm A');
    const timeObject = videoTimeObject.add(shoutout.segment_start, 'second');

    const date = timeObject.format('Do MMM YYYY');
    const time = timeObject.format('hh:mm A');
    const timeStamp = timeObject.unix();

    const timeOnScreen = momentTz
      .utc(shoutout.segment_start * 1000)
      .format('HH:mm:ss');

    const influencer = findInfluencerByVideo(video, usedInfluencers);

    const momentUrl = getMomentURL(video, shoutout.segment_start * 1000);

    const words = _sortBy(unsortedWords, 'length');

    const text = () => {
      if (words) {
        let text = xss(shoutout.text);
        const inclusion_terms = words.map((word) => {
          // If it's a wildcard term, we need to match any word that starts with the term
          if (word.includes('*')) {
            return `\\b${word.replace(/\*/g, '?\\w+')}`;
          }

          // Match only the exact word
          return `\\b${word}`;
        });

        const regex = new RegExp('(' + inclusion_terms.join(')|(') + ')', 'gi');

        return text.replace(regex, (match, ...finds) => {
          var replaced;

          finds.some((found, index) => {
            if (found !== undefined) {
              replaced = `<b>${found}</b>`;
              return true;
            }
            return replaced;
          });
          return replaced;
        });
      } else {
        return xss(shoutout.text)
          .replace(
            new RegExp(`(^|\\W)(${content})($|\\W)`, 'gi'),
            `$1<b>$2</b>$3`
          )
          .replace(
            new RegExp(`(^|\\W)("${content}")($|\\W)`, 'gi'),
            `$1<b>$2</b>$3`
          );
      }
    };

    const getCCV = () => {
      if (video.resource === 'tiktok_video') {
        return undefined;
      }

      // Only shoutouts from streaming platforms have CCV
      return videoIsFromStreamingPlatform(video) ? shoutout.views : null;
    };

    const ccv = getCCV();

    return {
      time,
      date,
      timeStamp,
      timeOnScreen,
      videoTimeStamp,
      influencer,
      creator: influencer.name,
      key: shoutout.id,
      platform: video.resource,
      views: shoutout.views,
      ccv: ccv,
      emv: shoutout.emv,
      text: text(),
      videoTitle: video.video_title,
      video,
      videoTime,
      momentUrl,
      timeZone,
      avatar: shoutout.avatar,
      name: shoutout.creator,
      content,
      clip: shoutout.clip,
      shoutout_deliverables_v2_id,
      shoutout_deliverable_id,
      shoutoutId,
      v2,
      isSelected: selectedRows.includes(shoutoutId),
    };
  });

export const DetailedTable = ({
  usedShoutouts,
  usedVideos,
  usedInfluencers,
  timeZone,
  content,
  words,
  v2,
  setCampaignShoutoutDelete,
  onDeleteShoutoutMentions,
}) => {
  const isViewOnly = useSelector(getIsViewOnly);

  const usedShoutoutsIds = useMemo(
    () => usedShoutouts.map((s) => s.id),
    [usedShoutouts]
  );

  const {
    selectedItems: selectedRows,
    handleAllItemsSelect: handleAllRowsSelect,
    handleItemSelect: handleRowSelect,
  } = useMultiItemsSelect(usedShoutoutsIds);

  const rows = useMemo(
    () =>
      getRows({
        usedShoutouts,
        usedVideos,
        usedInfluencers,
        timeZone,
        content,
        words,
        v2,
        selectedRows,
      }),
    [
      usedShoutouts,
      usedVideos,
      usedInfluencers,
      timeZone,
      content,
      words,
      v2,
      selectedRows,
    ]
  );
  const cols = useMemo(
    () =>
      getCols({
        setCampaignShoutoutDelete,
        isViewOnly,
        handleRowSelect,
      }),
    [setCampaignShoutoutDelete, isViewOnly]
  );
  const idTable = `SV-voiceMentions-detailed-${(
    words[0] ||
    content ||
    ''
  ).replace(/\s+/g, '')}`;

  return (
    <>
      {selectedRows.length > 0 && (
        <div style={{ padding: 4, display: 'flex' }}>
          <Checkbox
            color="primary"
            onChange={(_, checked) => {
              handleAllRowsSelect(checked);
            }}
          />
          <CampaignMultipleItemsDeleteDialog
            items={selectedRows}
            onDelete={onDeleteShoutoutMentions}
            title="Delete Shoutout Mentions"
          />
        </div>
      )}
      <Table
        cols={cols}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        minWidth={1300}
        defaultSortCol="videoTimeStamp"
        idTable={idTable}
        hasSelectableRows={true}
      />
    </>
  );
};

export default DetailedTable;
