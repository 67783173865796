import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import bgImage from '../../../../assets/images/big-blue-bgr-logo.png';
import FacebookIcon from '../../../../assets-2.0/images-influencer/facebook.svg';
import TwitchIcon from '../../../../assets-2.0/twitch.svg';
import YoutubeIcon from '../../../../assets-2.0/youtube.svg';
import Button from '../../../../components/Button';
import useEnableFacebook from '../../../../hooks/enable-facebook';

const useStyles = makeStyles((theme) => ({
  loginButton: {
    width: '220px',
    padding: '5px',
  },
  iconImage: {
    width: 24,
    height: 24,
  },
}));

export const FacebookOnboardingPage = ({
  loginFacebook,
  getOnboardingCampaignData,
  onboardingCompanies,
  redirectTwitch,
  redirectYoutube,
  onboardingCompleted,
  errors,
  match,
}) => {
  const classes = useStyles();
  const {
    params: { pageName },
  } = match;

  useEffect(() => {
    getOnboardingCampaignData();
  }, []);
  useEnableFacebook();

  return (
    <div className="peers ai-s fxw-nw h-100vh">
      <div
        className="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <div
        className="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r"
        style={{ minWidth: '320px' }}
      >
        {!onboardingCompleted && (
          <>
            <h4 className="fw-400 c-grey-900 mB-40">
              Hey {pageName}, <br />
              please authorize your Facebook account!
            </h4>

            <Button
              type="button"
              variant="outlined"
              onClick={loginFacebook}
              className={clsx('pL-0 mB-40', classes.loginButton)}
            >
              {' '}
              <img
                src={FacebookIcon}
                className={classes.iconImage}
                alt="facebook icon"
                style={{ marginLeft: 4 }}
              />
              <span style={{ marginLeft: 6 }}>Connect Facebook</span>
            </Button>
            {errors.length > 0 &&
              errors.map((err, i) => (
                <p key={i} className="mB-20" style={{ color: 'red' }}>
                  {' '}
                  {err}{' '}
                </p>
              ))}
            {onboardingCompanies && onboardingCompanies.length > 0 && (
              <Typography className={'fs-i fsz-sm'} color="textSecondary">
                {`By authorizing your account you consent Aggero to collect
                    data on behalf of ${onboardingCompanies.join(', ')}.
                    Your data will be used for analyzing the marketing performance
                    of the live streams.`}
              </Typography>
            )}
          </>
        )}
        {onboardingCompleted && (
          <>
            <h4 className="fw-400 c-grey-900 mB-10">Thank you {pageName}!</h4>
            <Typography className={'fs-i fsz-sm mT-10'} color="textSecondary">
              Your Facebook page is now connected to Aggero.
            </Typography>
            <Typography
              className={'fsz-sm c-grey-900 mT-40'}
              color="textSecondary"
            >
              Login to Aggero and access more stats for your streaming channels
            </Typography>
            <Button
              type="button"
              variant="outlined"
              onClick={redirectTwitch}
              className={clsx('mT-10 pL-10', classes.loginButton)}
            >
              {' '}
              <img
                src={TwitchIcon}
                className={classes.iconImage}
                alt="twitch icon"
              />
              <span style={{ marginLeft: 10 }}>Login with Twitch</span>
            </Button>
            <Button
              type="button"
              variant="outlined"
              className={clsx('mT-10 pL-10', classes.loginButton)}
              onClick={redirectYoutube}
            >
              {' '}
              <img
                src={YoutubeIcon}
                className={classes.iconImage}
                alt="youtube icon"
              />
              <span style={{ marginLeft: 10 }}>Login with Youtube</span>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

FacebookOnboardingPage.propTypes = {
  loginFacebook: PropTypes.func.isRequired,
};
