export const getCompanyName = ({ account: { firebaseData } }) =>
  firebaseData && firebaseData.company;
export const getUserFeatures = ({ account: { firebaseData } }) =>
  firebaseData ? firebaseData.features : {};

export const getUserId = ({ account }) =>
  account && account.authUser && account.authUser.id;
export const getUserEmail = ({ account }) =>
  account && account.authUser && account.authUser.email;

export const userLoggedOut = ({ account }) =>
  !account.authUser || !account.authUser.id;

export const isUserLoaded = ({ account }) =>
  account.authUser && !!account.authUser.id;
export const getCompanySearchLimit = ({ account: { firebaseData } }) =>
  firebaseData && firebaseData.search_limit;
export const getCompanyDummy = ({ account: { firebaseData } }) =>
  firebaseData && firebaseData.dummy;
export const getTrialInfluencerSearch = ({ account: { firebaseData } }) =>
  firebaseData && firebaseData.trial_influencer_search;
export const getTrialAccount = ({ account: { firebaseData } }) =>
  firebaseData && !!firebaseData.trial_account;
export const getDisableTrackCampaignButton = ({ account: { firebaseData } }) =>
  firebaseData && !!firebaseData.disable_track_campaign_button;

export const isAggeroUser = ({ account }) =>
  account.authUser &&
  account.authUser.email &&
  account.authUser.email.includes('@aggero.io');

export const getAuthUser = ({ account }) => account.authUser;

export const getIsViewOnly = ({ account: { firebaseData } }) =>
  firebaseData && !!firebaseData.view_only;

export const getShouldShowRemoveBannerOccurrenceButton = (store) => {
  if (isAggeroUser(store)) return true;

  const email = getUserEmail(store);

  if (!email) return false;

  // hardcoding this org as per AGG-2074
  return (
    email.includes('@prodigy-agency.gg') || email.includes('n.tian@efg.gg')
  );
};

export const getUserSearchLimit = ({ account: { firebaseData } }) =>
  (firebaseData && firebaseData.search_limit) || Infinity;
