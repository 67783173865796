import { CardContent, Grid, IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';

import CardTitle from './CardTitle';

export default function AggeroCardHeader({ title, info }) {
  return (
    <CardContent>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <CardTitle>{title}</CardTitle>
        </Grid>
        {info && (
          <Grid item style={{ height: 16 }}>
            <Tooltip placement="top" title={info}>
              <IconButton size="small">
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
}
