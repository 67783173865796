import { putUser, putUserPassword } from '../../ajax';
import { actions } from '../../store/actions';

export async function updateUser(payload) {
  const { id, name, dispatch } = payload;

  try {
    await putUser(id, { name });

    dispatch(actions.updateUser({ name }));

    return { success: true };
  } catch (e) {
    console.error('User Update Error - ', e);

    let errorKey = null;

    if (e.status === 422 || e.status === 408) {
      errorKey =
        e.bodyJson && e.bodyJson.messages && e.bodyJson.messages[0].key;
    } else if (e.status === 500) {
      errorKey = e.bodyJson && `${e.bodyJson.error} - ${e.bodyJson.exception}`;
    }

    return { success: false, errorKey };
  }
}

export async function updateUserPassword(payload) {
  const { password, current_password } = payload;

  try {
    await putUserPassword({ password, current_password });

    return { success: true };
  } catch (e) {
    console.error('User Update Error - ', e);

    let errorKey = null;

    if (e.status === 422 || e.status === 408 || e.status === 400) {
      errorKey =
        e.bodyJson && e.bodyJson.messages && e.bodyJson.messages[0].key;
    } else if (e.status === 500) {
      errorKey = e.bodyJson && `${e.bodyJson.error} - ${e.bodyJson.exception}`;
    }

    return { success: false, errorKey };
  }
}

export async function updateUserEmail(payload) {
  const { id, email, dispatch } = payload;

  try {
    await putUser(id, { email });

    dispatch(actions.updateUser({ email }));

    return { success: true };
  } catch (e) {
    console.error('User Update Error - ', e);

    let errorKey = null;

    if (e.status === 422 || e.status === 408 || e.status === 400) {
      errorKey =
        e.bodyJson && e.bodyJson.messages && e.bodyJson.messages[0].key;
    } else if (e.status === 500) {
      errorKey = e.bodyJson && `${e.bodyJson.error} - ${e.bodyJson.exception}`;
    }

    return { success: false, errorKey };
  }
}
