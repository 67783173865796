import moment from 'moment';

import twitchLink from '../../../helpers/twitch-link';
import { getInfluencer, getVideo } from './utils';

const computeVoiceMentions = ({
  shoutout_deliverables,
  campaign_influencers,
  videos,
}) => {
  const rows = [];

  rows.push([
    'Tracked Query',
    'Tracked Name',
    'Creator Name',
    'Voice Mention',
    'Link',
    'Platform',
    'Video/Stream Title',
    'Time',
    'Viewers',
    'Media Value',
  ]);

  shoutout_deliverables.forEach((deliverable) => {
    deliverable.shoutouts.forEach((shoutout) => {
      const video = getVideo(videos, shoutout.video_id);

      const timeObject = moment(video.published_at)
        .add(shoutout.segment_start, 'second')
        .utc();

      rows.push([
        (deliverable.inclusion_terms &&
          deliverable.inclusion_terms.join(',')) ||
          deliverable.content,
        deliverable.name || deliverable.content,
        getInfluencer(campaign_influencers, video.campaign_influencer_ids).name,
        shoutout.text,
        twitchLink(
          shoutout.resource_url || (video && video.link),
          shoutout.segment_start
        ),
        video.resource,
        video.video_title,
        timeObject.format('MM/DD/YYYY HH:mm:ss'),
        shoutout.views,
        shoutout.emv,
      ]);
    });
  });

  return rows;
};

export default computeVoiceMentions;
