export const PATH_ROOT = '/';
export const INSIGHTS = '/insights';
export const PATH_SIGNIN = '/signin';
export const PATH_LOGIN_VIA_TOKEN = '/signin/token/:token';
export const PATH_SIGNIN_CALLBACK_ROOT = '/signin/callback';
export const PATH_SIGNIN_TWITCH_CALLBACK = `${PATH_SIGNIN_CALLBACK_ROOT}/twitch`;
export const PATH_SIGNIN_YOUTUBE_CALLBACK = `${PATH_SIGNIN_CALLBACK_ROOT}/youtube`;
export const PATH_SIGNIN_TWITTER_CALLBACK = `${PATH_SIGNIN_CALLBACK_ROOT}/twitter`;
export const PATH_SIGNIN_TIKTOK_CALLBACK = `${PATH_SIGNIN_CALLBACK_ROOT}/tiktok`;
// export const PATH_SIGNUP = '/signup_n0t_f0undL0L'
export const PATH_FORGOT = '/forgot';
export const PATH_RESET_PASSWORD = '/reset_password';
export const PATH_CONNECTIONS = '/connections';
export const EMAIL_TEMPLATES = '/templates';
export const EMAIL_STATS = '/email-stats';
export const INFLUENCER_SEARCH = '/influencer-search';
export const INFLUENCERS = '/influencers';
export const HIGHLIGHTS = '/highlights';
export const DEALS = '/deals';
export const MESSAGING = '/messaging';
export const PROFILE = '/profile';
export const MY_BRANDS = '/my-brands';
export const MY_AGGERO = '/my-aggero';
export const INFLUENCERS_SIGNUP = '/signup/influencers';
export const INFLUENCERS_SIGNIN = '/signin/influencers';
export const INFLUENCERS_LEGACY_LOGIN = '/signin/legacy/influencers/';
export const INFLUENCERS_LOGIN_CREATOR = '/creator-profile-page';
export const ONBOARDING_FACEBOOK = '/onboarding/facebook';
export const ONBOARDING_TWITTER = '/onboarding/twitter';
export const ONBOARDING_INSTAGRAM = '/onboarding/instagram';
export const INFLUENCER = '/influencer';
export const CAMPAIGNS = '/campaigns';
export const TRACK_CAMPAIGN = '/track-campaign';
export const MY_WATCHLIST = '/my-watchlist';
export const ADD_TO_WATCHLIST = '/my-watchlist/add';
export const INFLUENCERS_STATS = '/stats';
export const INFLUENCER_EMAIL_CHANGE_CONFIRMATION = '/change_email';
export const OOPS = '/oops';
