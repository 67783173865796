import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Label from '../../../components/Label';
import OutlinedInput from '../../../components/OutlinedInput';
import SectionTitle from '../../../components/SectionTitle';
import {
  getInfluencerTwitchAudienceEngagement,
  getInfluencerYoutubeAudienceEngagement,
} from '../selectors';
import EngagementChart from './EngagementChart';
import FollowersGrowthChart from './FollowersGrowthChart';
import InfluencerAudienceEngagement from './InfluencerAudienceEngagement';
import InfluencerPlatformSelect from './InfluencerPlatformSelect';

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 120,
  },
}));

const mapStateToProps = (state) => ({
  youtubeAudienceEngagement: getInfluencerYoutubeAudienceEngagement(state),
  twitchAudienceEngagement: getInfluencerTwitchAudienceEngagement(state),
});

const enhance = connect(mapStateToProps);

const InfluencerTabEngagement = (props) => {
  const {
    networkFilter,
    dateRangeFilterChanged,
    dateRangeFilter,
    youtubeAudienceEngagement,
    twitchAudienceEngagement,
    engagementData,
    influencerTwitchStreamer,
    influencerYoutubeStreamer,
  } = props;

  const { followers_growth: twitchFollowersGrowth } = influencerTwitchStreamer;

  const { subscribers_growth: subscribersGrowthYoutube } =
    influencerYoutubeStreamer;

  const activePlatforms = [
    Object.values(twitchAudienceEngagement || {}).filter((e) => !!e).length >
      0 && 'twitch',
    Object.values(youtubeAudienceEngagement || {}).filter((e) => !!e).length >
      0 && 'youtube',
  ].filter((e) => !!e);

  const [activePlatform, setActivePlatform] = useState('twitch');

  const classes = useStyles();

  useEffect(() => {
    if (activePlatforms.length === 2 && networkFilter) {
      setActivePlatform(networkFilter);
    } else {
      setActivePlatform(activePlatforms[0]);
    }
  }, [activePlatforms.length]);

  useEffect(() => () => dateRangeFilterChanged(1000 * 60 * 60 * 24 * 365), []);

  function setDateRangeFilter({ target: { value } }) {
    dateRangeFilterChanged(value);
  }

  function renderDateRange() {
    if (activePlatform === 'youtube') return null;

    return (
      <Grid container alignItems="center" justify="flex-end" spacing={2}>
        <Grid item>
          <Label htmlFor="mentionsTimeAgoFilter">Date range</Label>
        </Grid>

        <Grid item>
          <Select
            fullWidth
            displayEmpty
            value={dateRangeFilter}
            className={classes.select}
            input={<OutlinedInput id="mentionsTimeAgoFilter" />}
            onChange={setDateRangeFilter}
          >
            <MenuItem value={1000 * 60 * 60 * 24 * 7}>Last 7 days</MenuItem>
            <MenuItem value={1000 * 60 * 60 * 24 * 30}>Last month</MenuItem>
            <MenuItem value={1000 * 60 * 60 * 24 * 90}>Last 3 months</MenuItem>
            <MenuItem value={1000 * 60 * 60 * 24 * 365} selected>
              Last year
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" wrap="nowrap" spacing={4}>
      <Grid item>
        <Grid container>
          <Grid item xs={6}>
            <SectionTitle>Audience Engagement</SectionTitle>
          </Grid>
          <Grid item xs={6}>
            {activePlatforms.length > 1 && (
              <InfluencerPlatformSelect
                activePlatform={activePlatform}
                activePlatforms={activePlatforms}
                onSelect={(platform) => setActivePlatform(platform)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        {renderDateRange()}
        <InfluencerAudienceEngagement
          activePlatform={activePlatform}
          youtubeAudienceEngagement={youtubeAudienceEngagement}
          twitchAudienceEngagement={twitchAudienceEngagement}
        />
      </Grid>
      {activePlatform === 'twitch' &&
        twitchFollowersGrowth &&
        twitchFollowersGrowth.length >= 5 && (
          <Grid item>
            <FollowersGrowthChart
              platform="twitch"
              stats={twitchFollowersGrowth}
            />
          </Grid>
        )}
      {activePlatform === 'youtube' &&
        subscribersGrowthYoutube &&
        subscribersGrowthYoutube.length >= 5 && (
          <Grid item>
            <FollowersGrowthChart
              platform="youtube"
              stats={subscribersGrowthYoutube}
            />
          </Grid>
        )}
      {activePlatform === 'twitch' &&
        engagementData &&
        engagementData.length >= 1 && (
          <Grid item>
            <EngagementChart data={engagementData} />
          </Grid>
        )}
    </Grid>
  );
};

export default enhance(InfluencerTabEngagement);
