import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  ClickAwayListener,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import * as ajax from '../ajax';
import * as paths from '../common/constants/paths';
import { getFirstWatchlist } from '../store/models/watchlists/watchlists.selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    zIndex: 105,
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  resultsContainer: {
    position: 'absolute',
    top: 'calc(100% + 16px)',
    left: 0,
    right: 0,
    zIndex: 103,
  },
  resultsCard: {
    border: 'none',
    boxShadow: theme.shadows[3],
  },
  noResults: {
    padding: theme.spacing(2),
  },
  listItem: {
    color: '#333',
    '&:hover': {
      background: '#efefef',
      color: '#0f9aee',
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const MIN_QUERY_LENGTH = 3;
const DEBOUNCE_INTERVAL = 500;

const SearchByName = () => {
  const classes = useStyles();

  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);

  const timeoutRef = useRef();

  const location = useLocation();

  const firstWatchlistId = useSelector(getFirstWatchlist()).id;

  const loading = results === null;

  useEffect(() => {
    if (query) setQuery('');
    if (results !== null) setResults(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const onChange = (query) => {
    setQuery(query);

    setResults(null);

    if (query.trim().length < MIN_QUERY_LENGTH) return;

    onSearch(query);
  };

  const onSearch = (query) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    timeoutRef.current = setTimeout(async () => {
      if (!loading) setResults(null);

      const data = await ajax.searchInfluencers(query.trim());

      setResults(data);
    }, DEBOUNCE_INTERVAL);
  };

  const onClickAway = () => {
    setQuery('');
    setResults(null);
  };
  const fallbackImage = '../../fallback/img.png';
  function renderContent() {
    if (loading)
      return (
        <Box
          height={128}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );

    if (results.length)
      return (
        <List dense>
          {results.map((influencer) => (
            <Link
              to={`${paths.INFLUENCER}/${encodeURI(influencer.login)}/${
                influencer.network || 'twitch'
              }`}
              key={Math.random()}
            >
              <ListItem classes={{ root: classes.listItem }}>
                <ListItemAvatar>
                  <Avatar
                    src={
                      influencer.profile_image_url
                        ? influencer.profile_image_url
                        : fallbackImage
                    }
                    alt={influencer.display_name?.toUpperCase()}
                  />
                </ListItemAvatar>
                <ListItemText primary={influencer.display_name} />
              </ListItem>
            </Link>
          ))}
        </List>
      );

    return (
      <Typography className={classes.noResults}>
        Sorry, we don't have this creator on our platform. If you want to
        analyze this channel, you can add the creator to your{' '}
        <Link to={`${paths.MY_WATCHLIST}/${firstWatchlistId}`}>Watchlist.</Link>
      </Typography>
    );
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={classes.container}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            data-id="influencers-search-bar"
            placeholder="Search creators"
            onChange={(e) => onChange(e.target.value)}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
        {query.trim().length >= MIN_QUERY_LENGTH && (
          <div className={classes.resultsContainer}>
            <Card className={classes.resultsCard}>
              <div className={classes.cardContent}>{renderContent()}</div>
            </Card>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SearchByName;
