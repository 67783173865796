import { call, put, takeLatest } from 'redux-saga/effects';

import { putCampaign } from '../../ajax';
import queryClient from '../../queryClient';
import { resetCampaignShoutouts } from '../../views/Campaign/DeliverablesTab/Shoutouts/store/ShoutoutDeliverables.slice';
import { CAMPAIGN_UPDATE_SUBMITTED } from '../action-types';
import {
  campaignRequested,
  campaignUpdateFailed,
  campaignUpdateRequested,
  campaignUpdateSucceeded,
} from '../models/campaigns/campaigns.actions';

function* updateCampaign(action) {
  const {
    payload: { values, id, influencerIds, name, deliverables, bag },
  } = action;

  yield put(campaignUpdateRequested());

  const {
    campaignName,
    influencersSocials,
    campaignDays,
    campaignDaysFrame,
    isScheduleDateType,
  } = values;

  const data = {
    name: campaignName,
    campaign_days: isScheduleDateType ? campaignDays : campaignDaysFrame,
    ...deliverables,
  };

  const idsSet = new Set(influencerIds);

  influencersSocials.forEach((i) => idsSet.delete(i.id));

  data.campaign_influencers_attributes = influencersSocials
    .map(
      ({
        influencerYoutubeUrl,
        influencerTwitchUrl,
        influencerFacebookUrl,
        influencerInstagramUrl,
        influencerTwitterUrl,
        influencerTiktokUrl,
        influencerFacebookGamingUrl,
        influencerYoutubeUrlChecked,
        influencerTwitchUrlChecked,
        influencerFacebookUrlChecked,
        influencerInstagramUrlChecked,
        influencerTwitterUrlChecked,
        influencerTiktokUrlChecked,
        influencerFacebookGamingUrlChecked,
        id,
        token,
      }) => ({
        youtube_url:
          !!influencerYoutubeUrl && influencerYoutubeUrlChecked
            ? influencerYoutubeUrl
            : null,
        facebook_url:
          !!influencerFacebookUrl && influencerFacebookUrlChecked
            ? influencerFacebookUrl
            : null,
        twitch_url:
          !!influencerTwitchUrl && influencerTwitchUrlChecked
            ? influencerTwitchUrl
            : null,
        twitter_url:
          !!influencerTwitterUrl && influencerTwitterUrlChecked
            ? influencerTwitterUrl
            : null,
        instagram_url:
          !!influencerInstagramUrl && influencerInstagramUrlChecked
            ? influencerInstagramUrl
            : null,
        tiktok_url:
          !!influencerTiktokUrl && influencerTiktokUrlChecked
            ? influencerTiktokUrl
            : null,
        facebook_gaming_url:
          !!influencerFacebookGamingUrl && influencerFacebookGamingUrlChecked
            ? influencerFacebookGamingUrl
            : null,
        influencer_token: token ?? null,
        id,
      })
    )
    .filter((influencer) => {
      const oldInfluencerData = bag.props.campaign.campaign_influencers.find(
        (i) => i.id === influencer.id
      );

      if (!oldInfluencerData) {
        return true;
      }

      return !compareInfluencerUrls(influencer, oldInfluencerData);
    })
    .concat(Array.from(idsSet).map((id) => ({ id, _destroy: true })));

  try {
    yield call(putCampaign, id, data);

    // reset deliverable data
    yield put(resetCampaignShoutouts(id));
    queryClient.clear();

    yield put(campaignRequested({ campaignId: id }));

    yield put(campaignUpdateSucceeded({ campaign: { id, name } }));
  } catch (e) {
    console.error('Campaign Update Error - ', e);

    let message = null;

    if (e.status === 422 || e.status === 408) {
      message = e.bodyJson && e.bodyJson.errors;
    } else if (e.status === 500) {
      message = e.bodyJson && `${e.bodyJson.error} - ${e.bodyJson.exception}`;
    }

    yield put(
      campaignUpdateFailed({ error: message || 'Campaign Update Error' })
    );
  }
}

export default function* root() {
  yield takeLatest(CAMPAIGN_UPDATE_SUBMITTED, updateCampaign);
}

function compareInfluencerUrls(obj1, obj2) {
  const properties = [
    'facebook_gaming_url',
    'facebook_url',
    'instagram_url',
    'tiktok_url',
    'twitch_url',
    'twitter_url',
    'youtube_url',
  ];

  for (let prop of properties) {
    if (obj1[prop] !== obj2[prop]) {
      return false;
    }
  }

  return true;
}
