import _orderBy from 'lodash/orderBy';
import memoize from 'proxy-memoize';

import { State } from '../../../../../store/types';
import { LoadingState, shouldFetch, shouldShowLoading } from '../../../types';
import { BrandFilter } from '../../types';
import { filterBrandDeliverables } from '../../utils';
import { ShoutoutDeliverableStructure, SLICE_NAME } from './types';

const memoizedFilteredShoutoutDeliverableStructures = memoize(
  ({
    campaignId,
    onlyBrandDeliverables,
    brandIdFilter,
    state,
    sortBy,
    shoutoutFilter,
  }: {
    campaignId: number;
    onlyBrandDeliverables: boolean;
    brandIdFilter: BrandFilter;
    state: State;
    sortBy?: { key: keyof ShoutoutDeliverableStructure; order: 'asc' | 'desc' };
    shoutoutFilter?: 'all' | number;
  }) => {
    const allShoutoutDeliverables =
      state.campaigns.byId[campaignId].all_shoutout_deliverables;

    let filteredShoutoutDeliverables = allShoutoutDeliverables;

    if (shoutoutFilter && shoutoutFilter !== 'all') {
      filteredShoutoutDeliverables = filteredShoutoutDeliverables.filter(
        (item) => item.id === shoutoutFilter
      );
    }

    return _orderBy(
      filterBrandDeliverables(
        filteredShoutoutDeliverables,
        onlyBrandDeliverables,
        brandIdFilter
      ),
      [sortBy.key || 'name'],
      [sortBy.order]
    );
  }
);

export const selectFilteredShoutoutDeliverableStructures =
  ({
    campaignId,
    onlyBrandDeliverables,
    brandIdFilter,
    sortBy,
    shoutoutFilter,
  }: {
    campaignId: number;
    onlyBrandDeliverables: boolean;
    brandIdFilter: BrandFilter;
    sortBy?: { key: keyof ShoutoutDeliverableStructure; order: 'asc' | 'desc' };
    shoutoutFilter?: 'all' | number;
  }) =>
  (state: State) =>
    memoizedFilteredShoutoutDeliverableStructures({
      campaignId,
      onlyBrandDeliverables,
      brandIdFilter,
      state,
      sortBy,
      shoutoutFilter,
    });

export const selectShoutoutDeliverableLoadingState =
  (shoutoutDeliverableId: number) => (state: State) =>
    state[SLICE_NAME].loadingMap[shoutoutDeliverableId] ?? LoadingState.IDLE;

export const selectShoutoutDeliverableShowLoading =
  (shoutoutDeliverableId: number) => (state: State) =>
    shouldShowLoading(
      selectShoutoutDeliverableLoadingState(shoutoutDeliverableId)(state)
    );

export const selectShouldFetchShoutoutDeliverable =
  (shoutoutDeliverableId: number) => (state: State) =>
    shouldFetch(
      selectShoutoutDeliverableLoadingState(shoutoutDeliverableId)(state)
    );

export const selectShoutoutDeliverableById =
  (shoutoutDeliverableId: number) => (state: State) =>
    state[SLICE_NAME].entities[shoutoutDeliverableId];

export const selectShoutoutDeliverablesError = () => (state: State) =>
  state[SLICE_NAME].error;

const memoizedAllShoutoutDeliverableIdsByCampaignId = memoize(
  ({ campaignId, state }: { campaignId: number; state: State }) =>
    state.campaigns.byId[campaignId]?.all_shoutout_deliverables.map(
      (deliverable) => deliverable.id
    ) ?? []
);

export const selectAllShoutoutDeliverableIdsByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedAllShoutoutDeliverableIdsByCampaignId({ campaignId, state });

// used by PDF report
const memoizedAllShoutoutDeliverablesByCampaignId = memoize(
  ({ campaignId, state }: { campaignId: number; state: State }) => {
    const allShoutoutDeliverableIds =
      selectAllShoutoutDeliverableIdsByCampaignId(campaignId)(state);

    return allShoutoutDeliverableIds.map((id) =>
      selectShoutoutDeliverableById(id)(state)
    );
  }
);

export const selectAllShoutoutDeliverablesByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedAllShoutoutDeliverablesByCampaignId({ campaignId, state });

export const selectAllShoutoutDeliverablesByCampaignIdShowLoading =
  (campaignId: number) => (state: State) => {
    const ids = selectAllShoutoutDeliverableIdsByCampaignId(campaignId)(state);

    return ids
      .map((id) => selectShoutoutDeliverableShowLoading(id)(state))
      .some((showLoading) => showLoading);
  };

const memoizedShoutoutDeliverablesToFetchByCampaignId = ({
  campaignId,
  state,
}: {
  campaignId: number;
  state: State;
}) => {
  const deliverables = state.campaigns.byId[
    campaignId
  ]?.all_shoutout_deliverables.map(({ id, v2 }) => ({ id, v2 }));

  return deliverables.filter(({ id }) =>
    selectShouldFetchShoutoutDeliverable(id)(state)
  );
};

export const selectShoutoutDeliverablesToFetchByCampaignId =
  (campaignId: number) => (state: State) =>
    memoizedShoutoutDeliverablesToFetchByCampaignId({ campaignId, state });
