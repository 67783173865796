import { useEffect, useState } from 'react';

export const useMultiItemsSelect = <T>(items: T[]) => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  const handleItemSelect = (item: T) => {
    setSelectedItems((prev) => {
      const itemIsSelected = prev.includes(item);

      if (itemIsSelected) {
        return prev.filter((r) => r !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  const handleAllItemsSelect = (selected: boolean) => {
    if (selected) {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }
  };

  // If the items change, we need to remove any selected items that are no longer in the list
  useEffect(() => {
    if (selectedItems.length > 0) {
      setSelectedItems((prev) => {
        return prev.filter((i) => items.includes(i));
      });
    }
    // We don't want to run this effect when the selectedItems change, so we disable the exhaustive-deps rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return { selectedItems, handleItemSelect, handleAllItemsSelect };
};
