import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

export const computeInfluencerSocialPostDeliverableData = ({
  timeFrame,
  campaign,
  keyword,
}) => {
  // Instagram
  const instagramDeliverables =
    campaign.instagram_keyword_mention_deliverables.filter(
      ({ keyword: current }) => current === keyword
    );

  const instagramPosts = (
    instagramDeliverables
      ? instagramDeliverables.reduce(
          (posts, deliverable) => posts.concat(deliverable.posts),
          []
        )
      : []
  ).filter(({ published_at }) =>
    moment(published_at).isBetween(timeFrame.start, timeFrame.end)
  );

  const instagramMentions = instagramPosts.length;

  const instagramEngagements = instagramPosts.reduce(
    (prev, { comment_count, likes }) => prev + comment_count + likes,
    0
  );

  // TikTok
  const tiktokDeliverables =
    campaign.tiktok_keyword_mention_deliverables.filter(
      ({ keyword: current }) => current === keyword
    );

  const tiktokPosts = (
    tiktokDeliverables
      ? tiktokDeliverables.reduce(
          (posts, deliverable) => posts.concat(deliverable.posts),
          []
        )
      : []
  ).filter(({ published_at }) =>
    moment(published_at).isBetween(timeFrame.start, timeFrame.end)
  );

  const tiktokMentions = tiktokPosts.length;

  const tiktokEngagements = tiktokPosts.reduce(
    (prev, { comments_count, likes_count, shares_count }) =>
      prev + comments_count + likes_count + shares_count,
    0
  );

  // Twitter
  const twitterDeliverables =
    campaign.twitter_keyword_mention_deliverables.filter(
      ({ keyword: current }) => current === keyword
    );

  const twitterPosts = (
    twitterDeliverables
      ? twitterDeliverables.reduce(
          (posts, { tweets }) => posts.concat(tweets),
          []
        )
      : []
  ).filter(({ published_at }) =>
    moment(published_at).isBetween(timeFrame.start, timeFrame.end)
  );

  const twitterEngagements = twitterPosts.reduce(
    (prev, { retweets, quotes, favorites }) =>
      prev + retweets + favorites + quotes,
    0
  );

  const twitterMentions = twitterPosts.length;

  // Facebook
  const facebookDeliverables =
    campaign.facebook_keyword_mention_deliverables.filter(
      ({ keyword: current }) => current === keyword
    );

  const facebookPosts = (
    facebookDeliverables
      ? facebookDeliverables.reduce(
          (posts, deliverable) => posts.concat(deliverable.posts),
          []
        )
      : []
  ).filter(({ published_at }) =>
    moment(published_at).isBetween(timeFrame.start, timeFrame.end)
  );

  const facebookMentions = facebookPosts.length;

  const facebookEngagements = facebookPosts.reduce(
    (prev, { comments_count, likes_count, shares_count }) =>
      prev + comments_count + likes_count + shares_count,
    0
  );

  // Totals
  const totalMentions =
    instagramMentions + twitterMentions + tiktokMentions + facebookMentions;

  const totalEngagements =
    twitterEngagements +
    instagramEngagements +
    tiktokEngagements +
    facebookEngagements;

  const concatenatedPosts = [
    ...instagramPosts,
    ...twitterPosts,
    ...tiktokPosts,
    ...facebookPosts,
  ];

  const totalImpressions = concatenatedPosts.reduce(
    (t, p) => t + (p.impression_count ?? p.est_impression_count),
    0
  );

  const impressionsEstimated = concatenatedPosts.some(
    (p) => !p.impression_count
  );

  const totalEMV = concatenatedPosts.reduce((t, p) => t + p.emv, 0);

  const sentiment = concatenatedPosts.length
    ? concatenatedPosts.reduce((prev, { sentiment }) => prev + sentiment, 0) /
      concatenatedPosts.length
    : 0;

  let maxPosts = 1;

  const byInfluencerId = instagramDeliverables
    .concat(twitterDeliverables)
    .reduce((prev, deliverable) => {
      const influencerData = prev[deliverable.campaign_influencer_id] || {
        twitterPosts: 0,
        instagramPosts: 0,
        tiktokPosts: 0,
        totalEngagements: 0,
        totalImpressionsById: 0,
      };

      if (deliverable.network === 'instagram') {
        const posts = deliverable.posts.filter(({ published_at }) =>
          moment(published_at).isBetween(timeFrame.start, timeFrame.end)
        );

        influencerData.instagramPosts += posts.length;
        influencerData.totalEngagements += posts.reduce(
          (prev, { comment_count, likes }) => prev + comment_count + likes,
          0
        );
        influencerData.totalImpressionsById += posts.reduce(
          (prev, { impression_count, est_impression_count }) =>
            prev + (impression_count ?? est_impression_count),
          0
        );

        if (influencerData.instagramPosts > maxPosts)
          maxPosts = influencerData.instagramPosts;
      } else if (deliverable.network === 'twitter') {
        const posts = deliverable.tweets.filter(({ published_at }) =>
          moment(published_at).isBetween(timeFrame.start, timeFrame.end)
        );

        influencerData.twitterPosts += posts.length;
        influencerData.totalEngagements += posts.reduce(
          (prev, { quotes, favorites }) => prev + quotes + favorites,
          0
        );
        influencerData.totalImpressionsById += posts.reduce(
          (prev, { impression_count, est_impression_count }) =>
            prev + (impression_count ?? est_impression_count),
          0
        );

        if (influencerData.twitterPosts > maxPosts)
          maxPosts = influencerData.twitterPosts;
      } else if (deliverable.network === 'tiktok') {
        const posts = deliverable.posts.filter(({ published_at }) =>
          moment(published_at).isBetween(timeFrame.start, timeFrame.end)
        );

        influencerData.tiktokPosts += posts.length;
        influencerData.totalEngagements += posts.reduce(
          (prev, { comments_count, likes_count, shares_count }) =>
            prev + comments_count + likes_count + shares_count,
          0
        );
        influencerData.totalImpressionsById += posts.reduce(
          (prev, { impression_count, est_impression_count }) =>
            prev + (impression_count ?? est_impression_count),
          0
        );

        if (influencerData.tiktokPosts > maxPosts)
          maxPosts = influencerData.tiktokPosts;
      } else if (deliverable.network === 'facebook') {
        const posts = deliverable.posts.filter(({ published_at }) =>
          moment(published_at).isBetween(timeFrame.start, timeFrame.end)
        );

        influencerData.facebookPosts += posts.length;
        influencerData.totalEngagements += posts.reduce(
          (prev, { comments_count, likes_count, shares_count }) =>
            prev + comments_count + likes_count + shares_count,
          0
        );
        influencerData.totalImpressionsById += posts.reduce(
          (prev, { impression_count, est_impression_count }) =>
            prev + (impression_count ?? est_impression_count),
          0
        );

        if (influencerData.facebookPosts > maxPosts)
          maxPosts = influencerData.facebookPosts;
      }

      prev[deliverable.campaign_influencer_id] = influencerData;

      return prev;
    }, {});

  const sortedInfluencers = _cloneDeep(campaign.campaign_influencers).sort(
    ({ id: idA }, { id: idB }) => {
      const { totalEngagements: totalEngagementsA } = byInfluencerId[idA] || {
        totalEngagements: 0,
      };

      const { totalEngagements: totalEngagementsB } = byInfluencerId[idB] || {
        totalEngagements: 0,
      };

      return totalEngagementsB - totalEngagementsA;
    }
  );

  const influencers = sortedInfluencers.map((influencer) => {
    const influencerData = byInfluencerId[influencer.id] || {
      twitterPosts: 0,
      instagramPosts: 0,
      tiktokPosts: 0,
      facebookPosts: 0,
      totalEngagements: 0,
      totalImpressionsById: 0,
    };

    const { totalEngagements: maxEngagements } = byInfluencerId[
      sortedInfluencers[0].id
    ] || {
      totalEngagements: 1,
    };
    const { totalImpressionsById: maxImpressionsById } = byInfluencerId[
      sortedInfluencers[0].id
    ] || {
      totalImpressionsById: 1,
    };

    influencer.socialPostsData = {
      ...influencerData,
      twitterPostsBar: influencerData.twitterPosts / maxPosts,
      instagramPostsBar: influencerData.instagramPosts / maxPosts,
      tiktokPostsBar: influencerData.tiktokPosts / maxPosts,
      facebookPostsBar: influencerData.facebookPosts / maxPosts,
      totalEngagementsBar: influencerData.totalEngagements / maxEngagements,
      totalImpressionsByIdBar:
        influencerData.totalImpressionsById / maxImpressionsById,
    };

    return influencer;
  });

  return {
    twitterPosts,
    instagramPosts,
    tiktokPosts,
    facebookPosts,
    totalMentions,
    twitterMentions,
    instagramMentions,
    tiktokMentions,
    facebookMentions,
    totalEngagements,
    sentiment,
    influencers,
    totalImpressions,
    impressionsEstimated,
    totalEMV,
    creators: Object.keys(byInfluencerId).length,
  };
};
