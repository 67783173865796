import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { FieldArray, Form, withFormik } from 'formik';
import _cloneDeep from 'lodash/cloneDeep';
import React, { createContext, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { array, object, string } from 'yup';

import * as regex from '../../../common/constants/regex';
import { Dialog } from '../../../common/containers';
import { getUserOrganizationRestrictions } from '../../../common/containers/Account/selectors';
import FileInput from '../../../components/FileInput';
import FormikMuiTextField from '../../../components/FormikMuiTextField';
import ImagePreview from '../../../components/ImagePreview';
import LoadingButton from '../../../components/LoadingButton';
import SideLine from '../../../components/Sideline';
import { brandCreationFormSubmitted } from '../../../store/events';
import { getTrialAccount } from '../../../store/models/user/user.selectors';
import getImageSize from '../../../utils/getImageSize';
import readFileAsDataURL from '../../../utils/readFileAsDataURL';
import ShoutoutsV2 from '../../CampaignWizard/ShoutoutsV2/ShoutoutsV2';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '24px',
    fontWeight: 'bold',
    paddingLeft: 0,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 16,
  },
  bannerPadding: {
    marginTop: 12,
    paddingTop: 12,
  },
  trialDisabled: {
    padding: '4px',
    width: '100%',
    pointerEvents: 'none',
    backgroundColor: '#ccc',
  },
  chipsContainer: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.6),
    maxWidth: 340,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginRight: theme.spacing(1),
  },
  commaSplit: {
    paddingLeft: '8px',
  },
  description: {
    height: 72,
  },
  brandNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
  h2Margin: {
    margin: '40px 0 15px 0',
  },
  h2MarginBottom: {
    marginBottom: '15px',
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: 'solid 1px #ddd',
  },
  noneBorderContainer: {
    borderRight: 'none',
    borderLeft: 'none',
    borderBottom: 'none',
    borderRadius: 'unset',
  },
}));

export const CampaignWizardContext = createContext({});
const mapPropsToValues = () => {
  const values = {
    brandName: '',
    createBrandName: '',
    shoutoutContent: '',
    shoutouts: [],
    socialMediaMentionContent: [],
    createSocialMediaMentionContent: '',
    links: [],
    newLink: '',
    bannerImages: [
      {
        bannerFile: undefined,
        bannerUrl: '',
        bannerUrlTemp: '',
      },
    ],
    imageUrl: '',
    kpiKeywordMentionList: [],
    createKpiKeywordMentionList: '',
  };

  return values;
};
const mapStateToProps = (state, ownProps) => ({
  trialAccount: getTrialAccount(state),
  organizationRestrictions: getUserOrganizationRestrictions(state),
  account: state.account,
});

const validateBrand = async (values) => {
  const { bannerImages } = values;
  let error = {};

  error.bannerImages = await Promise.all(
    bannerImages.map(async (banner) => {
      if ((!banner.bannerUrl && !banner.bannerFile) || banner.allowSmallSize)
        return null;
      const { width, height } = await getImageSize(
        banner.bannerUrl && banner.bannerUrl.length > 0
          ? banner.bannerUrl
          : banner.bannerFile
      );

      if (width < 60 || height < 60)
        return 'Image has to be at least 60x60 pixels.';

      return null;
    })
  );
  if (error.bannerImages.every((e) => e === null)) delete error.bannerImages;

  return error;
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, null),
  withFormik({
    mapPropsToValues,
    validationSchema: object().shape({
      brandName: string().required('Choose a name for your campaign'),
      newLink: string().matches(regex.URL, 'Not a valid url'),
      shoutoutContent: array(),
      createShoutoutContent: string(),
      bannerImages: array(),
    }),
    handleSubmit: (unclonedValues, bag) => {
      const { dispatch, isWizard, wizard, brands, onClose, setNewWizardBrand } =
        bag.props;
      if (
        brands[0].filter((brand) => brand.name === unclonedValues.brandName)
          .length
      ) {
        Dialog.show({
          caption: 'Invalid Brand Name',
          message: `The Brand name: ${unclonedValues.brandName} already exist, please use another one.`,
          buttons: ['Got it'],
        });
      } else {
        const values = _cloneDeep(unclonedValues);
        dispatch(brandCreationFormSubmitted({ values }));
        isWizard(!wizard);
        setNewWizardBrand(values.brandName);
        onClose();
      }
    },
    validateOnChange: true,
    validate: validateBrand,
  })
);

function MyBrandsWizard(props) {
  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    organizationRestrictions,
    trialAccount,
    wizard,
    isWizard,
    isWizardModal,
    onClose,
  } = props;

  const {
    ui_disable_shoutouts,
    ui_disable_banners,
    ui_banner_limit_per_campaign,
  } = organizationRestrictions;
  const [duplicate, isDuplicate] = useState({
    voice: false,
    links: false,
    chat: false,
    social: false,
  });
  const classes = useStyles();
  const [wrongFileType, setWrongFileType] = useState(false);

  function handleFileChange(name) {
    return async function (file) {
      setFieldValue(name, await readFileAsDataURL(file));
    };
  }

  function renderBrandName() {
    return (
      <Box className={classes.brandNameContainer}>
        <Typography variant="h2" className={classes.h2MarginBottom}>
          Brand name
        </Typography>
        <TextField
          onChange={(e) => setFieldValue('brandName', e.target.value)}
          variant="outlined"
          inputProps={{ style: { fontSize: 18 }, 'data-id': 'brandName-new' }}
          className={classes.input}
        />
        <Typography variant="h2" className={classes.h2Margin}>
          Media
        </Typography>
      </Box>
    );
  }
  function renderShoutout() {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          className={clsx({
            [classes.trialDisabled]: trialAccount || ui_disable_shoutouts,
          })}
        >
          <Typography className={classes.label}>Voice mentions</Typography>
          <Typography className={classes.description}>
            Track the number of times a certain keyword or keyphrase was
            mentioned in the videos streamed during the campaign
          </Typography>
          <Grid
            item
            style={{ marginTop: 16 }}
            className={clsx({
              [classes.trialDisabled]: trialAccount || ui_disable_shoutouts,
            })}
          >
            <ShoutoutsV2
              disabled={trialAccount || ui_disable_shoutouts}
              id="new"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderSocialMediaMention() {
    const onAdd = (arrayHelpers) => {
      const content = values.createSocialMediaMentionContent.split(',');
      content.forEach((el, index) => {
        content[index] = el.trim().toLowerCase();
      });
      new Set(content).forEach((newContent) => {
        if (!newContent) return;
        if (!values.socialMediaMentionContent.includes(newContent)) {
          isDuplicate({ ...duplicate, social: false });
          arrayHelpers.push(newContent);
          setFieldValue('createSocialMediaMentionContent', '');
        } else {
          setFieldValue('createSocialMediaMentionContent', newContent);
          isDuplicate({ ...duplicate, social: true });
        }
      });
    };
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item className={clsx({ [classes.trialDisabled]: trialAccount })}>
          <Typography className={classes.label}>
            Social posts mentions
          </Typography>
          <Typography>
            Track the posts from Twitter, Instagram, and TikTok where the
            keyword or keyphrase was mentioned
          </Typography>
        </Grid>

        <FieldArray
          name="socialMediaMentionContent"
          render={(arrayHelpers) => (
            <>
              <Grid
                item
                className={clsx({
                  [classes.trialDisabled]: trialAccount,
                })}
              >
                <FormLabel className={classes.formLabelStyle}>
                  Content
                </FormLabel>
                <div className={classes.inputContainer}>
                  <FormikMuiTextField
                    fullWidth
                    placeholder="Add keyword"
                    variant="outlined"
                    onChange={(e) => {
                      setFieldValue(
                        'createSocialMediaMentionContent',
                        e.target.value
                      );
                      isDuplicate({ ...duplicate, social: false });
                    }}
                    value={values.createSocialMediaMentionContent}
                    name="createSocialMediaMentionContent"
                    className={classes.input}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          onAdd(arrayHelpers);
                          setFieldValue('socialMediaMentionChecked', true);
                        }
                      },
                      'data-id': 'input-sm-new',
                    }}
                  />
                  <Button
                    data-id="button-sm-new"
                    size="large"
                    variant="outlined"
                    color="primary"
                    style={{ padding: 9.5 }}
                    disabled={!values.createSocialMediaMentionContent.trim()}
                    onClick={() => {
                      onAdd(arrayHelpers);
                      setFieldValue('socialMediaMentionChecked', true);
                    }}
                  >
                    Add
                  </Button>
                </div>
                {duplicate.social && (
                  <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                    "{values.createSocialMediaMentionContent}" is already added
                  </p>
                )}
                <CardContent component="ul" className={classes.chipsContainer}>
                  {values.socialMediaMentionContent.map((content, index) => (
                    <li key={index} data-id={`chip-sm-new-${index}`}>
                      <Chip
                        label={content}
                        variant="outlined"
                        className={classes.chip}
                        onDelete={() => {
                          arrayHelpers.remove(index);
                          isDuplicate({ ...duplicate, social: false });
                        }}
                      />
                    </li>
                  ))}
                </CardContent>
              </Grid>
            </>
          )}
        />
      </Grid>
    );
  }

  function renderBanner() {
    const bannerLimitReached =
      ui_banner_limit_per_campaign &&
      values.bannerImages.filter((e) => !!e.bannerUrl || !!e.bannerFile)
        .length >= ui_banner_limit_per_campaign;
    const disableAddMoreBanners = ui_disable_banners || bannerLimitReached;
    return (
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          className={clsx({ [classes.trialDisabled]: disableAddMoreBanners })}
        >
          <Typography className={classes.label}>Image tracking</Typography>
          <Typography className={classes.description}>
            Track the banner presence on-screen during the videos live-streamed
            or uploaded. Simply add the URL or upload the image of the banner
            you need to track
          </Typography>
        </Grid>
        <FieldArray
          name="bannerImages"
          className={clsx({ [classes.trialDisabled]: disableAddMoreBanners })}
          render={(arrayHelpers) => (
            <>
              {values.bannerImages.map((entry, index) => (
                <Grid
                  item
                  container
                  direction="column"
                  key={index}
                  spacing={1}
                  className={index !== 0 ? classes.bannerPadding : null}
                  data-id={`preview-banner-new-${index}`}
                >
                  {entry.bannerFile && (
                    <Grid item>
                      <ImagePreview
                        src={entry.bannerFile}
                        onDelete={() => {
                          arrayHelpers.remove(index);
                          if (values.bannerImages.length === 2) {
                            setFieldValue('bannerChecked', false);
                          }
                        }}
                        alt="user uploaded banner deliverable"
                      />
                      {errors &&
                      errors.bannerImages &&
                      errors.bannerImages[index] ? (
                        <p style={{ color: 'red' }}>
                          {errors.bannerImages[index]}
                        </p>
                      ) : null}
                    </Grid>
                  )}
                  {entry.bannerUrl && (
                    <Grid item>
                      <ImagePreview
                        src={entry.bannerUrl}
                        onDelete={() => {
                          arrayHelpers.remove(index);
                          if (values.bannerImages.length === 2) {
                            setFieldValue('bannerChecked', false);
                          }
                        }}
                        alt="user uploaded banner deliverable"
                      />
                      {errors &&
                      errors.bannerImages &&
                      errors.bannerImages[index] ? (
                        <p style={{ color: 'red' }}>
                          {errors.bannerImages[index]}
                        </p>
                      ) : null}
                    </Grid>
                  )}
                  {!entry.bannerFile &&
                    !entry.bannerUrl &&
                    !disableAddMoreBanners && (
                      <>
                        <Grid item>
                          <FormLabel
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Image URL
                            <Tooltip
                              placement="top"
                              title="For best results please upload the exact same image that will be displayed during the streams. The image has to be at least 60x60 pixels."
                            >
                              <IconButton size="small">
                                <InfoOutlinedIcon
                                  style={{ width: '0.65em', height: '0.65em' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </FormLabel>
                          <Grid container justify="space-between">
                            <Grid item style={{ flex: 1 }}>
                              <TextField
                                fullWidth
                                placeholder="Enter the URL of the banner image"
                                variant="outlined"
                                name={`bannerImages[${index}].bannerUrlTemp`}
                                onChange={(event) => {
                                  handleChange(event);
                                  setWrongFileType(
                                    !/(jpg|jpeg|png|bmp)/.test(
                                      event.target.value
                                    )
                                  );
                                }}
                                inputProps={{
                                  'data-id': 'input-banners-new',
                                }}
                                value={entry.bannerUrlTemp}
                              />
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                              <Button
                                data-id="button-banners-new"
                                size="large"
                                variant="outlined"
                                color="primary"
                                style={{ padding: 9.5 }}
                                disabled={!entry.bannerUrlTemp || wrongFileType}
                                onClick={() => {
                                  if (
                                    !/(jpg|jpeg|png|bmp)/.test(wrongFileType)
                                  ) {
                                    arrayHelpers.push({
                                      bannerFile: undefined,
                                      bannerUrl: '',
                                      bannerUrlTemp: '',
                                    });
                                    setFieldValue('bannerChecked', true);
                                    setFieldValue(
                                      `bannerImages[${index}].bannerUrl`,
                                      entry.bannerUrlTemp
                                    );
                                    setWrongFileType(false);
                                  }
                                }}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <SideLine inset>
                            <Box fontSize="1rem">or</Box>
                          </SideLine>
                        </Grid>
                        <Grid item>
                          <FormLabel style={{ width: '100%' }}>
                            <FileInput
                              setWrongFileType={setWrongFileType}
                              hidden
                              accept="image/*"
                              onChange={(...args) => {
                                handleFileChange(
                                  `bannerImages[${index}].bannerFile`
                                )(...args);
                                arrayHelpers.push({
                                  bannerFile: undefined,
                                  bannerUrl: '',
                                  bannerUrlTemp: '',
                                });
                                setFieldValue('bannerChecked', true);
                              }}
                            />
                            {wrongFileType && (
                              <p style={{ color: 'red' }}>
                                Image format must be png, jpg or bmp
                              </p>
                            )}
                            <Button
                              fullWidth
                              size="large"
                              variant="outlined"
                              color="primary"
                              component="span"
                              style={{ fontSize: 12 }}
                            >
                              select an image from your computer
                            </Button>
                          </FormLabel>
                        </Grid>
                      </>
                    )}
                </Grid>
              ))}
            </>
          )}
        />
      </Grid>
    );
  }
  function renderLinkSharing() {
    const onAdd = (arrayHelpers) => {
      if (!(values.newLink && !errors.newLink)) return;
      if (!values.links.includes(values.newLink)) {
        isDuplicate({ ...duplicate, links: false });
        arrayHelpers.push(values.newLink.trim());
        setFieldValue('newLink', '');
      } else {
        isDuplicate({ ...duplicate, links: true });
      }
    };
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography className={classes.label}>Link sharing</Typography>
          <Typography className={classes.description}>
            Track the number of times a URL was shared in the live chat during
            the campaign and get to know the users who shared it
          </Typography>
        </Grid>
        <FieldArray
          name="links"
          render={(arrayHelpers) => (
            <>
              <Grid
                item
                className={clsx({
                  [classes.trialDisabled]: trialAccount,
                })}
              >
                <FormLabel className={classes.formLabelStyle}>Links</FormLabel>
                <div className={classes.inputContainer}>
                  <FormikMuiTextField
                    fullWidth
                    placeholder="Add link"
                    variant="outlined"
                    onChange={(e) => {
                      setFieldValue('newLink', e.target.value);
                      isDuplicate({ ...duplicate, links: false });
                    }}
                    value={values.newLink}
                    name="newLink"
                    className={classes.input}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          onAdd(arrayHelpers);
                          setFieldValue('linkSharingChecked', true);
                        }
                      },
                      'data-id': 'input-links-new',
                    }}
                  />
                  <Button
                    data-id="button-links-new"
                    size="large"
                    variant="outlined"
                    color="primary"
                    style={{ padding: 9.5 }}
                    disabled={!values.newLink || !!errors.newLink}
                    onClick={() => {
                      onAdd(arrayHelpers);
                      setFieldValue('linkSharingChecked', true);
                    }}
                  >
                    Add
                  </Button>
                </div>
                {duplicate.links && (
                  <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                    "{values.newLink}" is already added
                  </p>
                )}
                <CardContent component="ul" className={classes.chipsContainer}>
                  {values.links.map((keyword, index) => (
                    <li key={index} data-id={`chip-links-new-${index}`}>
                      <Chip
                        label={keyword}
                        variant="outlined"
                        className={classes.chip}
                        onDelete={() => {
                          if (values.links.length === 1) {
                            setFieldValue('linkSharingChecked', false);
                          }
                          arrayHelpers.remove(index);
                          keyword === values.newLink &&
                            isDuplicate({ ...duplicate, links: false });
                        }}
                        classes={{ label: classes.chipLabel }}
                        title={keyword}
                      />
                    </li>
                  ))}
                </CardContent>
              </Grid>
            </>
          )}
        />
      </Grid>
    );
  }
  function renderKeywordMentions() {
    const onAdd = (arrayHelpers) => {
      const content = values.createKpiKeywordMentionList.split(',');
      content.forEach((el, index) => {
        content[index] = el.trim().toLowerCase();
      });
      new Set(content).forEach((newContent) => {
        if (!newContent) return;
        if (!values.kpiKeywordMentionList.includes(newContent)) {
          isDuplicate({ ...duplicate, chat: false });
          arrayHelpers.push(newContent);
          setFieldValue('createKpiKeywordMentionList', '');
        } else {
          setFieldValue('createKpiKeywordMentionList', newContent);
          isDuplicate({ ...duplicate, chat: true });
        }
      });
    };
    return (
      <>
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            className={clsx({ [classes.trialDisabled]: trialAccount })}
          >
            <Typography className={classes.label}>
              Chat/comments mentions
            </Typography>
            <Typography className={classes.description}>
              Monitor the audience mentions of a certain keyword on
              Twitch/YouTube live chat and YouTube comments.
            </Typography>
          </Grid>
          <FieldArray
            name="kpiKeywordMentionList"
            render={(arrayHelpers) => (
              <>
                <Grid
                  item
                  className={clsx({
                    [classes.trialDisabled]: trialAccount,
                  })}
                >
                  <FormLabel className={classes.formLabelStyle}>
                    Content
                  </FormLabel>
                  <div className={classes.inputContainer}>
                    <FormikMuiTextField
                      fullWidth
                      placeholder="Add keyword"
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue(
                          'createKpiKeywordMentionList',
                          e.target.value
                        );
                        isDuplicate({ ...duplicate, chat: false });
                      }}
                      value={values.createKpiKeywordMentionList}
                      name="createKpiKeywordMentionList"
                      className={classes.input}
                      inputProps={{
                        onKeyDown: (e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                            onAdd(arrayHelpers);
                            setFieldValue('kpiKeywordMentionChecked', true);
                          }
                        },
                        'data-id': 'input-cm-new',
                      }}
                    />
                    <Button
                      data-id="button-cm-new"
                      size="large"
                      variant="outlined"
                      color="primary"
                      style={{ padding: 9.5 }}
                      disabled={!values.createKpiKeywordMentionList.trim()}
                      onClick={() => {
                        onAdd(arrayHelpers);
                        setFieldValue('kpiKeywordMentionChecked', true);
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  {duplicate.chat && (
                    <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                      "{values.createKpiKeywordMentionList}" is already added
                    </p>
                  )}
                  <CardContent
                    component="ul"
                    className={classes.chipsContainer}
                  >
                    {values.kpiKeywordMentionList.map((content, index) => (
                      <li key={index} data-id={`chip-cm-new-${index}`}>
                        <Chip
                          label={content}
                          variant="outlined"
                          className={classes.chip}
                          onDelete={() => {
                            arrayHelpers.remove(index);
                            isDuplicate({ ...duplicate, chat: false });
                          }}
                        />
                      </li>
                    ))}
                  </CardContent>
                </Grid>
              </>
            )}
          />
        </Grid>
      </>
    );
  }

  return (
    <Card className={classes.noneBorderContainer}>
      <CardContent>
        <Form style={{ width: '100%' }}>
          <div>{renderBrandName()}</div>
          <div className={classes.grid}>
            {renderShoutout()}
            {renderBanner()}
            {renderLinkSharing()}
            {renderSocialMediaMention()}
            {renderKeywordMentions()}
          </div>
          <div className={classes.containerButton}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              color="primary"
              style={{ margin: '25px 0px' }}
              onClick={() => (isWizardModal ? onClose() : isWizard(!wizard))}
            >
              cancel
            </Button>
            <LoadingButton
              key="submit"
              type="submit"
              variant="contained"
              color="primary"
              style={{ margin: '25px 0px' }}
            >
              SAVE
            </LoadingButton>
          </div>
        </Form>
      </CardContent>
    </Card>
  );
}

export default enhance(MyBrandsWizard);
