import React from 'react';

const finalItemStyle = {
  display: 'block',
  paddingTop: 4,
  paddingBottom: 4,
  color: '#000',
};

const finalStyle = {
  margin: 0,
  padding: 10,
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  whiteSpace: 'nowrap',
};

const BarchartDefaultPercantageTooltipContent = ({
  payload,
  label,
  active,
}) => (
  <div className="recharts-default-tooltip" style={finalStyle}>
    <p className="recharts-tooltip-label" style={{ margin: 0 }}>
      <li className="recharts-tooltip-item" style={finalItemStyle}>
        <span className="recharts-tooltip-item-name">{label}</span>
        <span className="recharts-tooltip-item-separator">: </span>
        <span
          className="recharts-tooltip-item-value"
          style={{ color: '#2196f3' }}
        >
          {payload && payload[0] && payload[0].value
            ? Math.round(payload[0].value)
            : ''}{' '}
          %
        </span>
      </li>
    </p>
  </div>
);

export default BarchartDefaultPercantageTooltipContent;
