import { Icon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';

import {
  selectShoutoutsFilterMediaTracking,
  selectShoutoutsFilterSponsorship,
  setShoutoutsFilterMediaTracking,
  setShoutoutsFilterSponsorship,
} from './utils/CampaignFiltersStore';

function GrayArrowDropDownOutlinedIcon(props) {
  return (
    <Icon
      style={{ cursor: 'pointer' }}
      component={ArrowDropDownOutlinedIcon}
      color="action"
    ></Icon>
  );
}

const getBackgroundColor = (props) => {
  if (props.isSelected) {
    return '#DBDBDB';
  }

  if (props.isFocused) {
    return '#EBEBEB';
  }

  return 'white';
};

type Props = {
  campaign: any;
  tab: 'sponsorship' | 'deliverables' | unknown;
  brandFilter?: 'all' | number;
};

export const CampaignShoutoutsFilter = ({
  campaign,
  tab,
  brandFilter,
}: Props) => {
  const dispatch = useDispatch();
  const shoutoutsFilterMediaTracking = useSelector(
    selectShoutoutsFilterMediaTracking
  );
  const shoutoutsFilterSponsorship = useSelector(
    selectShoutoutsFilterSponsorship
  );

  const shoutoutsFilter =
    tab === 'sponsorship'
      ? shoutoutsFilterSponsorship
      : shoutoutsFilterMediaTracking;

  const onChange = (value) => {
    if (tab === 'deliverables') {
      dispatch(setShoutoutsFilterMediaTracking(value));
    } else if (tab === 'sponsorship') {
      dispatch(setShoutoutsFilterSponsorship(value));
    }
  };

  const myTheme = useTheme();
  const defaultValue = { value: 'all', label: 'All' };

  const keywords = [
    ...campaign.shoutout_deliverables_v2,
    ...campaign.shoutout_deliverables,
  ]
    .filter((item) => {
      if (tab === 'deliverables') {
        return item.campaign_brand_id === null;
      } else {
        if (brandFilter === 'all' || !brandFilter) {
          return item.campaign_brand_id !== null;
        } else {
          return item.campaign_brand_id === brandFilter;
        }
      }
    })
    .map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const allValues = [defaultValue, ...keywords];
  const currentValue = allValues.find((item) => item.value === shoutoutsFilter);

  return (
    <>
      <span style={{ fontSize: 16, marginRight: 8 }}>Mention: </span>
      <ReactSelect
        id="keyword-filter"
        // maxMenuHeight={480}
        value={currentValue}
        onChange={(item) => {
          onChange(item.value);
        }}
        options={allValues}
        components={{
          DropdownIndicator: GrayArrowDropDownOutlinedIcon,
          IndicatorSeparator: () => null,
        }}
        menuPosition="fixed"
        menuPlacement="auto"
        placeholder="Filter by keyword"
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: myTheme.palette.primary.main,
            primary25: myTheme.palette.action.selected,
          },
        })}
        styles={{
          control: (styles) => ({
            ...styles,
            height: 48,
            fontSize: '1.2rem',
            cursor: 'pointer',
            ':hover': {
              borderColor: myTheme.palette.text.primary,
            },
          }),
          option: (styles, state) => ({
            ...styles,
            padding: '12px',
            width: '100%',
            fontSize: '1.2rem',
            backgroundColor: getBackgroundColor(state),
            color: 'black',
            cursor: 'pointer',
          }),
          menu: (styles) => ({
            ...styles,
          }),
          input: (styles) => ({
            ...styles,
            height: 40,
            cursor: 'text',
            fontSize: '1.2rem',
            paddingTop: '6px',
          }),
          group: (styles) => ({
            ...styles,
            borderBottom: '#C8C8C8 1px solid',
          }),
        }}
        name="shoutout-filter"
      />
    </>
  );
};
