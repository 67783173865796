import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { State } from '../../../store/types';

type FilterValue = 'all' | number;

export type CampaignFiltersState = {
  shoutoutsFilterMediaTracking: FilterValue;
  shoutoutsFilterSponsorship: FilterValue;
};

const initialState = {
  shoutoutsFilterMediaTracking: 'all',
  shoutoutsFilterSponsorship: 'all',
} as CampaignFiltersState;

export const SLICE_NAME = 'campaignFilters';

const campaignFiltersSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setShoutoutsFilterMediaTracking(state, action: PayloadAction<FilterValue>) {
      state.shoutoutsFilterMediaTracking = action.payload;
    },

    setShoutoutsFilterSponsorship(state, action: PayloadAction<FilterValue>) {
      state.shoutoutsFilterSponsorship = action.payload;
    },
  },
});

export const {
  setShoutoutsFilterMediaTracking,
  setShoutoutsFilterSponsorship,
} = campaignFiltersSlice.actions;
export const reducer = campaignFiltersSlice.reducer;

export const selectShoutoutsFilterMediaTracking = (state: State) =>
  state[SLICE_NAME].shoutoutsFilterMediaTracking;

export const selectShoutoutsFilterSponsorship = (state: State) =>
  state[SLICE_NAME].shoutoutsFilterSponsorship;

export const selectCampaignFilters = (state: State) => state[SLICE_NAME];
