import _cloneDeep from 'lodash/cloneDeep';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import queryClient from '../../queryClient';
import {
  CAMPAIGN_SOCIAL_POST_DELETE,
  CAMPAIGN_SOCIAL_POSTS_MENTIONS_DELETE,
} from '../action-types';
import {
  campaignSocialPostDeleteFailed,
  campaignSocialPostDeleteRequested,
  campaignSocialPostDeleteSucceeded,
} from '../models/campaigns/campaigns.actions';

function* deleteSocialPost(action) {
  const { campaignId, postId, ...payload } = action?.payload || {};

  yield put(
    campaignSocialPostDeleteRequested({
      id: postId,
    })
  );

  try {
    yield call(ajax.deleteSocialPost, campaignId, payload);

    yield put(campaignSocialPostDeleteSucceeded(action.payload));
  } catch (e) {
    console.error('Campaign Social Post Delete Error - ', e);
    yield put(
      campaignSocialPostDeleteFailed({
        error:
          (e.bodyJson && e.bodyJson.errors) ||
          'Campaign Social Post Delete Error',
        id: postId,
      })
    );
  }
}

function* deleteSocialPostsMentions(action) {
  const {
    campaignId,
    section,
    twitterIds,
    facebookIds,
    instagramIds,
    tiktokIds,
  } = action?.payload || {};

  try {
    yield call(ajax.deleteSocialPostsMentions, {
      campaignId,
      section,
      twitterIds,
      facebookIds,
      instagramIds,
      tiktokIds,
    });

    // WARN: Update the query data for the campaign social posts mentions.
    // Total hack - but this was implemented like shit in the first place.
    // The data is being fetched using react-query, but "mutations" are done with redux-saga.

    queryClient.setQueryData(
      `campaign-${campaignId}-social-mentions`,
      (oldData) => {
        const newData = _cloneDeep(oldData);

        // Update twitter posts
        if (twitterIds && twitterIds.length) {
          newData.twitter_keyword_mention_deliverables.forEach(
            (deliverable) => {
              deliverable.tweets = deliverable.tweets.filter(
                (post) => !twitterIds.includes(post.tweet_id)
              );
            }
          );
        }

        // Update facebook posts
        if (facebookIds && facebookIds.length) {
          newData.facebook_keyword_mention_deliverables.forEach(
            (deliverable) => {
              deliverable.posts = deliverable.posts.filter(
                (post) => !facebookIds.includes(post.id)
              );
            }
          );
        }

        // Update instagram posts
        if (instagramIds && instagramIds.length) {
          newData.instagram_keyword_mention_deliverables.forEach(
            (deliverable) => {
              deliverable.posts = deliverable.posts.filter(
                (post) => !instagramIds.includes(post.shortcode)
              );
            }
          );
        }

        // Update tiktok posts
        if (tiktokIds && tiktokIds.length) {
          newData.tiktok_keyword_mention_deliverables.forEach((deliverable) => {
            deliverable.posts = deliverable.posts.filter(
              (post) => !tiktokIds.includes(post.id)
            );
          });
        }

        return newData;
      }
    );
  } catch (e) {
    console.error('Campaign Social Posts Mentions Delete Error - ', e);
  }
}

export default function* root() {
  yield takeLatest(CAMPAIGN_SOCIAL_POST_DELETE, deleteSocialPost);
  yield takeLatest(
    CAMPAIGN_SOCIAL_POSTS_MENTIONS_DELETE,
    deleteSocialPostsMentions
  );
}
