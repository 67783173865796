import { call, put, select } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import { actions } from '../../store/actions';
import {
  userRequestedFailed,
  userRequestedSucceeded,
} from '../models/user/user.actions';
import { selectors } from '../selectors';

// const UnauthenticatedError = {
//   status: 403,
//   message: "",
//   object: "UnauthenticatedError"
// }

export default function* () {
  const email = yield select(selectors.getUserEmail);
  const isInfluencerView = yield select(selectors.isInfluencerView);

  if (!email) {
    return null;
  }

  try {
    const backendUser = yield call(ajax.getUserData);

    yield call(ajax.recordUserSession, { action: 'page-refresh' });

    if (!isInfluencerView) {
      yield put(userRequestedSucceeded({ user: backendUser.firebase_data }));
    }

    yield put(actions.userGet({ ...backendUser }));
  } catch (e) {
    if (e.status === 403) {
      console.error('User 403 Error - ', e);
      yield put(actions.signout());
    } else {
      console.error('User Request Error - ', e);
      yield put(
        userRequestedFailed({
          error: (e.bodyJson && e.bodyJson.errors) || 'User Request Error',
        })
      );
    }
  }
}
