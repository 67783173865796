import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { Skeleton } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import clsx from 'clsx';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import AddToWatchlistForm from '../../../common/components/AddToWatchlistForm';
import * as paths from '../../../common/constants/paths';
import Button from '../../../components/Button';
import InfluencerSize from '../../../components/InfluencerSize';
import OrderableTableCell from '../../../components/OrderableTableCell';
import TableCellWithLink from '../../../components/TableCellWithLink';
import TopBar from '../../../components/TopBar';
import TopBarContent from '../../../components/TopBarContent';
import TopBarTitle from '../../../components/TopBarTitle';
import { getInfluencerSize } from '../../../helpers/influencer-sizes';
import {
  myWatchlistMountedTwitch,
  myWatchlistMountedYoutube,
} from '../../../store/events';
import { getWatchlistById } from '../../../store/models/watchlists/watchlists.selectors';
import capitalize from '../../../utils/capitalize';
import cmdClickNewTab from '../../../utils/cmd-click-new-tab';
import isNumber from '../../../utils/is-number';
import DownloadInfluencers from './DownloadInfluencers';
import { TwitchStats, YoutubeStats } from './OverallStats';

export const MyWatchlistContext = React.createContext({});

const INFLUENCERS_PER_PAGE = 20;

const useStyles = makeStyles((theme) => ({
  main: {
    maxHeight: '100%',
    overflow: 'scroll',
  },
  rootNoInfluencers: {
    margin: '82px 16px 0px 16px',
    padding: '48px 0px 48px 0px',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  search: {
    position: 'relative',
    border: '1px solid #333',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '16ch',
    },
  },
  filterButton: {
    width: '120px',
    height: '45px',
  },
  filter: {
    display: 'table',
    margin: '0 auto',
    padding: '12px',
  },
  noInfluencersH1: {
    padding: '24px',
    display: 'block',
  },
  notFetched: {
    cursor: 'auto',
  },
  root: {
    margin: '90px 16px 0px 16px',
    overflowX: 'auto',
    minWidth: 'calc(100% - 32px)',
    display: 'inline-block',
    [theme.breakpoints.down('md')]: {
      margin: '200px 16px 0px 16px',
    },
  },
  table: {
    minWidth: 650,
  },
  row: {
    cursor: 'pointer',
    transition: 'none',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
}));

export default function MyWatchlist(props) {
  const {
    dispatch,
    twitchInfluencers,
    twitchLoaded,
    youtubeInfluencers,
    youtubeLoaded,
    removeFromWatchlist,
  } = props;

  const { watchlistId, network: networkParam } = useParams();
  const history = useHistory();

  const network = networkParam || 'twitch';

  const setNetwork = (network) =>
    history.push(`${paths.MY_WATCHLIST}/${watchlistId}/${network}`);

  const watchlist = useSelector(getWatchlistById(parseInt(watchlistId)));

  const watchlistName = watchlist ? watchlist.name : null;

  if (watchlistName) document.title = watchlistName;

  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedPage, setSelectedPage] = React.useState(1);

  const [orderByTwitch, setOrderByTwitch] = useState('login');
  const [orderTwitch, setOrderTwitch] = useState('asc');

  const [orderByYoutube, setOrderByYoutube] = useState('display_name');
  const [orderYoutube, setOrderYoutube] = useState('asc');

  const loaded = network === 'twitch' ? twitchLoaded : youtubeLoaded;
  const influencers =
    network === 'twitch' ? twitchInfluencers : youtubeInfluencers;

  const orderMap = {
    twitch: {
      orderBy: orderByTwitch,
      setOrderBy: setOrderByTwitch,
      order: orderTwitch,
      setOrder: setOrderTwitch,
    },
    youtube: {
      orderBy: orderByYoutube,
      setOrderBy: setOrderByYoutube,
      order: orderYoutube,
      setOrder: setOrderYoutube,
    },
  };

  const context = {
    ...orderMap[network],
    handleOrderBySelection,
  };

  useEffect(() => {
    if (network === 'twitch') dispatch(myWatchlistMountedTwitch(watchlistId));
    else dispatch(myWatchlistMountedYoutube(watchlistId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, watchlistId]);

  function onDeleteWatchlistClick(event, influencer_id) {
    event.preventDefault();
    event.stopPropagation();

    dispatch(removeFromWatchlist(influencer_id, parseInt(watchlistId, 10)));
  }

  function handleOrderBySelection(orderByValue) {
    return function () {
      const { order, orderBy, setOrder, setOrderBy } = orderMap[network];

      if (orderBy === orderByValue) {
        setOrder(order === 'desc' ? 'asc' : 'desc');
      } else {
        setOrderBy(orderByValue);
        setOrder('desc');
      }
    };
  }

  function renderInfluencerTwitch(influencer) {
    const {
      id,
      profile_image_url,
      display_name,
      login,
      network,
      growth_score,
      followers,
      view_rate_30,
      views_days_30,
      streamer_id,
      avg_viewers_days_30,
      stream_time_days_30,
      ama_days_30,
      removed_from_network,
    } = influencer;

    const influencerLink = `${paths.INFLUENCER}/${encodeURI(login)}/${network}`;

    const size = getInfluencerSize(followers).toUpperCase();
    const fallbackImage = '../../fallback/img.png';

    return (
      <TableRow
        key={id}
        data-id={`influencer-row-${id}`}
        className={!streamer_id ? classes.notFetched : classes.row}
        onClick={() => (streamer_id ? history.push(influencerLink) : null)}
      >
        <TableCellWithLink
          noLink={!streamer_id}
          link={influencerLink}
          className="influencer-name-avatar"
          id={`influencer-name-${id}`}
        >
          <Avatar
            src={profile_image_url ? profile_image_url : fallbackImage}
            alt={display_name?.toUpperCase()}
          />
          <div
            className="influencer-name"
            onClick={(event) =>
              streamer_id ? cmdClickNewTab(event, influencerLink) : null
            }
          >
            {removed_from_network && (
              <Box
                px={1}
                py={0.5}
                bgcolor="#e03d23"
                color="white"
                style={{ marginRight: 4 }}
                fontSize="0.7rem"
                fontWeight="bold"
                display="inline-block"
                className="influencer-size"
              >
                TWITCH ACCOUNT NON-EXISTENT
              </Box>
            )}
            {!streamer_id && (
              <Box
                px={1}
                py={0.5}
                bgcolor="#87977f"
                color="white"
                style={{ marginRight: 4 }}
                fontSize="0.7rem"
                fontWeight="bold"
                display="inline-block"
                className="influencer-size"
              >
                PROCESSING
              </Box>
            )}
            {streamer_id && (
              <InfluencerSize sizeOnly>{`${size} INFLUENCER`}</InfluencerSize>
            )}
            <Typography
              variant="body2"
              className={!streamer_id ? classes.notFetched : 'hover-row'}
            >
              {display_name || login}
            </Typography>
            <span
              onClick={(event) => onDeleteWatchlistClick(event, id)}
              className="watchlist-remove"
            >
              Remove
            </span>
          </div>
        </TableCellWithLink>
        <TableCellWithLink
          id={`influencer-growth-score-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(growth_score)
            ? `${numeral(growth_score).format('0.[0]')}%`
            : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          id={`influencer-followers-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(followers) ? numeral(followers).format('0.[0]a') : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
          id={`influencer-viewrate-${id}`}
        >
          {isNumber(view_rate_30)
            ? `${numeral(view_rate_30).format('0.[0]')}%`
            : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          noLink={!streamer_id}
          alignRight
          link={influencerLink}
          id={`influencer-avgccv-${id}`}
        >
          {isNumber(avg_viewers_days_30)
            ? numeral(avg_viewers_days_30).format('0.[0]a')
            : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          noLink={!streamer_id}
          alignRight
          link={influencerLink}
          id={`influencer-streamtime-${id}`}
        >
          {isNumber(stream_time_days_30)
            ? `${numeral(stream_time_days_30).format('0.[0]')}h`
            : '-'}
        </TableCellWithLink>
        <TableCellWithLink alignRight link={influencerLink} id="influencer-ama">
          {isNumber(ama_days_30) ? numeral(ama_days_30).format('0.[0]a') : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
          id={`influencer-views-${id}`}
        >
          {isNumber(views_days_30)
            ? numeral(views_days_30).format('0.[0]a')
            : '-'}
        </TableCellWithLink>
      </TableRow>
    );
  }

  function renderInfluencerYoutube(influencer) {
    const {
      id,
      profile_image_url,
      display_name,
      login,
      network,
      likes_90,
      dislikes_90,
      subscriber_count,
      like_dislike,
      view_count_90,
      yt_engagement_90,
      streamer_id,
      video_count,
      avg_view_count_per_video,
      removed_from_network,
    } = influencer;

    const influencerLink = `${paths.INFLUENCER}/${encodeURI(
      display_name || login
    )}/${network}`;

    const size = getInfluencerSize(subscriber_count).toUpperCase();
    const fallbackImage = '../../fallback/img.png';
    return (
      <TableRow
        key={id}
        data-id={`creator-row-${id}`}
        className={!streamer_id ? classes.notFetched : classes.row}
        onClick={() => (streamer_id ? history.push(influencerLink) : null)}
      >
        <TableCellWithLink
          noLink={!streamer_id}
          link={influencerLink}
          className="influencer-name-avatar"
          id={`influencer-name-${id}`}
        >
          <Avatar
            src={profile_image_url ? profile_image_url : fallbackImage}
            alt={display_name?.toUpperCase()}
          />
          <div
            className="influencer-name"
            onClick={(event) =>
              streamer_id ? cmdClickNewTab(event, influencerLink) : null
            }
          >
            {removed_from_network && (
              <Box
                px={1}
                py={0.5}
                bgcolor="#e03d23"
                color="white"
                style={{ marginRight: 4 }}
                fontSize="0.7rem"
                fontWeight="bold"
                display="inline"
                className="influencer-size"
              >
                YOUTUBE ACCOUNT NON-EXISTENT
              </Box>
            )}
            {!streamer_id && (
              <Box
                px={1}
                py={0.5}
                bgcolor="#87977f"
                color="white"
                style={{ marginRight: 4 }}
                fontSize="0.7rem"
                fontWeight="bold"
                display="inline"
                className="influencer-size"
              >
                PROCESSING
              </Box>
            )}
            {streamer_id && (
              <InfluencerSize sizeOnly>{`${size} INFLUENCER`}</InfluencerSize>
            )}
            <Typography
              variant="body2"
              className={!streamer_id ? classes.notFetched : 'hover-row'}
            >
              {display_name || login}
            </Typography>
            <span
              onClick={(event) => onDeleteWatchlistClick(event, id)}
              className="watchlist-remove"
            >
              Remove
            </span>
          </div>
        </TableCellWithLink>
        <TableCellWithLink
          id={`influencer-subscribes-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(subscriber_count)
            ? numeral(subscriber_count).format('0.[0]a')
            : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          id={`influencer-likes-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(likes_90) ? numeral(likes_90).format('0.[0]a') : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          id={`influencer-dislikes-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(dislikes_90) ? numeral(dislikes_90).format('0.[0]a') : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          id={`influencer-like-dislike-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(like_dislike) ? numeral(like_dislike).format('0.[0]') : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          id={`influencer-views-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(view_count_90)
            ? numeral(view_count_90).format('0.[0]a')
            : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          id={`influencer-engagement-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(yt_engagement_90)
            ? numeral(yt_engagement_90).format('0[.][0]%')
            : '-'}
        </TableCellWithLink>
        <TableCellWithLink
          id={`influencer-views-video-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(avg_view_count_per_video)
            ? numeral(avg_view_count_per_video).format('0.[0]a')
            : '-'}
        </TableCellWithLink>

        <TableCellWithLink
          id={`influencer-videos-${id}`}
          noLink={!streamer_id}
          link={influencerLink}
          alignRight
        >
          {isNumber(video_count) ? numeral(video_count).format('0,0') : '-'}
        </TableCellWithLink>
      </TableRow>
    );
  }

  function renderInfluencersTable() {
    const { orderBy, order } = orderMap[network];

    const sortedInfluencers = influencers.sort(
      ({ [orderBy]: sortableA }, { [orderBy]: sortableB }) => {
        if (order === 'asc') {
          return sortableA > sortableB ? 1 : -1;
        } else if (order === 'desc') {
          return sortableB > sortableA ? 1 : -1;
        } else {
          return 0;
        }
      }
    );

    const filteredBySearchTerm = sortedInfluencers.filter(
      ({ display_name, login }) => {
        if (!!searchTerm) {
          return (
            (display_name || login || '')
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) !== -1
          );
        } else {
          return true;
        }
      }
    );

    const paginated = filteredBySearchTerm.slice(
      (selectedPage - 1) * INFLUENCERS_PER_PAGE,
      selectedPage * INFLUENCERS_PER_PAGE
    );

    const nPages = Math.ceil(
      filteredBySearchTerm.length / INFLUENCERS_PER_PAGE
    );

    return (
      <Paper>
        {filteredBySearchTerm.length > INFLUENCERS_PER_PAGE && (
          <Box
            padding={2}
            display="flex"
            alignContent="center"
            justifyContent="center"
          >
            <Pagination
              count={nPages}
              onChange={(_, page) => setSelectedPage(page)}
              page={selectedPage}
              variant="outlined"
              color="primary"
            />
          </Box>
        )}
        {filteredBySearchTerm.length > 0 && (
          <Table className={clsx('watchlist-table', classes.table)}>
            <TableHead>
              {network === 'youtube'
                ? renderTableHeadYoutube()
                : renderTableHeadTwitch()}
            </TableHead>
            <TableBody>
              {paginated.map(
                network === 'youtube'
                  ? renderInfluencerYoutube
                  : renderInfluencerTwitch
              )}
            </TableBody>
          </Table>
        )}
        {sortedInfluencers.length === 0 && (
          <Typography variant="h4" style={{ padding: '36px' }}>
            {`You don't have any ${capitalize(
              network
            )} creators in your watchlist!`}
          </Typography>
        )}
        {filteredBySearchTerm.length === 0 && sortedInfluencers.length > 0 && (
          <Typography variant="h4" style={{ padding: '36px' }}>
            {`No results for your search!`}
          </Typography>
        )}
        {filteredBySearchTerm.length > INFLUENCERS_PER_PAGE && (
          <Box
            padding={2}
            display="flex"
            alignContent="center"
            justifyContent="center"
          >
            <Pagination
              count={nPages}
              onChange={(_, page) => setSelectedPage(page)}
              page={selectedPage}
              variant="outlined"
              color="primary"
            />
          </Box>
        )}
      </Paper>
    );
  }

  function renderTableHeadYoutube() {
    return (
      <TableRow>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="display_name"
        >
          Creator
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="subscriber_count"
          TableCellProps={{ align: 'right' }}
        >
          Subscribers
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="likes_90"
          TableCellProps={{ align: 'right' }}
        >
          Likes (90d)
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="dislikes_90"
          TableCellProps={{ align: 'right' }}
        >
          Dislikes (90d)
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="like_dislike"
          TableCellProps={{ align: 'right' }}
        >
          Like / Dislike (90d)
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="views"
          TableCellProps={{ align: 'right' }}
        >
          Views (90d)
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="yt_engagement_90"
          TableCellProps={{ align: 'right' }}
        >
          Engagement Rate (90d)
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="avg_view_count_per_video"
          TableCellProps={{ align: 'right' }}
        >
          Views / Video (90d)
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="video_count"
          TableCellProps={{ align: 'right' }}
        >
          Videos
        </OrderableTableCell>
      </TableRow>
    );
  }

  function renderTableHeadTwitch() {
    return (
      <TableRow>
        <OrderableTableCell context={MyWatchlistContext} orderByValue="login">
          Creator
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="growth_score"
          TableCellProps={{ align: 'right' }}
        >
          Growth score
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="followers"
          TableCellProps={{ align: 'right' }}
        >
          Followers
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="view_rate_30"
          TableCellProps={{ align: 'right' }}
        >
          View rate
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="avg_viewers_days_30"
          TableCellProps={{
            align: 'right',
            css: { 'white-space': 'nowrap' },
          }}
        >
          Avg. CCV (30d)
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="stream_time_days_30"
          TableCellProps={{
            align: 'right',
            css: { 'white-space': 'nowrap' },
          }}
        >
          Stream Time (30d)
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="ama_days_30"
          TableCellProps={{
            align: 'right',
            css: { 'white-space': 'nowrap' },
          }}
        >
          AMA (30d)
        </OrderableTableCell>
        <OrderableTableCell
          context={MyWatchlistContext}
          orderByValue="views_days_30"
          TableCellProps={{
            align: 'right',
            css: { 'white-space': 'nowrap' },
          }}
        >
          Views (30d)
        </OrderableTableCell>
      </TableRow>
    );
  }

  function renderInfluencersForm() {
    return (
      <Paper className={classes.rootNoInfluencers}>
        <Typography variant="h2" className={classes.noInfluencersH1}>
          You don't have any creators in your watchlist!
        </Typography>
        <AddToWatchlistForm withDialog={false} />
      </Paper>
    );
  }

  function handleNetworkChange(network) {
    setSelectedPage(1);
    setNetwork(network);
  }

  function renderInfluencersStats() {
    if (network === 'youtube')
      return <YoutubeStats influencers={influencers} />;

    return <TwitchStats influencers={influencers} />;
  }

  return (
    <MyWatchlistContext.Provider value={context}>
      <main className={classes.main}>
        <TopBar>
          <TopBarContent>
            <Grid container alignItems="center">
              <Grid item style={{ flex: 1, padding: '10px' }}>
                {watchlistName ? (
                  <TopBarTitle>{watchlistName}</TopBarTitle>
                ) : (
                  <Skeleton width={128} height={32} />
                )}
              </Grid>
              <Grid item style={{ padding: '10px' }}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchTerm}
                    onChange={({ target: { value } }) => setSearchTerm(value)}
                  />
                </div>
              </Grid>
              <div className={classes.actionsContainer}>
                {loaded && influencers.length > 0 && (
                  <Grid item style={{ padding: '10px' }}>
                    <DownloadInfluencers
                      influencers={influencers}
                      network={network}
                      watchlistName={watchlistName}
                    />
                  </Grid>
                )}
                <Grid item style={{ padding: '10px' }}>
                  <Link
                    to={`${paths.INFLUENCER_SEARCH}?search_watchlist=${watchlistId}`}
                  >
                    <Button
                      size="medium"
                      variant="outlined"
                      style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                      color="primary"
                    >
                      <SearchIcon />
                      Advanced search
                    </Button>
                  </Link>
                </Grid>
                <Grid item style={{ padding: '10px' }}>
                  <Link
                    to={`${paths.ADD_TO_WATCHLIST}/${watchlistId}/${network}`}
                  >
                    <Button
                      size="medium"
                      variant="contained"
                      style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                      color="primary"
                    >
                      <AddIcon />
                      Add Creator
                    </Button>
                  </Link>
                </Grid>
              </div>
            </Grid>
          </TopBarContent>
        </TopBar>
        <div className={classes.root}>
          <Grid className={classes.filter}>
            <Button
              style={{ borderRadius: '10px 0px 0px 10px' }}
              className={classes.filterButton}
              color={network === 'twitch' ? 'primary' : 'inherit'}
              variant="contained"
              onClick={() => handleNetworkChange('twitch')}
            >
              Twitch
            </Button>
            <Button
              style={{ borderRadius: '0px 10px 10px 0px' }}
              className={classes.filterButton}
              color={network === 'youtube' ? 'primary' : 'inherit'}
              variant="contained"
              onClick={() => handleNetworkChange('youtube')}
            >
              Youtube
            </Button>
          </Grid>
          {!loaded && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="75vh"
              width="100%"
            >
              <CircularProgress />
            </Box>
          )}
          {loaded && influencers.length > 0 && renderInfluencersStats()}

          {loaded && influencers.length > 0 && renderInfluencersTable()}

          {loaded && !influencers.length && renderInfluencersForm()}
        </div>
      </main>
    </MyWatchlistContext.Provider>
  );
}
