import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import bgImage from '../../../../assets/images/big-blue-bgr-logo.png';
import * as paths from '../../../../common/constants/paths';
import ResetPasswordForm from '../ResetPasswordForm';

const ResetPasswordPage = ({ resetPasswordSubmit, onSubmitFail }) => {
  return (
    <div className="peers ai-s fxw-nw h-100vh">
      <div
        className="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv"
        style={{ backgroundImage: `url(${bgImage})` }}
      />
      <div
        className="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r"
        style={{ minWidth: '320px' }}
      >
        <h4 className="fw-300 c-grey-900 mB-40">Reset your Password</h4>
        <ResetPasswordForm
          onSubmit={resetPasswordSubmit}
          onSubmitFail={onSubmitFail}
        />
        <div className="peer">
          <Link to={paths.PATH_SIGNIN}>Existing user login</Link>
        </div>
      </div>
    </div>
  );
};

ResetPasswordPage.propTypes = {
  resetPasswordSubmit: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
};

export default ResetPasswordPage;
