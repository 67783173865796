import { createAsyncThunk } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash/cloneDeep';
import { createActions } from 'reduxsauce';

import { redoInfluencerSocialLinkCall } from '../../ajax';
import toMentionFromTwitchComment from '../../helpers/to-mention-from-twitch-comment';
import toMentionFromYoutubeComment from '../../helpers/to-mention-from-youtube-comment';
import { showToast } from '../../store/commonActions';

export const STORAGE_PREFIX = 'influencerDetail';

const INITIAL_STATE = {
  dateRangeFilter: 1000 * 60 * 60 * 24 * 365, // default 365
  youtube: undefined,
  twitch: undefined,
  influencer: undefined,
  videoWordcloudData: [],
};

export const INFLUENCER_DATA_RESET = 'INFLUENCER_DATA_RESET';
export const INFLUENCER_DATA_REQUESTED = 'INFLUENCER_DATA_REQUESTED';
export const INFLUENCER_DATA_REQUEST_SUCCEEDED =
  'INFLUENCER_DATA_REQUEST_SUCCEEDED';
export const INFLUENCER_DATA_REQUEST_SUCCEEDED_INTERIM =
  'INFLUENCER_DATA_REQUEST_SUCCEEDED_INTERIM';

export const influencerDataReset = () => ({
  type: INFLUENCER_DATA_RESET,
});

export const influencerDataRequested = (payload) => ({
  payload,
  type: INFLUENCER_DATA_REQUESTED,
  meta: {
    request: {
      type: 'requested',
      name: 'influencer',
    },
  },
});

export const influencerDataRequestSucceded = (payload) => ({
  payload,
  type: INFLUENCER_DATA_REQUEST_SUCCEEDED,
  meta: {
    request: {
      type: 'succeeded',
      name: 'influencer',
    },
  },
});

export const influencerDataRequestSuccededInterim = (payload) => ({
  payload,
  type: INFLUENCER_DATA_REQUEST_SUCCEEDED_INTERIM,
  meta: {},
});

export const { Types, Creators } = createActions(
  {
    requestInfluencerDetailsData: ['payload'],
    influencerDetailsDataReceived: ['payload'],
    dateRangeFilterChanged: ['payload'],
    addToWatchlist: ['watchlistId'],
    addToWatchlistSuccess: (watchlistId, influencerName, watchlistName) => ({
      type: `${STORAGE_PREFIX}/ADD_TO_WATCHLIST_SUCCESS`,
      watchlistId,
      meta: {
        toast: {
          message: `${influencerName} added to ${watchlistName}`,
          type: 'success',
          lifespan: 4000,
        },
      },
    }),
    removeFromWatchlist: ['watchlistId'],
    removeFromWatchlistSuccess: (
      watchlistId,
      influencerName,
      watchlistName
    ) => ({
      type: `${STORAGE_PREFIX}/REMOVE_FROM_WATCHLIST_SUCCESS`,
      watchlistId,
      meta: {
        toast: {
          message: `${influencerName} removed from ${watchlistName}`,
          type: 'success',
          lifespan: 4000,
        },
      },
    }),
  },
  {
    prefix: `${STORAGE_PREFIX}/`,
  }
);

export const handleDateRangeFilterChanged = (state, { payload }) => {
  return { ...state, dateRangeFilter: payload };
};

export const handleAddToWatchlist = (state, { watchlistId }) => {
  const newState = _cloneDeep(state);

  newState.influencer.watchlists.push(watchlistId);

  return newState;
};

export const handleRemoveFromWatchlist = (state, { watchlistId }) => {
  const newState = _cloneDeep(state);

  newState.influencer.watchlists = newState.influencer.watchlists.filter(
    (id) => id !== watchlistId
  );

  return newState;
};

export const handleRequestInfluencerDetailsData = (
  state,
  { payload: { influencerId, influencerNetwork } }
) => {
  return {
    ...state,
    name: influencerId,
    network: influencerNetwork,
  };
};

export const handleInfluencerDataReset = (state) => {
  return {
    ...INITIAL_STATE,
    dateRangeFilter: state.dateRangeFilter,
  };
};

export const handleInfluencerDetailsDataReceived = (state, { payload }) => {
  const { youtubeComments, twitch } = payload;

  return {
    ...state,
    ...payload,
    youtubeComments: {
      ...youtubeComments,
      comments:
        youtubeComments &&
        youtubeComments.comments &&
        youtubeComments.comments.map(toMentionFromYoutubeComment),
    },
    twitch: {
      ...twitch,
      comments:
        twitch &&
        twitch.comments &&
        twitch.comments.map(toMentionFromTwitchComment),
    },
  };
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.DATE_RANGE_FILTER_CHANGED: {
      return handleDateRangeFilterChanged(state, action);
    }

    case INFLUENCER_DATA_REQUESTED: {
      return handleRequestInfluencerDetailsData(state, action);
    }

    case INFLUENCER_DATA_REQUEST_SUCCEEDED_INTERIM: {
      return handleInfluencerDetailsDataReceived(state, action);
    }

    case INFLUENCER_DATA_REQUEST_SUCCEEDED: {
      return handleInfluencerDetailsDataReceived(state, action);
    }

    case INFLUENCER_DATA_RESET: {
      return handleInfluencerDataReset(state, action);
    }

    case Types.ADD_TO_WATCHLIST_SUCCESS: {
      return handleAddToWatchlist(state, action);
    }

    case Types.REMOVE_FROM_WATCHLIST_SUCCESS: {
      return handleRemoveFromWatchlist(state, action);
    }

    default:
      return state;
  }
};

export const redoInfluencerSocialLink = createAsyncThunk(
  'redoInfluencerSocialLink',
  async (data, thunkAPI) => {
    try {
      const result = await redoInfluencerSocialLinkCall(data);
      const message = result.message || 'Workers started successfully';
      thunkAPI.dispatch(showToast({ message, type: 'success' }));
    } catch (e) {
      const message = e.bodyJson?.message || 'There was an error';
      thunkAPI.dispatch(showToast({ message, type: 'error' }));
    }
  }
);

export const actionTypes = Types;

export default Creators;
