import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';

import Banners from '../MediaTrackingTab/Banners';
import ChatMentions from '../MediaTrackingTab/ChatMentions';
import Links from '../MediaTrackingTab/Links';
import Shoutouts from '../MediaTrackingTab/Shoutouts';
import SocialMediaMentions from '../MediaTrackingTab/SocialMediaMentions';
const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 0,
    width: '100%',
    '&:first-child': {
      borderTop: 0,
    },
    margin: '0 !important',
    '&:before': {
      display: 'none',
    },
    border: 'none',
  },
})(MuiAccordion);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
    display: 'block',
    borderTop: '1px solid grey',
  },
})(MuiAccordionDetails);
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(4),
    padding: 16,
  },
  leftColumn: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
  },
  rightColumn: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  trialDisabled: {
    pointerEvents: 'none',
    backgroundColor: '#ccc',
  },
  accordion: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  heightAccordionSummary: {
    height: '72px',
  },
  containerButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 24px',
  },
}));

export const SponsorshipValuationTab = (props) => {
  const classes = useStyles();
  const trialClassName = classes.trialDisabled;
  const {
    ui_disable_shoutouts,
    ui_disable_banners,
    ui_banner_limit_per_campaign,
  } = props.organizationRestrictions;
  const { values } = props;

  const {
    shoutouts,
    banners,
    linksBrands,
    chatMentionKeywords,
    socialMediaKeywords,
  } = values;

  useEffect(() => {
    if (values.sponsValWarning === 'wait') {
      props.setFieldValue('sponsValWarning', false);
    } else if (values.sponsValWarning === false) {
      props.setFieldValue('sponsValWarning', true);
    }
  }, [
    shoutouts,
    banners,
    linksBrands,
    chatMentionKeywords,
    socialMediaKeywords,
  ]);

  return (
    <>
      {values.sponsValWarning && (
        <Alert severity="warning" style={{ marginBottom: 16 }}>
          Please note that the process for analyzing newly added media items
          might take up to 15 minutes.
        </Alert>
      )}
      {values.brands.map((item, index) => (
        <Accordion key={index} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            data-id={`panel1a-header-${index}`}
            className={classes.heightAccordionSummary}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              // flex={1}
              // onFocus={(event) => event.stopPropagation()}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  style={{ fontWeight: 600, fontSize: 18 }}
                  className={classes.heading}
                >
                  {item.brandName}
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.container}>
              <div className={classes.leftColumn}>
                <Shoutouts
                  trialClassName={trialClassName}
                  disabled={ui_disable_shoutouts}
                  index={index}
                  brandId={item.id}
                />
                <ChatMentions brandId={item.id} />
                <SocialMediaMentions brandId={item.id} />
              </div>
              <div className={classes.rightColumn}>
                <Banners
                  brandId={item.id}
                  trialClassName={trialClassName}
                  disabled={ui_disable_banners}
                  limit={
                    !!ui_banner_limit_per_campaign
                      ? ui_banner_limit_per_campaign
                      : Infinity
                  }
                />
                <Links brandId={item.id} />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default SponsorshipValuationTab;
