import moment from 'moment';

import { getInfluencer } from './utils';

export const computeBannerPresence = ({
  videos,
  banner_deliverables,
  campaign_influencers,
}) => {
  const rows = [];

  rows.push([
    'Banner',
    'Creator',
    'Platform',
    'Stream/Video Title',
    'Time on-screen',
    '% of Time on Screen',
    'Average CCV',
    'Peak CCV',
    'Media Value',
  ]);

  banner_deliverables.forEach((deliverable) => {
    videos.forEach((video) => {
      const data = deliverable.data[video.id];
      const influencer = getInfluencer(
        campaign_influencers,
        video.campaign_influencer_ids
      );

      if (!data) return;

      rows.push([
        deliverable.image || deliverable.raw_banner_image,
        influencer.name,
        video.resource,
        video.video_title,
        moment.duration(data.duration, 'seconds').format('HH:mm:ss'),
        Math.round((data.duration / video.duration) * 10000) / 100 + '%',
        data.avg_ccv,
        data.peak_ccv,
        data.emv,
      ]);
    });
  });

  return rows;
};

export default computeBannerPresence;
