import { Button, makeStyles } from '@material-ui/core';

import clip_icon from './svg/clip_icon.svg';
import icon_monitor from './svg/icon_monitor.svg';
import play_icon from './svg/play_icon.svg';

const useStyles = makeStyles((theme) => ({
  highlightsMain: {
    width: 1386,
    height: 685,
    display: 'flex',
    background: '#edf7ff',
    margin: '40px auto 0px auto',
    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon_monitor: {},
  icon_play: {
    position: 'absolute',
    left: 143,
    top: 110,
  },
  clip_icon: {
    position: 'absolute',
    left: 280,
    top: 185,
  },
  textIsBold: {
    fontFamily: 'Calibri',
    fontSize: 30,
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: 'bold',
    margin: 0,
    lineHeight: 1,
  },
  smallText: {
    fontFamily: 'Calibri',
    fontSize: 20,
    color: 'rgba(0, 0, 0, 0.5)',
    margin: 0,
    marginBottom: 40,
  },
  highlights_content: {
    textAlign: 'center',
  },
  button: {
    fontWeight: 'bold',
    width: 120,
    height: 48,
  },
}));

export const Highlights = () => {
  const classes = useStyles();

  return (
    <div className={classes.highlightsMain}>
      <div style={{ position: 'relative' }}>
        <img
          className={classes.icon_monitor}
          alt="icon_monitor"
          src={icon_monitor}
        />
        <img className={classes.icon_play} alt="play_icon" src={play_icon} />
        <img className={classes.clip_icon} alt="clip_icon" src={clip_icon} />
      </div>
      <div className={classes.highlights_content}>
        <p className={classes.textIsBold}>Passionate About collectibles?</p>
        <p className={classes.smallText}>
          Create digital art from your live streaming content
        </p>
        <Button className={classes.button} color="primary" variant="contained">
          JOIN BETA
        </Button>
      </div>
    </div>
  );
};
