import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import folderIcon from '../../../../assets-2.0/folder.svg';
import { PROFILE } from '../../../../common/constants/paths';
import { UnlockBanner } from '../../../../components/UnlockBanner/UnlockBanner';
import { WaitingBanner } from '../../../../components/WaitingBanner/WaitingBanner';
import {
  selectInfluencerHasSocialMediaAccounts,
  selectInfluencerHasSocialMediaAccountsIsProcessing,
  selectInfluencerInfoShowLoading,
} from '../../store/InfluencerInfo.selectors';
import { selectDateFilter } from '../store/Stats.selectors';
import { SocialMediaOverview } from './SocialMediaOverview';
import { SocialMediaTable } from './SocialMediaTable';
import {
  selectShouldFetchSocialPosts,
  selectSocialMediaOverview,
  selectSocialPostsAreLoading,
  selectSocialPostsHasError,
} from './store/SocialMediaPosts.selectors';
import { fetchSocialMediaPosts } from './store/SocialMediaPosts.thunks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
    [theme.breakpoints.down('sm')]: {
      gap: 20,
    },
  },

  bannerContainer: {
    display: 'flex',
    padding: '0px 100px',

    [theme.breakpoints.down('md')]: {
      padding: '0px 20px',
    },
  },
}));

export const SocialMediaTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasSocialMediaAccounts = useSelector(
    selectInfluencerHasSocialMediaAccounts()
  );
  const onboardingPagesIsProcessing = !useSelector(
    selectInfluencerHasSocialMediaAccountsIsProcessing()
  );
  const filterByDate = useSelector(selectDateFilter());
  const shouldFetch = useSelector(selectShouldFetchSocialPosts());
  const history = useHistory();
  const hasError = useSelector(selectSocialPostsHasError());
  const socialMediaOverview = useSelector(selectSocialMediaOverview());
  const postsAreLoading = useSelector(selectSocialPostsAreLoading());
  const influencerInfoIsLoading = useSelector(
    selectInfluencerInfoShowLoading()
  );

  const isLoading = influencerInfoIsLoading || postsAreLoading;
  let bluredData = false;

  useEffect(() => {
    if (shouldFetch) {
      dispatch(fetchSocialMediaPosts());
    }
  }, [shouldFetch, dispatch]);

  if (hasError) {
    return (
      <Alert severity="error">
        There was an error while fetching your social media data. Please try
        again later.
      </Alert>
    );
  }

  const viewBanner = () => {
    if (!isLoading && !hasSocialMediaAccounts) {
      // If the user has social media accounts connected but there are no posts after fetching the data we display this info
      return (
        <div className={classes.bannerContainer}>
          <UnlockBanner
            title="Understand your Impact"
            bodyText="Connect your Twitter, Instagram, Facebook, or TikTok accounts to evaluate your performance on social media"
            onCTA={() => {
              history.push(`${PROFILE}?socialCallback=true`);
            }}
          />
        </div>
      );
    }
    if (!isLoading && hasSocialMediaAccounts) {
      if (onboardingPagesIsProcessing) {
        bluredData = true;
        return (
          <WaitingBanner
            loader={true}
            title="We are crunching the data"
            bodyText="We are analyzing your posts from the last two weeks. This might take a while."
          />
        );
      }
      if (
        !isLoading &&
        !onboardingPagesIsProcessing &&
        socialMediaOverview.total_count === 0
      ) {
        bluredData = true;
        return (
          <WaitingBanner bodyText="Your stats will show up here once you start posting. We are collecting your posts from the moment you connected your accounts." />
        );
      }
    }
  };

  return (
    <div className={classes.container}>
      {(!!filterByDate?.start_date || !!filterByDate?.end_date) &&
      !!hasSocialMediaAccounts &&
      socialMediaOverview.total_count === 0 ? (
        <WaitingBanner
          icon={folderIcon}
          title="No Results Found"
          bodyText="There are no posts for the selected time period. Try a different time frame."
        />
      ) : (
        <>
          {viewBanner()}
          <SocialMediaOverview isLoading={isLoading} bluredData={bluredData} />
          <SocialMediaTable isLoading={isLoading} bluredData={bluredData} />
        </>
      )}
    </div>
  );
};
