import { State } from '../../../../../store/types';
import { LoadingState } from '../../../../../types';
import { SLICE_NAME } from './types';

export const selectSocialMediaOverview = () => (state: State) =>
  state[SLICE_NAME].overview;

export const selectSocialPostsAreLoading = () => (state: State) => {
  return (
    state[SLICE_NAME].loading === LoadingState.PENDING ||
    state[SLICE_NAME].loading === LoadingState.IDLE
  );
};

export const selectSocialPostsHasError = () => (state: State) =>
  state[SLICE_NAME].loading === LoadingState.REJECTED;

export const selectShouldFetchSocialPosts = () => (state: State) =>
  state[SLICE_NAME].loading === LoadingState.IDLE;

export const selectSocialPostsPlatformFilter = () => (state: State) =>
  state[SLICE_NAME].platformFilter;
