import { Box, CircularProgress } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _sumBy from 'lodash/sumBy';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DetailedViewToggle, {
  useDetailedViewToggle,
} from '../../components/DetailedViewToggle';
import OverviewCard, {
  PlaceholderOverviewRow,
} from '../../components/OverviewCard';
import PointerIcon from '../../components/PointerIcon';
import SectionTitle from '../../components/SectionTitle';
import {
  Campaign,
  CampaignInfluencer,
  PlatformFilter,
  TimeFrame,
} from '../../types';
import DetailedTable, { SetSocialPostDelete } from './DetailedTable';
import {
  selectShouldFetchSocialMediaPerformancePosts,
  selectShouldFetchSocialMediaPerformanceSummary,
  selectSocialMediaPerformancePosts,
  selectSocialMediaPerformancePostsError,
  selectSocialMediaPerformancePostsHasSharesOrRetweets,
  selectSocialMediaPerformancePostsIsLoading,
  selectSocialMediaPerformanceSummary,
  selectSocialMediaPerformanceSummaryError,
  selectSocialMediaPerformanceSummaryIsLoading,
} from './store/SocialMediaPerformance.selectors';
import {
  fetchSocialMediaPerformancePosts,
  fetchSocialMediaPerformanceSummary,
} from './store/SocialMediaPerformance.thunks';
import {
  FetchSocialMediaPerformancePayload,
  SocialMediaPerformanceSummary,
} from './store/types';
import SummaryTable from './SummaryTable';

const computeOverview = (
  socialMediaPerformanceSummary: SocialMediaPerformanceSummary[]
) => {
  const posts = _sumBy(socialMediaPerformanceSummary, 'posts_count');
  const followers = _sumBy(socialMediaPerformanceSummary, 'followers_gained');
  const impressions = socialMediaPerformanceSummary.reduce((acc, curr) => {
    return acc + (curr.impressions_count || curr.est_impressions_count);
  }, 0);
  const totalEMV = _sumBy(socialMediaPerformanceSummary, 'emv');
  const likes = _sumBy(socialMediaPerformanceSummary, 'likes_count');
  const replies = _sumBy(socialMediaPerformanceSummary, 'comments_count');
  const retweets = _sumBy(socialMediaPerformanceSummary, 'shares_count');
  const engagements = _sumBy(socialMediaPerformanceSummary, 'engagements');
  const impressionsAreEst = socialMediaPerformanceSummary.some(
    (summary) => !summary.impressions_count
  );

  const creators = socialMediaPerformanceSummary.filter(
    (i) => i.posts_count > 0
  ).length;
  const totalCreators = socialMediaPerformanceSummary.length;

  return {
    posts,
    engagements,
    impressions,
    totalEMV,
    retweets,
    likes,
    replies,
    followers,
    creators,
    totalCreators,
    impressionsAreEst,
  };
};

const Overview = React.memo(
  ({
    socialMediaPerformanceSummary,
  }: {
    socialMediaPerformanceSummary: SocialMediaPerformanceSummary[];
  }) => {
    const {
      posts,
      engagements,
      impressions,
      totalEMV,
      retweets,
      likes,
      replies,
      followers,
      creators,
      totalCreators,
      impressionsAreEst,
    } = computeOverview(socialMediaPerformanceSummary);

    return (
      <div>
        <SectionTitle>Social media performance</SectionTitle>
        <OverviewCard.Container>
          <OverviewCard
            label="Creators"
            color="teal"
            main={{ stat: creators }}
            icon={<PersonIcon />}
            outOf={totalCreators}
            id={`performanceTab-socialMedia-creators`}
          />
          <OverviewCard
            label="Posts"
            color="blue"
            main={{ stat: posts }}
            icon={<ChatBubbleIcon />}
            id={`performanceTab-socialMedia-posts`}
          />
          <OverviewCard
            label="Followers Gained"
            color="grey"
            main={{ stat: followers }}
            icon={<PeopleIcon />}
            id={`performanceTab-socialMedia-followersgnd`}
          />
          <OverviewCard
            label="Retweets/Shares"
            color="lightBlue"
            main={{ stat: retweets }}
            icon={<CachedIcon />}
            id={`performanceTab-socialMedia-retweets`}
          />
          <OverviewCard
            label="Replies/CMNTs"
            color="black"
            main={{ stat: replies }}
            icon={<ChatBubbleIcon />}
            id={`performanceTab-socialMedia-replies`}
          />
          <OverviewCard
            label="Likes"
            color="red"
            main={{ stat: likes }}
            icon={<FavoriteIcon />}
            id={`performanceTab-socialMedia-likes`}
          />
          <OverviewCard
            label="Engagements"
            color="orange"
            main={{ stat: engagements }}
            icon={<PointerIcon />}
            id={`performanceTab-socialMedia-engagements`}
          />
          <OverviewCard
            label={impressionsAreEst ? 'Est. Impressions' : 'Impressions'}
            color="purple"
            main={{ stat: impressions }}
            icon={<VisibilityIcon />}
            id={`performanceTab-socialMedia-impressions`}
          />
          <OverviewCard
            label="Media value"
            color="green"
            main={{ stat: totalEMV, prefix: '$', format: '0,[0].[0]a' }}
            icon={<LocalAtmIcon />}
            id={`performanceTab-socialMedia-mediavle`}
          />
        </OverviewCard.Container>
      </div>
    );
  }
);

interface SocialMediaProps {
  usedInfluencers: CampaignInfluencer[];
  campaign: Campaign;
  usedInfluencersIds: Set<number>;
  timeFrame: TimeFrame;
  platformFilter: PlatformFilter;
  timeZone: string;
  setSelectedCampaignInfluencer: (id: number) => void;
  setSocialPostDelete: SetSocialPostDelete;
}

export const SocialMedia = ({
  usedInfluencers,
  campaign,
  usedInfluencersIds,
  timeFrame,
  platformFilter,
  timeZone,
  setSelectedCampaignInfluencer,
  setSocialPostDelete,
}: SocialMediaProps) => {
  const { detailed, setDetailed } = useDetailedViewToggle(usedInfluencers);

  const dispatch = useDispatch();

  const campaignId = campaign.id;

  const fetchPayload: FetchSocialMediaPerformancePayload = useMemo(
    () => ({
      campaignId,
      influencerIds: Array.from(usedInfluencersIds),
      platform: platformFilter,
      startTime: timeFrame.start.toISOString(),
      endTime: timeFrame.end.toISOString(),
    }),
    [
      campaignId,
      usedInfluencersIds,
      platformFilter,
      timeFrame.start,
      timeFrame.end,
    ]
  );

  // summary
  const socialMediaPerformanceSummary = useSelector(
    selectSocialMediaPerformanceSummary()
  );
  const shouldFetchSummary = useSelector(
    selectShouldFetchSocialMediaPerformanceSummary(fetchPayload)
  );
  const summaryLoading = useSelector(
    selectSocialMediaPerformanceSummaryIsLoading(fetchPayload)
  );
  const summaryError = useSelector(selectSocialMediaPerformanceSummaryError());

  useEffect(() => {
    if (shouldFetchSummary) {
      dispatch(fetchSocialMediaPerformanceSummary(fetchPayload));
    }
  }, [dispatch, fetchPayload, shouldFetchSummary]);

  // posts
  const socialMediaPerformancePosts = useSelector(
    selectSocialMediaPerformancePosts()
  );
  const shouldFetchPosts =
    useSelector(selectShouldFetchSocialMediaPerformancePosts(fetchPayload)) &&
    detailed; // only fetch when showing detailed view
  const postsLoading = useSelector(
    selectSocialMediaPerformancePostsIsLoading(fetchPayload)
  );
  const postsError = useSelector(selectSocialMediaPerformancePostsError());
  const hasSharesOrRetweets = useSelector(
    selectSocialMediaPerformancePostsHasSharesOrRetweets()
  );

  useEffect(() => {
    if (shouldFetchPosts) {
      dispatch(fetchSocialMediaPerformancePosts(fetchPayload));
    }
  }, [dispatch, fetchPayload, shouldFetchPosts]);

  const error = !detailed ? summaryError : postsError || summaryError; // only show posts error when rendering the detailed view

  if (summaryLoading)
    return (
      <div style={{ paddingTop: 64 }}>
        <SectionTitle>Social media performance</SectionTitle>
        <PlaceholderOverviewRow nCards={9} />
      </div>
    );

  if (error) return <p>error</p>;

  return (
    <div style={{ paddingTop: 64, paddingBottom: 100 }}>
      <Overview socialMediaPerformanceSummary={socialMediaPerformanceSummary} />
      <DetailedViewToggle {...{ detailed, setDetailed, usedInfluencers }} />
      {!detailed && (
        <SummaryTable
          usedInfluencers={usedInfluencers}
          socialMediaPerformanceSummary={socialMediaPerformanceSummary}
          hasSharesOrRetweets={hasSharesOrRetweets}
          setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
        />
      )}
      {detailed && (
        <>
          {postsLoading ? (
            <Box
              height={200}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <DetailedTable
              usedInfluencers={usedInfluencers}
              timeZone={timeZone}
              hasSharesOrRetweets={hasSharesOrRetweets}
              posts={socialMediaPerformancePosts}
              setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
              setSocialPostDelete={setSocialPostDelete}
              campaignId={campaignId}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SocialMedia;
