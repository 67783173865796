import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import GoBack from '../../../components/GoBack';
import TopBar from '../../../components/TopBar';
import TopBarContent from '../../../components/TopBarContent';
import mixpanelTrack from '../../../utils/mixpanel-track';
import InfluencerSummary from './InfluencerSummary';
import InfluencerTabActivity from './InfluencerTabActivity';
import InfluencerTabAudience from './InfluencerTabAudience';
import InfluencerTabEngagement from './InfluencerTabEngagement';
import InfluencerTabOverview from './InfluencerTabOverview';
import InfluencerTabPsychographics from './InfluencerTabPsychographics';

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: 89,
    maxHeight: '100%',
    overflow: 'auto',
    padding: '32px 16px',
  },
  topBarContainer: {
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    position: 'absolute',
  },
  tabs: {
    color: '#333',
    borderTop: '1px solid #e4e4e4',
    borderBottom: '1px solid #e4e4e4',
  },
  paper: {
    padding: 16,
    background: theme.palette.background.default,
  },
  select: {
    minWidth: 120,
  },
  button: {
    padding: theme.spacing(2),
    background: 'none',
    fontSize: 16,
    color: 'black',
    textTransform: 'uppercase',
    border: 'none',
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    width: 175,
  },
  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  menuDiv: {
    display: 'inline-block',
  },
}));

export const Influencer = (props) => {
  const {
    dateRangeFilterChanged,
    dateRangeFilter,
    networkFilter,
    youtubeComments,
    twitchComments,
    twitchAudienceBehaviour,
    youtubeAudienceBehaviour,
    twitterProfile,
    facebookPage,
    topBrands,
    topFollowed,
    topCategories,
    match,
    instagramUser,
    influencerMounted,
    influencerTwitchStreamer,
    influencerYoutubeStreamer,
    influencer,
    isOnWatchlist,
    addToWatchlist,
    removeFromWatchlist,
    playedGames,
    youtubeAudienceWordcloudData,
    twitchAudienceWordcloudData,
    engagementData,
  } = props;

  const {
    params: { influencerId, influencerNetwork },
  } = match;

  const isReady = influencer && influencer.id;

  const [activeTab, setActiveTab] = useState('influencerTabOverview'); //influencerTabOverview

  document.title = influencerId;

  useEffect(() => {
    influencerMounted({
      influencerId,
      influencerNetwork,
    });

    mixpanelTrack('Influencer Page View - Overview', {
      influencerId,
      influencerNetwork,
    });
  }, [influencerId]);

  const watchlistActions = {
    isOnWatchlist,
    add: () => influencer && addToWatchlist(influencer.id),
    remove: () => influencer && removeFromWatchlist(influencer.id),
  };

  const tabs = [
    {
      key: 'influencerTabOverview',
      title: 'Overview',
      display: true,
    },
    {
      key: 'influencerTabEngagement',
      title: 'Engagement',
      display: true,
    },
    {
      key: 'influencerTabActivity',
      title: 'Activity',
      display: true,
    },
    {
      key: 'influencerTabAudience',
      title: 'Audience',
      display: true,
    },
    {
      key: 'influencerTabPsycho',
      title: 'Psychographics',
      display:
        influencer &&
        ((influencerTwitchStreamer &&
          (influencerTwitchStreamer.personality ||
            influencerTwitchStreamer.behaviour)) ||
          (influencerYoutubeStreamer &&
            (influencerYoutubeStreamer.personality ||
              influencerYoutubeStreamer.behaviour)) ||
          (twitterProfile &&
            (twitterProfile.personality || twitterProfile.behaviour))),
    },
  ];

  function changeTab(key) {
    setActiveTab(key);

    const tab = tabs.filter((t) => t.key === key)[0];
    const elem = document.getElementsByClassName('main-container')[0];
    if (elem) {
      elem.scrollTo(0, 0);
    }

    mixpanelTrack(`Influencer Page View - ${tab.title}`, {
      influencerId,
      influencerNetwork,
    });
  }

  const totalAudience =
    (parseInt(twitterProfile && twitterProfile.followers_count) || 0) +
    (parseInt(
      influencer &&
        influencer.twitch_streamer &&
        influencer.twitch_streamer.total_followers
    ) || 0) +
    (parseInt(
      influencer &&
        influencer.youtube_streamer &&
        influencer.youtube_streamer.subscriber_count
    ) || 0) +
    (parseInt(instagramUser && instagramUser.no_followers) || 0);

  function getInfluencerTabsMenu() {
    return (
      <Grid container alignItems="center" className={classes.tabs}>
        <Grid item style={{ flex: 1 }}>
          <div className={classes.menuDiv}>
            {!isReady && <Skeleton component={'div'} width={420} height={36} />}
            {isReady &&
              tabs
                .filter(({ display }) => display)
                .map(({ key, title }, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => changeTab(key)}
                      className={clsx(classes.button, {
                        [classes.active]: activeTab === key,
                      })}
                    >
                      {title}
                    </button>
                  );
                })}
          </div>
        </Grid>
      </Grid>
    );
  }

  function getActiveTab() {
    switch (activeTab) {
      case 'influencerTabOverview': {
        return (
          <InfluencerTabOverview
            {...{
              networkFilter,
              twitterProfile,
              influencer,
              influencerTwitchStreamer,
              influencerYoutubeStreamer,
              instagramUser,
            }}
          />
        );
      }
      case 'influencerTabEngagement': {
        return (
          <InfluencerTabEngagement
            {...{
              dateRangeFilterChanged,
              dateRangeFilter,
              networkFilter,
              engagementData,
              influencerTwitchStreamer,
              influencerYoutubeStreamer,
            }}
          />
        );
      }
      case 'influencerTabActivity': {
        return (
          <InfluencerTabActivity
            {...{
              networkFilter,
              playedGames,
              influencerTwitchStreamer,
            }}
          />
        );
      }
      case 'influencerTabAudience': {
        return (
          <InfluencerTabAudience
            {...{
              dateRangeFilterChanged,
              dateRangeFilter,
              networkFilter,
              twitchComments,
              youtubeComments,
              influencerId,
              twitterProfile,
              topFollowed,
              topBrands,
              topCategories,
              youtubeAudienceWordcloudData,
              twitchAudienceWordcloudData,
              twitchAudienceBehaviour,
              youtubeAudienceBehaviour,
            }}
          />
        );
      }
      case 'influencerTabPsycho': {
        return (
          <InfluencerTabPsychographics
            {...{
              twitchAudienceBehaviour,
              youtubeAudienceBehaviour,
              twitterProfile,
              networkFilter,
              influencerTwitchStreamer,
            }}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  const classes = useStyles();

  return (
    <>
      <TopBar>
        <TopBarContent>
          <Grid container alignItems="center" className={classes.root}>
            <Grid item>
              <GoBack>
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </GoBack>
            </Grid>
          </Grid>
        </TopBarContent>
      </TopBar>

      <main className={clsx('influencer-page', classes.main)}>
        <Grid container spacing={4} direction="column" wrap="nowrap">
          <Grid item>
            <InfluencerSummary
              isReady={isReady}
              influencerTwitchStreamer={influencerTwitchStreamer}
              influencerYoutubeStreamer={influencerYoutubeStreamer}
              influencerNetwork={influencerNetwork}
              influencer={influencer}
              instagramUser={instagramUser}
              twitterProfile={twitterProfile}
              facebookPage={facebookPage}
              watchlistActions={watchlistActions}
              totalAudience={totalAudience}
            />
            {getInfluencerTabsMenu()}
          </Grid>

          <Grid item>{getActiveTab()}</Grid>
        </Grid>
      </main>
    </>
  );
};
