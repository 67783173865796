import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { aggero } from '../../../ajax';
import { PATH_SIGNIN_YOUTUBE_CALLBACK } from '../platformsLogin';
import { Pages } from './types';

export const influencerOnboardingApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  tagTypes: ['Pages'],
  endpoints: (build) => ({
    getPages: build.query<Pages, string>({
      query: (influencer_token) => ({
        url: `${aggero}/api/v1/campaign_onboarding/check_social_networks?influencer_token=${influencer_token}`,
      }),
      transformResponse: (response: { pages: Pages }) => {
        return response.pages;
      },
      providesTags: () => ['Pages'],
    }),

    addPage: build.mutation<
      void,
      {
        platform?: keyof Pages;
        target?: string | string[];
        accessToken?: string | string[];
        accessCode?: string | string[];
        oauthToken?: string | string[];
        oauthVerifier?: string | string[];
        influencer_token: string;
      }
    >({
      query({
        platform,
        target,
        accessToken,
        accessCode,
        oauthToken,
        oauthVerifier,
        influencer_token,
      }) {
        const url = `${aggero}/api/v1/campaign_onboarding/${platform}`;
        let body = {};

        if (platform === 'twitter') {
          body = {
            oauth_token: oauthToken,
            oauth_verifier: oauthVerifier,
            influencer_token,
          };
        }

        if (platform === 'tiktok') {
          body = {
            access_code: accessCode,
            influencer_token,
          };
        }

        if (platform === 'twitch') {
          body = {
            access_token: accessToken,
            influencer_token,
          };
        }

        if (platform === 'youtube') {
          body = {
            access_code: accessCode,
            redirect_uri: `${
              window.location.origin
            }${PATH_SIGNIN_YOUTUBE_CALLBACK}${target ? `?t=${target}` : ''}`,
            influencer_token,
          };
        }

        return {
          url,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Pages'],
    }),

    addFacebookPage: build.mutation<
      void,
      { accessToken; userID; pageName; onboardingNetwork; influencer_token }
    >({
      query(body) {
        return {
          url: `${aggero}/api/v1/campaign_onboarding/facebook`,
          method: 'POST',
          body: {
            access_token: body.accessToken,
            user_id: body.userID,
            onboarding_page_name: body.pageName,
            onboarding_network: body.onboardingNetwork,
            influencer_token: body.influencer_token,
          },
        };
      },
      invalidatesTags: ['Pages'],
    }),

    disconnectPage: build.mutation<
      void,
      { influencer_token: string; network: keyof Pages; page_id: number | null }
    >({
      query(body) {
        return {
          url: `${aggero}/api/v1/campaign_onboarding/disconnect`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Pages'],
    }),
  }),
});

export const {
  useGetPagesQuery,
  useDisconnectPageMutation,
  useAddPageMutation,
  useAddFacebookPageMutation,
} = influencerOnboardingApi;
