import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as ajax from '../../ajax';
import { Dialog } from '../../common/containers';
import { INFLUENCER_ONBOARDING_SUBMITTED } from '../../store/action-types';
import { actions } from '../../store/actions';

function* saveInfluencerOnboardingData(action) {
  yield put(actions.influencerOnboardingSaveRequest());

  try {
    const userData = yield call(ajax.saveUserData, action.payload);
    yield put(actions.userGet({ ...userData }));
    yield put(actions.influencerOnboardingSaveSuccess());
  } catch (e) {
    yield put(actions.influencerOnboardingSaveError());

    console.error(e);

    Dialog.show({
      caption: 'Somethig went wrong!',
      message: (e.bodyJson && e.bodyJson.errors) || e.message,
      buttons: ['Got it'],
    });
  }
}

function* watchRequestInfluencerOnboardingData() {
  yield takeLatest(
    INFLUENCER_ONBOARDING_SUBMITTED,
    saveInfluencerOnboardingData
  );
}

export default function* root() {
  yield all([fork(watchRequestInfluencerOnboardingData)]);
}
