import { withFormik } from 'formik';
import qs from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import * as paths from '../../../common/constants/paths';
import {
  getTrialInfluencerSearch,
  isAggeroUser,
} from '../../../store/models/user/user.selectors';
import { getWatchlistsList } from '../../../store/models/watchlists/watchlists.selectors';
import { selectors } from '../../../store/selectors';
import objectFilter from '../../../utils/object-filter';
import { DEFAULT_PARAMS } from './defaultParams';

const handleSubmit = (values, bag) => {
  const {
    props: { history, isAggeroUser },
  } = bag;

  if (values.game_categories.length > 0) {
    values.influencer_game_title_or_sector = '';
  }

  const completedParams = objectFilter((value) => Boolean(value))(values);

  if (isAggeroUser) {
    completedParams.with_email = true;
  }

  const query = qs.stringify(completedParams, { arrayFormat: 'comma' });

  history.replace({ search: query });

  history.push({
    pathname: paths.INFLUENCERS,
    search: query,
  });
};

const mapPropsToValues = ({ location: { search } }) => {
  let params = { ...DEFAULT_PARAMS };

  let parsedSearch = qs.parse(search);

  const keyValuesAreIntegers = ['game_categories'];

  Object.keys(params).forEach((key) => {
    if (parsedSearch[key] && Array.isArray(params[key])) {
      params[key] = parsedSearch[key].split(',');
    } else if (key in parsedSearch) {
      params[key] = parsedSearch[key];
    }

    if (keyValuesAreIntegers.indexOf(key) !== -1 && params[key]) {
      params[key] = params[key].map((i) => parseInt(i));
    }
  });

  return params;
};

const formik = {
  handleSubmit,
  mapPropsToValues,
  enableReinitialize: true,
};

const mapStateToProps = (state) => ({
  game_title_or_sector: selectors.getGameTitleOrSector(state),
  trialAccountSearch: getTrialInfluencerSearch(state),
  watchlists: getWatchlistsList()(state),
  isAggeroUser: isAggeroUser(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withFormik(formik)
);
