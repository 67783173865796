import { InfluencerSocials } from '../views/CampaignWizard/types';

type InfluencerError = {
  field?: string;
  influencerIndex?: number;
  link?: string;
  name?: string;
};

const influencerHasEmptyLinks = (influencer: InfluencerSocials) => {
  const checkedPlatformsKeys = Object.keys(influencer).filter((key) =>
    key.includes('UrlChecked')
  );

  const hasEmptyLink = checkedPlatformsKeys.some((key) => {
    const platformUrl = key.replace('Checked', '');
    return !influencer[platformUrl];
  });

  // Check if influencer has no links at all
  const allLinksAreEmpty = checkedPlatformsKeys.every(
    (key) => !influencer[key]
  );

  return hasEmptyLink || allLinksAreEmpty;
};

export const searchIdenticalUsers = (
  arrayLinksOrInfluencers: Array<any>,
  index: number,
  influencer: InfluencerSocials
): InfluencerError[] => {
  const arrayErrors: InfluencerError[] = [];
  const linksArray = [];
  const linksName = [
    'influencerFacebookUrl',
    'influencerInstagramUrl',
    'influencerTwitchUrl',
    'influencerTwitterUrl',
    'influencerYoutubeUrl',
    'influencerTiktokUrl',
  ];

  if (influencer) {
    arrayLinksOrInfluencers.forEach((item, indexArr) => {
      if (indexArr !== index && index > indexArr) {
        for (let key of linksName) {
          if (!!item[key]) {
            if (item[key] === influencer[key]) {
              arrayErrors.push({
                field: key,
                influencerIndex: index,
                link: item[key],
              });
            }
          }
        }
      }
    });

    if (influencerHasEmptyLinks(influencer)) {
      arrayErrors.push({ name: influencer.display_name });
    }
  } else {
    arrayLinksOrInfluencers.forEach((item, index) => {
      for (let key of linksName) {
        if (!!item[key]) {
          if (linksArray.find((elem) => elem === item[key])) {
            arrayErrors.push({
              field: key,
              influencerIndex: index,
              link: item[key],
            });
          } else {
            linksArray.push(item[key]);
          }
        }
      }
      if (influencerHasEmptyLinks(item)) {
        arrayErrors.push({ name: item.display_name });
      }
    });
  }

  return arrayErrors;
};
