import React from 'react';

import { InstagramSocialPage } from '../../../store/types';
import { BoxInfo } from '../InfluencerOverviewPerformance';

export const OverviewPerformanceInstagram = ({
  followers_count,
  posts_count,
}: InstagramSocialPage) => {
  return (
    <>
      <BoxInfo
        label="FOLLOWERS"
        info="Total number of followers."
        stat={followers_count}
      />
      <BoxInfo
        label="TOTAL POSTS"
        info="Total number of posts."
        stat={posts_count}
      />
    </>
  );
};
