import { Button, FormLabel } from '@material-ui/core';
import { Formik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';

import FormikMuiTextField from '../../../components/FormikMuiTextField';
import { LoadingState } from '../../Campaign/types';
import { ModalShell } from '../../influencerViews/Profile/components/modals/ModalShell';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Not a valid email')
    .required('Enter email')
    .max(75, 'Must be shorter than 75 character'),
  emailConfirm: yup
    .string()
    .required('Enter email confirmation')
    .max(75, 'Must be shorter than 75 character'),
});

const validateEmails = async (values, props) => {
  const { email, emailConfirm } = values;

  let error = {};

  if (
    email &&
    emailConfirm &&
    email.toLowerCase() !== emailConfirm.toLowerCase()
  ) {
    error.emailConfirm = 'Email should match email confirmation!';
  }

  return error;
};

export const ChangeEmailUserModal = ({ onClose, onSubmit }) => {
  const [error, setError] = useState(null);
  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);

  const onSubmitLocal = async (values) => {
    setLoadingState(LoadingState.PENDING);

    await onSubmit(values).then(({ success, errorKey }) => {
      if (success) {
        setLoadingState(LoadingState.FULFILLED);
      } else {
        setError({ key: errorKey });
        setLoadingState(LoadingState.IDLE);
      }
    });
  };

  return (
    <Formik
      initialValues={{ email: '', emailConfirm: '' }}
      onSubmit={onSubmitLocal}
      validationSchema={validationSchema}
      validate={validateEmails}
    >
      {(formik) => {
        const { submitForm, isValid } = formik;

        let content = '';

        if (loadingState === LoadingState.FULFILLED) {
          content = (
            <>
              <h4>Email confirmation required</h4>
              <p>
                A confirmation email has been sent to{' '}
                <b>{formik.values.email}</b>. You'll have to visit the link
                enclosed in the email to confirm the email address change.
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={() => {
                    onClose();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Got it
                </Button>
              </div>
            </>
          );
        } else {
          content = (
            <>
              <FormLabel style={{ marginBottom: 15 }}>New email</FormLabel>
              <FormikMuiTextField
                name="email"
                fullWidth
                placeholder="name@mail.com"
                variant="outlined"
                style={{ marginBottom: 16 }}
                disabled={loadingState === LoadingState.PENDING}
              />
              <FormLabel style={{ marginBottom: 15 }}>
                Repeat New email
              </FormLabel>
              <FormikMuiTextField
                name="emailConfirm"
                fullWidth
                placeholder="name@mail.com"
                variant="outlined"
                style={{ marginBottom: 16 }}
                errorMessage={error}
                disabled={loadingState === LoadingState.PENDING}
              />
            </>
          );
        }

        return (
          <ModalShell
            title="CHANGE EMAIL"
            open={true}
            onClose={() => {
              onClose();
              setError(null);
            }}
            onConfirm={submitForm}
            disableConfirm={!isValid}
            loading={loadingState === LoadingState.PENDING}
            noActionButtons={loadingState === LoadingState.FULFILLED}
          >
            {content}
          </ModalShell>
        );
      }}
    </Formik>
  );
};
