import { createSlice } from '@reduxjs/toolkit';

import { ErrorMessage } from '../../../common/ErrorMessageUtil';
import { LoadingState } from '../../Campaign/types';
import {
  disconnectInfluencerSocialPlatform,
  fetchInfluencerInfo,
  putInfluencerInfo,
} from './InfluencerInfo.thunks';
import { InfluencerInfoState, SLICE_NAME } from './types';

const initialState: InfluencerInfoState = {
  data: null,
  dataLoadingState: LoadingState.IDLE,
  dataError: null,
  putInfluencerInfoLoadingState: null,
  putInfluencerInfoError: null,
  disconnectPlatformLoadingMap: {},
};

const {
  reducer,
  actions: { forceInfluencerInfoReload },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    forceInfluencerInfoReload: (state) => {
      state.dataLoadingState = LoadingState.IDLE;
    },
  },
  extraReducers: (builder) =>
    builder
      // load info
      .addCase(fetchInfluencerInfo.pending, (state) => {
        state.dataLoadingState = LoadingState.PENDING;
        state.dataError = null;
      })
      .addCase(fetchInfluencerInfo.fulfilled, (state, action) => {
        state.dataLoadingState = LoadingState.FULLFILLED;

        state.data = action.payload;
      })
      .addCase(fetchInfluencerInfo.rejected, (state, action) => {
        state.dataLoadingState = LoadingState.REJECTED;
        state.dataError = action.error;
      })

      // put influencer info
      .addCase(putInfluencerInfo.pending, (state) => {
        state.putInfluencerInfoLoadingState = LoadingState.PENDING;
        state.putInfluencerInfoError = null;
      })
      .addCase(putInfluencerInfo.fulfilled, (state, action) => {
        state.putInfluencerInfoLoadingState = LoadingState.FULLFILLED;

        state.data = {
          ...state.data,
          ...action.meta.arg.influencerInfo,
        };
      })
      .addCase(putInfluencerInfo.rejected, (state, action) => {
        state.putInfluencerInfoLoadingState = LoadingState.REJECTED;
        state.putInfluencerInfoError = action.payload as ErrorMessage[];
      })

      // disconnect platform
      .addCase(
        disconnectInfluencerSocialPlatform.pending,
        (
          state,
          {
            meta: {
              arg: { id, platform },
            },
          }
        ) => {
          state.disconnectPlatformLoadingMap[`${platform}/${id}`] =
            LoadingState.PENDING;
        }
      )
      .addCase(
        disconnectInfluencerSocialPlatform.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id, platform },
            },
          }
        ) => {
          state.disconnectPlatformLoadingMap[`${platform}/${id}`] =
            LoadingState.FULLFILLED;

          state.data[`onboarding_${platform}_pages`] = state.data[
            `onboarding_${platform}_pages`
          ].filter((p) => p.id !== id);
        }
      )
      .addCase(
        disconnectInfluencerSocialPlatform.rejected,
        (
          state,
          {
            meta: {
              arg: { id, platform },
            },
          }
        ) => {
          state.disconnectPlatformLoadingMap[`${platform}/${id}`] =
            LoadingState.REJECTED;
        }
      ),
});

export { reducer, forceInfluencerInfoReload };
