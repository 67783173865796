import { Box, Button, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import twitch from '../../../assets-2.0/twitch.svg';
import youtube from '../../../assets-2.0/youtube.svg';
import { INFLUENCERS_SIGNUP } from '../../../common/constants/paths';
import { ErrorKey, ErrorMessage } from '../../../common/ErrorMessageUtil';
import Container from '../../../components/Container';
import FormikMuiTextField from '../../../components/FormikMuiTextField';
import LoadingButton from '../../../components/LoadingButton';
import {
  redirectTwitch,
  redirectYoutube,
} from '../../../helpers/influencer-login';
import { useRedirectSignedIn } from '../../../hooks/useRedirectSignedIn';
import logo from '../InfluencerSignupWizard/logo.svg';
import {
  selectSigninInfluencerError,
  selectSigninInfluencerShowLoading,
} from './store/InfluencerSignin.selectors';
import { signinInfluencer } from './store/InfluencerSignin.thunks';

const BUTTON_WIDTH = 268;

const useStyles = makeStyles(() => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    padding: 0,
    height: '100vh',
    justifyContent: 'center',
  },
  container: {
    padding: '30px 100px',
    maxWidth: 640,
    minWidth: 640,
    overflow: 'unset',
    background: 'white',
    minHeight: '370px',
    marginBottom: '100px',
    border: '2px solid #dbdbdb',
    borderRadius: '4px',
    strokeWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '100px',
    marginBottom: 55,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  input: {
    height: 44,
    flex: 1,
    fontSize: 16,
  },
  formLabelStyle: {
    marginRight: 'auto',
  },
  error: {
    color: 'red',
  },
  continueButton: {
    width: 95,
    fontSize: '0.875rem',
    marginTop: '12px',
    fontWeight: 'bold',
    padding: 10,
  },
  button: {
    width: BUTTON_WIDTH,
    height: 50,
    fontSize: 14,
    marginBottom: 14,
    border: ' 1px solid #dadfe3',
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  orWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 1,
    width: BUTTON_WIDTH,
    background: '#dbdbdb',
    marginTop: 28,
    marginBottom: 28,
  },
  or: {
    background: '#ffffff',
    width: 32,
    textAlign: 'center',
    marginTop: -11,
  },
  link: {
    color: '#347cff',
  },
}));

const INITIAL_VALUES = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Enter email'),
  password: yup.string().required('Enter password'),
});

export type InfluencerSigninProps = {};

export const InfluencerSignin = (p: InfluencerSigninProps) => {
  const shouldRender = useRedirectSignedIn();

  const classes = useStyles();
  const dispatch = useDispatch();

  const showLoading = useSelector(selectSigninInfluencerShowLoading());
  const errors = useSelector(selectSigninInfluencerError());
  const { t } = useTranslation();

  const onSubmit = useCallback(
    (values: typeof INITIAL_VALUES) => {
      dispatch(signinInfluencer(values));
    },
    [dispatch]
  );

  const errorKey: ErrorKey = errors?.length
    ? `errors.${errors[0].key as ErrorMessage['key']}`
    : null;

  if (!shouldRender) return null;

  return (
    <Container className={classes.outerContainer}>
      <img className={classes.logo} src={logo} alt="Aggero logo"></img>
      <Formik
        onSubmit={onSubmit}
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        isInitialValid={false}
      >
        {({ submitForm, isValid }) => {
          return (
            <Form>
              <Container className={classes.container}>
                <Box mb={3}>
                  <Box textAlign="center" fontSize="1.7rem">
                    Login to your account
                  </Box>
                </Box>
                <div className={classes.inputContainer}>
                  <FormLabel className={classes.formLabelStyle}>
                    Email
                  </FormLabel>
                  <FormikMuiTextField
                    className={classes.input}
                    fullWidth
                    placeholder="Enter your email address"
                    variant="outlined"
                    name="email"
                    style={{ marginBottom: 16 }}
                  />

                  <FormLabel className={classes.formLabelStyle}>
                    Password
                  </FormLabel>
                  <FormikMuiTextField
                    name="password"
                    fullWidth
                    type="password"
                    placeholder="Enter your password"
                    variant="outlined"
                    className={classes.input}
                    style={{ marginBottom: 16 }}
                  />
                </div>
                {errors?.length && (
                  <p className={classes.error}>
                    {t(errorKey, { ...errors[0].vars })}
                  </p>
                )}
                <LoadingButton
                  key="submit"
                  type="submit"
                  variant="contained"
                  className={classes.continueButton}
                  color="primary"
                  onClick={submitForm}
                  disabled={!isValid || showLoading}
                  loading={showLoading}
                >
                  Continue
                </LoadingButton>

                <div className={classes.orWrapper}>
                  <span className={classes.or}>{t('generic.or')}</span>
                </div>

                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={redirectTwitch()}
                >
                  <img
                    src={twitch}
                    className={classes.icon}
                    alt="Twitch logo"
                  />
                  Login with Twitch
                </Button>

                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={redirectYoutube()}
                >
                  <img
                    src={youtube}
                    className={classes.icon}
                    alt="Youtube logo"
                  />
                  Login with YouTube
                </Button>
                <p>
                  Don't have an account?{' '}
                  <Link to={INFLUENCERS_SIGNUP} className={classes.link}>
                    Sign up
                  </Link>
                </p>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
