import _cloneDeep from 'lodash/cloneDeep';
import { combineReducers } from 'redux';

import overwriteDeep from '../../../utils/overwrite-deep';
import toObject from '../../../utils/to-object';
import {
  CAMPAIGN_DELETE_SUCCEEDED,
  CAMPAIGN_MULTIPLE_VIDEOS_DELETE_SUCCEEDED,
  CAMPAIGN_REQUEST_SUCCEEDED,
  CAMPAIGN_UPDATE_SUCCEEDED,
  CAMPAIGN_VIDEO_ADD_FILTER_SUCCEEDED,
  CAMPAIGN_VIDEO_DELETE_SUCCEEDED,
  CAMPAIGNS_REQUEST_SUCCEEDED,
} from '../../action-types';

const byId = (state = {}, action) => {
  const { type } = action;

  switch (type) {
    case CAMPAIGNS_REQUEST_SUCCEEDED: {
      const { payload } = action;

      return overwriteDeep(
        state,
        toObject(
          (item) => item.id,
          (item) => {
            return { ...item, structure_loaded: false };
          }
        )(payload)
      );
    }

    case CAMPAIGN_REQUEST_SUCCEEDED: {
      const { payload } = action;

      return {
        ...state,
        [payload.campaign.id]: payload.campaign,
      };
    }

    case CAMPAIGN_DELETE_SUCCEEDED: {
      const {
        payload: { id },
      } = action;

      const { [id]: deleted, ...remaining } = state;

      return remaining;
    }

    case CAMPAIGN_UPDATE_SUCCEEDED: {
      const {
        payload: {
          campaign: { id, name },
        },
      } = action;

      return {
        ...state,
        [id]: { name },
      };
    }

    case CAMPAIGN_VIDEO_DELETE_SUCCEEDED: {
      const {
        payload: { id: videoId, campaignId },
      } = action;

      const newCampaign = Object.assign({}, state[campaignId]);

      return {
        ...state,
        [campaignId]: {
          ...newCampaign,
          // structure_loaded: false, // reset structure_loaded flag so that it can be reloaded
          videos: newCampaign.videos.filter((e) => e.id !== videoId),
        },
      };
    }

    case CAMPAIGN_MULTIPLE_VIDEOS_DELETE_SUCCEEDED: {
      const {
        payload: { videoIds, campaignId },
      } = action;

      const newCampaign = Object.assign({}, state[campaignId]);

      return {
        ...state,
        [campaignId]: {
          ...newCampaign,
          // structure_loaded: false, // // reset structure_loaded flag so that it can be reloaded
          videos: newCampaign.videos.filter((e) => !videoIds.includes(e.id)),
        },
      };
    }

    case CAMPAIGN_VIDEO_ADD_FILTER_SUCCEEDED: {
      const {
        payload: { campaignId, videoId, video },
      } = action;

      const newCampaign = _cloneDeep(state[campaignId]);

      newCampaign.videos = newCampaign.videos.map((v) => {
        if (v.id !== videoId) return v;

        return video;
      });

      return {
        ...state,
        [campaignId]: newCampaign,
      };
    }

    default:
      return state;
  }
};

export default combineReducers({ byId });
