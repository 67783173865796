import {
  Button,
  Card,
  CardContent,
  Chip,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import React, { useState } from 'react';

import FormikMuiTextField from '../../../../components/FormikMuiTextField';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  label: {
    fontSize: '24px',
    fontWeight: 'bold',
    paddingLeft: 0,
  },
}));

export const SocialMediaMentions = ({ isViewOnly, id }) => {
  const classes = useStyles();
  const [duplicate, isDuplicate] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const onAdd = (arrayHelpers) => {
    const content = values.newSocialMediaKeyword.split(',');
    content.forEach((el, index) => {
      content[index] = el.trim().toLowerCase();
    });
    new Set(content).forEach((newContent) => {
      if (!newContent) return;
      if (!values.socialMediaKeywords.includes(newContent)) {
        isDuplicate(false);
        arrayHelpers.push(newContent);
        setFieldValue('newSocialMediaKeyword', '');
      } else {
        setFieldValue('newSocialMediaKeyword', newContent);
        isDuplicate(true);
      }
    });
  };

  return (
    <Card className={classes.container}>
      <CardContent>
        <Typography className={classes.label} variant="h2">
          Social posts mentions
        </Typography>
      </CardContent>

      <>
        <FieldArray
          name="socialMediaKeywords"
          render={(arrayHelpers) => (
            <>
              <CardContent>
                {!isViewOnly && (
                  <div className={classes.inputContainer}>
                    <FormikMuiTextField
                      fullWidth
                      placeholder="Add keyword"
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue('newSocialMediaKeyword', e.target.value);
                        isDuplicate(false);
                      }}
                      value={values.newSocialMediaKeyword}
                      name="newSocialMediaKeyword"
                      className={classes.input}
                      inputProps={{
                        onKeyDown: (e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                            onAdd(arrayHelpers);
                          }
                        },
                        'data-id': `input-sm-${id}`,
                      }}
                    />
                    <Button
                      data-id={`button-sm-${id}`}
                      size="large"
                      variant="outlined"
                      color="primary"
                      style={{ padding: 9.5 }}
                      disabled={!values.newSocialMediaKeyword.trim()}
                      onClick={() => onAdd(arrayHelpers)}
                    >
                      Add
                    </Button>
                  </div>
                )}
                {duplicate && (
                  <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                    "{values.newSocialMediaKeyword}" is already added
                  </p>
                )}
              </CardContent>
              <CardContent component="ul" className={classes.chipsContainer}>
                {values.socialMediaKeywords.map((keyword, index) => (
                  <li key={index} data-id={`chip-sm-${id}-${index}`}>
                    <Chip
                      label={keyword}
                      variant="outlined"
                      className={classes.chip}
                      onDelete={
                        isViewOnly
                          ? undefined
                          : () => {
                              isDuplicate(false);
                              arrayHelpers.remove(index);
                            }
                      }
                    />
                  </li>
                ))}
              </CardContent>
            </>
          )}
        />
      </>
    </Card>
  );
};

export default SocialMediaMentions;
