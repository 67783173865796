import React from 'react';

import { TikTokSocialPage } from '../../../store/types';
import { BoxInfo } from '../InfluencerOverviewPerformance';

export const OverviewPerformanceTikTok = ({
  followers,
  following,
  likes,
  total_comments,
  videos_count,
  total_views,
  total_shares,
}: TikTokSocialPage) => {
  return (
    <>
      <BoxInfo
        label="FOLLOWERS"
        info="Total number of followers."
        stat={followers}
      />
      <BoxInfo
        label="FOLLOWING"
        info="Total number of accounts followed."
        stat={following}
      />
      <BoxInfo
        label="VIDEOS"
        info="Total number of videos."
        stat={videos_count}
      />
      <BoxInfo label="VIEWS" info="Total number of views." stat={total_views} />
      <BoxInfo label="LIKES" info="Total number of likes." stat={likes} />
      <BoxInfo
        label="SHARES"
        info="Total number of shares."
        stat={total_shares}
      />
      <BoxInfo
        label="COMMENTS"
        info="Total number of comments."
        stat={total_comments}
      />
    </>
  );
};
