import qs from 'query-string';
import { all, select,takeLatest } from 'redux-saga/effects';

import {
  BRAND_CREATION_SUBMITTED,
  CAMPAIGN_CREATION_STEP_ADVANCED,
  CAMPAIGN_CREATION_SUBMITTED,
  CAMPAIGNS_MOUNTED,
  INFLUENCERS_SEARCH_MOUNTED,
  INFLUENCERS_SEARCH_REQUEST_SUCCEEDED,
} from '../../store/action-types';
import { types } from '../../store/actions';
import mixpanelTrack from '../../utils/mixpanel-track';

/* eslint-disable require-yield */

function* mixpanelTrackUserSignedIn(args) {
  mixpanelTrack('User Signed In Regular');
}

function* mixpanelTrackUserTwitterSignedIn(args) {
  mixpanelTrack('User Signed In Twitter');
}

function* mixpanelTrackUserTwitchSignedIn(args) {
  mixpanelTrack('User Signed In Twitch');
}

function* mixpanelTrackUserYoutubeSignedIn(args) {
  mixpanelTrack('User Signed In Youtube');
}

function* mixpanelTrackCampaignsShow(args) {
  mixpanelTrack('Campaigns View');
}

function* mixpanelTrackInfluencerDiscoveryShow(args) {
  mixpanelTrack('Influencer Discovery View');
}

function* mixpanelTrackInfluencerSearchSucceeded(args) {
  let { influencerCount: result_influencer_count } = yield select(
    getInfluencerSearchData
  );

  const query = qs.parse(args.payload.params);

  delete query.key;
  delete query.limit;

  mixpanelTrack('Influencer Search', {
    ...query,
    result_influencer_count,
  });
}

function* mixpanelTrackCampaignStepAdvanced({ payload }) {
  const result = filterPayload(payload);

  const { step } = result;
  delete result.step;

  mixpanelTrack(`Campaign Step - ${step}`, result);
}

function* mixpanelTrackCampaignCompleted({ payload }) {
  const result = filterPayload(payload.values);

  mixpanelTrack(`Campaign Completed`, result);
}
function* mixpanelTrackBrandCompleted({ payload }) {
  const result = filterPayload(payload.values);

  mixpanelTrack(`Brand Created`, result);
}

function* mixpanelTrackAddToWatchlist() {
  mixpanelTrack(`Add To Watchlist`);
}

const filterPayload = (payload) => {
  return Object.keys(payload).reduce((acc, key) => {
    if (!!payload[key] && payload[key]._isAMomentObject) {
      acc[key] = payload[key].format();
    } else if (!!payload[key]) {
      acc[key] = payload[key];
    }

    return acc;
  }, {});
};

const getInfluencerSearchData = (state) => ({
  influencerCount:
    state.searchInfluencers.byId &&
    Object.keys(state.searchInfluencers.byId).length,
});

export default function* root() {
  yield all([
    takeLatest(types.USER_SIGNED_IN, mixpanelTrackUserSignedIn),
    takeLatest(types.SIGNIN_TWITTER, mixpanelTrackUserTwitterSignedIn),
    takeLatest(types.SIGNIN_TWITCH, mixpanelTrackUserTwitchSignedIn),
    takeLatest(types.SIGNIN_YOUTUBE, mixpanelTrackUserYoutubeSignedIn),
    takeLatest(types.ADD_TO_WATCHLIST, mixpanelTrackAddToWatchlist),
    takeLatest(types.MY_WATCHLIST_ADD_SUCCESS, mixpanelTrackAddToWatchlist),
    takeLatest(CAMPAIGNS_MOUNTED, mixpanelTrackCampaignsShow),
    takeLatest(
      INFLUENCERS_SEARCH_MOUNTED,
      mixpanelTrackInfluencerDiscoveryShow
    ),
    takeLatest(
      INFLUENCERS_SEARCH_REQUEST_SUCCEEDED,
      mixpanelTrackInfluencerSearchSucceeded
    ),
    takeLatest(
      CAMPAIGN_CREATION_STEP_ADVANCED,
      mixpanelTrackCampaignStepAdvanced
    ),
    takeLatest(CAMPAIGN_CREATION_SUBMITTED, mixpanelTrackCampaignCompleted),
    takeLatest(BRAND_CREATION_SUBMITTED, mixpanelTrackBrandCompleted),
  ]);
}
