import {
  Button,
  Card,
  CardContent,
  Chip,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import React, { useState } from 'react';

import * as regex from '../../../common/constants/regex';
import FormikMuiTextField from '../../../components/FormikMuiTextField';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipLabel: {
    maxWidth: 350,
  },
}));

export const Links = ({ brandId }) => {
  const classes = useStyles();
  const [duplicate, isDuplicate] = useState(false);
  const { values, errors, isValidating } = useFormikContext();
  const [newLink, setNewLink] = useState('');

  const onAdd = (arrayHelpers) => {
    if (!(newLink && !errors.newLink && !isValidating)) return;
    if (brandId) {
      if (
        !values.linksBrands
          .filter((el) => el.campaign_brand_id === brandId)
          .some((el) => el.link === newLink)
      ) {
        isDuplicate(false);
        arrayHelpers.push({
          link: newLink.trim(),
          campaign_brand_id: brandId && brandId,
        });
        setNewLink('');
      } else {
        isDuplicate(true);
      }
    } else {
      if (!values.links.some((el) => el.link === newLink)) {
        isDuplicate(false);
        arrayHelpers.push({
          link: newLink.trim(),
          campaign_brand_id: brandId && brandId,
        });
        setNewLink('');
      } else {
        isDuplicate(true);
      }
    }
  };
  return (
    <Card className={classes.container}>
      <CardContent>
        <Typography variant="h2">Link sharing</Typography>
      </CardContent>
      <FieldArray
        name={brandId ? 'linksBrands' : 'links'}
        render={(arrayHelpers) => (
          <>
            <CardContent>
              <div className={classes.inputContainer}>
                <FormikMuiTextField
                  fullWidth
                  placeholder="Add link"
                  variant="outlined"
                  value={newLink}
                  name="newLink"
                  onChange={(e) => {
                    setNewLink(e.target.value);
                    isDuplicate(false);
                  }}
                  className={classes.input}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        regex.URL.test(newLink) && onAdd(arrayHelpers);
                      }
                    },
                  }}
                />
                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  style={{ padding: 9.5 }}
                  disabled={
                    !newLink || !regex.URL.test(newLink) || isValidating
                  }
                  onClick={() => onAdd(arrayHelpers)}
                >
                  Add
                </Button>
              </div>
              {duplicate && (
                <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                  "{newLink}" is already added
                </p>
              )}
            </CardContent>
            <CardContent component="ul" className={classes.chipsContainer}>
              {brandId ? (
                <>
                  {values.linksBrands.map((l, index) => (
                    <>
                      {l.campaign_brand_id === brandId && (
                        <li key={index}>
                          <Chip
                            label={l.link}
                            variant="outlined"
                            className={classes.chip}
                            onDelete={() => {
                              arrayHelpers.remove(index);
                              l.link === newLink && isDuplicate(false);
                            }}
                            classes={{ label: classes.chipLabel }}
                            title={l.link}
                          />
                        </li>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {values.links.map((l, index) => (
                    <>
                      {!l.campaign_brand_id && (
                        <li key={index}>
                          <Chip
                            label={l.link}
                            variant="outlined"
                            className={classes.chip}
                            onDelete={() => {
                              arrayHelpers.remove(index);
                              l.link === newLink && isDuplicate(false);
                            }}
                            classes={{ label: classes.chipLabel }}
                            title={l.link}
                          />
                        </li>
                      )}
                    </>
                  ))}
                </>
              )}
            </CardContent>
          </>
        )}
      />
    </Card>
  );
};

export default Links;
