import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import MuiAlert from '@material-ui/lab/Alert';
import _sortBy from 'lodash/sortBy';
import { useRef, useState } from 'react';

import { addVideosToCampaign } from '../../ajax';
import facebookIcon from '../../assets-2.0/facebook.svg';
import instagramIcon from '../../assets-2.0/instagram.svg';
import tiktokIcon from '../../assets-2.0/tiktok.svg';
import twitchIcon from '../../assets-2.0/twitch.svg';
import youtubeIcon from '../../assets-2.0/youtube.svg';
import { getVideoIdAndPlatformFromUrl } from './linksUtils';

type VideoData = {
  id: string;
  platform: string;
};

export const CampaignAddVideosDialog = ({ campaignId }) => {
  const [open, setOpen] = useState(false);
  const [videoData, setVideoData] = useState<VideoData[]>([]);
  const [status, setStatus] = useState<{
    loadingState: 'idle' | 'loading' | 'success' | 'error';
    error?: string;
  }>({ loadingState: 'idle' });

  const statusRef = useRef(null);

  const onInputChange = (value: string) => {
    const data = getVideosDataFromUrls(value);
    setVideoData(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setVideoData([]);
    setStatus({ loadingState: 'idle', error: undefined });
    setOpen(false);
  };

  const onAddVideos = async (videos: VideoData[]) => {
    setStatus({ loadingState: 'loading' });

    const videosPayload = videos.map((video) => {
      return {
        video_id: video.id,
        network: video.platform,
        banner_algorithm: 'inherit',
        shoutout_algorithm: 'inherit',
      };
    });

    const result: { success: boolean; errors?: string[] } =
      await addVideosToCampaign(campaignId, videosPayload);

    if (result.success) {
      setStatus({ loadingState: 'success' });
    } else {
      setStatus({ loadingState: 'error', error: result.errors[0] });
    }

    statusRef.current?.scrollIntoView({ block: 'end' });
  };

  const getVideosDataFromUrls = (videoUrls: string): VideoData[] => {
    if (!videoUrls) {
      return [];
    }
    const videoUrlsArray = videoUrls.split(',');
    const videoUrlsArrayTrimmed = videoUrlsArray.map((url) => url.trim());

    const videos = videoUrlsArrayTrimmed
      .map((url) => {
        const { id, platform } = getVideoIdAndPlatformFromUrl(url) || {};

        return {
          id,
          platform,
        } as VideoData;
      })
      .filter((video) => !!video && !!video.id && !!video.platform);

    return videos;
  };

  const getSortedVideos = (videos: VideoData[]): VideoData[] => {
    return _sortBy(videos, 'platform');
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        style={{
          borderWidth: 2,
        }}
        onClick={handleClickOpen}
        startIcon={<VideoLibraryIcon />}
      >
        Add Videos
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Videos To Campaign
        </DialogTitle>
        <DialogContent>
          <div style={{ minWidth: '500px' }}>
            <TextareaAutosize
              style={{ width: '100%', marginBottom: '20px' }}
              aria-label="video urls"
              minRows={4}
              maxRows={10}
              placeholder="Add video URLs here separated by commas.&#10; Make sure the links are all valid. &#10;https://www.youtube.com/watch?v=abc,https://www.youtube.com/watch?v=def,https://www.twitch.tv/videos/123"
              onChange={(e) => onInputChange(e.target.value.trim())}
            />

            <MuiAlert severity="warning" elevation={0} variant="standard">
              Please only add up to <strong>900</strong> videos at a time,
              otherwise the service may fail!
            </MuiAlert>

            {videoData.length ? (
              <div>
                Detected <b>{videoData.length}</b> videos from parsing your
                input.
              </div>
            ) : null}
            {videoData.length > 900 ? (
              <MuiAlert severity="error" elevation={0} variant="standard">
                You've added <strong>{videoData.length}</strong> videos which is
                over the 900 limit.
              </MuiAlert>
            ) : null}

            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
              }}
            >
              {getSortedVideos(videoData).map((video, index) => {
                return <VideoCard key={video.id + index} video={video} />;
              })}

              <div ref={statusRef}>
                {status.loadingState === 'success' ? (
                  <MuiAlert severity="success" elevation={3} variant="filled">
                    Videos have been added successfully .
                  </MuiAlert>
                ) : null}

                {status.loadingState === 'error' ? (
                  <MuiAlert severity="error" elevation={3} variant="filled">
                    Error: {status.error}
                  </MuiAlert>
                ) : null}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: '12px 24px' }}
        >
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          {status.loadingState === 'success' ||
          status.loadingState === 'error' ? (
            <Button
              onClick={() => {
                handleClose();
              }}
              color="primary"
              variant="contained"
            >
              Done
            </Button>
          ) : (
            <Button
              onClick={() => {
                onAddVideos(videoData);
              }}
              disabled={!videoData.length || status.loadingState !== 'idle'}
              color="primary"
              variant="contained"
              endIcon={
                status.loadingState === 'loading' ? (
                  <CircularProgress style={{ width: 20, height: 20 }} />
                ) : null
              }
            >
              Add Videos
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const VideoCard = ({ video }: { video: VideoData }) => {
  const getPlatformIcon = (platform: VideoData['platform']) => {
    switch (platform) {
      case 'youtube':
        return youtubeIcon;
      case 'twitch':
        return twitchIcon;
      case 'facebook':
        return facebookIcon;
      case 'tiktok':
        return tiktokIcon;
      case 'instagram':
        return instagramIcon;
      default:
        return null;
    }
  };

  return (
    <Card variant="outlined">
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid transparent',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <div>
            <img
              width={24}
              height={24}
              style={{ marginRight: '10px' }}
              src={getPlatformIcon(video.platform)}
              alt="platform icon"
            />
            <b>{video.platform.toUpperCase()}</b> | Video ID:{' '}
            <span
              style={{
                backgroundColor: 'lightgray',
                borderRadius: 20,
                padding: 5,
              }}
            >
              {video.id}
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
