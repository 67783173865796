import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

import { oldGetSentiment } from '../helpers/get-sentiment';
import { oldSentimentMap } from '../helpers/sentiment-map';
import isNumber from '../utils/is-number';

const SentimentMeter = (props) => {
  const { mentions } = props;

  let totalSentiment = 0;
  let totalCount = 0;

  mentions.forEach(({ sentiment }) => {
    if (isNumber(sentiment)) {
      totalSentiment += sentiment;
      totalCount += 1;
    }
  });

  const averageSentiment = totalCount !== 0 ? totalSentiment / totalCount : 0;

  return (
    <ReactSpeedometer
      height={180}
      segments={200}
      maxSegmentLabels={0}
      minValue={-1}
      maxValue={1}
      startColor={'#ed675e'}
      endColor={'#85ce88'}
      // value={Number(averageSentiment.toFixed(4))}
      value={0}
      needleHeightRatio={0.8}
      needleColor="#c0bcb5"
      currentValueText={oldSentimentMap[oldGetSentiment(averageSentiment)]}
    />
  );
};

export default SentimentMeter;
