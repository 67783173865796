import moment from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from '../../../components/App';
import GlobalToast from '../../../components/GlobalToast';
import { history, persistor, store } from '../../../store';
import theme from '../../../theme.json';
import { ModalDialog } from '../../components';

class Main extends React.Component {
  componentDidCatch(e) {
    // window.alert('something went wrong')

    throw e;
  }

  render() {
    return (
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={moment}>
          <MuiThemeProvider
            theme={(function () {
              return createTheme(theme);
            })()}
          >
            <PersistGate loading={null} persistor={persistor}>
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
              <ModalDialog />
              <GlobalToast />
              <CssBaseline />
            </PersistGate>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    );
  }
}

export default Main;
