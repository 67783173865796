import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';

import { State } from '../../../../../store/types';
import { LoadingState, SocialMediaPlatform } from '../../../../../types';
import { setDateFilter } from '../../store/Stats.slice';
import { fetchSocialMediaPosts } from './SocialMediaPosts.thunks';
import { SLICE_NAME, SocialMediaOverview, SocialMediaPost } from './types';

export const socialMediaPostsAdapter = createEntityAdapter<SocialMediaPost>();

export const initialState = socialMediaPostsAdapter.getInitialState<{
  loading: LoadingState;
  overview: SocialMediaOverview;
  error: SerializedError | null;
  platformFilter: 'all' | SocialMediaPlatform;
}>({
  loading: LoadingState.IDLE,
  error: null,
  overview: null,
  platformFilter: 'all',
});

const socialMediaPostsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSocialPostsPlatformFilter: (
      state,
      action: PayloadAction<SocialMediaPostsState['platformFilter']>
    ) => {
      state.platformFilter = action.payload;
      // "INVALIDATE" the cached data by setting the loading state to IDLE
      state.loading = LoadingState.IDLE;
    },
    setSocialPostsDateFilter: (state) => {
      // "INVALIDATE" the cached data by setting the loading state to IDLE
      state.loading = LoadingState.IDLE;
    },
    forceSocialPostsReload: (state) => {
      state.loading = LoadingState.IDLE;
    },
  },
  extraReducers: (builder) => {
    // FULFILLED
    builder.addCase(fetchSocialMediaPosts.fulfilled, (state, action) => {
      const { social_posts, ...overviewData } = action.payload;

      socialMediaPostsAdapter.setAll(state, social_posts);
      state.overview = overviewData;

      state.loading = LoadingState.FULFILLED;
      state.error = null;
    });

    // PENDING
    builder.addCase(fetchSocialMediaPosts.pending, (state) => {
      state.loading = LoadingState.PENDING;
      state.error = null;
    });

    // REJECTED
    builder.addCase(fetchSocialMediaPosts.rejected, (state, action) => {
      state.loading = LoadingState.REJECTED;
      state.error = action.error;
    });

    builder.addCase(setDateFilter, (state) => {
      state.loading = LoadingState.IDLE;
    });
  },
});

export const {
  reducer,
  actions: {
    setSocialPostsPlatformFilter,
    setSocialPostsDateFilter,
    forceSocialPostsReload,
  },
} = socialMediaPostsSlice;
export type SocialMediaPostsState = typeof initialState;

// We have to export this selector that depends on the adapter from here (instead of .selectors.ts) in order to avoid circular dependency issues
export const selectAllSocialMediaPosts = socialMediaPostsAdapter.getSelectors(
  (state: State) => state[SLICE_NAME]
).selectAll;
