import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { brandDeleteClicked } from '../../../store/events';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 0,
    width: '100%',
    '&:first-child': {
      borderTop: 0,
    },
    margin: '0 !important',
  },
})(MuiAccordion);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  accordion: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  heightAccordionSummary: {
    height: '72px',
  },
  containerButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 24px',
  },
  modal: {
    position: 'fixed',
    left: 0,
    right: 25,
  },
}));

export default function AccordionMyBrands({
  openBrandName,
  handleSetBrandName,
  children,
  brand,
  isViewOnly,
  index,
}) {
  const dispatch = useDispatch();
  const { name, id } = brand;
  const deleteBrand = () => {
    setRemoveConfirmDialog(null);
    dispatch(brandDeleteClicked(id));
  };
  const [removeConfirmDialog, setRemoveConfirmDialog] = useState(null);

  const classes = useStyles();
  const RemoveDialog = ({ open, id, brandName, onClose }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove <strong>{brandName}</strong>? Please
          note that all the data associated with this brand will be deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: 'space-between', padding: '12px 24px' }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => deleteBrand(id)}
          color="primary"
          variant="outlined"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <Accordion expanded={openBrandName === brand.name}>
      <AccordionSummary
        onClick={() => !removeConfirmDialog && handleSetBrandName(brand.name)}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        data-id={`panel1a-header-${index}`}
        className={classes.heightAccordionSummary}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Box display="flex" alignItems="center">
            <Avatar className={classes.avatar}>
              {name?.trim()[0]?.toUpperCase()}
            </Avatar>
            <Typography
              style={{ fontWeight: 600, fontSize: 18 }}
              className={classes.heading}
            >
              {name}
            </Typography>
          </Box>
          {!isViewOnly && (
            <Box display="flex" alignItems="center">
              <DeleteIcon
                data-id={`delete-brand-${index}`}
                style={{ color: 'red', marginLeft: 'auto', cursor: 'pointer' }}
                onClick={(event) => {
                  event.stopPropagation();
                  setRemoveConfirmDialog(true);
                }}
              />
            </Box>
          )}
        </Box>
        <RemoveDialog
          open={removeConfirmDialog !== null}
          onClose={(event) => {
            event.stopPropagation();
            setRemoveConfirmDialog(null);
          }}
          brandName={removeConfirmDialog !== null ? name : ''}
          id={id}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
