import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as ajax from '../../../ajax';
import firebase from '../../../common/resources/firebase';
import AggeroCardHeader from '../../../components/AggeroCardHeader';
import CardIcons from '../../../components/CardIcons';
import Change from '../../../components/Change';
import Pending from '../../../components/Communication';
import Container from '../../../components/Container';
import InfluencerProfileOverviewCard from '../../../components/InfluencerProfileOverviewCard';
import SentimentMeter from '../../../components/SentimentMeter';
import toMentionFromInstagramPost from '../../../helpers/to-mention-from-instagram-post';
import toMentionFromRedditComment from '../../../helpers/to-mention-from-reddit-comment';
import toMentionFromTweet from '../../../helpers/to-mention-from-tweet';
import useRequest from '../../../hooks/use-request';
import {
  getCompanyDummy,
  getCompanyName,
  getUserFeatures,
} from '../../../store/models/user/user.selectors';
import selectors from '../../../store/selectors';
import average from '../../../utils/average';
import isNumber from '../../../utils/is-number';
import promiseAllFailsafe from '../../../utils/promise-all-failsafe';
import through from '../../../utils/through';

const useStyles = makeStyles({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  grower: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const OverviewContext = React.createContext({});

export default function Overview() {
  document.title = 'Overview';

  const { t } = useTranslation();

  const dummy = useSelector(getCompanyDummy);
  const userEmail = useSelector(selectors.getUserEmail);
  const companyName = useSelector(getCompanyName);
  const features = useSelector(getUserFeatures);
  const { organization_id } = useSelector(selectors.getUser);

  const {
    data: mentions = [],
    succeeded: mentionsSucceeded,
    init: initMentions,
  } = useRequest((companyId) =>
    promiseAllFailsafe([
      firebase.getBrandTweetsOneMonth(companyId, dummy ? 134 : null), // 134 - random number
      firebase.getBrandRedditCommentsOneMonth(companyId, dummy ? 147 : null), // 147 - random number
      ajax.getInstagramPostsByTag(companyId, { time: '1_month' }),
    ]).then(([tweets, redditComments, instagramPosts]) => {
      return [].concat(
        instagramPosts.map(toMentionFromInstagramPost),
        redditComments.map(toMentionFromRedditComment),
        tweets.filter(({ is_reply }) => !is_reply).map(toMentionFromTweet)
      );
    })
  );

  const { data: brand, init: initBrand } = useRequest(
    firebase.getBrandPageData
  );

  const {
    data: recommendedResult,
    succeeded: influencersSucceeded,
    init: initInfluencers,
  } = useRequest(ajax.getRecommendedInfluencers);

  const { results } = recommendedResult || {};

  const [influencers, setInfluencers] = useState(null);

  const [isRequestRunning, setIsRequestRunning] = useState(false);

  useEffect(() => setInfluencers(results?.results), [results]);

  useEffect(() => {
    firebase.getCompanyIdByEmail(userEmail).then((companyId) => {
      initBrand(companyId);
      initMentions(companyId);
      initInfluencers(companyId, { limit: 6, organization_id });
    });
  }, []);

  const mentionsLastWeek = useMemo(() => {
    const lastWeek = moment().subtract(7, 'days');

    const twoWeeksAgo = moment().subtract(14, 'days');

    return mentions.filter(
      ({ date }) => date && moment(date * 1000).isBetween(twoWeeksAgo, lastWeek)
    );
  }, [mentions]);

  const mentionsLast2Weeks = useMemo(() => {
    const lastWeek = moment().subtract(14, 'days');

    const twoWeeksAgo = moment().subtract(21, 'days');

    return mentions.filter(
      ({ date }) => date && moment(date * 1000).isBetween(twoWeeksAgo, lastWeek)
    );
  }, [mentions]);

  const numberOfMentions = useMemo(() => {
    if (brand && brand.limit_to_past) {
      return mentions.filter(
        ({ date }) =>
          date &&
          moment(date * 1000).isAfter(
            moment().subtract(brand.limit_to_past.replace('d', ''), 'days')
          )
      ).length;
    }

    return mentions.length;
  });

  // const mentionsThisWeek = useMemo(
  //   () => {
  //     const today = moment()

  //     const lastWeek = moment().subtract(7, 'days')

  //     return mentions.filter(
  //         ({ date }) => date && moment(date * 1000).isBetween(lastWeek, today)
  //       )
  //   },
  //   [ mentions ]
  // )

  const numberOfMentionsInclining =
    mentionsLastWeek.length > mentionsLast2Weeks.length;

  const overview = {
    brand,
    mentions,
    mentionsLast2Weeks,
    mentionsLastWeek,
    numberOfMentionsInclining,
  };

  const classes = useStyles();

  return (
    <OverviewContext.Provider value={overview}>
      <Container>
        <Grid container spacing={4} direction="column">
          {features.brand_insights !== false && (
            <>
              <Grid item>
                <Grid container spacing={0} justify="space-between">
                  <Grid item>
                    <Typography variant="h2">
                      <b>{companyName}</b> insights
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className="fs-i"
                    >
                      Showing stats for the last month
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={4} alignItems="stretch">
                  <Grid item xs={12} md={4}>
                    <Card className={classes.card} style={{ minHeight: 360 }}>
                      <AggeroCardHeader
                        title="NUMBER OF MENTIONS"
                        info="The total number of brand/product mentions"
                      />

                      <Pending ready={mentionsSucceeded}>
                        {() => (
                          <>
                            <CardContent style={{ paddingTop: 0 }}>
                              <Box display="flex" alignItems="center">
                                <Change
                                  percent
                                  before={mentionsLast2Weeks.length}
                                  after={mentionsLastWeek.length}
                                />
                                <Box ml={1}>Weekly change</Box>
                              </Box>
                            </CardContent>
                            <CardContent className={classes.grower}>
                              <div className="number-chart">
                                <p className="number-chart-digit">
                                  {numberOfMentions}
                                </p>
                                <p className="number-chart-text">mentions</p>
                              </div>
                            </CardContent>
                          </>
                        )}
                      </Pending>
                      <CardIcons twitter reddit instagram />
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card className={classes.card}>
                      <AggeroCardHeader
                        title="OVERALL SENTIMENT"
                        info="The overall sentiment generated by the comments mentioning your brand/product."
                      />
                      <Pending ready={mentionsSucceeded}>
                        {() => (
                          <>
                            <CardContent style={{ paddingTop: 0 }}>
                              <Box display="flex" alignItems="center">
                                <Change
                                  percent
                                  before={
                                    average(
                                      mentionsLast2Weeks
                                        .map(({ sentiment }) => sentiment)
                                        .filter(isNumber)
                                    ) + 1
                                  }
                                  after={
                                    average(
                                      mentionsLastWeek
                                        .map(({ sentiment }) => sentiment)
                                        .filter(isNumber)
                                    ) + 1
                                  }
                                />
                                <Box ml={1}>Weekly change</Box>
                              </Box>
                            </CardContent>
                            <Box clone textAlign="center">
                              <CardContent className={classes.grower}>
                                <SentimentMeter mentions={mentions} />
                              </CardContent>
                            </Box>
                          </>
                        )}
                      </Pending>
                      <CardIcons twitter reddit instagram />
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {features.discovery !== false && (
            <>
              <Grid item>
                <Typography variant="h2">
                  {t('overview.discovery.title')}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={4}>
                  <Pending
                    ready={influencersSucceeded && Array.isArray(influencers)}
                    fallback={through(0, 3).map((i) => (
                      <Grid item xs={12} sm={6} md={4} key={i}>
                        <Box
                          clone
                          height={400}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Card>
                            <CircularProgress />
                          </Card>
                        </Box>
                      </Grid>
                    ))}
                  >
                    {() =>
                      influencers?.map((influencer, i) =>
                        influencer ? (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={influencer.login}
                          >
                            <InfluencerProfileOverviewCard
                              {...influencer}
                              influencers={influencers}
                              setInfluencers={setInfluencers}
                              organization_id={organization_id}
                              isRequestRunning={isRequestRunning}
                              setIsRequestRunning={setIsRequestRunning}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} md={4} key={i}>
                            <Box
                              clone
                              height={345}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Card>
                                <CircularProgress />
                              </Card>
                            </Box>
                          </Grid>
                        )
                      )
                    }
                  </Pending>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </OverviewContext.Provider>
  );
}
