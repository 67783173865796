import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import folderIcon from '../../../../assets-2.0/folder.svg';
import { PROFILE } from '../../../../common/constants/paths';
import { UnlockBanner } from '../../../../components/UnlockBanner/UnlockBanner';
import { WaitingBanner } from '../../../../components/WaitingBanner/WaitingBanner';
import { useFetchOnMount } from '../../../../hooks/useFetchOnMount';
import {
  selectInfluencerHasStreamingAccounts,
  selectInfluencerInfoShowLoading,
} from '../../store/InfluencerInfo.selectors';
import { selectDateFilter } from '../store/Stats.selectors';
import {
  selectShouldFetchStreams,
  selectStreamsAreLoading,
  selectStreamsHasError,
  selectStreamsOverview,
} from './store/Streams.selectors';
import { fetchStreams } from './store/Streams.thunks';
import { StreamingOverview } from './StreamingOverview';
import { StreamingTable } from './StreamingTable';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
    [theme.breakpoints.down('sm')]: {
      gap: 20,
    },
  },

  bannerContainer: {
    display: 'flex',
    padding: '0px 100px',

    [theme.breakpoints.down('md')]: {
      padding: '0px 20px',
    },
  },
}));

export const Streaming = () => {
  const classes = useStyles();
  const history = useHistory();
  const [modalStreamSegment, setModalStreamSegment] = useState(null);

  useFetchOnMount(selectShouldFetchStreams(), fetchStreams);

  const hasStreamingAccounts = useSelector(
    selectInfluencerHasStreamingAccounts()
  );
  const filterByDate = useSelector(selectDateFilter());
  const hasError = useSelector(selectStreamsHasError());
  const streamsOverview = useSelector(selectStreamsOverview());
  const streamsLoading = useSelector(selectStreamsAreLoading());
  const influencerInfoLoading = useSelector(selectInfluencerInfoShowLoading());

  const isLoading = streamsLoading || influencerInfoLoading;
  let bluredData = false;

  if (hasError) {
    return (
      <Alert severity="error">
        There was an error while fetching your social media data. Please try
        again later.
      </Alert>
    );
  }

  const viewBanner = () => {
    if (!hasStreamingAccounts) {
      return (
        <UnlockBanner
          title="Connect Your Streaming Channels"
          bodyText="Connect your Twitch or YouTube accounts to understand your streaming performance"
          onCTA={() => {
            history.push(`${PROFILE}?socialCallback=true`);
          }}
        />
      );
    }
    // If the user has streaming accounts connected but there are no streams after fetching the data we display this info
    if (
      !isLoading &&
      streamsOverview.streamsCount === 0 &&
      hasStreamingAccounts
    ) {
      bluredData = true;
      return (
        <WaitingBanner bodyText=" We are still processing your streaming data. Please try again later." />
      );
    }
  };

  return (
    <div className={classes.container}>
      {(!!filterByDate?.start_date || !!filterByDate?.end_date) &&
      !!hasStreamingAccounts &&
      streamsOverview.streamsCount === 0 ? (
        <WaitingBanner
          icon={folderIcon}
          title="No Results Found"
          bodyText="There are no posts for the selected time period. Try a different time frame."
        />
      ) : (
        <>
          <div className={classes.bannerContainer}>{viewBanner()}</div>
          <StreamingOverview isLoading={isLoading} bluredData={bluredData} />
          {!isLoading && (
            <StreamingTable
              modalStreamSegment={modalStreamSegment}
              setModalStreamSegment={setModalStreamSegment}
              bluredData={bluredData}
            />
          )}
        </>
      )}
    </div>
  );
};
