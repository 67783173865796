import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { Pie } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { ScaleOrdinal } from 'd3-scale';
import React, { useRef } from 'react';

export const GENDER_COLOR_SCALE = scaleOrdinal({
  domain: ['Male', 'Female'],
  range: ['#279edf', '#ff977d'],
});

export const AGE_COLOR_SCALE = scaleOrdinal({
  domain: ['12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65-200'],
  range: [
    '#174EA8',
    '#1E5FCA',
    '#3677E2',
    '#6194E9',
    '#8CB0EF',
    '#C3D6F7',
    '#E3EAF7',
  ],
});

const getValue = (data: Datum) => data.value;

const DONUT_THINKESS = 64;
const LEGEND_GLYPH_SIZE = 12;
const LEGEND_HEIGHT = 48;

const useAudienceDonutChartStyles = makeStyles<
  Theme,
  { width: number; height: number }
>(() => ({
  container: ({ width, height }) => ({
    width,
    height,
    position: 'relative',
  }),
  legendContainer: {
    height: LEGEND_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

type Datum = {
  value: number;
  label: string;
};

interface AudienceDonutChartProps {
  width: number;
  height: number;
  data: Datum[];
  colorScale: ScaleOrdinal<string, string>;
}

export const AudienceDonutChart = React.memo(
  ({ width, height, data, colorScale }: AudienceDonutChartProps) => {
    const classes = useAudienceDonutChartStyles({ width, height });

    const svgRef = useRef();

    const {
      showTooltip,
      hideTooltip,
      tooltipOpen,
      tooltipData,
      tooltipLeft,
      tooltipTop,
    } = useTooltip<Datum>();

    const donutHeight = height - LEGEND_HEIGHT;

    const radius = Math.min(width, donutHeight) / 2;

    const centerY = donutHeight / 2;
    const centerX = width / 2;

    // ParentSize initialy sends 0
    if (width < 10) return null;

    return (
      <div className={classes.container}>
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <div className={classes.legendContainer}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-quantile-${i}`} margin="0 5px">
                  <svg width={LEGEND_GLYPH_SIZE} height={LEGEND_GLYPH_SIZE}>
                    <rect
                      fill={label.value}
                      width={LEGEND_GLYPH_SIZE}
                      height={LEGEND_GLYPH_SIZE}
                    />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
        <svg width={width} height={donutHeight} ref={svgRef}>
          <rect width={width} height={donutHeight} fill="#fff" />
          <Group top={centerY} left={centerX}>
            <Pie
              data={data}
              pieValue={getValue}
              outerRadius={radius}
              innerRadius={radius - DONUT_THINKESS}
              padAngle={0.005}
              cornerRadius={3}
            >
              {({ arcs, path }) =>
                arcs.map((arc) => {
                  const onMouseMove = (
                    event: React.MouseEvent | React.TouchEvent
                  ) => {
                    const point = localPoint(svgRef.current, event);

                    showTooltip({
                      tooltipData: arc.data,
                      tooltipLeft: point.x,
                      tooltipTop: point.y,
                    });
                  };
                  return (
                    <path
                      d={path(arc)}
                      fill={colorScale(arc.data.label)}
                      onMouseMove={onMouseMove}
                      onTouchMove={onMouseMove}
                      onMouseLeave={hideTooltip}
                      onTouchEnd={hideTooltip}
                    />
                  );
                })
              }
            </Pie>
          </Group>
        </svg>
        {tooltipOpen &&
          tooltipData &&
          tooltipLeft != null &&
          tooltipTop != null && (
            <TooltipWithBounds left={tooltipLeft + 10} top={tooltipTop + 10}>
              <strong>{tooltipData.label}</strong>: {tooltipData.value}%
            </TooltipWithBounds>
          )}
      </div>
    );
  }
);
