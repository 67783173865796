import MuiLinearProgress from '@material-ui/core/LinearProgress';
import { darken, lighten, withStyles } from '@material-ui/core/styles';

const styles = (theme) => {
  function getColor(color) {
    return theme.palette.type === 'light'
      ? lighten(color, 0.9)
      : darken(color, 0.5);
  }

  return {
    colorPrimary: {
      backgroundColor: getColor(theme.palette.primary.main),
    },
    colorSecondary: {
      backgroundColor: getColor(theme.palette.secondary.main),
    },
  };
};

export default withStyles(styles)(MuiLinearProgress);
