import { capitalize } from 'lodash';

import { getCountryName } from '../../../utils/country-list-audience';
import { Campaign } from '../types';
import { CampaignInfluencer } from '../types';
import { findInfluencerById, findInfluencerByName } from '../utils';
import { TopMoment } from './types';

export const getTwitchVideoIdFromUrl = (url: string) => {
  const regex = /(?:videos\/|video\/|v\/|\/v\/|twitch.tv\/videos\/)([0-9]+)/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    return null; // URL format doesn't match or video ID not found
  }
};

export const getYoutubeVideoIdFromUrl = (url: string) => {
  const regex =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regex);

  return match && match[7].length === 11 ? match[7] : false;
};

export const getTikTokVideoIdFromUrl = (url: string) => {
  const regex = /(?:videos\/|video\/|v\/|\/v\/|tiktok.com\/videos\/)([0-9]+)/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    return null; // URL format doesn't match or video ID not found
  }
};

export const getVideoIdFromUrl = (url: string) => {
  const twitchVideoId = getTwitchVideoIdFromUrl(url);
  if (twitchVideoId) {
    return twitchVideoId;
  }

  const youtubeVideoId = getYoutubeVideoIdFromUrl(url);
  if (youtubeVideoId) {
    return youtubeVideoId;
  }

  const tiktokVideoId = getTikTokVideoIdFromUrl(url);
  if (tiktokVideoId) {
    return tiktokVideoId;
  }

  return null;
};

export const getVideoIdByMoment = (moment: TopMoment) => {
  if (moment.video_id) {
    return moment.video_id;
  }

  return getVideoIdFromUrl(moment['moment in video']);
};

export const getVideoByMoment = (
  moment: TopMoment,
  campaignVideos: Campaign['videos']
) => {
  const external_id = getVideoIdByMoment(moment);

  if (external_id) {
    return campaignVideos.find(
      (video) => video.external_id?.toString() === external_id.toString()
    );
  }

  return null;
};

export const findInfluencerByMoment = (
  moment: TopMoment,
  influencers: CampaignInfluencer[]
) => {
  if (moment.campaign_influencer_id) {
    return findInfluencerById(moment.campaign_influencer_id, influencers);
  }

  return findInfluencerByName(moment.streamer, influencers);
};

export const getTopics = (data: TopMoment[]) => {
  const topics = data.map((moment) => moment.topic);

  return [...new Set(topics)].map((topic) => {
    return {
      name: capitalize(topic),
    };
  });
};

export const getCategories = (data: TopMoment[]) => {
  const categories = data.map((moment) => moment.category);

  return [...new Set(categories)].map((category) => {
    return {
      name: capitalize(category),
    };
  });
};

export const getRegions = (data: TopMoment[]) => {
  const regions = data.map((moment) => moment.region);

  return [...new Set(regions)].map((region) => {
    return {
      name: region?.toUpperCase(),
      label: getCountryName(region),
    };
  });
};

export const getPlatforms = (data: TopMoment[]) => {
  const platforms = data.map((moment) => moment?.platform);

  return [...new Set(platforms)].map((platform) => {
    return {
      name: capitalize(platform),
    };
  });
};
