import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles({ root: { fontWeight: 'normal' } });

function SectionTitle(props) {
  const classes = useStyles();

  return <Typography variant="h2" className={classes.root} {...props} />;
}

export default SectionTitle;
