import { makeStyles } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  container: {
    background: 'white',

    border: 'solid 1px #ddd',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '4px',
  },
}));
const SectionContainer = ({ children, isSponsorship }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      style={{ margin: isSponsorship ? '32px 0px 0px 0px' : '16px' }}
    >
      {children}
    </div>
  );
};

export default SectionContainer;
