import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { connect, FieldArray } from 'formik';
import React, { useState } from 'react';

import * as regex from '../../common/constants/regex';
import FileInput from '../../components/FileInput';
import FormikMuiTextField from '../../components/FormikMuiTextField';
import ImagePreview from '../../components/ImagePreview';
import SideLine from '../../components/Sideline';
import readFileAsDataURL from '../../utils/readFileAsDataURL';
import ShoutoutsV2 from './ShoutoutsV2/ShoutoutsV2';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 16,
  },
  bannerPadding: {
    marginTop: 12,
    paddingTop: 12,
  },
  trialDisabled: {
    padding: '4px',
    width: '100%',
    pointerEvents: 'none',
    backgroundColor: '#ccc',
  },
  chipsContainer: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.6),
    maxWidth: 340,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  commaSplit: {
    paddingLeft: '8px',
  },
  description: {
    height: 72,
  },
}));

const enhance = connect;

function CampaignWizardDeliverables(props) {
  const {
    formik: { values, handleChange, setFieldValue, errors },
    trialAccount,
    organizationRestrictions: {
      ui_disable_shoutouts,
      ui_disable_banners,
      ui_banner_limit_per_campaign,
    },
    checkedBrands,
    setCheckedBrands,
    item,
    brandForm,
    index,
    setEmptyCheckedBrands,
  } = props;

  const [wrongFileType, setWrongFileType] = useState(false);
  const [duplicate, isDuplicate] = useState({
    voice: false,
    links: false,
    chat: false,
    social: false,
  });
  function handleFileChange(name) {
    return async function (file) {
      setFieldValue(name, await readFileAsDataURL(file));
    };
  }
  const handleClick = (brand, value, index) => {
    if (index) {
      setFieldValue(
        `values.brands[${index}][${values}]`,
        !values.brands[index][values]
      );
    }

    if (Object.keys(checkedBrands).includes(brand.brandName)) {
      if (brand[value]) {
        checkedBrands[brand.brandName].splice(
          checkedBrands[brand.brandName].indexOf(value),
          1
        );
        if (index || index === 0) {
          setEmptyCheckedBrands((prev) => !prev);
        }
      } else {
        const obj = {
          ...checkedBrands,
          [brand.brandName]: [
            ...new Set([value, ...checkedBrands[brand.brandName]]),
          ],
        };
        setCheckedBrands({ ...obj });
      }
    } else {
      setCheckedBrands({ [brand.brandName]: [value], ...checkedBrands });
    }
  };

  const handleDelete = (brand, value, index) => {
    if (values.brands[index][value]) {
      setCheckedBrands({
        ...checkedBrands,
        [brand.brandName]: checkedBrands[brand.brandName].filter(
          (el) => el !== value
        ),
      });
    }
  };
  const classes = useStyles();
  function renderShoutout() {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          className={clsx({
            [classes.trialDisabled]: trialAccount || ui_disable_shoutouts,
          })}
        >
          {/* VOICE MENTIONS */}
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <Checkbox
                color="primary"
                checked={
                  brandForm
                    ? values.brands[index].shoutoutChecked
                    : values.shoutoutChecked
                }
                onClick={() =>
                  brandForm && handleClick(item, `shoutoutChecked`, index)
                }
                onChange={handleChange(
                  brandForm
                    ? `brands[${index}].shoutoutChecked`
                    : 'shoutoutChecked'
                )}
              />
            }
            label="Voice mentions"
          />
          <Typography className={classes.description}>
            Track the number of times a certain keyword or keyphrase was
            mentioned in the videos streamed during the campaign
          </Typography>
        </Grid>
        <Grid
          item
          style={{ marginTop: 16 }}
          className={clsx({
            [classes.trialDisabled]: trialAccount || ui_disable_shoutouts,
          })}
        >
          <ShoutoutsV2
            item={item}
            value={`shoutoutChecked`}
            handleClick={handleClick}
            handleDelete={handleDelete}
            brandForm={brandForm}
            index={index}
            disabled={trialAccount || ui_disable_shoutouts}
            id={item ? item.id : `new`}
          />
        </Grid>
      </Grid>
    );
  }
  function renderSocialMediaMention() {
    const onAdd = (arrayHelpers) => {
      if (brandForm) {
        const content =
          values.brands[index].createSocialMediaMentionContent.split(',');
        content.forEach((el, index) => {
          content[index] = el.trim().toLowerCase();
        });
        new Set(content).forEach((newContent) => {
          if (!newContent) return;
          if (
            !values.brands[index].socialMediaMentionContent.includes(
              newContent.toLowerCase()
            )
          ) {
            isDuplicate({ ...duplicate, social: false });
            arrayHelpers.push(newContent);
            setFieldValue(
              `brands[${index}].createSocialMediaMentionContent`,
              ''
            );
          } else {
            isDuplicate({ ...duplicate, social: true });
          }
        });
      } else {
        const content = values.createSocialMediaMentionContent.split(',');
        content.forEach((el, index) => {
          content[index] = el.trim().toLowerCase();
        });
        new Set(content).forEach((newContent) => {
          if (!newContent) return;
          if (!values.socialMediaMentionContent.includes(newContent)) {
            isDuplicate({ ...duplicate, social: false });
            arrayHelpers.push(newContent);
            setFieldValue('createSocialMediaMentionContent', '');
          } else {
            setFieldValue('createSocialMediaMentionContent', newContent);
            isDuplicate({ ...duplicate, social: true });
          }
        });
      }
    };
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item className={clsx({ [classes.trialDisabled]: trialAccount })}>
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <Checkbox
                color="primary"
                checked={
                  brandForm
                    ? values.brands[index].socialMediaMentionChecked
                    : values.socialMediaMentionChecked
                }
                onClick={() =>
                  brandForm &&
                  handleClick(item, `socialMediaMentionChecked`, index)
                }
                onChange={handleChange(
                  brandForm
                    ? `brands[${index}].socialMediaMentionChecked`
                    : 'socialMediaMentionChecked'
                )}
                value="true"
              />
            }
            label="Social posts mentions"
          />
          <Typography>
            Track the posts from Twitter, Instagram, and TikTok where the
            keyword or keyphrase was mentioned
          </Typography>
        </Grid>
        <FieldArray
          name={
            brandForm
              ? `brands[${index}].socialMediaMentionContent`
              : 'socialMediaMentionContent'
          }
          render={(arrayHelpers) => (
            <>
              <Grid
                item
                className={clsx({
                  [classes.trialDisabled]: trialAccount,
                })}
              >
                <FormLabel className={classes.formLabelStyle}>
                  Content
                </FormLabel>
                <div className={classes.inputContainer}>
                  <FormikMuiTextField
                    fullWidth
                    placeholder="Add keyword"
                    variant="outlined"
                    onChange={(e) => {
                      setFieldValue(
                        brandForm
                          ? `brands[${index}].createSocialMediaMentionContent`
                          : 'createSocialMediaMentionContent',
                        e.target.value
                      );
                      isDuplicate({ ...duplicate, social: false });
                    }}
                    value={
                      brandForm
                        ? values.brands[index].createSocialMediaMentionContent
                        : values.createSocialMediaMentionContent
                    }
                    name={
                      brandForm
                        ? `brands[${index}].createSocialMediaMentionContent`
                        : 'createSocialMediaMentionContent'
                    }
                    className={classes.input}
                    inputProps={{
                      onKeyDown: (e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          onAdd(arrayHelpers);
                          brandForm &&
                            handleClick(item, `socialMediaMentionChecked`);
                          setFieldValue(
                            brandForm
                              ? `brands[${index}].socialMediaMentionChecked`
                              : 'socialMediaMentionChecked',
                            true
                          );
                        }
                      },
                      data_id: item ? `input-sm-${item.id}` : `input-sm-new`,
                    }}
                  />
                  <Button
                    data-id={item ? `button-sm-${item.id}` : `button-sm-new`}
                    size="large"
                    variant="outlined"
                    color="primary"
                    style={{ padding: 9.5 }}
                    disabled={
                      brandForm
                        ? !values.brands[
                            index
                          ].createSocialMediaMentionContent.trim()
                        : !values.createSocialMediaMentionContent.trim()
                    }
                    onClick={() => {
                      onAdd(arrayHelpers);
                      brandForm &&
                        handleClick(item, `socialMediaMentionChecked`);
                      setFieldValue(
                        brandForm
                          ? `brands[${index}].socialMediaMentionChecked`
                          : 'socialMediaMentionChecked',
                        true
                      );
                    }}
                  >
                    Add
                  </Button>
                </div>
                {duplicate.social && (
                  <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                    "
                    {brandForm
                      ? values.brands[index].createSocialMediaMentionContent
                      : values.createSocialMediaMentionContent}
                    " is already added
                  </p>
                )}
                <CardContent component="ul" className={classes.chipsContainer}>
                  {brandForm ? (
                    <>
                      {values.brands[index].socialMediaMentionContent
                        .filter((v) => !v.organization_brand_id)
                        .map((content, i) => (
                          <li
                            key={i}
                            data-id={
                              item
                                ? `chip-sm-${item.id}-${i}`
                                : `chip-sm-new-${i}`
                            }
                          >
                            <Chip
                              label={content}
                              variant="outlined"
                              className={classes.chip}
                              onDelete={() => {
                                arrayHelpers.remove(i);
                                if (
                                  values.brands &&
                                  values.brands[index].socialMediaMentionContent
                                    .length === 1
                                ) {
                                  setFieldValue(
                                    `brands[${index}].socialMediaMentionChecked`,
                                    false
                                  );
                                  handleDelete(
                                    item,
                                    `socialMediaMentionChecked`,
                                    index
                                  );
                                }
                                content ===
                                  values.brands[index]
                                    .createSocialMediaMentionContent &&
                                  isDuplicate({ ...duplicate, social: false });
                              }}
                            />
                          </li>
                        ))}
                    </>
                  ) : (
                    <>
                      {values.socialMediaMentionContent
                        .filter((v) => !v.organization_brand_id)
                        .map((content, index) => (
                          <li key={index}>
                            <Chip
                              label={content}
                              variant="outlined"
                              className={classes.chip}
                              onDelete={() => {
                                arrayHelpers.remove(index);
                                content ===
                                  values.createSocialMediaMentionContent &&
                                  isDuplicate({ ...duplicate, social: false });
                                if (
                                  values.socialMediaMentionContent.length === 1
                                ) {
                                  setFieldValue(
                                    'socialMediaMentionChecked',
                                    false
                                  );
                                }
                              }}
                            />
                          </li>
                        ))}
                    </>
                  )}
                </CardContent>
              </Grid>
            </>
          )}
        />

        {trialAccount && (
          <Typography className="fs-i" color="textSecondary">
            This feature is disabled for trial account
          </Typography>
        )}
        {trialAccount && (
          <Typography className="fs-i" color="textSecondary">
            This feature is disabled for trial account
          </Typography>
        )}
      </Grid>
    );
  }
  function renderLinkSharing() {
    const onAdd = (arrayHelpers) => {
      if (brandForm) {
        if (!values.brands[index].newLink.trim()) return;
        if (
          !values.brands[index].links.includes(values.brands[index].newLink)
        ) {
          isDuplicate({ ...duplicate, links: false });
          arrayHelpers.push(values.brands[index].newLink.trim());
          setFieldValue(`brands[${index}].newLink`, '');
        } else {
          isDuplicate({ ...duplicate, links: true });
        }
      } else {
        if (!values.newLink.trim()) return;
        if (!values.links.includes(values.newLink)) {
          isDuplicate({ ...duplicate, links: false });
          arrayHelpers.push(values.newLink.trim());
          setFieldValue('newLink', '');
        } else {
          isDuplicate({ ...duplicate, links: true });
        }
      }
    };
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <Checkbox
                color="primary"
                checked={
                  brandForm
                    ? values.brands[index].linkSharingChecked
                    : values.linkSharingChecked
                }
                onClick={() =>
                  brandForm && handleClick(item, `linkSharingChecked`, index)
                }
                onChange={handleChange(
                  brandForm
                    ? `brands[${index}].linkSharingChecked`
                    : 'linkSharingChecked'
                )}
                value="true"
              />
            }
            label="Link sharing"
          />
          <Typography className={classes.description}>
            Track the number of times a URL was shared in the live chat during
            the campaign and get to know the users who shared it
          </Typography>
        </Grid>
        <FieldArray
          name={brandForm ? `brands[${index}].links` : 'links'}
          render={(arrayHelpers) => (
            <Grid
              item
              className={clsx({
                [classes.trialDisabled]: trialAccount,
              })}
            >
              <FormLabel className={classes.formLabelStyle}>Links</FormLabel>
              <div className={classes.inputContainer}>
                <FormikMuiTextField
                  fullWidth
                  placeholder="Add link"
                  variant="outlined"
                  onChange={(e) => {
                    setFieldValue(
                      brandForm ? `brands[${index}].newLink` : 'newLink',
                      e.target.value
                    );
                    isDuplicate({ ...duplicate, links: false });
                  }}
                  value={
                    brandForm ? values.brands[index].newLink : values.newLink
                  }
                  name={brandForm ? `brands[${index}].newLink` : 'newLink'}
                  className={classes.input}
                  inputProps={{
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        if (
                          regex.URL.test(
                            brandForm
                              ? values.brands[index].newLink
                              : values.newLink
                          )
                        ) {
                          onAdd(arrayHelpers);
                          brandForm && handleClick(item, `linkSharingChecked`);
                          setFieldValue(
                            brandForm
                              ? `brands[${index}].linkSharingChecked`
                              : 'linkSharingChecked',
                            true
                          );
                        }
                      }
                    },
                    'data-id': item
                      ? `input-links-${item.id}`
                      : `input-links-new`,
                  }}
                />
                <Button
                  data-id={
                    item ? `button-links-${item.id}` : `button-links-new`
                  }
                  size="large"
                  variant="outlined"
                  color="primary"
                  style={{ padding: 9.5 }}
                  disabled={
                    brandForm
                      ? !regex.URL.test(values.brands[index].newLink) ||
                        !values.brands[index].newLink
                      : !regex.URL.test(values.newLink) || !values.newLink
                  }
                  onClick={() => {
                    regex.URL.test(
                      brandForm ? values.brands[index].newLink : values.newLink
                    ) && onAdd(arrayHelpers);
                    brandForm && handleClick(item, `linkSharingChecked`);
                    setFieldValue(
                      brandForm
                        ? `brands[${index}].linkSharingChecked`
                        : 'linkSharingChecked',
                      true
                    );
                  }}
                >
                  Add
                </Button>
              </div>
              {duplicate.links && (
                <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                  "{brandForm ? values.brands[index].newLink : values.newLink}"
                  is already added
                </p>
              )}
              {!regex.URL.test(
                brandForm ? values.brands[index].newLink : values.newLink
              ) &&
                (brandForm ? values.brands[index].newLink : values.newLink) && (
                  <p style={{ color: 'red' }}>Not a valid URL</p>
                )}
              {brandForm ? (
                <CardContent component="ul" className={classes.chipsContainer}>
                  {values.brands[index].links.map((keyword, i) => (
                    <li
                      key={i}
                      data-id={
                        item
                          ? `chip-links-${item.id}-${i}`
                          : `chip-links-new-${i}`
                      }
                    >
                      <Chip
                        label={keyword}
                        variant="outlined"
                        className={classes.chip}
                        onDelete={() => {
                          if (values.brands[index]?.links.length === 1) {
                            setFieldValue(
                              `brands[${index}].linkSharingChecked`,
                              false
                            );
                            handleDelete(item, `linkSharingChecked`, index);
                          }
                          arrayHelpers.remove(i);
                          keyword === values.brands[index].newLink &&
                            isDuplicate({ ...duplicate, links: false });
                        }}
                        classes={{ label: classes.chipLabel }}
                        title={keyword}
                      />
                    </li>
                  ))}
                </CardContent>
              ) : (
                <CardContent component="ul" className={classes.chipsContainer}>
                  {values.links.map((keyword, index) => (
                    <li key={index}>
                      <Chip
                        label={keyword}
                        variant="outlined"
                        className={classes.chip}
                        onDelete={() => {
                          if (values.links.length === 1) {
                            setFieldValue('linkSharingChecked', false);
                          }
                          arrayHelpers.remove(index);
                          keyword === values.newLink &&
                            isDuplicate({ ...duplicate, links: false });
                        }}
                        classes={{ label: classes.chipLabel }}
                        title={keyword}
                      />
                    </li>
                  ))}
                </CardContent>
              )}
            </Grid>
          )}
        />
      </Grid>
    );
  }
  function renderBannerBrand() {
    const bannerLimitReached =
      ui_banner_limit_per_campaign &&
      values.brands[index].bannerImages.filter(
        (e) => !!e.bannerUrl || !!e.bannerFile
      ).length >= ui_banner_limit_per_campaign;
    const disableAddMoreBanners = ui_disable_banners || bannerLimitReached;
    return (
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          className={clsx({ [classes.trialDisabled]: disableAddMoreBanners })}
        >
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <Checkbox
                color="primary"
                checked={values.brands[index].bannerChecked}
                onClick={() =>
                  brandForm && handleClick(item, `bannerChecked`, index)
                }
                onChange={handleChange(`brands[${index}].bannerChecked`)}
                value="true"
              />
            }
            label="Image tracking"
          />
          <Typography className={classes.description}>
            Track the banner presence on-screen during the videos live-streamed
            or uploaded. Simply add the URL or upload the image of the banner
            you need to track
          </Typography>
        </Grid>
        <FieldArray
          name={`brands[${index}].bannerImages`}
          className={clsx({ [classes.trialDisabled]: disableAddMoreBanners })}
          render={(arrayHelpers) => (
            <>
              {values.brands[index].bannerImages.map((entry, i) => (
                <Grid
                  data-id={
                    item
                      ? `preview-banners-${item.id}-${i}`
                      : `preview-banners-new-${i}`
                  }
                  item
                  container
                  direction="column"
                  key={i}
                  spacing={1}
                  className={i !== 0 ? classes.bannerPadding : null}
                >
                  {entry.bannerFile && (
                    <Grid item>
                      <ImagePreview
                        src={entry.bannerFile}
                        onDelete={() => {
                          arrayHelpers.remove(i);
                          if (
                            values?.brands[index]?.bannerImages.length === 2
                          ) {
                            setFieldValue(
                              `brands[${index}].bannerChecked`,
                              false
                            );
                            handleDelete(item, `bannerChecked`, index);
                          }
                        }}
                        alt="user uploaded banner deliverable"
                      />
                      {errors &&
                      errors.brandsBannerImages &&
                      errors.brandsBannerImages[index] ? (
                        <p style={{ color: 'red' }}>
                          {errors.brandsBannerImages[index][i]}
                        </p>
                      ) : null}
                    </Grid>
                  )}
                  {entry.bannerUrl && (
                    <Grid item>
                      <ImagePreview
                        src={entry.bannerUrl}
                        onDelete={() => {
                          arrayHelpers.remove(i);
                          if (values.brands[index].bannerImages.length === 2) {
                            setFieldValue(
                              `brands[${index}].bannerChecked`,
                              false
                            );

                            handleDelete(item, `bannerChecked`, index);
                          }
                        }}
                        alt="user uploaded banner deliverable"
                      />
                      {errors &&
                      errors.brandsBannerImages &&
                      errors.brandsBannerImages[index] ? (
                        <p style={{ color: 'red' }}>
                          {errors.brandsBannerImages[index][i]}
                        </p>
                      ) : null}
                    </Grid>
                  )}
                  {!entry.bannerFile &&
                    !entry.bannerUrl &&
                    !disableAddMoreBanners && (
                      <>
                        <Grid item>
                          <FormLabel
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Image URL
                            <Tooltip
                              placement="top"
                              title="For best results please upload the exact same image that will be displayed during the streams. The image has to be at least 60x60 pixels."
                            >
                              <IconButton size="small">
                                <InfoOutlinedIcon
                                  style={{
                                    width: '0.65em',
                                    height: '0.65em',
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </FormLabel>
                          <Grid container justify="space-between">
                            <Grid item style={{ flex: 1 }}>
                              <TextField
                                fullWidth
                                placeholder="Enter the URL of the banner image"
                                variant="outlined"
                                name={`brands[${index}].bannerImages[${i}].bannerUrlTemp`}
                                onChange={(event) => {
                                  handleChange(event);
                                  setWrongFileType(
                                    !regex.URL_IMAGE.test(event.target.value)
                                  );
                                }}
                                inputProps={{
                                  'data-id': item
                                    ? `input-banners-${item.id}`
                                    : `input-banners-new`,
                                }}
                                value={entry.bannerUrlTemp}
                              />
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                              <Button
                                data-id={
                                  item
                                    ? `button-banners-${item.id}`
                                    : `button-banners-new`
                                }
                                size="large"
                                variant="outlined"
                                color="primary"
                                style={{ padding: 9.5 }}
                                disabled={!entry.bannerUrlTemp || wrongFileType}
                                onClick={() => {
                                  arrayHelpers.push({
                                    bannerFile: undefined,
                                    bannerUrl: '',
                                    bannerUrlTemp: '',
                                  });
                                  brandForm &&
                                    handleClick(item, `bannerChecked`);
                                  setFieldValue(
                                    `brands[${index}].bannerChecked`,
                                    true
                                  );
                                  setFieldValue(
                                    `brands[${index}].bannerImages[${i}].bannerUrl`,
                                    entry.bannerUrlTemp
                                  );

                                  handleClick(item, `bannerChecked`);
                                }}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <SideLine inset>
                            <Box fontSize="1rem">or</Box>
                          </SideLine>
                        </Grid>
                        <Grid item>
                          <FormLabel style={{ width: '100%' }}>
                            <FileInput
                              setWrongFileType={setWrongFileType}
                              hidden
                              accept="image/*"
                              onChange={(...args) => {
                                handleFileChange(
                                  `brands[${index}].bannerImages[${i}].bannerFile`
                                )(...args);
                                arrayHelpers.push({
                                  bannerFile: undefined,
                                  bannerUrl: '',
                                  bannerUrlTemp: '',
                                });
                                setFieldValue(
                                  `brands[${index}].bannerChecked`,
                                  true
                                );
                                handleClick(item, `bannerChecked`);
                              }}
                            />
                            {wrongFileType && (
                              <p style={{ color: 'red' }}>
                                Image format must be png, jpg or bmp
                              </p>
                            )}
                            <Button
                              fullWidth
                              size="large"
                              variant="outlined"
                              color="primary"
                              component="span"
                              style={{ fontSize: 12 }}
                            >
                              select an image from your computer
                            </Button>
                          </FormLabel>
                        </Grid>
                      </>
                    )}
                </Grid>
              ))}
            </>
          )}
        />
        {!!ui_disable_banners && (
          <Typography className="fs-i" color="textSecondary">
            This feature is disabled for your account. Please contact Aggero
            support to upgrade your plan.
          </Typography>
        )}
        {!!bannerLimitReached && (
          <Typography className="fs-i" color="textSecondary">
            You can add only {ui_banner_limit_per_campaign} image(s) for a
            campaign. Please upgrade your plan if you want to track more images.
          </Typography>
        )}
      </Grid>
    );
  }
  function renderBanner() {
    const bannerLimitReached =
      ui_banner_limit_per_campaign &&
      values.bannerImages.filter((e) => !!e.bannerUrl || !!e.bannerFile)
        .length >= ui_banner_limit_per_campaign;
    const disableAddMoreBanners = ui_disable_banners || bannerLimitReached;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          className={clsx({ [classes.trialDisabled]: disableAddMoreBanners })}
        >
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <Checkbox
                color="primary"
                checked={values.bannerChecked}
                onChange={handleChange('bannerChecked')}
                value="true"
              />
            }
            label="Image tracking"
          />
          <Typography className={classes.description}>
            Track the banner presence on-screen during the videos live-streamed
            or uploaded. Simply add the URL or upload the image of the banner
            you need to track
          </Typography>
        </Grid>
        <FieldArray
          name="bannerImages"
          className={clsx({ [classes.trialDisabled]: disableAddMoreBanners })}
          render={(arrayHelpers) => (
            <>
              {/* [`values.brands${[index]}.bannerImages`] */}

              {values.bannerImages.map((entry, index) => (
                <Grid
                  data-id={
                    item
                      ? `preview-banners-${item.id}-${index}`
                      : `preview-banners-new-${index}`
                  }
                  item
                  container
                  direction="column"
                  key={index}
                  spacing={1}
                  className={index !== 0 ? classes.bannerPadding : null}
                >
                  {entry.bannerFile && (
                    <Grid item>
                      <ImagePreview
                        src={entry.bannerFile}
                        onDelete={() => {
                          arrayHelpers.remove(index);
                          if (values.bannerImages.length === 2) {
                            setFieldValue('bannerChecked', false);
                          }
                        }}
                        alt="user uploaded banner deliverable"
                      />
                      {errors &&
                      errors.bannerImages &&
                      errors.bannerImages[index] ? (
                        <p style={{ color: 'red' }}>
                          {errors.bannerImages[index]}
                        </p>
                      ) : null}
                    </Grid>
                  )}
                  {entry.bannerUrl && (
                    <Grid item>
                      <ImagePreview
                        src={entry.bannerUrl}
                        onDelete={() => {
                          arrayHelpers.remove(index);
                          if (values.bannerImages.length === 2) {
                            setFieldValue('bannerChecked', false);
                          }
                        }}
                        alt="user uploaded banner deliverable"
                      />
                      {errors &&
                      errors.bannerImages &&
                      errors.bannerImages[index] ? (
                        <p style={{ color: 'red' }}>
                          {errors.bannerImages[index]}
                        </p>
                      ) : null}
                    </Grid>
                  )}
                  {!entry.bannerFile &&
                    !entry.bannerUrl &&
                    !disableAddMoreBanners && (
                      <>
                        <Grid item>
                          <FormLabel
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Image URL
                            <Tooltip
                              placement="top"
                              title="For best results please upload the exact same image that will be displayed during the streams. The image has to be at least 60x60 pixels."
                            >
                              <IconButton size="small">
                                <InfoOutlinedIcon
                                  style={{
                                    width: '0.65em',
                                    height: '0.65em',
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </FormLabel>
                          <Grid container justify="space-between">
                            <Grid item style={{ flex: 1 }}>
                              <TextField
                                fullWidth
                                placeholder="Enter the URL of the banner image"
                                variant="outlined"
                                name={`bannerImages[${index}].bannerUrlTemp`}
                                onChange={(event) => {
                                  handleChange(event);
                                  setWrongFileType(
                                    !regex.URL_IMAGE.test(event.target.value)
                                  );
                                }}
                                inputProps={{
                                  'data-id': item
                                    ? `input-banners-${item.id}`
                                    : `input-banners-new`,
                                }}
                                value={entry.bannerUrlTemp}
                              />
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                              <Button
                                data-id={
                                  item
                                    ? `button-banners-${item.id}`
                                    : `button-banners-new`
                                }
                                size="large"
                                variant="outlined"
                                color="primary"
                                style={{ padding: 9.5 }}
                                disabled={!entry.bannerUrlTemp || wrongFileType}
                                onClick={() => {
                                  if (
                                    !/(jpg|jpeg|png|bmp)/.test(wrongFileType)
                                  ) {
                                    arrayHelpers.push({
                                      bannerFile: undefined,
                                      bannerUrl: '',
                                      bannerUrlTemp: '',
                                    });
                                    setFieldValue('bannerChecked', true);
                                    setFieldValue(
                                      `bannerImages[${index}].bannerUrl`,
                                      entry.bannerUrlTemp
                                    );
                                    setWrongFileType(false);
                                  }
                                }}
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <SideLine inset>
                            <Box fontSize="1rem">or</Box>
                          </SideLine>
                        </Grid>
                        <Grid item>
                          <FormLabel style={{ width: '100%' }}>
                            <FileInput
                              setWrongFileType={setWrongFileType}
                              hidden
                              accept="image/*"
                              onChange={(...args) => {
                                handleFileChange(
                                  `bannerImages[${index}].bannerFile`
                                )(...args);
                                arrayHelpers.push({
                                  bannerFile: undefined,
                                  bannerUrl: '',
                                  bannerUrlTemp: '',
                                });
                                setFieldValue('bannerChecked', true);
                              }}
                            />
                            {wrongFileType && (
                              <p style={{ color: 'red' }}>
                                Image format must be png, jpg or bmp
                              </p>
                            )}
                            <Button
                              fullWidth
                              size="large"
                              variant="outlined"
                              color="primary"
                              component="span"
                              style={{ fontSize: 12 }}
                            >
                              select an image from your computer
                            </Button>
                          </FormLabel>
                        </Grid>
                      </>
                    )}
                </Grid>
              ))}
            </>
          )}
        />
        {!!ui_disable_banners && (
          <Typography className="fs-i" color="textSecondary">
            This feature is disabled for your account. Please contact Aggero
            support to upgrade your plan.
          </Typography>
        )}
        {!!bannerLimitReached && (
          <Typography className="fs-i" color="textSecondary">
            You can add only {ui_banner_limit_per_campaign} image(s) for a
            campaign. Please upgrade your plan if you want to track more images.
          </Typography>
        )}
      </Grid>
    );
  }

  function renderKeywordMentions() {
    const onAdd = (arrayHelpers) => {
      if (brandForm) {
        const content =
          values.brands[index].createKpiKeywordMentionList.split(',');
        content.forEach((el, index) => {
          content[index] = el.trim().toLowerCase();
        });
        new Set(content).forEach((newContent) => {
          if (!newContent) return;
          if (
            !values.brands[index].kpiKeywordMentionList.includes(
              newContent.toLowerCase()
            )
          ) {
            isDuplicate({ ...duplicate, chat: false });
            arrayHelpers.push(newContent.trim());
            setFieldValue(`brands[${index}].createKpiKeywordMentionList`, '');
          } else {
            isDuplicate({ ...duplicate, chat: true });
          }
        });
      } else {
        const content = values.createKpiKeywordMentionList.split(',');
        content.forEach((el, index) => {
          content[index] = el.trim().toLowerCase();
        });
        new Set(content).forEach((newContent) => {
          if (!newContent) return;
          if (!values.kpiKeywordMentionList.includes(newContent)) {
            isDuplicate({ ...duplicate, chat: false });
            arrayHelpers.push(newContent);
            setFieldValue('createKpiKeywordMentionList', '');
          } else {
            setFieldValue('createKpiKeywordMentionList', newContent);
            isDuplicate({ ...duplicate, chat: true });
          }
        });
      }
    };
    return (
      <>
        <Grid
          container
          direction="column"
          spacing={2}
          className={clsx({ [classes.trialDisabled]: trialAccount })}
        >
          <Grid item>
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <Checkbox
                  color="primary"
                  checked={
                    brandForm
                      ? values.brands[index].kpiKeywordMentionChecked
                      : values.kpiKeywordMentionChecked
                  }
                  onClick={() =>
                    brandForm &&
                    handleClick(item, `kpiKeywordMentionChecked`, index)
                  }
                  onChange={handleChange(
                    brandForm
                      ? `brands[${index}].kpiKeywordMentionChecked`
                      : 'kpiKeywordMentionChecked'
                  )}
                  value="true"
                />
              }
              label="Chat/comments mentions"
            />
            <Typography className={classes.description}>
              Monitor the audience mentions of a certain keyword on
              Twitch/YouTube live chat and YouTube comments.
            </Typography>
          </Grid>
          <FieldArray
            name={
              brandForm
                ? `brands[${index}].kpiKeywordMentionList`
                : 'kpiKeywordMentionList'
            }
            render={(arrayHelpers) => (
              <>
                <Grid
                  item
                  className={clsx({
                    [classes.trialDisabled]: trialAccount,
                  })}
                >
                  <FormLabel className={classes.formLabelStyle}>
                    Content
                  </FormLabel>
                  <div className={classes.inputContainer}>
                    <FormikMuiTextField
                      fullWidth
                      placeholder="Add keyword"
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue(
                          brandForm
                            ? `brands[${index}].createKpiKeywordMentionList`
                            : 'createKpiKeywordMentionList',
                          e.target.value
                        );
                        isDuplicate({ ...duplicate, chat: false });
                      }}
                      value={
                        brandForm
                          ? values.brands[index].createKpiKeywordMentionList
                          : values.createKpiKeywordMentionList
                      }
                      name="createKpiKeywordMentionList"
                      className={classes.input}
                      inputProps={{
                        onKeyDown: (e) => {
                          if (e.keyCode === 13) {
                            e.preventDefault();
                            onAdd(arrayHelpers);
                            brandForm &&
                              handleClick(item, `kpiKeywordMentionChecked`);
                            setFieldValue(
                              brandForm
                                ? `brands[${index}].kpiKeywordMentionChecked`
                                : 'kpiKeywordMentionChecked',
                              true
                            );
                          }
                        },
                        'data-id': item
                          ? `input-cm-${item.id}`
                          : `input-cm-new`,
                      }}
                    />
                    <Button
                      data-id={item ? `button-cm-${item.id}` : `button-cm-new`}
                      size="large"
                      variant="outlined"
                      color="primary"
                      style={{ padding: 9.5 }}
                      disabled={
                        brandForm
                          ? !values.brands[
                              index
                            ].createKpiKeywordMentionList.trim()
                          : !values.createKpiKeywordMentionList.trim()
                      }
                      onClick={() => {
                        onAdd(arrayHelpers);
                        brandForm &&
                          handleClick(item, `kpiKeywordMentionChecked`);
                        setFieldValue(
                          brandForm
                            ? `brands[${index}].kpiKeywordMentionChecked`
                            : 'kpiKeywordMentionChecked',
                          true
                        );
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  {duplicate.chat && (
                    <p style={{ color: 'red', overflowWrap: 'anywhere' }}>
                      "
                      {brandForm
                        ? values.brands[index].createKpiKeywordMentionList
                        : values.createKpiKeywordMentionList}
                      " is already added
                    </p>
                  )}
                  <CardContent
                    component="ul"
                    className={classes.chipsContainer}
                  >
                    {brandForm ? (
                      <>
                        {values.brands[index].kpiKeywordMentionList
                          .filter((v) => !v.organization_brand_id)
                          .map((content, i) => (
                            <li
                              key={i}
                              data-id={
                                item
                                  ? `chip-cm-${item.id}-${i}`
                                  : `chip-cm-new-${i}`
                              }
                            >
                              <Chip
                                label={content}
                                variant="outlined"
                                className={classes.chip}
                                onDelete={() => {
                                  arrayHelpers.remove(i);
                                  if (
                                    values.brands[index].kpiKeywordMentionList
                                      .length === 1
                                  ) {
                                    setFieldValue(
                                      `brands[${index}].kpiKeywordMentionChecked`,
                                      false
                                    );
                                    handleDelete(
                                      item,
                                      `kpiKeywordMentionChecked`,
                                      index
                                    );
                                  }
                                  content ===
                                    values.brands[index]
                                      .createKpiKeywordMentionList &&
                                    isDuplicate({ ...duplicate, chat: false });
                                }}
                              />
                            </li>
                          ))}
                      </>
                    ) : (
                      <>
                        {values.kpiKeywordMentionList
                          .filter((v) => !v.organization_brand_id)
                          .map((content, index) => (
                            <li key={index}>
                              <Chip
                                label={content}
                                variant="outlined"
                                className={classes.chip}
                                onDelete={() => {
                                  arrayHelpers.remove(index);
                                  content ===
                                    values.createKpiKeywordMentionList &&
                                    isDuplicate({ ...duplicate, chat: false });
                                  if (
                                    values.kpiKeywordMentionList.length === 1
                                  ) {
                                    setFieldValue(
                                      'kpiKeywordMentionChecked',
                                      false
                                    );
                                  }
                                }}
                              />
                            </li>
                          ))}
                      </>
                    )}
                  </CardContent>
                </Grid>
              </>
            )}
          />
        </Grid>
        {trialAccount && (
          <Typography className="fs-i" color="textSecondary">
            This feature is disabled for trial account
          </Typography>
        )}
      </>
    );
  }

  return (
    <Card>
      <CardContent>
        <div className={classes.grid}>
          {renderShoutout()}
          {brandForm ? renderBannerBrand() : renderBanner()}
          {renderLinkSharing()}
          {renderKeywordMentions()}
          {renderSocialMediaMention()}
        </div>
      </CardContent>
    </Card>
  );
}

export default enhance(CampaignWizardDeliverables);
