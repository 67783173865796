function toMentionFromInstagramPost(post) {
  const { sentiment, language, age, gender, date_created, emv } = post;

  return {
    sentiment,
    language,
    age,
    emv,
    new_age: age && age.toString(),
    gender: Array.isArray(gender) ? gender[0] : gender,
    platform: 'instagram',
    created_at: date_created,
    author_name: post.owner_username,
    author_avatar: post.owner_avatar,
    date: post.date_created,
    text: post.caption,
    link: post.post_url,
  };
}

export default toMentionFromInstagramPost;
