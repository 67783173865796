import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import * as paths from '../../../common/constants/paths';
import Button from '../../../components/Button';
import CustomizedMenus from '../../../components/CustomizedMenus';
import TopBar from '../../../components/TopBar';
import TopBarContent from '../../../components/TopBarContent';
import TopBarTitle from '../../../components/TopBarTitle';
import influencerTypeBySubscriberCount from '../../../helpers/influencer-type-by-subscriber-count';
import useRouter from '../../../hooks/use-router';
import clamp from '../../../utils/clamp';
import compose from '../../../utils/compose';
import paramLocale from '../../../utils/paramLocale';
import InfluencersMain from '../InfluencersMain';

const useStyles = makeStyles((theme) => ({
  main: {
    maxHeight: 'calc(100% - 50px)',
    overflow: 'scroll',
    padding: '0px 16px 76px 16px',
    marginTop: 65,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  sortingOptionsContainer: {
    marginTop: 24,
    marginBottom: 24,
  },
  alert: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
}));

export const InfluencersContext = React.createContext({});

const prettyPercentage = compose(Math.round, clamp(0, 100));

const Influencers = (props) => {
  document.title = 'Influencer Discovery';

  const classes = useStyles();

  const {
    location,
    influencersSearchCommunication,
    searchLimit,
    companyName,
    influencersMounted,
    influencersFetchNextPage,
    fetchingNextPage,
    hasNextPage,
    totalResults,
    pageIndex,
  } = props;

  const params = qs.parse(location.search);

  const hasCampaignGoal = params.campaign_goal !== 'best_fit';

  const [orderBy, setOrderBy] = useState(
    hasCampaignGoal ? 'match_score' : 'target_score'
  );
  const [order, setOrder] = useState('desc');

  const router = useRouter();

  function handleOrderBySelection(orderByValue) {
    return function () {
      if (orderBy === orderByValue) {
        setOrder(order === 'desc' ? 'asc' : 'desc');
      } else {
        setOrderBy(orderByValue);
        setOrder('desc');
      }
    };
  }

  const onFetchMore = () =>
    influencersFetchNextPage({ params, ordering: { order, orderBy } });
  let { influencers: allInfluencers } = props;

  let filteredInfluencers = allInfluencers;

  useEffect(() => {
    if (companyName) {
      influencersMounted({ params, searchLimit, ordering: { order, orderBy } });
    }
  }, [location.search, companyName, order, orderBy]);

  function getExportArray() {
    const criteria = qs.parse(router.location.search);
    const { network } = criteria;

    let data = [];
    let header = [];
    let preHeader = [];

    Object.keys(criteria).forEach((crit) => {
      preHeader.push([paramLocale[crit] || crit, criteria[crit]]);
    });
    preHeader.push([]); // empty

    if (network === 'twitch') {
      data = filteredInfluencers.map(
        ({
          display_name,
          email,
          match_score,
          target_score,
          size,
          follower_virality,
          total_followers,
          view_rate_30,
          avg_viewers_days_30,
          stream_time_days_30,
          ama_days_30,
          views_days_30,
          network,
          login,
        }) => [
          display_name,
          email || '',
          (size || '').replace('INFLUENCER', '').trim(),
          hasCampaignGoal
            ? `${prettyPercentage(match_score)}%`
            : `${prettyPercentage(target_score)}%`,
          `${prettyPercentage(follower_virality)}%`,
          total_followers,
          view_rate_30,
          avg_viewers_days_30,
          stream_time_days_30,
          ama_days_30,
          views_days_30,
          `https://app.aggero.io${paths.INFLUENCER}/${encodeURI(
            login
          )}/${network}`,
          `https://twitch.tv/${login}`,
        ]
      );

      header = [
        'Creator',
        'Email',
        'Size',
        hasCampaignGoal ? 'Match Score' : 'Target Score',
        'Growth Score',
        'Followers',
        'View Rate',
        'Avg CCV (30d)',
        'Stream Time (30d)',
        'AMA (30d)',
        'Views (30d)',
        'Link',
        'Twitch Link',
      ];
    } else {
      data = filteredInfluencers.map(
        ({
          display_name,
          subscriber_count,
          match_score,
          target_score,
          likes_90,
          dislikes_90,
          like_dislike_ratio,
          avg_view_count_per_video,
          view_count_90,
          yt_engagement_90,
          network,
          login,
          channel_id,
          email,
          video_count,
        }) => [
          display_name,
          email || '',
          influencerTypeBySubscriberCount(subscriber_count),
          hasCampaignGoal
            ? `${prettyPercentage(match_score)}%`
            : `${prettyPercentage(target_score)}%`,
          subscriber_count,
          likes_90 ? likes_90 : '-',
          dislikes_90 ? dislikes_90 : '-',
          like_dislike_ratio,
          view_count_90,
          yt_engagement_90,
          video_count,
          avg_view_count_per_video,
          `https://app.aggero.io${paths.INFLUENCER}/${encodeURI(
            login
          )}/${network}`,
          `https://youtube.com/channel/${channel_id || login}`,
        ]
      );

      header = [
        'Creator',
        'Email',
        'Size',
        hasCampaignGoal ? 'Match Score' : 'Target Score',
        'Subscribers',
        'Likes',
        'Dislikes',
        'Like / Dislike',
        'Views',
        'Eng. Rate',
        'Videos',
        'Views / Video',
        'Link',
        'Youtube Link',
      ];
    }

    return [...preHeader, header, ...data];
  }

  function createDocument(type, XLSX) {
    const data = getExportArray();
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Content Creators');
    XLSX.writeFile(
      wb,
      `Aggero-Sponsorship-Opportunities-${new Date()
        .toLocaleString()
        .replace(',', '')}.${type}`
    );
  }

  function onDownloadClick(key) {
    import('xlsx')
      .then((XLSX) => {
        createDocument(key, XLSX);
      })
      .catch((error) => console.error('XLSX', error));
  }

  const context = {
    order,
    orderBy,
    handleOrderBySelection,
  };

  return (
    <InfluencersContext.Provider value={context}>
      <TopBar>
        <TopBarContent>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              {!params.search_watchlist && (
                <TopBarTitle>Search creators</TopBarTitle>
              )}
              {params.search_watchlist && params.search_watchlist !== 'all' && (
                <Link to={`${paths.MY_WATCHLIST}/${params.search_watchlist}`}>
                  <Button>
                    <Icon className={classes.icon}>arrow_back</Icon>Back to
                    watchlist
                  </Button>
                </Link>
              )}
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                {filteredInfluencers.length > 0 && (
                  <Grid item>
                    <CustomizedMenus
                      buttonText={'Download'}
                      buttonIcon={<Icon className={classes.icon}>get_app</Icon>}
                      onClick={onDownloadClick}
                      options={[
                        {
                          text: 'Comma-separated values (.csv)',
                          value: 'csv',
                        },
                        {
                          text: 'Microsoft Excel (.xlsx)',
                          value: 'xlsx',
                        },
                      ]}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Link
                    to={{
                      pathname: paths.INFLUENCER_SEARCH,
                      search: router.location.search,
                    }}
                  >
                    <Button size="medium" variant="contained" color="primary">
                      {params.search_watchlist ? (
                        <>
                          <TuneIcon className={classes.icon} /> Search options
                        </>
                      ) : (
                        <>
                          <SearchIcon className={classes.icon} /> Refine your
                          search
                        </>
                      )}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TopBarContent>
      </TopBar>
      <main className={classes.main}>
        <InfluencersMain
          influencers={allInfluencers}
          params={params}
          loading={influencersSearchCommunication.loading || fetchingNextPage}
          onFetchMore={onFetchMore}
          hasNextPage={hasNextPage}
          totalResults={totalResults}
          pageIndex={pageIndex}
        />
      </main>
    </InfluencersContext.Provider>
  );
};

export default Influencers;
