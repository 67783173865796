import { Checkbox, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import momentTz from 'moment-timezone';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import xss from 'xss';

import { useMultiItemsSelect } from '../../../../hooks/useMultiItemsSelect';
import { campaignSocialPostsMentionsDelete } from '../../../../store/events';
import { getIsViewOnly } from '../../../../store/models/user/user.selectors';
import { CampaignMultipleItemsDeleteDialog } from '../../CampaignMultipleItemsDeleteDialog';
import CreatorAvatar from '../../components/CreatorAvatar';
import PostCell, { ROW_HEIGHT } from '../../components/PostCell';
import { Sections } from '../../constants';
import {
  getCreator,
  getImage,
  getLikes,
  getPermalink,
  getPlatform,
  getPlatformKey,
  getPostId,
  getReplies,
  getRetweets,
  getText,
} from '../../PerformanceTab/SocialMedia/store/types';
import Table, { StatBox } from '../../Table';
import { findInfluencerById } from '../../utils';

const getCols = (
  platformFilter,
  allImpressionsAreEst,
  setSocialPostDelete,
  isSponsorship,
  isViewOnly,
  handleRowSelect
) =>
  [
    {
      label: 'Creator',
      key: 'creator',
      render: (row) => (
        <div
          style={{ position: 'relative', maxWidth: '135px' }}
          className={clsx({ 'padding-on-hover': !isViewOnly })}
        >
          {!isViewOnly && (
            <div
              style={{
                position: 'absolute',
                left: -32,
                top: '50%',
                transform: 'translateY(-50%)',
                height: ROW_HEIGHT,
                width: 48,
                background: 'rgba(0, 0, 0, .1)',
                zIndex: 5,
              }}
              className="slide-on-hover"
            >
              <Checkbox
                color="primary"
                checked={row.isSelected}
                onChange={() => {
                  handleRowSelect(row.postId);
                }}
                inputProps={{ 'aria-label': 'Select post' }}
              />
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Tooltip placement="top" title="Delete Post">
                  <IconButton
                    onClick={() => {
                      setSocialPostDelete({
                        id: row.postId,
                        campaignId: row.influencer?.campaign_id,
                        platform: row.platform,
                        section: isSponsorship
                          ? Sections.SPONSORSHIP_VALUATION
                          : Sections.MEDIA_TRACKING,
                      });
                    }}
                  >
                    <DeleteIcon style={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
          <CreatorAvatar
            name={row.creator}
            avatar={row.influencer.avatar}
            link={row.link}
            influencer={row.influencer}
          />
        </div>
      ),
      stretch: true,
    },
    {
      label: 'Post',
      key: 'date',
      render: (row) => <PostCell {...row} />,
      stretch: true,
      flex: 3,
    },
    platformFilter !== 'instagram'
      ? {
          label: 'Retweets/Shares',
          key: 'retweets',
          render: (data) => (
            <StatBox
              stat={data.retweets}
              format={data.platform === 'instagram' ? null : undefined}
            />
          ),
        }
      : null,
    {
      label: 'Likes',
      key: 'likes',
      render: (data) => <StatBox stat={data.likes} />,
    },
    {
      label: 'Replies/Comm',
      key: 'replies',
      render: (data) => <StatBox stat={data.replies} />,
    },
    {
      label: allImpressionsAreEst ? 'Est. Impressions' : 'Impressions',
      key: 'impressions',
      render: (data) => <StatBox stat={data.impressions} />,
    },
    {
      label: 'Media Value',
      key: 'emv',
      render: (data) => (
        <StatBox stat={data.emv} format="0,[0].[0]a" prefix="$" />
      ),
    },
  ].filter((col) => col !== null);

const getRows = ({
  usedInfluencers,
  timeZone,
  keyword,
  twitterPosts,
  instagramPosts,
  tiktokPosts,
  facebookPosts,
  selectedRows,
}) =>
  twitterPosts
    .concat(instagramPosts)
    .concat(tiktokPosts)
    .concat(facebookPosts)
    .map((post) => {
      const influencer = findInfluencerById(
        post.influencer_id,
        usedInfluencers
      );

      const date = momentTz.tz(post.published_at, timeZone);

      const link = getPermalink(post);

      const text = xss(getText(post)).replace(
        new RegExp(`(^|\\W)(${keyword})($|\\W)`, 'gi'),
        `<b>$&</b>`
      );

      const creator = getCreator(post);
      const postId = getPostId(post);

      return {
        key: getPlatformKey(post),
        text,
        date: date.format('DD MMM YYYY, h:mm A'),
        date_unix: date.unix(),
        image: getImage(post),
        link,
        influencer,
        creator,
        platform: post.network,
        retweets: getRetweets(post),
        likes: getLikes(post),
        replies: getReplies(post),
        impressionsAreEst: !!post.est_impression_count,
        impressions:
          post.impression_count ||
          post.impressions ||
          post.est_impression_count,
        emv: post.emv,
        postId,
        isSelected: selectedRows.includes(postId),
      };
    });

export const DetailedTable = ({
  usedInfluencers,
  timeZone,
  keyword,
  twitterPosts,
  instagramPosts,
  tiktokPosts,
  facebookPosts,
  platformFilter,
  setSocialPostDelete,
  isSponsorship,
}) => {
  const isViewOnly = useSelector(getIsViewOnly);
  const dispatch = useDispatch();

  const postsIds = useMemo(
    () =>
      [
        ...twitterPosts,
        ...instagramPosts,
        ...tiktokPosts,
        ...facebookPosts,
      ].map((post) => getPostId(post)),
    [twitterPosts, instagramPosts, tiktokPosts, facebookPosts]
  );

  const {
    selectedItems: selectedRows,
    handleAllItemsSelect: handleAllRowsSelect,
    handleItemSelect: handleRowSelect,
  } = useMultiItemsSelect(postsIds);

  const getSocialPostsMappedByPlatform = (postIds) => {
    const posts = [
      ...twitterPosts,
      ...instagramPosts,
      ...tiktokPosts,
      ...facebookPosts,
    ];

    const foundPosts = postIds.map((postId) => {
      const post = posts.find((p) => getPostId(p) === postId);
      return post;
    });

    return foundPosts.reduce((acc, currentPost) => {
      const platform = getPlatform(currentPost);
      const postId = getPostId(currentPost);

      const key = `${platform}Ids`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(postId);

      return acc;
    }, {});
  };

  const onMultiplePostsDelete = (postsIds) => {
    const postsMappedByPlatform = getSocialPostsMappedByPlatform(postsIds);

    const payload = {
      twitterIds: postsMappedByPlatform.twitterIds || [],
      instagramIds: postsMappedByPlatform.instagramIds || [],
      tiktokIds: postsMappedByPlatform.tiktokIds || [],
      facebookIds: postsMappedByPlatform.facebookIds || [],
      campaignId: usedInfluencers[0].campaign_id,
      section: isSponsorship
        ? Sections.SPONSORSHIP_VALUATION
        : Sections.MEDIA_TRACKING,
    };

    dispatch(campaignSocialPostsMentionsDelete(payload));
  };

  const rows = useMemo(
    () =>
      getRows({
        usedInfluencers,
        timeZone,
        keyword,
        twitterPosts,
        instagramPosts,
        tiktokPosts,
        facebookPosts,
        selectedRows,
      }),
    [
      usedInfluencers,
      timeZone,
      keyword,
      twitterPosts,
      instagramPosts,
      tiktokPosts,
      facebookPosts,
      selectedRows,
    ]
  );

  const allImpressionsAreEst = rows.some(
    ({ impressionsAreEst }) => impressionsAreEst
  );
  const idTable = `SV-socialmentions-detailed-${keyword.replace(/\s+/g, '')}`;

  return (
    <>
      {selectedRows.length > 0 && (
        <div style={{ padding: 4, display: 'flex' }}>
          <Checkbox
            color="primary"
            onChange={(_, checked) => {
              handleAllRowsSelect(checked);
            }}
          />
          <CampaignMultipleItemsDeleteDialog
            items={selectedRows}
            onDelete={onMultiplePostsDelete}
            title={'Delete Social Posts Mentions'}
          />
        </div>
      )}
      <Table
        cols={getCols(
          platformFilter,
          allImpressionsAreEst,
          setSocialPostDelete,
          isSponsorship,
          isViewOnly,
          handleRowSelect
        )}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        minWidth={1300}
        defaultSortCol="date_unix"
        idTable={idTable}
      />
    </>
  );
};

export default DetailedTable;
