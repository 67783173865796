import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Slider,
} from '@material-ui/core';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import clsx from 'clsx';
import { connect, FieldArray } from 'formik';
import numeral from 'numeral';
import React, { useEffect } from 'react';
import ReactSelect from 'react-select';
import { FixedSizeList } from 'react-window';

import Button from '../../../components/Button';
import Card from '../../../components/Card';
import CardContent from '../../../components/CardContent';
import Chip from '../../../components/Chip';
import ClickSelectMultiple from '../../../components/ClickSelectMultiple';
import FormControl from '../../../components/FormControl';
import FormLabel from '../../../components/FormLabel';
import ExplainedFormLabel from '../../../components/FormLabel';
import IconButton from '../../../components/IconButton';
import InputAdornment from '../../../components/InputAdornment';
import MenuItem from '../../../components/MenuItem';
import OutlinedInput from '../../../components/OutlinedInput';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';
import gameCategories from '../../../helpers/game-category-list';
import interestCategories from '../../../helpers/interest-categories';
import useInputValue from '../../../hooks/use-input-value';
import capitalize from '../../../utils/capitalize';
import countries, { regions } from '../../../utils/country-list';
import languages from '../../../utils/languages-list';
import { ErrorMessage } from './ErrorMessage';

const useStyles = makeStyles((theme) => ({
  buttonSelected: {
    background: theme.palette.primary.lighter,
    '&:hover': {
      background: theme.palette.primary.lighter,
    },
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    padding: 0,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  subHeader: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  trialDisabled: {
    pointerEvents: 'none',
    backgroundColor: theme.palette.text.disabled,
  },
}));

const enhance = connect;

const SCALE = (x) => Math.pow(x, 5);
const INV_SCALE = (x) => Math.pow(x, 1 / 5);

const INFLUENCER_SIZES = [
  { value: 1e3, label: 'NANO' },
  { value: 1e4, label: 'MICRO' },
  {
    value: 5e4,
    label: 'MID-TIER',
  },
  { value: 5e5, label: 'MACRO' },
  { value: 1e6, label: 'MEGA' },
].map((m) => ({
  ...m,
  value: INV_SCALE(m.value),
}));

const MARKS_WITH_LABEL = [
  { value: 1e3, label: '1K' },
  { value: 1e4, label: '10K' },
  {
    value: 5e4,
    label: '50K',
  },
  { value: 5e5, label: '500K' },
  { value: 1e6, label: '1M' },
];

const generateMarks = (min, max, step) =>
  Array(Math.floor((max - min) / step))
    .fill(0)
    .map((_, i) => ({
      value: min + step * i,
    }));

const TWITCH_SIZE = {
  MIN: 500,
  MAX: 17e6,
  MARKS: MARKS_WITH_LABEL.concat(generateMarks(500, 1000, 250))
    .concat(generateMarks(2500, 1e4, 2500))
    .concat(generateMarks(2e4, 5e4, 1e4))
    .concat(generateMarks(1e5, 5e5, 1e5))
    .concat(generateMarks(75e4, 1e6, 25e4))
    .concat(generateMarks(2e6, 17e6, 1e6))
    .concat([{ value: 17e6, label: '17M' }])
    .map((m) => ({
      ...m,
      value: INV_SCALE(m.value),
    })),
};

const YOUTUBE_SIZE = {
  MIN: 500,
  MAX: 184e6,
  MARKS: MARKS_WITH_LABEL.concat(generateMarks(500, 1000, 250))
    .concat(generateMarks(2500, 1e4, 2500))
    .concat(generateMarks(2e4, 5e4, 1e4))
    .concat(generateMarks(1e5, 5e5, 1e5))
    .concat(generateMarks(75e4, 1e6, 25e4))
    .concat(generateMarks(2e6, 10e6, 1e6))
    .concat(generateMarks(10e6, 184e6, 15e6))
    .concat([
      {
        value: 184e6,
        label: '184M',
      },
    ])
    .map((m) => ({
      ...m,
      value: INV_SCALE(m.value),
    })),
};

function InfluencerParameters(props) {
  const {
    formik: { values, handleChange, setFieldValue },
    trialAccountSearch,
    watchlists,
    gamesList,
  } = props;

  const classes = useStyles();
  const myTheme = useTheme();

  const talkingAbout = useInputValue('');

  const defaultCountry = { value: '', label: 'Any' };
  const defaultLanguage = { value: '', label: 'Any' };

  function gameNameById(id) {
    const game = gamesList.find((e) => e.id === parseInt(id));

    return game && game.label;
  }

  function handleNetworkSelection(network) {
    setFieldValue('network', network);
  }

  function GrayArrowDropDownOutlinedIcon(props) {
    return (
      <Icon
        style={{ cursor: 'pointer' }}
        component={ArrowDropDownOutlinedIcon}
        color="action"
      ></Icon>
    );
  }

  function renderCountriesList() {
    return [
      {
        options: [
          defaultCountry,
          ...regions.map(({ code, name }) => ({ value: code, label: name })),
        ],
      },
      {
        options: [
          ...countries
            .filter((e) => !!e.inSearch)
            .map(({ code, name }) => {
              return { value: code, label: name };
            }),
        ],
      },
    ];
  }

  function getSelectedCountry() {
    let country = countries
      .concat(regions)
      .find(({ code, name }) => code === values.country);

    if (country) {
      return { value: country.code, label: country.name };
    } else {
      return defaultCountry;
    }
  }

  function onCountryChange({ value }) {
    setFieldValue('country', value);
  }

  function onLanguageChange({ value }) {
    setFieldValue('streamer_language', value);
  }

  function renderLanguagesList() {
    return [
      defaultLanguage,
      ...languages.map(({ name }) => {
        return { value: name, label: name };
      }),
    ];
  }

  function getSelectedLanguage() {
    let country = languages.find(
      ({ name }) => name === values.streamer_language
    );

    if (country) {
      return { value: country.name, label: country.name };
    } else {
      return defaultLanguage;
    }
  }

  const { MIN, MAX, MARKS } =
    values.network === 'twitch' ? TWITCH_SIZE : YOUTUBE_SIZE;

  useEffect(() => {
    const [min, max] = values.influencer_followers_range;

    const { MIN, MAX } =
      values.network === 'twitch' ? YOUTUBE_SIZE : TWITCH_SIZE;

    if (min === MIN && max === MAX) {
      const { MIN, MAX } =
        values.network === 'twitch' ? TWITCH_SIZE : YOUTUBE_SIZE;

      setFieldValue('influencer_followers_range', [MIN, MAX]);
    } else {
      const { MAX } = values.network === 'twitch' ? TWITCH_SIZE : YOUTUBE_SIZE;

      setFieldValue('influencer_followers_range', [
        Math.min(min, MAX),
        Math.min(max, MAX),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.network]);

  const inflFollRange = (field, value) => {
    if (value < MIN) {
      setFieldValue(field, MIN);
    } else if (value > MAX) {
      setFieldValue(field, MAX);
    }
  };

  return (
    <Grid item>
      <Card style={{ overflow: 'visible' }}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} xl={2}>
              <FormControl>
                <FormLabel>Network</FormLabel>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      data-id="discovery-network-twitch-button"
                      type="button"
                      variant="outlined"
                      style={{ height: 48 }}
                      className={clsx({
                        [classes.buttonSelected]: values.network === 'twitch',
                      })}
                      color={
                        values.network === 'twitch' ? 'primary' : 'default'
                      }
                      onClick={() => handleNetworkSelection('twitch')}
                    >
                      Twitch
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-id="discovery-network-youtube-button"
                      type="button"
                      variant="outlined"
                      style={{ height: 48 }}
                      className={clsx({
                        [classes.buttonSelected]: values.network === 'youtube',
                      })}
                      color={
                        values.network === 'youtube' ? 'primary' : 'default'
                      }
                      onClick={() => handleNetworkSelection('youtube')}
                    >
                      Youtube
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel>
                  {values.network === 'twitch' ? 'Followers' : 'Subscribers'}
                </FormLabel>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div
                    style={{
                      height:
                        values.influencer_followers_range[0] < MIN
                          ? '100px'
                          : values.influencer_followers_range[0] > MAX &&
                            '100px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      type="number"
                      value={values.influencer_followers_range[0]}
                      name="influencer_followers_range[0]"
                      onChange={handleChange}
                      size="small"
                      onBlur={(e) =>
                        inflFollRange(
                          'influencer_followers_range[0]',
                          e.target.value
                        )
                      }
                      inputProps={{
                        min: MIN,
                        max: values.influencer_followers_range[1],
                        'data-id': 'subscribers-start',
                      }}
                      style={{ width: 140 }}
                    />
                    {values.influencer_followers_range[0] < MIN ? (
                      <ErrorMessage message={`Minimum value is ${MIN}`} />
                    ) : (
                      values.influencer_followers_range[0] > MAX && (
                        <ErrorMessage message={`Maximum value is ${MAX}`} />
                      )
                    )}
                  </div>
                  <div
                    style={{
                      margin: '4px 24px',
                      flex: 1,
                      position: 'relative',
                    }}
                  >
                    {INFLUENCER_SIZES.map(({ value, label }) => (
                      <span
                        key={label}
                        onClick={() =>
                          setFieldValue(
                            'influencer_followers_range[0]',
                            Math.round(SCALE(value))
                          )
                        }
                        style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          top: -12,
                          left: `${
                            ((value - INV_SCALE(MIN)) / INV_SCALE(MAX)) * 100
                          }%`,
                          transform: 'translateX(-50%)',
                          fontSize: 12,
                        }}
                      >
                        {label}
                      </span>
                    ))}
                    <Slider
                      data-id="subscribers-number"
                      min={INV_SCALE(MIN)}
                      max={INV_SCALE(MAX)}
                      step={null}
                      value={values.influencer_followers_range.map(INV_SCALE)}
                      scale={SCALE}
                      marks={MARKS}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(v) =>
                        numeral(v).format('0a').toUpperCase()
                      }
                      onChange={(_, v) =>
                        setFieldValue(
                          'influencer_followers_range',
                          v.map((v) => Math.round(SCALE(v)))
                        )
                      }
                    />
                  </div>
                  <div
                    style={{
                      height:
                        values.influencer_followers_range[1] < MIN
                          ? '100px'
                          : values.influencer_followers_range[1] > MAX &&
                            '100px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      type="number"
                      value={values.influencer_followers_range[1]}
                      name="influencer_followers_range[1]"
                      onChange={handleChange}
                      size="small"
                      onBlur={(e) =>
                        inflFollRange(
                          'influencer_followers_range[1]',
                          e.target.value
                        )
                      }
                      inputProps={{
                        max: MAX,
                        min: values.influencer_followers_range[0],
                        'data-id': 'subscribers-end',
                      }}
                      style={{ width: 140 }}
                    />
                    {values.influencer_followers_range[1] < MIN ? (
                      <ErrorMessage
                        style={{ marginLeft: '-120px' }}
                        message={`Minimum value is ${MIN}`}
                      />
                    ) : (
                      values.influencer_followers_range[1] > MAX && (
                        <ErrorMessage
                          style={{ marginLeft: '-120px' }}
                          message={`Maximum value is ${MAX}`}
                        />
                      )
                    )}
                  </div>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={4}>
            {!values.game_categories.length && (
              <Grid item>
                <FormControl>
                  <FormLabel>Game titles</FormLabel>
                  <FieldArray
                    name="main_games"
                    render={({ remove }) => {
                      return (
                        <Grid container spacing={1} alignItems="center">
                          {values.main_games.length < 1 && (
                            <Grid item>
                              <ClickSelectMultiple
                                withSearchInput
                                values={values.main_games || []}
                                onChange={(values) => {
                                  setFieldValue('main_games', values);
                                }}
                                opener={
                                  <IconButton
                                    data-id="game-titles-input"
                                    size="small"
                                    edge="start"
                                    color="primary"
                                  >
                                    <Icon fontSize="large">add_circle</Icon>
                                  </IconButton>
                                }
                              >
                                {({ handleChange, withSearchFilterBasic }) => {
                                  const items =
                                    withSearchFilterBasic(gamesList).filter(
                                      (g) =>
                                        !values.main_games.some(
                                          (id) => id === g.id
                                        )
                                    ) || [];

                                  return (
                                    <List
                                      className={classes.list}
                                      subheader={<li />}
                                      style={{ width: 400 }}
                                    >
                                      <FixedSizeList
                                        itemCount={items.length}
                                        height={300}
                                        itemSize={48}
                                      >
                                        {({ index, style }) => (
                                          <ListItem
                                            button
                                            key={items[index].label}
                                            onClick={() =>
                                              handleChange(items[index].id)
                                            }
                                            style={style}
                                            selected={false}
                                          >
                                            <ListItemText
                                              primary={capitalize(
                                                items[index].label
                                              )}
                                            />
                                          </ListItem>
                                        )}
                                      </FixedSizeList>
                                    </List>
                                  );
                                }}
                              </ClickSelectMultiple>
                            </Grid>
                          )}
                          {values.main_games &&
                            values.main_games.map((id, index) => (
                              <Grid
                                item
                                key={index}
                                data-id={`game-title-${id}`}
                              >
                                <Chip
                                  variant="outlined"
                                  label={gameNameById(id)}
                                  onDelete={() => remove(index)}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            {!values.main_games.length && (
              <Grid item>
                <FormControl>
                  <FormLabel>Game Categories</FormLabel>
                  <FieldArray
                    name="game_categories"
                    render={({ remove }) => {
                      return (
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <ClickSelectMultiple
                              withSearchInput
                              values={values.game_categories || []}
                              onChange={(values) => {
                                setFieldValue('game_categories', values);
                              }}
                              opener={
                                <IconButton
                                  data-id="game-categories-input"
                                  size="small"
                                  edge="start"
                                  color="primary"
                                >
                                  <Icon fontSize="large">add_circle</Icon>
                                </IconButton>
                              }
                            >
                              {({ handleChange, withSearchFilterBasic }) => (
                                <List
                                  className={classes.list}
                                  subheader={<li />}
                                >
                                  {(
                                    withSearchFilterBasic(gameCategories) || []
                                  ).map((gameCategory) => (
                                    <ListItem
                                      button
                                      key={gameCategory.value}
                                      onClick={() =>
                                        handleChange(gameCategory.value)
                                      }
                                      selected={false}
                                    >
                                      <ListItemText
                                        primary={capitalize(gameCategory.label)}
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              )}
                            </ClickSelectMultiple>
                          </Grid>
                          {values.game_categories &&
                            values.game_categories.map((id, index) => (
                              <Grid
                                item
                                key={index}
                                data-id={`game-categories-${id}`}
                              >
                                <Chip
                                  variant="outlined"
                                  label={
                                    gameCategories.filter(
                                      ({ value }) => value === id
                                    )[0].label
                                  }
                                  onDelete={() => remove(index)}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <FormControl style={{ zIndex: 101 }}>
                <FormLabel>Region / Country</FormLabel>
                <ReactSelect
                  id="discovery-region"
                  value={getSelectedCountry()}
                  onChange={onCountryChange}
                  options={renderCountriesList()}
                  components={{
                    DropdownIndicator: GrayArrowDropDownOutlinedIcon,
                  }}
                  placeholder="e.g., United States"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: myTheme.palette.primary.main,
                      primary25: myTheme.palette.action.selected,
                    },
                  })}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      width: 170,
                      height: 48,
                      fontSize: '16px',
                    }),
                    option: (styles) => ({
                      ...styles,
                      width: 170,
                      fontSize: '16px',
                    }),
                    input: (styles) => ({
                      ...styles,
                      width: 170,
                      height: 40,
                      cursor: 'text',
                      fontSize: '16px',
                      padding: '10px 0px',
                    }),
                    group: (styles) => ({
                      ...styles,
                      borderBottom: '#C8C8C8 1px solid',
                    }),
                  }}
                  name="country"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel>Language</FormLabel>
                <ReactSelect
                  id="discovery-language"
                  value={getSelectedLanguage()}
                  onChange={onLanguageChange}
                  options={renderLanguagesList()}
                  components={{
                    DropdownIndicator: GrayArrowDropDownOutlinedIcon,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: myTheme.palette.primary.main,
                      primary25: myTheme.palette.action.selected,
                    },
                  })}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      width: 170,
                      height: 48,
                      fontSize: '16px',
                    }),
                    option: (styles) => ({
                      ...styles,
                      width: 170,
                      fontSize: '16px',
                    }),
                    input: (styles) => ({
                      ...styles,
                      width: 170,
                      height: 40,
                      cursor: 'text',
                      fontSize: '16px',
                      padding: '10px 0px',
                    }),
                  }}
                  name="streamer_language"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <ExplainedFormLabel explanation="Discover creators who mentioned certain keywords during the stream">
                  Talking about
                </ExplainedFormLabel>
                <FieldArray
                  name="streamer_talking_about"
                  render={({ push, remove }) => {
                    function handleAdd() {
                      if (talkingAbout.value) {
                        push(talkingAbout.value);

                        talkingAbout.set('');
                      }
                    }

                    return (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <TextField
                            variant="outlined"
                            className={clsx({
                              [classes.trialDisabled]: trialAccountSearch,
                            })}
                            placeholder="e.g., keyboard or fifa"
                            value={talkingAbout.value}
                            onChange={talkingAbout.handleChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  variant="filled"
                                  data-id="discovery-talking-button"
                                >
                                  <IconButton
                                    data-id="discovery-talking-button-icon"
                                    size="small"
                                    color="primary"
                                    edge="end"
                                    disabled={!talkingAbout.value}
                                    onClick={handleAdd}
                                  >
                                    <Icon
                                      data-id="discovery-talking-button-icon-span"
                                      fontSize="large"
                                    >
                                      add_circle
                                    </Icon>
                                  </IconButton>
                                </InputAdornment>
                              ),
                              'data-id': 'discovery-talking-about',
                            }}
                            onKeyDown={({ key }) => {
                              if (key === 'Enter') {
                                handleAdd();
                              }
                            }}
                          />
                        </Grid>
                        {values.streamer_talking_about.map((word, index) => (
                          <Grid item key={index}>
                            <Chip
                              data-id={`discovery-talking-about-chip-${index}`}
                              variant="outlined"
                              label={word}
                              onDelete={() => remove(index)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    );
                  }}
                />
                {trialAccountSearch && (
                  <b>This feature is disabled for trial account</b>
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel>Interest categories</FormLabel>
                <FieldArray
                  name="streamer_interest_categories"
                  render={({ remove }) => {
                    return (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <ClickSelectMultiple
                            withSearchInput
                            values={values.streamer_interest_categories}
                            onChange={(values) => {
                              setFieldValue(
                                'streamer_interest_categories',
                                values
                              );
                            }}
                            opener={
                              <IconButton
                                data-id="discovery-interest-categories"
                                size="small"
                                edge="start"
                                color="primary"
                              >
                                <Icon fontSize="large">add_circle</Icon>
                              </IconButton>
                            }
                          >
                            {({ handleChange, withSearchFilter }) => (
                              <List className={classes.list} subheader={<li />}>
                                {withSearchFilter(interestCategories).map(
                                  (interestCategoryGroup) => (
                                    <li
                                      key={interestCategoryGroup.name}
                                      className={classes.listSection}
                                    >
                                      <ul className={classes.ul}>
                                        <ListSubheader
                                          className={classes.subHeader}
                                        >
                                          {interestCategoryGroup.name}
                                        </ListSubheader>
                                        {interestCategoryGroup.items.map(
                                          (category) => (
                                            <ListItem
                                              button
                                              key={category}
                                              onClick={() =>
                                                handleChange(category)
                                              }
                                              selected={values.streamer_interest_categories.includes(
                                                category
                                              )}
                                            >
                                              <ListItemText
                                                primary={capitalize(category)}
                                              />
                                            </ListItem>
                                          )
                                        )}
                                      </ul>
                                    </li>
                                  )
                                )}
                              </List>
                            )}
                          </ClickSelectMultiple>
                        </Grid>
                        {values.streamer_interest_categories.map(
                          (category, index) => (
                            <Grid
                              item
                              key={index}
                              data-id={`streamer-interest-categories-${index}`}
                            >
                              <Chip
                                variant="outlined"
                                label={category}
                                onDelete={() => remove(index)}
                              />
                            </Grid>
                          )
                        )}
                      </Grid>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        {values.main_games.length > 0 && (
          <CardContent>
            <FormControlLabel
              style={{ marginBottom: 0 }}
              control={
                <Checkbox
                  color="primary"
                  name="main_game_only"
                  checked={
                    values.main_game_only && values.main_game_only !== 'false'
                  }
                  onChange={handleChange}
                />
              }
              label={
                <p style={{ fontSize: 16, margin: 0 }}>
                  Search only for creators who have{' '}
                  <span className="bold" style={{ margin: '0px 4px' }}>
                    {values.main_games.map(gameNameById).join(', ')}
                  </span>
                  as the most played game
                  {values.main_games.length > 1 ? 's' : ''}
                </p>
              }
            />
          </CardContent>
        )}
        <CardContent>
          <Grid container spacing={4}>
            {values.network === 'twitch' && (
              <Grid item sm={6} xs={12}>
                <FormControl style={{ marginRight: 14 }}>
                  <FormLabel>Min Avg. CCV (30d)</FormLabel>
                  <OutlinedInput
                    type="number"
                    placeholder="e.g., 300000"
                    name="min_ccv"
                    value={values.min_ccv}
                    onChange={(e) =>
                      setFieldValue(
                        'min_ccv',
                        e.target.value.replace(/\D/g, '')
                      )
                    }
                    onBlur={() => {
                      if (values.max_ccv && +values.max_ccv < +values.min_ccv) {
                        setFieldValue('min_ccv', values.max_ccv);
                      }
                    }}
                    id="min_ccv"
                    style={{ width: 160 }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Max Avg. CCV (30d)</FormLabel>
                  <OutlinedInput
                    type="number"
                    placeholder="e.g., 600000"
                    name="max_ccv"
                    value={values.max_ccv}
                    onChange={(e) =>
                      setFieldValue(
                        'max_ccv',
                        e.target.value.replace(/\D/g, '')
                      )
                    }
                    onBlur={() => {
                      if (values.min_ccv && +values.min_ccv > +values.max_ccv)
                        setFieldValue('max_ccv', values.min_ccv);
                    }}
                    id="max_ccv"
                    style={{ width: 160 }}
                  />
                </FormControl>
              </Grid>
            )}
            {values.network === 'youtube' && (
              <Grid item sm={6} xs={12}>
                <FormControl style={{ marginRight: 14 }}>
                  <FormLabel>Min Views / Video</FormLabel>
                  <OutlinedInput
                    type="number"
                    placeholder="e.g., 300000"
                    name="min_avg_view_count_per_video"
                    value={values.min_avg_view_count_per_video}
                    onChange={handleChange}
                    id="min_avg_view_count_per_video"
                    style={{ width: 160 }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Max Views / Video</FormLabel>
                  <OutlinedInput
                    type="number"
                    placeholder="e.g., 600000"
                    name="max_avg_view_count_per_video"
                    value={values.max_avg_view_count_per_video}
                    onChange={handleChange}
                    id="max_avg_view_count_per_video"
                    style={{ width: 160 }}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <CardContent>
          <Grid item sm={6} xs={12}>
            <FormControl>
              <FormLabel>Search in</FormLabel>
              <Select
                displayEmpty
                name="search_watchlist"
                value={values.search_watchlist}
                onChange={handleChange}
                variant="outlined"
                input={<OutlinedInput />}
                style={{ minWidth: 140 }}
              >
                <MenuItem value={false}>All creators</MenuItem>
                {watchlists.length > 1 && (
                  <MenuItem value="all">All watchlisted creators</MenuItem>
                )}
                {watchlists.length > 1
                  ? watchlists.map(({ id, name }) => (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))
                  : watchlists.length > 0 && (
                      <MenuItem value={watchlists[0].id}>
                        Watchlisted creators
                      </MenuItem>
                    )}
              </Select>
            </FormControl>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default enhance(InfluencerParameters);
