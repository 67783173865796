import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { isAggeroUser } from '../../../store/models/user/user.selectors';
import { getCampaignTypes, videoIsFromStreamingPlatform } from '../utils';
import BannerDeliverables from './BannerDeliverables';
import ChatMentions from './ChatMentions';
import LinkSharing from './LinkSharing';
import ShoutoutDeliverables from './ShoutoutDeliverables';
import SocialMediaMentions from './SocialMediaMentions';
import SocialMediaPerformance from './SocialMediaPerformance';
import SponsorshipValuation from './SponsorshipValuation';
import StreamPerformance from './StreamPerformance';

const setNewPerformData = (videos) => {
  const newPerformanceData = {};
  newPerformanceData.count = videos.length;

  let creators = [],
    avg_ccv = 0,
    peak_ccv = 0,
    duration = 0,
    total_emv = 0,
    views = 0,
    hrs_watched = 0;

  videos.forEach((video) => {
    creators.length === 0
      ? creators.push(video.streamer_id)
      : creators.find((creator) => creator === video.streamer_id) === -1 &&
        creators.push(video.streamer_id);
    avg_ccv += video.avg_cc_viewers;
    if (peak_ccv < video.max_cc_viewers) peak_ccv = video.max_cc_viewers;
    duration += video.duration;
    total_emv += video.emv;
    views += video.computed_total_views;
    hrs_watched += (video.avg_cc_viewers * video.duration) / 3600;
  });

  newPerformanceData.creators = creators.length;
  newPerformanceData.avg_ccv = avg_ccv / videos.length;
  newPerformanceData.peak_ccv = peak_ccv;
  newPerformanceData.duration = duration;
  newPerformanceData.total_emv = total_emv;
  newPerformanceData.views = views;
  newPerformanceData.hrs_watched = hrs_watched;
  return newPerformanceData;
};

export const OverviewTab = ({
  campaign,
  onChangeTab,
  setActivePoint,
  setActiveTab,
  activeTab,
  MainActiveTab,
}) => {
  const changeTab = (tab, subMenu) => (id) => {
    if (id && subMenu) setActivePoint(`${subMenu}/${id}`);
    else if (subMenu) setActivePoint(`${subMenu}/`);
    if (subMenu) setActiveTab(subMenu);

    onChangeTab(tab);
  };

  const aggeroUser = useSelector(isAggeroUser);

  if (!campaign.overview) return null;

  const campaignTypes = getCampaignTypes(campaign);

  const showPerformance =
    campaign?.campaign_types?.some(
      (el) => el.campaign_type === 'performance'
    ) ||
    // Show performance section for aggero accounts; for monitoring purposes
    aggeroUser;

  const showMediaTracking = campaignTypes.includes('media_tracking');

  const {
    campaign_influencers: campaignInfluencers,
    overview,
    videos: campaignVideos,
  } = campaign;

  // Only use the videos from the streaming platforms
  const videos = campaignVideos.filter((video) =>
    videoIsFromStreamingPlatform(video)
  );

  const newPerformanceData = setNewPerformData(videos);

  const streamPerformance =
    overview.stream && Object.keys(overview.stream).length > 0 ? (
      <StreamPerformance
        data={
          videos.length !== overview.stream.count
            ? newPerformanceData
            : overview.stream
        }
        campaignInfluencers={campaignInfluencers}
        onViewMore={changeTab('performance', 'streaming')}
      />
    ) : null;

  const shoutouts =
    overview.shoutouts && overview.shoutouts.length ? (
      <ShoutoutDeliverables
        onViewMore={changeTab('deliverables', 'shoutouts')}
        activeTab={activeTab || MainActiveTab}
        shoutouts={overview.shoutouts}
      />
    ) : null;

  const banners =
    overview.banners && overview.banners.length ? (
      <BannerDeliverables
        onViewMore={changeTab('deliverables', 'banners')}
        banners={overview.banners}
      />
    ) : null;

  const socialMediaMentions =
    overview.social_media_mentions && overview.social_media_mentions.length ? (
      <SocialMediaMentions
        onViewMore={changeTab('deliverables', 'social-media')}
        campaignInfluencers={campaignInfluencers}
        mentions={overview.social_media_mentions}
      />
    ) : null;

  const socialMediaPerformance =
    overview.social_media_performance &&
    Object.keys(overview.social_media_performance).length > 0 ? (
      <>
        <SocialMediaPerformance
          onViewMore={changeTab('performance', 'social-media')}
          performance={overview.social_media_performance}
        />
      </>
    ) : null;

  const linkSharing =
    overview.link_trackings && overview.link_trackings.length ? (
      <LinkSharing
        linkTrackings={overview.link_trackings}
        onViewMore={changeTab('deliverables', 'link-sharing')}
      />
    ) : null;

  const chatMentions =
    overview.keyword_mentions && overview.keyword_mentions.length ? (
      <ChatMentions
        onViewMore={changeTab('deliverables', 'chat-mentions')}
        mentions={overview.keyword_mentions}
      />
    ) : null;

  const sponsorshipValuation =
    overview.sponsorship_valuation &&
    Object.keys(overview.sponsorship_valuation).length ? (
      <SponsorshipValuation
        onViewMore={changeTab('sponsorship', 'summary')}
        campaign={campaign}
      />
    ) : null;

  const renderMediaTracking = () => {
    return (
      <>
        {(shoutouts ||
          banners ||
          linkSharing ||
          chatMentions ||
          socialMediaMentions) && (
          <Typography
            variant="h2"
            style={{
              margin: '32px 0 16px 0',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              fontSize: '1.3rem',
              paddingLeft: '16px',
              textTransform: 'uppercase',
            }}
          >
            Media Tracking
          </Typography>
        )}

        {shoutouts}
        {banners}
        {linkSharing}
        {chatMentions}
        {socialMediaMentions}
      </>
    );
  };

  return (
    <Grid
      container
      spacing={4}
      wrap="nowrap"
      direction="column"
      style={{ marginBottom: 32 }}
    >
      {showPerformance && (
        <>
          <Typography
            variant="h2"
            style={{
              margin: '32px 0 16px 0',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              fontSize: '1.3rem',
              paddingLeft: '16px',
              textTransform: 'uppercase',
            }}
          >
            Performance
          </Typography>
          {streamPerformance}
          {socialMediaPerformance}
        </>
      )}

      {sponsorshipValuation && (
        <Typography
          variant="h2"
          style={{
            margin: '32px 0 16px 0',
            fontWeight: 'bold',
            fontFamily: 'Open Sans',
            fontSize: '1.3rem',
            paddingLeft: '16px',
            textTransform: 'uppercase',
          }}
        >
          Sponsorship Valuation
        </Typography>
      )}

      {sponsorshipValuation}

      {showMediaTracking && renderMediaTracking()}
    </Grid>
  );
};

export default OverviewTab;
