import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { confirmInfluencerEmailChange } from '../../../ajax';
import { PROFILE } from '../../../common/constants/paths';
import {
  ErrorMessage,
  getErrorMessagesFromResponse,
} from '../../../common/ErrorMessageUtil';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { LoadingState } from '../../Campaign/types';
import logo from '../InfluencerSignupWizard/logo.svg';

const useEmailChangeConfirmationStyles = makeStyles(() => ({
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  card: {
    minWidth: `min(95%, 360px)`,
    minHeight: `300px`,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    width: '100px',
    marginBottom: 55,
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: 12,
  },
}));

export const EmailChangeConfirmation = () => {
  const classes = useEmailChangeConfirmationStyles();

  const [status, { setFullfilled, setRejected }] = useLoadingState(
    LoadingState.PENDING
  );

  const [error, setError] = useState<ErrorMessage | null>(null);

  const { search } = useLocation();

  const { confirmation_token: token } = qs.parse(search);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        await confirmInfluencerEmailChange(token);

        setFullfilled();
      } catch (err) {
        setError(getErrorMessagesFromResponse(err)?.[0]);
        setRejected();
      }
    })();
  }, [token, setFullfilled, setRejected]);

  const history = useHistory();

  const onGoToApp = useCallback(() => history.replace(PROFILE), [history]);

  let content = <CircularProgress />;

  if (status === LoadingState.FULLFILLED) {
    content = (
      <>
        <Typography>Email changed successfully</Typography>
        <Button
          onClick={onGoToApp}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Go to app
        </Button>
      </>
    );
  }

  if (status === LoadingState.REJECTED) {
    content = (
      <>
        <Typography>
          Failed to change email:{' '}
          {error && t(`errors.${error.key}`, { ...error.vars })}
        </Typography>
        <Button
          onClick={onGoToApp}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Return to app
        </Button>
      </>
    );
  }

  return (
    <div className={classes.container}>
      <img className={classes.logo} src={logo} alt="Aggero logo"></img>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>{content}</CardContent>
      </Card>
    </div>
  );
};
