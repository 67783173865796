import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import numeral from 'numeral';
import qs from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InstagramIcon from '../../../assets-2.0/instagram.svg';
import TwitchIcon from '../../../assets-2.0/twitch.svg';
import TwitterIcon from '../../../assets-2.0/twitter.svg';
import YoutubeIcon from '../../../assets-2.0/youtube.svg';
import * as paths from '../../../common/constants/paths';
import Hyperlink from '../../../components/Hyperlink';
import InfluencerSize from '../../../components/InfluencerSize';
import influencerTypeBySubscriberCount from '../../../helpers/influencer-type-by-subscriber-count';
import {
  getUserFeatures,
  isAggeroUser,
} from '../../../store/models/user/user.selectors';
import countries from '../../../utils/country-list';
import { redoInfluencerSocialLink } from '../redux';
import WatchlistDropdown from './WatchlistDropdown';

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: '2.125rem',
  },
  avatar: {
    width: 160,
    height: 160,
  },
  avatarFallback: {
    width: 120,
    height: 120,
  },
  iconImage: {
    width: 24,
    height: 24,
  },
}));

const InfluencerSummary = (props) => {
  const {
    influencer,
    twitterProfile,
    watchlistActions,
    influencerNetwork,
    influencerTwitchStreamer,
    influencerYoutubeStreamer,
    totalAudience,
    isReady,
    instagramUser,
    facebookPage,
  } = props;

  const features = useSelector(getUserFeatures);
  const dispatch = useDispatch();
  const aggeroUser = useSelector(isAggeroUser);

  let image, influencerType, description, language, updatedAt, displayName;

  updatedAt = [
    influencerTwitchStreamer.updated_at,
    influencerYoutubeStreamer.updated_at,
    instagramUser.updated_at,
    facebookPage.updated_at,
  ].filter((e) => !!e)[0];

  displayName = [
    influencerNetwork === 'twitch' && influencerTwitchStreamer.display_name,
    influencerNetwork === 'youtube' && influencerYoutubeStreamer.display_name,
    influencerTwitchStreamer.display_name,
    influencerYoutubeStreamer.display_name,
    twitterProfile.username,
    instagramUser.username,
    facebookPage.name,
  ].filter((e) => !!e)[0];

  influencerType = [
    influencerNetwork === 'twitch' && influencerTwitchStreamer.size,
    influencerNetwork === 'youtube' &&
      influencerTypeBySubscriberCount(
        influencerYoutubeStreamer.subscriber_count
      ),
  ].filter((e) => !!e)[0];

  image = [
    influencerNetwork === 'twitch' &&
      influencerTwitchStreamer.profile_image_url,
    influencerNetwork === 'youtube' &&
      influencerYoutubeStreamer.profile_image_url,
    influencerTwitchStreamer.profile_image_url,
    influencerYoutubeStreamer.profile_image_url,
    twitterProfile.avatar_url,
    instagramUser.profile_pic_url,
  ].filter((e) => !!e)[0];

  description = [
    influencerTwitchStreamer.description,
    influencerYoutubeStreamer.description,
    facebookPage.about,
  ].filter((e) => !!e)[0];

  language = [
    influencerTwitchStreamer.language,
    influencerYoutubeStreamer.language,
  ]
    .filter((e, index, self) => !!e && self.indexOf(e) === index)
    .join(', ');

  const classes = useStyles();

  const influencerDetails = {};

  if (influencer) {
    influencerDetails.profile_image_url = image;
    influencerDetails.display_name = displayName;
  }

  if (influencerTwitchStreamer && influencerTwitchStreamer.login)
    influencerDetails[
      'influencerTwitchUrl'
    ] = `https://twitch.tv/${influencerTwitchStreamer.login}`;

  if (influencerYoutubeStreamer && influencerYoutubeStreamer.channel_id)
    influencerDetails[
      'influencerYoutubeUrl'
    ] = `https://youtube.com/channel/${influencerYoutubeStreamer.channel_id}`;

  if (instagramUser && instagramUser.url)
    influencerDetails[
      'influencerInstagramUrl'
    ] = `${instagramUser.url.toLowerCase()}`;

  if (twitterProfile && twitterProfile.username)
    influencerDetails[
      'influencerTwitterUrl'
    ] = `https://twitter.com/${twitterProfile.username}`;

  const trackCampaignUrl = `${paths.TRACK_CAMPAIGN}/options?${qs.stringify(
    influencerDetails
  )}`;

  function renderSkeleton() {
    return (
      <Grid container spacing={4}>
        <Grid item>
          <Skeleton variant="circle" width={160} height={160} />
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Skeleton variant="rect" width={134} height={24} />
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Skeleton width={280} height={36} />
                </Grid>
                <Grid item>
                  <Skeleton variant="circle" width={36} height={36} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Skeleton width={370} height={16} />
              <Skeleton width={360} height={16} />
              <Skeleton width={380} height={16} />
              <Skeleton width={375} height={16} />
              <Skeleton width={355} height={16} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const redoSocialLinking = () => {
    let data = {
      network: influencerNetwork,
    };

    if (influencerNetwork === 'youtube') {
      data['youtube_channel_id'] = influencerYoutubeStreamer.channel_id;
    } else if (influencerNetwork === 'twitch') {
      data['twitch_name'] = influencerTwitchStreamer.login;
    }

    dispatch(redoInfluencerSocialLink(data));
  };

  function renderContent() {
    const influencer_location =
      influencer &&
      ((countries.find((c) => c.code === influencer.iso_country) || {}).name ||
        influencer.iso_country ||
        influencer.location);
    const fallbackImage = '../../fallback/img.png';

    return (
      <Grid container spacing={4}>
        <Grid item>
          <Avatar
            className={classes.avatar}
            src={image ? image : fallbackImage}
            alt={displayName?.toUpperCase()}
          />
        </Grid>
        <Grid item style={{ flex: '1' }}>
          <Grid container direction="column" spacing={2}>
            <Grid item container justify="space-between" alignItems="center">
              <Grid item>
                {influencerType && (
                  <InfluencerSize>{influencerType}</InfluencerSize>
                )}
              </Grid>
              <Grid item>
                {updatedAt && (
                  <>
                    <Typography
                      className="fs-i"
                      style={{ display: 'inline', fontSize: 13 }}
                      color="textSecondary"
                    >
                      Last update: {moment(updatedAt).format('DD MMMM YYYY')}
                    </Typography>
                    <Tooltip
                      placement="top"
                      title={
                        'You can see daily stats updates by adding this creator to your watchlist'
                      }
                    >
                      <IconButton size="small">
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item>
              <Grid container justify="space-between">
                <Grid item>
                  <Grid container spacing={1}>
                    <Typography
                      className={classes.name}
                      variant="h4"
                      style={{ paddingRight: 12 }}
                    >
                      {displayName}
                    </Typography>

                    {influencerTwitchStreamer &&
                      influencerTwitchStreamer.login && (
                        <Grid item>
                          <Hyperlink
                            href={`https://twitch.tv/${influencerTwitchStreamer.login}`}
                            className={classes.linkIcon}
                            underline="none"
                            target="_blank"
                          >
                            <IconButton>
                              <img
                                src={TwitchIcon}
                                className={classes.iconImage}
                                alt="twitch icon"
                              />
                            </IconButton>
                          </Hyperlink>
                        </Grid>
                      )}

                    {influencer &&
                      influencer.youtube_streamer &&
                      influencer.youtube_streamer.channel_id && (
                        <Grid item>
                          <Hyperlink
                            href={`https://youtube.com/channel/${influencer.youtube_streamer.channel_id}`}
                            className={classes.linkIcon}
                            underline="none"
                            target="_blank"
                          >
                            <IconButton>
                              <img
                                src={YoutubeIcon}
                                className={classes.iconImage}
                                alt="youtube icon"
                              />
                            </IconButton>
                          </Hyperlink>
                        </Grid>
                      )}

                    {instagramUser &&
                      !!instagramUser.no_followers &&
                      instagramUser.url && (
                        <Grid item>
                          <Hyperlink
                            href={instagramUser.url}
                            className={classes.linkIcon}
                            underline="none"
                            target="_blank"
                          >
                            <IconButton>
                              <img
                                src={InstagramIcon}
                                className={classes.iconImage}
                                alt="instagram icon"
                              />
                            </IconButton>
                          </Hyperlink>
                        </Grid>
                      )}

                    {twitterProfile && twitterProfile.username && (
                      <Grid item>
                        <Hyperlink
                          href={`https://twitter.com/${twitterProfile.username}`}
                          className={classes.linkIcon}
                          underline="none"
                          target="_blank"
                        >
                          <IconButton>
                            <img
                              src={TwitterIcon}
                              className={classes.iconImage}
                              alt="twitter icon"
                            />
                          </IconButton>
                        </Hyperlink>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <div style={{ float: 'right', textAlign: 'right' }}>
                    TOTAL AUDIENCE
                    <br />
                    <Typography
                      variant="h1"
                      color="primary"
                      className="fw-600 tt-u"
                      style={{ fontSize: 28 }}
                    >
                      {numeral(totalAudience).format('0.[0]a')}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {influencer && (
              <Grid item>
                <Grid container spacing={4}>
                  {influencer_location && (
                    <Grid item>
                      <Box clone display="flex" alignItems="center">
                        <Typography>
                          <Box clone mr={1}>
                            <Icon>room</Icon>
                          </Box>
                          {influencer_location}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {influencer.email && (
                    <Grid item>
                      <Box clone display="flex" alignItems="center">
                        <Typography>
                          <Box clone mr={1}>
                            <Icon>email</Icon>
                          </Box>
                          <a href={`mailto:${influencer.email.toLowerCase()}`}>
                            {influencer.email.toLowerCase()}
                          </a>
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item>{description}</Grid>
            <Grid item container justify="space-between" alignItems="center">
              <Grid item xs={12} sm={8}>
                <Grid container spacing={4}>
                  {language && (
                    <Grid item>
                      <Typography variant="caption">Language</Typography>
                      <Typography>
                        <Box fontWeight="bold" component="span">
                          {language}
                        </Box>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={12}
                sm={4}
                justify="flex-end"
              >
                {features.tracking && (
                  <Grid item>
                    <Link to={trackCampaignUrl}>
                      <Button
                        type="button"
                        variant="outlined"
                        color={'primary'}
                        style={{ float: 'right' }}
                      >
                        Track Campaign
                      </Button>
                    </Link>
                  </Grid>
                )}
                {watchlistActions && (
                  /* <Grid item xs={12} sm={4}>
                  <Button
                    type="button"
                    variant="outlined"
                    color={'primary'}
                    style={{ float: 'right' }}
                    onClick={watchlistActions.isOnWatchlist ? watchlistActions.remove : watchlistActions.add }
                  >
                    { watchlistActions.isOnWatchlist ? 'Remove from Watchlist' : '+ Add to Watchlist' }
                  </Button>
                </Grid>
                */
                  <Grid item>
                    <WatchlistDropdown />
                  </Grid>
                )}
                {aggeroUser && (
                  <Grid item>
                    <Button
                      type="button"
                      variant="outlined"
                      color={'primary'}
                      style={{ float: 'right' }}
                      onClick={() => {
                        redoSocialLinking();
                      }}
                    >
                      Redo Social Linking
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card>
      <CardContent>{isReady ? renderContent() : renderSkeleton()}</CardContent>
    </Card>
  );
};

export default InfluencerSummary;
