import momentTz from 'moment-timezone';

import groupBy from '../../../utils/group-by';
import { getInfluencer } from './utils';

export const computeChatMentions = ({
  videos,
  keyword_mention_deliverables,
  campaign_influencers,
  time_zone,
}) => {
  const rows = [];

  rows.push([
    'Keyword',
    'Platform',
    'Stream/Video Title',
    'Creator Name',
    'User',
    'Mention',
    'Time',
  ]);

  const videosById = groupBy(videos, 'id');

  keyword_mention_deliverables.forEach((deliverable) => {
    deliverable.video_comments.forEach((comment) => {
      let video = videosById[comment.video_id][0];
      let influencer = getInfluencer(
        campaign_influencers,
        video.campaign_influencer_ids
      );

      rows.push([
        deliverable.keyword,
        video && video.resource,
        video && video.video_title,
        influencer && influencer.name,
        comment.name || comment.commenter_name,
        comment.comment,
        momentTz
          .tz(comment.published_at, time_zone)
          .format('MM/DD/YYYY HH:mm:ss'),
      ]);
    });
  });

  return rows;
};

export default computeChatMentions;
