import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import * as paths from '../../common/constants/paths';
import TopBar from '../../components/TopBar';
import TopBarContent from '../../components/TopBarContent';
import TopBarTitle from '../../components/TopBarTitle';
import useRouter from '../../hooks/use-router';
import Profile from './components/Profile';
import Usage from './components/Usage';
import Users from './components/Users';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    background: 'none',
    fontSize: 16,
    color: 'black',
    textTransform: 'uppercase',
    border: 'none',
    '&:hover': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  container: {
    paddingTop: 54,
    overflow: 'scroll',
    height: '100%',
  },
  menuDiv: {
    borderBottom: '1px solid #ddd',
  },
}));

const MyAggeroMain = (props) => {
  document.title = 'My Aggero';

  const classes = useStyles();
  const history = useHistory();

  const {
    match: {
      params: { tab },
    },
  } = useRouter();
  const tabs = [
    {
      key: 'profile',
      title: 'Profile',
      component: <Profile />,
    },
    {
      key: 'users',
      title: 'Users',
      component: <Users />,
    },
    {
      key: 'usage',
      title: 'Usage',
      component: <Usage />,
    },
  ];
  const currentTab = tabs.find(({ key }) => key === tab);

  function changeTab(key) {
    history.push(`${paths.MY_AGGERO}/${key}`);

    const elem = document.getElementsByClassName('main-container')[0];
    if (elem) {
      elem.scrollTo(0, 0);
    }
  }

  return (
    <div className={classes.container}>
      <TopBar>
        <TopBarContent>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={4}
          >
            <Grid item style={{ flex: 1 }}>
              <TopBarTitle>My Aggero</TopBarTitle>
            </Grid>
          </Grid>
        </TopBarContent>
      </TopBar>
      <div className={classes.menuDiv}>
        {tabs.map(({ key, title }, index) => {
          return (
            <button
              key={index}
              onClick={() => changeTab(key)}
              className={clsx(classes.button, {
                [classes.active]: currentTab.key === key,
              })}
            >
              {title}
            </button>
          );
        })}
      </div>
      <Container>{currentTab.component}</Container>
    </div>
  );
};

export default MyAggeroMain;
