import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

export default function Pending(props) {
  const { ready, children, fallback } = props;

  if (ready) return children();

  return (
    fallback || (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <CircularProgress />
      </Box>
    )
  );
}
