import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { scaleOrdinal } from '@visx/scale';
import {
  defaultStyles as defaultTooltipStyles,
  TooltipWithBounds,
  useTooltip,
} from '@visx/tooltip';
import numeral from 'numeral';
import React, { useCallback, useMemo } from 'react';

import { ChatMentionDeliverable } from '../DeliverablesTab/ChatMentions/store/types';
import { ShoutoutDeliverableV2 } from '../DeliverablesTab/Shoutouts/store/types';
import AverageCCV from './AverageCCV';
import { MentionsBars } from './MentionsBars';
import {
  BrushBounds,
  CCVData,
  ccvGetTime,
  groupCCV,
  groupMentions,
  groupShoutouts,
  MentionsData,
  mentionsGetMentionsKeys,
  STACK_COLORS,
} from './utils';

interface CCVMentionsProps {
  ccvData: CCVData[];
  width: number;
  startTime: string;
  chatMentionDeliverables: ChatMentionDeliverable[];
  shoutoutDeliverables: ShoutoutDeliverableV2[];
  duration: number;
  svgOnly: boolean;
  brushBounds: BrushBounds;
  videoId: number;
}

const CCVMentions = ({
  ccvData: unsortedCCVData,
  width,
  startTime,
  chatMentionDeliverables,
  shoutoutDeliverables,
  duration,
  svgOnly,
  brushBounds,
  videoId,
}: CCVMentionsProps) => {
  const tooltipTimeout = React.useRef(null);

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<{
    label: string;
    ccvData: CCVData;
    chatMentionsData: MentionsData;
    shoutoutData: MentionsData;
  }>();

  const ccvData = useMemo(
    () => unsortedCCVData.sort((a, b) => ccvGetTime(a) - ccvGetTime(b)),
    [unsortedCCVData]
  );

  const unfilteredCCVBars = useMemo(
    () => groupCCV({ duration, ccvData, startTime }),
    [ccvData, duration, startTime]
  );

  const ccvBars = useMemo(() => {
    if (!brushBounds) return unfilteredCCVBars;

    return unfilteredCCVBars.slice(brushBounds.start, brushBounds.end);
  }, [brushBounds, unfilteredCCVBars]);

  const unfilteredChatMentionsBars = useMemo(
    () =>
      groupMentions({
        deliverables: chatMentionDeliverables,
        duration,
        startTime,
        videoId,
      }),
    [chatMentionDeliverables, duration, startTime, videoId]
  );

  const chatMentionsBars = useMemo(() => {
    if (!brushBounds) return unfilteredChatMentionsBars;

    return unfilteredChatMentionsBars.slice(brushBounds.start, brushBounds.end);
  }, [brushBounds, unfilteredChatMentionsBars]);

  const unfilteredShoutoutBars = useMemo(
    () =>
      groupShoutouts({
        deliverables: shoutoutDeliverables,
        duration,
        startTime,
        videoId,
      }),
    [duration, shoutoutDeliverables, startTime, videoId]
  );

  const shoutoutBars = useMemo(() => {
    if (!brushBounds) return unfilteredShoutoutBars;

    return unfilteredShoutoutBars.slice(brushBounds.start, brushBounds.end);
  }, [brushBounds, unfilteredShoutoutBars]);

  const onBarLeave = () => {
    tooltipTimeout.current = setTimeout(() => {
      hideTooltip();
    }, 300);
  };

  const onBarMove =
    (chart: 'ccv' | 'chatMentions' | 'shoutouts') =>
    ({ label, barIndex }) =>
    (event) => {
      if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);

      const { x, y } = { x: event.pageX, y: event.pageY } || { x: 0, y: 0 };

      const ccvData = ccvBars[barIndex];
      const mentionsData = chatMentionsBars[barIndex];
      const shoutoutData = shoutoutBars[barIndex];

      showTooltip({
        tooltipTop: y,
        tooltipLeft: x,
        tooltipData: {
          ccvData,
          chatMentionsData: mentionsData,
          label,
          shoutoutData,
        },
      });
    };

  const getOpacity = useCallback(
    (label) => {
      if (tooltipData && tooltipData.label === label) {
        return 1;
      }

      return tooltipData ? 0.4 : 1;
    },
    [tooltipData]
  );

  const mentionsColorScale = useMemo(() => {
    const mentionsKeys = chatMentionsBars[0]
      ? mentionsGetMentionsKeys(chatMentionsBars[0])
      : [];

    return scaleOrdinal({
      domain: mentionsKeys,
      range: STACK_COLORS,
    });
  }, [chatMentionsBars]);

  const svg = (
    <>
      {ccvData && ccvData.length && unfilteredCCVBars.length > 0 ? (
        <AverageCCV
          ccvBars={ccvBars}
          width={width}
          onBarLeave={onBarLeave}
          onBarMove={onBarMove('ccv')}
          getOpacity={getOpacity}
        />
      ) : null}
      {chatMentionDeliverables &&
      chatMentionDeliverables.length > 0 &&
      unfilteredChatMentionsBars.length > 0 ? (
        <MentionsBars
          mentionsBars={chatMentionsBars}
          width={width}
          onBarLeave={onBarLeave}
          onBarMove={onBarMove('chatMentions')}
          getOpacity={getOpacity}
          mentionsColorScale={mentionsColorScale}
          title="Chat mentions"
        />
      ) : null}
      {shoutoutDeliverables &&
      shoutoutDeliverables.length > 0 &&
      unfilteredShoutoutBars.length > 0 ? (
        <MentionsBars
          mentionsBars={shoutoutBars}
          width={width}
          onBarLeave={onBarLeave}
          onBarMove={onBarMove('shoutouts')}
          getOpacity={getOpacity}
          mentionsColorScale={mentionsColorScale}
          title="Shoutouts"
          BarIcon={VolumeUpIcon}
        />
      ) : null}
    </>
  );

  if (svgOnly) return svg;

  return (
    <div>
      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={{ ...defaultTooltipStyles, position: 'fixed' }}
        >
          <p>
            <strong>{tooltipData.label}</strong>
          </p>
          {tooltipData.ccvData && (
            <p>
              <strong>Avg. CCV: </strong>{' '}
              {numeral(tooltipData.ccvData.count).format('0.[0]a')}
            </p>
          )}
          <div style={{ display: 'flex' }}>
            {tooltipData.chatMentionsData && (
              <div
                style={{
                  flex: 1,
                  paddingRight: 32,
                  borderRight: tooltipData.shoutoutData
                    ? '1px solid #eee'
                    : 'none',
                }}
              >
                <strong>Chat mentions:</strong>
                <ul style={{ listStyle: 'none', paddingLeft: 16 }}>
                  {mentionsGetMentionsKeys(tooltipData.chatMentionsData).map(
                    (k) => (
                      <li
                        key={k}
                        style={{
                          whiteSpace: 'nowrap',
                          marginTop: 8,
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              display: 'inline-block',
                              height: 8,
                              width: 8,
                              marginRight: 8,
                              borderRadius: '100%',
                              background: mentionsColorScale(k),
                            }}
                          />
                          {k}:{' '}
                        </strong>
                        {tooltipData.chatMentionsData[k]}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {tooltipData.shoutoutData && (
              <div style={{ flex: 1, paddingLeft: 32 }}>
                <strong>Shoutouts:</strong>
                <ul style={{ listStyle: 'none', paddingLeft: 16 }}>
                  {mentionsGetMentionsKeys(tooltipData.shoutoutData).map(
                    (k) => (
                      <li
                        key={k}
                        style={{
                          whiteSpace: 'nowrap',
                          marginTop: 8,
                        }}
                      >
                        <strong>
                          <span
                            style={{
                              display: 'inline-block',
                              height: 8,
                              width: 8,
                              marginRight: 8,
                              borderRadius: '100%',
                              background: mentionsColorScale(k),
                            }}
                          />
                          {k}:{' '}
                        </strong>
                        {tooltipData.shoutoutData[k]}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
          </div>
        </TooltipWithBounds>
      )}
      {svg}
    </div>
  );
};

export default CCVMentions;
