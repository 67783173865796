import moment from 'moment';

function getRange (startDate, endDate, type) {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, type);
  let range = [];
  for (let i = 0; i <= diff; i++) {
    range.push(
      moment(startDate)
        .add(i, type)
        .format('YYYY-MM-DD')
    );
  }
  return range.sort();
}
export default getRange;
