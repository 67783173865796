import languageCode2NameMap from './languageCode2NameMap.json';
import countryCode2NameMap from './threeLetterCountryCode2Name.json';
import twoLetterCountryCode2ThreeLetterMap from './twoLetterCountryCode2ThreeLetter.json';

const twoLetterCountryCode2ThreeLetter = (code: string): string =>
  twoLetterCountryCode2ThreeLetterMap[code];

const countryCode2Name = (code: string) => countryCode2NameMap[code];

// don't show language alternative names
const languageCode2Name = (code: string) =>
  languageCode2NameMap[code]?.English.split(/(,|;)/g)[0];

export default {
  twoLetterCountryCode2ThreeLetter,
  countryCode2Name,
  languageCode2Name,
};
