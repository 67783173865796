import { IconButton, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DeleteIcon from '@material-ui/icons/Delete';
import momentTz from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMultiItemsSelect } from '../../../../hooks/useMultiItemsSelect';
import { campaignMultipleVideosDeleteClicked } from '../../../../store/events';
import {
  getIsViewOnly,
  getUserFeatures,
} from '../../../../store/models/user/user.selectors';
import { CampaignMultipleItemsDeleteDialog } from '../../CampaignMultipleItemsDeleteDialog';
import { CampaignDeliverableChartTrigger } from '../../components/DeliverablesChartTrigger';
import InfluencerAvatar from '../../components/InfluencerAvatar';
import VideoCell from '../../components/VideoCell';
import Table, { StatBox } from '../../Table';
import {
  findInfluencerByVideo,
  getVideoDuration,
  videoHasSegments,
} from '../../utils';

const ROW_HEIGHT = 164;

const CreatorCol = ({
  influencer,
  platform,
  setCampaignVideoDeleteId,
  setTimeSegmentVideoId,
  id,
  videoType,
  isViewOnly,
  isDiscovery,
  handleRowSelect,
  isSelected,
}) => (
  <div
    style={{ position: 'relative', maxWidth: '150px' }}
    className="padding-on-hover"
  >
    <div
      style={{
        position: 'absolute',
        left: -32,
        top: '50%',
        transform: 'translateY(-50%)',
        height: ROW_HEIGHT,
        width: 48,
        background: 'rgba(0, 0, 0, .1)',
        zIndex: 5,
      }}
      className="slide-on-hover"
      onClick={(e) => e.stopPropagation()}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Checkbox
          color="primary"
          checked={isSelected}
          onChange={() => {
            handleRowSelect(id);
          }}
          inputProps={{ 'aria-label': 'Select video' }}
        />
        <CampaignDeliverableChartTrigger videoId={id} />
        {!isViewOnly && (
          <>
            {(videoType === 'live-vod' ||
              videoType === 'live' ||
              videoType === 'vod') && (
              <Tooltip
                placement="top"
                title="Select stream segment to be tracked"
              >
                <IconButton
                  style={{ height: 45 }}
                  onClick={() => setTimeSegmentVideoId(id)}
                >
                  <AvTimerIcon style={{ color: '#A300D8' }} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip placement="top" title="Delete Video">
              <IconButton
                style={{ height: 45 }}
                onClick={() => setCampaignVideoDeleteId(id)}
              >
                <DeleteIcon style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </div>
    <InfluencerAvatar
      influencer={influencer}
      platform={platform}
      isDiscovery={isDiscovery}
    />
  </div>
);

const getCols = ({
  setCampaignVideoDeleteId,
  hasFollowersGained,
  setTimeSegmentVideoId,
  isViewOnly,
  isDiscovery,
  handleRowSelect,
}) =>
  [
    {
      label: 'Creator',
      key: 'creator',
      render: (row) => (
        <CreatorCol
          {...row}
          setCampaignVideoDeleteId={setCampaignVideoDeleteId}
          setTimeSegmentVideoId={setTimeSegmentVideoId}
          videoType={row.video.video_type}
          isViewOnly={isViewOnly}
          isDiscovery={isDiscovery}
          handleRowSelect={handleRowSelect}
        />
      ),
      stretch: true,
      flex: 1.5,
    },
    {
      label: 'Video',
      key: 'videoTimeStamp',
      render: (row) => (
        <VideoCell video={row.video} videoTime={row.videoTime} showType />
      ),
      stretch: true,
      flex: 2,
    },
    {
      label: 'Duration hrs.',
      key: 'hoursStreamed',
      render: (data) => <StatBox stat={data.hoursStreamed} />,
    },
    {
      label: 'Hrs. Watched',
      key: 'hoursWatched',
      render: (data) => <StatBox stat={data.hoursWatched} />,
    },
    hasFollowersGained
      ? {
          label: 'Followers',
          key: 'followersGained',
          render: (data) => <StatBox stat={data.followersGained} />,
        }
      : null,
    {
      label: 'Cmnts.',
      key: 'comments',
      render: (data) => <StatBox stat={data.comments} />,
    },
    {
      label: 'Eng. Rate',
      key: 'engagementRate',
      render: (data) => (
        <StatBox
          stat={data.engagementRate}
          info={
            data.hasSegments
              ? 'Engagement rate is calculated based on the number of interactions and potential estimated views for the selected stream segment.'
              : undefined
          }
          suffix={'%'}
        />
      ),
    },
    {
      label: 'Avg CCV',
      key: 'avgCCV',
      render: (data) => <StatBox stat={data.avgCCV} />,
    },
    {
      label: 'Peak CCV',
      key: 'peakCCV',
      render: (data) => <StatBox stat={data.peakCCV} />,
    },
    {
      label: 'Views',
      key: 'totalViews',
      render: (data) => (
        <StatBox
          stat={data.totalViews}
          info={
            data.hasSegments
              ? 'The number of estimated potential views based on the distribution of concurrent viewers at each minute across the selected stream segment'
              : undefined
          }
        />
      ),
    },
    {
      label: 'Value',
      key: 'emv',
      render: (data) => (
        <StatBox
          stat={data.emv}
          info={
            data.hasSegments
              ? 'Media value is calculated based on the estimated number of impressions relying on the potential views the selected stream segment got.'
              : undefined
          }
          format="0,[0].[0]a"
          prefix="$"
        />
      ),
    },
  ].filter((c) => c !== null);

const getRows = ({ usedVideos, usedInfluencers, timeZone, selectedRows }) =>
  usedVideos.map((video) => {
    const videoTimeObject = momentTz.tz(video.published_at, timeZone);

    const videoTime = videoTimeObject.format('Do MMM YYYY, hh:mm A');

    const videoTimeStamp = videoTimeObject.unix();

    const influencer = findInfluencerByVideo(video, usedInfluencers);

    const hoursStreamed = getVideoDuration(video) / (60 * 60);

    const avgCCV = video.avg_cc_viewers;
    const peakCCV = video.max_cc_viewers;

    const hoursWatched = hoursStreamed * avgCCV || null;

    const comments = video.comments_count;

    const engagementRate = video.engagement_rate;

    const totalViews = video.computed_total_views || video.total_views;

    const emv = video.emv;

    const hasSegments = videoHasSegments(video);

    return {
      influencer,
      creator: influencer.name,
      key: video.id,
      id: video.id,
      platform: video.resource,
      videoTimeStamp,
      video,
      videoTime,
      hoursStreamed,
      avgCCV,
      peakCCV,
      hoursWatched,
      comments,
      engagementRate,
      totalViews,
      emv,
      followersGained: video.followers_gained,
      hasSegments,
      isSelected: selectedRows.includes(video.id),
    };
  });

export const DetailedTable = ({
  usedVideos,
  usedInfluencers,
  timeZone,
  hasTwitch,
  setCampaignVideoDeleteId,
  setTimeSegmentVideoId,
  setSelectedCampaignInfluencer,
  campaignId,
}) => {
  const dispatch = useDispatch();
  const isViewOnly = useSelector(getIsViewOnly);

  const features = useSelector(getUserFeatures);

  const usedVideosIds = useMemo(
    () => usedVideos.map((v) => v.id),
    [usedVideos]
  );

  const {
    selectedItems: selectedRows,
    handleAllItemsSelect: handleAllRowsSelect,
    handleItemSelect: handleRowSelect,
  } = useMultiItemsSelect(usedVideosIds);

  const rows = useMemo(
    () =>
      getRows({
        usedVideos,
        usedInfluencers,
        timeZone,
        selectedRows,
      }),
    [usedVideos, usedInfluencers, timeZone, selectedRows]
  );

  const hasFollowersGained = useMemo(
    () => rows.some((r) => !!r.followersGained),
    [rows]
  );

  const cols = useMemo(
    () =>
      getCols({
        hasTwitch,
        setCampaignVideoDeleteId,
        hasFollowersGained,
        setTimeSegmentVideoId,
        isViewOnly,
        isDiscovery: features.discovery,
        handleRowSelect,
      }),
    [
      hasTwitch,
      setCampaignVideoDeleteId,
      hasFollowersGained,
      setTimeSegmentVideoId,
      isViewOnly,
    ]
  );
  const onCreatorColClick = useCallback(
    ({ influencer }) => setSelectedCampaignInfluencer(influencer.id),
    [setSelectedCampaignInfluencer]
  );
  const idTable = 'performanceTab-streaming-detailed';

  const onMultipleVideosDelete = (videoIds) => {
    dispatch(campaignMultipleVideosDeleteClicked({ videoIds, campaignId }));
  };

  return (
    <>
      {selectedRows.length > 0 && (
        <div style={{ padding: 4, display: 'flex' }}>
          <Checkbox
            color="primary"
            onChange={(_, checked) => {
              handleAllRowsSelect(checked);
            }}
          />
          <CampaignMultipleItemsDeleteDialog
            items={selectedRows}
            onDelete={onMultipleVideosDelete}
            title={'Delete Videos'}
          />
        </div>
      )}
      <Table
        cols={cols}
        rows={rows}
        rowHeight={ROW_HEIGHT}
        minWidth={1400}
        defaultSortCol="videoTimeStamp"
        idTable={idTable}
        onCreatorColClick={usedInfluencers.length > 1 && onCreatorColClick}
      />
    </>
  );
};

export default DetailedTable;
