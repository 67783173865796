import { useEffect, useState } from 'react';

import delay from '../utils/delay';

function useDelayedValue(value, ms) {
  const [delayedValue, setDelayedValue] = useState();

  useEffect(
    function () {
      let mounted = true;

      function updateDelayedValue() {
        if (mounted) setDelayedValue(value);
      }

      delay(ms).then(updateDelayedValue);

      return () => {
        mounted = false;
      };
    },
    [value]
  );

  return delayedValue;
}

export default useDelayedValue;
