import isObject from './is-object';

function overwriteDeep(original, overwrite) {
  return Object.keys(overwrite).reduce(
    (overwritten, key) => ({
      ...overwritten,
      [key]:
        isObject(original[key]) && isObject(overwrite[key])
          ? overwriteDeep(original[key], overwrite[key])
          : overwrite[key],
    }),
    original
  );
}

export default overwriteDeep;
