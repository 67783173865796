import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'fixed',
    top: 20,
    left: 196,
    zIndex: 1000000,
    color: '#eee',
  },
}));

export const FloatingLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CircularProgress size={24} />
    </div>
  );
};
