import { LoadingState, PublishedRecord } from '../../../types';
import { Deliverable } from '../../types';
import { initialState } from './LinkTrackingDeliverables.slice';

export const SLICE_NAME = 'linkTrackingDeliverables';

export interface LinkTrackingOccurrence extends PublishedRecord {
  avatar: string;
  comment: string;
  commenter_id: number;
  external_id: string;
  is_live: boolean;
  link: string | null;
  name: number;
  platform: string;
  sentiment: number | null;
  video_id: number;
}

export interface LinkTrackingDeliverable extends Deliverable {
  link: string;
  link_tracking_occurrences: LinkTrackingOccurrence[];
}

export type LinkTrackingDeliverableStructure = Omit<
  LinkTrackingDeliverable,
  'link_sharing_occurrences'
>;

// maps deliverableId to its loading state
export type LoadingStateMap = { [key: number]: LoadingState };

export type LinkTrackingDeliverablesState = typeof initialState;
