import { createSlice } from '@reduxjs/toolkit';

import { ErrorMessage } from '../../../../common/ErrorMessageUtil';
import { LoadingState } from '../../../Campaign/types';
import { signupInfluencerWithPassword } from './InfluencerSignupWizard.thunks';
import { InfluencerSignupWizardState } from './types';
import { SLICE_NAME } from './types';

export const initialState: InfluencerSignupWizardState = {
  signupInfluencerWithPasswordLoadingState: LoadingState.IDLE,
  signupInfluencerWithPasswordError: null,
};

export const { reducer } = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // signup with passowrd
      .addCase(signupInfluencerWithPassword.pending, (state) => {
        state.signupInfluencerWithPasswordLoadingState = LoadingState.PENDING;
        state.signupInfluencerWithPasswordError = null;
      })
      .addCase(signupInfluencerWithPassword.rejected, (state, action) => {
        state.signupInfluencerWithPasswordLoadingState = LoadingState.REJECTED;
        state.signupInfluencerWithPasswordError =
          action.payload as ErrorMessage[];
      })
      .addCase(signupInfluencerWithPassword.fulfilled, (state) => {
        state.signupInfluencerWithPasswordLoadingState =
          LoadingState.FULLFILLED;
      });
  },
});
