export default [
  { name: 'Afghanistan', code: 'AF', inSearch: true },
  { name: 'Antarctica', code: 'AQ', inSearch: true },
  { name: 'Argentina', code: 'AR', inSearch: true },
  { name: 'Australia', code: 'AU', inSearch: true },
  { name: 'Austria', code: 'AT', inSearch: true },
  { name: 'Belgium', code: 'BE', inSearch: true },
  { name: 'Brazil', code: 'BR', inSearch: true },
  { name: 'Bulgaria', code: 'BG', inSearch: true },
  { name: 'Canada', code: 'CA', inSearch: true },
  { name: 'Chad', code: 'TD', inSearch: true },
  { name: 'Chile', code: 'CL', inSearch: true },
  { name: 'China', code: 'CN', inSearch: true },
  { name: 'Colombia', code: 'CO', inSearch: true },
  { name: 'Congo, The Democratic Republic of the', code: 'CD', inSearch: true },
  { name: 'Croatia', code: 'HR', inSearch: true },
  { name: 'Cuba', code: 'CU', inSearch: true },
  { name: 'Czech Republic', code: 'CZ', inSearch: true },
  { name: 'Denmark', code: 'DK', inSearch: true },
  { name: 'Dominican Republic', code: 'DO', inSearch: true },
  { name: 'Ecuador', code: 'EC', inSearch: true },
  { name: 'El Salvador', code: 'SV', inSearch: true },
  { name: 'Ethiopia', code: 'ET', inSearch: true },
  { name: 'Finland', code: 'FI', inSearch: true },
  { name: 'France', code: 'FR', inSearch: true },
  { name: 'Germany', code: 'DE', inSearch: true },
  { name: 'Greece', code: 'GR', inSearch: true },
  { name: 'Guernsey', code: 'GG', inSearch: true },
  { name: 'Guinea', code: 'GN', inSearch: true },
  { name: 'Haiti', code: 'HT', inSearch: true },
  { name: 'Hungary', code: 'HU', inSearch: true },
  { name: 'Iceland', code: 'IS', inSearch: true },
  { name: 'India', code: 'IN', inSearch: true },
  { name: 'Indonesia', code: 'ID', inSearch: true },
  { name: 'Iran, Islamic Republic Of', code: 'IR', inSearch: true },
  { name: 'Ireland', code: 'IE', inSearch: true },
  { name: 'Italy', code: 'IT', inSearch: true },
  { name: 'Japan', code: 'JP', inSearch: true },
  { name: 'Jersey', code: 'JE', inSearch: true },
  { name: 'Kazakhstan', code: 'KZ', inSearch: true },
  { name: 'Kenya', code: 'KE', inSearch: true },
  { name: 'Korea, Republic of', code: 'KR', inSearch: true },
  { name: "Lao People'S Democratic Republic", code: 'LA', inSearch: true },
  { name: 'Latvia', code: 'LV', inSearch: true },
  { name: 'Lithuania', code: 'LT', inSearch: true },
  { name: 'Luxembourg', code: 'LU', inSearch: true },
  { name: 'Macao', code: 'MO', inSearch: true },
  { name: 'Malaysia', code: 'MY', inSearch: true },
  { name: 'Malta', code: 'MT', inSearch: true },
  { name: 'Mexico', code: 'MX', inSearch: true },
  { name: 'Mongolia', code: 'MN', inSearch: true },
  { name: 'Netherlands', code: 'NL', inSearch: true },
  { name: 'New Zealand', code: 'NZ', inSearch: true },
  { name: 'Nicaragua', code: 'NI', inSearch: true },
  { name: 'Nigeria', code: 'NG', inSearch: true },
  { name: 'Norway', code: 'NO', inSearch: true },
  { name: 'Panama', code: 'PA', inSearch: true },
  { name: 'Paraguay', code: 'PY', inSearch: true },
  { name: 'Philippines', code: 'PH', inSearch: true },
  { name: 'Poland', code: 'PL', inSearch: true },
  { name: 'Portugal', code: 'PT', inSearch: true },
  { name: 'Romania', code: 'RO', inSearch: true },
  { name: 'Russian Federation', code: 'RU', inSearch: true },
  { name: 'Saudi Arabia', code: 'SA', inSearch: true },
  { name: 'Singapore', code: 'SG', inSearch: true },
  { name: 'Slovakia', code: 'SK', inSearch: true },
  { name: 'Slovenia', code: 'SI', inSearch: true },
  { name: 'South Africa', code: 'ZA', inSearch: true },
  { name: 'Spain', code: 'ES', inSearch: true },
  { name: 'Sweden', code: 'SE', inSearch: true },
  { name: 'Switzerland', code: 'CH', inSearch: true },
  { name: 'Taiwan, Province of China', code: 'TW', inSearch: true },
  { name: 'Thailand', code: 'TH', inSearch: true },
  { name: 'Turkey', code: 'TR', inSearch: true },
  { name: 'Tuvalu', code: 'TV', inSearch: true },
  { name: 'Ukraine', code: 'UA', inSearch: true },
  { name: 'United Arab Emirates', code: 'AE', inSearch: true },
  { name: 'United Kingdom', code: 'GB', inSearch: true },
  { name: 'United States', code: 'US', inSearch: true },
  { name: 'Venezuela', code: 'VE', inSearch: true },
  { name: 'Vietnam', code: 'VN', inSearch: true },
  { name: 'Yemen', code: 'YE', inSearch: true },
  { name: 'Aland Islands', code: 'AX', inSearch: true },
  { name: 'Peru', code: 'PE', inSearch: true },
  { name: 'Albania', code: 'AL', inSearch: false },
  { name: 'Algeria', code: 'DZ', inSearch: true },
  { name: 'American Samoa', code: 'AS', inSearch: false },
  { name: 'Andorra', code: 'AD', inSearch: false },
  { name: 'Angola', code: 'AO', inSearch: false },
  { name: 'Anguilla', code: 'AI', inSearch: false },
  { name: 'Antigua and Barbuda', code: 'AG', inSearch: false },
  { name: 'Armenia', code: 'AM', inSearch: false },
  { name: 'Aruba', code: 'AW', inSearch: false },
  { name: 'Azerbaijan', code: 'AZ', inSearch: false },
  { name: 'Bahamas', code: 'BS', inSearch: false },
  { name: 'Bahrain', code: 'BH', inSearch: true },
  { name: 'Bangladesh', code: 'BD', inSearch: true },
  { name: 'Barbados', code: 'BB', inSearch: false },
  { name: 'Belarus', code: 'BY', inSearch: false },
  { name: 'Belize', code: 'BZ', inSearch: false },
  { name: 'Benin', code: 'BJ', inSearch: false },
  { name: 'Bermuda', code: 'BM', inSearch: false },
  { name: 'Bhutan', code: 'BT', inSearch: false },
  { name: 'Bolivia', code: 'BO', inSearch: false },
  { name: 'Bosnia and Herzegovina', code: 'BA', inSearch: false },
  { name: 'Botswana', code: 'BW', inSearch: false },
  { name: 'Bouvet Island', code: 'BV', inSearch: false },
  { name: 'British Indian Ocean Territory', code: 'IO', inSearch: false },
  { name: 'Brunei Darussalam', code: 'BN', inSearch: false },
  { name: 'Burkina Faso', code: 'BF', inSearch: false },
  { name: 'Burundi', code: 'BI', inSearch: false },
  { name: 'Cambodia', code: 'KH', inSearch: false },
  { name: 'Cameroon', code: 'CM', inSearch: false },
  { name: 'Cape Verde', code: 'CV', inSearch: false },
  { name: 'Cayman Islands', code: 'KY', inSearch: false },
  { name: 'Central African Republic', code: 'CF', inSearch: false },
  { name: 'Christmas Island', code: 'CX', inSearch: false },
  { name: 'Cocos (Keeling) Islands', code: 'CC', inSearch: false },
  { name: 'Comoros', code: 'KM', inSearch: true },
  { name: 'Congo', code: 'CG', inSearch: false },
  { name: 'Cook Islands', code: 'CK', inSearch: false },
  { name: 'Costa Rica', code: 'CR', inSearch: false },
  { name: "Cote D'Ivoire", code: 'CI', inSearch: false },
  { name: 'Cyprus', code: 'CY', inSearch: false },
  { name: 'Djibouti', code: 'DJ', inSearch: true },
  { name: 'Dominica', code: 'DM', inSearch: false },
  { name: 'Egypt', code: 'EG', inSearch: true },
  { name: 'Equatorial Guinea', code: 'GQ', inSearch: false },
  { name: 'Eritrea', code: 'ER', inSearch: false },
  { name: 'Estonia', code: 'EE', inSearch: true },
  { name: 'Falkland Islands (Malvinas)', code: 'FK', inSearch: false },
  { name: 'Faroe Islands', code: 'FO', inSearch: false },
  { name: 'Fiji', code: 'FJ', inSearch: false },
  { name: 'French Guiana', code: 'GF', inSearch: false },
  { name: 'French Polynesia', code: 'PF', inSearch: false },
  { name: 'French Southern Territories', code: 'TF', inSearch: false },
  { name: 'Gabon', code: 'GA', inSearch: false },
  { name: 'Gambia', code: 'GM', inSearch: false },
  { name: 'Georgia', code: 'GE', inSearch: false },
  { name: 'Ghana', code: 'GH', inSearch: false },
  { name: 'Gibraltar', code: 'GI', inSearch: false },
  { name: 'Greenland', code: 'GL', inSearch: false },
  { name: 'Grenada', code: 'GD', inSearch: false },
  { name: 'Guadeloupe', code: 'GP', inSearch: false },
  { name: 'Guam', code: 'GU', inSearch: false },
  { name: 'Guatemala', code: 'GT', inSearch: false },
  { name: 'Guinea-Bissau', code: 'GW', inSearch: false },
  { name: 'Guyana', code: 'GY', inSearch: false },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM', inSearch: false },
  { name: 'Holy See (Vatican City State)', code: 'VA', inSearch: false },
  { name: 'Honduras', code: 'HN', inSearch: false },
  { name: 'Hong Kong', code: 'HK', inSearch: false },
  { name: 'Iraq', code: 'IQ', inSearch: true },
  { name: 'Isle of Man', code: 'IM', inSearch: false },
  { name: 'Israel', code: 'IL', inSearch: true },
  { name: 'Jamaica', code: 'JM', inSearch: false },
  { name: 'Jordan', code: 'JO', inSearch: true },
  { name: 'Kiribati', code: 'KI', inSearch: false },
  {
    name: "Korea, Democratic People'S Republic of",
    code: 'KP',
    inSearch: false,
  },
  { name: 'Kuwait', code: 'KW', inSearch: true },
  { name: 'Kyrgyzstan', code: 'KG', inSearch: false },
  { name: 'Lebanon', code: 'LB', inSearch: true },
  { name: 'Lesotho', code: 'LS', inSearch: false },
  { name: 'Liberia', code: 'LR', inSearch: false },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', inSearch: true },
  { name: 'Liechtenstein', code: 'LI', inSearch: false },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
    inSearch: false,
  },
  { name: 'Madagascar', code: 'MG', inSearch: false },
  { name: 'Malawi', code: 'MW', inSearch: false },
  { name: 'Maldives', code: 'MV', inSearch: false },
  { name: 'Mali', code: 'ML', inSearch: false },
  { name: 'Marshall Islands', code: 'MH', inSearch: false },
  { name: 'Martinique', code: 'MQ', inSearch: false },
  { name: 'Mauritania', code: 'MR', inSearch: true },
  { name: 'Mauritius', code: 'MU', inSearch: false },
  { name: 'Mayotte', code: 'YT', inSearch: false },
  { name: 'Micronesia, Federated States of', code: 'FM', inSearch: false },
  { name: 'Moldova, Republic of', code: 'MD', inSearch: false },
  { name: 'Monaco', code: 'MC', inSearch: false },
  { name: 'Montserrat', code: 'MS', inSearch: false },
  { name: 'Morocco', code: 'MA', inSearch: true },
  { name: 'Mozambique', code: 'MZ', inSearch: false },
  { name: 'Myanmar', code: 'MM', inSearch: false },
  { name: 'Namibia', code: 'NA', inSearch: false },
  { name: 'Nauru', code: 'NR', inSearch: false },
  { name: 'Nepal', code: 'NP', inSearch: false },
  { name: 'Netherlands Antilles', code: 'AN', inSearch: false },
  { name: 'New Caledonia', code: 'NC', inSearch: false },
  { name: 'Niger', code: 'NE', inSearch: false },
  { name: 'Niue', code: 'NU', inSearch: false },
  { name: 'Norfolk Island', code: 'NF', inSearch: false },
  { name: 'Northern Mariana Islands', code: 'MP', inSearch: false },
  { name: 'Oman', code: 'OM', inSearch: true },
  { name: 'Pakistan', code: 'PK', inSearch: true },
  { name: 'Palau', code: 'PW', inSearch: false },
  { name: 'Palestinian Territory, Occupied', code: 'PS', inSearch: true },
  { name: 'Papua New Guinea', code: 'PG', inSearch: false },
  { name: 'Pitcairn', code: 'PN', inSearch: false },
  { name: 'Puerto Rico', code: 'PR', inSearch: false },
  { name: 'Qatar', code: 'QA', inSearch: true },
  { name: 'Reunion', code: 'RE', inSearch: false },
  { name: 'RWANDA', code: 'RW', inSearch: false },
  { name: 'Saint Helena', code: 'SH', inSearch: false },
  { name: 'Saint Kitts and Nevis', code: 'KN', inSearch: false },
  { name: 'Saint Lucia', code: 'LC', inSearch: false },
  { name: 'Saint Pierre and Miquelon', code: 'PM', inSearch: false },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', inSearch: false },
  { name: 'Samoa', code: 'WS', inSearch: false },
  { name: 'San Marino', code: 'SM', inSearch: false },
  { name: 'Sao Tome and Principe', code: 'ST', inSearch: false },
  { name: 'Senegal', code: 'SN', inSearch: false },
  { name: 'Serbia and Montenegro', code: 'CS', inSearch: false },
  { name: 'Seychelles', code: 'SC', inSearch: false },
  { name: 'Sierra Leone', code: 'SL', inSearch: false },
  { name: 'Solomon Islands', code: 'SB', inSearch: false },
  { name: 'Somalia', code: 'SO', inSearch: false },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    inSearch: false,
  },
  { name: 'Sri Lanka', code: 'LK', inSearch: false },
  { name: 'Sudan', code: 'SD', inSearch: true },
  { name: 'Suriname', code: 'SR', inSearch: false },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', inSearch: false },
  { name: 'Swaziland', code: 'SZ', inSearch: false },
  { name: 'Syrian Arab Republic', code: 'SY', inSearch: false },
  { name: 'Tajikistan', code: 'TJ', inSearch: false },
  { name: 'Tanzania, United Republic of', code: 'TZ', inSearch: false },
  { name: 'Thailand', code: 'TH', inSearch: false },
  { name: 'Timor-Leste', code: 'TL', inSearch: false },
  { name: 'Togo', code: 'TG', inSearch: false },
  { name: 'Tokelau', code: 'TK', inSearch: false },
  { name: 'Tonga', code: 'TO', inSearch: false },
  { name: 'Trinidad and Tobago', code: 'TT', inSearch: false },
  { name: 'Tunisia', code: 'TN', inSearch: true },
  { name: 'Turkmenistan', code: 'TM', inSearch: false },
  { name: 'Turks and Caicos Islands', code: 'TC', inSearch: false },
  { name: 'Uganda', code: 'UG', inSearch: false },
  { name: 'United States Minor Outlying Islands', code: 'UM', inSearch: false },
  { name: 'Uruguay', code: 'UY', inSearch: false },
  { name: 'Uzbekistan', code: 'UZ', inSearch: false },
  { name: 'Vanuatu', code: 'VU', inSearch: false },
  { name: 'Vietnam', code: 'VN', inSearch: false },
  { name: 'Virgin Islands, British', code: 'VG', inSearch: false },
  { name: 'Virgin Islands, U.S.', code: 'VI', inSearch: false },
  { name: 'Wallis and Futuna', code: 'WF', inSearch: false },
  { name: 'Western Sahara', code: 'EH', inSearch: false },
  { name: 'Zambia', code: 'ZM', inSearch: false },
  { name: 'Zimbabwe', code: 'ZW', inSearch: false },
];

export const regions = [
  {
    name: 'NA',
    code: 'NAREG',
  },
  {
    name: 'EMEA',
    code: 'EMEA',
  },
  {
    name: 'LATAM',
    code: 'LATAM',
  },
  {
    name: 'APAC',
    code: 'APAC',
  },
];

export const REGIONS_COUNTRY_LIST = {
  EMEA: [
    'DZ',
    'AT',
    'BH',
    'BE',
    'BG',
    'TD',
    'KM',
    'HR',
    'CZ',
    'DK',
    'DJ',
    'EG',
    'ET',
    'FI',
    'FR',
    'DE',
    'GR',
    'GG',
    'GN',
    'HU',
    'IS',
    'IQ',
    'IE',
    'IT',
    'JE',
    'JO',
    'KE',
    'KW',
    'LV',
    'LB',
    'LT',
    'LU',
    'MT',
    'MR',
    'MA',
    'NL',
    'NG',
    'NO',
    'OM',
    'PL',
    'PT',
    'QA',
    'RO',
    'SA',
    'SK',
    'SI',
    'ZA',
    'ES',
    'SD',
    'SE',
    'CH',
    'TN',
    'TR',
    'UA',
    'AE',
    'GB',
    'YE',
  ],
  NAREG: ['CA', 'CU', 'DO', 'SV', 'HT', 'MX', 'NI', 'PA', 'US'],
  LATAM: ['AR', 'BR', 'CL', 'CO', 'EC', 'PY', 'PE', 'VE'],
  APAC: [
    'AF',
    'AQ',
    'AU',
    'CN',
    'IN',
    'ID',
    'JP',
    'KZ',
    'LY',
    'MO',
    'MY',
    'MN',
    'NZ',
    'PH',
    'RU',
    'SG',
    'TH',
    'TV',
    'VN',
  ],
};
