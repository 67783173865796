import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { array, boolean, object, string } from 'yup';

import * as regex from '../../common/constants/regex';
import { getUserOrganizationRestrictions } from '../../common/containers/Account/selectors';
import { campaignUpdateFormSubmitted } from '../../store/events';
import { selectCampaignById } from '../../store/models/campaigns/campaigns.selectors';
import { getTrialAccount } from '../../store/models/user/user.selectors';
import getImageSize from '../../utils/getImageSize';
import objectFilter from '../../utils/object-filter';
import { addToggles } from '../CampaignWizard/CampaignWizardInfluencers';

const mapPropsToValues = ({ campaign = {} }) => {
  const { name } = campaign;
  return {
    campaignName: name,
    campaignTypes: campaign && campaign.campaign_types,
    mediaTrakinkWarning: 'wait',
    sponsValWarning: 'wait',
    campaignDays:
      campaign &&
      campaign.campaign_days &&
      campaign.campaign_days.length &&
      campaign.campaign_days.sort(),
    campaignDaysFrame:
      campaign &&
      campaign.campaign_days &&
      campaign.campaign_days.length &&
      campaign.campaign_days.sort(),

    campaignLoaded: !!(campaign && campaign.id),
    influencersSocials:
      campaign && campaign.campaign_influencers
        ? campaign.campaign_influencers.map((inf) => ({
            id: inf.id,
            profile_image_url: inf.avatar,
            display_name: inf.name,
            token: inf.influencer_token,
            ...addToggles(
              objectFilter((v) => v !== null)({
                influencerYoutubeUrl: inf.youtube_url || null,
                influencerTwitchUrl: inf.twitch_url || null,
                influencerTwitterUrl: inf.twitter_url || null,
                influencerFacebookUrl: inf.facebook_url || null,
                influencerInstagramUrl: inf.instagram_url || null,
                influencerTiktokUrl: inf.tiktok_url || null,
                influencerFacebookGamingUrl: inf.facebook_gaming_url || null,
              })
            ),
          }))
        : [],
    shoutouts: campaign
      ? (campaign.shoutout_deliverables || [])
          .filter((d) => !d.v2)
          .concat(
            (campaign.shoutout_deliverables_v2 || []).map((d) => ({
              ...d,
              v2: true,
            }))
          )
          .map(
            ({
              v2,
              name,
              content,
              inclusion_terms,
              exclusion_terms,
              structured,
              id,
              campaign_brand_id,
            }) => {
              if (!v2)
                return {
                  campaign_brand_id,
                  structured: false,
                  name: content,
                  inclusionTerms: [content],
                  exclusionTerms: [],
                  v2: false,
                  id,
                };

              return {
                campaign_brand_id,
                structured,
                name,
                inclusionTerms: inclusion_terms,
                exclusionTerms: exclusion_terms,
                id,
                v2,
              };
            }
          )
      : [],
    socialMediaKeywords:
      campaign && campaign.social_media_mention_deliverables
        ? campaign.social_media_mention_deliverables
        : [],
    chatMentionKeywords:
      campaign && campaign.keyword_mention_deliverables
        ? campaign.keyword_mention_deliverables.map((d) => {
            return {
              keyword: d.keyword,
              campaign_brand_id: d.campaign_brand_id,
            };
          })
        : [],
    newLink: '',
    linksBrands:
      campaign && campaign.link_tracking_deliverables
        ? campaign.link_tracking_deliverables
            .filter((el) => el.campaign_brand_id)
            .map((l) => {
              return {
                id: l.id || null,
                link: l.link,
                campaign_brand_id: l.campaign_brand_id,
              };
            })
        : [],
    links:
      campaign && campaign.link_tracking_deliverables
        ? campaign.link_tracking_deliverables
            .filter((el) => !el.campaign_brand_id)
            .map((l) => {
              return {
                id: l.id || null,
                link: l.link,
              };
            })
        : [],
    newBannerUrl: '',
    banners:
      campaign && campaign.banner_deliverables
        ? campaign.banner_deliverables.map((d) => ({
            bannerUrl: d.image || d.raw_banner_url,
            rawBanner: d.raw_banner_url,
            id: d.id,
            campaign_brand_id: d.campaign_brand_id,
          }))
        : [],
    brands: campaign?.campaign_brands?.map((el) => ({
      brandName: el.brand_name,
      id: el.id,
    })),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    params: { campaignId },
  } = ownProps.match;

  return {
    campaign: selectCampaignById(campaignId)(state),
    trialAccount: getTrialAccount(state),
    organizationRestrictions: getUserOrganizationRestrictions(state),
  };
};

const platformSchema = (platform, regex, message) => {
  const capitalizedPlatform =
    platform.slice(0, 1).toUpperCase() + platform.slice(1).toLowerCase();

  const urlKey = `influencer${capitalizedPlatform}Url`;
  const checkKey = `${urlKey}Checked`;

  return {
    [urlKey]: string().when(checkKey, {
      is: true,
      then: string().matches(regex, `Is not a valid url. ${message}`),
    }),
    [checkKey]: boolean(),
  };
};

export default compose(
  connect(mapStateToProps, null),
  withFormik({
    mapPropsToValues,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: object().shape({
      campaignName: string().required('Choose a name for your campaign'),
      newLink: string().matches(regex.URL, 'Not a valid url'),
      banners: array().of(
        object().test(
          'minimum-size',
          'Image has to be at least 60x60 pixels.',
          async (banner) => {
            if ((!banner.bannerUrl && !banner.bannerFile) || banner.id)
              return true;

            const { width, height } = await getImageSize(
              banner.bannerUrl && banner.bannerUrl.length > 0
                ? banner.bannerUrl
                : banner.bannerFile
            );

            if (width < 60 || height < 60) return false;

            return true;
          }
        )
      ),
      influencersSocials: array().of(
        object({
          ...platformSchema(
            'twitch',
            /(twitch.tv)\/(\S+)/i,
            'Must Include: twitch.tv'
          ),
          ...platformSchema(
            'youtube',
            /(youtube.com)\/[A-Z0-9._%+-]*/i,
            'Must Include: youtube.com'
          ),
          ...platformSchema(
            'tiktok',
            /(tiktok.com)\/[A-Z0-9._%+-]*/i,
            'Must Include: tiktok.com'
          ),
          ...platformSchema(
            'facebook',
            /(facebook\.com|fb.me)\/(\S+)/i,
            'Must Include: facebook.com or fb.me'
          ),
          ...platformSchema(
            'twitter',
            /(twitter.com)\/(\S+)/i,
            'Must Include: twitter.com'
          ),
          ...platformSchema(
            'instagram',
            /(instagram.com)\/(?!p\/)(\S+)/i,
            'Should include instagram.com and not /p/'
          ),
        })
      ),
    }),
    handleSubmit: (values, bag) => {
      const {
        props: {
          campaign: {
            id,
            campaign_influencers,
            name,
            shoutout_deliverables,
            shoutout_deliverables_v2,
            keyword_mention_deliverables,
            link_tracking_deliverables,
            social_media_mention_deliverables,
            banner_deliverables,
          },
        },
      } = bag;
      const influencerIds = campaign_influencers.map((i) => i.id);
      const link_tracking_deliverables_media =
        link_tracking_deliverables.filter((el) => !el.campaign_brand_id);
      const link_tracking_deliverables_sv = link_tracking_deliverables.filter(
        (el) => el.campaign_brand_id
      );
      const deliverables = {};

      const allShoutoutDeliverables = (shoutout_deliverables || []).concat(
        (shoutout_deliverables_v2 || []).map((d) => ({ ...d, v2: true }))
      );

      const shoutoutDeliverables = allShoutoutDeliverables.filter((d) => !d.v2);
      const shoutoutDeliverablesV2 = allShoutoutDeliverables.filter(
        (d) => d.v2
      );

      const newShoutoutDeliverables = values.shoutouts.filter((d) => !d.v2);
      const newShoutoutDeliverablesV2 = values.shoutouts.filter((d) => d.v2);

      if (shoutoutDeliverables && shoutoutDeliverables.length > 0) {
        const deliverablesToRemove = new Set(
          shoutoutDeliverables.filter((d) => d.id).map((d) => d.id)
        );

        deliverables.shoutout_deliverables_attributes = newShoutoutDeliverables
          .map((d) => {
            deliverablesToRemove.delete(d.id);

            return { id: d.id, content: d.name };
          })
          .concat(
            Array.from(deliverablesToRemove).map((id) => ({
              id,
              _destroy: true,
            }))
          );
      }

      if (shoutoutDeliverablesV2 && shoutoutDeliverablesV2.length) {
        const deliverablesToRemove = new Set(
          shoutoutDeliverablesV2.filter((d) => d.id).map((d) => d.id)
        );

        deliverables.shoutout_deliverables_v2_attributes =
          newShoutoutDeliverablesV2
            .map((d) => {
              deliverablesToRemove.delete(d.id);

              return {
                name: d.name,
                inclusion_terms: d.inclusionTerms,
                exclusion_terms: d.exclusionTerms,
                structured: d.structured,
                id: d.id,
                campaign_brand_id: d.campaign_brand_id,
              };
            })
            .concat(
              Array.from(deliverablesToRemove).map((id) => ({
                id,
                _destroy: true,
              }))
            );
      } else {
        deliverables.shoutout_deliverables_v2_attributes =
          newShoutoutDeliverablesV2.map((d) => ({
            name: d.name,
            inclusion_terms: d.inclusionTerms,
            exclusion_terms: d.exclusionTerms,
            structured: d.structured,
            campaign_brand_id: d.campaign_brand_id,
          }));
      }

      if (keyword_mention_deliverables) {
        const keywords = new Set(
          values.chatMentionKeywords.map((el) => el.keyword)
        );
        const newKeywords = new Set(
          values.chatMentionKeywords.map((el) => el.keyword)
        );

        deliverables.keyword_mention_deliverables_attributes =
          keyword_mention_deliverables
            .map((d) => {
              const bareDeliverable = {
                id: d.id,
                keyword: d.keyword,
                campaign_brand_id: d.campaign_brand_id,
              };

              if (keywords.has(d.keyword)) {
                newKeywords.delete(d.keyword);
                return bareDeliverable;
              }

              return { ...bareDeliverable, _destroy: true };
            })
            .concat(
              Array.from(newKeywords).map((d) => ({
                keyword: d,
                campaign_brand_id: values.chatMentionKeywords.filter(
                  (el) => el.keyword === d
                )[0].campaign_brand_id,
              }))
            );
      } else {
        deliverables.keyword_mention_deliverables_attributes =
          values.chatMentionKeywords.map((d) => ({
            keyword: d.keyword,
            campaign_brand_id: d.campaign_brand_id,
          }));
      }

      if (link_tracking_deliverables) {
        const linksIds = new Set(values.links.map((el) => el.id));
        const newLinks = [...values.links].filter((el) => !el.id);

        const linksBrandsIds = new Set(values.linksBrands.map((el) => el.id));
        const newLinksBrands = [...values.linksBrands].filter((el) => !el.id);

        const linksTracking = link_tracking_deliverables_media
          .map((d) => {
            const bareDeliverable = {
              id: d.id,
              link: d.link,
            };

            // If the link tracking exists we just return it
            if (linksIds.has(d.id)) {
              return bareDeliverable;
            } else {
              // If it doesn't that means it was deleted so we flag it appropriately
              return { ...bareDeliverable, _destroy: true };
            }
          })
          .concat(newLinks);

        const linksBrandsTracking = link_tracking_deliverables_sv
          .map((d) => {
            const bareDeliverable = {
              id: d.id,
              link: d.link,
              campaign_brand_id: d.campaign_brand_id,
            };

            // If the link tracking exists we just return it
            if (linksBrandsIds.has(d.id)) {
              return bareDeliverable;
            } else {
              // If it doesn't that means it was deleted so we flag it appropriately
              return { ...bareDeliverable, _destroy: true };
            }
          })
          .concat(newLinksBrands);

        deliverables.link_tracking_deliverables_attributes =
          linksTracking.concat(linksBrandsTracking);
      } else {
        deliverables.link_tracking_deliverables_attributes =
          values.linksBrands.map((l) =>
            ({
              link: l.link,
              campaign_brand_id: l.campaign_brand_id,
            }.concat(
              values.links.map((l) => ({
                link: l.link,
              }))
            ))
          );
      }

      if (social_media_mention_deliverables) {
        const keywordsIds = new Set(
          values.socialMediaKeywords.map((el) => el.id)
        );
        const newKeywords = [...values.socialMediaKeywords].filter(
          (item) => !item.id
        );

        deliverables.social_media_mention_deliverables_attributes =
          social_media_mention_deliverables
            .map((d) => {
              const bareDeliverable = {
                id: d.id,
                keyword: d.keyword,
                campaign_brand_id: d.campaign_brand_id,
              };

              // If this keyword is already existing then we just return it
              if (keywordsIds.has(d.id)) {
                return bareDeliverable;
              } else {
                // Other wise we flag it to be deleted
                return { ...bareDeliverable, _destroy: true };
              }
            })
            .concat(newKeywords); // The new keywords just get added
      } else {
        deliverables.social_media_mention_deliverables_attributes =
          values.socialMediaKeywords.map((k) => ({
            keyword: k.keyword,
            campaign_brand_id: k.campaign_brand_id,
          }));
      }

      const bannersToDestroy = new Set(
        (banner_deliverables || []).map((d) => d.id)
      );

      deliverables.banner_deliverables_attributes = values.banners
        .map((banner) => {
          const {
            id,
            bannerUrl,
            raw_banner_url,
            bannerFile,
            campaign_brand_id,
          } = banner;

          if (id) {
            bannersToDestroy.delete(id);

            return {
              id,
              raw_banner_url: raw_banner_url,
              image: !!raw_banner_url ? undefined : bannerUrl,
              campaign_brand_id: campaign_brand_id,
            };
          }

          if (bannerUrl)
            return { image: bannerUrl, campaign_brand_id: campaign_brand_id };

          return {
            raw_banner: {
              data: bannerFile,
            },
            campaign_brand_id: campaign_brand_id,
          };
        })
        .concat(
          Array.from(bannersToDestroy).map((id) => ({ id, _destroy: true }))
        );

      if (
        values.campaignDays[values.campaignDays.length - 1] >=
          values.campaignDays[0] ||
        values.campaignDaysFrame[values.campaignDaysFrame.length - 1] >=
          values.campaignDaysFrame[0]
      )
        bag.props.dispatch(
          campaignUpdateFormSubmitted({
            id,
            values,
            influencerIds,
            name,
            deliverables,
            bag,
          })
        );
    },
    enableReinitialize: true,
  })
);
