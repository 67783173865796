import { ComponentMeta, ComponentStory } from '@storybook/react';
import React from 'react';

import { AudienceChoropleth } from './AudienceChoropleth';

// More on default export: https://storybook.js.org/docs/react/writing-stories/introduction#default-export
export default {
  title: 'AudienceChoropleth',
  component: AudienceChoropleth,
  argTypes: {
    width: { type: 'number' },
    height: { type: 'number' },
  },
} as ComponentMeta<typeof AudienceChoropleth>;

export const TOP_COUNTRIES = {
  US: 38,
  GB: 8,
  CA: 6,
  FR: 6,
  BR: 4,
  ES: 4,
  other: 34,
};

const Template: ComponentStory<typeof AudienceChoropleth> = (arg) => {
  return <AudienceChoropleth {...arg} />;
};

export const Primary = Template.bind({});
Primary.args = {
  topCountries: TOP_COUNTRIES,
  width: 1000,
  height: 600,
};
