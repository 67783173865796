import _cloneDeep from 'lodash/cloneDeep';

import { InfluencerInfo } from '../store/types';

export const EMPTY_FORM_VALUES = {
  emailChecked: false,
  player_type: { pro_player: false, streamer: false },
  location: '',
  interests: [],
  other_interests: [],
  gender: {
    maleOrFemale: '',
    // disable custom gender for now
    customGender: 'other',
  },
  phone: '',
  phoneChecked: false,
  discord_username: '',
  discordChecked: false,
  name: '',
  date_of_birth: '',
};

export type InfluencerInfoFormValues = typeof EMPTY_FORM_VALUES;

export const formValuesToInfluencerInfo = (
  values: InfluencerInfoFormValues
) => {
  const {
    location,
    gender: { maleOrFemale, customGender },
    emailChecked,
    discord_username,
    discordChecked,
    phone,
    phoneChecked,
    interests,
    other_interests,
    player_type,
    name,
    date_of_birth,
  } = values;
  // always set mark the wizard as complete
  const creator: Partial<InfluencerInfo> = { wizard_completed: true };

  creator.location = location ?? null;

  creator.name = name ?? null;

  creator.gender =
    maleOrFemale === 'custom' ? customGender : maleOrFemale ?? null;

  creator.discord_username =
    discordChecked && discord_username ? discord_username : null;

  creator.phone = phoneChecked && phone ? phone : null;

  creator.interests = interests && interests.length !== 0 ? interests : [];

  creator.other_interests =
    other_interests && other_interests.length !== 0 ? other_interests : [];

  creator.date_of_birth = date_of_birth ?? null;

  creator.contact_by_email = !!emailChecked;

  creator.player_type = player_type;

  return creator;
};

export const influencerInfoToFormValues = (
  info: InfluencerInfo
): InfluencerInfoFormValues => {
  const values = _cloneDeep(EMPTY_FORM_VALUES);

  const {
    location,
    gender,
    discord_username,
    phone,
    interests,
    other_interests,
    player_type,
    contact_by_email,
    name,
    date_of_birth,
  } = info;
  if (location) values.location = location;
  if (gender) {
    if (gender !== 'male' && gender !== 'female') {
      values.gender.customGender = gender;
      values.gender.maleOrFemale = 'custom';
    } else {
      values.gender.maleOrFemale = gender;
    }
  }

  if (discord_username) {
    values.discord_username = discord_username;
    values.discordChecked = true;
  }

  if (phone) {
    values.phone = phone;
    values.phoneChecked = true;
  }

  if (phone) {
    values.phone = phone;
    values.phoneChecked = true;
  }

  if (interests) {
    values.interests = interests;
  }

  if (other_interests) {
    values.other_interests = other_interests;
  }

  if (player_type) {
    values.player_type = player_type;
  }

  if (name) {
    values.name = name;
  }

  if (date_of_birth) {
    values.date_of_birth = date_of_birth;
  }

  values.emailChecked = !!contact_by_email;

  return values;
};
