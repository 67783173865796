import { FormLabel } from '@material-ui/core';
import { Formik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';

import { Alert } from '@material-ui/lab';
import FormikMuiTextField from '../../../components/FormikMuiTextField';
import { LoadingState } from '../../Campaign/types';
import { ModalShell } from '../../influencerViews/Profile/components/modals/ModalShell';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Not a valid email')
    .required('Enter email')
    .max(75, 'Must be shorter than 75 character'),
  name: yup
    .string()
    .required('Enter name')
    .matches(/^(?!\s+$).*/, 'Name cannot contain only blank spaces'),
});

const validateForm = async (values, props) => {
  const { password, passwordConfirm } = values;

  let error = {};

  if (password && passwordConfirm && password !== passwordConfirm) {
    error.passwordConfirm =
      'Password should match password confirmation!';
  }

  return error;
};

export const InviteUserModal = ({ onClose, onSubmit }) => {
  const [error, setError] = useState(null);
  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);

  const onSubmitLocal = async (values) => {
    setLoadingState(LoadingState.PENDING);
    setError(null);

    await onSubmit(values).then(({ success, errorMessage }) => {
      if (success) {
        setLoadingState(LoadingState.FULFILLED);
        onClose();
      } else {
        setError(errorMessage);

        setLoadingState(LoadingState.IDLE);
      }
    });
  };

  return (
    <Formik
      initialValues={{ name: '', email: '', password: '', passwordConfirm: '' }}
      onSubmit={onSubmitLocal}
      validationSchema={validationSchema}
      validate={validateForm}
    >
      {(formik) => {
        const { submitForm, isValid } = formik;

        return (
          <ModalShell
            title="ADD USER"
            open={true}
            onClose={() => {
              onClose();
              setError(null);
            }}
            onConfirm={submitForm}
            disableConfirm={!isValid}
            loading={loadingState === LoadingState.PENDING}
            noActionButtons={loadingState === LoadingState.FULFILLED}
          >
            <FormLabel style={{ marginBottom: 15 }}>Full name</FormLabel>
            <FormikMuiTextField
              name="name"
              fullWidth
              placeholder="Add name"
              variant="outlined"
              style={{ marginBottom: 16 }}
              disabled={loadingState === LoadingState.PENDING}
            />
            <FormLabel style={{ marginBottom: 15 }}>Email</FormLabel>
            <FormikMuiTextField
              name="email"
              fullWidth
              placeholder="user@mail.com"
              variant="outlined"
              style={{ marginBottom: 16 }}
              disabled={loadingState === LoadingState.PENDING}
            />
            <FormLabel style={{ marginBottom: 15 }}>New Password</FormLabel>
            <FormikMuiTextField
              name="password"
              type="password"
              fullWidth
              placeholder="New Password"
              variant="outlined"
              style={{ marginBottom: 16 }}
              disabled={loadingState === LoadingState.PENDING}
            />
            <FormLabel style={{ marginBottom: 15 }}>
              Repeat New Password
            </FormLabel>
            <FormikMuiTextField
              name="passwordConfirm"
              type="password"
              fullWidth
              placeholder="Confirm Password"
              variant="outlined"
              style={{ marginBottom: 16 }}
              disabled={loadingState === LoadingState.PENDING}
            />
            { error && (
              <Alert severity="error">
                {error}
              </Alert>
            )}

          </ModalShell>
        );
      }}
    </Formik>
  );
};
