import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  disconnectInfluencerSocial,
  getInfoInfluencer,
  putInfoInfluencer,
  putNewPasswordInfluencer,
} from '../../../ajax';
import { getErrorMessagesFromResponse } from '../../../common/ErrorMessageUtil';
import { showToast } from '../../../store/commonActions';
import { State } from '../../../store/types';
import { forceSocialPostsReload } from '../Stats/SocialMedia/store/SocialMediaPosts.slice';
import { selectInfluencerInfo } from './InfluencerInfo.selectors';
import { InfluencerInfo, PlatformName, SLICE_NAME } from './types';

export const fetchInfluencerInfo = createAsyncThunk<InfluencerInfo>(
  `${SLICE_NAME}/fetchInfluencerInfo`,
  () => getInfoInfluencer()
);

interface PutInfluencerInfoPayload {
  // TODO: use configureStore in order to be able to use dispatch(...).unwrap()
  onSuccess?: () => void;
  influencerInfo: Partial<InfluencerInfo>;
  showToasts?: boolean;
}

export const putInfluencerInfo = createAsyncThunk<
  InfluencerInfo,
  PutInfluencerInfoPayload
>(
  `${SLICE_NAME}/putInfluencerInfo`,
  async (
    { onSuccess, influencerInfo, showToasts },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await putInfoInfluencer(influencerInfo);

      onSuccess?.();

      if (showToasts) {
        dispatch(
          showToast({
            message: 'Details saved successfully',
            type: 'success',
          })
        );
      }

      return res;
    } catch (err) {
      if (showToasts) {
        dispatch(
          showToast({
            message: 'Failed to save details',
            type: 'error',
          })
        );
      }

      return rejectWithValue(getErrorMessagesFromResponse(err));
    }
  }
);

interface DisconnectInfluencerSocialPlatformPayload {
  id: number;
  platform: PlatformName;
}

export const disconnectInfluencerSocialPlatform = createAsyncThunk<
  void,
  DisconnectInfluencerSocialPlatformPayload
>(
  `${SLICE_NAME}/disconnectInfluencerSocialPlatform`,
  async ({ id, platform }, { rejectWithValue, dispatch }) => {
    try {
      await disconnectInfluencerSocial(id, platform);

      dispatch(forceSocialPostsReload());

      // TODO need to translate this error message in the future
      dispatch(
        showToast({
          message: 'Platform disconnected successfully',
          type: 'success',
        })
      );
    } catch (err) {
      // TODO need to translate this error message in the future
      dispatch(
        showToast({
          message: `Failed to disconnect social platform: ${err.bodyJson.message}`,
          type: 'error',
        })
      );
      return rejectWithValue(err);
    }
  }
);

interface ChangeInfluencerPasswordPayload {
  current_password: string;
  password: string;
}

export const changeInfluencerPassword = createAsyncThunk<
  void,
  ChangeInfluencerPasswordPayload
>(
  `${SLICE_NAME}/changeInfluencerPassword`,
  async ({ current_password, password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await putNewPasswordInfluencer(current_password, password);
      // TODO need to translate this error message in the future
      dispatch(
        showToast({ message: 'Password changed successfully', type: 'success' })
      );
      return res;
    } catch (err) {
      return rejectWithValue(getErrorMessagesFromResponse(err));
    }
  }
);

interface ChangeInfluencerEmailPayload {
  email: string;
}

export const changeInfluencerEmail = createAsyncThunk<
  void,
  ChangeInfluencerEmailPayload
>(
  `${SLICE_NAME}/changeInfluencerEmail`,
  async ({ email }, { rejectWithValue, getState, dispatch }) => {
    const { id } = selectInfluencerInfo()(getState() as State) ?? {};

    if (id == null) return;

    try {
      const res = await putInfoInfluencer({ email, id });

      return res;
    } catch (err) {
      return rejectWithValue(getErrorMessagesFromResponse(err));
    }
  }
);
