import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from '@material-ui/core';
import React, { useState } from 'react';

export const useCampaignShoutoutDeleteConfirmation = () => {
  const [shoutoutToDelete, setShoutoutToDelete] = useState(null);

  return { shoutoutToDelete, setShoutoutToDelete };
};

export default function CampaignShoutoutDeleteConfirmation({
  shoutoutToDelete,
  setShoutoutToDelete,
  handleConfirmDeleteShoutout,
}) {
  const authorName = shoutoutToDelete && shoutoutToDelete.influencer.name;

  const isOpen = !!shoutoutToDelete;

  const handleClose = () => setShoutoutToDelete(null);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Voice Mention</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this voice mention from
            <span className="bold"> {authorName}? </span>
            Please note that this action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: '12px 24px' }}
        >
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              handleConfirmDeleteShoutout();
              handleClose();
            }}
            color="primary"
          >
            <Box clone mr={1}>
              <Icon>delete_outline</Icon>
            </Box>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
