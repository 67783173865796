import {
  Avatar,
  Button,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import { Platform } from '../store/types';

type Props = {
  platform: Platform;
};

export const PlatformAccountCard = ({ platform }: Props) => {
  const { connected, pageTitle, icon, key, avatar } = platform;
  const classes = useStyles({ platformColor: platform.color, connected });
  const ctaText = connected ? 'DISCONNECT' : 'CONNECT';

  return (
    <div className={classes.container}>
      {!connected && (
        <div className={classes.platformLogoContainer}>
          <img
            className={classes.platformIcon}
            src={icon}
            alt={`${key} icon`}
          />
        </div>
      )}

      {connected && (
        <div className={classes.accountAvatarContainer}>
          <Avatar src={avatar} className={classes.accountAvatar} />
          <div className={classes.accountAvatarPlatform}>
            <img src={icon} alt={`${key} icon`} />
          </div>
        </div>
      )}

      <div className={classes.accountInfoContainer}>
        {!connected && (
          <Typography className={classes.notConnectedLabel}>
            NOT CONNECTED
          </Typography>
        )}
        <Typography className={classes.accountName}>{pageTitle}</Typography>
        <Button
          type="button"
          color="primary"
          onClick={() => {
            if (!connected) {
              platform?.onConnect && platform?.onConnect();
            } else {
              platform?.onDisconnect && platform?.onDisconnect();
            }
          }}
          className={classes.ctaButton}
        >
          {ctaText}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles<
  Theme,
  { platformColor: string; connected?: boolean }
>(() => ({
  container: {
    display: 'flex',
    width: 203,
    height: 97,
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '4px 4px 20px rgba(0,0,0,0.1)',
    backgroundColor: 'white',
  },

  platformLogoContainer: ({ platformColor }) => ({
    backgroundColor: platformColor,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),

  platformIcon: {
    width: 'auto',
    maxHeight: 35,
  },

  accountInfoContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 6,
    padding: '18px 0',
  },

  accountName: {
    fontSize: '0.875rem',
  },

  notConnectedLabel: {
    fontSize: '0.625rem',
    color: '#c0c0c0',
  },

  accountAvatarContainer: {
    position: 'relative',
    alignSelf: 'center',
    marginLeft: 16,
  },

  accountAvatar: {
    width: 54,
    height: 54,
    borderRadius: '100%',
  },

  accountAvatarPlatform: ({ platformColor }) => ({
    width: 17,
    height: 17,
    backgroundColor: platformColor,
    borderRadius: 4,
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      width: 14,
      height: 14,
    },
  }),

  ctaButton: ({ platformColor, connected }) => ({
    minWidth: 75,
    height: 21,
    color: 'white',
    borderRadius: 2,
    backgroundColor: connected ? '#ff7a7a' : platformColor,
    fontSize: 12,
  }),
}));
