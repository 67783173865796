function twitchLink(videoUrl, segmentStart) {
  if (!videoUrl) {
    return '';
  }

  if (segmentStart !== undefined && segmentStart !== null) {
    const platform = videoUrl.substr(12, 6);

    return videoUrl + `${platform === 'twitch' ? '?' : '&'}t=${segmentStart}s`;
    // https://www.twitch.tv/videos/603719988?t=0h33m52s
  }
}

export default twitchLink;
