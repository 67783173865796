import { all, fork } from 'redux-saga/effects';

import appSaga from './sagas-folder';

const context = require.context('..', true, /\.\/(common|views).*\/sagas\.js$/);
const sagas = [];
context.keys().forEach((path) => {
  const component = context(path);
  sagas.push(component.default);
});

export default function* rootSaga() {
  yield all([...sagas.map((saga) => fork(saga)), fork(appSaga)]);
}
