import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

import VideoPreview from '../../components/VideoPreview';

export const ShoutoutClipDialog = ({
  onClose,
  video,
  open,
  influencer,
  timeZone,
  content,
  clip,
  momentUrl,
  timeOnScreen,
}) => {
  const renderContent = () => {
    if (!open) return null;

    return (
      <DialogContent>
        <VideoPreview
          video={video}
          influencer={influencer}
          timeZone={timeZone}
          momentUrl={momentUrl}
          timeOnScreen={timeOnScreen}
        />
        <video style={{ width: '100%' }} controls autoPlay>
          <source src={clip} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </DialogContent>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ position: 'relative' }} disableTypography>
        <Typography variant="h6">
          <b>{content}</b> occurrence clip
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {renderContent()}
    </Dialog>
  );
};

export default ShoutoutClipDialog;
