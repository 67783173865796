import { makeStyles } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import LinkIcon from '@material-ui/icons/Link';
import StarsIcon from '@material-ui/icons/Stars';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import TheatersIcon from '@material-ui/icons/Theaters';
import VideocamIcon from '@material-ui/icons/Videocam';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { Campaign } from '../types';
import ChatIcon from './ChatIcon';
import ChatsIcon from './ChatsIcon';

const DELIVERABLE_TABS = [
  {
    key: 'summary',
    label: 'Summary',
    icon: <AppsIcon />,
  },
  {
    key: 'shoutouts',
    label: 'Voice mentions',
    icon: <VolumeDownIcon style={{ transform: 'rotate(-45deg)' }} />,
  },
  {
    key: 'banners',
    label: 'Image tracking',
    icon: <TheatersIcon />,
  },
  {
    key: 'link-sharing',
    label: 'Link sharing',
    icon: <LinkIcon style={{ transform: 'rotate(-45deg)' }} />,
  },
  {
    key: 'chat-mentions',
    label: 'Chat/comments mentions',
    icon: <ChatsIcon />,
  },
  {
    key: 'social-media',
    label: 'Social posts mentions',
    icon: <ChatBubbleIcon />,
  },
];

const PERFORMANCE_TABS = [
  {
    key: 'streaming',
    label: 'Videos',
    icon: <VideocamIcon />,
  },
  {
    key: 'social-media',
    label: 'Social media',
    icon: <ChatIcon style={{ height: '20px', width: '20px' }} />,
  },
];

const INSIGHTS_TABS = [
  {
    key: 'top_moments',
    label: 'Top Moments',
    icon: <WhatshotIcon />,
  },
  {
    key: 'top_creators',
    label: 'Top Creators',
    icon: <SupervisedUserCircleIcon />,
  },
  {
    key: 'top_topics',
    label: 'Top Topics',
    icon: <StarsIcon />,
  },
  {
    key: 'stats',
    label: 'Stats',
    icon: <EqualizerIcon />,
  },
] as const;
type InsightsTab = typeof INSIGHTS_TABS[number];
type StatsPageProps = {
  topic: string;
  sentiment?: string;
  feedback?: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: -16,
    marginBottom: 0,
  },
  card: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    background: '#f9fbfc',
    border: 'solid 1px #e4e4e4',
    borderTopWidth: 0,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  tab: {
    marginRight: theme.spacing(3),
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: 16,
  },
  active: {
    color: theme.palette.primary.main,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1.5),
    fontSize: 28,
  },
}));

export const usePerformanceSubMenu = (
  campaign: Campaign,
  setShowSocialPlatforms: (showSocialMediaPlatforms: boolean) => void,
  forcedActiveTab: string | null,
  setForcedActiveTab: (forcedActiveSubMenu: string | null) => void
) => {
  const tabs = [];

  tabs.push(PERFORMANCE_TABS[0]);
  tabs.push(PERFORMANCE_TABS[1]);

  const [activeTab, setActiveTab] = useState<string>(
    forcedActiveTab ? forcedActiveTab : tabs[0]?.key
  );

  useEffect(() => {
    if (forcedActiveTab != null) {
      setActiveTab(forcedActiveTab);

      document
        .getElementsByClassName('main-container')?.[0]
        ?.scrollTo({ top: 0 });
    }
  }, [forcedActiveTab]);

  useEffect(() => {
    setForcedActiveTab(null);
    if (activeTab === 'social-media') setShowSocialPlatforms(true);
    else setShowSocialPlatforms(false);
  }, [activeTab, setShowSocialPlatforms, setForcedActiveTab]);

  return {
    tabs,
    activeTab,
    setActiveTab,
    onTabClick: (key: string) => setActiveTab(key),
  };
};

export const useSponsorshipSubMenu = (
  campaign: Campaign,
  setShowSocialPlatforms: (showSocialMediaPlatforms: boolean) => void,
  forcedActiveTab: string | null,
  setForcedActiveTab: (forcedActiveSubMenu: string | null) => void,
  setHideFilters: (boolean) => void,
  MainActiveTab: string,
  isActive: boolean
) => {
  const tabs = [];

  // Overview tab
  tabs.push(DELIVERABLE_TABS[0]);

  if (
    campaign.all_shoutout_deliverables?.some(
      (el) => el.campaign_brand_id != null
    )
  )
    tabs.push(DELIVERABLE_TABS[1]);

  if (campaign.banner_deliverables?.some((el) => el.campaign_brand_id != null))
    tabs.push(DELIVERABLE_TABS[2]);

  if (
    campaign.link_tracking_deliverables?.some(
      (el) => el.campaign_brand_id != null
    )
  )
    tabs.push(DELIVERABLE_TABS[3]);

  if (
    campaign.keyword_mention_deliverables?.some(
      (el) => el.campaign_brand_id != null
    )
  )
    tabs.push(DELIVERABLE_TABS[4]);

  if (
    campaign.social_media_mention_deliverables?.some(
      (el) => el.campaign_brand_id != null
    )
  )
    tabs.push(DELIVERABLE_TABS[5]);

  const [activeTab, setActiveTab] = useState<string>(
    forcedActiveTab ? forcedActiveTab : tabs?.[0]?.key
  );

  useEffect(() => {
    if (MainActiveTab === 'sponsorship' && activeTab === 'summary') {
      setHideFilters(true);
    } else {
      setHideFilters(false);
    }
  }, [activeTab, MainActiveTab, setHideFilters]);

  useEffect(() => {
    if (forcedActiveTab && isActive) {
      setActiveTab(forcedActiveTab);

      document
        .getElementsByClassName('main-container')?.[0]
        ?.scrollTo({ top: 0 });
    }
  }, [forcedActiveTab, isActive]);

  useEffect(() => {
    // only do something if this is the active tab
    if (!isActive) return;

    setForcedActiveTab(null);

    if (activeTab === 'social-media') setShowSocialPlatforms(true);
    else setShowSocialPlatforms(false);
  }, [setForcedActiveTab, activeTab, setShowSocialPlatforms, isActive]);

  return {
    tabs,
    activeTab,
    setActiveTab,
    onTabClick: (key: string) => setActiveTab(key),
  };
};

export const useDeliverablesSubMenu = (
  campaign: Campaign,
  setShowSocialPlatforms: (showSocialMediaPlatforms: boolean) => void,
  forcedActiveTab: string | null,
  setForcedActiveTab: (forcedActiveSubMenu: string | null) => void,
  isActive: boolean
) => {
  const tabs = [];

  if (
    campaign.all_shoutout_deliverables?.some(
      (el) => el.campaign_brand_id == null
    )
  )
    tabs.push(DELIVERABLE_TABS[1]);

  if (campaign.banner_deliverables?.some((el) => el.campaign_brand_id == null))
    tabs.push(DELIVERABLE_TABS[2]);

  if (
    campaign.link_tracking_deliverables?.some(
      (el) => el.campaign_brand_id == null
    )
  )
    tabs.push(DELIVERABLE_TABS[3]);

  if (
    campaign.keyword_mention_deliverables?.some(
      (el) => el.campaign_brand_id == null
    )
  )
    tabs.push(DELIVERABLE_TABS[4]);

  if (
    campaign.social_media_mention_deliverables?.some(
      (el) => el.campaign_brand_id == null
    )
  )
    tabs.push(DELIVERABLE_TABS[5]);

  const [activeTab, setActiveTab] = useState<string>(
    forcedActiveTab ? forcedActiveTab : tabs?.[0]?.key
  );

  useEffect(() => {
    if (forcedActiveTab && isActive) {
      setActiveTab(forcedActiveTab);
      document
        .getElementsByClassName('main-container')?.[0]
        ?.scrollTo({ top: 0 });
    }
  }, [forcedActiveTab, isActive]);

  useEffect(() => {
    // only do something if this is the active tab
    if (!isActive) return;

    setForcedActiveTab(null);
    if (activeTab === 'social-media') setShowSocialPlatforms(true);
    else setShowSocialPlatforms(false);
  }, [setForcedActiveTab, activeTab, setShowSocialPlatforms, isActive]);

  return {
    tabs,
    activeTab,
    setActiveTab,
    onTabClick: (key: string) => setActiveTab(key),
  };
};

export const useInsightsSubmenu = (
  forcedActiveTab: InsightsTab['key'] | null,
  hasStats: boolean = false
) => {
  const tabs: InsightsTab[] = [];

  tabs.push(...INSIGHTS_TABS);

  if (!hasStats) {
    tabs.pop();
  }

  const [activeTab, setActiveTab] = useState<InsightsTab['key']>(
    forcedActiveTab ? forcedActiveTab : tabs[0]?.key
  );

  const [statsProps, setStatsProps] = useState<StatsPageProps>(null);

  useEffect(() => {
    if (forcedActiveTab !== null) {
      setActiveTab(forcedActiveTab);

      document
        .getElementsByClassName('main-container')?.[0]
        ?.scrollTo({ top: 0 });
    }
  }, [forcedActiveTab]);

  return {
    tabs,
    activeTab,
    setActiveTab,
    onTabClick: (key: InsightsTab['key']) => setActiveTab(key),
    setStatsProps,
    statsProps,
  };
};

export const SubMenu = ({ activeTab, onTabClick, tabs }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        {tabs.map(({ key, label, icon }) => (
          <div
            onClick={() => onTabClick(key)}
            className={cx(classes.tab, { [classes.active]: activeTab === key })}
            key={key}
          >
            <div className={classes.iconContainer}>{icon}</div>
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubMenu;
