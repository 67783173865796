import { FormLabel, Button } from '@material-ui/core';
import { Formik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';

import { Alert } from '@material-ui/lab';
import FormikMuiTextField from '../../../components/FormikMuiTextField';
import { LoadingState } from '../../Campaign/types';
import { ModalShell } from '../../influencerViews/Profile/components/modals/ModalShell';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Not a valid email')
    .required('Enter email')
    .max(75, 'Must be shorter than 75 character'),
  name: yup
    .string()
    .required('Enter name')
    .matches(/^(?!\s+$).*/, 'Name cannot contain only blank spaces'),
});

const validateForm = async (values, props) => {
  const { password, passwordConfirm } = values;

  let error = {};

  if (password && passwordConfirm && password !== passwordConfirm) {
    error.passwordConfirm =
      'Password should match password confirmation!';
  }

  return error;
};

export const EditUserModal = ({ user, onClose, onSubmit }) => {
  const [error, setError] = useState(null);
  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);

  const onSubmitLocal = async (values) => {
    setLoadingState(LoadingState.PENDING);
    setError(null);

    const newValues = {
      name: values.name.trim(),
      email: values.email.trim(),
    }
    if (!!values.password) {
      newValues.password = values.password;
    }

    await onSubmit(user.id, values).then(({ success, errorMessage }) => {
      if (success) {
        setLoadingState(LoadingState.FULFILLED);

        if (newValues.email === user.email) {
          onClose();
        }

      } else {
        setError(errorMessage);

        setLoadingState(LoadingState.IDLE);
      }
    });
  };


  return (
    <Formik
      initialValues={{ name: user.name, email: user.email }}
      onSubmit={onSubmitLocal}
      validationSchema={validationSchema}
      validate={validateForm}
      enableReinitialize
    >
      {(formik) => {
        const { submitForm, isValid } = formik;

        let content = '';

        if (loadingState === LoadingState.FULFILLED) {
          content = (
            <>
              <h4>Email confirmation required</h4>
              <p>
                A confirmation email has been sent to{' '}
                <b>{formik.values.email}</b>. You'll have to visit the link
                enclosed in the email to confirm the email address change.
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={() => {
                    onClose();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Got it
                </Button>
              </div>
            </>
          );
        } else {
          content = (
            <>
              <FormLabel style={{ marginBottom: 15 }}>Full name</FormLabel>
              <FormikMuiTextField
                name="name"
                fullWidth
                placeholder="Add name"
                variant="outlined"
                defaultValue={user.name}
                style={{ marginBottom: 16 }}
                disabled={loadingState === LoadingState.PENDING}
              />
              <FormLabel style={{ marginBottom: 15 }}>Email</FormLabel>
              <FormikMuiTextField
                name="email"
                fullWidth
                placeholder="user@mail.com"
                variant="outlined"
                defaultValue={user.email}
                style={{ marginBottom: 16 }}
                disabled={loadingState === LoadingState.PENDING}
              />
              <FormLabel style={{ marginBottom: 15 }}>New Password</FormLabel>
              <FormikMuiTextField
                name="password"
                type="password"
                fullWidth
                placeholder="New Password"
                variant="outlined"
                style={{ marginBottom: 16 }}
                disabled={loadingState === LoadingState.PENDING}
              />
              <FormLabel style={{ marginBottom: 15 }}>
                Repeat New Password
              </FormLabel>
              <FormikMuiTextField
                name="passwordConfirm"
                type="password"
                fullWidth
                placeholder="Confirm Password"
                variant="outlined"
                style={{ marginBottom: 16 }}
                disabled={loadingState === LoadingState.PENDING}
              />
              { error && (
                <Alert severity="error">
                  {error}
                </Alert>
              )}
            </>
          )
        }

        return (
          <ModalShell
            title="EDIT USER"
            open={true}
            onClose={() => {
              onClose();
              setError(null);
            }}
            onConfirm={submitForm}
            disableConfirm={!isValid}
            loading={loadingState === LoadingState.PENDING}
            noActionButtons={loadingState === LoadingState.FULFILLED}
          >
            { content }
          </ModalShell>
        );
      }}
    </Formik>
  );
};
