import LogRocket from 'logrocket';

import { isInfluencerView } from '../../common/containers/Config/selectors';
import { USER_REQUEST_SUCCEEDED } from '../action-types';
import { getAuthUser, isAggeroUser } from '../models/user/user.selectors';

const analyticsMiddleware = (store) => (next) => (action) => {
  next(action);

  const { type } = action;

  if (type !== USER_REQUEST_SUCCEEDED) return;

  const {
    payload: { user: requestedUser },
  } = action;

  if (!requestedUser) return;

  if (isAggeroUser(store.getState())) return;

  const user = getAuthUser(store.getState());
  const influencerView = isInfluencerView(store.getState());

  // don't enable on SaaS
  if (!influencerView) {
    LogRocket.init('uuabko/aggero');

    LogRocket.identify(user.id, {
      name: user.displayName,
      email: user.email,
      company: {
        id: user.organization_id,
        name: requestedUser.company,
      },
    });
  }
};

export default analyticsMiddleware;
