import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes } from 'recompose';
import { reduxForm } from 'redux-form';

import { FORM_RESET_PASSWORD } from '../../../../common/constants/forms';
import { selectors } from '../../../../store/selectors';

const mapStateToProps = (state) => ({
  authInProgress: selectors.getAuthInProgress(state),
});

export default compose(
  connect(mapStateToProps),
  setPropTypes({
    onSubmit: PropTypes.func.isRequired,
  }),
  reduxForm({
    form: FORM_RESET_PASSWORD,
    validate: (values) => {
      const errors = {};

      if (!values.password) {
        errors.password = 'Required';
      } else if (!values.password_confirmation) {
        errors.password_confirmation = 'Required';
      } else if (values.password !== values.password_confirmation) {
        errors.password_confirmation = 'Must match password confirmation!';
      }

      return errors;
    },
  })
);
