/*
 * number [] => number
 *
 * takes an array of numbers
 * returns the sum of all numbers in the array
 * */

function sum(numbers) {
  return numbers.reduce((total, number) => total + number, 0);
}

export default sum;
