import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g stroke="#000" strokeWidth={0.2} strokeMiterlimit={10}>
        <path d="M12.16 5.075L3.874 16.803l2.668 2.669 2.997-1.101v1.054h5.477v-3.066l4.355-1.593-7.211-9.691zM4.787 16.732L12.184 6.27l6.063 8.146-11.541 4.236-1.919-1.92zm9.551 1.991h-4.097v-.608l4.073-1.498.024 2.106z" />
        <path
          d="M15.085 7.779l1.905-3.252.606.355-1.906 3.252z"
          strokeWidth={0.199996}
        />
        <path
          d="M16.892 10.035l3.25-1.13.231.663-3.25 1.131z"
          strokeWidth={0.20000600000000002}
        />
        <path d="M7.409 16.803l-.75-.679-.468.492 1.054 1.006 4.447-1.662-.257-.655z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
