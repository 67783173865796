import { SocialMediaPlatform } from '../../../../../types';

export type SocialMediaPost = {
  comments_count: number;
  engagements_count: number;
  external_id: string;
  id: number;
  impressions_count: number;
  likes_count: number;
  page_external_id: string;
  permalink: string;
  reactions_count: number;
  shares_count: number;
  text: string;
  owner_name: string;
  owner_avatar: string;
  published_at: string;
  emv: number;
  image: string;
  attachments?: SocialPostAttachment[];
  platform: SocialMediaPlatform;
  media_url?: string;
  thumbnail_url?: string;

  // these are only found on tweets
  retweets?: number;
  replies?: number;
};

export type SocialPostsAPIResponse = {
  emv: number;
  engagements_count: number;
  facebook_count: number;
  impressions: number;
  instagram_count: number;
  likes_count: number;
  replies_cmnts_count: number;
  retweets_shares_count: number;
  tiktok_count: number;
  total_count: number;
  twitter_count: number;
  social_posts: SocialMediaPost[];
};

export type SocialPostAttachment = {
  attachment_type: 'photo' | 'video';
  description: string;
  id: number;
  url: string;
};

export type SocialMediaOverview = Omit<SocialPostsAPIResponse, 'social_posts'>;

export const SLICE_NAME = 'influencerSocialPosts';
