import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from '@material-ui/core';
import React, { useState } from 'react';

export const useCampaignBannerDeleteConfirmation = () => {
  const [bannerToDelete, setBannerToDelete] = useState(null);

  return { bannerToDelete, setBannerToDelete };
};

export default function CampaignBannerDeleteConfirmation({
  bannerToDelete,
  setBannerToDelete,
  handleConfirmDeleteBanner,
}) {
  const authorName = bannerToDelete && bannerToDelete.creator;

  const isOpen = !!bannerToDelete;

  const handleClose = () => setBannerToDelete(null);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Image Tracking</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this image tracking from
            <span className="bold"> {authorName}? </span>
            Please note that this action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: '12px 24px' }}
        >
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              handleConfirmDeleteBanner();
              handleClose();
            }}
            color="primary"
          >
            <Box clone mr={1}>
              <Icon>delete_outline</Icon>
            </Box>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
