import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { PlatformDropdown } from '../../../components/PlatformDropdown/PlatformDropdown';
import {
  Platform,
  SocialMediaPlatform,
  StreamingPlatform,
} from '../../../types';
import { selectSocialPostsPlatformFilter } from './SocialMedia/store/SocialMediaPosts.selectors';
import { setSocialPostsPlatformFilter } from './SocialMedia/store/SocialMediaPosts.slice';
import { StatsTab } from './Stats';
import { selectStreamsPlatformFilter } from './Streaming/store/Streams.selectors';
import { setStreamsPlatformFilter } from './Streaming/store/Streams.slice';

type Props = {
  activeTab: StatsTab;
};

const useStyles = makeStyles(() => ({
  dropdown: {
    '& .MuiSelect-root': {
      padding: '2px 24px 2px 8px',
    },
  },
}));

export const StatsPlatformDropdown = ({ activeTab }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const socialPostPlatformFilter = useSelector(
    selectSocialPostsPlatformFilter()
  );
  const streamsPlatformFilter = useSelector(selectStreamsPlatformFilter());

  const onPlatformChange = (value: 'all' | Platform) => {
    if (activeTab === 'social-media') {
      dispatch(
        setSocialPostsPlatformFilter(value as 'all' | SocialMediaPlatform)
      );
    }

    if (activeTab === 'streaming') {
      dispatch(setStreamsPlatformFilter(value as 'all' | StreamingPlatform));
    }

    return;
  };

  const getPlatformFilter = () => {
    if (activeTab === 'social-media') {
      return socialPostPlatformFilter;
    }

    if (activeTab === 'streaming') {
      return streamsPlatformFilter;
    }

    return 'all';
  };

  return (
    <PlatformDropdown
      platformType={activeTab === 'streaming' ? 'streaming' : 'social'}
      className={classes.dropdown}
      value={getPlatformFilter()}
      onChange={(value) => {
        onPlatformChange(value);
      }}
    />
  );
};
