export const en = {
  translation: {
    generic: {
      or: 'or',
      login: 'Log in',
      privacyPolicy: 'Privacy policy',
    },

    campaigns: {
      campaignsList: {
        title: 'Tracking',
      },
    },
    overview: {
      discovery: {
        title: 'Recommended content creators',
      },
    },
    marketplace: {
      signup: {
        welcomeStep: {
          title: 'Welcome! Let’s get started',
          registerTwitch: 'Register with Twitch',
          registerYoutube: 'Register with YouTube',
          registerEmail: 'Register with email',
          existentAccount: 'Already have an account?',
          confirmPolicy: "By signing up, you confirm that you've read our",
        },
        signupForm: {
          title: 'Create account',
          subTitle: 'Enter your email and set your password to get started',
        },
        describesForm: {
          title: 'Title goes here',
          subTitle: 'Select all which of the following describes you best',
        },
        infoForm: {
          title: 'Complete your profile',
          subTitle: 'Add additional info on your profile',
        },
        subInfoForm: {
          title: 'Complete your profile',
          subTitle: 'Add additional info on your profile',
        },
        socialAccountsForm: {
          title: 'Connect social media accounts',
          subTitle:
            'Description about benefits of connecting all their social media accounts',
        },
      },
    },
    errors: {
      error: 'There was an error!',
      resource_not_authorized: 'You are not authorised to perform this action!',

      // Email related
      'email.invalid': 'Invalid email: {{value}}',
      'email.taken': 'Email is already in use',
      'email.blank': 'Invalid email',

      // Password related
      'password.too_short':
        'Password is too short. It needs to be a minimum of {{count}} characters',
      'password.blank': 'Password can not be blank',
      'current_password.invalid': 'Invalid password provided',

      // Login
      'user.invalid_credentials': 'Invalid credentials',
      'password.wrong': 'Incorrect email or password. Please try again!',

      // Phone related
      'phone.too_long':
        'Phone number is too long. Maximum {{count}} characters',

      // Discord username related
      'discord_username.too_long':
        'Discord username is too long. Maximum {{count}} characters',

      // Social connections related
      token_data: 'Invalid token passed, please try again',

      // Connect YouTube related
      'social_linking.youtube.no_channel_found':
        "Your YouTube account doesn't have a YouTube channel",
    },
  },
};
