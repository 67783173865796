import { makeStyles, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '160px',
    margin: '0 36px 0 16px',
    fontSize: '1rem',
  },
  title: {
    marginTop: '20px',
    color: '#000',
    fontSize: '14px',
    height: '38px',
    marginBottom: '5px',
  },
  content: {
    height: '220px',
    overflow: 'auto',
  },
  item: {
    color: '#000000de',
    width: '100%',
    border: '1px solid #BBBBBB',
    maxWidth: '140px',
    background: '#F0F0F0',
    boxShadow: 'none',
    borderRadius: '20px',
    padding: '3px 0px',
    marginBottom: '10px',
    '& span': {
      padding: '3px',
      fontSize: '14px',
      lineHeight: '23px',
      marginLeft: '10px',
    },
  },
  btn: {
    color: '#F0F0F0',
    width: '18px',
    height: '18px',
    background: '#919090',
    fontWeight: 'bold',
    alignItems: 'center',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: 'Arial!important',
    fontSize: '16px',
    justifyContent: 'center',
    position: 'absolute',
    top: '14px',
    right: '6px',
    lineheight: '6px',
    padding: '0',
    transform: 'translateY(-50%) rotate(45deg)',
  },
}));
const CustomPluginForDatePicker = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { campaignDays } = values;
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Tracking schedule</Typography>
      <div className={classes.content}>
        {campaignDays
          .filter(
            (el) => moment(el).isAfter() || moment().format('YYYY-MM-DD') === el
          )
          .map((el, index) => (
            <div key={index} className={classes.item}>
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>{moment(el).format('MMM D, YYYY')}</span>
                <i
                  onClick={() => {
                    setFieldValue(
                      'campaignDays',
                      campaignDays.filter((day) => day !== el)
                    );
                  }}
                  className={classes.btn}
                >
                  +
                </i>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CustomPluginForDatePicker;
