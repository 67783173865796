import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DeliverablePaginator } from '../../components/DeliverablePaginator';
import LinkSharingDeliverable from './LinkSharingDeliverable';
import {
  selectFilteredLinkTrackingDeliverablesStructures,
  selectLinkTrackingDeliverablesError,
} from './store/LinkTrackingDeliverables.selectors';
import { LinkTrackingDeliverableStructure } from './store/types';

export const LinkSharing = ({
  campaign,
  usedVideos,
  usedVideosIds,
  usedInfluencers,
  setSelectedCampaignInfluencer,
  isSponsorship,
  brandFilter,
  numberPage,
  setNumberPage,
}) => {
  const error = useSelector(selectLinkTrackingDeliverablesError());

  const linkTrackingDeliverablesStructures = useSelector(
    selectFilteredLinkTrackingDeliverablesStructures({
      campaignId: campaign.id,
      brandIdFilter: brandFilter,
      onlyBrandDeliverables: isSponsorship,
      sortBy: { key: 'results_count', order: 'desc' },
    })
  );

  useEffect(() => {
    if (!!numberPage) {
      return () => {
        setNumberPage(0);
      };
    }
  }, [numberPage, setNumberPage]);

  const renderDeliverable = useCallback(
    (deliverableStructure: LinkTrackingDeliverableStructure) => (
      <LinkSharingDeliverable
        key={deliverableStructure.id}
        linkTrackingDeliverableStructure={deliverableStructure}
        usedVideos={usedVideos}
        usedVideosIds={usedVideosIds}
        usedInfluencers={usedInfluencers}
        timeZone={campaign.time_zone}
        setSelectedCampaignInfluencer={setSelectedCampaignInfluencer}
      />
    ),
    [
      usedVideos,
      usedVideosIds,
      usedInfluencers,
      campaign,
      setSelectedCampaignInfluencer,
    ]
  );

  if (error) return <p>error</p>;

  return (
    <div style={{ paddingTop: 24 }}>
      <DeliverablePaginator
        renderDeliverable={renderDeliverable}
        deliverables={linkTrackingDeliverablesStructures}
        forcePageIndex={numberPage}
      />
    </div>
  );
};

export default LinkSharing;
