export default [
  { value: 1, label: 'FPS' },
  { value: 2, label: 'Shooter' },
  { value: 3, label: 'MMORPG' },
  { value: 4, label: 'RPG' },
  { value: 5, label: 'Action' },
  { value: 6, label: 'Adventure Game' },
  { value: 7, label: 'Strategy' },
  { value: 8, label: 'Autobattler' },
  { value: 9, label: 'Card & Board Game' },
  { value: 10, label: 'Simulation' },
  { value: 11, label: 'Horror' },
  { value: 12, label: 'Platformer' },
  { value: 13, label: 'Stealth' },
  { value: 14, label: 'Open World' },
  { value: 15, label: 'Fighting' },
  { value: 16, label: 'Driving/Racing Game' },
  { value: 17, label: 'Sports Game' },
  { value: 18, label: 'MOBA' },
  { value: 19, label: 'IRL' },
  { value: 20, label: 'Survival' },
  { value: 21, label: 'Roguelike' },
  { value: 22, label: 'Metroidvania' },
  { value: 23, label: 'Indie Game' },
  { value: 24, label: 'Compilation' },
  { value: 25, label: 'RTS' },
  { value: 26, label: 'Mobile Game' },
  { value: 27, label: 'Puzzle' },
  { value: 28, label: 'Creative' },
  { value: 29, label: 'Rhythm & Music Game' },
  { value: 30, label: 'Gambling Game' },
  { value: 31, label: 'Visual Novel' },
  { value: 32, label: "Shoot 'Em Up" },
  { value: 33, label: 'Hidden Objects' },
  { value: 34, label: 'Point and Click' },
  { value: 35, label: 'Flight Simulator' },
  { value: 36, label: 'Educational Game' },
  { value: 37, label: 'Pinball' },
  { value: 38, label: 'Party Game' },
  { value: 39, label: 'VR' },
  { value: 40, label: 'MMO' },
  { value: 41, label: 'Arcade' },
  { value: 42, label: 'Mystery' },
  { value: 43, label: '4X' },
  { value: 44, label: 'Battle Royales' },
];
