import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

import { PATH_ROOT } from '../common/constants/paths';
import { history } from '../store';

const locationHelper = locationHelperBuilder({});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || PATH_ROOT;
  },
  authenticatingSelector: () => false,
  authenticatedSelector: ({ account: { authUser } }) =>
    !(authUser && authUser.token),
  redirectAction: (newLoc) => () => history.replace(newLoc),
});
