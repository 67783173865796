import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { object, string } from 'yup';

import { actions } from '../../../store/actions';
import { selectors } from '../../../store/selectors';
import objectFilter from '../../../utils/object-filter';

const mapPropsToValues = (props) => ({
  twitch_url: props.twitch_url || '',
  youtube_url: props.youtube_url || '',
  facebook_url: props.facebook_url || '',
  twitter_url: props.twitter_url || '',
  instagram_url: props.instagram_url || '',
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (urls, withDialog, watchlistId, network) =>
    dispatch(
      actions.myWatchlistAddRequest(urls, withDialog, watchlistId, network)
    ),
});

const mapStateToProps = (state) => ({
  loading: selectors.getWatchlistAddSubmitted(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFormik({
    mapPropsToValues,
    validateOnChange: true,
    validationSchema: object().shape({
      twitch_url: string().matches(
        /(twitch.tv)\/(\S+)/i,
        'Is not a valid url. Must include: twitch.tv'
      ),
      // .test(
      //   'one is required',
      //   'Please add a Twitch, YouTube or Twitter URL',
      //   function (value) {
      //     const { youtube_url, facebook_url } = this.parent;

      //     return youtube_url || value || facebook_url;
      //   }
      // ),
      youtube_url: string().matches(
        /(youtube.com)\/[A-Z0-9._%+-]*/i,
        'Is not a valid url. Must include: youtube.com/*'
      ),
      facebook_url: string().matches(
        /(facebook.com)\/(\S+)/i,
        'Is not a valid url. Must include facebook.com'
      ),
      twitter_url: string().matches(
        /(twitter.com)\/(\S+)/i,
        'Is not a valid url. Must include: twitter.com'
      ),
      instagram_url: string()
        .matches(
          /(instagram.com)\/(\S+)/i,
          'Is not a valid url. Should include instagram.com'
        )
        .test(
          'Not include /p/',
          "Should't include /p/",
          (s) => !/(instagram.com)\/p\/(\S+)/i.test(s)
        ),
    }),
    handleSubmit: (urls, bag) => {
      const {
        withDialog,
        match: {
          params: { watchlistId, network },
        },
      } = bag.props;

      bag.props.onSubmit(
        objectFilter((v) => !!v)(urls),
        withDialog,
        parseInt(watchlistId, 10),
        network || 'twitch'
      );
    },
  })
);
