import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';

import ShoutoutsV2 from '../../../CampaignWizard/ShoutoutsV2/ShoutoutsV2';

const useStyles = makeStyles((theme) => ({
  container: {},
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  chipsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  label: {
    fontSize: '24px',
    fontWeight: 'bold',
    paddingLeft: 0,
  },
}));

export const Shoutouts = ({ disabled, trialClassName, isViewOnly, id }) => {
  const classes = useStyles();
  return (
    <Card
      className={cx(classes.container, {
        [trialClassName]: disabled,
      })}
    >
      <CardContent>
        <Typography className={classes.label} variant="h2">
          Voice mentions
        </Typography>
      </CardContent>

      <CardContent>
        <ShoutoutsV2 disabled={disabled} isViewOnly={isViewOnly} id={id} />
      </CardContent>
    </Card>
  );
};

export default Shoutouts;
