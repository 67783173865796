import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import _isEqual from 'lodash/isEqual';
import React from 'react';

import { getStaticGames } from '../../../ajax';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import CardContent from '../../../components/CardContent';
import SectionTitle from '../../../components/SectionTitle';
import TopBar from '../../../components/TopBar';
import TopBarContent from '../../../components/TopBarContent';
import TopBarTitle from '../../../components/TopBarTitle';
import { influencersSearchReset } from '../../../store/action-creators';
import { influencersSearchMounted } from '../../../store/events';
import AudienceParameters from './AudienceParameters';
import CampaignParameters from './CampaignParameters';
import { DEFAULT_PARAMS } from './defaultParams';
import InfluencerParameters from './InfluencerParameters';

const paramsChanged = (values) =>
  !_isEqual(
    // ignore network and search_watchlist params
    { ...values, search_watchlist: null, network: null },
    { ...DEFAULT_PARAMS, search_watchlist: null, network: null }
  );

const enhance = withStyles((theme) => ({
  main: {
    maxHeight: 'calc(100% - 50px)',
    overflow: 'scroll',
    padding: '50px 16px 76px 16px',
    marginTop: 65,
  },
  searchContainer: {
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    position: 'absolute',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  buttonSelected: {
    background: theme.palette.primary.lighter,
    '&:hover': {
      background: theme.palette.primary.lighter,
    },
  },
  trialDisabled: {
    pointerEvents: 'none',
    backgroundColor: theme.palette.text.disabled,
  },
}));

class InfluencerSearchPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gamesList: [],
    };
  }

  componentDidMount() {
    document.title = 'Influencer Discovery';

    this.props.dispatch(influencersSearchReset());
    this.props.dispatch(influencersSearchMounted());

    getStaticGames().then((res) => {
      const parseGames = res.map((el) => {
        return {
          id: el.id,
          label: el.name,
        };
      });

      this.setState({ gamesList: parseGames });
    });
  }

  componentDidUpdate(prevProps) {
    const { setFieldValue } = this.props;

    if (this.networkHasChanged(prevProps)) {
      /*
       * because we don't want to send un-necessary information together
       * with a query we reset both these parameters should the user
       * decide to change the selected network
       */

      setFieldValue('min_ccv', '');
      setFieldValue('max_ccv', '');
      setFieldValue('min_avg_view_count_per_video', '');
      setFieldValue('max_avg_view_count_per_video', '');
    }
  }

  networkHasChanged(prevProps) {
    return prevProps.values.network !== this.props.values.network;
  }

  clearSearch = () => {
    if (this.props.location.search) this.props.history.replace({ search: '' });

    this.props.resetForm();
  };

  // categories = [
  //   'Movies and tv',
  //   'Fashion industry',
  //   'Beverages',
  //   'Food',
  //   'Technology',
  // ]

  // renderAudienceCategoryInterests = () => {
  //   const { values } = this.props

  //   const availableCategories = this.categories.filter(
  //     category => !values.audience_interest_categories.includes(category)
  //   )

  //   return (
  //     <div className="option-content">
  //       <p className="option-title">Category</p>
  //       <FieldArray
  //         name="audience_interest_categories"
  //         render={
  //           ({ push, remove }) => (
  //             <ul className="option-list removable-option">

  //               {
  //                 values.audience_interest_categories.map(
  //                   (category, index) => (
  //                     <li key={category}>
  //                       <Chip label={category} onDelete={() => { remove(index) }} />
  //                     </li>
  //                   )
  //                 )
  //               }

  //               {
  //                 availableCategories.length > 0 && (
  //                   <li className="dropdown-option">
  //                     <SelectTermPopoverMenu
  //                       terms={availableCategories}
  //                       onSelect={push}
  //                     />
  //                   </li>
  //                 )
  //               }
  //             </ul>
  //           )
  //         }
  //       />
  //     </div>
  //   )
  // }

  // renderAudienceKeywordInterests = () => {
  //   const { values } = this.props

  //   const { audienceInterestKeywordFormIsOpen } = this.state

  //   return (
  //     <div className="option-content">
  //       <label className="option-title">Talking about</label>
  //       <FieldArray
  //         name="audience_interest_keywords"
  //         render={
  //           ({ push, remove }) => (
  //             <ul className="option-list removable-option">
  //               {
  //                 values.audience_interest_keywords.map(
  //                   (keyword, index) => (
  //                     <li key={keyword}>
  //                       <Chip label={keyword} onDelete={() => remove(index)} />
  //                     </li>
  //                   )
  //                 )
  //               }

  //               <li className="dropdown-option">
  //                 <Popover
  //                   under
  //                   align="left"
  //                   open={audienceInterestKeywordFormIsOpen}
  //                   onClose={this.closeAudienceInterestKeywordForm}
  //                   content={(
  //                     <AudienceKeywordForm
  //                       close={this.closeAudienceInterestKeywordForm}
  //                       onSubmit={({ keyword }) => push(keyword)}
  //                     />
  //                   )}
  //                 >
  //                   <button
  //                     onClick={this.openAudienceInterestKeywordForm}
  //                     className="btn btn-outline-primary option-add"
  //                     aria-haspopup="true"
  //                     aria-expanded={audienceInterestKeywordFormIsOpen}
  //                   >
  //                     <i className="ia fa-plus" />
  //                   </button>
  //                 </Popover>
  //               </li>

  //               {
  //                 values.audience_interest_keywords.length === 0 && (
  //                   <li>
  //                     <p className="empty-filter-message">
  //                       No keyword added
  //                     </p>
  //                   </li>
  //                 )
  //               }
  //             </ul>
  //           )
  //         }
  //       />
  //     </div>
  //   )
  // }

  render() {
    const { classes, trialAccountSearch, values, watchlists } = this.props;
    const { gamesList } = this.state;

    return (
      <>
        <TopBar>
          <TopBarContent>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <TopBarTitle>
                  {values.search_watchlist
                    ? 'Search My Watchlist'
                    : 'Discovery'}
                </TopBarTitle>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  {paramsChanged(values) && (
                    <Grid item>
                      <Button
                        size="medium"
                        variant="outlined"
                        color="primary"
                        onClick={this.clearSearch}
                      >
                        <ClearIcon className={classes.icon} />
                        Clear Filters
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={this.props.submitForm}
                    >
                      <SearchIcon className={classes.icon} />
                      Search creators
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TopBarContent>
        </TopBar>

        <main className={classes.main}>
          <form onSubmit={this.handleSubmit}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <SectionTitle>Content Creator</SectionTitle>
              </Grid>

              <Grid item>
                <InfluencerParameters
                  trialAccountSearch={trialAccountSearch}
                  watchlists={watchlists}
                  gamesList={gamesList}
                />
              </Grid>

              <Grid item>
                <SectionTitle>Target Audience</SectionTitle>
              </Grid>

              <Grid item>
                <Card style={{ overflow: 'inherit' }}>
                  <AudienceParameters trialAccountSearch={trialAccountSearch} />
                </Card>
              </Grid>

              <Grid item>
                <SectionTitle>Campaign</SectionTitle>
              </Grid>

              <Grid item>
                <Card>
                  <CardContent>
                    <CampaignParameters
                      trialAccountSearch={trialAccountSearch}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        </main>
      </>
    );
  }
}

export default enhance(InfluencerSearchPage);
