import { Button, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React from 'react';
import { DateObject } from 'react-multi-date-picker';

import DatePickerMultiple from '../../components/DatePickerMultiple';
import FormikMuiTextField from '../../components/FormikMuiTextField';
import getRange from '../../utils/get-range-dates';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    marginRight: 24,
    background: 'none',
    fontSize: 16,
    color: 'black',
    textTransform: 'uppercase',
    border: 'none',
    borderRadius: 0,
    borderBottom: `2px solid #ffffff00`,
    '&:hover': {
      transitionDuration: '0s',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      background: 'none',
    },
  },
  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  description: {
    margin: '1rem 0 0 0',
    fontSize: '1rem',
    color: 'black',
    lineHeight: 1,
  },
}));

export const BasicTab = ({
  values,
  campaign,
  setFieldValue,
  campaignIsOver,
}) => {
  const { time_zone } = campaign;
  const timeZone = time_zone || 'UTC';
  const campaignStartTime = momentTz
    .tz(values.campaignDaysFrame[0], timeZone)
    .startOf('day');
  const campaignEndTime = momentTz
    .tz(values.campaignDaysFrame[values.campaignDaysFrame.length - 1], timeZone)
    .endOf('day');
  const nowInTz = momentTz.tz(timeZone);
  const canEditStartDate = nowInTz.isBefore(campaignStartTime);
  const canEditEndDate = nowInTz.isBefore(campaignEndTime);

  const classes = useStyles();

  const handlePickerChange = (name, flag, minDate) => (nowMoment) => {
    if (flag === 'end') {
      let arr;
      const isTimeCheck =
        nowMoment?.isAfter(minDate) || nowMoment?.isSame(minDate);

      arr = getRange(
        values.campaignDaysFrame[0],
        isTimeCheck ? nowMoment?.format('YYYY-MM-DD') : minDate,
        'days'
      );

      setFieldValue(name, arr);
    } else if (flag === 'start') {
      let arr = getRange(
        nowMoment?.format('YYYY-MM-DD'),
        values.campaignDaysFrame[values.campaignDaysFrame.length - 1],
        'days'
      );
      setFieldValue(name, arr);
    } else {
      setFieldValue(
        name,
        nowMoment?.map((el) => new DateObject(el).format('YYYY-MM-DD')).sort()
      );
    }
  };

  const minEndDate = moment
    .max([moment(values.campaignDaysFrame[0]), nowInTz])
    .format('YYYY-MM-DD');

  return (
    <Grid item>
      <Card style={{ overflow: 'initial' }}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <FormLabel>Campaign name</FormLabel>
              <FormikMuiTextField
                fullWidth
                placeholder="Name of your campaign"
                variant="outlined"
                name="campaignName"
                value={values.campaignName}
                style={{ maxWidth: '320px', display: 'block' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="text"
                onClick={() => setFieldValue('isScheduleDateType', false)}
                className={`${classes.button} ${
                  !values.isScheduleDateType && classes.active
                }`}
              >
                TIME FRAME
              </Button>
              <Button
                onClick={() => setFieldValue('isScheduleDateType', true)}
                className={`${classes.button} ${
                  values.isScheduleDateType && classes.active
                }`}
              >
                SCHEDULE
              </Button>
              <p className={classes.description}>
                {!values.isScheduleDateType
                  ? 'Select the starting date and the ending date for your campaign.'
                  : 'Choose the scheduled dates by opening the calendar and selecting the days you want to track '}
              </p>
            </Grid>

            {!values.isScheduleDateType ? (
              <>
                <Grid item xs={6} sm={3}>
                  <FormLabel>Start date</FormLabel>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    name="campaignStartDate"
                    value={values.campaignDaysFrame[0]}
                    variant="inline"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    disabled={!canEditStartDate}
                    helperText={null}
                    minDate={nowInTz.add('1', 'day').format()}
                    onChange={handlePickerChange('campaignDaysFrame', 'start')}
                  />
                  <Typography
                    className="fs-i"
                    style={{ fontSize: 12, marginTop: 8 }}
                    color="textSecondary"
                  >
                    Start date can be changed only for future campaigns.
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FormLabel>End date</FormLabel>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    name="campaignEndDate"
                    value={
                      values.campaignDaysFrame[
                        values.campaignDaysFrame.length - 1
                      ]
                    }
                    inputValue={moment(
                      values.campaignDaysFrame[
                        values.campaignDaysFrame.length - 1
                      ]
                    ).format('DD/MM/YYYY')}
                    variant="inline"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    minDate={minEndDate}
                    disabled={!canEditEndDate}
                    helperText={null}
                    onChange={handlePickerChange(
                      'campaignDaysFrame',
                      'end',
                      minEndDate
                    )}
                  />
                  <Typography
                    className="fs-i"
                    style={{ fontSize: 12, marginTop: 8 }}
                    color="textSecondary"
                  >
                    End date can be changed for future or ongoing campaigns.
                  </Typography>
                </Grid>
              </>
            ) : (
              <DatePickerMultiple
                handlePickerChange={handlePickerChange}
                campaignIsOver={campaignIsOver}
              />
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BasicTab;
