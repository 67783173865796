import { Button, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { connect } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { DateObject } from 'react-multi-date-picker';
import ReactSelect from 'react-select';

import DatePickerMultiple from '../../components/DatePickerMultiple';
import FormikMuiTextField from '../../components/FormikMuiTextField';
import getRange from '../../utils/get-range-dates';
import timezones from '../../utils/timezone-list';
const CAMPAIGN_START_DATE = 'campaignStartDate';
const CAMPAIGN_END_DATE = 'campaignEndDate';

const enhance = connect;
const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    marginRight: 24,
    background: 'none',
    fontSize: 16,
    color: 'black',
    textTransform: 'uppercase',
    border: 'none',
    borderRadius: 0,
    borderBottom: `2px solid #ffffff00`,
    '&:hover': {
      transitionDuration: '0s',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      background: 'none',
    },
  },
  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  description: {
    margin: '1rem 0 0 0',
    fontSize: '1rem',
    color: 'black',
    lineHeight: 1,
  },
}));

function CampaignWizardBasicInformation(props) {
  const classes = useStyles();
  const {
    formik: { values, setFieldValue },
  } = props;

  const handlePickerChange = (name, flag) => (date, value) => {
    if (flag === 'end') {
      setFieldValue('newEndDate', value);
      let arr = getRange(
        values.newStartDate,
        date?.format('YYYY-MM-DD'),
        'days'
      );
      setFieldValue(name, arr);
    } else if (flag === 'start') {
      setFieldValue('newStartDate', value);
      let arr = getRange(date?.format('YYYY-MM-DD'), values.newEndDate, 'days');
      setFieldValue(name, arr);
    } else {
      setFieldValue(
        name,
        date?.map((el) => new DateObject(el).format('YYYY-MM-DD')).sort()
      );
    }
  };
  function handleTimeZoneChange({ value }) {
    setFieldValue('campaignTimezone', value);
  }
  useEffect(() => {
    if (values.newStartDate > values.newEndDate) {
      let arr = getRange(
        values.newStartDate,
        moment(values.newStartDate).add(1, 'days').format('MMM DD, YYYY'),
        'days'
      );
      setFieldValue(
        'newEndDate',
        moment(values.newStartDate).add(1, 'days').format('MMM DD, YYYY')
      );
      setFieldValue('campaignDaysFrame', arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.newStartDate]);
  return (
    <Card style={{ overflow: 'initial' }}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormLabel>Campaign name</FormLabel>
            <FormikMuiTextField
              fullWidth
              placeholder="Name of your campaign"
              variant="outlined"
              name="campaignName"
              value={values.campaignName}
              style={{ maxWidth: '320px', display: 'block' }}
              onChange={(e) => setFieldValue('campaignName', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="text"
              onClick={() => setFieldValue('isScheduleDateType', false)}
              className={`${classes.button} ${
                !values.isScheduleDateType && classes.active
              }`}
            >
              TIME FRAME
            </Button>
            <Button
              onClick={() => setFieldValue('isScheduleDateType', true)}
              className={`${classes.button} ${
                values.isScheduleDateType && classes.active
              }`}
            >
              SCHEDULE
            </Button>
            <p className={classes.description}>
              {!values.isScheduleDateType
                ? 'Select the starting date and the ending date for your campaign.'
                : 'Choose the scheduled dates by opening the calendar and selecting the days you want to track '}
            </p>
          </Grid>
          {!values.isScheduleDateType ? (
            <>
              <Grid item xs={6} sm={3}>
                <FormLabel>Start date</FormLabel>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  inputVariant="outlined"
                  name={CAMPAIGN_START_DATE}
                  inputValue={moment(values.newStartDate).format(
                    'MMM DD, YYYY'
                  )}
                  refuse={/[^\da-zA-Z]+/gi}
                  variant="inline"
                  format="MMM DD, YYYY"
                  minDate={moment()}
                  onChange={handlePickerChange('campaignDaysFrame', 'start')}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormLabel>End date</FormLabel>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  name={CAMPAIGN_END_DATE}
                  value={
                    values.campaignDaysFrame[
                      values.campaignDaysFrame.length - 1
                    ]
                  }
                  inputVariant="outlined"
                  inputValue={moment(values.newEndDate).format('MMM DD, YYYY')}
                  refuse={/[^\da-zA-Z]+/gi}
                  variant="inline"
                  format="MMM DD, YYYY"
                  minDate={moment(values.campaignDaysFrame[0])}
                  minDateMessage="The end date mustn't be before the start date"
                  onChange={handlePickerChange('campaignDaysFrame', 'end')}
                />
              </Grid>
            </>
          ) : (
            <DatePickerMultiple handlePickerChange={handlePickerChange} />
          )}
          <Grid item xs={6} sm={6}>
            <FormControl style={{ zIndex: 99, width: '100%' }}>
              <FormLabel>Time zone</FormLabel>
              <ReactSelect
                value={timezones.find(
                  ({ value }) => value === values.campaignTimezone
                )}
                onChange={handleTimeZoneChange}
                options={timezones}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    width: '100%',
                    height: 48,
                    fontSize: '16px',
                  }),
                  option: (styles) => ({
                    ...styles,
                    width: '100%',
                    fontSize: '16px',
                  }),
                  input: (styles) => ({
                    ...styles,
                    width: '100%',
                    height: 40,
                    cursor: 'text',
                    fontSize: '16px',
                    padding: '10px 0px',
                  }),
                }}
                name="campaignTimezone"
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default enhance(CampaignWizardBasicInformation);
