import { Sema } from 'async-sema';
import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSocialMediaPerformanceSummary,
  getSocialMentionsDeliverables,
  MAX_CONCURRENT_REQUESTS,
} from '../../../ajax';
import {
  selectAllBannerDeliverablesByCampaignId,
  selectAllBannerDeliverablesByCampaignIdShowLoading,
  selectBannerDeliverablesError,
  selectBannerDeliverablesToFetchByCampaignId,
} from '../DeliverablesTab/Banners/store/BannerDeliverables.selectors';
import { fetchBannerDeliverable } from '../DeliverablesTab/Banners/store/BannerDeliverables.thunks';
import {
  selectAllChatMentionDeliverablesByCampaignId,
  selectAllChatMentionDeliverablesByCampaignIdShowLoading,
  selectChatMentionDeliverablesError,
  selectChatMentionDeliverablesToFetchByCampaignId,
} from '../DeliverablesTab/ChatMentions/store/ChatMentionDeliverables.selectors';
import { fetchChatMentionDeliverable } from '../DeliverablesTab/ChatMentions/store/ChatMentionDeliverables.thunks';
import {
  selectAllLinkTrackingDeliverablesByCampaignId,
  selectAllLinkTrackingDeliverablesByCampaignIdShowLoading,
  selectLinkTrackingDeliverablesError,
  selectLinkTrackingDeliverablesToFetchByCampaignId,
} from '../DeliverablesTab/LinkSharing/store/LinkTrackingDeliverables.selectors';
import { fetchLinkTrackingDeliverable } from '../DeliverablesTab/LinkSharing/store/LinkTrackingDeliverables.thunks';
import {
  selectAllShoutoutDeliverablesByCampaignId,
  selectAllShoutoutDeliverablesByCampaignIdShowLoading,
  selectShoutoutDeliverablesError,
  selectShoutoutDeliverablesToFetchByCampaignId,
} from '../DeliverablesTab/Shoutouts/store/ShoutoutDeliverables.selectors';
import { fetchShoutoutDeliverable } from '../DeliverablesTab/Shoutouts/store/ShoutoutDeliverables.thunks';
import { Campaign, TimeFrame } from '../types';

export const useAllDeliverables = (
  slimCampaign: Campaign,
  timeFrame: Partial<TimeFrame>,
  shouldFetch: boolean
) => {
  const campaignId = slimCampaign.id;
  const influencerIds = slimCampaign.campaign_influencers.map(
    (influencer) => influencer.id
  );

  // Shoutout deliverables
  const shoutoutDeliverables = useSelector(
    selectAllShoutoutDeliverablesByCampaignId(campaignId)
  );

  const shoutoutDeliverablesShowLoading = useSelector(
    selectAllShoutoutDeliverablesByCampaignIdShowLoading(campaignId)
  );

  const shoutoutDeliverablesToFetch = useSelector(
    selectShoutoutDeliverablesToFetchByCampaignId(campaignId)
  );

  const shoutoutDeliverablesError = useSelector(
    selectShoutoutDeliverablesError()
  );

  // Banner deliverables
  const bannerDeliverables = useSelector(
    selectAllBannerDeliverablesByCampaignId(campaignId)
  );

  const bannerDeliverablesShowLoading = useSelector(
    selectAllBannerDeliverablesByCampaignIdShowLoading(campaignId)
  );

  const bannerDeliverablesToFetch = useSelector(
    selectBannerDeliverablesToFetchByCampaignId(campaignId)
  );

  const bannerDeliverablesError = useSelector(selectBannerDeliverablesError());

  // Link tracking deliverables
  const linkTrackingDeliverables = useSelector(
    selectAllLinkTrackingDeliverablesByCampaignId(campaignId)
  );

  const linkTrackingDeliverablesShowLoading = useSelector(
    selectAllLinkTrackingDeliverablesByCampaignIdShowLoading(campaignId)
  );

  const linkTrackingDeliverablesToFetch = useSelector(
    selectLinkTrackingDeliverablesToFetchByCampaignId(campaignId)
  );

  const linkTrackingDeliverablesError = useSelector(
    selectLinkTrackingDeliverablesError()
  );

  // Chat mention deliverables
  const chatMentionDeliverables = useSelector(
    selectAllChatMentionDeliverablesByCampaignId(campaignId)
  );

  const chatMentionDeliverablesShowLoading = useSelector(
    selectAllChatMentionDeliverablesByCampaignIdShowLoading(campaignId)
  );

  const chatMentionDeliverablesToFetch = useSelector(
    selectChatMentionDeliverablesToFetchByCampaignId(campaignId)
  );

  const chatMentionDeliverablesError = useSelector(
    selectChatMentionDeliverablesError()
  );

  const {
    isLoading: socialMediaDeliverablesShowLoading,
    data: socialMediaData,
    error: socialMediaDeliverablesError,
  } = useQuery(
    `campaign-${campaignId}-social-mentions`,
    () => getSocialMentionsDeliverables(campaignId),
    { enabled: shouldFetch }
  );

  const {
    isLoading: socialPerformanceSummaryShowLoading,
    data: socialPerformanceSummary,
    error: socialPerformanceSummaryError,
  } = useQuery(
    `campaign-${campaignId}-social-performances-summary`,
    () =>
      getSocialMediaPerformanceSummary(
        campaignId,
        influencerIds,
        'all',
        timeFrame.start,
        timeFrame.end
      ),
    { enabled: shouldFetch }
  );

  const {
    instagram_keyword_mention_deliverables: instagramMentionDeliverables,
    twitter_keyword_mention_deliverables: twitterMentionDeliverables,
    tiktok_keyword_mention_deliverables: tikTokMentionDeliverables,
    facebook_keyword_mention_deliverables: facebookMentionDeliverables,
  } = socialMediaData || {};

  const showLoading = [
    shoutoutDeliverablesShowLoading,
    bannerDeliverablesShowLoading,
    linkTrackingDeliverablesShowLoading,
    chatMentionDeliverablesShowLoading,
    socialMediaDeliverablesShowLoading || !socialMediaData,
    socialPerformanceSummaryShowLoading || !socialPerformanceSummary,
  ].some((showLoading) => showLoading);

  const dispatch = useDispatch();

  const getAllDeliverablesAsyncThunks = useCallback(() => {
    const getShoutoutsThunks = () => {
      return shoutoutDeliverablesToFetch.map(({ id, v2 }) => {
        return fetchShoutoutDeliverable({
          campaignId,
          v2,
          shoutoutDeliverableId: id,
        });
      });
    };

    const getBannersThunks = () => {
      return bannerDeliverablesToFetch.map((id) => {
        return fetchBannerDeliverable({ campaignId, bannerDeliverableId: id });
      });
    };

    const getLinkTrackingThunks = () => {
      return linkTrackingDeliverablesToFetch.map((id) => {
        return fetchLinkTrackingDeliverable({
          campaignId,
          linkTrackingDeliverableId: id,
        });
      });
    };

    const getChatMentionsThunks = () => {
      return chatMentionDeliverablesToFetch.map((id) => {
        return fetchChatMentionDeliverable({
          campaignId,
          chatMentionDeliverableId: id,
        });
      });
    };

    return [
      ...getShoutoutsThunks(),
      ...getBannersThunks(),
      ...getLinkTrackingThunks(),
      ...getChatMentionsThunks(),
    ];
  }, [
    shoutoutDeliverablesToFetch,
    bannerDeliverablesToFetch,
    linkTrackingDeliverablesToFetch,
    chatMentionDeliverablesToFetch,
    campaignId,
  ]);

  const allThunks = useMemo(
    () => getAllDeliverablesAsyncThunks(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaignId]
  ); // Only re-fetch when campaignId changes, since the deliverables to fetch changes each time a deliverable is fetched
  const semaphore = useMemo(
    () => new Sema(MAX_CONCURRENT_REQUESTS, { capacity: allThunks.length }),
    [allThunks]
  );

  const fetchData = useCallback(
    async (thunk) => {
      await semaphore.acquire();
      try {
        await dispatch(thunk);
      } finally {
        semaphore.release();
      }
    },
    [dispatch, semaphore]
  );

  const fetchAllDeliverables = useCallback(async () => {
    await Promise.all(allThunks.map(fetchData));
  }, [allThunks, fetchData]);

  useEffect(() => {
    if (!shouldFetch) return;

    fetchAllDeliverables();
  }, [campaignId, shouldFetch, fetchAllDeliverables]);

  const hasError = [
    shoutoutDeliverablesError,
    bannerDeliverablesError,
    linkTrackingDeliverablesError,
    chatMentionDeliverablesError,
    socialMediaDeliverablesError,
    socialPerformanceSummaryError,
  ].some((error) => error != null);

  return {
    shoutoutDeliverables,
    bannerDeliverables,
    linkTrackingDeliverables,
    chatMentionDeliverables,
    twitterMentionDeliverables,
    instagramMentionDeliverables,
    tikTokMentionDeliverables,
    facebookMentionDeliverables,
    socialPerformanceSummary,
    showLoading,
    hasError,
  };
};
