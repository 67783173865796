import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  deleteMultipleSocialPosts,
  getSocialMediaPerformancePosts,
  getSocialMediaPerformanceSummary,
} from '../../../../../ajax';
import { showToast } from '../../../../../store/commonActions';
import {
  FetchSocialMediaPerformancePayload,
  SLICE_NAME,
  SocialMediaPerformancePost,
  SocialMediaPerformanceSummary,
} from './types';

export const fetchSocialMediaPerformanceSummary = createAsyncThunk<
  SocialMediaPerformanceSummary[],
  FetchSocialMediaPerformancePayload
>(`${SLICE_NAME}/fetchSocialMediaPerformanceSummary`, async (payload) => {
  const { campaignId, influencerIds, platform, startTime, endTime } = payload;

  return getSocialMediaPerformanceSummary(
    campaignId,
    influencerIds,
    platform,
    startTime,
    endTime
  );
});

export const fetchSocialMediaPerformancePosts = createAsyncThunk<
  SocialMediaPerformancePost[],
  FetchSocialMediaPerformancePayload
>(`${SLICE_NAME}/fetchSocialMediaPerformancePosts`, async (payload) => {
  const { campaignId, influencerIds, platform, startTime, endTime } = payload;

  return getSocialMediaPerformancePosts(
    campaignId,
    influencerIds,
    platform,
    startTime,
    endTime
  );
});

export type DeleteMultipleSocialPostsPayload = {
  campaignId: number;
  postIds: (number | string)[];
  influencersPostsMap: Record<number, Record<string, (string | number)[]>>;
};

export const deleteSocialPostsAction = createAsyncThunk(
  `${SLICE_NAME}/deleteMultipleSocialPosts`,
  async (
    { influencersPostsMap, campaignId }: DeleteMultipleSocialPostsPayload,
    thunkAPI
  ) => {
    const influencersIds = Object.keys(influencersPostsMap);

    const postsMapByInfluencerAndPlatform = {};

    influencersIds.forEach((influencerId) => {
      const postsMap = influencersPostsMap[influencerId];

      const tweet_ids = (postsMap?.twitter || []).toString();
      const tiktok_ids = (postsMap?.tiktok || []).toString();
      const facebook_ids = (postsMap?.facebook || []).toString();
      const insta_ids = (postsMap?.instagram || []).toString();

      postsMapByInfluencerAndPlatform[influencerId] = {
        tweet_ids,
        tiktok_ids,
        facebook_ids,
        insta_ids,
      };
    });

    try {
      const res = await deleteMultipleSocialPosts({
        campaign_id: campaignId,
        postsMap: postsMapByInfluencerAndPlatform,
      });

      thunkAPI.dispatch(
        showToast({
          message: 'Posts deleted successfully.',
          type: 'success',
        })
      );

      return res;
    } catch (e) {
      thunkAPI.dispatch(
        showToast({
          message: 'There was an error when trying to delete the posts!',
          type: 'error',
        })
      );

      return thunkAPI.rejectWithValue('Error deleting the posts');
    }
  }
);
